import { createSvgIcon, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
};

const GreenCheckIcon = createSvgIcon(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#EFFCF2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.2568 14.4096C27.5829 14.7357 27.5829 15.2644 27.2568 15.5905L18.0901 24.7571C17.9336 24.9137 17.7212 25.0017 17.4997 25.0017C17.2783 25.0017 17.0659 24.9137 16.9093 24.7571L12.7426 20.5905C12.4165 20.2644 12.4165 19.7357 12.7426 19.4096C13.0687 19.0835 13.5974 19.0835 13.9235 19.4096L17.4997 22.9858L26.0759 14.4096C26.402 14.0835 26.9307 14.0835 27.2568 14.4096Z"
            fill="#26BF56"
        />
    </svg>,
    "GreenCheckIcon"
);

const RedirectDialog: React.FC<Props> = ({ open }) => {
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                <Stack spacing={2} alignItems="center">
                    <GreenCheckIcon sx={{ height: "40px", width: "40px", mb: 2.5 }} />
                    <Typography variant="textLg" fontWeight={500} textAlign="center">
                        <FormattedMessage
                            defaultMessage="You're all done"
                            description="Header for dialog when successfully submitting an emission intensity for the first time"
                        />
                    </Typography>
                    <Typography variant="textSm" color={(t) => t.palette.text["text-body"]}>
                        <FormattedMessage
                            defaultMessage="You'll soon be redirect to the overview."
                            description="Subheader for dialog when successfully submitting an emission intensity for the first time"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export { RedirectDialog };
