import { DefaultFormType } from "@/Components/AddActivityForm/types";
import { useFormContext } from "react-hook-form";
import { MissingInformationContent } from "../MissingInformationContent";
import { OverlappingDataWrapper } from "../Wrapper";
import { MainContent } from "./MainContent";

export const EmissionsExcludedFromSupplierSource = () => {
    const { watch } = useFormContext<DefaultFormType>();
    const supplierId = watch("supplierId");
    const from = watch("from");
    const to = watch("to");
    return (
        <OverlappingDataWrapper>
            {from && to && supplierId ? (
                <MainContent supplierId={supplierId} year={from.getFullYear()} />
            ) : (
                <MissingInformationContent type="supplier" />
            )}
        </OverlappingDataWrapper>
    );
};
