import { trpc } from "@/lib/trpc/client";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

function getBreakdownSelectionKey() {
    return `${localStorage.getItem("tenant")}:CARBON:PIE_CHART_BREAKDOWN`;
}

function getBreakdownSelection(): PossibleBreakdown | null {
    const localStorageKey = getBreakdownSelectionKey();
    const breakdown = localStorage.getItem(localStorageKey);
    if (breakdown === "scope" || breakdown === "companyStructure" || breakdown === "spendCategories") {
        return breakdown;
    }
    return null;
}

function useSelectedPieChartBreakdown(): [PossibleBreakdown, React.Dispatch<React.SetStateAction<PossibleBreakdown>>] {
    const [breakdown, setBreakdown] = useState<PossibleBreakdown>(getBreakdownSelection() ?? "scope");

    useEffect(() => {
        const localStorageKey = getBreakdownSelectionKey();
        localStorage.setItem(localStorageKey, breakdown);
    }, [breakdown]);

    return [breakdown, setBreakdown];
}

export { useSelectedPieChartBreakdown };

export type PossibleBreakdown = "scope" | "companyStructure" | "spendCategories";

export function useBreakdownOptions(): { value: PossibleBreakdown; label: string }[] {
    const { formatMessage } = useIntl();
    const { data: contributorTypes } = trpc.getContributorTypes.useQuery();
    const options = useMemo(() => {
        const structures =
            contributorTypes
                ?.filter((option) => option.contributorType !== "suppliers")
                .map((ct) => ({
                    value: ct.contributorType,
                    label: ct.name,
                })) ?? [];
        const scope = {
            value: "scope" as const,
            label: formatMessage({
                defaultMessage: "Scope",
                description: "Option in picker for breakdown of emissions",
            }),
        };
        return [scope, ...structures];
    }, [contributorTypes, formatMessage]);
    return options;
}
