import { InfoCircle } from "@/lib/svg/InfoCircle";
import { Stack, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

export const MissingDataContent = () => {
    return (
        <Stack gap={2}>
            <Stack gap={2} padding={3} alignItems="center">
                <InfoCircle />
                <Typography variant="textMd" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Enter activity information above to view live updates on how the emissions are calculated."
                        description="Missing data content"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};
