import { trpc } from "@/lib/trpc/client";
import { useAlert } from "@/Providers/Alerts";
import { addActivityFormRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useIntl } from "react-intl";
import { z } from "zod";
import {
    ActivityInformation,
    ActivityOption,
    UnitOfMeasurement,
    activityInformationSchema,
    activitySchema,
    allUnitOfMeasure,
    fieldBasedFilter,
} from "router";
import { UploadFileStatus } from "../FileUploadComponent/FileListItem/UploadFileStatus.enum";

export const useGetMutationCleanupFunction = () => {
    const { formatMessage } = useIntl();
    const utils = trpc.useContext();
    const { alertUser } = useAlert();

    const onSuccess = (activityId: string) => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Activity was created",
                description: "Alert message when adding activity data",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.activityDataInEditTable.cancel();
        utils.activityDataInEditTable.refetch();
        utils.getActivity.cancel();
        utils.getActivity.refetch({ id: activityId });
        utils.overview.cancel();
        utils.overview.refetch();
        utils.emissionsToBeRemovedFromSupplier.invalidate();
    };

    const onError = (errorMessage: string) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred while adding emissions: {error}",
                    description: "Alert message when adding emissions data",
                },
                {
                    error: errorMessage,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };

    return { onSuccess, onError };
};

export const useGetChangeHandlers = (
    changeL2: (activityL2: ActivityInformation | null) => void,
    changeUnitOfMeasurement: (unitOfMeasurement: UnitOfMeasurement | null) => void
) => {
    const navigate = useNavigate();
    const handleChangeL1 = (activityOptions: ActivityOption[], activityL2: ActivityInformation | null) => {
        if (activityL2 && !activityOptions.some((option) => option.activityL2?.value === activityL2.value)) {
            changeL2(null);
        }
        changeUnitOfMeasurement(null);
        if (activityOptions.length && activityOptions[0].activityL2) {
            navigate({ to: ".", search: (prev) => ({ ...prev, formType: "activityL2" }), replace: true });
            return;
        }
        navigate({ to: ".", search: (prev) => ({ ...prev, formType: "activityL1" }), replace: true });
    };

    const handleChangeL2 = (actitityOption: ActivityOption, unitOfMeasurement: UnitOfMeasurement | null) => {
        if (unitOfMeasurement && !actitityOption.unitsOfMeasurement.find((unit) => unit === unitOfMeasurement)) {
            changeUnitOfMeasurement(null);
        }
    };

    return { handleChangeL1, handleChangeL2 };
};

export const exclusionFromConnectionInForm = z.object({
    filter: z.array(fieldBasedFilter.extend({ id: z.string() })).optional(),
});

// This is separated to a different function to be able to use the core logic without
// depending on search params

export const useGetValidatorSchema = (formValidationType: "activityL1" | "activityL2") => {
    const { formatMessage } = useIntl();

    const formValidatorBase =
        formValidationType === "activityL1"
            ? activitySchema
            : activitySchema.omit({ activityL2: true }).extend({ activityL2: activityInformationSchema });
    // Error messages
    const missingValue = formatMessage({
        defaultMessage: "Missing activity value",
        description: "Error message for missing value in the add data schema",
    });
    const missingUnit = formatMessage({
        defaultMessage: "Missing unit of measurement",
        description: "Error message for missing unit of measurement in the add data schema",
    });
    const missingFrom = formatMessage({
        defaultMessage: "Missing from date",
        description: "Error message for missing from date in the add data schema",
    });
    const missingTo = formatMessage({
        defaultMessage: "Missing to date",
        description: "Error message for missing to date in the add data schema",
    });
    const fromBeforeTo = formatMessage({
        defaultMessage: "From date must be before to date",
        description: "Error message for from date before to date in the add data schema",
    });
    const fromSameYear = formatMessage({
        defaultMessage: "From date and to date must be in the same year",
        description: "Error message for from date and to date not in the same year in the add data schema",
    });
    const notNegativeMessage = formatMessage({
        defaultMessage: "Value must be a positive number",
        description: "Error message for value not being a positive number in the add data schema",
    });
    // Zod types and schemas
    const formValidator = z
        .object({
            value: z.number({ required_error: missingValue }).nonnegative({ message: notNegativeMessage }),
            from: z.date({ required_error: missingFrom }),
            to: z.date({ required_error: missingTo }),
            unitOfMeasurement: z.enum(allUnitOfMeasure, { required_error: missingUnit }),
            filter: z.array(fieldBasedFilter.extend({ id: z.string() })).optional(),
            files: z
                .array(
                    z.object({
                        name: z.string(),
                        file: z.instanceof(File),
                        status: z.nativeEnum(UploadFileStatus),
                    })
                )
                .optional(),
            removeEmissionsFromSpendSource: z.boolean().optional(),
        })
        .superRefine((val, ctx) => {
            if (val.from && val.to && val.from > val.to) {
                ctx.addIssue({
                    message: fromBeforeTo,
                    path: ["to"],
                    code: z.ZodIssueCode.custom,
                });
                ctx.addIssue({
                    message: fromBeforeTo,
                    path: ["from"],
                    code: z.ZodIssueCode.custom,
                });
            }
            if (val.from.getUTCFullYear() !== val.to.getUTCFullYear()) {
                ctx.addIssue({
                    message: fromSameYear,
                    path: ["to"],
                    code: z.ZodIssueCode.custom,
                });
                ctx.addIssue({
                    message: fromSameYear,
                    path: ["from"],
                    code: z.ZodIssueCode.custom,
                });
            }
        });

    return z.intersection(formValidator, formValidatorBase);
};

export const useGetValidatorSchemaFromUrl = () => {
    const { formType: formValidationType } = useSearch({ from: addActivityFormRoute.id });
    const validator = useGetValidatorSchema(formValidationType);
    return validator;
};
