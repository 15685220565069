import { MessageDescriptor } from "react-intl";
import activityL1Messages from "./messages.activityL1";
import activityL2Messages from "./messages.activityL2";

export const getMessageForActivity = (activity: string): MessageDescriptor | null => {
    switch (activity) {
        case "gaseousFuels":
            return activityL1Messages.gaseousFuels;
        case "liquidFuels":
            return activityL1Messages.liquidFuels;
        case "solidFuels":
            return activityL1Messages.solidFuels;
        case "biofuel":
            return activityL1Messages.biofuel;
        case "biomass":
            return activityL1Messages.biomass;
        case "biogas":
            return activityL1Messages.biogas;
        case "carbonDioxideCo2":
            return activityL1Messages.carbonDioxideCo2;
        case "methaneCh4":
            return activityL1Messages.methaneCh4;
        case "nitrousOxideN2o":
            return activityL1Messages.nitrousOxideN2o;
        case "nitrogenTrifluorideNf3":
            return activityL1Messages.nitrogenTrifluorideNf3;
        case "sulphurHexafluorideSf6":
            return activityL1Messages.sulphurHexafluorideSf6;
        case "smallCar":
            return activityL1Messages.smallCar;
        case "mediumCar":
            return activityL1Messages.mediumCar;
        case "largeCar":
            return activityL1Messages.largeCar;
        case "averageCar":
            return activityL1Messages.averageCar;
        case "hotelStay":
            return activityL1Messages.hotelStay;
        case "largeMotorbike":
            return activityL1Messages.largeMotorbike;
        case "mediumMotorbike":
            return activityL1Messages.mediumMotorbike;
        case "smallMotorbike":
            return activityL1Messages.smallMotorbike;
        case "averageMotorbike":
            return activityL1Messages.averageMotorbike;
        case "hgvs17Tonnes":
            return activityL1Messages.hgvs17Tonnes;
        case "hgvs7.5Tonnes17Tonnes":
            return activityL1Messages["hgvs7.5Tonnes17Tonnes"];
        case "hgvs3.533t":
            return activityL1Messages["hgvs3.533t"];
        case "hgvs3.57.5Tonnes":
            return activityL1Messages["hgvs3.57.5Tonnes"];
        case "hgvs33t":
            return activityL1Messages.hgvs33t;
        case "refrigeratedHgvs17Tonnes":
            return activityL1Messages.refrigeratedHgvs17Tonnes;
        case "refrigeratedHgvs3.57.5Tonnes":
            return activityL1Messages["refrigeratedHgvs3.57.5Tonnes"];
        case "refrigeratedHgvs7.5Tonnes17Tonnes":
            return activityL1Messages["refrigeratedHgvs7.5Tonnes17Tonnes"];
        case "refrigeratedHgvs3.533t":
            return activityL1Messages["refrigeratedHgvs3.533t"];
        case "refrigeratedHgvs33t":
            return activityL1Messages.refrigeratedHgvs33t;
        case "vans1.305To1.74Tonnes":
            return activityL1Messages["vans1.305To1.74Tonnes"];
        case "vans1.74To3.5Tonnes":
            return activityL1Messages["vans1.74To3.5Tonnes"];
        case "vansUpTo1.305Tonnes":
            return activityL1Messages["vansUpTo1.305Tonnes"];
        case "flights":
            return activityL1Messages.flights;
        case "landTravel":
            return activityL1Messages.landTravel;
        case "seaTravel":
            return activityL1Messages.seaTravel;
        case "homeOffice":
            return activityL1Messages.homeOffice;
        case "buildingMaterials":
            return activityL1Messages.buildingMaterials;
        case "clothingAndFootwear":
            return activityL1Messages.clothingAndFootwear;
        case "electricalEquipment":
            return activityL1Messages.electricalEquipment;
        case "fabricatedMetalProducts":
            return activityL1Messages.fabricatedMetalProducts;
        case "foodAndDrink":
            return activityL1Messages.foodAndDrink;
        case "glass":
            return activityL1Messages.glass;
        case "metals":
            return activityL1Messages.metals;
        case "organic":
            return activityL1Messages.organic;
        case "paperAndCardboard":
            return activityL1Messages.paperAndCardboard;
        case "petroleumProducts":
            return activityL1Messages.petroleumProducts;
        case "water":
            return activityL1Messages.water;
        case "cargoShip":
            return activityL1Messages.cargoShip;
        case "freightFlights":
            return activityL1Messages.freightFlights;
        case "freightTrain":
            return activityL1Messages.freightTrain;
        case "hgvs":
            return activityL1Messages.hgvs;
        case "refrigeratedHgvs":
            return activityL1Messages.refrigeratedHgvs;
        case "seaTanker":
            return activityL1Messages.seaTanker;
        case "vans":
            return activityL1Messages.vans;
        case "refuse":
            return activityL1Messages.refuse;
        case "wasteWater":
            return activityL1Messages.wasteWater;
        case "butane":
            return activityL2Messages.butane;
        case "cng":
            return activityL2Messages.cng;
        case "lng":
            return activityL2Messages.lng;
        case "lpg":
            return activityL2Messages.lpg;
        case "naturalGas":
            return activityL2Messages.naturalGas;
        case "naturalGas100MineralBlend":
            return activityL2Messages.naturalGas100MineralBlend;
        case "otherPetroleumGas":
            return activityL2Messages.otherPetroleumGas;
        case "propane":
            return activityL2Messages.propane;
        case "aviationSpirit":
            return activityL2Messages.aviationSpirit;
        case "burningOil":
            return activityL2Messages.burningOil;
        case "dieselAverageBiofuelBlend":
            return activityL2Messages.dieselAverageBiofuelBlend;
        case "diesel100MineralDiesel":
            return activityL2Messages.diesel100MineralDiesel;
        case "fuelOil":
            return activityL2Messages.fuelOil;
        case "gasOil":
            return activityL2Messages.gasOil;
        case "lubricants":
            return activityL2Messages.lubricants;
        case "naphtha":
            return activityL2Messages.naphtha;
        case "petrolAverageBiofuelBlend":
            return activityL2Messages.petrolAverageBiofuelBlend;
        case "petrol100MineralPetrol":
            return activityL2Messages.petrol100MineralPetrol;
        case "processedFuelOilsResidualOil":
            return activityL2Messages.processedFuelOilsResidualOil;
        case "processedFuelOilsDistillateOil":
            return activityL2Messages.processedFuelOilsDistillateOil;
        case "refineryMiscellaneous":
            return activityL2Messages.refineryMiscellaneous;
        case "wasteOils":
            return activityL2Messages.wasteOils;
        case "marineGasOil":
            return activityL2Messages.marineGasOil;
        case "coalIndustrial":
            return activityL2Messages.coalIndustrial;
        case "coalElectricityGeneration":
            return activityL2Messages.coalElectricityGeneration;
        case "coalDomestic":
            return activityL2Messages.coalDomestic;
        case "cokingCoal":
            return activityL2Messages.cokingCoal;
        case "petroleumCoke":
            return activityL2Messages.petroleumCoke;
        case "coalElectricityGenerationHomeProducedCoalOnly":
            return activityL2Messages.coalElectricityGenerationHomeProducedCoalOnly;
        case "bioethanol":
            return activityL2Messages.bioethanol;
        case "biodieselMe":
            return activityL2Messages.biodieselMe;
        case "biomethaneCompressed":
            return activityL2Messages.biomethaneCompressed;
        case "biodieselMeFromUsedCookingOil":
            return activityL2Messages.biodieselMeFromUsedCookingOil;
        case "biodieselMeFromTallow":
            return activityL2Messages.biodieselMeFromTallow;
        case "biodieselHvo":
            return activityL2Messages.biodieselHvo;
        case "biopropane":
            return activityL2Messages.biopropane;
        case "bioPetrol":
            return activityL2Messages.bioPetrol;
        case "renewablePetrol":
            return activityL2Messages.renewablePetrol;
        case "offRoadBiodiesel":
            return activityL2Messages.offRoadBiodiesel;
        case "biomethaneLiquified":
            return activityL2Messages.biomethaneLiquified;
        case "methanolBio":
            return activityL2Messages.methanolBio;
        case "woodLogs":
            return activityL2Messages.woodLogs;
        case "woodChips":
            return activityL2Messages.woodChips;
        case "woodPellets":
            return activityL2Messages.woodPellets;
        case "grassstraw":
            return activityL2Messages.grassstraw;
        case "landfillGas":
            return activityL2Messages.landfillGas;
        case "petrol":
            return activityL2Messages.petrol;
        case "diesel":
            return activityL2Messages.diesel;
        case "hybrid":
            return activityL2Messages.hybrid;
        case "pluginHybrid":
            return activityL2Messages.pluginHybrid;
        case "unknown":
            return activityL2Messages.unknown;
        case "0Laden":
            return activityL2Messages["0Laden"];
        case "50Laden":
            return activityL2Messages["50Laden"];
        case "100Laden":
            return activityL2Messages["100Laden"];
        case "averageLaden":
            return activityL2Messages.averageLaden;
        case "domesticAveragePassenger":
            return activityL2Messages.domesticAveragePassenger;
        case "longhaulInternational3700kmAveragePassenger":
            return activityL2Messages.longhaulInternational3700kmAveragePassenger;
        case "longhaulInternational3700kmBusinessClass":
            return activityL2Messages.longhaulInternational3700kmBusinessClass;
        case "longhaulInternational3700kmEconomyClass":
            return activityL2Messages.longhaulInternational3700kmEconomyClass;
        case "longhaulInternational3700kmFirstClass":
            return activityL2Messages.longhaulInternational3700kmFirstClass;
        case "longhaulInternational3700kmPremiumEconomyClass":
            return activityL2Messages.longhaulInternational3700kmPremiumEconomyClass;
        case "shorthaulInternational3700kmAveragePassenger":
            return activityL2Messages.shorthaulInternational3700kmAveragePassenger;
        case "shorthaulInternational3700kmBusinessClass":
            return activityL2Messages.shorthaulInternational3700kmBusinessClass;
        case "shorthaulInternational3700kmEconomyClass":
            return activityL2Messages.shorthaulInternational3700kmEconomyClass;
        case "busCoach":
            return activityL2Messages.busCoach;
        case "busLocal":
            return activityL2Messages.busLocal;
        case "carAverageElectric":
            return activityL2Messages.carAverageElectric;
        case "carAverageIc":
            return activityL2Messages.carAverageIc;
        case "carLargeElectric":
            return activityL2Messages.carLargeElectric;
        case "carLargeIc":
            return activityL2Messages.carLargeIc;
        case "carMediumElectric":
            return activityL2Messages.carMediumElectric;
        case "carMediumIc":
            return activityL2Messages.carMediumIc;
        case "carSmallElectric":
            return activityL2Messages.carSmallElectric;
        case "carSmallIc":
            return activityL2Messages.carSmallIc;
        case "metroAndTram":
            return activityL2Messages.metroAndTram;
        case "motorbikeAverage":
            return activityL2Messages.motorbikeAverage;
        case "motorbikeLarge":
            return activityL2Messages.motorbikeLarge;
        case "motorbikeMedium":
            return activityL2Messages.motorbikeMedium;
        case "motorbikeSmall":
            return activityL2Messages.motorbikeSmall;
        case "railInternational":
            return activityL2Messages.railInternational;
        case "railNational":
            return activityL2Messages.railNational;
        case "taxiLarge":
            return activityL2Messages.taxiLarge;
        case "taxiRegular":
            return activityL2Messages.taxiRegular;
        case "ferryAveragePassenger":
            return activityL2Messages.ferryAveragePassenger;
        case "ferryCarPassenger":
            return activityL2Messages.ferryCarPassenger;
        case "ferryFootPassenger":
            return activityL2Messages.ferryFootPassenger;
        case "aggregatesClosedloopSource":
            return activityL2Messages.aggregatesClosedloopSource;
        case "aggregatesPrimaryMaterialProduction":
            return activityL2Messages.aggregatesPrimaryMaterialProduction;
        case "aggregatesReused":
            return activityL2Messages.aggregatesReused;
        case "asbestosPrimaryMaterialProduction":
            return activityL2Messages.asbestosPrimaryMaterialProduction;
        case "asphaltClosedloopSource":
            return activityL2Messages.asphaltClosedloopSource;
        case "asphaltPrimaryMaterialProduction":
            return activityL2Messages.asphaltPrimaryMaterialProduction;
        case "asphaltReused":
            return activityL2Messages.asphaltReused;
        case "averageConstructionPrimaryMaterialProduction":
            return activityL2Messages.averageConstructionPrimaryMaterialProduction;
        case "bricksPrimaryMaterialProduction":
            return activityL2Messages.bricksPrimaryMaterialProduction;
        case "concreteClosedloopSource":
            return activityL2Messages.concreteClosedloopSource;
        case "concretePrimaryMaterialProduction":
            return activityL2Messages.concretePrimaryMaterialProduction;
        case "insulationClosedloopSource":
            return activityL2Messages.insulationClosedloopSource;
        case "insulationPrimaryMaterialProduction":
            return activityL2Messages.insulationPrimaryMaterialProduction;
        case "plasterboardClosedloopSource":
            return activityL2Messages.plasterboardClosedloopSource;
        case "plasterboardPrimaryMaterialProduction":
            return activityL2Messages.plasterboardPrimaryMaterialProduction;
        case "woodClosedloopSource":
            return activityL2Messages.woodClosedloopSource;
        case "woodPrimaryMaterialProduction":
            return activityL2Messages.woodPrimaryMaterialProduction;
        case "woodReused":
            return activityL2Messages.woodReused;
        case "clothingPrimaryMaterialProduction":
            return activityL2Messages.clothingPrimaryMaterialProduction;
        case "clothingReused":
            return activityL2Messages.clothingReused;
        case "batteriesAlkalinePrimaryMaterialProduction":
            return activityL2Messages.batteriesAlkalinePrimaryMaterialProduction;
        case "batteriesLiIonPrimaryMaterialProduction":
            return activityL2Messages.batteriesLiIonPrimaryMaterialProduction;
        case "batteriesNimhPrimaryMaterialProduction":
            return activityL2Messages.batteriesNimhPrimaryMaterialProduction;
        case "fridgesAndFreezersPrimaryMaterialProduction":
            return activityL2Messages.fridgesAndFreezersPrimaryMaterialProduction;
        case "itPrimaryMaterialProduction":
            return activityL2Messages.itPrimaryMaterialProduction;
        case "largeElectricalItemsPrimaryMaterialProduction":
            return activityL2Messages.largeElectricalItemsPrimaryMaterialProduction;
        case "smallElectricalItemsPrimaryMaterialProduction":
            return activityL2Messages.smallElectricalItemsPrimaryMaterialProduction;
        case "aluminiumCansAndFoilExcl.FormingClosedloopSource":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingClosedloopSource"];
        case "aluminiumCansAndFoilExcl.FormingPrimaryMaterialProduction":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingPrimaryMaterialProduction"];
        case "mixedCansClosedloopSource":
            return activityL2Messages.mixedCansClosedloopSource;
        case "mixedCansPrimaryMaterialProduction":
            return activityL2Messages.mixedCansPrimaryMaterialProduction;
        case "steelCansClosedloopSource":
            return activityL2Messages.steelCansClosedloopSource;
        case "steelCansPrimaryMaterialProduction":
            return activityL2Messages.steelCansPrimaryMaterialProduction;
        case "foodAndDrinkPrimaryMaterialProduction":
            return activityL2Messages.foodAndDrinkPrimaryMaterialProduction;
        case "glassClosedloopSource":
            return activityL2Messages.glassClosedloopSource;
        case "glassPrimaryMaterialProduction":
            return activityL2Messages.glassPrimaryMaterialProduction;
        case "metalsClosedloopSource":
            return activityL2Messages.metalsClosedloopSource;
        case "metalsPrimaryMaterialProduction":
            return activityL2Messages.metalsPrimaryMaterialProduction;
        case "scrapMetalClosedloopSource":
            return activityL2Messages.scrapMetalClosedloopSource;
        case "scrapMetalPrimaryMaterialProduction":
            return activityL2Messages.scrapMetalPrimaryMaterialProduction;
        case "compostDerivedFromFoodAndGardenWastePrimaryMaterialProduction":
            return activityL2Messages.compostDerivedFromFoodAndGardenWastePrimaryMaterialProduction;
        case "compostDerivedFromGardenWastePrimaryMaterialProduction":
            return activityL2Messages.compostDerivedFromGardenWastePrimaryMaterialProduction;
        case "soilsClosedloopSource":
            return activityL2Messages.soilsClosedloopSource;
        case "cardboardClosedloopSource":
            return activityL2Messages.cardboardClosedloopSource;
        case "cardboardPrimaryMaterialProduction":
            return activityL2Messages.cardboardPrimaryMaterialProduction;
        case "mixedClosedloopSource":
            return activityL2Messages.mixedClosedloopSource;
        case "mixedPrimaryMaterialProduction":
            return activityL2Messages.mixedPrimaryMaterialProduction;
        case "paperClosedloopSource":
            return activityL2Messages.paperClosedloopSource;
        case "paperPrimaryMaterialProduction":
            return activityL2Messages.paperPrimaryMaterialProduction;
        case "averagePlasticFilmClosedloopSource":
            return activityL2Messages.averagePlasticFilmClosedloopSource;
        case "averagePlasticFilmPrimaryMaterialProduction":
            return activityL2Messages.averagePlasticFilmPrimaryMaterialProduction;
        case "averagePlasticRigidClosedloopSource":
            return activityL2Messages.averagePlasticRigidClosedloopSource;
        case "averagePlasticRigidPrimaryMaterialProduction":
            return activityL2Messages.averagePlasticRigidPrimaryMaterialProduction;
        case "averagePlasticsClosedloopSource":
            return activityL2Messages.averagePlasticsClosedloopSource;
        case "averagePlasticsPrimaryMaterialProduction":
            return activityL2Messages.averagePlasticsPrimaryMaterialProduction;
        case "hdpeIncl.FormingClosedloopSource":
            return activityL2Messages["hdpeIncl.FormingClosedloopSource"];
        case "hdpeIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["hdpeIncl.FormingPrimaryMaterialProduction"];
        case "ldpeAndLldpeIncl.FormingClosedloopSource":
            return activityL2Messages["ldpeAndLldpeIncl.FormingClosedloopSource"];
        case "ldpeAndLldpeIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["ldpeAndLldpeIncl.FormingPrimaryMaterialProduction"];
        case "mineralOilClosedloopSource":
            return activityL2Messages.mineralOilClosedloopSource;
        case "mineralOilPrimaryMaterialProduction":
            return activityL2Messages.mineralOilPrimaryMaterialProduction;
        case "petIncl.FormingClosedloopSource":
            return activityL2Messages["petIncl.FormingClosedloopSource"];
        case "petIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["petIncl.FormingPrimaryMaterialProduction"];
        case "ppIncl.FormingClosedloopSource":
            return activityL2Messages["ppIncl.FormingClosedloopSource"];
        case "ppIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["ppIncl.FormingPrimaryMaterialProduction"];
        case "psIncl.FormingClosedloopSource":
            return activityL2Messages["psIncl.FormingClosedloopSource"];
        case "psIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["psIncl.FormingPrimaryMaterialProduction"];
        case "pvcIncl.FormingClosedloopSource":
            return activityL2Messages["pvcIncl.FormingClosedloopSource"];
        case "pvcIncl.FormingPrimaryMaterialProduction":
            return activityL2Messages["pvcIncl.FormingPrimaryMaterialProduction"];
        case "tyresPrimaryMaterialProduction":
            return activityL2Messages.tyresPrimaryMaterialProduction;
        case "tyresReused":
            return activityL2Messages.tyresReused;
        case "waterSupply":
            return activityL2Messages.waterSupply;
        case "bulkCarrier09999Dwt":
            return activityL2Messages.bulkCarrier09999Dwt;
        case "bulkCarrier1000034999Dwt":
            return activityL2Messages.bulkCarrier1000034999Dwt;
        case "bulkCarrier100000199999Dwt":
            return activityL2Messages.bulkCarrier100000199999Dwt;
        case "bulkCarrier200000Dwt":
            return activityL2Messages.bulkCarrier200000Dwt;
        case "bulkCarrier3500059999Dwt":
            return activityL2Messages.bulkCarrier3500059999Dwt;
        case "bulkCarrier6000099999Dwt":
            return activityL2Messages.bulkCarrier6000099999Dwt;
        case "bulkCarrierAverage":
            return activityL2Messages.bulkCarrierAverage;
        case "containerShip0999Teu":
            return activityL2Messages.containerShip0999Teu;
        case "containerShip10001999Teu":
            return activityL2Messages.containerShip10001999Teu;
        case "containerShip20002999Teu":
            return activityL2Messages.containerShip20002999Teu;
        case "containerShip30004999Teu":
            return activityL2Messages.containerShip30004999Teu;
        case "containerShip50007999Teu":
            return activityL2Messages.containerShip50007999Teu;
        case "containerShip8000Teu":
            return activityL2Messages.containerShip8000Teu;
        case "containerShipAverage":
            return activityL2Messages.containerShipAverage;
        case "generalCargo04999Dwt100Teu":
            return activityL2Messages.generalCargo04999Dwt100Teu;
        case "generalCargo04999Dwt":
            return activityL2Messages.generalCargo04999Dwt;
        case "generalCargo10000Dwt100Teu":
            return activityL2Messages.generalCargo10000Dwt100Teu;
        case "generalCargo10000Dwt":
            return activityL2Messages.generalCargo10000Dwt;
        case "generalCargo50009999Dwt100Teu":
            return activityL2Messages.generalCargo50009999Dwt100Teu;
        case "generalCargo50009999Dwt":
            return activityL2Messages.generalCargo50009999Dwt;
        case "generalCargoAverage":
            return activityL2Messages.generalCargoAverage;
        case "largeRopaxFerryAverage":
            return activityL2Messages.largeRopaxFerryAverage;
        case "refrigeratedCargoAllDwt":
            return activityL2Messages.refrigeratedCargoAllDwt;
        case "roroferry01999Lm":
            return activityL2Messages.roroferry01999Lm;
        case "roroferry2000Lm":
            return activityL2Messages.roroferry2000Lm;
        case "roroferryAverage":
            return activityL2Messages.roroferryAverage;
        case "vehicleTransport03999Ceu":
            return activityL2Messages.vehicleTransport03999Ceu;
        case "vehicleTransport4000Ceu":
            return activityL2Messages.vehicleTransport4000Ceu;
        case "vehicleTransportAverage":
            return activityL2Messages.vehicleTransportAverage;
        case "domestic":
            return activityL2Messages.domestic;
        case "longhaulInternational3700km":
            return activityL2Messages.longhaulInternational3700km;
        case "shorthaulInternational3700km":
            return activityL2Messages.shorthaulInternational3700km;
        case "articulated3.533Tonnes0Laden":
            return activityL2Messages["articulated3.533Tonnes0Laden"];
        case "articulated3.533Tonnes100Laden":
            return activityL2Messages["articulated3.533Tonnes100Laden"];
        case "articulated3.533Tonnes50Laden":
            return activityL2Messages["articulated3.533Tonnes50Laden"];
        case "articulated3.533TonnesAverageLaden":
            return activityL2Messages["articulated3.533TonnesAverageLaden"];
        case "articulated33Tonnes0Laden":
            return activityL2Messages.articulated33Tonnes0Laden;
        case "articulated33Tonnes100Laden":
            return activityL2Messages.articulated33Tonnes100Laden;
        case "articulated33Tonnes50Laden":
            return activityL2Messages.articulated33Tonnes50Laden;
        case "articulated33TonnesAverageLaden":
            return activityL2Messages.articulated33TonnesAverageLaden;
        case "averageHgv0Laden":
            return activityL2Messages.averageHgv0Laden;
        case "averageHgv100Laden":
            return activityL2Messages.averageHgv100Laden;
        case "averageHgv50Laden":
            return activityL2Messages.averageHgv50Laden;
        case "averageHgvAverageLaden":
            return activityL2Messages.averageHgvAverageLaden;
        case "rigid17Tonnes0Laden":
            return activityL2Messages.rigid17Tonnes0Laden;
        case "rigid17Tonnes100Laden":
            return activityL2Messages.rigid17Tonnes100Laden;
        case "rigid17Tonnes50Laden":
            return activityL2Messages.rigid17Tonnes50Laden;
        case "rigid17TonnesAverageLaden":
            return activityL2Messages.rigid17TonnesAverageLaden;
        case "rigid3.57.5Tonnes0Laden":
            return activityL2Messages["rigid3.57.5Tonnes0Laden"];
        case "rigid3.57.5Tonnes100Laden":
            return activityL2Messages["rigid3.57.5Tonnes100Laden"];
        case "rigid3.57.5Tonnes50Laden":
            return activityL2Messages["rigid3.57.5Tonnes50Laden"];
        case "rigid3.57.5TonnesAverageLaden":
            return activityL2Messages["rigid3.57.5TonnesAverageLaden"];
        case "rigid7.517Tonnes0Laden":
            return activityL2Messages["rigid7.517Tonnes0Laden"];
        case "rigid7.517Tonnes100Laden":
            return activityL2Messages["rigid7.517Tonnes100Laden"];
        case "rigid7.517Tonnes50Laden":
            return activityL2Messages["rigid7.517Tonnes50Laden"];
        case "rigid7.517TonnesAverageLaden":
            return activityL2Messages["rigid7.517TonnesAverageLaden"];
        case "chemicalTanker04999Dwt":
            return activityL2Messages.chemicalTanker04999Dwt;
        case "chemicalTanker1000019999Dwt":
            return activityL2Messages.chemicalTanker1000019999Dwt;
        case "chemicalTanker20000Dwt":
            return activityL2Messages.chemicalTanker20000Dwt;
        case "chemicalTanker50009999Dwt":
            return activityL2Messages.chemicalTanker50009999Dwt;
        case "chemicalTankerAverage":
            return activityL2Messages.chemicalTankerAverage;
        case "crudeTanker09999Dwt":
            return activityL2Messages.crudeTanker09999Dwt;
        case "crudeTanker1000059999Dwt":
            return activityL2Messages.crudeTanker1000059999Dwt;
        case "crudeTanker120000199999Dwt":
            return activityL2Messages.crudeTanker120000199999Dwt;
        case "crudeTanker200000Dwt":
            return activityL2Messages.crudeTanker200000Dwt;
        case "crudeTanker6000079999Dwt":
            return activityL2Messages.crudeTanker6000079999Dwt;
        case "crudeTanker80000119999Dwt":
            return activityL2Messages.crudeTanker80000119999Dwt;
        case "crudeTankerAverage":
            return activityL2Messages.crudeTankerAverage;
        case "lngTanker0199999M3":
            return activityL2Messages.lngTanker0199999M3;
        case "lngTanker200000M3":
            return activityL2Messages.lngTanker200000M3;
        case "lngTankerAverage":
            return activityL2Messages.lngTankerAverage;
        case "lpgTanker049999M3":
            return activityL2Messages.lpgTanker049999M3;
        case "lpgTanker50000M3":
            return activityL2Messages.lpgTanker50000M3;
        case "lpgTankerAverage":
            return activityL2Messages.lpgTankerAverage;
        case "productsTanker04999Dwt":
            return activityL2Messages.productsTanker04999Dwt;
        case "productsTanker1000019999Dwt":
            return activityL2Messages.productsTanker1000019999Dwt;
        case "productsTanker2000059999Dwt":
            return activityL2Messages.productsTanker2000059999Dwt;
        case "productsTanker50009999Dwt":
            return activityL2Messages.productsTanker50009999Dwt;
        case "productsTanker60000Dwt":
            return activityL2Messages.productsTanker60000Dwt;
        case "productsTankerAverage":
            return activityL2Messages.productsTankerAverage;
        case "1.305To1.74TonnesDiesel":
            return activityL2Messages["1.305To1.74TonnesDiesel"];
        case "1.305To1.74TonnesElectric":
            return activityL2Messages["1.305To1.74TonnesElectric"];
        case "1.305To1.74TonnesPetrol":
            return activityL2Messages["1.305To1.74TonnesPetrol"];
        case "1.74To3.5TonnesDiesel":
            return activityL2Messages["1.74To3.5TonnesDiesel"];
        case "1.74To3.5TonnesElectric":
            return activityL2Messages["1.74To3.5TonnesElectric"];
        case "1.74To3.5TonnesPetrol":
            return activityL2Messages["1.74To3.5TonnesPetrol"];
        case "averageSizeDiesel":
            return activityL2Messages.averageSizeDiesel;
        case "averageSizeElectric":
            return activityL2Messages.averageSizeElectric;
        case "averageSizePetrol":
            return activityL2Messages.averageSizePetrol;
        case "upTo1.305TonnesDiesel":
            return activityL2Messages["upTo1.305TonnesDiesel"];
        case "upTo1.305TonnesElectric":
            return activityL2Messages["upTo1.305TonnesElectric"];
        case "upTo1.305TonnesPetrol":
            return activityL2Messages["upTo1.305TonnesPetrol"];
        case "aggregatesClosedloop":
            return activityL2Messages.aggregatesClosedloop;
        case "aggregatesLandfill":
            return activityL2Messages.aggregatesLandfill;
        case "aggregatesOpenloop":
            return activityL2Messages.aggregatesOpenloop;
        case "asbestosLandfill":
            return activityL2Messages.asbestosLandfill;
        case "asphaltClosedloop":
            return activityL2Messages.asphaltClosedloop;
        case "asphaltLandfill":
            return activityL2Messages.asphaltLandfill;
        case "asphaltOpenloop":
            return activityL2Messages.asphaltOpenloop;
        case "averageConstructionClosedloop":
            return activityL2Messages.averageConstructionClosedloop;
        case "averageConstructionCombustion":
            return activityL2Messages.averageConstructionCombustion;
        case "averageConstructionOpenloop":
            return activityL2Messages.averageConstructionOpenloop;
        case "bricksLandfill":
            return activityL2Messages.bricksLandfill;
        case "bricksOpenloop":
            return activityL2Messages.bricksOpenloop;
        case "concreteClosedloop":
            return activityL2Messages.concreteClosedloop;
        case "concreteLandfill":
            return activityL2Messages.concreteLandfill;
        case "concreteOpenloop":
            return activityL2Messages.concreteOpenloop;
        case "insulationClosedloop":
            return activityL2Messages.insulationClosedloop;
        case "insulationLandfill":
            return activityL2Messages.insulationLandfill;
        case "plasterboardClosedloop":
            return activityL2Messages.plasterboardClosedloop;
        case "plasterboardLandfill":
            return activityL2Messages.plasterboardLandfill;
        case "woodClosedloop":
            return activityL2Messages.woodClosedloop;
        case "woodCombustion":
            return activityL2Messages.woodCombustion;
        case "woodComposting":
            return activityL2Messages.woodComposting;
        case "woodLandfill":
            return activityL2Messages.woodLandfill;
        case "clothingClosedloop":
            return activityL2Messages.clothingClosedloop;
        case "clothingCombustion":
            return activityL2Messages.clothingCombustion;
        case "clothingLandfill":
            return activityL2Messages.clothingLandfill;
        case "batteriesLandfill":
            return activityL2Messages.batteriesLandfill;
        case "batteriesOpenloop":
            return activityL2Messages.batteriesOpenloop;
        case "weeeFridgesAndFreezersLandfill":
            return activityL2Messages.weeeFridgesAndFreezersLandfill;
        case "weeeFridgesAndFreezersOpenloop":
            return activityL2Messages.weeeFridgesAndFreezersOpenloop;
        case "weeeLargeCombustion":
            return activityL2Messages.weeeLargeCombustion;
        case "weeeLargeLandfill":
            return activityL2Messages.weeeLargeLandfill;
        case "weeeLargeOpenloop":
            return activityL2Messages.weeeLargeOpenloop;
        case "weeeMixedCombustion":
            return activityL2Messages.weeeMixedCombustion;
        case "weeeMixedLandfill":
            return activityL2Messages.weeeMixedLandfill;
        case "weeeMixedOpenloop":
            return activityL2Messages.weeeMixedOpenloop;
        case "weeeSmallCombustion":
            return activityL2Messages.weeeSmallCombustion;
        case "weeeSmallLandfill":
            return activityL2Messages.weeeSmallLandfill;
        case "weeeSmallOpenloop":
            return activityL2Messages.weeeSmallOpenloop;
        case "aluminiumCansAndFoilExcl.FormingClosedloop":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingClosedloop"];
        case "aluminiumCansAndFoilExcl.FormingCombustion":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingCombustion"];
        case "aluminiumCansAndFoilExcl.FormingLandfill":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingLandfill"];
        case "aluminiumCansAndFoilExcl.FormingOpenloop":
            return activityL2Messages["aluminiumCansAndFoilExcl.FormingOpenloop"];
        case "mixedCansClosedloop":
            return activityL2Messages.mixedCansClosedloop;
        case "mixedCansCombustion":
            return activityL2Messages.mixedCansCombustion;
        case "mixedCansLandfill":
            return activityL2Messages.mixedCansLandfill;
        case "mixedCansOpenloop":
            return activityL2Messages.mixedCansOpenloop;
        case "steelCansClosedloop":
            return activityL2Messages.steelCansClosedloop;
        case "steelCansCombustion":
            return activityL2Messages.steelCansCombustion;
        case "steelCansLandfill":
            return activityL2Messages.steelCansLandfill;
        case "steelCansOpenloop":
            return activityL2Messages.steelCansOpenloop;
        case "glassClosedloop":
            return activityL2Messages.glassClosedloop;
        case "glassCombustion":
            return activityL2Messages.glassCombustion;
        case "glassLandfill":
            return activityL2Messages.glassLandfill;
        case "glassOpenloop":
            return activityL2Messages.glassOpenloop;
        case "metalsClosedloop":
            return activityL2Messages.metalsClosedloop;
        case "metalsLandfill":
            return activityL2Messages.metalsLandfill;
        case "scrapMetalClosedloop":
            return activityL2Messages.scrapMetalClosedloop;
        case "scrapMetalCombustion":
            return activityL2Messages.scrapMetalCombustion;
        case "scrapMetalLandfill":
            return activityL2Messages.scrapMetalLandfill;
        case "scrapMetalOpenloop":
            return activityL2Messages.scrapMetalOpenloop;
        case "foodAndDrinkWasteAnaerobicDigestion":
            return activityL2Messages.foodAndDrinkWasteAnaerobicDigestion;
        case "foodAndDrinkWasteCombustion":
            return activityL2Messages.foodAndDrinkWasteCombustion;
        case "foodAndDrinkWasteComposting":
            return activityL2Messages.foodAndDrinkWasteComposting;
        case "foodAndDrinkWasteLandfill":
            return activityL2Messages.foodAndDrinkWasteLandfill;
        case "gardenWasteAnaerobicDigestion":
            return activityL2Messages.gardenWasteAnaerobicDigestion;
        case "gardenWasteCombustion":
            return activityL2Messages.gardenWasteCombustion;
        case "gardenWasteComposting":
            return activityL2Messages.gardenWasteComposting;
        case "gardenWasteLandfill":
            return activityL2Messages.gardenWasteLandfill;
        case "mixedFoodAndGardenWasteAnaerobicDigestion":
            return activityL2Messages.mixedFoodAndGardenWasteAnaerobicDigestion;
        case "mixedFoodAndGardenWasteCombustion":
            return activityL2Messages.mixedFoodAndGardenWasteCombustion;
        case "mixedFoodAndGardenWasteComposting":
            return activityL2Messages.mixedFoodAndGardenWasteComposting;
        case "mixedFoodAndGardenWasteLandfill":
            return activityL2Messages.mixedFoodAndGardenWasteLandfill;
        case "soilsClosedloop":
            return activityL2Messages.soilsClosedloop;
        case "soilsLandfill":
            return activityL2Messages.soilsLandfill;
        case "booksClosedloop":
            return activityL2Messages.booksClosedloop;
        case "booksCombustion":
            return activityL2Messages.booksCombustion;
        case "booksLandfill":
            return activityL2Messages.booksLandfill;
        case "cardboardClosedloop":
            return activityL2Messages.cardboardClosedloop;
        case "cardboardCombustion":
            return activityL2Messages.cardboardCombustion;
        case "cardboardComposting":
            return activityL2Messages.cardboardComposting;
        case "cardboardLandfill":
            return activityL2Messages.cardboardLandfill;
        case "mixedClosedloop":
            return activityL2Messages.mixedClosedloop;
        case "mixedCombustion":
            return activityL2Messages.mixedCombustion;
        case "mixedComposting":
            return activityL2Messages.mixedComposting;
        case "mixedLandfill":
            return activityL2Messages.mixedLandfill;
        case "paperClosedloop":
            return activityL2Messages.paperClosedloop;
        case "paperCombustion":
            return activityL2Messages.paperCombustion;
        case "paperComposting":
            return activityL2Messages.paperComposting;
        case "paperLandfill":
            return activityL2Messages.paperLandfill;
        case "averagePlasticFilmClosedloop":
            return activityL2Messages.averagePlasticFilmClosedloop;
        case "averagePlasticFilmCombustion":
            return activityL2Messages.averagePlasticFilmCombustion;
        case "averagePlasticFilmLandfill":
            return activityL2Messages.averagePlasticFilmLandfill;
        case "averagePlasticFilmOpenloop":
            return activityL2Messages.averagePlasticFilmOpenloop;
        case "averagePlasticRigidClosedloop":
            return activityL2Messages.averagePlasticRigidClosedloop;
        case "averagePlasticRigidCombustion":
            return activityL2Messages.averagePlasticRigidCombustion;
        case "averagePlasticRigidLandfill":
            return activityL2Messages.averagePlasticRigidLandfill;
        case "averagePlasticRigidOpenloop":
            return activityL2Messages.averagePlasticRigidOpenloop;
        case "averagePlasticsClosedloop":
            return activityL2Messages.averagePlasticsClosedloop;
        case "averagePlasticsCombustion":
            return activityL2Messages.averagePlasticsCombustion;
        case "averagePlasticsLandfill":
            return activityL2Messages.averagePlasticsLandfill;
        case "averagePlasticsOpenloop":
            return activityL2Messages.averagePlasticsOpenloop;
        case "hdpeIncl.FormingClosedloop":
            return activityL2Messages["hdpeIncl.FormingClosedloop"];
        case "hdpeIncl.FormingCombustion":
            return activityL2Messages["hdpeIncl.FormingCombustion"];
        case "hdpeIncl.FormingLandfill":
            return activityL2Messages["hdpeIncl.FormingLandfill"];
        case "hdpeIncl.FormingOpenloop":
            return activityL2Messages["hdpeIncl.FormingOpenloop"];
        case "ldpeAndLldpeIncl.FormingClosedloop":
            return activityL2Messages["ldpeAndLldpeIncl.FormingClosedloop"];
        case "ldpeAndLldpeIncl.FormingCombustion":
            return activityL2Messages["ldpeAndLldpeIncl.FormingCombustion"];
        case "ldpeAndLldpeIncl.FormingLandfill":
            return activityL2Messages["ldpeAndLldpeIncl.FormingLandfill"];
        case "ldpeAndLldpeIncl.FormingOpenloop":
            return activityL2Messages["ldpeAndLldpeIncl.FormingOpenloop"];
        case "mineralOilClosedloop":
            return activityL2Messages.mineralOilClosedloop;
        case "mineralOilCombustion":
            return activityL2Messages.mineralOilCombustion;
        case "petIncl.FormingClosedloop":
            return activityL2Messages["petIncl.FormingClosedloop"];
        case "petIncl.FormingCombustion":
            return activityL2Messages["petIncl.FormingCombustion"];
        case "petIncl.FormingLandfill":
            return activityL2Messages["petIncl.FormingLandfill"];
        case "petIncl.FormingOpenloop":
            return activityL2Messages["petIncl.FormingOpenloop"];
        case "ppIncl.FormingClosedloop":
            return activityL2Messages["ppIncl.FormingClosedloop"];
        case "ppIncl.FormingCombustion":
            return activityL2Messages["ppIncl.FormingCombustion"];
        case "ppIncl.FormingLandfill":
            return activityL2Messages["ppIncl.FormingLandfill"];
        case "ppIncl.FormingOpenloop":
            return activityL2Messages["ppIncl.FormingOpenloop"];
        case "psIncl.FormingClosedloop":
            return activityL2Messages["psIncl.FormingClosedloop"];
        case "psIncl.FormingCombustion":
            return activityL2Messages["psIncl.FormingCombustion"];
        case "psIncl.FormingLandfill":
            return activityL2Messages["psIncl.FormingLandfill"];
        case "psIncl.FormingOpenloop":
            return activityL2Messages["psIncl.FormingOpenloop"];
        case "pvcIncl.FormingClosedloop":
            return activityL2Messages["pvcIncl.FormingClosedloop"];
        case "pvcIncl.FormingCombustion":
            return activityL2Messages["pvcIncl.FormingCombustion"];
        case "pvcIncl.FormingLandfill":
            return activityL2Messages["pvcIncl.FormingLandfill"];
        case "pvcIncl.FormingOpenloop":
            return activityL2Messages["pvcIncl.FormingOpenloop"];
        case "tyresClosedloop":
            return activityL2Messages.tyresClosedloop;
        case "commercialAndIndustrialWasteCombustion":
            return activityL2Messages.commercialAndIndustrialWasteCombustion;
        case "commercialAndIndustrialWasteLandfill":
            return activityL2Messages.commercialAndIndustrialWasteLandfill;
        case "householdResidualWasteCombustion":
            return activityL2Messages.householdResidualWasteCombustion;
        case "householdResidualWasteLandfill":
            return activityL2Messages.householdResidualWasteLandfill;
        case "waterTreatment":
            return activityL2Messages.waterTreatment;
        // Cases and messages should be made for all L2 activities
        default:
            return null;
    }
};
