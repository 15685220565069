import { overviewRoute } from "@/Router/Overview";
import { Filter } from "@ignite-analytics/icons";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { FilterModal } from "./FilterModal";

interface Props {
    buttonType?: "primary" | "secondary";
}

export const FilterOverview: React.FC<Props> = ({ buttonType = "primary" }) => {
    const { year, tagFilters, sources, viewType, businessUnitIds, spendCategoryIds, supplierIds } = useSearch({
        from: overviewRoute.fullPath,
    });
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const navigate = useNavigate();

    const badgeNumber = useMemo(() => {
        const tagNum = tagFilters ? tagFilters.length : 0;
        return (
            tagNum +
            Number(Boolean(supplierIds?.length)) +
            Number(Boolean(sources?.length)) +
            Number(Boolean(businessUnitIds?.length)) +
            Number(Boolean(spendCategoryIds?.length))
        );
    }, [tagFilters, supplierIds?.length, sources?.length, businessUnitIds?.length, spendCategoryIds?.length]);

    const onButtonClick = useCallback(() => {
        navigate({
            to: overviewRoute.fullPath,
            search: (prev) => ({
                ...prev,
                viewType,
                year,
            }),
        });
        setFilterModalOpen(true);
    }, [navigate, viewType, year]);

    const button = useMemo(() => {
        if (buttonType === "primary") {
            return (
                <Button
                    startIcon={<Filter />}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ minWidth: 100 }}
                    onClick={onButtonClick}
                >
                    <FormattedMessage defaultMessage="Filter" description="Filter button" />
                </Button>
            );
        }
        return (
            <Tooltip title={<FormattedMessage defaultMessage="Filter" description="Filter button" />}>
                <IconButton color="secondary" size="small" onClick={onButtonClick}>
                    <Filter />
                </IconButton>
            </Tooltip>
        );
    }, [buttonType, onButtonClick]);

    return (
        <>
            <Badge color="primary" badgeContent={badgeNumber}>
                {button}
            </Badge>
            <FilterModal open={filterModalOpen} onClose={() => setFilterModalOpen(false)} />
        </>
    );
};

export { SourceFilterType } from "./constants";
