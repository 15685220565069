import { RouterType } from "@/types";
import { TRPCClientErrorLike } from "@trpc/client";

/* Query-fn that you can provide to useQuery to avoid retries when you didn't have access */
export const retry = (attempts: number, error: TRPCClientErrorLike<RouterType>) => {
    const errorCode = error.data?.httpStatus ?? 500;
    if (errorCode === 401 || errorCode === 403) return false;
    return true;
};

export const goToSupplier = (supplierId: string) => {
    const newPath = `${window.location.pathname}/supplier-page/${supplierId}/overview`;
    window.postMessage(
        {
            type: "route-change",
            path: newPath,
            from: window.location.pathname,
        },
        window.location.origin
    );
};

/**
 * Helper function to get the current hostname of the window
 * @returns {string} - The current hostname of the window - which is ignite or igniteprocurement in production, and undefined locally
 */
export const getCurrentDomain = () => {
    return window.location.hostname.split("app.")[1]?.split(".")?.[0];
};
