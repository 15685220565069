import { createRoute } from "@tanstack/react-router";
import { newEmissionIntensitiesRootRoute } from "../root";
import { StepLayout } from "./Layout";

const emissionIntensitiesStepLayout = createRoute({
    getParentRoute: () => newEmissionIntensitiesRootRoute,
    component: StepLayout,
    /**
     * Pathless routes are supposed to use the ID attribute, which should result in a PATHLESS route, but this doesn't
     * work. I'm super frustrated with this, so I'm adding a path here to make the router work.
     */
    path: "/step",
});

export { emissionIntensitiesStepLayout };
