import { ShapeIcon } from "@ignite-analytics/components";
import { Check } from "@ignite-analytics/icons";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
};

const RedirectDialog: React.FC<Props> = ({ open }) => {
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                <Stack spacing={2} alignItems="center">
                    <ShapeIcon color="success" size="medium">
                        <Check />
                    </ShapeIcon>
                    <Typography sx={{ mt: 2.5 }} variant="textLg" fontWeight={500} textAlign="center">
                        <FormattedMessage
                            defaultMessage="You're all done"
                            description="Header for dialog when successfully submitting an emission intensity for the first time"
                        />
                    </Typography>
                    <Typography variant="textSm" color={(t) => t.palette.text.textBody}>
                        <FormattedMessage
                            defaultMessage="You'll soon be redirect to the overview."
                            description="Subheader for dialog when successfully submitting an emission intensity for the first time"
                        />
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export { RedirectDialog };
