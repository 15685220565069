import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { DUMMY_DEPARTMENT_ID } from "@ignite-analytics/department";

const cacheKey = (tenant: string) => `DISPLAY_CURRENCY_${tenant}`;

const validateCurrency = (currency: string | null | undefined): string | undefined => {
    if (!currency) return undefined;
    if (currency.length !== 3) return undefined;
    return currency;
};

interface ContextValue {
    currency: string | undefined;
}

const Context = createContext<ContextValue | null>(null);

interface Props {
    children: ReactNode;
    tenant: string;
}

export const CompanyCurrencyProvider: React.FC<Props> = ({ children, tenant }) => {
    const [fetchedCurrency, setFetchedCurrency] = useState<string | undefined>(undefined);
    const cachedCurrency = validateCurrency(
        localStorage.getItem(cacheKey(tenant)) ??
            // REACT_APP_LOCAL_DEV_CURRENCY is set for local development (not production)
            process.env.REACT_APP_LOCAL_DEV_CURRENCY
    );

    useEffect(() => {
        if (!tenant) return;
        // We are in local development mode, so we don't need to fetch the currency
        if (process.env.REACT_APP_LOCAL_DEV_CURRENCY) return;
        let tenantHasChanged = false;
        fetch(`${process.env.VITE_API_URL}/r/${tenant}/departments/${DUMMY_DEPARTMENT_ID}/department-settings/`, {
            credentials: "include",
        }).then(async (response) => {
            const data = await response.json();
            const currency = data.display_currency;

            if (!currency) return;
            if (tenantHasChanged) return;

            localStorage.setItem(cacheKey(tenant), currency);
            setFetchedCurrency(currency);
        });
        return () => {
            tenantHasChanged = true;
        };
    }, [tenant]);

    const currency = fetchedCurrency || cachedCurrency;
    const value = useMemo(() => ({ currency }), [currency]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCompanyCurrency = (): string | undefined => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useCompanyCurrency must be used within a CompanyCurrencyProvider");
    }
    return context.currency;
};
