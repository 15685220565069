import { EmissionsFilter, SourceAndActivityFilter } from "@/__generated__/graphql";
import { OverviewFilters } from "@/Containers/LandingPage/hooks";

export const mapOverviewFiltersToGraphqlEmissionFilter = (filters: OverviewFilters): Required<EmissionsFilter> => {
    const sourceFilter: SourceAndActivityFilter = {
        sourceIdFilter: filters.sources?.filter((obj) => obj.filterType === "source").map(({ id }) => id),
        activityFilterActive: filters.sources?.some((filter) => filter.filterType === "activityData") ?? false,
    };
    const hasSourceFilter = sourceFilter.activityFilterActive || sourceFilter.sourceIdFilter?.length;
    return {
        businessUnitIds: filters.businessUnitIds,
        supplierIds: filters.supplierIds,
        spendCategoryIds: filters.spendCategoryIds,
        tagFilters: filters.tagFilters,
        dateFilter: filters.dateFilter,
        sourceFilter: hasSourceFilter ? sourceFilter : null,
    };
};
