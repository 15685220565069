import { useMemo } from "react";
import { ScopeCategoryType } from "router";
import { useGetScope1MessagesRecord } from "./useGetScope1Messages";
import { useGetScope2MessagesRecord } from "./useGetScope2Messages";
import { useGetScope3MessagesRecord } from "./useGetScope3Messages";

export const useGetAllScopeMessages = (): Record<
    ScopeCategoryType,
    { header: string; subHeader: string; longSubHeader: string }
> => {
    const scope1Messages = useGetScope1MessagesRecord();
    const scope2Messages = useGetScope2MessagesRecord();
    const scope3Messages = useGetScope3MessagesRecord();

    return useMemo(
        () => ({
            ...scope1Messages,
            ...scope2Messages,
            ...scope3Messages,
        }),
        [scope1Messages, scope2Messages, scope3Messages]
    );
};
