import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { EmissionFactors } from "@/Containers/EmissionFactors";
import { TableOutlet } from "@/Containers/EmissionFactors/TableOutlet";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { appLayoutRoute } from "../AppLayoutRoute";

export const emissionFactorsWrapperRoute = createRoute({
    getParentRoute: () => appLayoutRoute,
    path: "/emission-factors",
    component: EmissionFactors,
    errorComponent: ErrorBoundary,
});

export const emissionFactorsRoute = createRoute({
    getParentRoute: () => emissionFactorsWrapperRoute,
    path: "/$factorType",
    parseParams: ({ factorType }) => {
        const factor = z.enum(["spend", "activity"]).catch("activity").parse(factorType);
        return {
            factorType: factor,
        };
    },
    component: TableOutlet,
    errorComponent: ErrorBoundary,
});
