import { addDataSourceTypeRoute } from "@/Router/DataPreferencesRoute";
import { Plus } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";
import { DataSourcesPageHeader } from "../DataSourcesPageHeader";

export const EmptyState: React.FC = () => {
    return (
        <>
            <DataSourcesPageHeader />
            <Stack direction="column" alignItems="center" justifyContent="center" padding={10}>
                <Typography variant="h6">
                    <FormattedMessage defaultMessage="Uh-oh, no data sources added" description="Empty state title" />
                </Typography>
                <Typography variant="textMd" color="grey">
                    <FormattedMessage
                        defaultMessage="Let's kickstart your emissions journey by adding your first data source!"
                        description="Empty state subtitle"
                    />
                </Typography>
                <br />
                <Link
                    to={addDataSourceTypeRoute.fullPath}
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <Button color="primary" size="large" data-testid="add-data-source-button">
                        <Plus />
                        <FormattedMessage defaultMessage="Add data source" description="Add data source button title" />
                    </Button>
                </Link>
            </Stack>
        </>
    );
};
