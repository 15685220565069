import { z } from "zod";
import { ScopeCategory, isScope1Type, isScope2Type, isScope3Type, otherConnection, spendConnection } from "router";

export type SpendReturnValue = Omit<z.infer<typeof spendConnection>, "createdBy">;
export type SpendFormReturn = Omit<SpendReturnValue, "name" | "tableId">;

export type OtherReturnValue = Omit<z.infer<typeof otherConnection>, "createdBy">;
export type OtherFormReturn = Omit<OtherReturnValue, "name" | "tableId">;

export function getScopeByCategory(scope: ScopeCategory) {
    if (isScope1Type(scope)) {
        return "scope1";
    }
    if (isScope2Type(scope)) {
        return "scope2";
    }
    if (isScope3Type(scope)) {
        return "scope3";
    }
    throw new Error("Scope not found");
}
