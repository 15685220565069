import { Stack } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { kgCO2e, tCO2e } from "router";
import { DefaultFormType } from "../../types";
import { ValuePickerComponent } from "./valuePickerComponent";

export const ValuePicker = () => {
    const { formatMessage } = useIntl();

    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();

    const locationBasedValue = watch("locationBasedValue");
    const regularValue = watch("value");
    const unitOfMeasurement = watch("unitOfMeasurement");
    const scopeCategory = watch("scopeType");
    if (
        unitOfMeasurement &&
        [tCO2e, kgCO2e].includes(unitOfMeasurement) &&
        scopeCategory === "electricityConsumption"
    ) {
        return (
            <Stack gap={0.5} direction="row">
                <ValuePickerComponent
                    setValue={(value) => {
                        setValue("value", value);
                    }}
                    value={regularValue}
                    errorMessage={errors.value?.message}
                    label={formatMessage({
                        defaultMessage: "Market-based emissions",
                        description: "Market based label used for emissions",
                    })}
                />
                <ValuePickerComponent
                    value={locationBasedValue}
                    setValue={(value) => {
                        setValue("locationBasedValue", value);
                    }}
                    dataTestId="location-based-value-input"
                    errorMessage={errors.locationBasedValue?.message}
                    label={formatMessage({
                        defaultMessage: "Location-based emissions",
                        description: "Location based label used for emissions",
                    })}
                />
            </Stack>
        );
    }
    return (
        <ValuePickerComponent
            setValue={(value) => {
                setValue("value", value);
            }}
            value={regularValue}
            errorMessage={errors.value?.message}
            label={formatMessage({
                defaultMessage: "Value",
                description: "Header for value column on uploaded table",
            })}
        />
    );
};
