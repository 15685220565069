import { AlertIconWrapper } from "@/lib/svg/alertIconWrapper";
import { Check } from "@ignite-analytics/icons";
import { Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

export const LearnMoreFinished = () => {
    const navigate = useNavigate();

    return (
        <Stack alignItems="center" gap={2} width="100%" padding={2}>
            <AlertIconWrapper color="success">
                <Check />
            </AlertIconWrapper>
            <Stack gap={0.5} alignItems="center">
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage defaultMessage="Thank you!" />
                </Typography>
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage defaultMessage="We'll be in touch shortly." />
                </Typography>
            </Stack>
            <Button
                fullWidth
                onClick={() => {
                    navigate({
                        search: (prev) => ({
                            ...prev,
                            viewType: undefined,
                        }),
                    });
                }}
            >
                <FormattedMessage defaultMessage="Close" description="Close button" />
            </Button>
        </Stack>
    );
};
