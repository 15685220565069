import { TooltipIfTruncated } from "@/Components/TooltipIfTruncated";
import { pixelsToRem } from "@/lib/accessibility";
import { Search } from "@ignite-analytics/icons";
import {
    Checkbox,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFilterToggler } from "../../../../filterContext";

type Option = {
    label: string;
    value: string;
    children?: Option[];
    parent?: Option;
};

interface Props {
    options: Option[];
    currentFilter: string[] | null;
    onChange: (filter: string[] | null) => void;
}

const getOptionsPerLevel = (options: Option[], level = 1): Partial<Record<number, Option[]>> => {
    const nextLevelOptions = options.flatMap(
        (option) => option.children?.map((child) => ({ ...child, parent: option })) ?? []
    );
    return {
        [level]: options,
        ...(nextLevelOptions.length ? getOptionsPerLevel(nextLevelOptions, level + 1) : undefined),
    };
};

const getAncestors = (option: Option): Option[] => {
    if (!option.parent) {
        return [];
    }
    return [...getAncestors(option.parent), option.parent];
};

export const GroupFilterOptions: React.FC<Props> = ({ options, currentFilter, onChange }) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const optionsPerLevel = useMemo(() => getOptionsPerLevel(options), [options]);

    const getInitialLevel = useCallback(() => {
        if (!currentFilter?.length) {
            return 1;
        }

        const optionsOverlapWithFilter = (opts: Option[] | undefined) =>
            opts?.some((option) => currentFilter.includes(option.value));

        let selectedLevel = 1;
        Object.entries(optionsPerLevel).forEach(([level, opts]) => {
            if (optionsOverlapWithFilter(opts)) {
                selectedLevel = Number(level);
            }
        });

        return selectedLevel;
    }, [currentFilter, optionsPerLevel]);

    const [level, setLevel] = React.useState(getInitialLevel);
    const { formatMessage } = useIntl();

    const toggleValue = useFilterToggler(currentFilter, onChange);

    const relevantOptions = useMemo(() => {
        const optionOrParentMatches = (opt: Option) =>
            opt.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (opt.parent && optionOrParentMatches(opt.parent));

        return optionsPerLevel[level]?.filter(optionOrParentMatches) ?? [];
    }, [optionsPerLevel, level, searchTerm]);

    return (
        <Stack>
            <Stack direction="row" alignItems="end" columnGap={2} paddingBottom={1}>
                <Stack>
                    <FormControl>
                        <InputLabel id="level-selector-label">
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="Level"
                                    description="Level selector label in group filters"
                                />
                            </Typography>
                        </InputLabel>

                        <Select
                            labelId="level-selector-label"
                            value={level}
                            onChange={(e) => {
                                onChange(null);
                                setLevel(Number(e.target.value));
                            }}
                            size="small"
                            sx={{ height: pixelsToRem(40), width: "100px" }}
                            autoWidth
                        >
                            {Object.keys(optionsPerLevel)
                                .sort()
                                .map((l) => (
                                    <MenuItem value={l} key={l} sx={{ width: "100px" }}>
                                        {l}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Stack>

                <TextField
                    fullWidth
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={formatMessage({
                        defaultMessage: "Search",
                        description: "Search",
                    })}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            {relevantOptions?.map((opt) => {
                const selected = currentFilter?.includes(opt.value) ?? false;
                const ancestorLabel = getAncestors(opt)
                    .map((parent) => parent.label)
                    .join(" → ");
                return (
                    <MenuItem key={opt.value} onClick={() => toggleValue(opt.value)}>
                        <ListItemIcon>
                            <Checkbox size="small" edge="start" checked={selected} />
                        </ListItemIcon>
                        <ListItemText
                            secondary={
                                ancestorLabel && (
                                    <TooltipIfTruncated title={ancestorLabel}>{ancestorLabel}</TooltipIfTruncated>
                                )
                            }
                            primaryTypographyProps={{ textOverflow: "ellipsis", overflow: "hidden" }}
                            secondaryTypographyProps={{ textOverflow: "ellipsis", overflow: "hidden" }}
                        >
                            <TooltipIfTruncated title={opt.label}>{opt.label}</TooltipIfTruncated>
                        </ListItemText>
                    </MenuItem>
                );
            })}
        </Stack>
    );
};
