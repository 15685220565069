import { trpc } from "@/lib/trpc/client";
import { formatNumber } from "@/lib/primitives/numbers";
import { FormFilter } from "@/Components/FormV2/types";
import React from "react";
import { Alert } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { hasValue } from "utils";
import { DeprecatedEmissionsFilter } from "router";
import { DataTakenIntoAccount } from "../../types";

type CustomFilter = { field: string; values: string[] };

type Props = {
    filterList?: FormFilter[];
    dataTakenIntoAccount: DataTakenIntoAccount;
};

export const RemovedFromSource: React.FC<Props> = ({ filterList, dataTakenIntoAccount }) => {
    const { supplier, from, to, tags } = dataTakenIntoAccount;
    const customFilter: CustomFilter[] | undefined = filterList
        ?.map((filterValue) => {
            if (filterValue.filterField && filterValue.value) {
                return { field: filterValue.filterField.id, values: [filterValue.value.value] };
            }
            return null;
        })
        .filter(hasValue);
    const tagFilter: DeprecatedEmissionsFilter["tag"] = {
        tags: tags?.map((tag) => ({ tagId: tag.id, values: [tag.value.value] })),
    };
    const removed = trpc.totalRemovedWhenAddingActivity.useQuery(
        {
            from,
            to,
            supplierId: supplier?.id,
            customFilter,
            tagFilter,
        },
        {
            enabled: !!from && !!to,
        }
    );
    if (removed.isSuccess && removed.data?.totalRemoved === 0) {
        return (
            <Alert severity="warning">
                <FormattedMessage
                    defaultMessage="You will exclude {zero} out of {total}. With the current conditions, you will not exclude anything from the spend source. It might be that one of the conditions added makes it so that no procurement data is found. Review them to make sure."
                    description="Formatting of removed from connection data"
                    values={{
                        // eslint-disable-next-line react/jsx-no-literals
                        total: <b> {formatNumber((removed.data?.totalBeforeRemoving ?? 0) * 1000, 2)} kg CO₂e </b>,
                        zero: <b> {0} </b>,
                    }}
                />
            </Alert>
        );
    }
    return (
        <Alert severity="info">
            {removed.isSuccess ? (
                <FormattedMessage
                    defaultMessage="You will exclude {removed} out of a total of {total} from the spend source"
                    description="Formatting of removed from connection data"
                    values={{
                        // eslint-disable-next-line react/jsx-no-literals
                        total: <b> {formatNumber((removed.data?.totalBeforeRemoving ?? 0) * 1000, 2)} kg CO₂e </b>,
                        removed: <b> {formatNumber((removed.data?.totalRemoved ?? 0) * 1000, 2)} </b>,
                    }}
                />
            ) : (
                <FormattedMessage defaultMessage="Calculating removed emissions..." />
            )}
        </Alert>
    );
};
