import { Button, Card, CardActions, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const LoadingEmissionIntensityCard: React.FC = () => {
    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={<Skeleton variant="text" width="7.5rem" />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text.textSecondary,
                }}
            />
            <CardContent component={Stack} direction="row" justifyContent="center" alignItems="center" height="100%">
                <Skeleton variant="rounded" height="100%" width="100%" />
            </CardContent>
            <CardActions>
                <Skeleton variant="rounded">
                    <Button color="secondary" size="xsmall" disabled>
                        <FormattedMessage
                            defaultMessage="Edit data"
                            description="Label for the button to edit data in the emission intensity card on the overview page"
                        />
                    </Button>
                </Skeleton>
            </CardActions>
        </Card>
    );
};

export { LoadingEmissionIntensityCard };
