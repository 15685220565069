import {
    Contributor,
    ContributorPickerProps,
    ContributorTypePicker,
} from "@/Containers/LandingPage/components/ContributorTypePicker";
import { CARBON_EVENTS } from "@/lib/tracking";
import { topContributorsRoute } from "@/Router/TopContributors";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React, { useMemo } from "react";

export type ToolbarContributorPickerProps = {
    options: ContributorPickerProps["options"];
};
export const ToolbarContributorPicker: React.FC<ToolbarContributorPickerProps> = ({ options }) => {
    const { contributorType, contributorTypeLevel } = useSearch({ from: topContributorsRoute.id });

    const defaultValue = useMemo<Contributor | undefined>(
        () => ({
            contributorType: contributorType ?? "suppliers",
            level: contributorTypeLevel,
        }),
        [contributorType, contributorTypeLevel]
    );

    const navigate = useNavigate();
    const handleContributorTypeSelected = (contributor: Contributor) => {
        CARBON_EVENTS.TOP_CONTRIBUTORS_CHANGED(
            {
                page: "TopContributors",
                component: "ContributorTypePicker",
            },
            { switchedTo: contributor.contributorType, level: contributor.level }
        );
        navigate({
            to: ".",
            search: (current) => ({
                ...current,
                contributorType: contributor.contributorType,
                contributorTypeLevel: contributor.level,
            }),
        });
    };

    return (
        <ContributorTypePicker options={options} defaultValue={defaultValue} onSelect={handleContributorTypeSelected} />
    );
};
