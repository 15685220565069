import { useGetMessages } from "../../hooks";
import { SourceConfig } from "../../types";
import { ListActionItem } from "../ListActionItem";

type Props = {
    config: SourceConfig & { type: "emissionsByScope" };
};
export const DrillDownChartListAction: React.FC<Props> = ({ config }) => {
    const messages = useGetMessages(config);
    const { scope, drilldown } = config;

    if (!messages?.drillDownInGraph || !drilldown) return null;
    return (
        <ListActionItem
            onClick={() => {
                drilldown(scope);
            }}
            text={messages?.drillDownInGraph}
        />
    );
};
