import { ScopeCategory } from "router";

export const CATEGORY_WITHOUT_L1: ScopeCategory[] = [
    "otherScope1",
    "electricityConsumption",
    "districtHeating",
    "capitalGoods",
    "upstreamLeasedAssets",
    "downstreamTransportationAndDistribution",
    "processingOfSoldProducts",
    "endOfLifeTreatmentOfSoldProducts",
    "downstreamLeasedAssets",
    "franchises",
    "investments",
];
