import { FormFilter } from "@/Components/FormV2/types";
import { FormattedMessage } from "react-intl";
import { Stack, Checkbox, Typography, Divider } from "@mui/material";
import { FieldOption, FilterField } from "router";
import { FilterPicker } from "../../Components/Filter";
import { RemovedFromSource } from "../../Components/RemovedFromSource";
import { DataTakenIntoAccountComponent } from "../../Components/DataTakenIntoAccount";
import { DataTakenIntoAccount } from "../../types";

type Props = {
    filter: FormFilter[];
    changeDisabled: () => void;
    disabled: boolean;
    addFilter: (id: string) => void;
    removeFilter: (id: string) => void;
    changeFilter: (id: string, filter: { filterField: FilterField; value?: FieldOption }) => void;
    dataTakenIntoAccount: DataTakenIntoAccount;
};

export const MainContent: React.FC<Props> = ({
    filter,
    disabled,
    changeDisabled,
    addFilter,
    changeFilter,
    removeFilter,
    dataTakenIntoAccount,
}) => {
    return (
        <Stack spacing={2}>
            {!disabled && (
                <Stack gap={2} divider={<Divider />}>
                    <DataTakenIntoAccountComponent dataTakenIntoAccount={dataTakenIntoAccount} />
                    <Stack gap={1} paddingTop={1}>
                        <FilterPicker
                            selectedFilters={filter ?? []}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            changeFilter={changeFilter}
                            optionFilter={{
                                filterList: filter,
                                dataTakenIntoAccount,
                            }}
                        />
                        <RemovedFromSource dataTakenIntoAccount={dataTakenIntoAccount} filterList={filter ?? []} />
                    </Stack>
                </Stack>
            )}
            <Stack direction="row" justifyContent="end" alignItems="center" gap={1}>
                <Checkbox
                    onChange={() => {
                        changeDisabled();
                    }}
                    checked={!disabled}
                />
                <Typography variant="textMd" fontWeight={500}>
                    <FormattedMessage
                        defaultMessage="I wish to exclude spend based estimates"
                        description="Remove emissions from spend description"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};
