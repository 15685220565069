import { useAlert } from "@/Providers/Alerts";
import { filesServiceHttp } from "@/lib/http";
import { CARBON_EVENTS } from "@/lib/tracking";
import { Skeleton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useIntl } from "react-intl";
import { hasValue } from "utils";
import { UploadFileInfo } from "./FileListItem/UploadFileInfo.model";
import { UploadFileStatus } from "./FileListItem/UploadFileStatus.enum";
import { FileUpload } from "./dumb";

interface FileUploadProps {
    referenceId: string;
    viewOnly?: boolean;
}

type Attachment = {
    id: string;
    fileName: string;
    url: string;
    createdAt: number;
    metadata?: { [key: string]: string };
};

const mapAttachmentToUploadFileInfo = (attachment: Attachment): UploadFileInfo => {
    return {
        id: attachment.id,
        name: attachment.fileName,
        status: UploadFileStatus.Selected,
        file: undefined,
        message: undefined,
        progress: undefined,
        url: attachment.url,
    };
};

const fetchFiles = async (referenceId: string) => {
    return filesServiceHttp
        .get<Attachment[]>(`/api/files/reference/${referenceId}`, { headers: { "content-type": "application/json" } })
        .then((res) => {
            const data = res.data ?? [];
            // eslint-disable-next-line fp/no-mutating-methods
            return data.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)).map(mapAttachmentToUploadFileInfo);
        });
};

export const updateFiles = (referenceId: string) => async (files: UploadFileInfo[]) => {
    const notEmptyFiles = files.map((file) => file.file).filter(hasValue);
    return Promise.all(
        notEmptyFiles.map((file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("referenceObjectId", referenceId);
            return filesServiceHttp.post<UploadFileInfo>(`/api/files`, formData, {
                headers: { "content-type": "multipart/form-data" },
            });
        })
    );
};

const FileUploadSmartComponent: React.FC<FileUploadProps> = ({ referenceId, viewOnly }: FileUploadProps) => {
    const { alertUser } = useAlert();
    const queryClient = useQueryClient();
    const { formatMessage } = useIntl();

    const {
        data: attachments,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ["files", referenceId],
        queryFn: () => fetchFiles(referenceId),
        onError: () => {
            alertUser({
                title: formatMessage({
                    defaultMessage: "Error getting files",
                    description: "Error uploading file message",
                }),
                value: formatMessage({
                    defaultMessage: "Something went wrong, please retry later",
                    description: "Something went wrong, please retry later",
                }),
                severity: "error",
            });
        },
    });
    const { mutate, isLoading: pendingUpdate } = useMutation({
        mutationFn: updateFiles(referenceId),
        onError: () => {
            alertUser({
                title: formatMessage({
                    defaultMessage: "Error adding file",
                    description: "Error getting file message",
                }),
                value: "",
                severity: "error",
            });
        },

        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ["files", referenceId] });
        },
    });

    const onFileAdded = (files: UploadFileInfo[]) => {
        CARBON_EVENTS.FILE_UPLOADED(
            { page: "ActivityForm", component: "ActivityDocsAndNotes" },
            { emissionActivityId: referenceId }
        ).track();
        mutate(files);
    };

    const onFileRemoved = (file: UploadFileInfo) => {
        filesServiceHttp
            .delete(`/api/files/${file.id}`)
            .then(() => refetch())
            .catch((err) => {
                alertUser({
                    title: formatMessage({
                        defaultMessage: "Error removing file",
                        description: "Error removing file message",
                    }),
                    value: err.response?.data ?? err.message,
                    severity: "error",
                });
            });
    };
    return (
        <>
            {isLoading && (
                <>
                    <Skeleton height="80px" animation="wave" />
                    <Skeleton height="80px" animation="wave" />
                </>
            )}
            {!isLoading && (
                <FileUpload
                    disabled={viewOnly}
                    pendingUpdates={pendingUpdate}
                    onFilesSelect={onFileAdded}
                    files={attachments}
                    onFileRemove={onFileRemoved}
                />
            )}
        </>
    );
};

export default FileUploadSmartComponent;
