import { getYear } from "@/Containers/LandingPage/hooks";
import { z } from "zod";

export const searchParamsSchema = z.object({
    year: z.number().optional().catch(getYear()),
    tagFilters: z
        .array(
            z.object({
                tagId: z.string(),
                values: z.array(z.string()),
            })
        )
        /**
         * This is a breaking change to existing search parameters, to avoid breaking existing links we
         * handle this gracefully by just defaulting to undefined if an invalid value exists.
         */
        .optional()
        .catch(undefined),
    sources: z.array(z.string()).optional().catch(undefined),
    businessUnitIds: z.array(z.string()).optional().catch(undefined),
    spendCategoryIds: z.array(z.string()).optional().catch(undefined),
    supplierIds: z.array(z.string()).optional().catch(undefined),
    scope: z.enum(["1", "2", "3"]).optional(),
    electricity: z.enum(["marketBased", "locationBased"]).optional(),
});
