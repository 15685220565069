import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { DataColumn } from "../interfaces";

interface Props {
    label: string;
    onChange: (value: string | null) => void;
    errorMessage?: string;
    value: string | null;
    dataColumns: DataColumn[];
    type: DataColumn["type"];
}
export const SupplierColumnSelect: React.FC<Props> = ({ label, onChange, errorMessage, value, dataColumns }) => {
    const activeColumn = dataColumns.find((column) => column.dataColumnId === value) ?? null;
    const filteredColumns = dataColumns;
    return (
        <Autocomplete
            size="small"
            value={activeColumn}
            options={filteredColumns}
            isOptionEqualToValue={(option, value_) => option.dataColumnId === value_.dataColumnId}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => onChange(newValue?.dataColumnId ?? null)}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
