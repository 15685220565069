/* eslint-disable jsx-a11y/anchor-is-valid */
import type {} from "@ignite-analytics/theme";
import { Theme } from "@mui/material";
import React from "react";
import { PieLabelRenderProps, Text } from "recharts";

export interface PointData {
    name: string;
}

function getLabelWidth(options: {
    rotated: boolean;
    innerRadius: number | string | undefined;
    outerRadius: number | string | undefined;
}): number {
    const defaultWidth = 150;
    if (!options.rotated) {
        return defaultWidth;
    }

    let inner: number | undefined;
    let outer: number | undefined;
    if (typeof options.innerRadius === "number") {
        inner = options.innerRadius;
    } else if (typeof options.innerRadius === "string") {
        inner = Number.parseInt(options.innerRadius, 10);
    }
    if (typeof options.outerRadius === "number") {
        outer = options.outerRadius;
    } else if (typeof options.outerRadius === "string") {
        outer = Number.parseInt(options.outerRadius, 10);
    }

    if (inner === undefined || outer === undefined) {
        return defaultWidth;
    }
    return outer - inner;
}

const RADIAN = Math.PI / 180;
const CustomLabel: React.FC<PieLabelRenderProps & { theme: Theme; fill: string }> = ({
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    payload,
    onClick,
    cx,
    cy,
    theme,
}) => {
    if (!percent || percent < 0.02) {
        return null;
    }

    let maxLines = 2;
    if (percent < 0.08) {
        maxLines = 1;
    }

    const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
    const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
    const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

    const rotation = midAngle > -90 && midAngle < 90 ? -midAngle : 180 - midAngle;
    const shouldRotate = percent < 0.1;
    const pointData = payload.payload as PointData;
    const label = pointData.name;

    const width = getLabelWidth({ rotated: shouldRotate, innerRadius, outerRadius });
    return (
        <Text
            cursor="pointer"
            fontFamily={theme.typography.textSm.fontFamily}
            fontSize={theme.typography.textSm.fontSize}
            lineHeight="1em"
            fontWeight={400}
            onClick={onClick}
            maxLines={maxLines}
            verticalAnchor="middle"
            x={x}
            y={y}
            fill={theme.palette.getContrastText(payload.fill)}
            textAnchor="middle"
            dominantBaseline="middle"
            angle={shouldRotate ? rotation : 0}
            width={width}
        >
            {label}
        </Text>
    );
};

export { CustomLabel };
