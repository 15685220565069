import { Dialog, DialogContent } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import { ModalHeader } from "../ModalHeader";
import { EditDataSourceDetails } from "../EditDataSourceDetails";

type Props = {
    edit?: boolean;
};
const DataSourceModal: React.FC<Props> = ({ edit }) => {
    return (
        // In order to avoid accidentally closing the modal, we are disabling click outside close
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Dialog open onClose={() => {}} fullWidth maxWidth="sm">
            <ModalHeader />
            {edit && (
                <DialogContent>
                    <EditDataSourceDetails />
                </DialogContent>
            )}
            <DialogContent>
                <Outlet />
            </DialogContent>
        </Dialog>
    );
};

export const EditDataSourceModal: React.FC = () => {
    return <DataSourceModal edit />;
};

export const AddDataSourceModal: React.FC = () => {
    return <DataSourceModal />;
};
