import {
    createCustomOptionsForTagRoute,
    createOptionsFromSourceForTagRoute,
    createTagNameRoute,
} from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    TextField,
    Button,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    DialogContent,
    DialogActions,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { z } from "zod";
import { useNavigate, useSearch } from "@tanstack/react-router";

const createNameSchema = z.object({
    name: z.string(),
    initialTagType: z.enum(["custom", "source"]),
});

type CreateNameType = z.infer<typeof createNameSchema>;

export const CreateTagName: React.FC = () => {
    const { name, initialOptionsType } = useSearch({ from: createTagNameRoute.id });
    const navigate = useNavigate();
    const trpcUtils = trpc.useContext();
    trpcUtils.addedConnections.prefetch();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateNameType>({
        resolver: zodResolver(createNameSchema),
        defaultValues: { name, initialTagType: initialOptionsType ?? "source" },
    });

    const onSubmit = async (tag: CreateNameType) => {
        if (tag.initialTagType === "custom") {
            navigate({
                to: createCustomOptionsForTagRoute.fullPath,
                search: {
                    name: tag.name,
                },
            });
        }
        if (tag.initialTagType === "source") {
            navigate({
                to: createOptionsFromSourceForTagRoute.fullPath,
                search: {
                    name: tag.name,
                },
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Stack gap={2} paddingTop={1}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <Stack gap={1}>
                                <Typography variant="subtitle2">
                                    <FormattedMessage
                                        defaultMessage="What type of tags are you creating"
                                        description="What type of tags are you creating"
                                    />
                                </Typography>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    autoFocus
                                    sx={{ width: 250 }}
                                    {...field}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </Stack>
                        )}
                    />
                    <Controller
                        name="initialTagType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Stack>
                                <Typography variant="subtitle2">
                                    <FormattedMessage
                                        defaultMessage="How do you want to create your initial tag values?"
                                        description="Tag type for the Tags component"
                                    />
                                </Typography>
                                <FormControl>
                                    <RadioGroup value={value} onChange={onChange}>
                                        <FormControlLabel
                                            value="custom"
                                            control={<Radio size="small" />}
                                            label="Custom values"
                                        />
                                        <FormControlLabel
                                            value="source"
                                            control={<Radio size="small" />}
                                            label="From data source"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="submit">
                    <FormattedMessage defaultMessage="Next" description="Create button for the Tags component" />
                </Button>
            </DialogActions>
        </form>
    );
};
