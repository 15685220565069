import { selectYearRoute } from "../pages";
import { enterInformationRoute } from "../pages/EnterInformation/route";
import { selectIntensityRoute } from "../pages/SelectIntensity/route";
import { selectLevelRoute } from "../pages/SelectLevel/route";
import { emissionIntensitiesStepLayout } from "./StepLayout/route";

const newEmissionIntensitiesRootRouteChildren = [
    selectIntensityRoute,
    emissionIntensitiesStepLayout.addChildren([selectLevelRoute, enterInformationRoute, selectYearRoute]),
];

export { newEmissionIntensitiesRootRoute } from "./root";
export { newEmissionIntensitiesRootRouteChildren };
export { emissionIntensitiesStepLayout };
