import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { CreateTagModal } from "@/Containers/Tags/CreateModal";
import { CreateTagName } from "@/Containers/Tags/CreateModal/CreateName";
import { CreateOptionsFromSource } from "@/Containers/Tags/CreateModal/CreateSourceOptions";
import { CreateCustomOptions } from "@/Containers/Tags/CreateModal/CustomOptions";
import { EditModal } from "@/Containers/Tags/EditModal";
import { EditCustomOptionsAndName } from "@/Containers/Tags/EditModal/EditCustomOptionsAndName";
import { EditOptionsFromSource } from "@/Containers/Tags/EditModal/EditSource";
import { createRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";
import { dataPreferenceTagsRoute } from "..";

export const overviewTagRoute = createRoute({
    getParentRoute: () => dataPreferenceTagsRoute,
    path: "/overview",
    component: () => <Outlet />,
    errorComponent: ErrorBoundary,
});
export const createTagRoute = createRoute({
    getParentRoute: () => dataPreferenceTagsRoute,
    path: "/create",
    component: CreateTagModal,
    errorComponent: ErrorBoundary,
});

export const createTagNameRoute = createRoute({
    getParentRoute: () => createTagRoute,
    path: "/name",
    component: CreateTagName,
    validateSearch: z.object({
        name: z.string().optional(),
        initialOptionsType: z.enum(["custom", "source"]).optional(),
    }),
    errorComponent: ErrorBoundary,
});

export const createCustomOptionsForTagRoute = createRoute({
    getParentRoute: () => createTagRoute,
    path: "/custom-options",
    component: CreateCustomOptions,
    validateSearch: z.object({
        name: z.string(),
    }),
    errorComponent: ErrorBoundary,
});

export const createOptionsFromSourceForTagRoute = createRoute({
    getParentRoute: () => createTagRoute,
    path: "/source-options",
    component: CreateOptionsFromSource,
    validateSearch: z.object({
        name: z.string(),
    }),
    errorComponent: ErrorBoundary,
});

export const editTagRoute = createRoute({
    getParentRoute: () => dataPreferenceTagsRoute,
    path: "/edit",
    component: EditModal,
    validateSearch: z.object({
        id: z.string(),
        name: z.string(),
    }),
    errorComponent: ErrorBoundary,
});

export const editCustomOptions = createRoute({
    getParentRoute: () => editTagRoute,
    path: "/custom-options",
    component: EditCustomOptionsAndName,
    errorComponent: ErrorBoundary,
});

export const editSourceOptionsForTagRoute = createRoute({
    getParentRoute: () => editTagRoute,
    path: "/sources/$sourceType/$sourceId",
    component: EditOptionsFromSource,
    errorComponent: ErrorBoundary,
});
