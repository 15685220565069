import { ButtonRow } from "@/Containers/Components/PostFormButton";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { PartialExcept } from "@ignite-analytics/general-tools";
import { Alert, Divider, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { EditSupplierConnection, InputSupplierConnection, inputSupplierConnection } from "router";
import { CurrencySelect } from "../DataTableConnectionForms/CurrencySelect";
import { SupplierColumnSelect } from "../DataTableConnectionForms/SupplierColumnSelect";
import { useMutationCleanupFunctions } from "../hooks";
import { FormSkeleton } from "./FormSkeleton";
import { SpendPeriodForm } from "./SpendPeriodForm";
import messages from "./SpendPeriodForm/messages";
import { defaultSupplierConnection } from "./constants";

interface InnerProps {
    existingSupplierConnection: PartialExcept<EditSupplierConnection, "id"> | null;
    postSubmit?: () => void;
}

const InnerSupplierForm: React.FC<InnerProps> = ({ existingSupplierConnection, postSubmit }) => {
    const { formatMessage } = useIntl();
    const { onCreateSuccess, onError, onEditSuccess } = useMutationCleanupFunctions();

    const columnsRequest = trpc.supplierColumns.useQuery();
    const createMutation = trpc.addSupplierConnection.useMutation({ onError, onSuccess: onCreateSuccess });
    const editMutation = trpc.editSupplierConnection.useMutation({ onError, onSuccess: onEditSuccess });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<InputSupplierConnection>({
        resolver: zodResolver(inputSupplierConnection),
        defaultValues: existingSupplierConnection ?? defaultSupplierConnection,
    });

    const onSubmit = handleSubmit((formData) => {
        if (existingSupplierConnection) {
            editMutation.mutate({ ...formData, id: existingSupplierConnection.id });
            CARBON_EVENTS.DATA_PREFERENCES_CONNECTION_EDITED(
                {
                    page: "DataPreferences",
                    component: "DataSources",
                },
                {
                    type: "supplier",
                    currency: formData.currency,
                    years: formData.periods.map((period) => period.year),
                }
            ).track();
            postSubmit?.();
            return;
        }
        createMutation.mutate(formData);
        CARBON_EVENTS.DATA_PREFERENCES_CONNECTION_ADDED(
            {
                page: "DataPreferences",
                component: "DataSources",
            },
            {
                type: "supplier",
                currency: formData.currency,
                years: formData.periods.map((period) => period.year),
            }
        );
        postSubmit?.();
    });

    if (columnsRequest.status === "loading") {
        return <FormSkeleton />;
    }

    if (columnsRequest.status === "error") {
        return (
            <Stack gap={2}>
                <FormattedMessage
                    defaultMessage="You already have a data source based on spend or supplier number, you can only add one"
                    description="Error message when trying to add a second connection"
                />
            </Stack>
        );
    }

    return (
        <form onSubmit={onSubmit}>
            <Stack gap={2}>
                <Controller
                    control={control}
                    name="naceColumn"
                    render={({ field: { onChange, value } }) => (
                        <SupplierColumnSelect
                            type="string"
                            value={value}
                            errorMessage={errors.naceColumn?.message}
                            label={formatMessage(messages.naceColumn)}
                            onChange={onChange}
                            dataColumns={columnsRequest.data.columns}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="currency"
                    render={({ field: { onChange, value } }) => (
                        <CurrencySelect
                            value={value}
                            onChange={onChange}
                            label={formatMessage(messages.currencyLabel)}
                        />
                    )}
                />
            </Stack>
            <Stack paddingTop={2}>
                <Divider />
                <SpendPeriodForm control={control} columns={columnsRequest.data.columns} />
            </Stack>
            <Stack paddingTop={1}>
                <ButtonRow />
            </Stack>
        </form>
    );
};

type Props = {
    postSubmit?: () => void;
};

export const SupplierForm: React.FC<Props> = ({ postSubmit }) => {
    const supplierConnection = trpc.supplierConnection.useQuery();

    if (supplierConnection.status === "loading") {
        return <FormSkeleton />;
    }

    if (supplierConnection.status === "error") {
        return (
            <Stack gap={2}>
                <Alert severity="success">
                    <FormattedMessage defaultMessage="An error occurred while checking if a supplier table connection has already been configured." />
                </Alert>
            </Stack>
        );
    }

    return <InnerSupplierForm existingSupplierConnection={supplierConnection.data} postSubmit={postSubmit} />;
};
