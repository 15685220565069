import { CARBON_EVENTS } from "@/lib/tracking";
import { editRoute } from "@/Router/DataInputRoute/EditRoute";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Outlet, useNavigate } from "@tanstack/react-router";

import { FormattedMessage } from "react-intl";
import { SideMenuAddActivities } from "./Components/SideMenu";

export const FormV2 = () => {
    const navigate = useNavigate();
    return (
        <>
            <Stack direction="row" py={3} justifyContent="space-between">
                <Typography variant="h6">
                    <FormattedMessage defaultMessage="Add a new activity" description="Add new activity header" />
                </Typography>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                        CARBON_EVENTS.VIEW_EMISSION_FACTORS_CLICK({
                            page: "ActivityForm",
                        }).track();
                        navigate({ to: editRoute.fullPath });
                    }}
                >
                    <FormattedMessage
                        defaultMessage="View activity log"
                        description="Button label for navigating to emission factor data base"
                    />
                </Button>
            </Stack>
            <Grid container spacing={2}>
                <Grid item sm={4} md={4} lg={3}>
                    <SideMenuAddActivities />
                </Grid>
                <Grid item sm={8} md={8} lg={9} alignItems="center" justifyContent="center">
                    <Outlet />
                </Grid>
            </Grid>
        </>
    );
};
