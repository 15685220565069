import { OverviewData } from "@/Containers/LandingPage/types";
import { overviewRoute } from "@/Router/Overview";
import { useGetScope2MessagesRecord } from "@/lib/commonMessages/useGetScope2Messages";
import { Stack, TableRow, Typography } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { scope2Types } from "router";
import { Cell } from "../Cell";
import { HeaderCell } from "../HeaderCell";

type Props = {
    data: Pick<OverviewData, "scope2">;
    sortedYears: number[];
    topValue: number;
};

export const Scope2Rows: React.FC<Props> = ({ data, sortedYears, topValue }) => {
    const { formatMessage } = useIntl();
    const marketBased = formatMessage({
        defaultMessage: "Market-based",
        description: "Market-based value",
    });
    const locationBased = formatMessage({
        defaultMessage: "Location-based",
        description: "Location-based value",
    });
    const scope2Messages = useGetScope2MessagesRecord();
    const { year: focusYear, electricity } = useSearch({ from: overviewRoute.fullPath });
    return (
        <>
            <TableRow>
                <HeaderCell rowSpan={4}>
                    <FormattedMessage defaultMessage="Scope 2" description="Scope 2" />
                </HeaderCell>
            </TableRow>
            {scope2Types.map((scope2Type) => {
                const highlightRowHeader = focusYear === sortedYears[0];
                const scope2Val = data.scope2[scope2Type] ? (data.scope2[scope2Type] ?? 0) : 0;
                return (
                    <TableRow key={scope2Type}>
                        <HeaderCell highlightRight={highlightRowHeader}>
                            {scope2Type === "electricityConsumption" ? (
                                <Stack>
                                    <Typography variant="caption"> {scope2Messages[scope2Type].header} </Typography>
                                    <Typography variant="caption" color="GrayText">
                                        {electricity === "locationBased" ? locationBased : marketBased}{" "}
                                    </Typography>
                                </Stack>
                            ) : (
                                scope2Messages[scope2Type].header
                            )}
                        </HeaderCell>
                        {sortedYears.map((year, index) => {
                            const highlight =
                                focusYear === year || !!(sortedYears.length && focusYear === sortedYears[index + 1]);
                            const val = scope2Val[year] ?? 0;
                            return (
                                <Cell
                                    year={year}
                                    category={scope2Type}
                                    key={year}
                                    highlightRight={highlight}
                                    value={val}
                                    topValue={topValue}
                                />
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};
