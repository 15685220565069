import { CARBON_EVENTS, TrackSource } from "@/lib/tracking";
import { goToSupplierProfile } from "@/lib/utils/utils";
import { useGetMessages } from "../../hooks";
import { SourceConfig } from "../../types";
import { ListActionItem } from "../ListActionItem";

type Props = {
    config: SourceConfig & { type: "supplier" };
    trackSource: TrackSource;
};
export const GoToSupplierCardListAction: React.FC<Props> = ({ config, trackSource }) => {
    const messages = useGetMessages(config);
    const { supplierId } = config;

    return messages?.viewSupplierCard ? (
        <ListActionItem
            onClick={() => {
                CARBON_EVENTS.GO_TO_SUPPLIER_CARD_CLICK(trackSource, { supplierId }).track();
                goToSupplierProfile(supplierId);
            }}
            text={messages.viewSupplierCard}
        />
    ) : null;
};
