import { getColor } from "@/lib/charts";
import { formatNumber } from "@/lib/primitives/numbers";
import { trpc } from "@/lib/trpc/client";
import { Alert, Box, Skeleton, Stack, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Filters } from "router";
import { useGetSearchParamFilters } from "../../../hooks";
import { BackButtonForChart } from "../../components/BackButtonForChart";
import { CustomLabel, PointData } from "../../EmissionsPerScopeCard/PieChart/Label";

interface Props {
    structure: "companyStructure" | "spendCategories";
}

interface ChartData extends PointData {
    name: string;
    value: number;
    entityId: string | null;
}

const addGroupFilter = (
    filters: Filters,
    groupFilterKey: "businessUnitIds" | "spendCategoryIds",
    groupFilter: string | undefined
): Filters => {
    if (!groupFilter) return filters;
    return {
        ...filters,
        [groupFilterKey]: [...(filters?.[groupFilterKey] ?? []), groupFilter],
    };
};

const EmissionsByClassificationStructureChart: React.FC<Props> = ({ structure }) => {
    const [level, setLevel] = useState(1);
    const { electricity, year, ...filters } = useGetSearchParamFilters();
    const intl = useIntl();
    const theme = useTheme();

    // The groupFilterPath is used to keep track of the path of groups that the user has drilled down on
    const [groupFilterPath, setGroupFilterPath] = useState<string[]>([]);

    // The last element of the groupFilterPath is the current group filter
    const groupFilter = useMemo(
        () => (groupFilterPath.length ? groupFilterPath.slice(-1)[0] : undefined),
        [groupFilterPath]
    );

    const groupFilterKey = structure === "companyStructure" ? "businessUnitIds" : "spendCategoryIds";

    const dataRequest = trpc.getAggregatedEmissions.useQuery(
        {
            level,
            groupBy: structure,
            electricity,
            filters: addGroupFilter(filters, groupFilterKey, groupFilter),
        },
        { retry: false }
    );

    const structureDescription = trpc.getClassificationStructureDescription.useQuery({ type: structure });

    const maxDepth = useMemo(() => {
        switch (structureDescription.status) {
            case "success":
                return structureDescription.data.depth;
            case "loading":
                return undefined;
            case "error":
                // Not serious enough to crash the app, but we should log it and treat it as an error
                Sentry.captureException(structureDescription.error, { tags: { app: "carbon-overview-app" } });
                return Infinity;
        }
    }, [structureDescription]);

    const atMaxDepth = useMemo(() => {
        if (maxDepth === undefined) {
            // If we don't know yet, prevent drill down until we do
            return true;
        }
        return level >= maxDepth;
    }, [level, maxDepth]);

    const handleClickOnGroup = useCallback((entityId: string) => {
        setGroupFilterPath((prev) => [...prev, entityId]);
        setLevel((prev) => prev + 1);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        setLevel((prev) => prev - 1);
        setGroupFilterPath((prev) => prev.slice(0, -1));
    }, []);

    if (dataRequest.status === "loading") {
        return <Skeleton variant="circular" width="100%" height="100%" />;
    }
    if (dataRequest.status === "error") {
        if (dataRequest.error?.data) {
            const { httpStatus } = dataRequest.error.data;
            if (httpStatus >= 400 && httpStatus < 500) {
                return <Alert severity="info">{dataRequest.error.message}</Alert>;
            }
        }

        return (
            <Alert severity="error">
                <FormattedMessage
                    defaultMessage="Failed to load data. The team has been notified."
                    description="Used as error message in emissions breakdown chart"
                />
            </Alert>
        );
    }

    if (!dataRequest.data.ok) {
        return <Alert severity="info">{dataRequest.data.error.message}</Alert>;
    }

    const dataForYear = dataRequest.data.data[year];

    if (!dataForYear?.length) {
        return (
            <Alert severity="info">
                <FormattedMessage defaultMessage="No data available for this year." />
            </Alert>
        );
    }

    const chartData: ChartData[] = dataForYear.map((obj) => ({
        name:
            obj.entityName ??
            intl.formatMessage({ defaultMessage: "Unclassified", description: "Used as label in pie chart" }),
        value: obj.value,
        entityId: obj.entityId,
    }));

    return (
        <Stack gap={2} height="100%">
            <Box position="absolute" top={0} right={0} zIndex={1}>
                <BackButtonForChart visible={groupFilterPath.length > 0} goBack={handleBackButtonClick} />
            </Box>
            <ResponsiveContainer minHeight={410}>
                <PieChart>
                    <Tooltip
                        itemStyle={{ fontFamily: theme.typography.fontFamily }}
                        formatter={(v) => `${formatNumber(Number(v), 2)} tCO₂e`}
                    />
                    <Pie
                        data={chartData}
                        startAngle={90}
                        endAngle={-270}
                        innerRadius="35%"
                        outerRadius="100%"
                        animationBegin={0}
                        animationDuration={500}
                        labelLine={false}
                        dataKey="value"
                        label={CustomLabel}
                    >
                        {chartData.map(({ entityId }, index) => (
                            <Cell
                                onClick={entityId && !atMaxDepth ? () => handleClickOnGroup(entityId) : undefined}
                                style={{
                                    cursor: entityId && !atMaxDepth ? "pointer" : "default",
                                    outline: "none",
                                    userSelect: "none",
                                }}
                                key={`cell-${entityId}`}
                                fill={getColor(index, chartData.length)}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Stack>
    );
};

export default EmissionsByClassificationStructureChart;
