import { GridColTypeDef } from "@mui/x-data-grid-pro";
import { isNil } from "lodash";
import React from "react";
import { FormattedNumber } from "react-intl";
import type { Row } from "./types";

const yearColDef: GridColTypeDef<Row[string], React.ReactNode> = {
    flex: 1,
    editable: true,
    type: "number",
    disableColumnMenu: false,
    minWidth: 100,
    renderCell({ value }) {
        if (isNil(value)) {
            return null;
        }
        let numericValue: number;
        if (typeof value === "string") {
            numericValue = parseFloat(value);
        } else {
            numericValue = value;
        }
        if (Number.isNaN(numericValue)) {
            return null;
        }
        // It's not a regular style prop. It's just a prop called style :(
        // eslint-disable-next-line react/style-prop-object
        return <FormattedNumber value={numericValue} style="decimal" maximumFractionDigits={2} />;
    },
};
export { yearColDef };
