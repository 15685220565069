import { SearchField } from "@/Components/SearchField";
import { TextFieldProps } from "@mui/material";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

type GridToolbarSearchFieldProps = {
    slotProps?: { textField?: Partial<Omit<TextFieldProps, "onChange">> };
};

const GridToolbarSearchField = React.forwardRef<HTMLInputElement, GridToolbarSearchFieldProps>(
    function GridToolbarSearchField(props, ref) {
        const apiRef = useGridApiContext();
        const textFieldProps = useMemo(() => props.slotProps?.textField || {}, [props.slotProps?.textField]);
        const rootProps = useGridRootProps();
        const { formatMessage } = useIntl();
        const defaultLabel = formatMessage({
            defaultMessage: "Search in table",
            description: "Search placeholder",
        });

        const debouncedHandleChange = React.useMemo<(value: string) => void>(
            () =>
                debounce((value) => {
                    rootProps?.slotProps?.toolbar?.search?.onChange?.(value);
                }, 1_000),
            [rootProps?.slotProps?.toolbar?.search]
        );

        const value = apiRef.current?.state.filter.filterModel.quickFilterValues?.[0] ?? "";

        return (
            <SearchField
                ref={ref}
                value={value}
                placeholder={defaultLabel}
                {...textFieldProps}
                {...rootProps.slotProps?.baseTextField}
                onChange={(_e, newValue) => {
                    apiRef.current?.setQuickFilterValues([newValue]);
                    debouncedHandleChange(newValue);
                }}
            />
        );
    }
);

interface CustomToolbarProps {
    search?: {
        /** Optional callback for handling value changes - MUI state is handled by default */
        onChange?: (value: string) => void;
    };
}

declare module "@mui/x-data-grid-pro" {
    // Disabling this eslint rule as we simply want to extend the module definition with our custom props.
    // The eslint fix breaks the intended behavior.
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ToolbarPropsOverrides extends CustomToolbarProps {}
}

export { GridToolbarSearchField };
