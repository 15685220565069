/* eslint-disable fp/no-mutating-methods */
import { useKeyboardShortcut } from "@/lib/tools";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import { ListItemIcon, Radio } from "@mui/material";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useGetSearchParamFilters } from "../../../../hooks";
import { useFilterHandlers } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { MenuRow } from "../components/MenuRow";

export const DateFilterMenu: React.FC = () => {
    const [searchPhrase, setSearchPhrase] = useState("");
    const [focusList, setFocusList] = useState(false);
    const { filter: year, setFilter: setYear } = useFilterHandlers("year");

    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);
    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);

    const { electricity, ...filters } = useGetSearchParamFilters();

    const query = trpc.overview.useQuery({ filters: { ...filters, dateFilter: null }, electricity }, { retry });

    return (
        <FilterValueSelect
            title={<FormattedMessage defaultMessage="Year" description="Year" />}
            onSearch={setSearchPhrase}
            isError={query.isError}
            isLoading={query.isLoading}
        >
            {query.data?.emissionData.years
                .filter((y) => y.toString().includes(searchPhrase))
                .sort((a, b) => b - a)
                .map((yearIterator) => (
                    <MenuRow
                        key={yearIterator}
                        optionLabel={yearIterator.toString()}
                        onClick={() => setYear(yearIterator)}
                    >
                        <ListItemIcon>
                            <Radio size="small" edge="start" checked={year === yearIterator} />
                        </ListItemIcon>
                    </MenuRow>
                ))}
        </FilterValueSelect>
    );
};
