import { useGetMenuItemStyles } from "@/Containers/utils/hooks";
import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import { Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FilterType } from "../../../interfaces";

interface Props {
    filterType: FilterType;
    selectedFilterType: FilterType;
    isActive: boolean;
    onSelect: () => void;
    Icon: OverridableComponent<SvgIconTypeMap>;
    children: React.ReactNode;
}

const isSelectedFilterType = (selectedFilterType: FilterType, filterType: FilterType) => {
    switch (filterType.type) {
        case "tag":
            return selectedFilterType.type === "tag" && selectedFilterType.tagId === filterType.tagId;
        default:
            return selectedFilterType.type === filterType.type;
    }
};

const SideMenuItem: React.FC<Props> = ({ selectedFilterType, filterType, onSelect, Icon, isActive, children }) => {
    const { activeMenuLinkStyle, menuLinkStyle, typographyStyle } = useGetMenuItemStyles();

    return (
        <Stack
            style={isSelectedFilterType(selectedFilterType, filterType) ? activeMenuLinkStyle : menuLinkStyle}
            direction="row"
            minWidth="100%"
            justifyContent="space-between"
            alignItems="center"
            onClick={onSelect}
        >
            <Stack direction="row" alignItems="center" paddingX={1}>
                <Icon fontSize="inherit" />
                <Typography {...typographyStyle}>{children}</Typography>
            </Stack>
            {isActive && <CheckCircle fontSize="inherit" />}
        </Stack>
    );
};

export default SideMenuItem;
