import { Check, X } from "@ignite-analytics/icons";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
    loading?: boolean;
    success?: boolean;
    error?: boolean;
};

const LoadingIndicator: React.FC<Props> = ({ loading, success, error }) => {
    if (loading) {
        return (
            <Stack direction="row" alignItems="center" height="1" justifyContent="center" spacing={1}>
                <CircularProgress size="20px" />
                <Typography variant="textSm" fontWeight={500} color={(t) => t.palette.text.textSecondary}>
                    <FormattedMessage
                        defaultMessage="Saving changes..."
                        description="Message to display when saving changes after entering values in the emission intensity table"
                    />
                </Typography>
            </Stack>
        );
    }

    if (success) {
        return (
            <Stack direction="row" alignItems="center" height="1" justifyContent="center" spacing={1}>
                <Check />
                <Typography variant="textSm" fontWeight={500} color={(t) => t.palette.text.textSecondary}>
                    <FormattedMessage
                        defaultMessage="Changes saved"
                        description="Message to display when changes have been saved when entering values in the emission intensity table"
                    />
                </Typography>
            </Stack>
        );
    }

    if (error) {
        return (
            <Stack direction="row" alignItems="center" height="1" justifyContent="center" spacing={1}>
                <X />
                <Typography variant="textSm" fontWeight={500} color={(t) => t.palette.text.textSecondary}>
                    <FormattedMessage
                        defaultMessage="An error occured. Changes were not saved."
                        description="Message to display when changes have not been saved due to an error when entering values in the emission intensity table"
                    />
                </Typography>
            </Stack>
        );
    }
    return null;
};

export { LoadingIndicator };
