import { OtherTableForm } from "@/Containers/DataSources/DataSourceForms/Other";
import { SpendForm } from "@/Containers/DataSources/DataSourceForms/Spend";
import { SupplierForm } from "@/Containers/DataSources/DataSourceForms/Supplier";
import { addDataSourceDetailsRoute, dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";

export const AddDataSourceDetails: React.FC = () => {
    const navigate = useNavigate();
    const postSubmit = () => {
        navigate({ to: dataPreferenceDataSourcesOverview.fullPath });
    };
    const params = useParams({ from: addDataSourceDetailsRoute.fullPath });
    const otherForms = ["energy", "airTravel", "other"];
    return (
        <Stack direction="column" spacing={2} padding={2}>
            {params.dataSourceType === "spend" && <SpendForm postSubmit={postSubmit} />}
            {params.dataSourceType === "supplier" && <SupplierForm postSubmit={postSubmit} />}
            {otherForms.includes(params.dataSourceType) && <OtherTableForm postSubmit={postSubmit} />}
        </Stack>
    );
};
