import { OverlappingDataWrapper } from "../Wrapper";
import { MissingInformationContent } from "../MissingInformationContent";
import { MainContent } from "./MainContent";
import { useGetFormData } from "./hooks";

export const EmissionsExcludedFromSpendSource = () => {
    const { filter, changeDisabled, disabled, addFilter, removeFilter, changeFilter, dataTakenIntoAccount } =
        useGetFormData();
    const { from, to } = dataTakenIntoAccount;
    const showContent = !!from && !!to;
    return (
        <OverlappingDataWrapper>
            {!showContent && <MissingInformationContent type="spend" />}
            {from && to && (
                <MainContent
                    filter={filter}
                    disabled={disabled}
                    addFilter={addFilter}
                    changeFilter={changeFilter}
                    removeFilter={removeFilter}
                    dataTakenIntoAccount={{ ...dataTakenIntoAccount, from, to }}
                    changeDisabled={changeDisabled}
                />
            )}
        </OverlappingDataWrapper>
    );
};
