import { useImage } from "@/lib/files/useimage";
import { overviewRoute } from "@/Router/Overview";
import { track } from "@/lib/track";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Play } from "@ignite-analytics/icons";
import { Box, Button, Card, CardContent, Chip, Grid, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";

export const MissingModuleAccess: React.FC = () => {
    const image = useImage(
        `https://imagedelivery.net/15ALoYjGmzQG831cZcmjQQ/a12b11ff-45e4-4a5d-d410-caf594a04700/public`
    );

    return (
        <Card variant="outlined">
            <CardContent sx={{ background: "#F7F7F8" }}>
                <Stack direction="row" gap={2}>
                    <Stack gap={2} justifyContent="space-between">
                        <Stack gap={1}>
                            <Box>
                                <Chip
                                    label={
                                        <Typography variant="textMd" fontWeight={500}>
                                            <FormattedMessage
                                                defaultMessage="Carbon accounting"
                                                description="Carbon card chip label"
                                            />
                                        </Typography>
                                    }
                                    color="success"
                                />
                            </Box>
                            <Typography variant="displayMd" fontWeight={600}>
                                <FormattedMessage
                                    defaultMessage="Calculate and manage your emissions"
                                    description="Carbon card header"
                                />
                            </Typography>
                            <Typography variant="textMd" color="text.text-secondary">
                                <FormattedMessage
                                    defaultMessage="Easily import your data from multiple sources to one place. Get a baseline of your organization's Scope 1, 2, and 3 emissions quickly, improve accuracy, and add the data to your CSRD report. All calculations can be traced in Ignite, so it's audit-ready."
                                    description="Carbon card description"
                                />
                            </Typography>
                        </Stack>

                        <Stack direction="row" gap={1} justifyContent="start">
                            <Link
                                to={overviewRoute.to}
                                from={overviewRoute.to}
                                search={(prev) => {
                                    return {
                                        ...prev,
                                        viewType: "demo",
                                    };
                                }}
                            >
                                <Button startIcon={<Play />} color="secondary">
                                    <FormattedMessage
                                        defaultMessage="Watch video"
                                        description="Carbon card watch video button"
                                    />
                                </Button>
                            </Link>
                            <Link
                                to={overviewRoute.to}
                                from={overviewRoute.to}
                                onClick={() => {
                                    track(`Explore Modules: Clicked on carbon`);
                                }}
                                search={(prev) => {
                                    return {
                                        ...prev,
                                        viewType: "learnMore",
                                    };
                                }}
                            >
                                <Button color="primary">
                                    <FormattedMessage
                                        defaultMessage="Learn more"
                                        description="Carbon card learn more button"
                                    />
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                    {image.data ? (
                        <Box sx={{ minWidth: 450, position: "relative", left: 50, maxHeight: 300 }}>
                            <Grid container alignItems="flex-end" justifyContent="flex-end">
                                <Grid item>
                                    <img src={image.data} alt="Carbon module banner" style={{ height: "400px" }} />
                                </Grid>
                            </Grid>
                        </Box>
                    ) : null}
                </Stack>
            </CardContent>
        </Card>
    );
};
