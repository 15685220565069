import { RouteButton } from "@/Components/RouteButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { range } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import objectSupport from "dayjs/plugin/objectSupport";
import { StepFooter } from "../components/StepFooter";

dayjs.extend(objectSupport);

const formId = "select-year-form";

const useSchema = () => {
    const { formatMessage } = useIntl();
    const schema = z
        .object({
            from: z.number().int(),
            to: z.number().int(),
        })
        .refine(({ from, to }) => from <= to, {
            message: formatMessage({
                defaultMessage: "From year must be less than or equal to To year",
                description:
                    "Error message for the select year step when adding emission intensities if the from year exceeds the to year",
            }),
            path: ["from"],
        });
    return schema;
};

const SelectYear: React.FC = () => {
    const schema = useSchema();
    const { control, handleSubmit } = useForm({
        defaultValues: {
            from: 2021,
            to: dayjs().year(),
        },
        reValidateMode: "onChange",
        resolver: zodResolver(schema),
    });
    const navigate = useNavigate();

    function navigateToNextStep(data: z.infer<typeof schema>) {
        navigate({
            from: "/data-preferences/emission-intensities/new/step/select-year",
            to: "/data-preferences/emission-intensities/new/step/level",
            search(current) {
                return {
                    ...current,
                    fromYear: data.from,
                    toYear: data.to,
                };
            },
        });
    }

    return (
        <>
            <Stack height="100%" spacing={7.5} mb={2}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Select a year range or a single year"
                            description="Header for select year page when creating a new emission intensity"
                        />
                    </Typography>
                    <Typography variant="textSm" color={(t) => t.palette.text.textHelper}>
                        <FormattedMessage
                            defaultMessage="Which years would you like to measure emission intensities for?"
                            description="Subheader for select year page when creating a new emission intensity"
                        />
                    </Typography>
                </Stack>
                <form onSubmit={handleSubmit(navigateToNextStep)} id={formId}>
                    <Stack direction="row" spacing={2}>
                        <Controller
                            name="from"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={Boolean(error)}>
                                    <InputLabel id="select-from-year">
                                        <FormattedMessage
                                            defaultMessage="From"
                                            description="Label for select to pick which year denotes the start of the emission intensity"
                                        />
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            slotProps: {
                                                paper: {
                                                    style: {
                                                        maxHeight: "20rem",
                                                    },
                                                },
                                            },
                                        }}
                                        labelId="select-from-year"
                                        {...field}
                                        label={
                                            <FormattedMessage
                                                defaultMessage="From"
                                                description="Label for select to pick which year denotes the start of the emission intensity"
                                            />
                                        }
                                    >
                                        {range(dayjs().year(), dayjs({ year: 2015 }).year(), -1).map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{error?.message ?? " "}</FormHelperText>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="to"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={Boolean(error)}>
                                    <InputLabel id="select-to-year">
                                        <FormattedMessage
                                            defaultMessage="To"
                                            description="Label for select to pick which year denotes the end of the emission intensity"
                                        />
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            slotProps: {
                                                paper: {
                                                    style: {
                                                        maxHeight: "20rem",
                                                    },
                                                },
                                            },
                                        }}
                                        labelId="select-to-year"
                                        {...field}
                                        label={
                                            <FormattedMessage
                                                defaultMessage="To"
                                                description="Label for select to pick which year denotes the end of the emission intensity"
                                            />
                                        }
                                    >
                                        {range(dayjs().year(), dayjs({ year: 2015 }).year(), -1).map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{error?.message ?? " "}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Stack>
                </form>
            </Stack>

            <StepFooter>
                <Stack direction="row" justifyContent="space-between">
                    <RouteButton color="ghostGray" size="small" to="/overview">
                        <FormattedMessage defaultMessage="Cancel" description="Cancel action" />
                    </RouteButton>
                    <Stack direction="row" spacing={2}>
                        <RouteButton
                            color="secondary"
                            size="small"
                            to="/data-preferences/emission-intensities/new/select-intensity"
                            search
                        >
                            <FormattedMessage defaultMessage="Back" description="Previous step" />
                        </RouteButton>
                        <Button size="small" type="submit" form={formId}>
                            <FormattedMessage defaultMessage="Next" description="Next step" />
                        </Button>
                    </Stack>
                </Stack>
            </StepFooter>
        </>
    );
};

export { SelectYear };
