import { ActionPopover } from "@/Components/ActionPopover/Common";
import { ListActionItem } from "@/Components/ActionPopover/Common/Components/ListActionItem";
import { CommonProps as ActionPopoverCommonProps } from "@/Components/ActionPopover/Common/types";
import { SupplierActionPopover } from "@/Components/ActionPopover/SupplierActionPopover";
import { ContributorType } from "@/Containers/LandingPage/components/ContributorTypePicker";
import { goToCategoryProfile } from "@/lib/utils/utils";
import { useIntl } from "react-intl";

type Props = ActionPopoverCommonProps & {
    data: { id: string; name: string; year: number | undefined } | undefined;
    contributorType: ContributorType;
};
export const ContributorActionPopover: React.FC<Props> = ({
    data,
    contributorType,
    trackSource,
    anchorEl,
    open,
    handleClose,
}) => {
    const { formatMessage } = useIntl();

    if (!data) {
        return null;
    }
    if (contributorType === "suppliers") {
        return (
            <SupplierActionPopover
                config={{
                    type: "supplier",
                    supplierId: data.id,
                    supplierName: data.name,
                    year: data.year,
                }}
                trackSource={trackSource}
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={Boolean(anchorEl)}
            />
        );
    }
    if (contributorType === "spendCategories") {
        return (
            <ActionPopover
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                items={[
                    <ListActionItem
                        key="spendCategories-1"
                        onClick={() => goToCategoryProfile(data.id)}
                        text={formatMessage({
                            defaultMessage: "Open category profile",
                            description: "Label for action in action popover for opening category profile",
                        })}
                    />,
                    // TODO: Add support for viewing activities based on category
                ]}
            />
        );
    }
    return null;
};
