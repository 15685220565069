import { RouteButton } from "@/Components/RouteButton";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { usePermission } from "@/Providers/permissions";
import { Card, CardActions, CardContent, CardHeader, createSvgIcon, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGetSearchParamFilters, useSelectedElectricityBasis, useSelectedYear } from "../../hooks";
import { EmissionIntensityCard, EmissionIntensityCardProps, EmissionIntensitySelect } from "./EmissionIntensityCard";
import { ErrorEmissionIntensityCard } from "./ErrorEmissionIntensityCard";
import { LoadingEmissionIntensityCard } from "./LoadingEmissionIntensityCard";

const ShapeIcon = createSvgIcon(
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="8" width="32" height="32" rx="16" fill="#FEF9EA" />
        <rect x="1" y="1" width="46" height="46" rx="23" stroke="#FCB823" strokeOpacity="0.16" strokeWidth="2" />
        <rect x="5" y="5" width="38" height="38" rx="19" stroke="#FCB823" strokeOpacity="0.32" strokeWidth="2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5702 25.1769C16.92 24.5266 16.92 23.4724 17.5702 22.8222L22.8227 17.5697C23.4729 16.9195 24.5271 16.9195 25.1774 17.5697L30.4299 22.8222C31.0801 23.4724 31.0801 24.5266 30.4299 25.1769L25.1774 30.4294C24.5271 31.0796 23.4729 31.0796 22.8227 30.4294L17.5702 25.1769ZM18.5106 23.7627C18.3798 23.8935 18.3798 24.1056 18.5106 24.2364L23.7631 29.4889C23.894 29.6197 24.1061 29.6197 24.2369 29.4889L29.4894 24.2364C29.6202 24.1056 29.6202 23.8935 29.4894 23.7627L24.2369 18.5102C24.1061 18.3793 23.894 18.3793 23.7631 18.5101L18.5106 23.7627Z"
            fill="#F6970A"
        />
    </svg>,
    "ShapeIcon"
);

const NotFoundEmissionIntensityCard: React.FC = () => {
    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={
                    <FormattedMessage
                        defaultMessage="Emission Intensity"
                        description="Header for emission intensity widget on the overview page when the emission intensity is not found"
                    />
                }
                action={<EmissionIntensitySelect />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text["text-secondary"],
                }}
            />
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Stack alignItems="center" justifyContent="center">
                        <ShapeIcon sx={{ height: "44px", width: "44px", margin: "-4px" }} />
                    </Stack>
                    <Typography variant="textSm" color={(t) => t.palette.text["text-body"]}>
                        <FormattedMessage
                            defaultMessage="Unable to find the emission intensity, please select one from the dropdown."
                            description="Description for the emission intensity card on the overview page when the emission intensity is not found"
                        />
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions>
                <RouteButton color="secondary" size="xsmall" to="/data-preferences/emission-intensities" disabled>
                    <FormattedMessage
                        defaultMessage="Edit data"
                        description="Label for the button to edit data in the emission intensity card on the overview page"
                    />
                </RouteButton>
            </CardActions>
        </Card>
    );
};

type Props = {
    emissionIntensityId: string;
};

const NonSpendEmissionIntensityCard: React.FC<Props> = ({ emissionIntensityId }) => {
    const { formatMessage } = useIntl();

    const electricity = useSelectedElectricityBasis();
    const filters = useGetSearchParamFilters();
    const year = useSelectedYear();
    const isEditor = usePermission("general", { object: "general", relation: "write" });
    const { data, isLoading, isError } = trpc.getEmissionIntensityFactor.useQuery({
        emissionIntensityId,
        electricity,
        filters,
        year,
    });

    if (isLoading) {
        return <LoadingEmissionIntensityCard />;
    }

    if (isError) {
        return <ErrorEmissionIntensityCard />;
    }

    if (data.ok === false) {
        if (data.error.code === "ERR_NOT_FOUND") {
            return <NotFoundEmissionIntensityCard />;
        }
    }

    const emissionIntensityFactor = data?.ok ? data.data.emissionIntensityFactor : undefined;
    const changeSinceLastPeriod = data?.ok ? data.data.changeSinceLastPeriod : undefined;
    const emissionIntensityUnit = data?.ok ? data.data.emissionIntensityUnit : undefined;
    const emissionsUnit = data?.ok ? data.data.emissionsUnit : undefined;
    const emissionIntensityType = data?.ok ? data.data?.emissionIntensityType : undefined;
    let units: EmissionIntensityCardProps["units"];
    if (emissionIntensityUnit && emissionsUnit) {
        units = {
            emissions: emissionsUnit,
            emissionIntensity: emissionIntensityUnit,
        };
    }

    let header: string;
    switch (emissionIntensityType) {
        case "Revenue":
            header = formatMessage({
                defaultMessage: "Emissions per revenue",
                description: "Header for the emission intensity per revenue card",
            });
            break;
        case "Employees":
            header = formatMessage({
                defaultMessage: "Emissions per employee",
                description: "Header for the emission intensity per employee card",
            });
            break;
        default:
            header = formatMessage(
                {
                    defaultMessage: "Emissions per {unit}",
                    description: "Header for the custom emission intensity card",
                },
                { unit: emissionIntensityUnit }
            );
            break;
    }

    return (
        <EmissionIntensityCard
            emissionIntensityFactor={emissionIntensityFactor}
            changeSinceLastPeriod={changeSinceLastPeriod}
            units={units}
            header={header}
            action={
                <RouteButton
                    color="secondary"
                    size="xsmall"
                    to="/data-preferences/emission-intensities/$emissionIntensityId"
                    params={{
                        emissionIntensityId,
                    }}
                    disabled={!isEditor}
                    onClick={() => {
                        CARBON_EVENTS.INTENSITIES_EDIT_CLICKED(
                            { page: "Overview" },
                            { intensityType: emissionIntensityType?.toLowerCase() }
                        ).track();
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Edit data"
                        description="Label for the button to edit data in the emission intensity card on the overview page"
                    />
                </RouteButton>
            }
        />
    );
};

export { NonSpendEmissionIntensityCard };
