import { TextField } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

interface Props {
    label: string;
    setValue: (value: number | null) => void;
    errorMessage?: string;
    value: number | null;
    dataTestId?: string;
}

// As the component maintain its own internal states and sets the external value and the internal value
// is a string, we need to return different values
const getStateValues = (newValue: number | string | null): [number | null, string | null] => {
    if (newValue === null) {
        return [null, null];
    }
    if (typeof newValue === "number") {
        return [newValue, newValue.toString()];
    }
    const parsedValue = parseFloat(newValue);

    if (Number.isNaN(parsedValue)) {
        return [null, null];
    }
    return [parsedValue, newValue];
};
// As the component maintain its own internal states we need to handle edge cases like where the value has trailing zeros
const isEqual = (firstValue: string | null, secondValue: string | null): boolean => {
    if (firstValue === null && secondValue === null) {
        return true;
    }
    if (firstValue === null || secondValue === null) {
        return false;
    }
    return parseFloat(firstValue) === parseFloat(secondValue);
};

export const ValuePickerComponent: React.FC<Props> = ({
    label,
    setValue,
    errorMessage,
    value,
    dataTestId = "value-input",
}) => {
    // Use of internal value is needed to prevent number parsing to remove trailing zeros
    const [internalValue, setInternalValue] = React.useState<string | null>(value?.toString() ?? null);

    const { formatMessage } = useIntl();
    /* We always need to keep the internal value in sync with the forms value
    /  The reason for this is that the value in the form can be changed based on other 
    /  side effects like submitting the form.
    */
    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, newInternalValue] = getStateValues(value);
        if (!isEqual(internalValue, newInternalValue)) {
            setInternalValue(newInternalValue);
        }
    }, [value, internalValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const [formValue, newInternalValue] = getStateValues(e.target.value);
        setValue(formValue);
        setInternalValue(newInternalValue);
    };
    return (
        <TextField
            label={label}
            value={internalValue ?? ""}
            onChange={handleChange}
            inputProps={{
                "data-testid": dataTestId,
            }}
            placeholder={formatMessage({
                defaultMessage: "Enter a value",
            })}
            type="number"
            error={!!errorMessage}
            helperText={errorMessage ?? ""}
            fullWidth
        />
    );
};
