import { useKeyboardShortcut } from "@/lib/tools";
import { Checkbox, ListItemIcon } from "@mui/material";
import { useCallback, useState } from "react";
import { MenuRow } from "../../components/MenuRow";

type Props = {
    selected?: boolean;
    label: string;
    onChange: (selected: boolean) => void;
};

export const TagSelect: React.FC<Props> = ({ onChange, selected, label }) => {
    const [focusList, setFocusList] = useState(false);
    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);
    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);

    const handleMenuRowClick = useCallback(() => {
        onChange(!selected);
    }, [onChange, selected]);

    return (
        <MenuRow optionLabel={label} onClick={handleMenuRowClick} chip>
            <ListItemIcon>
                <Checkbox size="small" edge="start" checked={selected} />
            </ListItemIcon>
        </MenuRow>
    );
};
