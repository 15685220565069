import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { ListItemText, Menu, MenuItem, MenuProps, Skeleton } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelectedEmissionIntensityId } from "../../hooks";

interface Props extends MenuProps {
    onEmissionIntensityChange?: (emissionIntensityId: string) => void;
}

const SelectEmissionIntensityMenu: React.FC<Props> = ({ onEmissionIntensityChange, ...menuProps }) => {
    const { data, isLoading } = trpc.findManyEmissionIntensities.useQuery();
    const [intensity] = useSelectedEmissionIntensityId();
    if (isLoading) {
        return (
            <Menu {...menuProps}>
                <Skeleton>
                    <MenuItem>
                        <ListItemText>loading</ListItemText>
                    </MenuItem>
                </Skeleton>
                <Skeleton>
                    <MenuItem>
                        <ListItemText>loading</ListItemText>
                    </MenuItem>
                </Skeleton>
            </Menu>
        );
    }

    if (data) {
        return (
            <Menu
                {...menuProps}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "20rem",
                        },
                    },
                }}
            >
                <MenuItem
                    key="revenue"
                    selected={intensity === data.revenue?.id || intensity === "revenue"}
                    onClick={() => {
                        CARBON_EVENTS.INTENSITIES_TYPE_SWITCHED(
                            { page: "Overview" },
                            { intensityType: "revenue" }
                        ).track();
                        if (data.revenue) {
                            onEmissionIntensityChange?.(data.revenue.id);
                        } else {
                            onEmissionIntensityChange?.("revenue");
                        }
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Revenue"
                        description="Option to select revenue when changing emission intensities on the overview page"
                    />
                </MenuItem>
                <MenuItem
                    key="employees"
                    selected={intensity === data.employees?.id || intensity === "employees"}
                    onClick={() => {
                        CARBON_EVENTS.INTENSITIES_TYPE_SWITCHED(
                            { page: "Overview" },
                            { intensityType: "employees" }
                        ).track();
                        if (data.employees) {
                            onEmissionIntensityChange?.(data.employees.id);
                        } else {
                            onEmissionIntensityChange?.("employees");
                        }
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Employees"
                        description="Option to select employees when changing emission intensities on the overview page"
                    />
                </MenuItem>
                <MenuItem key="spend" onClick={() => onEmissionIntensityChange?.("spend")}>
                    <ListItemText>
                        <FormattedMessage
                            defaultMessage="Spend"
                            description="Option to select spend when changing emission intensities on the overview page"
                        />
                    </ListItemText>
                </MenuItem>
                {data.other.map((emissionIntensity) => (
                    <MenuItem
                        key={emissionIntensity.id}
                        selected={intensity === emissionIntensity.id}
                        onClick={() => {
                            CARBON_EVENTS.INTENSITIES_TYPE_SWITCHED(
                                { page: "Overview" },
                                { intensityType: "custom" }
                            ).track();
                            onEmissionIntensityChange?.(emissionIntensity.id);
                        }}
                    >
                        <ListItemText>{emissionIntensity.name}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        );
    }
    return null;
};

export { SelectEmissionIntensityMenu };
