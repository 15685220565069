import { Stack, Typography } from "@mui/material";
import React from "react";

type InternalProps = {
    message: React.ReactNode;
};
export const ActionPopoverHeader: React.FC<InternalProps> = ({ message }) => {
    return (
        <Stack alignItems="end" direction="row" justifyContent="flex-start" padding={2}>
            <Typography variant="textSm" fontWeight={500} noWrap>
                {message}
            </Typography>
        </Stack>
    );
};
