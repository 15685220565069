import {
    createCustomOptionsForTagRoute,
    createOptionsFromSourceForTagRoute,
} from "@/Router/DataPreferencesRoute/TagRoute";
import { FormattedMessage } from "react-intl";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useMatches } from "@tanstack/react-router";
import { useMemo } from "react";
import { Stack } from "@mui/material";

export const CreateTitle = () => {
    const matches = useMatches();
    const headerType: "main" | "custom" | "source" = useMemo(() => {
        if (matches.find((match) => match.id.includes(createCustomOptionsForTagRoute.id))) {
            return "custom";
        }
        if (matches.find((match) => match.id.includes(createOptionsFromSourceForTagRoute.id))) {
            return "source";
        }
        return "main";
    }, [matches]);
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <BookmarkBorderIcon fontSize="inherit" />
            {headerType === "main" && (
                <FormattedMessage defaultMessage="Create Tag" description="Create Tag for the Tags component" />
            )}
            {headerType === "custom" && (
                <FormattedMessage
                    defaultMessage="Create custom tags for emissions data"
                    description="Create Custom Tag for the Tags component"
                />
            )}
            {headerType === "source" && (
                <FormattedMessage
                    defaultMessage="Create Tag from Source"
                    description="Create Tag from Source for the Tags component"
                />
            )}
        </Stack>
    );
};
