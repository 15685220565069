import { AppLayout } from "@/Components/Layout/AppLayout";
import { createRoute, Outlet } from "@tanstack/react-router";
import { rootRoute } from "..";

const ApplayoutComponent = () => {
    return (
        <AppLayout>
            <Outlet />
        </AppLayout>
    );
};

const appLayoutRoute = createRoute({
    id: "_appLayout",
    getParentRoute: () => rootRoute,
    component: ApplayoutComponent,
});

export { appLayoutRoute };
