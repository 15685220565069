import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { Plus, Trash } from "@ignite-analytics/icons";
import { Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    options: string[];
    addOption: (option: string) => void;
    deleteOption: (option: string) => void;
    disabled?: boolean;
};

export const OptionsList: React.FC<Props> = ({ options, addOption, deleteOption, disabled }) => {
    const [option, setOption] = React.useState("");

    return (
        <Stack gap={1}>
            <Typography variant="subtitle2">
                <FormattedMessage
                    defaultMessage="Start adding tag names"
                    description="Start adding tag names for the Tags component"
                />
            </Typography>
            <Stack direction="row" gap={2}>
                <TextField
                    disabled={disabled}
                    label={
                        <FormattedMessage defaultMessage="Add option" description="Add option for the Tags component" />
                    }
                    onKeyDown={(e) => {
                        if (option === "") return;
                        if (e.key === "Enter") {
                            e.preventDefault();
                            addOption(option);
                            setOption("");
                        }
                    }}
                    variant="outlined"
                    size="small"
                    onChange={({ target }) => {
                        setOption(target.value);
                    }}
                    sx={{ minWidth: 250 }}
                    value={option}
                />
                <Button
                    disabled={disabled}
                    color="secondary"
                    onClick={() => {
                        if (option === "") return;
                        addOption(option);
                        setOption("");
                    }}
                    startIcon={<Plus fontSize="small" />}
                >
                    <Typography variant="inherit">
                        <FormattedMessage defaultMessage="Add" description="Add for the Tags component" />
                    </Typography>
                </Button>
            </Stack>
            <Grid container gap={0.5}>
                {[...new Set(options)].map((innerOption) => (
                    <Grid item key={innerOption}>
                        <SquareChip color={getChipColor("green")}>
                            <Typography> {innerOption} </Typography>
                            <IconButton
                                sx={{ padding: 0, margin: 0, paddingLeft: 0.5 }}
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteOption(innerOption)}
                            >
                                <Trash fontSize="inherit" />
                            </IconButton>
                        </SquareChip>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};
