import { useFormattedTotal } from "@/Containers/LandingPage/hooks";
import { ActionPopoverHeader } from "../TotalEmissionForYearHeader";
import { useGetMessage } from "./hooks";

type Props = {
    year: number;
    scope?: "1" | "2" | "3";
};
export const ScopeAndYearTotalHeader: React.FC<Props> = ({ year, scope }) => {
    const total = useFormattedTotal(scope ? { type: "scope", scope } : { type: "total" }, year);
    const message = useGetMessage(year, scope, total);
    return <ActionPopoverHeader message={message} />;
};
