import { Check, ChevronRight } from "@ignite-analytics/icons";
import { List, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { sortByIfHasChildren } from "./helpers";

/**
 * A single item in a hierarchical menu.
 * @template T Allows the item to have additional properties.
 */
export interface Item {
    value: string;
    label?: string;
    children?: Item[];
}

export type Props = {
    level?: number;
    item: Item;
    searchTerm?: string;
} & (
    | {
          multiSelect: true;
          selected: string[] | undefined;
          onSelect: (value: string[] | undefined) => void;
      }
    | {
          multiSelect: false;
          selected: string | undefined;
          onSelect: (value: string | undefined) => void;
      }
);

export const SEARCH_TERM_MIN_LENGTH = 2;

const isDescendantSelected = (item: Item, selected: string | undefined) => {
    if (!selected) {
        return false;
    }

    if (!item.children) {
        return false;
    }

    return item.children.some((child) => child.value === selected || isDescendantSelected(child, selected));
};

const itemMatchesSearchTerm = (item: { label?: string; value: string }, searchTerm: string | undefined) => {
    if (!searchTerm) return false;

    const label = item.label ?? item.value;
    return label.toLowerCase().includes(searchTerm.toLowerCase());
};

const descendantMatchesSearchTerm = (item: Item, searchTerm: string | undefined) => {
    if (!searchTerm) return false;

    if (!item.children?.length) return false;

    return item.children.some(
        (child) => itemMatchesSearchTerm(child, searchTerm) || descendantMatchesSearchTerm(child, searchTerm)
    );
};

const HierarchicalMenuItem: React.FC<Props> = ({ item, level = 0, ...propsToBeForwarded }) => {
    const { selected, onSelect, multiSelect, searchTerm } = propsToBeForwarded;
    const [userExpansionSetting, setUserExpansionsSetting] = useState<boolean | undefined>(undefined);

    const isSelected = useMemo(() => {
        if (multiSelect) {
            return selected?.some((value) => value === item.value);
        }

        return selected === item.value;
    }, [multiSelect, selected, item.value]);

    const expandedDueToSelection = useMemo(() => {
        if (multiSelect) {
            // Don't auto expand something in multi select because of the search term
            return;
        }

        // For single-select, auto-expand if the selected item is a descendant of this item
        if (isDescendantSelected(item, selected)) {
            return true;
        }
    }, [item, multiSelect, selected]);

    const expandedDueToSearchTerm = useMemo(() => {
        // Auto expand any item with descendants that match the search term, regardless or whether it's multi
        // select or not, as long as the search term is at least x characters long
        if (
            searchTerm &&
            searchTerm.length >= SEARCH_TERM_MIN_LENGTH &&
            descendantMatchesSearchTerm(item, searchTerm)
        ) {
            return true;
        }
    }, [item, searchTerm]);

    const expanded = userExpansionSetting ?? expandedDueToSelection ?? expandedDueToSearchTerm ?? false;

    const handleSelect = useCallback(() => {
        if (multiSelect) {
            if (isSelected) {
                onSelect(selected?.filter((s) => s !== item.value) ?? []);
            } else {
                onSelect([...(selected ?? []), item.value]);
            }
        } else {
            onSelect(isSelected ? undefined : item.value);
        }
    }, [isSelected, item, multiSelect, onSelect, selected]);

    const sortedChildren = useMemo(
        () => (item.children ? sortByIfHasChildren(item.children) : undefined),
        [item.children]
    );

    const filteredChildren = useMemo(() => {
        if (!searchTerm) return sortedChildren;
        // If the user expands something manually after search, they should be allowed to see all children
        if (userExpansionSetting) return sortedChildren;
        return sortedChildren?.filter((child) => {
            return itemMatchesSearchTerm(child, searchTerm) || descendantMatchesSearchTerm(child, searchTerm);
        });
    }, [searchTerm, sortedChildren, userExpansionSetting]);

    return (
        <>
            <MenuItem component="li" selected={isSelected} onClick={handleSelect} sx={{ paddingLeft: 1 + level * 3 }}>
                <ListItemIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        setUserExpansionsSetting(!expanded);
                    }}
                    role="button"
                >
                    <ChevronRight
                        sx={{
                            transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
                            transition: "transform .2s",
                            visibility: item.children?.length ? "visible" : "hidden",
                        }}
                    />
                </ListItemIcon>
                <ListItemText>{item.label ?? item.value}</ListItemText>
                {isSelected && (
                    <ListItemIcon>
                        <Check fontSize="small" />
                    </ListItemIcon>
                )}
            </MenuItem>
            <List>
                {expanded &&
                    filteredChildren?.map((child) => (
                        <HierarchicalMenuItem
                            key={child.value}
                            item={child}
                            level={level + 1}
                            {...propsToBeForwarded}
                        />
                    ))}
            </List>
        </>
    );
};

export default HierarchicalMenuItem;
