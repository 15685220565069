import { useIntl } from "react-intl";
import { EmissionFactorMeta } from "router";

export const useGetTextForDatabase = (source: EmissionFactorMeta["source"]) => {
    const { formatMessage } = useIntl();
    switch (source) {
        case "AIB":
            return formatMessage({
                defaultMessage:
                    "The Association of Issuing Bodies (AIB) publishes yearly datasets of electricity emission factors taking into account guarantees of origin in the European electricity market. The location-based emission factors are from the production mix per country and year, while the market-based factors are from the residual mix. All factors only take into account the CO₂-emissions, not any or the other greenhouse gases.",
                description: "AIB description",
            });
        case "BEIS/DEFRA":
            return formatMessage({
                defaultMessage:
                    "The BEIS/DEFRA database, maintained by the Department for Business, Energy, and Industrial Strategy (BEIS) in the United Kingdom, is a comprehensive repository of emission factors. These factors quantify the amount of greenhouse gases released per unit of activity or fuel consumption, enabling organizations to estimate their carbon footprint and understand the environmental impact of their operations.",
                description: "BEIS description",
            });
        case "Hotel Footprinting Tool":
            return formatMessage({
                defaultMessage:
                    "Emission intensity of average hotel night in the region based on the Cornell Hotel Sustainability Benchmarking (CHSB) index. Database used for the BEIS/DEFRA emission factors, the GHGP/Quantis tool and several other carbon accounting calculators.",
                description: "Hotel Footprinting Tool description",
            });
        case "IPCC AR6":
            return formatMessage({
                defaultMessage:
                    "The Global Warming Potential (GWP)-values for a 100 years, using estimates from the sixth assessment report (AR6) from the International Panel on Climate Change (IPCC).",
                description: "IPCC AR6 description",
            });
        case "UNKNOWN":
            return formatMessage({
                defaultMessage: "The result was precalculated by the user and no database was used.",
                description: "Unknown database description",
            });
    }
};
