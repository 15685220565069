import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DropZoneArea } from "./style";

interface DropZoneProps {
    children: React.ReactNode;
    disabled: boolean;
}

export const DropZone = ({ children, disabled }: DropZoneProps) => {
    const [uploading, setUploading] = React.useState(false);

    const dragEnter = (e: React.MouseEvent) => {
        e.preventDefault();
        !disabled && setUploading(true);
    };

    const dragLeave = (e: React.MouseEvent) => {
        e.preventDefault();
        setUploading(false);
    };

    const drop = () => {
        setUploading(false);
    };

    return (
        <DropZoneArea
            uploading={uploading ? true : undefined}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={drop}
            disabled={disabled}
        >
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                <Typography variant="h6">
                    <FormattedMessage defaultMessage="Drop files" description="Drop files" />
                </Typography>
                <Grid justifyContent="center">
                    <Typography display="inline" variant="textMd" sx={{ pr: 0.5 }}>
                        <FormattedMessage defaultMessage="or" description="Or" />
                    </Typography>
                    <Typography display="inline" variant="inherit" sx={{ pr: 0.5 }}>
                        <FormattedMessage defaultMessage="browse" description="browse" />
                    </Typography>
                    <Typography display="inline" variant="textSm">
                        <FormattedMessage defaultMessage="to choose a file" description="to choose a file" />
                    </Typography>
                </Grid>
            </Grid>
            {children}
        </DropZoneArea>
    );
};
