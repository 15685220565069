import { trpc } from "@/lib/trpc/client";
import { overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Outlet, useNavigate } from "@tanstack/react-router";
import { CreateTitle } from "./CreateTitle";

export const CreateTagModal = () => {
    // Prefetch connections
    const utils = trpc.useContext();
    utils.addedConnections.prefetch();

    const navigate = useNavigate();
    const close = () => {
        navigate({ to: overviewTagRoute.fullPath });
    };

    return (
        // In order to avoid accidentally closing the modal, we are disabling click outside close
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Dialog open onClose={() => {}} fullWidth maxWidth="md">
            <DialogTitle>
                <CreateTitle />
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Outlet />
        </Dialog>
    );
};
