import { AppLayout } from "@/Components/Layout/AppLayout";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { useAlert } from "@/Providers/Alerts";
import {
    Button,
    FormControl,
    Unstable_Grid2 as Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmissionIntensityDataGrid, useEmissionIntensityDataGrid } from "../../../components/EmissionIntensityDataGrid";
import { LoadingIndicator } from "../../../components/EmissionIntensityDataGrid/LoadingIndicator";
import { useSetEmissionIntensityValue } from "../../../components/EmissionIntensityDataGrid/useEmissionIntensityDataGrid";
import { RouteButton } from "../../components/RouteButton";
import { StepFooter } from "../components/StepFooter";
import { ConfirmSubmissionDialog } from "./components/ConfirmSubmissionDialog";
import { RedirectDialog } from "./components/RedirectDialog";
import { useEmissionIntensityUnits } from "./useEmissionIntensityUnits";

/**
 * Using auto-focus to automatically highlight the first cell when entering the page
 * can be problematic for users who are using screen readers. As such, we instead opt for an interactive border
 * around the first cell to nudge the user to start editing the cell. When the user starts editing, we remove the
 * border and let the user navigate the table as they see fit.
 */
const useHighlightFirstCell = () => {
    const [touched, setTouched] = useState(false);
    const onEditStart = useCallback(
        function onCellEditStart() {
            setTouched(true);
        },
        [setTouched]
    );
    if (touched) {
        return { onEditStart, highlightFirstCell: false };
    }
    return { onEditStart, highlightFirstCell: true };
};

const EnterInformation: React.FC = () => {
    const { emissionIntensityId } = useParams({
        from: "/_containerLayout/data-preferences/emission-intensities/new/step/$emissionIntensityId/enter-information",
    });
    const { onEditStart, highlightFirstCell } = useHighlightFirstCell();
    const { data, isLoading, refetch } = trpc.getEmissionIntensity.useQuery({
        id: emissionIntensityId,
    });
    const updateEmissionIntensity = trpc.updateEmissionIntensity.useMutation();
    const {
        mutate,
        isLoading: isSetEmissionIntensityValueLoading,
        isSuccess,
        isError,
    } = useSetEmissionIntensityValue();
    const { props, emissionIntensity } = useEmissionIntensityDataGrid(emissionIntensityId, mutate);

    const [openDialog, setOpenDialog] = useState<"redirecting" | "confirm-missing-data-submission" | false>(false);
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const navigate = useNavigate();

    function onSubmit() {
        if (!emissionIntensity) {
            return alertUser({
                severity: "error",
                value: formatMessage({
                    defaultMessage: "An unexpected error occured, please try again later",
                    description: "Error message if an unexpected error occurs on submission",
                }),
            });
        }
        updateEmissionIntensity.mutate(
            {
                id: emissionIntensity.id,
                shouldMakeVisible: true,
            },
            {
                onSuccess: (response) => {
                    if (!response.ok) {
                        alertUser({
                            severity: "error",
                            value: formatMessage({
                                defaultMessage: "An unexpected error occured, please try again later",
                                description:
                                    "Title for an alert informing the user of an unexpected error occuring when submitting emission intensities",
                            }),
                        });
                    } else {
                        CARBON_EVENTS.INTENSITIES_COMPLETED_FLOW(
                            { page: "DataPreferences" },
                            { intensityType: emissionIntensity.type.toLowerCase() }
                        );
                        setOpenDialog("redirecting");
                        setTimeout(() => {
                            alertUser({
                                severity: "success",
                                value: formatMessage({
                                    defaultMessage: "Successfully saved!",
                                    description: "Success message when emission intensity is added successfully",
                                }),
                            });
                            navigate({
                                to: "/overview",
                                search: {
                                    tour: "emission-intensities-0",
                                    emissionIntensity: response.data.emissionIntensity.id,
                                },
                            });
                        }, 3_000); // 3 seconds
                    }
                },
            }
        );
    }

    const { getEmissionIntensity } = trpc.useUtils();

    async function onConfirm() {
        const { data: currentData } = await refetch();
        if (!currentData?.ok) {
            alertUser({
                severity: "error",
                value: formatMessage({
                    defaultMessage: "An unexpected error occured, please try again later",
                    description:
                        "Title for an alert informing the user of an unexpected error occuring when submitting emission intensities",
                }),
            });
        } else if (!currentData.data.isLevelComplete) {
            setOpenDialog("confirm-missing-data-submission");
        } else {
            onSubmit();
        }
    }

    async function onEmissionIntensityUnitChange(unit: string) {
        if (!emissionIntensity) {
            return;
        }
        updateEmissionIntensity.mutate(
            {
                id: emissionIntensity.id,
                unit,
            },
            {
                onSuccess(result) {
                    getEmissionIntensity.setData({ id: emissionIntensity.id }, (prev) => {
                        if (!result.ok) {
                            return prev;
                        }
                        if (!prev?.ok) {
                            return prev;
                        }
                        return {
                            ...prev,
                            data: {
                                ...prev.data,
                                emissionIntensity: result.data.emissionIntensity,
                            },
                        };
                    });
                },
            }
        );
    }

    const selectUnitLabel = formatMessage({
        defaultMessage: "Unit",
        description: "Label for unit text input when adding emission intensities",
    });

    const selectOptions = useEmissionIntensityUnits(emissionIntensity?.type);

    return (
        <AppLayout
            disableGutters
            sx={{
                // Allows the footer to stick to the bottom of the page
                "*:where(html[data-new-layout]) &": { paddingBottom: 0 },
                "*:where(html:not([data-new-layout])) &": { marginBottom: -5 },
            }}
        >
            <Stack height="100%" gap={2} justifyContent="space-between">
                <Stack flex="0 1 auto" minHeight={0} spacing={2}>
                    <RedirectDialog open={openDialog === "redirecting"} />
                    <ConfirmSubmissionDialog
                        open={openDialog === "confirm-missing-data-submission"}
                        onCancel={() => setOpenDialog(false)}
                        onConfirm={() => onSubmit()}
                    />
                    <Stack direction="column" spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Add {emissionIntensityName} information"
                                description="Header for enter information step when adding emission intensities"
                                values={{
                                    emissionIntensityName: emissionIntensity?.name.toLowerCase(),
                                }}
                            />
                        </Typography>
                        <Typography variant="textSm" color={(t) => t.palette.text.textHelper}>
                            <FormattedMessage
                                defaultMessage="Which data corresponds to the selected years and dimensions for revenue?"
                                description="Sub-header for enter information step wehn adding emission intensities"
                            />
                        </Typography>
                    </Stack>
                    <Grid direction="row" alignItems="flex-end" justifyContent="space-between" container>
                        <Grid md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="select-unit-label">{selectUnitLabel}</InputLabel>
                                <Select
                                    labelId="select-unit-label"
                                    value={emissionIntensity?.unit ?? "MNOK"}
                                    onChange={(e) => {
                                        onEmissionIntensityUnitChange(e.target.value);
                                    }}
                                    label={selectUnitLabel}
                                >
                                    {selectOptions.map((option) => (
                                        <MenuItem value={option.value} key={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid sx={{ height: "2.5rem" }}>
                            <LoadingIndicator
                                loading={isSetEmissionIntensityValueLoading}
                                success={isSuccess}
                                error={isError}
                            />
                        </Grid>
                    </Grid>
                    {data?.ok === false && (
                        <Typography variant="textSm" color="error">
                            <FormattedMessage
                                defaultMessage="Failed to load data, error code: {code}."
                                description="Error message when data failed to load"
                                values={{
                                    code: data.error.code,
                                }}
                            />
                        </Typography>
                    )}
                    {data?.ok && (
                        <EmissionIntensityDataGrid
                            highlightFirstCell={highlightFirstCell}
                            onCellEditStart={onEditStart}
                            onRowEditStart={onEditStart}
                            {...props}
                        />
                    )}
                </Stack>

                <StepFooter>
                    <Stack direction="row" justifyContent="space-between">
                        <RouteButton color="ghostGray" size="small" to="/overview">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel action" />
                        </RouteButton>
                        <Stack direction="row" spacing={2}>
                            {emissionIntensity && (
                                <RouteButton
                                    color="secondary"
                                    size="small"
                                    to="/data-preferences/emission-intensities/new/step/level"
                                    search={(prev) => ({
                                        ...prev,
                                        type: emissionIntensity.type,
                                        name: emissionIntensity.name,
                                        unit: emissionIntensity.unit,
                                        fromYear: emissionIntensity.startYear,
                                        toYear: emissionIntensity.endYear,
                                    })}
                                >
                                    <FormattedMessage defaultMessage="Back" description="Previous step" />
                                </RouteButton>
                            )}
                            {!isLoading && !emissionIntensity && (
                                <RouteButton
                                    color="secondary"
                                    size="small"
                                    to="/data-preferences/emission-intensities/new/select-intensity"
                                >
                                    <FormattedMessage defaultMessage="Back" description="Previous step" />
                                </RouteButton>
                            )}

                            <Button size="small" onClick={() => onConfirm()}>
                                <FormattedMessage
                                    defaultMessage="Confirm"
                                    description="Confirm action when submitting emission intensities"
                                />
                            </Button>
                        </Stack>
                    </Stack>
                </StepFooter>
            </Stack>
        </AppLayout>
    );
};

export { EnterInformation };
