import { addDataSourceDetailsRoute } from "@/Router/DataPreferencesRoute";
import { useNavigate } from "@tanstack/react-router";
import { defineMessages } from "react-intl";
import { MenuOption } from "./types";

const useGetMessages = () => {
    const messages = defineMessages({
        spend: {
            id: "addDataSourceType.spend",
            defaultMessage: "Spend data",
            description: "Spend data radio button",
        },
        supplier: {
            id: "addDataSourceType.supplier",
            defaultMessage: "Supplier data",
            description: "Supplier data radio button",
        },
        energy: {
            id: "addDataSourceType.energy",
            defaultMessage: "Electricity and district heating",
            description: "Energy data radio button",
        },

        airtravel: {
            id: "addDataSourceType.airTravel",
            defaultMessage: "Air travel",
            description: "Air travel data radio button",
        },
        other: {
            id: "addDataSourceType.other",
            defaultMessage: "Other",
            description: "Other radio button",
        },
    });
    return messages;
};

export const useGetMenuOptions = () => {
    const navigate = useNavigate();
    const messages = useGetMessages();
    const options: MenuOption[] = [
        {
            value: "spend",
            message: messages.spend,
            tableType: "spend",
            navigateFn: () => {
                navigate({ to: addDataSourceDetailsRoute.fullPath, params: { dataSourceType: "spend" } });
            },
        },
        {
            value: "supplier",
            message: messages.supplier,
            tableType: "supplier",
            navigateFn: () => {
                navigate({ to: addDataSourceDetailsRoute.fullPath, params: { dataSourceType: "supplier" } });
            },
        },
        {
            value: "energy",
            message: messages.energy,
            tableType: "energy",
            navigateFn: () => {
                navigate({
                    to: addDataSourceDetailsRoute.fullPath,
                    params: { dataSourceType: "energy" },
                });
            },
        },
        {
            value: "airTravel",
            message: messages.airtravel,
            tableType: "other",
            navigateFn: () => {
                navigate({
                    to: addDataSourceDetailsRoute.fullPath,
                    params: { dataSourceType: "airTravel" },
                });
            },
        },
        {
            value: "other",
            message: messages.other,
            tableType: "other",
            navigateFn: () => {
                navigate({
                    to: addDataSourceDetailsRoute.fullPath,
                    params: { dataSourceType: "other" },
                });
            },
        },
    ];
    return { options, messages };
};
