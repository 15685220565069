import { RouteButton } from "@/Components/RouteButton";
import { usePermission } from "@/lib/permissions/usePermissions";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationCircle } from "@ignite-analytics/icons";
import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGetSearchParamFilters, useSelectedElectricityBasis, useSelectedYear } from "../../hooks";
import { EmissionIntensityCard, EmissionIntensityCardProps, EmissionIntensitySelect } from "./EmissionIntensityCard";
import { ErrorEmissionIntensityCard } from "./ErrorEmissionIntensityCard";
import { LoadingEmissionIntensityCard } from "./LoadingEmissionIntensityCard";

const NotFoundEmissionIntensityCard: React.FC = () => {
    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={
                    <FormattedMessage
                        defaultMessage="Emission Intensity"
                        description="Header for emission intensity widget on the overview page when the emission intensity is not found"
                    />
                }
                action={<EmissionIntensitySelect />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text.textSecondary,
                }}
            />
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Stack alignItems="center" justifyContent="center">
                        <ShapeIcon size="small" variant="outline" color="error">
                            <ExclamationCircle />
                        </ShapeIcon>
                    </Stack>
                    <Typography variant="textSm" color={(t) => t.palette.text.textBody}>
                        <FormattedMessage
                            defaultMessage="Unable to find the emission intensity, please select one from the dropdown."
                            description="Description for the emission intensity card on the overview page when the emission intensity is not found"
                        />
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions>
                <RouteButton color="secondary" size="xsmall" to="/data-preferences/emission-intensities" disabled>
                    <FormattedMessage
                        defaultMessage="Edit data"
                        description="Label for the button to edit data in the emission intensity card on the overview page"
                    />
                </RouteButton>
            </CardActions>
        </Card>
    );
};

type Props = {
    emissionIntensityId: string;
};

const NonSpendEmissionIntensityCard: React.FC<Props> = ({ emissionIntensityId }) => {
    const { formatMessage } = useIntl();

    const electricity = useSelectedElectricityBasis();
    const filters = useGetSearchParamFilters();
    const year = useSelectedYear();
    const isEditor = usePermission({ permission: "write" });
    const { data, isLoading, isError } = trpc.getEmissionIntensityFactor.useQuery({
        emissionIntensityId,
        electricity,
        filters,
        year,
    });

    if (isLoading) {
        return <LoadingEmissionIntensityCard />;
    }

    if (isError) {
        return <ErrorEmissionIntensityCard />;
    }

    if (data.ok === false) {
        if (data.error.code === "ERR_NOT_FOUND") {
            return <NotFoundEmissionIntensityCard />;
        }
    }

    const emissionIntensityFactor = data?.ok ? data.data.emissionIntensityFactor : undefined;
    const changeSinceLastPeriod = data?.ok ? data.data.changeSinceLastPeriod : undefined;
    const emissionIntensityUnit = data?.ok ? data.data.emissionIntensityUnit : undefined;
    const emissionsUnit = data?.ok ? data.data.emissionsUnit : undefined;
    const emissionIntensityType = data?.ok ? data.data?.emissionIntensityType : undefined;
    let units: EmissionIntensityCardProps["units"];
    if (emissionIntensityUnit && emissionsUnit) {
        units = {
            emissions: emissionsUnit,
            emissionIntensity: emissionIntensityUnit,
        };
    }

    let header: string;
    switch (emissionIntensityType) {
        case "Revenue":
            header = formatMessage({
                defaultMessage: "Emissions per revenue",
                description: "Header for the emission intensity per revenue card",
            });
            break;
        case "Employees":
            header = formatMessage({
                defaultMessage: "Emissions per employee",
                description: "Header for the emission intensity per employee card",
            });
            break;
        default:
            header = formatMessage(
                {
                    defaultMessage: "Emissions per {unit}",
                    description: "Header for the custom emission intensity card",
                },
                { unit: emissionIntensityUnit }
            );
            break;
    }

    return (
        <EmissionIntensityCard
            emissionIntensityFactor={emissionIntensityFactor}
            changeSinceLastPeriod={changeSinceLastPeriod}
            units={units}
            header={header}
            action={
                <RouteButton
                    color="secondary"
                    size="xsmall"
                    to="/data-preferences/emission-intensities/$emissionIntensityId"
                    disabled={!isEditor}
                    params={{
                        emissionIntensityId,
                    }}
                    search
                    onClick={() => {
                        CARBON_EVENTS.INTENSITIES_EDIT_CLICKED(
                            { page: "Overview" },
                            { intensityType: emissionIntensityType?.toLowerCase() }
                        ).track();
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Edit data"
                        description="Label for the button to edit data in the emission intensity card on the overview page"
                    />
                </RouteButton>
            }
        />
    );
};

export { NonSpendEmissionIntensityCard };
