import { useMatchRoute, useRouterState } from "@tanstack/react-router";
import { useEffect } from "react";
import { useIntl } from "react-intl";

type Breadcrumb = {
    href?: string;
    text: string;
};

const useBreadcrumbsList = (): Breadcrumb[] => {
    const { formatMessage } = useIntl();
    const matches = useMatchRoute();
    const { location } = useRouterState();
    const basePath = "/carbon";

    const homeBreadcrumb: Breadcrumb = {
        text: formatMessage({
            defaultMessage: "Carbon accounting",
            description: "Breadcrumb for the carbon accounting app",
        }),
        href: `${basePath}/overview`,
    };

    if (matches({ to: "/top-contributors" })) {
        return [
            homeBreadcrumb,
            {
                text: formatMessage({ defaultMessage: "Top contributors", description: "Top contributors breadcrumb" }),
            },
        ];
    }
    if (location.pathname.includes("/activities/edit")) {
        return [
            homeBreadcrumb,
            {
                text: formatMessage({ defaultMessage: "Activity log", description: "Activity log breadcrumb" }),
            },
        ];
    }
    if (location.pathname.includes("/activities/add-activity")) {
        return [
            homeBreadcrumb,
            {
                text: formatMessage({ defaultMessage: "Add activity", description: "Add activity breadcrumb" }),
            },
        ];
    }
    if (matches({ to: "/emission-factors/$factorType", params: { factorType: "activity" } })) {
        return [
            homeBreadcrumb,
            {
                text: formatMessage({ defaultMessage: "Activity log", description: "Activity log breadcrumb" }),
                href: `${basePath}/activities/edit`,
            },
            {
                text: formatMessage({
                    defaultMessage: "Emission factors",
                    description: "Activity emission factors breadcrumb",
                }),
            },
        ];
    }
    if (location.pathname.includes("/data-preferences")) {
        const dataPreferencesBreadcrumb: Breadcrumb = {
            text: formatMessage({ defaultMessage: "Settings", description: "Settings breadcrumb" }),
            href: `${basePath}/data-preferences/data-sources/overview`,
        };
        if (location.pathname.includes("/data-preferences/data-sources")) {
            return [
                homeBreadcrumb,
                dataPreferencesBreadcrumb,
                {
                    text: formatMessage({ defaultMessage: "Data sources", description: "Data sources breadcrumb" }),
                },
            ];
        }
        if (location.pathname.includes("/data-preferences/tags")) {
            return [
                homeBreadcrumb,
                dataPreferencesBreadcrumb,
                {
                    text: formatMessage({ defaultMessage: "Tags", description: "Tags breadcrumb" }),
                },
            ];
        }
        if (location.pathname.includes("/data-preferences/custom-categories")) {
            return [
                homeBreadcrumb,
                dataPreferencesBreadcrumb,
                {
                    text: formatMessage({
                        defaultMessage: "Custom categories",
                        description: "Custom categories breadcrumb",
                    }),
                },
            ];
        }
        if (location.pathname.includes("/data-preferences/emission-intensities")) {
            return [
                homeBreadcrumb,
                dataPreferencesBreadcrumb,
                {
                    text: formatMessage({
                        defaultMessage: "Emission intensities",
                        description: "Emission intensities breadcrumb",
                    }),
                },
            ];
        }
    }
    return [homeBreadcrumb];
};

const useBreadcrumbs = () => {
    const { location } = useRouterState();
    const breadcrumbs = useBreadcrumbsList();

    useEffect(() => {
        const data = {
            source: "ignite-breadcrumbs",
            payload: breadcrumbs,
        };
        window.postMessage(data, window.location.origin);
    }, [breadcrumbs, location.pathname]);
};

export { useBreadcrumbs };
