import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

type Props = {
    onClick: () => void;
    text: string;
    icon?: React.ReactNode;
};
export const ListActionItem: React.FC<Props> = ({ icon, onClick, text }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton onClick={onClick}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <Typography fontWeight={300} variant="textSm">
                    <ListItemText
                        primary={
                            <Typography variant="textSm" fontWeight={400}>
                                {text}
                            </Typography>
                        }
                    />
                </Typography>
            </ListItemButton>
        </ListItem>
    );
};
