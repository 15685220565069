import {
    DataGrid,
    GridToolbarActionButton,
    GridToolbarDeleteButton,
    GridToolbarFilterButton,
    GridToolbarSearchField,
    GridToolbarTotalItemsContainer,
} from "@/Components/DataGrid";
import { DeleteModal } from "@/Components/DeleteModal";
import { useGetCustomGridStyle } from "@/Containers/EditData/Table/hooks";
import { Plus } from "@ignite-analytics/icons";
import { Divider, Stack } from "@mui/material";
import {
    DataGridProProps,
    GridFilterModel,
    GridRowModel,
    GridToolbarContainer,
    useGridApiContext,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "@tanstack/react-router";
import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { CustomCategoryWithMetaData, DataGridFilterOptions } from "router";
import { useDataGridColumns, useDataGridHandlers, useDataGridRows } from "./hooks";

export type Row = GridRowModel<{
    id: string;
    name: string;
    scope: string;
    activityLevel: string;
    createdAt: Date;
    inUseByActivityNumber: number;
}>;

export type CustomCategoryData =
    | { status: "error" }
    | { status: "loading" }
    | { status: "success"; categories: CustomCategoryWithMetaData[] };

type FilterOptions = { status: "error" } | { status: "loading" } | { status: "success"; data: DataGridFilterOptions };

type Props = {
    data: CustomCategoryData;
    filterOptions: FilterOptions;
    deleteCustomCategoriesByIds: (ids: string[]) => void;
};

const isRowSelectable: DataGridProProps<Row>["isRowSelectable"] = (params) => {
    return params.row.inUseByActivityNumber === 0;
};

const GridToolbar = () => {
    const { formatMessage } = useIntl();
    const apiRef = useGridApiContext();
    const deleteLabel = formatMessage(
        {
            defaultMessage: `{numberOfSelectedCategories, plural,
                =0 {Delete # categories}
                one {Delete # category}
                other {Delete # categories}
            }`,
            description: "Aria label and tooltip for the delete button in the custom categories table",
        },
        {
            numberOfSelectedCategories: apiRef.current?.getSelectedRows().size,
        }
    );
    const addNewCategoryLabel = formatMessage({
        defaultMessage: "Add new category",
        description: "Add new category button",
    });
    return (
        <GridToolbarContainer>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Stack direction="row" gap={1}>
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                    <GridToolbarFilterButton />
                </Stack>
                <Stack direction="row" gap={1}>
                    <GridToolbarDeleteButton
                        slotProps={{
                            iconButton: {
                                "data-testid": "delete-categories",
                                "aria-label": deleteLabel,
                            },
                            tooltip: {
                                title: deleteLabel,
                            },
                        }}
                    />
                    <GridToolbarActionButton
                        slotProps={{
                            button: {
                                "data-testid": "add-custom-category",
                                children: addNewCategoryLabel,
                            },
                            tooltip: {
                                title: addNewCategoryLabel,
                            },
                        }}
                    />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};

const slots: DataGridProProps<Row>["slots"] = {
    toolbar: GridToolbar,
    actionIcon: Plus,
};

export const CustomCategoriesTable: React.FC<Props> = ({ data, deleteCustomCategoriesByIds, filterOptions }) => {
    const [categoriesToDelete, setCategoriesToDelete] = React.useState<string[]>([]);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [],
    });

    const customGridStyle = useGetCustomGridStyle();
    const apiRef = useGridApiRef();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const filterOptionsData = React.useMemo(() => {
        if (filterOptions.status === "success") {
            return filterOptions.data;
        }
        return { activityL1: [], activityL2: [], scope: [], dataSourceType: [] };
    }, [filterOptions]);

    const {
        onRowSelectionModelChange,
        onFilterModelChange,
        buildGetOnEditClick,
        onDelete,
        onSearch,
        buildGetOnNavigateToActivityClick,
    } = useDataGridHandlers(categoriesToDelete, setCategoriesToDelete, setFilterModel, deleteCustomCategoriesByIds);

    const columns = useDataGridColumns(buildGetOnEditClick, buildGetOnNavigateToActivityClick, filterOptionsData);
    const rows = useDataGridRows(data);

    const slotProps: DataGridProProps["slotProps"] = useMemo(
        () => ({
            toolbar: {
                filter: {
                    numActiveFilters: filterModel.items.length,
                },
                actionButton: {
                    onClick() {
                        navigate({ search: (prev) => ({ ...prev, newCategory: true }) });
                    },
                },
                deleteButton: {
                    onDelete() {
                        setOpenDelete(true);
                    },
                },
                search: {
                    onChange: onSearch,
                },
            },
        }),
        [filterModel.items.length, onSearch, navigate]
    );

    return (
        <>
            <DataGrid<Row>
                disableColumnReorder
                disableColumnMenu
                loading={data.status === "loading"}
                slots={slots}
                apiRef={apiRef}
                sx={customGridStyle}
                slotProps={slotProps}
                columns={columns}
                rows={rows}
                filterModel={filterModel}
                disableRowSelectionOnClick
                isRowSelectable={isRowSelectable}
                checkboxSelection
                onFilterModelChange={onFilterModelChange}
                onRowSelectionModelChange={onRowSelectionModelChange}
            />
            <DeleteModal
                onDelete={() => {
                    onDelete();
                    setOpenDelete(false);
                }}
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                title={formatMessage({
                    defaultMessage: "Delete custom categories",
                    description: "Delete custom categories modal title",
                })}
                subTitle={formatMessage(
                    {
                        defaultMessage: "Are you sure you want to delete these {number} categories?",
                        description: "Are you sure you want to delete these categories?",
                    },
                    { number: categoriesToDelete.length }
                )}
            />
        </>
    );
};
