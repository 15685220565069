import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { Typography, Grid, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    options: string[];
    tagType: { type: "custom" } | { type: "dataSource"; sourceName: string; numberOfTags: number };
};

export const TagOptions: React.FC<Props> = ({ options, tagType }) => {
    const moreOptionsNumber = useMemo(() => {
        if (tagType.type === "custom") {
            return undefined;
        }
        const totalNumberExtra = tagType.numberOfTags - options.length;
        return totalNumberExtra > 0 ? totalNumberExtra : undefined;
    }, [options, tagType]);

    if (!options.length) return null;

    return (
        <Stack gap={1}>
            <Typography variant="subtitle2">
                <Stack direction="row" gap={0.2} alignItems="center">
                    {tagType.type === "custom" ? (
                        <FormattedMessage
                            defaultMessage="Custom tag options"
                            description="Options for the Tags component"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Tags from {sourceName}"
                            description="Options for the Tags component"
                            values={{ sourceName: tagType.sourceName }}
                        />
                    )}
                </Stack>
            </Typography>
            <Stack gap={0.2}>
                <Grid container gap={1}>
                    {options.map((innerOption) => (
                        <Grid item key={innerOption}>
                            <SquareChip color={getChipColor(tagType.type === "custom" ? "green" : "blue")}>
                                <Typography>{innerOption}</Typography>
                            </SquareChip>
                        </Grid>
                    ))}
                </Grid>
                {moreOptionsNumber && (
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="+ {moreOptionsNumber} more"
                            description="More options for the Tags component"
                            values={{ moreOptionsNumber }}
                        />
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
