import { MissingSearch } from "@/Components/MIssingSearch";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { SearchField } from "@/Components/SearchField";
import { Box, Card, CardContent, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ScopeCategoryType, scope1Types, scope2Types, scope3Types } from "router";
import { TabSection } from "./TabSection";

type Props = {
    onChange?: (scopeCategory: ScopeCategoryType) => void;
};

export const SideMenuAddActivities: React.FC<Props> = ({ onChange }) => {
    const { formatMessage } = useIntl();
    const [categorySearch, setCategorySearch] = React.useState<string>("");

    const scopeMessages = useGetAllScopeMessages();

    const filteredScopes: ScopeCategoryType[][] = [scope1Types, scope2Types, scope3Types].map(
        (scopeTypes: readonly ScopeCategoryType[]) =>
            scopeTypes.filter((scopeType: ScopeCategoryType) => {
                if (!categorySearch) return true;
                const filterString = scopeMessages[scopeType].header.toLowerCase();
                return filterString.includes(categorySearch?.toLowerCase());
            })
    );
    const [filteredScope1, filteredScope2, filteredScope3] = filteredScopes;

    const noCategoriesFound = filteredScopes.every((categories) => categories.length === 0);
    return (
        <Box maxHeight="80vh" overflow="auto">
            <Card variant="outlined" component={Paper}>
                <CardContent>
                    <Stack gap={3}>
                        <Stack gap={2}>
                            <Typography variant="textLg">
                                <FormattedMessage
                                    defaultMessage="Select an emission category"
                                    description="Header for selecting emission category for manual activity data input"
                                />
                            </Typography>
                            <SearchField
                                data-testid="list-search-field"
                                autoFocus
                                defaultValue={categorySearch}
                                value={categorySearch}
                                onChange={(_e, value) => {
                                    setCategorySearch(value);
                                }}
                            />
                            {noCategoriesFound && (
                                <MissingSearch
                                    subTitle={formatMessage({
                                        defaultMessage: "We cannot find the category you are searching for.",
                                        description: "No categories found",
                                    })}
                                />
                            )}
                            <TabSection
                                onChange={onChange}
                                scopeTypes={filteredScope1}
                                headerMessage={formatMessage({
                                    defaultMessage: "Scope 1",
                                    description: "Scope 1",
                                })}
                            />
                            <TabSection
                                scopeTypes={filteredScope2}
                                headerMessage={formatMessage({
                                    defaultMessage: "Scope 2",
                                    description: "Scope 2",
                                })}
                            />
                            <TabSection
                                onChange={onChange}
                                scopeTypes={filteredScope3}
                                headerMessage={formatMessage({
                                    defaultMessage: "Scope 3",
                                    description: "Scope 3",
                                })}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};
