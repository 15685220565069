import { trpc } from "@/lib/trpc/client";
import { useAlert } from "@/Providers/Alerts";
import { DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { EmissionIntensityDataGridProps } from "./EmissionIntensityDataGrid";
import { InvalidEmissionIntensityValueError } from "./errors";
import { BusinessUnit, EmissionIntensity, EmissionIntensityValue, Row } from "./types";

import { yearColDef } from "./yearColDef";

/**
 * @returns A stable function that can be used to set an emission intensity value. Intended to be passed as
 * the `onEmissionIntensityValueChange` prop to the `EmissionIntensityDataGrid` component.
 *
 * @example
 * ```tsx
 * const onChange = useSetEmissionIntensityValue();
 * return <EmissionIntensityDataGrid onEmissionIntensityValueChange={onChange} />;
 * ```
 */
const useSetEmissionIntensityValue = () => {
    const setEmissionIntensityValue = trpc.setEmissionIntensityValue.useMutation();
    const { formatMessage } = useIntl();
    const onChange = useCallback(
        (emissionIntensityValue: EmissionIntensityValue) => {
            return setEmissionIntensityValue.mutate(emissionIntensityValue, {
                onSuccess(data, variables) {
                    if (!data.ok) {
                        switch (data.error.code) {
                            case "invalid_argument":
                                throw new InvalidEmissionIntensityValueError(
                                    formatMessage({
                                        defaultMessage: "Invalid value, must be a number",
                                        description:
                                            "Error message when the user tries to set an invalid emission intensity value and the server rejects it",
                                    }),
                                    {
                                        invalidValue: variables.value.toString(),
                                        rowId: variables.businessUnitId,
                                        year: variables.year,
                                    }
                                );
                        }
                    }
                },
            });
        },
        [setEmissionIntensityValue, formatMessage]
    );

    return { ...setEmissionIntensityValue, mutate: onChange };
};

const useOnYearRangeChange = (emissionIntensityId: string) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const updateEmissionIntensity = trpc.updateEmissionIntensity.useMutation();

    const handleYearChange = useCallback<(data: Partial<{ startYear: number; endYear: number }>) => void>(
        ({ endYear, startYear }) => {
            if (!emissionIntensityId) {
                return;
            }
            updateEmissionIntensity.mutate(
                {
                    id: emissionIntensityId,
                    startYear,
                    endYear,
                },
                {
                    onSuccess(result) {
                        if (!result.ok) {
                            alertUser({
                                value: formatMessage({
                                    defaultMessage: "Failed to update year range",
                                    description:
                                        "Alert message when failing to update the year range for emission intensities",
                                }),
                                severity: "error",
                            });
                        }
                    },
                }
            );
        },
        [updateEmissionIntensity, alertUser, formatMessage, emissionIntensityId]
    );

    return handleYearChange;
};

function useProcessRowUpdate(
    emissionIntensityId: string,
    onEmissionIntensityValueChange: (emissionIntensityValue: EmissionIntensityValue) => void
): DataGridProProps<Row>["processRowUpdate"] {
    const { formatMessage } = useIntl();

    const processRowUpdate: DataGridProProps<Row>["processRowUpdate"] = useCallback(
        function processRowUpdate(newRow: Row, oldRow: Row): Row {
            const updatedRow = { ...newRow };
            if (!emissionIntensityId) {
                return updatedRow;
            }

            const { id, entity, ...years } = updatedRow;
            Object.entries(years).forEach(([year, value]) => {
                if (value === undefined) {
                    return;
                }

                const hasChanged = value !== oldRow[year];
                if (!hasChanged) {
                    return;
                }

                let numericValue = 0;
                /**
                 * If the input field is just empty, or some invalid number, then the input
                 * field passes `''` as the value, so we need to account for that.
                 */
                if (typeof value === "string" && value.trim() !== "") {
                    numericValue = Number.parseFloat(value);
                } else if (typeof value === "number") {
                    numericValue = value;
                }

                /**
                 * If the value is not a number, throw an invalid emission intensity value error.
                 */
                if (Number.isNaN(numericValue)) {
                    throw new InvalidEmissionIntensityValueError(
                        formatMessage(
                            {
                                defaultMessage: "The value must be a number, got {invalidValue}",
                                description:
                                    "Error message when the user enters a non-numeric value in the emission intensity table",
                            },
                            {
                                invalidValue: value,
                            }
                        ),
                        {
                            invalidValue: value,
                            rowId: updatedRow.id,
                            year: Number.parseInt(year, 10),
                        }
                    );
                }

                if (value !== undefined && hasChanged) {
                    onEmissionIntensityValueChange({
                        businessUnitId: id,
                        id,
                        emissionIntensityId,
                        value: numericValue,
                        year: parseInt(year, 10),
                    });
                    // Update the row with the new value
                    updatedRow[year] = numericValue.toString();
                }
            });
            return updatedRow;
        },
        [emissionIntensityId, onEmissionIntensityValueChange, formatMessage]
    );
    return processRowUpdate;
}
const useOnProcessRowUpdateError = (): EmissionIntensityDataGridProps["onProcessRowUpdateError"] => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const onProcessRowUpdateError = useCallback<NonNullable<DataGridProProps["onProcessRowUpdateError"]>>(
        (error) => {
            if (error instanceof InvalidEmissionIntensityValueError) {
                alertUser({
                    severity: "error",
                    title: formatMessage({
                        defaultMessage: "An error occurred when updating the emission intensity value",
                        description: "Error message title when updating the emission intensity value",
                    }),
                    value: error.message,
                });
                return;
            }
            alertUser({
                severity: "error",
                value: formatMessage({
                    defaultMessage: "An error occurred when updating the emission intensity value",
                    description: "Error message title when updating the emission intensity value",
                }),
            });
        },
        [alertUser, formatMessage]
    );
    return onProcessRowUpdateError;
};

/**
 * Returns the rows for the emission intensity grid for the given emission intensity ID and level.
 */
function useEmissionIntensityDataGridData({
    emissionIntensity,
    businessUnits,
}: {
    emissionIntensity?: EmissionIntensity;
    businessUnits: BusinessUnit[];
}): {
    rows: Row[];
    columns: GridColDef<Row>[];
} {
    const { formatMessage } = useIntl();
    const { rows, columns } = useMemo<{
        rows: Row[];
        columns: GridColDef<Row>[];
    }>(() => {
        const _columns: GridColDef<Row>[] = [
            {
                field: "entity",
                headerName: formatMessage({
                    defaultMessage: "Entity",
                    description: "Entity column header in emission intensity table",
                }),
                disableColumnMenu: true,
                flex: 1,
                resizable: false,
                minWidth: 200,
            },
        ];

        if (!emissionIntensity) {
            return { rows: [], columns: _columns };
        }

        emissionIntensity.years.forEach((year) => {
            _columns.push({
                field: year.toString(),
                headerName: year.toString(),
                ...yearColDef,
            });
        });

        const _rows = businessUnits.map((businessUnit) => {
            const row: Row = {
                id: businessUnit.id,
                entity: businessUnit.name,
            };
            businessUnit.emissionIntensityValues?.forEach((emissionIntensityValue) => {
                /**
                 * Setting the value as a number here enables automatic number formatting in the data grid,
                 * whereas setting a string here does not.
                 */
                row[emissionIntensityValue.year.toString()] = emissionIntensityValue.value;
            });
            return row;
        });
        return { rows: _rows, columns: _columns };
    }, [emissionIntensity, businessUnits, formatMessage]);

    return { rows, columns };
}

/**
 * Hook that returns almost everything needed for the `EmissionIntensityDataGrid` component.
 * Ensures that anything returned from this hook is stable to prevent unnecessary re-renders of the
 * data grid.
 *
 * Parameter is intentionally not an object to prevent accidentally passing objects with unstable references.
 *
 * Handles the following:
 * - Rows and columns
 * - Changes to the year range
 * - Updating the emission intensity value
 * - Error handling for updating the emission intensity value
 * - Loading state
 *
 * @example
 * ```tsx
 * const { props, businessUnits, emissionIntensity } = useEmissionIntensityDataGrid(emissionIntensityId);
 * return <EmissionIntensityDataGrid {...props} />;
 * ```
 */
const useEmissionIntensityDataGrid = (
    emissionIntensityId: string,
    onEmissionIntensityValueChange: (emissionIntensityValue: EmissionIntensityValue) => void
): { props: EmissionIntensityDataGridProps; businessUnits: BusinessUnit[]; emissionIntensity?: EmissionIntensity } => {
    const { data, isLoading } = trpc.getEmissionIntensity.useQuery({ id: emissionIntensityId });
    let emissionIntensity: EmissionIntensity | undefined;
    let businessUnits: BusinessUnit[] = [];
    if (data?.ok) {
        emissionIntensity = data.data.emissionIntensity;
        businessUnits = data.data.businessUnits;
    }
    const { rows, columns } = useEmissionIntensityDataGridData({
        emissionIntensity,
        businessUnits,
    });

    const processRowUpdate = useProcessRowUpdate(emissionIntensityId, onEmissionIntensityValueChange);
    const onYearRangeChange = useOnYearRangeChange(emissionIntensityId);
    const onProcessRowUpdateError = useOnProcessRowUpdateError();
    const slotProps: DataGridProProps<Row>["slotProps"] = useMemo(
        () => ({ columnMenu: { onYearRangeChange } }),
        [onYearRangeChange]
    );
    const props = useMemo(() => {
        return {
            processRowUpdate,
            onYearRangeChange,
            onProcessRowUpdateError,
            rows,
            columns,
            loading: isLoading,
            slotProps,
        };
    }, [processRowUpdate, onYearRangeChange, onProcessRowUpdateError, rows, columns, isLoading, slotProps]);
    return {
        props,
        businessUnits,
        emissionIntensity,
    };
};

export { useEmissionIntensityDataGrid, useSetEmissionIntensityValue };
