import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationTriangle } from "@ignite-analytics/icons";
import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

const ConfirmSubmissionDialog: React.FC<Props> = ({ open, onCancel, onConfirm }) => {
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                <Stack spacing={4}>
                    <Stack spacing={1} alignItems="center">
                        <ShapeIcon size="medium" color="warning">
                            <ExclamationTriangle />
                        </ShapeIcon>
                        <Typography sx={{ mt: 2.5 }} variant="textLg" fontWeight={500} textAlign="center">
                            <FormattedMessage
                                defaultMessage="Missing data"
                                description="Header for dialog when some data is missing when submitting emission intensities"
                            />
                        </Typography>
                        <Typography variant="textSm" color={(t) => t.palette.text.textBody} textAlign="center">
                            <FormattedMessage
                                defaultMessage="You haven't filled in all information, so we won't be able to show you all your emission intensities data. Do you want to save and continue anyway?"
                                description="Subheader for dialog when some data is missing when submitting emission intensities"
                            />
                        </Typography>
                    </Stack>

                    <Stack spacing={1.5}>
                        <Button size="small" color="secondary" onClick={() => onConfirm()}>
                            <FormattedMessage
                                defaultMessage="Save and continue later"
                                description="Label for button to submit emission intensities even with missing data"
                            />
                        </Button>
                        <Button
                            size="small"
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            <FormattedMessage
                                defaultMessage="Add missing data"
                                description="Label for button to go back to editing emission intensities"
                            />
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export { ConfirmSubmissionDialog };
