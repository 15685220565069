import * as React from "react";
import Popover from "@mui/material/Popover";
import { Box, Grid, List, ListItem, ListItemText, ListSubheader } from "@mui/material";

export type InformationRow = {
    title: string;
    text: string | React.ReactNode;
};
type Props = {
    header: string;
    informationRows: InformationRow[];

    children: React.ReactNode;
};
export const PopoverWithText: React.FC<Props> = ({ header, informationRows, children }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget && setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Grid container alignItems="center" justifyContent="center" onClick={handlePopoverOpen}>
                {children}
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 0,
                    horizontal: 20,
                }}
            >
                <Box p={2} maxWidth={400}>
                    <List
                        dense
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {header}
                            </ListSubheader>
                        }
                    >
                        {informationRows.map((line) => (
                            <ListItem key={line.title}>
                                <ListItemText primary={line.title} secondary={line.text} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </div>
    );
};
