import { DeleteModal } from "@/Components/DeleteModal";
import { Trash } from "@ignite-analytics/icons";
import { CardContent, Grid, Stack } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import React from "react";
import { defineMessage, useIntl } from "react-intl";
import { DataSourceData, DataSourceIdentifier } from "../../types";
import { DataSourcesPageHeader } from "../DataSourcesPageHeader";
import { DataSourceCard } from "./Components/DataSourceCard";

type Props = {
    dataSources: DataSourceData;
    deleteDataSource: (source: DataSourceIdentifier) => void;
};
export const DataSourceGrid: React.FC<Props> = ({ dataSources, deleteDataSource }) => {
    const { formatMessage } = useIntl();
    const [openDelete, setOpenDelete] = React.useState(false);
    const [dataSourceToDelete, setDataSourceToDelete] = React.useState<DataSourceIdentifier | undefined>(undefined);

    const onDeleteDataSource = (id: DataSourceIdentifier) => {
        setDataSourceToDelete(id);
        setOpenDelete(true);
    };

    const onPerformDataSourceDelete = () => {
        if (!dataSourceToDelete) return;
        deleteDataSource(dataSourceToDelete);
        setDataSourceToDelete(undefined);
        setOpenDelete(false);
    };

    const { hasSpendOrSupplier, hasSources } = React.useMemo(() => {
        if (dataSources.status !== "success") return { hasSpendOrSupplier: false, hasSources: false };

        const hasSpend = dataSources.connections.some((connection) => {
            return (
                connection.formType === "spend" ||
                connection.formType === "spend-based" ||
                connection.formType === "supplier"
            );
        });
        return { hasSpendOrSupplier: hasSpend, hasSources: dataSources.connections.length > 0 };
    }, [dataSources]);

    return (
        <Stack direction="column">
            <DataSourcesPageHeader hasSpendOrSupplier={hasSpendOrSupplier} hasSources={hasSources} />
            <CardContent>
                <Grid container rowSpacing={1} columnSpacing={1} padding={0}>
                    {dataSources.status === "success" &&
                        dataSources.connections.map((connection) => {
                            return (
                                <DataSourceCard
                                    data={connection}
                                    key={connection.id}
                                    deleteDataSource={onDeleteDataSource}
                                />
                            );
                        })}
                </Grid>
            </CardContent>
            <Outlet />
            <DeleteModal
                onDelete={onPerformDataSourceDelete}
                open={openDelete}
                onClose={() => {
                    setDataSourceToDelete(undefined);
                    setOpenDelete(false);
                }}
                title={formatMessage({
                    defaultMessage: " Are you certain you want to remove this data source?",
                    description: "Remove data source",
                })}
                subTitle={formatMessage({
                    defaultMessage:
                        "This will remove emissions tied to this data source, but it won't be deleted from Ignite, so you can reconnect it later using 'Add Data Source'.",
                    description: "Are you sure you want to delete this data source?",
                })}
                deleteButtonTitle={defineMessage({
                    defaultMessage: "Remove data source",
                    description: "Remove data source",
                })}
                titleIcon={<Trash fontSize="small" />}
            />
        </Stack>
    );
};
