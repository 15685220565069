import { useSetSelectedScope } from "@/Providers/DrilldownContext";
import { getColor } from "@/lib/charts";
import { formatNumber } from "@/lib/primitives/numbers";
import { CircleSolid } from "@ignite-analytics/icons";
import { Box, List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    Scope1Type,
    Scope2Type,
    Scope3Type,
    isScope1Type,
    isScope2Type,
    isScope3Type,
    scope1Types,
    scope2Types,
    scope3Types,
} from "router";
import { useGetSideMenuOptions } from "./hooks";

type ListItem = ReturnType<typeof useGetSideMenuOptions>[number];

type ContentProps = {
    item: ListItem;
    emissionsValue?: number;
};

export const ListItemContent: React.FC<ContentProps> = ({ item, emissionsValue }) => {
    const getColorForItem = (scopeType: ListItem["value"]) => {
        if (isScope1Type(scopeType)) {
            const type: Scope1Type = scopeType;
            return getColor(scope1Types.indexOf(type), scope1Types.length);
        }
        if (isScope2Type(scopeType)) {
            const type: Scope2Type = scopeType;
            return getColor(scope2Types.indexOf(type), scope2Types.length);
        }
        if (isScope3Type(scopeType)) {
            const type: Scope3Type = scopeType;
            return getColor(scope3Types.indexOf(type), scope3Types.length);
        }
        const scopes = ["1", "2", "3"] as const;
        return getColor(scopes.indexOf(scopeType), scopes.length);
    };

    const { label, value } = item;
    const labelString = emissionsValue ? `${label} ${formatNumber(emissionsValue, 2)} tCO₂e` : label;
    return (
        <>
            <ListItemIcon sx={{ minWidth: 0, paddingRight: 1 }}>
                <CircleSolid style={{ color: getColorForItem(value) }} />
            </ListItemIcon>
            <Typography variant="caption" noWrap>
                {labelString}
            </Typography>
        </>
    );
};

type Props = {
    showSideMenuItems:
        | Record<"1" | "2" | "3", boolean>
        | Record<Scope1Type, boolean>
        | Record<Scope2Type, boolean>
        | Record<Scope3Type, boolean>;
};

export const ChartSideMenu: React.FC<Props> = ({ showSideMenuItems }) => {
    const sideMenuItems = useGetSideMenuOptions();
    const setSelectedScope = useSetSelectedScope();
    return (
        <Box minWidth={300}>
            <List>
                {sideMenuItems.map((item: ReturnType<typeof useGetSideMenuOptions>[number]) => {
                    const { value } = item;
                    if (!showSideMenuItems[value]) {
                        return null;
                    }
                    if (value === "1" || value === "2" || value === "3") {
                        return (
                            <ListItem disablePadding key={item.value}>
                                <ListItemButton
                                    sx={{ padding: 0 }}
                                    onClick={() => {
                                        setSelectedScope(value, "ChartGraph");
                                    }}
                                >
                                    <ListItemContent item={item} />
                                </ListItemButton>
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem disablePadding key={item.value}>
                            <ListItemContent item={item} />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};
