import { FixedCategoryActionPopover, PopoverInput } from "@/Components/ActionPopover/FixedCategoryActionPopover";
import { MissingData } from "@/Containers/LandingPage/LandingPageContent/components/MissingData";
import { useGetFill } from "@/Containers/LandingPage/LandingPageContent/useGetFill";
import { formatNumber } from "@/lib/primitives/numbers";
import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { Cell, Pie, PieChart, PieLabel, ResponsiveContainer, Tooltip } from "recharts";
import { ScopeCategory } from "router";
import { CustomLabel, PointData } from "../Label";

interface ChartData extends PointData {
    onClick?: () => void;
    name: string;
    value: number;
    category?: ScopeCategory;
}
type Props = {
    chartData: ChartData[];
    year: number;
};

export const PieChartChart: React.FC<Props> = ({ chartData, year }) => {
    const [popoverData, setPopoverData] = React.useState<PopoverInput>(null);
    const total = chartData?.reduce<number>((acc: number, curr) => curr.value + acc, 0);

    const handleBarClick = (e: React.MouseEvent<HTMLDivElement | SVGElement>, dataPoint: ChartData) => {
        e.stopPropagation();
        dataPoint.onClick?.();
        if (dataPoint.category) {
            setPopoverData({ total, year, category: dataPoint.category });
        }
    };
    const theme = useTheme();

    const getFill = useGetFill();

    const label: PieLabel = (props) => <CustomLabel {...props} theme={theme} />;

    if (total === 0) {
        return (
            <Box alignItems="center" justifyContent="center" height="100%" minHeight={410}>
                <MissingData />
            </Box>
        );
    }
    return (
        <Stack gap={2} height="100%" data-testid="scope-breakdown-pie-chart">
            <ResponsiveContainer minHeight={410}>
                <PieChart>
                    {popoverData ? (
                        <Tooltip
                            content={
                                <FixedCategoryActionPopover close={() => setPopoverData(null)} input={popoverData} />
                            }
                        />
                    ) : (
                        <Tooltip
                            itemStyle={{ fontFamily: theme.typography.fontFamily }}
                            formatter={(v) => `${formatNumber(Number(v), 2)} tCO₂e`}
                        />
                    )}
                    <Pie
                        label={label}
                        data={chartData}
                        startAngle={90}
                        endAngle={-270}
                        innerRadius="35%"
                        outerRadius="100%"
                        animationBegin={0}
                        animationDuration={500}
                        labelLine={false}
                        dataKey="value"
                    >
                        {chartData.map((data, index) => (
                            <Cell
                                onClick={(e) => handleBarClick(e, data)}
                                style={{
                                    cursor: "pointer",
                                    outline: "none",
                                    userSelect: "none",
                                }}
                                key={`cell-${data.category ?? data.name}`}
                                fill={getFill(index, chartData.length)}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Stack>
    );
};
