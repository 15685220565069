import { CARBON_EVENTS } from "@/lib/tracking";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack, DialogContent, DialogActions } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { EmissionDataTag, emissionDataTagSchema } from "router";
import { OptionsList } from "../../CustomOptionsList";
import { ChangeName } from "../ChangeName";

type EmissionTagInput = Omit<EmissionDataTag, "id" | "name">;

type Props = {
    name: string;
    setName: (name: string) => void;
    submit: (tag: Omit<EmissionDataTag, "id">) => void;
};

export const CustomOptionsForm: React.FC<Props> = ({ name, setName, submit }) => {
    const [editName, setEditName] = React.useState(false);

    const { control, handleSubmit, reset } = useForm<EmissionTagInput>({
        resolver: zodResolver(emissionDataTagSchema.omit({ id: true, name: true })),
        defaultValues: { options: [] },
    });

    const onSubmit = async (tag: EmissionTagInput) => {
        submit({ ...tag, name });
        reset();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={1} paddingTop={1}>
                <DialogContent>
                    <ChangeName
                        setEdit={setEditName}
                        edit={editName}
                        setName={(inputName) => setName(inputName)}
                        name={name}
                    />
                    <Controller
                        name="options"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <OptionsList
                                disabled={editName}
                                options={value}
                                addOption={(option) => {
                                    CARBON_EVENTS.CREATED_CUSTOM_OPTION_FOR_TAG({
                                        page: "DataPreferences",
                                        component: "Tags",
                                    }).track();

                                    onChange([...new Set([...value, option])]);
                                }}
                                deleteOption={(option) => {
                                    CARBON_EVENTS.DELETED_CUSTOM_OPTION_FOR_TAG({
                                        page: "DataPreferences",
                                        component: "Tags",
                                    }).track();
                                    onChange(value.filter((v) => v !== option));
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">
                        <FormattedMessage defaultMessage="Create" description="Create button for the Tags component" />
                    </Button>
                </DialogActions>
            </Stack>
        </form>
    );
};
