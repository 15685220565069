/* This component is based on MUIs own implementation of the filter panel.
 * It has been modified to allow for a custom filter panel.
 * The original can be found here:
 *  https://github.com/mui/mui-x/blob/1d7a4649d0abd0507f1022bc9e8312928f88a003/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterPanel.tsx#L16
 */
import { Button, Stack, Typography } from "@mui/material";
import {
    GridColDef,
    GridFilterForm,
    GridFilterItem,
    GridLogicOperator,
    GridPanelContent,
    GridPanelFooter,
    GridPanelWrapper,
    gridFilterModelSelector,
    gridFilterableColumnDefinitionsSelector,
    useGridApiContext,
    useGridRootProps,
    useGridSelector,
} from "@mui/x-data-grid-pro";
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps";
import React from "react";
import { FormattedMessage } from "react-intl";

const getGridFilter = (col: GridColDef): GridFilterItem => ({
    field: col.field,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    operator: col.filterOperators![0].value,
    id: Math.round(Math.random() * 1e5),
});

export const CustomFilterPanel = React.forwardRef<HTMLDivElement, GridProSlotProps["filterPanel"]>(
    function GridFilterPanel(props, ref) {
        const apiRef = useGridApiContext();
        const rootProps = useGridRootProps();
        const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
        const filterableColumns = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lastFilterRef = React.useRef<any>(null);
        const placeholderFilter = React.useRef<GridFilterItem | null>(null);

        const {
            logicOperators = [GridLogicOperator.And],
            columnsSort,
            filterFormProps,
            getColumnForNewFilter,
            children,
            ...other
        } = props ?? {};

        const applyFilter = apiRef.current.upsertFilterItem;

        const applyFilterLogicOperator = React.useCallback(
            (operator: GridLogicOperator) => {
                apiRef.current.setFilterLogicOperator(operator);
            },
            [apiRef]
        );

        const getDefaultFilter = React.useCallback((): GridFilterItem | null => {
            let nextColumnWithOperator;
            if (getColumnForNewFilter && typeof getColumnForNewFilter === "function") {
                // To allow override the column for default (first) filter
                const nextFieldName = getColumnForNewFilter({
                    currentFilters: filterModel?.items || [],
                    columns: filterableColumns,
                });

                if (nextFieldName === null) {
                    return null;
                }

                nextColumnWithOperator = filterableColumns.find(({ field }) => field === nextFieldName);
            } else {
                nextColumnWithOperator = filterableColumns.find((colDef) => colDef.filterOperators?.length);
            }

            if (!nextColumnWithOperator) {
                return null;
            }

            return getGridFilter(nextColumnWithOperator);
        }, [filterModel?.items, filterableColumns, getColumnForNewFilter]);

        const getNewFilter = React.useCallback((): GridFilterItem | null => {
            if (getColumnForNewFilter === undefined || typeof getColumnForNewFilter !== "function") {
                return getDefaultFilter();
            }

            const currentFilters = filterModel.items.length ? filterModel.items : [getDefaultFilter()].filter(Boolean);

            // If no items are there in filterModel, we have to pass defaultFilter
            const nextColumnFieldName = getColumnForNewFilter({
                currentFilters: currentFilters as GridFilterItem[],
                columns: filterableColumns,
            });

            if (nextColumnFieldName === null) {
                return null;
            }

            const nextColumnWithOperator = filterableColumns.find(({ field }) => field === nextColumnFieldName);

            if (!nextColumnWithOperator) {
                return null;
            }

            return getGridFilter(nextColumnWithOperator);
        }, [filterModel.items, filterableColumns, getColumnForNewFilter, getDefaultFilter]);

        const items = React.useMemo<GridFilterItem[]>(() => {
            if (filterModel.items.length) {
                return filterModel.items;
            }

            if (!placeholderFilter.current) {
                placeholderFilter.current = getDefaultFilter();
            }

            return placeholderFilter.current ? [placeholderFilter.current] : [];
        }, [filterModel.items, getDefaultFilter]);

        const hasMultipleFilters = items.length > 1;

        const addNewFilter = () => {
            const newFilter = getNewFilter();
            if (!newFilter) {
                return;
            }
            apiRef.current.upsertFilterItems([...items, newFilter]);
        };

        const deleteFilter = React.useCallback(
            (item: GridFilterItem) => {
                const shouldCloseFilterPanel = items.length === 1;
                apiRef.current.deleteFilterItem(item);
                if (shouldCloseFilterPanel) {
                    apiRef.current.hideFilterPanel();
                }
            },
            [apiRef, items.length]
        );

        const handleRemoveAll = () => {
            if (items.length === 1 && items[0].value === undefined) {
                apiRef.current.deleteFilterItem(items[0]);
                apiRef.current.hideFilterPanel();
            }
            apiRef.current.setFilterModel({ ...filterModel, items: [] });
        };

        React.useEffect(() => {
            if (
                logicOperators.length > 0 &&
                filterModel.logicOperator &&
                !logicOperators.includes(filterModel.logicOperator)
            ) {
                applyFilterLogicOperator(logicOperators[0]);
            }
        }, [logicOperators, applyFilterLogicOperator, filterModel.logicOperator]);

        React.useEffect(() => {
            if (items.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                lastFilterRef.current!.focus();
            }
        }, [items.length]);

        return (
            <GridPanelWrapper ref={ref} {...other}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" p={1} pl={2}>
                    <Typography>
                        <FormattedMessage
                            defaultMessage="Edit filters"
                            description="Button label for the filter panel title"
                        />
                    </Typography>
                    <Button
                        onClick={handleRemoveAll}
                        startIcon={<rootProps.slots.filterPanelRemoveAllIcon />}
                        {...rootProps.slotProps?.baseButton}
                        color="secondary"
                        variant="text"
                    >
                        <FormattedMessage
                            defaultMessage="Clear filters"
                            description="Button label for the filter panel clear all button"
                        />
                    </Button>
                </Stack>
                <GridPanelContent>
                    {items.map((item, index) => (
                        <GridFilterForm
                            key={item.id == null ? index : item.id}
                            item={item}
                            applyFilterChanges={applyFilter}
                            deleteFilter={deleteFilter}
                            hasMultipleFilters={hasMultipleFilters}
                            showMultiFilterOperators={false}
                            disableMultiFilterOperator={false}
                            applyMultiFilterOperatorChanges={applyFilterLogicOperator}
                            focusElementRef={index === items.length - 1 ? lastFilterRef : null}
                            logicOperators={undefined}
                            logicOperatorInputProps={{}}
                            columnsSort={columnsSort}
                            {...filterFormProps}
                        />
                    ))}
                </GridPanelContent>
                <GridPanelFooter>
                    <Button
                        onClick={addNewFilter}
                        startIcon={<rootProps.slots.filterPanelAddIcon />}
                        {...rootProps.slotProps?.baseButton}
                    >
                        <FormattedMessage
                            defaultMessage="Add filter"
                            description="Button label for the filter panel add filter button"
                        />
                    </Button>
                </GridPanelFooter>
            </GridPanelWrapper>
        );
    }
);

/**
 * Demos:
 * - [Filtering - overview](https://mui.com/x/react-data-grid/filtering/)
 *
 * API:
 * - [GridFilterPanel API](https://mui.com/x/api/data-grid/grid-filter-panel/)
 */
export { getGridFilter };
