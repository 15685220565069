import { hasPermissionQueryOptions } from "@/lib/permissions/usePermissions";
import { QueryClient } from "@tanstack/react-query";
import { redirect } from "@tanstack/react-router";

/**
 * A function to check if the user has the editor permission, redirects to the overview page if the user is not an editor.
 * Use this function in the beforeLoad of a route.
 * @param options An object with the router context
 */
const isEditorOrRedirect = async (options: { context: { queryClient: QueryClient } }) => {
    const hasPermission = await options.context.queryClient.ensureQueryData(
        hasPermissionQueryOptions({ permission: "write" })
    );
    if (!hasPermission) {
        throw redirect({
            to: "/overview",
        });
    }
    return options;
};

export { isEditorOrRedirect };
