/* eslint-disable react/style-prop-object */
import { RouteButton } from "@/Components/RouteButton";
import { ArrowRight } from "@ignite-analytics/icons";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    LinearProgressProps,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { ERROR, LOADING, useSelectedYear, useTotal } from "../../hooks";

const ActivityBasedShareContent: React.FC<{ loading?: boolean; error?: boolean; share: number }> = ({
    loading,
    error,
    share,
}) => {
    if (loading) {
        return (
            <>
                <Skeleton variant="text" width={100} height={44} />
                <Skeleton variant="rectangular" width="100%" height={10} />
            </>
        );
    }
    if (error) {
        return (
            <Typography>
                <FormattedMessage
                    defaultMessage="An unexpected error occurred"
                    description="Used when summary numbers fail to load"
                />
            </Typography>
        );
    }

    let color: LinearProgressProps["color"] = "success";
    if (share < 0.3) {
        color = "error";
    } else if (share < 0.7) {
        color = "warning";
    }

    return (
        <Stack spacing={1}>
            <Typography variant="h4" fontWeight={500}>
                <FormattedNumber maximumFractionDigits={2} value={share} style="percent" />
            </Typography>
            <LinearProgress color={color} variant="determinate" value={share * 100} />
            <Typography color={(t) => t.palette.text.textHelper} variant="textXs">
                <FormattedMessage
                    defaultMessage="Add activity data to increase accuracy"
                    description="Text to encourage users to add activity data in the widget showing the share of activity based emissions on the overview page"
                />
            </Typography>
        </Stack>
    );
};

const useActivityBasedShare = (year: number) => {
    const totalEmissions = useTotal({ type: "total" }, year);
    const activityBased = useTotal({ type: "total" }, year, "activityData");

    const loading = totalEmissions === LOADING || activityBased === LOADING;
    const error = totalEmissions === ERROR || activityBased === ERROR;
    let share = 0;
    if (!loading && !error && activityBased !== 0) {
        share = activityBased / totalEmissions;
    }
    return { loading, error, share };
};

const ActivitiesShareOfEmissionsCard: React.FC = () => {
    const year = useSelectedYear();
    const { loading, error, share } = useActivityBasedShare(year);

    return (
        <Card component={Stack} justifyContent="space-between" height="100%">
            <CardHeader
                title={
                    <FormattedMessage
                        defaultMessage="Activity based share of emissions (vs. spend based data)"
                        description="Title for summary number"
                    />
                }
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text.textSecondary,
                }}
            />
            <CardContent height="100%" component={Stack} spacing={1} direction="column">
                <ActivityBasedShareContent share={share} loading={loading} error={error} />
            </CardContent>
            <CardActions>
                <RouteButton
                    color="secondary"
                    to="/activities/add-activity/find-category"
                    endIcon={<ArrowRight />}
                    size="small"
                >
                    <FormattedMessage
                        defaultMessage="Add activity"
                        description="Label for button to add activity data in the share of activity based emissions card"
                    />
                </RouteButton>
            </CardActions>
        </Card>
    );
};

export default ActivitiesShareOfEmissionsCard;
