import { Skeleton, Stack } from "@mui/material";

export const TimelineSkeleton = () => (
    <Stack spacing={1} alignItems="center" paddingTop={3}>
        <Skeleton variant="rounded" width="16px" height="16px" />
        <Skeleton variant="rounded" width="10px" height="100px" />
        <Skeleton variant="rounded" width="16px" height="16px" />
        <Skeleton variant="rounded" width="10px" height="100px" />
    </Stack>
);
