import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { OverviewData } from "router";
import { getRelevantYears, getStructuredData } from "../helpers";
import CompactEmissionRow from "./CompactEmissionRow";

interface Props {
    data: OverviewData["emissionData"];
}

const CompactTable: React.FC<Props> = ({ data }) => {
    const relevantYears = getRelevantYears();
    const structuredData = getStructuredData(data);

    return (
        <TableContainer sx={{ backgroundColor: "white" }}>
            <Table width="100%">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "20%" }}>
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="Scope"
                                    description="Header for scope column of overview table"
                                />
                            </Typography>
                        </TableCell>
                        {relevantYears.map((year) => (
                            <TableCell key={year} align="right" sx={{ width: "20%" }}>
                                <Typography variant="textSm">{year}</Typography>
                            </TableCell>
                        ))}
                        <TableCell align="right" sx={{ width: "20%" }}>
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="Change ({previousReportingYear} - {currentReportingYear})"
                                    description="Header for pctChange in compact table"
                                    values={{
                                        previousReportingYear: relevantYears.slice(-2)[0],
                                        currentReportingYear: relevantYears.slice(-1)[0],
                                    }}
                                />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <CompactEmissionRow rowDescriptor="Scope 1" data={structuredData.scope1} />
                    <CompactEmissionRow rowDescriptor="Scope 2" data={structuredData.scope2} />
                    <CompactEmissionRow rowDescriptor="Scope 3" data={structuredData.scope3} />
                    <CompactEmissionRow rowDescriptor="Total" data={structuredData.total} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CompactTable;
