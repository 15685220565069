import { TypographyProps, useTheme } from "@mui/material";

export const useGetMenuItemStyles = () => {
    const theme = useTheme();
    const menuLinkStyle = {
        color: theme.palette.text.primary,
        textDecoration: "none",
        marginTop: "0.25rem",
        cursor: "pointer",
    };
    const activeMenuLinkStyle = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.focused,
        borderRadius: "8px 8px 0px 0px",
        marginTop: "0.25rem",
        borderStyle: "none none solid none",
        borderColor: theme.palette.primary.main,
        cursor: "pointer",
    };
    const typographyStyle: TypographyProps = { paddingX: 1, paddingY: 0.25, variant: "textSm" };
    const seeMoreTypographyStyle: TypographyProps = {
        color: theme.palette.info.light,
        variant: "textSm",
    };
    return { menuLinkStyle, activeMenuLinkStyle, typographyStyle, seeMoreTypographyStyle };
};
