import { addDataSourceTypeRoute } from "@/Router/DataPreferencesRoute";
import { Plus } from "@ignite-analytics/icons";
import { Button, CardHeader, Grid, Typography } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

type Props = {
    hasSpendOrSupplier?: boolean;
    hasSources?: boolean;
};
export const DataSourcesPageHeader: React.FC<Props> = ({ hasSpendOrSupplier, hasSources }) => {
    const navigate = useNavigate();
    return (
        <CardHeader
            title={
                <Typography variant="textLg">
                    <FormattedMessage defaultMessage="Data sources" description="Data sources title" />
                </Typography>
            }
            subheader={
                <Grid spacing={2} alignItems="flex-start" container>
                    <Grid item sm={8.5} md={9} lg={10}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Here you can extract data from your existing sources in Ignite or upload external data using Data module first, and then create a new data source here. If you need assistance, feel free to reach out to us."
                                description="Data sources title intro message"
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3.5} md={3} lg={2}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item>
                                {hasSources && (
                                    <Button
                                        startIcon={<Plus />}
                                        color="primary"
                                        onClick={() =>
                                            navigate({
                                                to: addDataSourceTypeRoute.fullPath,
                                                search: { hideSpendAndSupplier: !!hasSpendOrSupplier },
                                            })
                                        }
                                        size="small"
                                    >
                                        <Typography variant="inherit" noWrap>
                                            <FormattedMessage
                                                defaultMessage="Add data source"
                                                description="Add data source button title"
                                            />
                                        </Typography>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
