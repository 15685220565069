import { useAlert } from "@/Providers/Alerts";
import { addDataSourceDetailsRoute, addDataSourceTypeRoute, editDataSourceRoute } from "@/Router/DataPreferencesRoute";
import { trpc } from "@/lib/trpc/client";
import { useMatches, useSearch } from "@tanstack/react-router";
import React from "react";
import { useIntl } from "react-intl";

export const useMutationCleanupFunctions = () => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const utils = trpc.useContext();
    const onCreateSuccess = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Connection successfully added",
                description: "Alert message when adding a connection",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.allAddedConnections.cancel();
        utils.allAddedConnections.refetch();
        utils.addedConnections.cancel();
        utils.addedConnections.refetch();
        utils.supplierConnection.cancel();
        utils.supplierConnection.refetch();
    };
    const onEditSuccess = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Connection successfully edited",
                description: "Alert message after submitting edits to a connection",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.allAddedConnections.cancel();
        utils.allAddedConnections.refetch();
        utils.addedConnections.cancel();
        utils.addedConnections.refetch();
        utils.supplierConnection.cancel();
        utils.supplierConnection.refetch();
    };
    const onFinishedDelete = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Connection successfully removed",
                description: "Alert message when removing a connection",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.allAddedConnections.cancel();
        utils.allAddedConnections.refetch();
        utils.addedConnections.cancel();
        utils.addedConnections.refetch();
        utils.supplierConnection.cancel();
        utils.supplierConnection.refetch();
    };
    const onError = (error) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred while updating connections: {error}",
                    description: "Alert message when updating connections",
                },
                {
                    error: error.message,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };
    return { onCreateSuccess, onError, onFinishedDelete, onEditSuccess };
};

export const useGetCurrentFormRoute = () => {
    const matches = useMatches();
    const currentRouteId = React.useMemo(() => {
        if (matches.find((match) => match.routeId.includes(addDataSourceTypeRoute.id))) {
            return addDataSourceTypeRoute;
        }
        if (matches.find((match) => match.routeId.includes(addDataSourceDetailsRoute.id))) {
            return addDataSourceDetailsRoute;
        }
        return editDataSourceRoute;
    }, [matches]);
    return currentRouteId;
};

export const useFetchConnectionOptions = () => {
    const currentRoute = useGetCurrentFormRoute();
    const { tableId } = useSearch({ from: currentRoute.id });
    const tables = trpc.dataTables.useQuery();
    const dataColumns = trpc.dataColumns.useQuery({ filter: { tableId: tableId ?? "" } }, { enabled: !!tableId });
    const selectedTable =
        (tables.status === "success" && tables.data.dataTables.find((table) => table.dataTableId === tableId)) ??
        undefined;
    return { tables, dataColumns, selectedTable, tableId };
};
