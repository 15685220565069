import { EstimatesCell } from "@/Components/Estimates";
import { LoadingRows } from "@/Components/Loading/LoadingTable/LoadingRows";
import { ManageActivityDialog } from "@/Containers/ManageActivity";

import { emissionFactorsRoute } from "@/Router/EmissionFactors";
import { overviewRoute } from "@/Router/Overview";
import { getLabelForUser } from "@/lib/labelling/userLabel";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import {
    Alert,
    Avatar,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGetSearchParamFilters } from "../../hooks";
import { getDateRepresentation, getEmissionsFormattedFromActivityRow, useGetLowestLevelHandler } from "./helpers";
import { NoActivitiesCard } from "./noActivities";

const LatestActivitiesCard: React.FC = () => {
    const getLowestLevel = useGetLowestLevelHandler();
    const filters = useGetSearchParamFilters();
    const activities = trpc.activityDataInEditTable.useQuery(
        { filters },
        {
            trpc: {
                context: {
                    skipBatch: true,
                },
            },
        }
    );
    const intl = useIntl();
    const navigate = useNavigate();
    const handleActivityClick = (activityId: string) => {
        CARBON_EVENTS.LANDING_PAGE_RECENT_ACTIVITY_CLICKED({
            page: "Overview",
            component: "LatestActivitiesCard",
        }).track();
        navigate({ to: ".", search: (prev) => ({ ...prev, activityId }) });
    };

    if (activities.isSuccess && activities.data.activityData.length === 0) {
        return <NoActivitiesCard />;
    }
    return (
        <Stack spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="textLg" component="h2" fontWeight={500}>
                    <FormattedMessage
                        defaultMessage="Recently added activities"
                        description="Recently added activities"
                    />
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Button
                        variant="text"
                        color="linkPrimary"
                        onClick={() => {
                            CARBON_EVENTS.VIEW_EMISSION_FACTORS_CLICK({
                                page: "Overview",
                                component: "HeaderButton",
                            }).track();
                            navigate({
                                from: overviewRoute.fullPath,
                                to: emissionFactorsRoute.fullPath,
                                params: { factorType: "activity" },
                            });
                        }}
                    >
                        <FormattedMessage defaultMessage="Go to emission factors" description="Emission factors" />
                    </Button>
                    <Button
                        aria-label="view-all-activities"
                        size="small"
                        color="primary"
                        onClick={() => {
                            CARBON_EVENTS.VIEW_ALL_ACTIVITIES_CLICK({
                                page: "Overview",
                                component: "HeaderButton",
                            }).track();
                            navigate({ to: "/activities/edit" });
                        }}
                    >
                        <FormattedMessage description="View all" defaultMessage="View all" />
                    </Button>
                </Stack>
            </Stack>
            <TableContainer sx={{ backgroundColor: "white" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Activity" description="Activity" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Category" description="Category" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="User" description="User" />
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Date" />
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Emissions" />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.isError && <Alert severity="error">{activities.error.message}</Alert>}
                        {activities.isLoading && <LoadingRows numberOfRows={4} numberOfColumns={5} />}
                        {activities.isSuccess &&
                            activities.data.activityData.slice(0, 5).map((row) => {
                                const scopeInformation = getLowestLevel(row);
                                const userLabel = getLabelForUser(row.createdBy, intl);
                                return (
                                    <TableRow
                                        key={row.id}
                                        onClick={() => handleActivityClick(row.id)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <TableCell>
                                            <Typography variant="textSm" noWrap>
                                                {scopeInformation.lowestActivity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="textSm">{scopeInformation.category}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Avatar
                                                    alt={userLabel}
                                                    src="/"
                                                    sx={{
                                                        width: 25,
                                                        height: 25,
                                                        padding: null,
                                                        margin: null,
                                                        fontSize: "inherit",
                                                    }}
                                                />
                                                <Typography variant="textSm">{userLabel}</Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="textSm">
                                                {getDateRepresentation(row.addedAt)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <EstimatesCell emissions={getEmissionsFormattedFromActivityRow(row)} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ManageActivityDialog />
        </Stack>
    );
};

export default LatestActivitiesCard;
