function fromHexToRgb(hex: string): { r: number; g: number; b: number } {
    return {
        r: parseInt(hex.slice(1, 3), 16),
        g: parseInt(hex.slice(3, 5), 16),
        b: parseInt(hex.slice(5, 7), 16),
    };
}
function getColorInGradient(percent: number, startColor: string, endColor: string) {
    const start = fromHexToRgb(startColor);
    const end = fromHexToRgb(endColor);
    const r = Math.floor(start.r + (end.r - start.r) * percent);
    const g = Math.floor(start.g + (end.g - start.g) * percent);
    const b = Math.floor(start.b + (end.b - start.b) * percent);
    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}

export function getColor(index: number, total: number) {
    const darkColor = "#021F2D";
    const midColor = "#6BC4EC";
    const brightColor = "#E6F5FC";
    if (index / total > 0.5) {
        return getColorInGradient((index / total - 0.5) * 2, midColor, brightColor);
    }
    return getColorInGradient((index / total) * 2, darkColor, midColor);
}
