import { FormattedMessage } from "react-intl";

export const useGetMessage = (year, scope, total) => {
    return scope ? (
        <FormattedMessage
            defaultMessage="{year}: {total} tCO₂e"
            description="Category header for overview table"
            values={{
                year,
                total,
            }}
        />
    ) : (
        <FormattedMessage
            defaultMessage="Scope {scope} ({year}) - {total}  tCO₂e"
            description="Category header for overview table"
            values={{
                scope,
                year,
                total,
            }}
        />
    );
};
