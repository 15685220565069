import { getMessageForActivity } from "@/Containers/ManualEmissions/helpers.activityL1";
import { getScopeByCategory } from "@/Router/interfaces";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { GridComparatorFn, ValueOptions } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { z } from "zod";
import {
    ActivityDataForEditTable,
    ActivityInformation,
    DataGridFilterOptions,
    isActivityInformation,
    userData,
} from "router";
import type { Emissions, RowData } from "./types";

export const activityComparator: GridComparatorFn<ActivityInformation | undefined> = (v1, v2) => {
    if (!v2) {
        return -1;
    }
    if (!v1) {
        return 1;
    }
    if ((v1.label ?? v1.value) < (v2.label ?? v2.value)) {
        return -1;
    }
    return 1;
};

export const emissionsSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("regular"),
        estimate: z.number().nullable().optional(),
    }),
    z.object({
        type: z.literal("electricity"),
        estimateLocation: z.number().nullable().optional(),
        estimateMarket: z.number().nullable().optional(),
    }),
]);

export const addingInformationSchema = z.object({
    user: userData,
});

export const getRow = (row: ActivityDataForEditTable): RowData => {
    const {
        id,
        scopeType,
        activityL1,
        activityL2,
        region,
        value,
        unitOfMeasurement,
        estimate,
        estimateMarket,
        estimateLocation,
        emissionFactorMeta,
        from,
        to,
        createdBy,
        addedAt,
        supplier,
        tags,
        energyCertificate,
        exclusionFromConnection,
        spendCategory,
        businessUnit,
    } = row;
    const emissions: Emissions =
        estimateLocation && estimateLocation > 0
            ? {
                  type: "electricity",
                  estimateMarket: energyCertificate ? 0 : (estimateMarket ?? 0),
                  estimateLocation: estimateLocation ?? 0,
              }
            : {
                  type: "regular",
                  estimate,
              };
    return {
        id,
        source: exclusionFromConnection?.connection.name,
        category: scopeType,
        region,
        scope: getScopeByCategory(scopeType),
        activityL1: isActivityInformation(activityL1) ? activityL1 : undefined,
        activityL2: isActivityInformation(activityL2) ? activityL2 : undefined,
        unitOfMeasurement,
        value,
        emissions,
        to,
        from,
        supplierId: supplier?.id,
        supplierName: supplier?.label,
        addedAt,
        addingInformation: {
            user: createdBy,
        },
        hasExcludedEmissions: !!exclusionFromConnection,
        emissionFactor: emissionFactorMeta,
        tags: tags ? Object.entries(tags).map(([tagId, tag]) => ({ tagId, label: tag.label })) : [],
        energyCertificate: energyCertificate ?? false,
        businessUnit,
        spendCategory,
    };
};

export const useGetScopeOptionsAndMessages = () => {
    const { formatMessage } = useIntl();

    const messages = {
        scope1: formatMessage({
            defaultMessage: "Scope 1",
            description: "Scope 1",
        }),
        scope2: formatMessage({
            defaultMessage: "Scope 2",
            description: "Scope 2",
        }),
        scope3: formatMessage({
            defaultMessage: "Scope 3",
            description: "Scope 3",
        }),
    };
    const options: ValueOptions[] = [
        {
            value: "scope1",
            label: messages.scope1,
        },
        {
            value: "scope2",
            label: messages.scope2,
        },
        {
            value: "scope3",
            label: messages.scope3,
        },
    ];
    return { messages, options };
};

export const useGetCategoryOptionsAndMessages = (filterOptions?: DataGridFilterOptions) => {
    const messages = useGetAllScopeMessages();
    const options: ValueOptions[] = useMemo(
        () =>
            Object.keys(messages).map((key) => ({
                value: key,
                label: messages[key].header,
            })),
        [messages]
    );

    const filteredOptions: ValueOptions[] = useMemo(() => {
        if (!filterOptions) {
            return options;
        }
        return Object.keys(messages)
            .filter((key) => filterOptions.scope.includes(key))
            .map((key) => ({
                value: key,
                label: messages[key].header,
            }));
    }, [filterOptions, messages, options]);

    return { messages, options, filteredOptions };
};

export const useGetActivityOptions = (filterOptions: DataGridFilterOptions["activityL2"]) => {
    const { formatMessage } = useIntl();

    const options: ValueOptions[] = useMemo(
        () =>
            (filterOptions ?? []).map((activity) => {
                if (activity.type === "custom") {
                    return activity;
                }
                // As we want the best possible label we go a conditional route
                // 1. If we have a translated message for the activity level we use that
                // 2. If the activity has a label we use that
                // 3. If we have nothing else we use the value
                const message = getMessageForActivity(activity.value);
                if (message) {
                    return {
                        value: activity.value,
                        label: formatMessage(message),
                    };
                }
                if (activity.label) {
                    return {
                        value: activity.value,
                        label: activity.label,
                    };
                }
                return {
                    value: activity.value,
                    label: activity.value,
                };
            }),
        [formatMessage, filterOptions]
    );
    return options;
};
