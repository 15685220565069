import { OtherFormReturn } from "@/Router/interfaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, Radio, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { kgCO2e, tCO2e } from "router";
import { ButtonRow } from "../../../../Components/PostFormButton";
import { DataColumnSelect } from "../DataColumnSelect";
import { TagColumnSelect } from "../TagsColumnPicker";
import {
    getClassificationColumnByGlobalType,
    getRelationalColumnByGlobalType,
    getReturnValueFromOtherForm,
} from "../helpers";
import { useGetTableAndColumnMessages } from "../hooks";
import { DataColumn, OtherTableFormType, useGetOtherTableZodSchema } from "../interfaces";
import { useSpecificUnitMessages } from "../../Components/UnitPicker/helpers";
import { UnitPicker } from "../../Components/UnitPicker";

interface Props {
    onSubmitForm: (data: OtherFormReturn) => void;
    columns: DataColumn[];
    defaultValues: Partial<OtherTableFormType> | undefined;
    // eslint-disable-next-line react-redux/no-unused-prop-types
    tableId: string;
}

export const OtherDataTableConnectionForm: React.FC<Props> = ({ onSubmitForm, columns, defaultValues, tableId }) => {
    const [useFactorColumn, setUseFactorColumn] = React.useState(defaultValues?.emissionFactorColumn !== undefined);
    const otherTableZodSchema = useGetOtherTableZodSchema();

    const {
        dateColumnSelectMessage,
        calculatedEmissionsColumnSelectMessage,
        emissionStructureColumnSelectMessage,
        emissionFactorColumnSelectMessage,
        supplierColumnSelectMessage,
        activityValueColumnMessages,
    } = useGetTableAndColumnMessages();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<OtherTableFormType>({
        defaultValues: {
            emissionStructureColumn: getClassificationColumnByGlobalType(columns, "ghg_scopes_and_categories"),
            supplierColumn: getRelationalColumnByGlobalType(columns, "supplier_relation"),
            ...defaultValues,
        },
        resolver: zodResolver(otherTableZodSchema),
    });

    const unitMessages = useSpecificUnitMessages([kgCO2e, tCO2e]);

    const onSubmit: SubmitHandler<OtherTableFormType> = useCallback(
        (data) => {
            onSubmitForm(getReturnValueFromOtherForm(data));
            reset();
        },
        [onSubmitForm, reset]
    );
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
                <Controller
                    control={control}
                    name="unitOfMeasurement"
                    render={({ field: { onChange, value } }) => (
                        <UnitPicker
                            selectedValue={value}
                            onChange={onChange}
                            messages={unitMessages}
                            errorMessage={errors?.unitOfMeasurement?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="dateColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.dateColumn?.message}
                            label={dateColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="date"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="calculatedEmissionsColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.calculatedEmissionsColumn?.message}
                            label={
                                useFactorColumn ? activityValueColumnMessages : calculatedEmissionsColumnSelectMessage
                            }
                            onChange={onChange}
                            dataColumns={columns}
                            type="number"
                        />
                    )}
                />
                <Stack>
                    <Stack direction="row" alignItems="center">
                        <Grid minWidth={220}>
                            <Typography>
                                <FormattedMessage
                                    defaultMessage="Use emission factor column"
                                    description="Label for use factor column radio button"
                                />
                            </Typography>
                        </Grid>
                        <Radio checked={useFactorColumn} onChange={() => setUseFactorColumn((value) => !value)} />
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Grid minWidth={220}>
                            <Typography>
                                <FormattedMessage
                                    defaultMessage="Use pre-calculated emissions"
                                    description="Label for use calculated emissions column radio button"
                                />
                            </Typography>
                        </Grid>
                        <Radio checked={!useFactorColumn} onChange={() => setUseFactorColumn((value) => !value)} />
                    </Stack>
                </Stack>
                <Controller
                    control={control}
                    name="emissionFactorColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            disabled={!useFactorColumn}
                            value={value}
                            errorMessage={errors.emissionFactorColumn?.message}
                            label={emissionFactorColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="number"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="emissionStructureColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.emissionStructureColumn?.message}
                            label={emissionStructureColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="classification"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="supplierColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value ?? null}
                            errorMessage={errors.supplierColumn?.message}
                            label={supplierColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="relation"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="tagColumns"
                    render={({ field: { onChange, value } }) => (
                        <TagColumnSelect
                            columnsSelectedForTags={value || {}}
                            setColumnForTag={(tagId, column) => {
                                if (!column) {
                                    const { [tagId]: _, ...rest } = value || {};
                                    onChange(rest);
                                }
                                onChange({
                                    ...value,
                                    [tagId]: column,
                                });
                            }}
                            tableId={tableId}
                        />
                    )}
                />
                <ButtonRow />
            </Stack>
        </form>
    );
};
