import { ListItemText, MenuItem, Typography } from "@mui/material";
import React from "react";

interface Props {
    optionLabel: string;
    onClick: () => void;
}

export const OptionRow: React.FC<Props> = ({ onClick, optionLabel }) => {
    return (
        <MenuItem
            onKeyDown={(e) => {
                e.key === "Enter" && onClick();
            }}
            onClick={onClick}
        >
            <ListItemText>
                <Typography lineHeight={1} variant="inherit">
                    {optionLabel}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
};
