import { SearchField } from "@/Components/SearchField";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { useAlert } from "@/Providers/Alerts";
import { AppLayout } from "@/Components/Layout/AppLayout";
import { useFormatEmissionIntensityCurrency } from "@/Containers/useFormatEmissionIntensityCurrency";
import { Layers, Plus } from "@ignite-analytics/icons";
import { Box, Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { createLink, useNavigate, useParams } from "@tanstack/react-router";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCurrency } from "../../../useCurrency";
import { EmissionIntensityDataGrid, useEmissionIntensityDataGrid } from "../components/EmissionIntensityDataGrid";
import { LoadingIndicator } from "../components/EmissionIntensityDataGrid/LoadingIndicator";
import { useSetEmissionIntensityValue } from "../components/EmissionIntensityDataGrid/useEmissionIntensityDataGrid";
import { NewEmissionIntensityDialog } from "./components/NewEmissionIntensityDialog";
import { SwitchLevelDialog } from "./components/SwitchLevelDialog";

const LinkToggleButton = createLink(ToggleButton);

/**
 * Fetches the classification structure for the company and returns the depth of the structure and examples for each level.
 */
const useHasConfiguredCompanyStructure = (): {
    hasConfiguredCompanyStructure: boolean | undefined;
    loading: boolean | undefined;
} => {
    const result = trpc.getClassificationStructureDescription.useQuery({ type: "companyStructure" });
    if (result.status === "loading") return { loading: true, hasConfiguredCompanyStructure: undefined };
    if (result.status === "error") {
        if (result.error.data?.code === "NOT_FOUND") {
            return { hasConfiguredCompanyStructure: false, loading: false };
        }
    }
    return { hasConfiguredCompanyStructure: true, loading: false };
};

const EmissionIntensities: React.FC = () => {
    const { emissionIntensityId } = useParams({
        from: "/_containerLayout/data-preferences/emission-intensities/$emissionIntensityId",
    });

    const { data } = trpc.findManyEmissionIntensities.useQuery();
    const createEmissionIntensity = trpc.createEmissionIntensity.useMutation();

    const [openDialog, setOpenDialog] = useState<"switch-level" | "new-emission-intensity" | false>(false);
    const { mutate, isLoading, isError, isSuccess } = useSetEmissionIntensityValue();
    const { emissionIntensity, props } = useEmissionIntensityDataGrid(emissionIntensityId, mutate);

    const { hasConfiguredCompanyStructure, loading: hasConfiguredCompanyStructureLoading } =
        useHasConfiguredCompanyStructure();
    const currentLevel = emissionIntensity?.level;
    const updateEmissionIntensity = trpc.updateEmissionIntensity.useMutation();
    const getOrCreateEmissionIntensity = trpc.getOrCreateEmissionIntensity.useMutation();
    const companyCurrency = useCurrency();
    const formatCurrency = useFormatEmissionIntensityCurrency();

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const gridApiRef = useGridApiRef();
    const { findManyEmissionIntensities, getEmissionIntensity } = trpc.useUtils();
    const navigate = useNavigate();
    const moreThanTenRows = props.rows ? props.rows.length > 10 : false;

    function handleOnLevelSwitch({ level: _level }: { level: string }) {
        const level = Number.parseInt(_level, 10);
        updateEmissionIntensity.mutate(
            {
                id: emissionIntensityId,
                level,
            },
            {
                onSuccess(result) {
                    /**
                     * Update the cache with the new level, if the mutation was successful.
                     */
                    if (result.ok) {
                        CARBON_EVENTS.INTENSITIES_SWITCH_LEVEL({ page: "DataPreferences" }, { level }).track();
                        getEmissionIntensity.refetch({ id: emissionIntensityId });
                        alertUser({
                            value: formatMessage({
                                defaultMessage: "Level switched",
                                description:
                                    "Alert message when switching the level for which to see emission intensities",
                            }),
                            severity: "success",
                        });
                    } else {
                        alertUser({
                            value: formatMessage({
                                defaultMessage:
                                    "Failed to switch level. An unknown error occurred, please try again later.",
                                description:
                                    "Alert message when failing to switch the level for which to see emission intensities",
                            }),
                            severity: "error",
                        });
                    }
                },
            }
        );
        setOpenDialog(false);
    }

    const handleNewEmissionIntensitySubmit = ({ name, unit }: { name: string; unit: string }) => {
        createEmissionIntensity.mutate(
            {
                name,
                type: "Other",
                level: currentLevel ?? 0,
                unit,
                visible: true,
            },
            {
                onSuccess(result) {
                    if (result.ok) {
                        CARBON_EVENTS.INTENSITIES_ADD_CUSTOM(
                            { page: "DataPreferences" },
                            { level: currentLevel ?? 0 }
                        ).track();

                        /**
                         * Update the cached result for findMany with the new emission intensity
                         * to provide the user with instant feedback
                         */
                        findManyEmissionIntensities.setData(undefined, (prev) => {
                            if (prev) {
                                return {
                                    ...prev,
                                    emissionIntensities: [...prev.emissionIntensities, result.data.emissionIntensity],
                                    other: [...prev.other, result.data.emissionIntensity],
                                };
                            }
                            return {
                                emissionIntensities: [result.data.emissionIntensity],
                                other: [result.data.emissionIntensity],
                            };
                        });
                        navigate({
                            to: "/data-preferences/emission-intensities/$emissionIntensityId",
                            params: {
                                emissionIntensityId: result.data.emissionIntensity.id,
                            },
                        });
                    } else {
                        alertUser({
                            title: formatMessage({
                                defaultMessage: "Failed to create emission intensity",
                                description: "Alert title when failing to create emission intensity",
                            }),
                            value: result.error.code,
                            severity: "error",
                        });
                    }
                },
            }
        );
        setOpenDialog(false);
    };

    const handleGetOrCreateEmissionIntensity = ({ type }: { type: "Revenue" | "Employees" }) => {
        let name: string;
        let unit: string;
        if (type === "Revenue") {
            name = "Revenue";
            unit = formatCurrency({ currency: companyCurrency, modifier: "million" });
        } else {
            name = "Employees";
            unit = "FTEs";
        }
        getOrCreateEmissionIntensity.mutate(
            {
                where: {
                    type,
                },
                create: {
                    level: currentLevel ?? 0,
                    name,
                    type,
                    unit,
                },
            },
            {
                onSuccess(result) {
                    if (result.ok) {
                        navigate({
                            to: "/data-preferences/emission-intensities/$emissionIntensityId",
                            params: {
                                emissionIntensityId: result.data.emissionIntensity.id,
                            },
                            replace: true,
                        });
                    } else {
                        alertUser({
                            title: formatMessage({
                                defaultMessage: "Failed to get emission intensity, an unexpected error occurred",
                                description: "Alert title when failing to get or create emission intensity",
                            }),
                            value: result.error.code,
                            severity: "error",
                        });
                    }
                },
            }
        );
    };

    return (
        <AppLayout disableGutters>
            <Stack direction="column" spacing={2} height="100%" justifyContent="flex-start">
                <Stack spacing={3}>
                    <Stack direction="row" justifyContent="flex-start" spacing={1} alignItems="center">
                        <ToggleButtonGroup size="small" exclusive value={emissionIntensityId}>
                            {data?.revenue && (
                                <LinkToggleButton
                                    params={{ emissionIntensityId: data.revenue.id }}
                                    to="/data-preferences/emission-intensities/$emissionIntensityId"
                                    value={data.revenue.id}
                                >
                                    <FormattedMessage
                                        defaultMessage="Revenue"
                                        description="Revenue emission intensity"
                                    />
                                </LinkToggleButton>
                            )}
                            {!data?.revenue && (
                                <ToggleButton
                                    onClick={() => handleGetOrCreateEmissionIntensity({ type: "Revenue" })}
                                    value="revenue"
                                >
                                    <FormattedMessage
                                        defaultMessage="Revenue"
                                        description="Revenue emission intensity"
                                    />
                                </ToggleButton>
                            )}
                            {data?.employees && (
                                <LinkToggleButton
                                    params={{ emissionIntensityId: data.employees.id }}
                                    to="/data-preferences/emission-intensities/$emissionIntensityId"
                                    value={data.employees.id}
                                >
                                    <FormattedMessage
                                        defaultMessage="Employees"
                                        description="Employees emission intensity"
                                    />
                                </LinkToggleButton>
                            )}
                            {!data?.employees && (
                                <ToggleButton
                                    onClick={() => handleGetOrCreateEmissionIntensity({ type: "Employees" })}
                                    value="employees"
                                >
                                    <FormattedMessage
                                        defaultMessage="Employees"
                                        description="Employees emission intensity"
                                    />
                                </ToggleButton>
                            )}
                            {data?.other.map((denominator) => (
                                <LinkToggleButton
                                    key={denominator.id}
                                    params={{ emissionIntensityId: denominator.id }}
                                    to="/data-preferences/emission-intensities/$emissionIntensityId"
                                    value={denominator.id}
                                >
                                    {denominator.name}
                                </LinkToggleButton>
                            ))}
                        </ToggleButtonGroup>
                        <IconButton
                            color="secondary"
                            onClick={() => setOpenDialog("new-emission-intensity")}
                            size="small"
                            aria-label={formatMessage({
                                defaultMessage: "Add new emission intensity",
                                description: "Aria label for add new emission intensity button",
                            })}
                        >
                            <Plus />
                        </IconButton>
                        <NewEmissionIntensityDialog
                            open={openDialog === "new-emission-intensity"}
                            onClose={() => setOpenDialog(false)}
                            onSubmit={handleNewEmissionIntensitySubmit}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack>
                            <Typography
                                variant="textLg"
                                fontWeight={500}
                                color={(t) => t.palette.text.textPrimary}
                                gutterBottom
                            >
                                <FormattedMessage
                                    defaultMessage="Level {level}"
                                    description="Header describing for which level the emission intensities apply"
                                    values={{ level: currentLevel }}
                                />
                            </Typography>
                            <Typography variant="textSm" color={(t) => t.palette.text.textHelper}>
                                <FormattedMessage
                                    defaultMessage="Add, edit or delete data for your emission intensities"
                                    description="Subheader when viewing emission intensities"
                                />
                            </Typography>
                        </Stack>
                        {hasConfiguredCompanyStructure && (
                            <Button
                                startIcon={<Layers />}
                                size="small"
                                color="secondary"
                                onClick={() => setOpenDialog("switch-level")}
                                disabled={currentLevel === undefined || hasConfiguredCompanyStructureLoading}
                            >
                                <FormattedMessage
                                    defaultMessage="Switch level"
                                    description="Button to switch the level for which to see emission intensities"
                                />
                            </Button>
                        )}
                        {currentLevel !== undefined && (
                            <SwitchLevelDialog
                                open={openDialog === "switch-level"}
                                onClose={() => setOpenDialog(false)}
                                currentLevel={currentLevel}
                                onSave={({ level }) => handleOnLevelSwitch({ level })}
                            />
                        )}
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <SearchField
                            slotProps={{
                                textField: {
                                    sx: {
                                        visibility: moreThanTenRows ? "visible" : "hidden",
                                    },
                                },
                            }}
                            onChange={(_e, value) => {
                                gridApiRef.current?.setQuickFilterValues([value]);
                            }}
                        />
                        <LoadingIndicator loading={isLoading} error={isError} success={isSuccess} />
                    </Stack>
                </Stack>
                {/* flexGrow 0 -> don't grow, flexShrink 1 -> allow the table to shrink to fit the available space,
                flexBasis auto -> ideal size is determined by the size of the table,
                minHeight 0 allows it to become arbitrarily small, otherwise it would grow beyond the page.  */}
                <Box flex="0 1 auto" minHeight={0}>
                    <EmissionIntensityDataGrid key={emissionIntensityId} apiRef={gridApiRef} {...props} />
                </Box>
            </Stack>
        </AppLayout>
    );
};

export { EmissionIntensities };
