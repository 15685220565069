import { AddedConnection } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { Backdrop, Card, CardContent, Paper, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
    data: AddedConnection;
    onClose: () => void;
};

export const DataSourceExpandedCard: React.FC<Props> = ({ onClose }) => {
    return (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Paper variant="outlined">
                <Card component={Paper}>
                    <CardContent>
                        <Typography variant="h6">
                            <FormattedMessage
                                defaultMessage="Data source details"
                                description="Data source details title"
                            />
                        </Typography>
                        <Button onClick={onClose}>
                            <FormattedMessage defaultMessage="Close" description="Close button" />
                        </Button>
                        <br />
                    </CardContent>
                </Card>
            </Paper>
        </Backdrop>
    );
};
