import { formatNumber } from "@/lib/primitives/numbers";

import { Tooltip } from "@mui/material";
import { isNil } from "lodash";
import React from "react";

type Props = {
    value: number | null | undefined;
};
export const ValueCell: React.FC<Props> = ({ value }) => {
    if (isNil(value)) return "-";
    if (value === 0) {
        return (
            <Tooltip title={`${value} tCO₂e`} placement="top-start">
                <span>{value}</span>
            </Tooltip>
        );
    }

    const formattedValue = formatNumber(value, 2);
    return (
        <Tooltip title={`${formattedValue} tCO₂e`} placement="top-start">
            <span>{formattedValue}</span>
        </Tooltip>
    );
};
