import { OverviewData } from "@/Containers/LandingPage/types";
import { overviewRoute } from "@/Router/Overview";
import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { HeaderCell } from "./HeaderCell";
import { Scope1Rows } from "./Scope1Rows";
import { Scope2Rows } from "./Scope2Rows";
import { Scope3Rows } from "./Scope3Rows";

interface Props {
    data: OverviewData;
    topValue: number;
}

export const DetailedTable: React.FC<Props> = ({ data, topValue }) => {
    const { year: focusYear } = useSearch({ from: overviewRoute.fullPath });
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedYears = data.years.sort();
    return (
        <TableContainer sx={{ backgroundColor: "white" }}>
            <Table cellPadding={10} width="100%">
                <TableHead>
                    <TableRow>
                        <HeaderCell>
                            <Typography variant="subtitle2">
                                <FormattedMessage
                                    defaultMessage="Scope"
                                    description="Header for scope column of overview table"
                                />
                            </Typography>
                        </HeaderCell>
                        <HeaderCell highlightRight={focusYear === sortedYears[0]}>
                            <Typography variant="subtitle2">
                                <FormattedMessage
                                    defaultMessage="Category"
                                    description="Header for category column of overview table"
                                />
                            </Typography>
                        </HeaderCell>
                        {sortedYears.map((year, index) => (
                            <HeaderCell
                                key={year}
                                highlight={focusYear === Number(year)}
                                highlightRight={
                                    focusYear === Number(year) ||
                                    !!(sortedYears.length && focusYear === sortedYears[index + 1])
                                }
                                number
                            >
                                <Typography variant="subtitle2">{year}</Typography>
                            </HeaderCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Scope1Rows data={data} sortedYears={sortedYears} topValue={topValue} />
                    <Scope2Rows data={data} sortedYears={sortedYears} topValue={topValue} />
                    <Scope3Rows data={data} sortedYears={sortedYears} topValue={topValue} />
                </TableBody>
            </Table>
        </TableContainer>
    );
};
