import { createGraphqlClient } from "@/lib/apollo-client";
import { ApolloProvider } from "@apollo/client";
import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import React, { useMemo } from "react";
import { ErrorBoundary } from "./Components/ErrorBoundary/ErrorBoundary";
import { useOnUnhandledRejectionReporter } from "./lib/errors";
import { errorLink } from "./lib/graphql";
import { queryClient } from "./lib/react-query/client";
import { useSyncedTanstackBrowserHistory } from "./lib/tanstack-router/useSyncedTanstackBrowserHistory";
import { getTRPCClient, trpc } from "./lib/trpc/client";
import { AlertProvider } from "./Providers/Alerts";
import { FeatureToggleProvider } from "./Providers/FeatureToggleProvider";
import { IntlProvider } from "./Providers/IntlProvider";
import { router } from "./Router";
import { MicroAppMountValues } from "./types";
import type { History } from "history";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TOKEN ?? "");
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

const graphqlClient = createGraphqlClient(process.env.VITE_GRAPHQL_URL ?? "", errorLink);

const InnerApp: React.FC<{ history?: History }> = ({ history }) => {
    const { tanstackHistory } = useSyncedTanstackBrowserHistory({ reactRouterHistory: history });
    return <RouterProvider history={tanstackHistory} router={router} basepath="carbon" />;
};

const App: React.FC<MicroAppMountValues> = ({ theme: themeName = "ignite-riddle" as const, locale, history }) => {
    const responsiveTheme = useMemo(() => {
        const designTokens = getDesignTokens(themeName, "light");
        const theme = createTheme(designTokens);
        return responsiveFontSizes(theme);
    }, [themeName]);

    const trpcClient = useMemo(() => {
        return getTRPCClient();
    }, []);
    useOnUnhandledRejectionReporter();
    return (
        <ErrorBoundary>
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <ApolloProvider client={graphqlClient}>
                        <ThemeProvider theme={responsiveTheme}>
                            <CssBaseline />
                            <FeatureToggleProvider>
                                <AlertProvider>
                                    <IntlProvider locale={locale ?? "en-US"}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <InnerApp history={history} />
                                        </LocalizationProvider>
                                    </IntlProvider>
                                </AlertProvider>
                            </FeatureToggleProvider>
                        </ThemeProvider>
                    </ApolloProvider>
                </QueryClientProvider>
            </trpc.Provider>
        </ErrorBoundary>
    );
};

export default App;
