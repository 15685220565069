import { useKeyboardShortcut } from "@/lib/tools";
import { trpc } from "@/lib/trpc/client";
import { useDebounce } from "@ignite-analytics/general-tools";
import { useDebouncedValue } from "@mantine/hooks";
import { ListSubheader, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFilterHandlers } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { TagSelect } from "./TagSelect";

type Props = {
    tagId: string;
};
export const TagFilterMenu: React.FC<Props> = ({ tagId }) => {
    const { setFilter: setFilters, filter: filters } = useFilterHandlers("tagFilters");
    const [searchPhrase, setSearchPhrase] = useState("");
    const [debouncedSearchPhrase] = useDebouncedValue(searchPhrase, 300);
    const activeFilterForTag = filters?.find((filter) => filter.tagId === tagId) ?? {
        tagId,
        values: [],
    };

    const optionsResponse = trpc.getOptionsFromTag.useQuery({
        id: tagId,
        searchString: debouncedSearchPhrase,
    });
    const [focusList, setFocusList] = useState(false);
    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);

    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);
    const onSearch = useDebounce((query: string) => {
        setSearchPhrase(query);
    }, 500);

    /**
     * If `selected` is true, add the value to the filter for this tag,
     * otherwise remove it.
     *
     * If the resulting filter for this tag is empty, remove the filter entirely.
     */
    function onTagValueChange({ value, selected }: { value: string; selected: boolean }) {
        let newValues: string[] = [];
        if (selected) {
            // Add the value to the filter for this tag.
            newValues = activeFilterForTag?.values.concat(value);
        } else {
            // Remove the value from the filter for this tag.
            newValues = activeFilterForTag?.values.filter((v) => v !== value);
        }
        // Remove the filter for this tag, as no values are currently selected.
        if (newValues.length === 0) {
            return setFilters(filters?.filter((filter) => filter.tagId !== tagId) ?? []);
        }
        // Update the filter for this tag with the new values.
        const newTagFilterValue = { ...activeFilterForTag, values: newValues };
        const newTagFilter = [...(filters?.filter((filter) => filter.tagId !== tagId) ?? []), newTagFilterValue];
        return setFilters(newTagFilter);
    }

    return (
        <FilterValueSelect
            onReset={() => setFilters([])}
            title={<FormattedMessage defaultMessage="Tag" description="Tag" />}
            isError={optionsResponse.isError}
            onSearch={onSearch}
            isLoading={optionsResponse.isLoading}
        >
            {optionsResponse.isSuccess && (
                <>
                    {!optionsResponse?.data?.optionsFromConnections.length &&
                        !optionsResponse?.data?.predefinedOptions.length && (
                            <Stack direction="row" justifyContent="center" px={2} pb={2}>
                                <Typography color="text.secondary">
                                    <FormattedMessage defaultMessage="No results" description="No results text" />
                                </Typography>
                            </Stack>
                        )}
                    {optionsResponse?.data?.optionsFromConnections.length > 0 && (
                        <>
                            <ListSubheader sx={{ lineHeight: 1 }}>
                                <Typography variant="caption">
                                    <FormattedMessage
                                        defaultMessage="Options from connections"
                                        description="Predefined options"
                                    />
                                </Typography>
                            </ListSubheader>
                            {optionsResponse.data.optionsFromConnections.map((option) => (
                                <TagSelect
                                    key={option.value}
                                    label={option.label}
                                    selected={activeFilterForTag?.values.includes(option.value)}
                                    onChange={(selected) => {
                                        onTagValueChange({ value: option.value, selected });
                                    }}
                                />
                            ))}
                        </>
                    )}
                    {optionsResponse?.data?.predefinedOptions.length > 0 && (
                        <>
                            <ListSubheader sx={{ lineHeight: 1 }}>
                                <Typography variant="caption">
                                    <FormattedMessage
                                        defaultMessage="Predefined options"
                                        description="Predefined options"
                                    />
                                </Typography>
                            </ListSubheader>
                            {optionsResponse.data.predefinedOptions.map((option) => (
                                <TagSelect
                                    key={option.value}
                                    label={option.label}
                                    selected={activeFilterForTag?.values.includes(option.value)}
                                    onChange={(selected) => {
                                        onTagValueChange({ value: option.value, selected });
                                    }}
                                />
                            ))}
                        </>
                    )}
                </>
            )}
        </FilterValueSelect>
    );
};
