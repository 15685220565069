import { trpc } from "@/lib/trpc/client";

import { useFormContext } from "react-hook-form";
import { isCountryCode } from "router";
import { DefaultFormType } from "../../types";
import { EmissionEstimateMeta } from "./types";

export const useGetActivityEstimate = () => {
    const { watch } = useFormContext<DefaultFormType>();

    const value = watch("value");
    const region = watch("region");
    const from = watch("from");
    const energyCertificate = watch("energyCertificate");
    const unitOfMeasurement = watch("unitOfMeasurement");
    const locationBasedValue = watch("locationBasedValue");
    const activityL1 = watch("activityL1");
    const activityL2 = watch("activityL2");
    const scopeCategory = watch("scopeType");

    const { data, isLoading, isError, isFetching } = trpc.getEmissionNumbersDuringCreation.useQuery(
        {
            unitOfMeasurement,
            region,
            from,
            scopeType: scopeCategory,
            activityL1,
            activityL2,
            value,
            locationBasedValue,
            energyCertificate,
        },
        {
            enabled: !!unitOfMeasurement && !!from && !!scopeCategory && !!activityL1 && !!value,
        }
    );
    if (isFetching && isLoading) {
        return {
            type: "loading",
        } as const;
    }
    if (!data || isError || data.type === "missingData") {
        return {
            type: "missingData",
        } as const;
    }

    const getMetaData = (): {
        shared: EmissionEstimateMeta;
        electricityFactor: {
            locationBased: number;
            marketBased: number;
        };
    } | null => {
        if (data.type === "precalculated" || !unitOfMeasurement || !from) {
            return null;
        }
        const emissionFactorRegion =
            data.emissionsFactorMeta.region && isCountryCode(data.emissionsFactorMeta.region)
                ? data.emissionsFactorMeta.region
                : undefined;
        return {
            shared: {
                value: value ?? 0,
                emissionFactorValue: data.emissionsFactorMeta.emissionFactor ?? 0,
                unitOfMeasurement,
                year: from.getFullYear(),
                region: region ?? undefined,
                emissionFactorYear: data.emissionsFactorMeta.year ?? undefined,
                emissionFactorRegion,
                emissionFactorSource: data.emissionsFactorMeta.source,
                emissionFactorLink: data.emissionsFactorMeta.link,
            },
            electricityFactor: {
                locationBased: data.emissionsFactorMeta.locationBasedFactor ?? 0,
                marketBased: data.emissionsFactorMeta.marketBasedFactor ?? 0,
            },
        };
    };
    const metaData = getMetaData();
    if (scopeCategory === "electricityConsumption") {
        const emissionFactors = metaData?.electricityFactor
            ? metaData.electricityFactor
            : { locationBased: 0, marketBased: 0 };

        return {
            type: "electricity",
            estimates: {
                locationBased: data.estimateLocation ?? 0,
                marketBased: data.estimateMarket ?? 0,
            },
            emissionFactors,
            metaData: metaData ? metaData.shared : null,
            certificate: data.energyCertificate,
        } as const;
    }

    return {
        type: "regularData",
        estimate: data.estimate ?? 0,
        metaData: metaData ? metaData.shared : null,
    } as const;
};
