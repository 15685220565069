import { trpc } from "@/lib/trpc/client";
import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import dayjs from "dayjs";
import React from "react";
import {
    ActivityBasedEmissionFactor,
    isUnitOfMeasurement,
    isScopeType,
    CountryCode,
    UnitOfMeasurement,
    ScopeCategoryType,
    isCountryCode,
    scopeCategoryTypes,
    ActivityInformation,
} from "router";
import { DefaultValues, RowData } from "./types";

export type SearchParams = {
    region?: CountryCode;
    activityYear?: number;
    unit?: UnitOfMeasurement;
    type: ScopeCategoryType | "none";
    activityL1?: string;
    activityL2?: string;
    formType: "activityL1" | "activityL2";
};

const getSearchParamsFromRow = (row: ActivityBasedEmissionFactor): SearchParams => {
    const { region, year, id } = row;
    /*   The id is in the format:
      region, year, unit, category, activityL1, activityL2 separated by underscores
      E.g: "NO_2022_pkm_businessTravel_flights_longhaulInternational3700kmAveragePassenger"
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, __, unitString, categoryString, activityL1, activityL2] = id.split("_");

    const unit = isUnitOfMeasurement(unitString) ? unitString : undefined;
    const type = isScopeType(categoryString) ? categoryString : ("none" as const);

    const regionValue = isCountryCode(region) ? region : undefined;
    const formType = activityL2 ? "activityL2" : ("activityL1" as const);
    return {
        region: regionValue,
        activityYear: year,
        unit,
        type,
        activityL1,
        activityL2,
        formType,
    };
};
const searchParamsToDefaultValues = (row: SearchParams): DefaultValues => {
    const { region, activityYear, unit, type, activityL1: activityL1String, activityL2: activityL2String } = row;

    const l1Formatted = {
        type: "predefined",
        value: activityL1String,
    };
    const l2Formatted = {
        type: "predefined",
        value: activityL2String,
    };

    const activityL1 = l1Formatted.value ? (l1Formatted as ActivityInformation) : undefined;
    const activityL2 = l2Formatted.value ? (l2Formatted as ActivityInformation) : undefined;
    const from = activityYear ? dayjs(`${activityYear}-01-01`).add(1, "hour").toDate() : undefined;
    const to = activityYear ? dayjs(`${activityYear}-12-31`).subtract(1, "hour").toDate() : undefined;
    return {
        activityL1,
        activityL2,
        from,
        to,
        unitOfMeasurement: unit,
        region,
        scopeType: type,
    };
};

export const useGetRows = (): "error" | "loading" | RowData[] => {
    const emissionFactorsResponse = trpc.allActivityEmissionFactors.useQuery();
    const rows: RowData[] | "error" | "loading" = React.useMemo(() => {
        const returnRows: RowData[] = [];
        if (emissionFactorsResponse.isLoading) return "loading";
        if (emissionFactorsResponse.isError) return "error";
        if (emissionFactorsResponse.isSuccess) {
            emissionFactorsResponse.data.forEach((row) => {
                const { region, year, id, scope, source, link, description, activityL1, activityL2 } = row;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, __, unitString, categoryString] = id.split("_");

                const unit = unitString as UnitOfMeasurement;
                const category = categoryString as ScopeCategoryType;

                const regionValue = region && region !== "GLOBAL" ? (region as CountryCode) : undefined;
                const regionLabel = regionValue ? (countryCodeLabelRecord[regionValue]?.label ?? undefined) : undefined;
                const searchParams = getSearchParamsFromRow(row);

                const rowData: RowData = {
                    id,
                    scope,
                    category,
                    activityL1: activityL1 ?? "",
                    activityL2: activityL2 ?? "",
                    unit,
                    year,
                    source: {
                        name: source ?? "",
                        link,
                        description: description ?? undefined,
                    },
                    emissionFactor: {
                        regular: row.emissionFactor ?? undefined,
                        locationBased: row.emissionFactorLocationBased ?? undefined,
                        marketBased: row.emissionFactorMarketBased ?? undefined,
                    },
                    region: regionLabel,
                    regionCode: regionValue,
                    searchParams,
                    defaultValues: searchParamsToDefaultValues(searchParams),
                };
                if (scopeCategoryTypes.includes(category)) {
                    // eslint-disable-next-line fp/no-mutating-methods
                    returnRows.push(rowData);
                }
            });
        }
        return returnRows;
    }, [
        emissionFactorsResponse.data,
        emissionFactorsResponse.isSuccess,
        emissionFactorsResponse.isLoading,
        emissionFactorsResponse.isError,
    ]);
    return rows;
};
