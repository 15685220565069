import { topContributorsRoute } from "@/Router/TopContributors";
import { Company, LocationPin } from "@ignite-analytics/icons";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

export const ToolbarElectricityBasis = () => {
    const { electricity } = useSearch({ from: topContributorsRoute.id });
    const navigate = useNavigate();

    const handleChange = (value: "locationBased" | "marketBased") => {
        navigate({ to: ".", search: (prev) => ({ ...prev, electricity: value }) });
    };

    return (
        <ToggleButtonGroup
            size="small"
            value={electricity ?? "marketBased"}
            exclusive
            onChange={(e, value) => {
                if (value === null) return;
                handleChange(value);
            }}
        >
            <Tooltip
                title={
                    <FormattedMessage
                        defaultMessage="Location-based estimates for electricity emissions"
                        description="Tooltip for button group for picking electricity type"
                    />
                }
            >
                <ToggleButton sx={{ p: 1 }} value="locationBased">
                    <LocationPin />
                </ToggleButton>
            </Tooltip>
            <Tooltip
                title={
                    <FormattedMessage
                        defaultMessage="Market-based estimates for electricity emissions"
                        description="Tooltip for button group for picking electricity type"
                    />
                }
            >
                <ToggleButton sx={{ p: 1 }} value="marketBased">
                    <Company />
                </ToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
    );
};
