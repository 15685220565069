import { MessageDescriptor } from "react-intl";

import { UnitOfMeasurement } from "router";
import messages from "./messages";
import { getMessageForActivity } from "./helpers.activityL1";

export { getMessageForActivity };

export const getMessageForUnit = (unit: UnitOfMeasurement): MessageDescriptor => {
    switch (unit) {
        case "kg":
            return messages.kg;
        case "t":
            return messages.t;
        case "l":
            return messages.l;
        case "m3":
            return messages.m3;
        case "kgCO2e":
            return messages.kgCO2e;
        case "tCO2e":
            return messages.tCO2e;
        case "kWh":
            return messages.kWh;
        case "kWhGross":
            return messages.kWhGross;
        case "GJ":
            return messages.GJ;
        case "km":
            return messages.km;
        case "miles":
            return messages.miles;
        case "roomsPerNight":
            return messages.roomsPerNight;
        case "pkm":
            return messages.pkm;
        case "Ml":
            return messages.Ml;
        case "tkm":
            return messages.tkm;
        case "FTEhour":
            return messages.FTEhour;
        // Should always be a case for each unit, so do not add a default case
        default:
            return {
                id: unit,
                defaultMessage: unit,
                description: "Label for activity",
            };
    }
};

export const getMessageForEmissionFactorDividedByUnit = (unit: UnitOfMeasurement): MessageDescriptor => {
    switch (unit) {
        case "kg":
            return messages.kgDivided;
        case "t":
            return messages.tDivided;
        case "l":
            return messages.lDivided;
        case "m3":
            return messages.m3Divided;
        case "kgCO2e":
            return messages.kgCO2eDivided;
        case "tCO2e":
            return messages.tCO2eDivided;
        case "kWh":
            return messages.kWhDivided;
        case "kWhGross":
            return messages.kWhGrossDivided;
        case "GJ":
            return messages.GJDivided;
        case "km":
            return messages.kmDivided;
        case "miles":
            return messages.milesDivided;
        case "roomsPerNight":
            return messages.roomsPerNightDivided;
        case "pkm":
            return messages.pkmDivided;
        case "Ml":
            return messages.MlDivided;
        case "tkm":
            return messages.tkmDivided;
        case "FTEhour":
            return messages.FTEhourDivided;
        // Should always be a case for each unit, so do not add a default case
        default:
            return {
                id: unit,
                defaultMessage: unit,
                description: "Label for activity",
            };
    }
};
