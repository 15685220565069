import { hasValue } from "@/lib/hasValue";
import React from "react";

import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ActivityOption, ScopeCategory } from "router";
import { useGetChangeHandlers } from "../../hooks";
import { DefaultFormType } from "../../types";
import { ActivityPickerDeprecated } from "../ActivityPickerDeprecated";

type Props = {
    activityOptions: ActivityOption[];
    loading?: boolean;
    errorMessage?: string;
    scopeCategory: ScopeCategory;
};

export const ActivityL2Picker: React.FC<Props> = ({ activityOptions, loading, errorMessage, scopeCategory }) => {
    const { formatMessage } = useIntl();
    const { setValue, watch } = useFormContext<DefaultFormType>();
    const { handleChangeL2 } = useGetChangeHandlers(
        (l2) => setValue("activityL2", l2),
        (unit) => setValue("unitOfMeasurement", unit)
    );

    const activity = watch("activityL2");
    if (!activityOptions.length || loading) {
        return null;
    }

    return (
        <ActivityPickerDeprecated
            scopeCategory={scopeCategory}
            loading={loading}
            dataTestId="activity-l2-picker"
            activityOptions={activityOptions.map((option) => option.activityL2).filter(hasValue)}
            activity={activity}
            setValue={(activityInformation) => {
                const option = activityOptions.find(
                    (innerOption) =>
                        innerOption?.activityL2?.value && innerOption?.activityL2?.value === activityInformation?.value
                );
                const l2Value = option?.activityL2;
                option && handleChangeL2(option, watch("unitOfMeasurement"));
                l2Value && setValue("activityL2", option?.activityL2);
                setValue("locationBasedValue", null);
            }}
            label={formatMessage({
                defaultMessage: "Activity level 2",
                description: "Header for activity column on uploaded table",
            })}
            errorMessage={errorMessage}
        />
    );
};
