import { Button, createSvgIcon, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

const WarningIcon = createSvgIcon(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#FEF9EA" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5096 13.3052C18.5796 11.3128 21.4373 11.3138 22.5058 13.307L28.3242 24.1603C29.3367 26.0489 27.9685 28.3348 25.8256 28.3348H14.1788C12.035 28.3348 10.6669 26.0471 11.6812 24.1585L17.5096 13.3052ZM21.034 14.0961C20.5949 13.277 19.4206 13.2766 18.9809 14.0953L13.1524 24.9486C12.7356 25.7247 13.2978 26.6648 14.1788 26.6648H25.8256C26.7062 26.6648 27.2685 25.7254 26.8524 24.9493L21.034 14.0961ZM20.0004 16.6648C20.4616 16.6648 20.8354 17.0386 20.8354 17.4998V20.8331C20.8354 21.2942 20.4616 21.6681 20.0004 21.6681C19.5393 21.6681 19.1654 21.2942 19.1654 20.8331V17.4998C19.1654 17.0386 19.5393 16.6648 20.0004 16.6648ZM19.1654 24.1664C19.1654 23.7053 19.5393 23.3314 20.0004 23.3314H20.0088C20.4699 23.3314 20.8438 23.7053 20.8438 24.1664C20.8438 24.6276 20.4699 25.0014 20.0088 25.0014H20.0004C19.5393 25.0014 19.1654 24.6276 19.1654 24.1664Z"
            fill="#F6970A"
        />
    </svg>,

    "WarningIcon"
);

const ConfirmSubmissionDialog: React.FC<Props> = ({ open, onCancel, onConfirm }) => {
    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                <Stack spacing={4}>
                    <Stack spacing={1} alignItems="center">
                        <WarningIcon sx={{ height: "40px", width: "40px", mb: 2.5 }} />
                        <Typography variant="textLg" fontWeight={500} textAlign="center">
                            <FormattedMessage
                                defaultMessage="Missing data"
                                description="Header for dialog when some data is missing when submitting emission intensities"
                            />
                        </Typography>
                        <Typography variant="textSm" color={(t) => t.palette.text["text-body"]} textAlign="center">
                            <FormattedMessage
                                defaultMessage="You haven't filled in all information, so we won't be able to show you all your emission intensities data. Do you want to save and continue anyway?"
                                description="Subheader for dialog when some data is missing when submitting emission intensities"
                            />
                        </Typography>
                    </Stack>

                    <Stack spacing={1.5}>
                        <Button size="small" color="secondary" onClick={() => onConfirm()}>
                            <FormattedMessage
                                defaultMessage="Save and continue later"
                                description="Label for button to submit emission intensities even with missing data"
                            />
                        </Button>
                        <Button
                            size="small"
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            <FormattedMessage
                                defaultMessage="Add missing data"
                                description="Label for button to go back to editing emission intensities"
                            />
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export { ConfirmSubmissionDialog };
