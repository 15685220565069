import { ArrowLeft } from "@ignite-analytics/icons";
import { Box, IconButton } from "@mui/material";

type Props = {
    goBack: () => void;
    visible: boolean;
};
export const BackButtonForChart: React.FC<Props> = ({ goBack, visible }) => {
    const onBack = () => {
        goBack();
    };
    if (!visible) {
        return null;
    }
    return (
        <Box>
            <IconButton color="primary" onClick={onBack} size="small">
                <ArrowLeft fontSize="small" />
            </IconButton>
        </Box>
    );
};
