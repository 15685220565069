import { useAlert } from "@/Providers/Alerts";
import { dataPreferenceCustomCategoriesRoute } from "@/Router/DataPreferencesRoute";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React from "react";

import { useIntl } from "react-intl";
import { CustomCategoryDialog } from "./Dialog";
import { CustomCategoriesTable, CustomCategoryData } from "./Table";

export const EditCustomCategoriesRoute: React.FC = () => {
    const { categoryId } = useSearch({ from: dataPreferenceCustomCategoriesRoute.fullPath });
    const navigate = useNavigate();
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const categories = trpc.allCustomCategories.useQuery();
    const filterOptionsResult = trpc.getDataGridFilterOptions.useQuery();
    const deleteCustomCategoriesByIds = trpc.deleteCustomCategoriesByIds.useMutation({
        onSuccess: () => {
            categories.refetch();
            alertUser({
                value: formatMessage({
                    defaultMessage: "Categories successfully deleted",
                    description: "Alert message when deleting custom categories",
                }),
                title: formatMessage({
                    defaultMessage: "Success",
                    description: "Success",
                }),
                severity: "success",
            });
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Error deleting categories",
                    description: "Alert message when deleting custom categories",
                }),
                title: formatMessage({
                    defaultMessage: "Error",
                    description: "Error",
                }),
                severity: "error",
            });
        },
    });

    const categoriesData: CustomCategoryData = React.useMemo(() => {
        if (categories.status === "success") {
            return {
                status: "success",
                categories: categories.data.data,
            };
        }
        return {
            status: categories.status,
        };
    }, [categories]);

    const filterOptions =
        filterOptionsResult.status === "success"
            ? {
                  data: filterOptionsResult.data,
                  status: filterOptionsResult.status,
              }
            : { status: filterOptionsResult.status };

    const deleteCategories = (ids) => {
        CARBON_EVENTS.CUSTOM_CATEGORY_DELETED({
            page: "DataPreferences",
            component: "CustomCategories",
        }).track();

        deleteCustomCategoriesByIds.mutate({ ids });
    };

    return (
        <>
            <CustomCategoryDialog
                onClose={() => {
                    navigate({ search: (prev) => ({ ...prev, categoryId: undefined, newCategory: undefined }) });
                }}
                category={
                    categories.status === "success"
                        ? categories.data?.data.find((category) => category.id === categoryId)
                        : undefined
                }
                onRefetch={() => {
                    categories.refetch();
                    navigate({ search: (prev) => ({ ...prev, categoryId: undefined, newCategory: undefined }) });
                }}
            />
            <CustomCategoriesTable
                data={categoriesData}
                filterOptions={filterOptions}
                deleteCustomCategoriesByIds={(ids) => deleteCategories(ids)}
            />
        </>
    );
};
