import { useGetTableAndColumnMessages } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/hooks";
import { DataTable, GlobalType } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useMemo } from "react";
import { useGetCurrentFormRoute } from "../../hooks";

type Props = {
    tableId?: string;
    globalType?: GlobalType;
    tables:
        | { status: "loading" }
        | {
              status: "success";
              data: { dataTables: DataTable[] };
          }
        | { status: "error"; error: { message?: string } };
    connectionId?: string; // If in edit mode
};

export const TablePicker: React.FC<Props> = ({ tableId, globalType, tables }) => {
    const { tableSelectMessage } = useGetTableAndColumnMessages();
    const navigate = useNavigate();
    const currentFormRoute = useGetCurrentFormRoute();
    const changeTable = useCallback(
        (id: string | null) => {
            navigate({
                to: currentFormRoute.fullPath,
                search: (prev) => ({ ...prev, tableId: id || undefined }),
                // We want to replace the current history entry, so that clicking back works.
                replace: true,
            });
        },
        [navigate, currentFormRoute]
    );

    const options: DataTable[] = useMemo(() => {
        return tables.status === "success" ? tables.data.dataTables : [];
    }, [tables]);

    const loading = tables.status === "loading";

    useEffect(() => {
        if (!tableId && options.length > 0 && globalType) {
            const defaultTable = options.find((table) => table.globalType === globalType);
            defaultTable && changeTable(defaultTable?.dataTableId);
        }
    }, [tableId, options, changeTable, globalType]);
    return (
        <Grid paddingBottom={1}>
            <Autocomplete
                size="small"
                id="data-table-select"
                value={tableId ?? null}
                options={options.map((option) => option.dataTableId)}
                autoHighlight
                getOptionLabel={(option) => {
                    return options.find((innerOpt) => innerOpt.dataTableId === option)?.name ?? "";
                }}
                onChange={(_, newValue: string | null) => {
                    changeTable(newValue?.toString() ?? null);
                }}
                fullWidth
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={tableSelectMessage}
                        error={tables.status === "error"}
                        helperText={tables.status === "error" ? tables.error.message : ""}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </Grid>
    );
};
