import { TrackSource } from "@/lib/tracking";
import { useGetMessages } from "../../hooks";
import { SourceConfig } from "../../types";
import { ListActionItem } from "../ListActionItem";
import { useGetCreateActivityOnClick } from "./hooks";

type Props = {
    config: SourceConfig;
    trackSource: TrackSource;
};
export const CreateNewActivityListAction: React.FC<Props> = ({ config, trackSource }) => {
    const messages = useGetMessages(config);
    const onClick = useGetCreateActivityOnClick(config, trackSource);

    if (config.type === "supplier" && (!config.category || !config.supplierId || !config.supplierName)) return null;

    return onClick && messages?.createNewActivity ? (
        <ListActionItem onClick={onClick} text={messages.createNewActivity} />
    ) : null;
};
