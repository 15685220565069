/* eslint-disable react/no-array-index-key */
import { Chip, Stack, Typography } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { createContext, forwardRef, useContext, useEffect, useRef } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { ActivityOption } from "..";

// Virtualization of Autocomplete is described in the MUI documentation: https://mui.com/material-ui/react-autocomplete/#virtualization
// The following code is a modified version of the example provided in the documentation.

export const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    const { key, ...optionProps } = dataSet[0];
    const option: ActivityOption = dataSet[1];
    const searchTerm = dataSet[2];

    const matchesName = match(option.name, searchTerm ?? undefined, { insideWords: true });
    const partsName = parse(option.name, matchesName);

    const matchesCategory = match(option.category, searchTerm ?? undefined, { insideWords: true });
    const partsCategory = parse(option.category, matchesCategory);

    return (
        <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" gap={1}>
                <Typography
                    sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    flexBasis="70%"
                    flexGrow={1}
                    flexShrink={0}
                    title={option.name}
                >
                    {partsName.map((part, partIndex) => (
                        <span
                            key={`${index}${part}${partIndex}-name`}
                            style={{
                                fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </Typography>

                <Chip
                    sx={{ flexGrow: 0, flexShrink: 1, maxWidth: "30%" }}
                    title={option.category}
                    label={partsCategory.map((part, partIndex) => (
                        <span
                            key={`${index}${part}${partIndex}-category`}
                            style={{
                                fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                />
            </Stack>
        </Typography>
    );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = "OuterElementType";

function useResetCache(data: number) {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

interface SizeRef {
    [key: number]: number | null;
}

export const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData: React.ReactChild[] = [];
        (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const rowHeightRefs = useRef<SizeRef>({});

        const itemCount = itemData.length;

        const gridRef = useResetCache(itemCount);

        const getItemSize = (index: number) => {
            const size = rowHeightRefs?.current[index] || 50;
            return size;
        };

        const getCurtainHeight = () => {
            const height = itemData.map((_, index) => getItemSize(index)).reduce((a, b) => a + b, 0);
            if (height > 300) {
                return 300;
            }
            return height + LISTBOX_PADDING * 2;
        };

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getCurtainHeight()}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        itemSize={(index) => getItemSize(index)}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    }
);
