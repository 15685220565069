/* eslint-disable fp/no-mutating-methods */
import { DeleteModal } from "@/Components/DeleteModal";
import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { createTagNameRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Outlet, useNavigate } from "@tanstack/react-router";
import { TagCard } from "./TagCard";
import { useGetCleanupFunctions } from "./hooks";

import { CreateTagCard } from "./CreateTagCard";

export const EmissionTags = () => {
    const { onFinishedDelete, onError } = useGetCleanupFunctions();
    const { formatMessage } = useIntl();

    const [tagToDelete, setTagToDelete] = React.useState<null | string>(null);

    const tags = trpc.getEmissionTagsWithOptions.useQuery();

    const deleteTag = trpc.deleteEmissionTag.useMutation({
        onError: (error) => {
            onError(error);
        },
        onSuccess: onFinishedDelete,
    });

    const navigate = useNavigate();

    tags.error && <ErrorBoundary error={tags.error} />;
    return (
        <Stack overflow="visible">
            <Card variant="outlined" sx={{ mb: 4 }}>
                <CardHeader
                    titleTypographyProps={{ variant: "textLg" }}
                    title={<FormattedMessage defaultMessage="Tags" description="Title for the Tags component" />}
                    subheaderTypographyProps={{ variant: "textSm" }}
                    subheader={
                        <FormattedMessage
                            defaultMessage="You can create different types of tags that you can associate with you emissions data such as “Department”, “Project ID”, “Sub-company name”, etc. This can help you to view your emissions in different dimensions."
                            description="Subtitle for the Tags component"
                        />
                    }
                />
                <CardContent>
                    <Stack gap={2}>
                        {tags.isLoading ? (
                            <Skeleton variant="rectangular" height={118} />
                        ) : (
                            <Stack gap={2}>
                                {tags?.data
                                    ?.sort((a, b) => {
                                        if (a.tagName < b.tagName) {
                                            return -1;
                                        }
                                        if (a.tagName > b.tagName) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((tag) => (
                                        <TagCard
                                            id={tag.tagId}
                                            deleteTag={() => setTagToDelete(tag.tagId)}
                                            tagName={tag.tagName}
                                            key={tag.tagId}
                                            optionsFromSources={tag.optionsFromSource}
                                            customOptions={tag.customOptions}
                                            sourceMissingOptionExample={
                                                tag?.sourcesMissingOptions && tag?.sourcesMissingOptions?.length > 0
                                                    ? tag.sourcesMissingOptions[0]
                                                    : undefined
                                            }
                                        />
                                    ))}
                                <CreateTagCard
                                    numberOfTags={tags?.data?.length ?? 0}
                                    createTag={() => {
                                        navigate({
                                            to: createTagNameRoute.fullPath,
                                        });
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                </CardContent>
                <DeleteModal
                    onDelete={() => {
                        tagToDelete && deleteTag.mutate({ id: tagToDelete });
                        setTagToDelete(null);
                    }}
                    open={!!tagToDelete}
                    onClose={() => setTagToDelete(null)}
                    title={formatMessage({
                        defaultMessage: "Delete tags",
                        description: "Delete activities header",
                    })}
                    subTitle={formatMessage({
                        defaultMessage: "Are you sure you want to delete this tag? This is a permanent action.",
                        description: "Delete activities subtitle",
                    })}
                />
                <Outlet />
            </Card>
        </Stack>
    );
};
