/* eslint-disable react/jsx-no-literals */
import { trpc } from "@/lib/trpc/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button, Stack } from "@mui/material";
import React from "react";

const MigrationTools: React.FC = () => {
    const showMigrationTools = useFeatureToggle("carbon-migration-tooling", false);
    const migrateMutation = trpc.migrateActivitiesToExclusionStructure.useMutation();
    const resetMigrationMutation = trpc.resetExclusionMigration.useMutation();
    const handleMigrate = () => {
        migrateMutation.mutate();
    };
    const handleReset = () => {
        resetMigrationMutation.mutate();
    };
    if (!showMigrationTools) return null;
    return (
        <Stack direction="row" gap={1}>
            <Button color="tertiary" onClick={handleMigrate}>
                Migrate
            </Button>
            <Button color="error" onClick={handleReset}>
                Reset
            </Button>
        </Stack>
    );
};

export default MigrationTools;
