export const InfoCircle: React.FC = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#EFF8FE" />
        <g clipPath="url(#clip0_34_3321)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12.5017C15.8588 12.5017 12.5017 15.8588 12.5017 20C12.5017 24.1412 15.8588 27.4983 20 27.4983C24.1412 27.4983 27.4983 24.1412 27.4983 20C27.4983 15.8588 24.1412 12.5017 20 12.5017ZM10.8317 20C10.8317 14.9365 14.9365 10.8317 20 10.8317C25.0635 10.8317 29.1683 14.9365 29.1683 20C29.1683 25.0635 25.0635 29.1683 20 29.1683C14.9365 29.1683 10.8317 25.0635 10.8317 20ZM19.165 15.8333C19.165 15.3722 19.5388 14.9983 20 14.9983H20.0083C20.4695 14.9983 20.8433 15.3722 20.8433 15.8333C20.8433 16.2945 20.4695 16.6683 20.0083 16.6683H20C19.5388 16.6683 19.165 16.2945 19.165 15.8333ZM20 18.3317C20.4612 18.3317 20.835 18.7055 20.835 19.1667V24.1667C20.835 24.6278 20.4612 25.0017 20 25.0017C19.5388 25.0017 19.165 24.6278 19.165 24.1667V19.1667C19.165 18.7055 19.5388 18.3317 20 18.3317Z"
                fill="#069CDF"
            />
        </g>
        <defs>
            <clipPath id="clip0_34_3321">
                <rect width="20" height="20" fill="white" transform="translate(10 10)" />
            </clipPath>
        </defs>
    </svg>
);
