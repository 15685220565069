import { HeartBroken } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorFallback = () => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Alert
                severity="error"
                sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", width: "60%" }}
                icon={false}
            >
                <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <HeartBroken fontSize="large" sx={{ marginBottom: "10px" }} />
                        <AlertTitle sx={{ fontSize: 38 }}>
                            <FormattedMessage
                                defaultMessage="Something went wrong!"
                                description="Something went wrong message"
                            />
                        </AlertTitle>
                    </Stack>
                    <Typography sx={{ fontSize: 22 }}>
                        <FormattedMessage
                            defaultMessage="Our team has been informed. You can attempt to refresh the page.
                                If the issue persists, please reach out to our support team for assistance."
                            description="Team informed message"
                        />
                    </Typography>
                    <Button size="large" onClick={() => window.location.reload()}>
                        <FormattedMessage defaultMessage="Refresh" description="Refresh message" />
                    </Button>
                </Stack>
            </Alert>
        </Stack>
    );
};

type Props = {
    children?: React.ReactNode;
    error?: unknown;
};
export const ErrorBoundary: React.FC<Props> = ({ children, error }) => {
    const getElementForErrorProp = () => {
        if (typeof error === "string") return error;
        if (error instanceof Error) return error.message;
        if (typeof error === "object" && error && React.isValidElement(error)) return error;
        return null;
    };
    return (
        <SentryErrorBoundary
            beforeCapture={(scope) => {
                scope.setTag("app", "carbon-overview-app");
            }}
            fallback={<ErrorFallback />}
        >
            {children}
            {getElementForErrorProp()}
        </SentryErrorBoundary>
    );
};
