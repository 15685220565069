import { Category } from "@ignite-analytics/icons";
import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { trpc } from "../../../../lib/trpc/client";
import AdvancedSelect from "../../../AdvancedSelect";
import { DefaultFormType } from "../../types";
import { ContextText } from "../ContextText";

export const SpendCategoryPicker = () => {
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();

    const validationError = errors.spendCategoryId?.message;
    const { formatMessage } = useIntl();
    const spendCategoryId = watch("spendCategoryId");

    const query = trpc.getSpendCategoryOptions.useQuery();
    const items = useMemo(() => {
        switch (query.status) {
            case "success":
                return query.data;
            case "loading":
                return [
                    {
                        label: formatMessage({
                            defaultMessage: "Loading...",
                            description: "Text displayed if spend category options are still loading in picker",
                        }),
                        value: "loading",
                    },
                ];
            case "error":
                return [
                    {
                        label: formatMessage({
                            defaultMessage: "Error loading options",
                            description: "Text displayed if spend category options failed to load",
                        }),
                        value: "error",
                    },
                ];
        }
    }, [formatMessage, query.data, query.status]);

    const queryError = query.isError
        ? formatMessage({
              defaultMessage: "Error loading options",
              description: "Text displayed if spend category options failed to load",
          })
        : undefined;

    const errorMessage = validationError ?? queryError;

    return (
        <Grid container wrap="nowrap" gap={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={4} sm={5} md={6}>
                <ContextText
                    icon={<Category fontSize="small" />}
                    header={formatMessage({
                        defaultMessage: "Add spend category",
                        description: "Add spend category",
                    })}
                    text={formatMessage({
                        defaultMessage:
                            "Specifying a spend category allows you to track emissions at a more granular level.",
                        description: "Add spend category description",
                    })}
                />
            </Grid>
            <Grid item xs>
                <AdvancedSelect
                    key={spendCategoryId}
                    selected={spendCategoryId ?? undefined}
                    items={items}
                    onSelect={(newValue) => {
                        setValue("spendCategoryId", newValue);
                    }}
                    placeholder={formatMessage({
                        defaultMessage: "Select a spend category",
                        description: "Placeholder for spend category picker",
                    })}
                    multiSelect={false}
                    errorMessage={errorMessage}
                />
            </Grid>
        </Grid>
    );
};
