import { formatNumber } from "@/lib/primitives/numbers";
import { Alert, Stack, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Scope1Type, Scope2Type, Scope3Type, isScopeType } from "router";

import {
    FixedCategoryActionPopover,
    PopoverInput,
} from "../../../../../../Components/ActionPopover/FixedCategoryActionPopover";
import { MissingData } from "../../../components/MissingData";
import { EmissionsData } from "../hooks";
import { ChartSideMenu } from "./SideMenu";
import { PayloadType, RegularTooltip } from "./Tooltip";
import { useGetBarElements } from "./hooks";

interface Props {
    emissionsSortedByYear: EmissionsData[];
    showSideMenuItems:
        | Record<"1" | "2" | "3", boolean>
        | Record<Scope1Type, boolean>
        | Record<Scope2Type, boolean>
        | Record<Scope3Type, boolean>;
    years: number[];
    total: number;
}

export const EmissionsByYearChart: React.FC<Props> = ({ years, emissionsSortedByYear, showSideMenuItems, total }) => {
    const barElements = useGetBarElements();
    const theme = useTheme();
    const [popoverData, setPopoverData] = React.useState<(PopoverInput & { x: number; y: number }) | null>(null);
    const handleBarClick = (
        e: React.MouseEvent<HTMLDivElement | SVGElement>,
        dataPoint: ReturnType<typeof useGetBarElements>[number]
    ) => {
        dataPoint.onClick?.();
        if (isScopeType(dataPoint.id)) {
            const element = e as unknown as { name: string; y: number; x: number };
            const year = Number(element.name);
            setPopoverData({
                category: dataPoint.id,
                total: element[dataPoint.id],
                year,
                x: element.x,
                y: element.y,
            });
        }
    };
    return (
        <Stack>
            {years.length ? (
                <>
                    <Stack height={500} direction="row" gap={1}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={emissionsSortedByYear}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" fontFamily={theme.typography.fontFamily} />
                                <YAxis fontFamily={theme.typography.fontFamily} />
                                {popoverData ? (
                                    <Tooltip
                                        position={{ x: popoverData.x, y: popoverData.y }}
                                        content={
                                            <FixedCategoryActionPopover
                                                close={() => setPopoverData(null)}
                                                input={popoverData}
                                            />
                                        }
                                    />
                                ) : (
                                    <Tooltip
                                        // eslint-disable-next-line
                                        content={(props) => {
                                            return <RegularTooltip payload={props.payload as PayloadType[]} />;
                                        }}
                                    />
                                )}
                                {barElements.map((barElement) => {
                                    return (
                                        <Bar
                                            style={{
                                                cursor: "pointer",
                                                outline: "none",
                                                userSelect: "none",
                                            }}
                                            key={barElement.id}
                                            dataKey={barElement.id}
                                            stackId="a"
                                            fill={barElement.color}
                                            onClick={(e) => handleBarClick(e, barElement)}
                                        />
                                    );
                                })}
                            </BarChart>
                        </ResponsiveContainer>
                        <ChartSideMenu showSideMenuItems={showSideMenuItems} />
                    </Stack>
                    <Alert severity="primary">
                        <FormattedMessage
                            defaultMessage="Total emissions: {total} tCO₂e"
                            description="Total emissions"
                            values={{ total: formatNumber(total, 2) }}
                        />
                    </Alert>
                </>
            ) : (
                <MissingData />
            )}
        </Stack>
    );
};
