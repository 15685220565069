import { useAlert } from "@/Providers/Alerts";
import { usePermission } from "@/lib/permissions/usePermissions";
import React from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { Activity, activitySchema } from "router";
import { ActivityEstimatesCard } from "../../Components/ActivityEstimates";
import { BasicDetails } from "../../Components/BasicDetails";
import { CompanyStructurePicker } from "../../Components/CompanyStructurePicker";
import { CountryPicker } from "../../Components/CountryPicker";
import { EnergyCertificate } from "../../Components/EnergyCertificate";
import { OverlappingData } from "../../Components/RemoveEmissionFromSpend";
import { SpendCategoryPicker } from "../../Components/SpendCategoryPicker";
import { SupplierPicker } from "../../Components/SupplierPicker";
import { TagsRow } from "../../Components/TagsPicker";
import { useGetValidatorSchema } from "../../hooks";
import { DefaultFormType } from "../../types";

type Props = {
    onSubmit: (data: Activity) => void;
    defaultValues: Omit<DefaultFormType, "initialDescription">;
    onCancel: () => void;
};

export const UpdateActivityForm: React.FC<Props> = ({ onSubmit, defaultValues, onCancel }) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const isEditor = usePermission({ permission: "write" });

    const validator = useGetValidatorSchema(defaultValues.activityL2 ? "activityL2" : "activityL1");

    const methods = useForm<DefaultFormType>({
        resolver: zodResolver(validator),
        defaultValues,
    });
    const { handleSubmit, watch } = methods;

    const scopeCategory = watch("scopeType");

    const onSubmitForm: SubmitHandler<DefaultFormType> = async (formValues) => {
        const { removeEmissionsFromSpendSource, filter: filterValue, ...values } = formValues;
        const parsedActivityData = activitySchema.safeParse({
            ...values,
            removeEmissionsFromSpendSource,
            filter: removeEmissionsFromSpendSource ? filterValue : [],
        });
        if (!parsedActivityData.success) {
            alertUser({
                title: formatMessage({
                    defaultMessage: "Invalid form",
                    description: "Error message for invalid form",
                }),
                value: formatMessage({
                    defaultMessage: "Please fill in all required fields",
                    description: "Error message for missing fields",
                }),

                severity: "error",
            });
            return;
        }
        onSubmit(parsedActivityData.data);
    };
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack spacing={2}>
                    <BasicDetails />
                    <Card>
                        <CardContent>
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Add recommended information"
                                    description="Header for contextual details"
                                />
                            </Typography>
                            <Stack gap={2} paddingTop={2} divider={<Divider />}>
                                <SupplierPicker />
                                <CountryPicker />
                                <CompanyStructurePicker />
                                <SpendCategoryPicker />
                                <TagsRow />
                                {scopeCategory === "electricityConsumption" && <EnergyCertificate />}
                            </Stack>
                        </CardContent>
                    </Card>
                    <ActivityEstimatesCard />
                    <OverlappingData />
                </Stack>

                <Stack gap={2} direction="row" justifyContent="end" paddingTop={2}>
                    <Button color="secondary" variant="outlined" onClick={onCancel}>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button disabled={!isEditor} color="primary" type="submit" data-testid="update-activity-button">
                        <FormattedMessage defaultMessage="Confirm and update activity" />
                    </Button>
                </Stack>
            </form>
        </FormProvider>
    );
};
