import { SupplierColumnSelect } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/SupplierColumnSelect";
import { DataColumn } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { Plus, Trash } from "@ignite-analytics/icons";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";
import { InputSupplierConnection } from "router";
import messages from "./messages";

interface Props {
    control: Control<InputSupplierConnection>;
    columns: DataColumn[];
}

export const SpendPeriodForm = ({ control, columns }: Props) => {
    const { formatMessage } = useIntl();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "periods",
    });

    return (
        <Stack padding={1} paddingTop={2}>
            <Typography variant="caption" paddingBottom={1}>
                {formatMessage(messages.spendPeriodTitle)}
            </Typography>
            {fields.map(({ id }, index) => (
                <Stack key={id} spacing={2} paddingBottom={2}>
                    <Stack direction="row" spacing={2}>
                        <Controller
                            control={control}
                            name={`periods.${index}.year`}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    onChange={(newYear: dayjs.Dayjs | null) => onChange(newYear?.get("year"))}
                                    views={["year"]}
                                    openTo="year"
                                    value={dayjs().year(value)}
                                    slotProps={{ textField: { size: "small" } }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={`periods.${index}.spendColumn`}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <SupplierColumnSelect
                                    type="number"
                                    value={value}
                                    errorMessage={error?.message}
                                    label={formatMessage(messages.spendColumn)}
                                    onChange={onChange}
                                    dataColumns={columns}
                                />
                            )}
                        />
                        <IconButton aria-label="delete" onClick={() => remove(index)}>
                            <Trash fontSize="small" />
                        </IconButton>
                    </Stack>
                </Stack>
            ))}
            <Stack direction="row" justifyContent="center" paddingTop={1}>
                <Button
                    variant="text"
                    color="secondary"
                    startIcon={<Plus />}
                    size="small"
                    onClick={() => append({ spendColumn: "", year: 2022 })}
                >
                    {formatMessage(messages.addPeriodButton)}
                </Button>
            </Stack>
        </Stack>
    );
};
