import { Grid, Stack, Tab, Tabs } from "@mui/material";
import { Outlet, useNavigate, useParams } from "@tanstack/react-router";
import { useIntl } from "react-intl";

export const EmissionFactors = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { factorType } = useParams({ from: "/_appLayout/emission-factors/$factorType" });

    return (
        <Stack direction="column" height="100%" spacing={2}>
            <Grid container>
                <Tabs
                    value={factorType}
                    onChange={(_, newValue) => {
                        if (newValue === "spend") {
                            navigate({ to: "/emission-factors/$factorType", params: { factorType: "spend" } });
                            return;
                        }
                        navigate({ to: "/emission-factors/$factorType", params: { factorType: "activity" } });
                    }}
                >
                    <Tab
                        label={formatMessage({
                            defaultMessage: "Activity-based emission factors",
                            description: "Tab label for adding data",
                        })}
                        iconPosition="start"
                        value="activity"
                    />
                    <Tab
                        label={formatMessage({
                            defaultMessage: "Spend-based emission factors",
                            description: "Tab label for adding data",
                        })}
                        iconPosition="start"
                        value="spend"
                    />
                </Tabs>
            </Grid>
            <Outlet />
        </Stack>
    );
};
