import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const CURRENCIES = ["NOK", "EUR", "SEK", "USD", "GBP"] as const;

interface Props {
    label: string;
    onChange: (value: string | null) => void;
    errorMessage?: string;
    value: string | null;
}

export const CurrencySelect: React.FC<Props> = ({ label, onChange, errorMessage, value }) => {
    return (
        <Autocomplete
            size="small"
            value={value}
            options={CURRENCIES}
            autoHighlight
            onChange={(_, newValue) => onChange(newValue)}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
