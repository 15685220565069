import React, { useMemo } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { loadMessages } from "../lib/i18n";

const customErrorFunction = () => {
    /* Do not err on translations */
};

export const IntlProvider: React.FC<{ locale: string; children: React.ReactNode }> = ({ locale, children }) => {
    const messages = useMemo(() => ({ ...loadMessages(locale) }), [locale]);

    return (
        <ReactIntlProvider messages={messages} locale={locale ?? "en-US"} key={locale} onError={customErrorFunction}>
            {children}
        </ReactIntlProvider>
    );
};
