import { IconButton, Stack, TextField, Typography, Button } from "@mui/material";
import { Pen } from "@ignite-analytics/icons";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    name: string;
    setName: (name: string) => void;
    edit?: boolean;
    setEdit: (edit: boolean) => void;
};

export const ChangeName: React.FC<Props> = ({ name, setName, setEdit, edit }) => {
    const [newName, setNewName] = React.useState(name);
    return (
        <Stack gap={1}>
            <Typography variant="subtitle2">
                <FormattedMessage
                    defaultMessage="What type of tag is this?"
                    description="What type of tags are you creating"
                />
            </Typography>
            {!edit ? (
                <Stack direction="row" gap={0.2} alignItems="center">
                    <Typography variant="textMd">{name}</Typography>
                    <IconButton onClick={() => setEdit(true)}>
                        <Pen fontSize="small" />
                    </IconButton>
                </Stack>
            ) : (
                <Stack gap={2} direction="row">
                    <TextField
                        sx={{ width: 250, paddiongTop: 1 }}
                        label={<FormattedMessage defaultMessage="Name" description="Name" />}
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                    <Button
                        color="secondary"
                        onClick={() => {
                            setEdit(false);
                            setName(newName);
                        }}
                    >
                        <FormattedMessage defaultMessage="Save" description="Save button" />
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};
