import { formatNumber } from "@/lib/primitives/numbers";
import { Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ImprovedEmissionsEstimate } from "router";

type Props = {
    improvedEstimates: Extract<ImprovedEmissionsEstimate, { type: "improvedActivitySupplier" }>;
};

export const MultipleSuppliersActivities: React.FC<Props> = ({ improvedEstimates }) => {
    const otherNumber = improvedEstimates.oldActivities.reduce((acc, curr) => acc + curr.value * curr.factor, 0);

    const theme = useTheme();
    if (!improvedEstimates) return null;
    return (
        <Stack gap={1}>
            <Typography variant="textMd">
                <FormattedMessage
                    defaultMessage="For this supplier in the year {year}, {numberOfOther} other activities have already been registered. You are therefore not excluding any additional data"
                    values={{
                        numberOfOther: <b>{improvedEstimates.oldActivities.length} </b>,
                        year: improvedEstimates.year,
                    }}
                />
            </Typography>
            <Typography variant="textMd">
                <FormattedMessage
                    defaultMessage="{numberOfOther} other activities = {otherNumber} kg CO₂e"
                    values={{
                        numberOfOther: <b>{improvedEstimates.oldActivities.length}</b>,
                        otherNumber: <b>{formatNumber(otherNumber, 2)}</b>,
                    }}
                />
            </Typography>
            <Typography variant="textMd" color={theme.palette.grey["600"]}>
                <FormattedMessage
                    defaultMessage="Old spend-based estimate = {total} CO₂e"
                    values={{
                        total: (
                            <b>
                                {formatNumber(
                                    improvedEstimates.spendBasedEstimate.factor *
                                        improvedEstimates.spendBasedEstimate.spend,
                                    2
                                )}
                            </b>
                        ),
                    }}
                />
            </Typography>
        </Stack>
    );
};
