/* eslint-disable fp/no-mutating-methods */
import { Item } from ".";

/**
 * Sorts items by if they have children or not.
 * Does not take any other properties into account, and preserves the original order between items with and without children.
 * Does not mutate the original array.
 */
export const sortByIfHasChildren = (items: Item[]) => {
    return items.slice().sort((a, b) => {
        if (a.children?.length && !b.children?.length) {
            return -1;
        }
        if (!a.children?.length && b.children?.length) {
            return 1;
        }
        return 0;
    });
};
