import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { createRoute, Outlet } from "@tanstack/react-router";
import { rootRoute } from "..";

export const dataInputRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/data-input",
    component: Outlet,
    errorComponent: ErrorBoundary,
});
