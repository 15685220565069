import { styled } from "@mui/material";
import Card from "@mui/material/Card";

interface DropZoneAreaProps {
    uploading: boolean | undefined;
    disabled: boolean;
    onDragEnter?: React.MouseEventHandler<HTMLDivElement>;
    onDragLeave?: React.MouseEventHandler<HTMLDivElement>;
    onDrop?: React.MouseEventHandler<HTMLDivElement>;
}

export const DropZoneArea = styled(Card)<DropZoneAreaProps>(({ theme, uploading, disabled }) => {
    return {
        width: "100%",
        border: `1px dashed`,
        borderColor: uploading && !disabled ? theme.palette.info.light : theme.palette.grey[500],
        background: uploading ? theme.palette.grey[200] : theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
    };
});
