import { Box, BoxProps, Divider, Stack } from "@mui/material";

const StepFooter: React.FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Box
            position="sticky"
            paddingBottom={3}
            bottom={0}
            bgcolor={(t) => t.palette.background.paper}
            zIndex={(theme) => theme.zIndex.appBar}
            {...props}
        >
            <Stack direction="column" spacing={3}>
                <Divider />
                {children}
            </Stack>
        </Box>
    );
};

export { StepFooter };
