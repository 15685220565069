import { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { captureException } from "@sentry/react";
import { GraphQLFormattedError } from "graphql";
import { isString } from "lodash";

const extractTags = (error: GraphQLFormattedError | NonNullable<NetworkError>) => {
    if ("extensions" in error) {
        return {
            code: isString(error.extensions?.code) ? error.extensions.code : "UNKNOWN",
            serviceName: isString(error.extensions?.serviceName) ? error.extensions.serviceName : "UNKNOWN",
            path: error.path ? error.path.join(" -> ") : undefined,
        };
    }
    return {
        statusCode: "statusCode" in error ? error.statusCode.toString() : undefined,
        bodyText: "bodyText" in error ? error.bodyText : undefined,
        response: "response" in error ? JSON.stringify(error.response.body) : undefined,
    };
};

export const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((gqlError) => {
            const additionalTags = extractTags(gqlError);
            captureException(new Error(gqlError.message), { tags: { ...additionalTags, app: "carbon-overview-app" } });
        });
    }

    if (networkError) {
        const additionalTags = extractTags(networkError);
        captureException(new Error(networkError.message), {
            tags: { ...additionalTags, app: "carbon-overview-app" },
        });
    }
});
