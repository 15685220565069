import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { TopContributorsPage } from "@/Containers/LandingPage/TopContributorsPage";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { rootRoute } from "..";

export const topContributorsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/top-contributors",
    validateSearch: z.object({
        search: z.string().optional(),
        contributorType: z.enum(["suppliers", "companyStructure", "spendCategories"]).optional().catch(undefined),
        contributorTypeLevel: z.number().optional(),
    }),
    component: TopContributorsPage,
    errorComponent: ErrorBoundary,
});
