import { Stack, Typography, Card, CardContent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

type Props = {
    children: React.ReactNode;
};

export const OverlappingDataWrapper: React.FC<Props> = ({ children }) => {
    return (
        <Card>
            <CardContent>
                <Stack gap={2}>
                    <Stack gap={0.5}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Exclude spend-based estimates"
                                description="Remove emissions from spend"
                            />
                        </Typography>
                        <Typography variant="textSm" fontWeight={400}>
                            <FormattedMessage
                                defaultMessage="Make sure to avoid double counting by defining what spend this activity corresponds to."
                                description="Remove emissions from spend description"
                            />
                        </Typography>
                    </Stack>
                    {children}
                </Stack>
            </CardContent>
        </Card>
    );
};
