/* eslint-disable fp/no-mutating-methods */
import { useGetMenuItemStyles } from "@/Containers/utils/hooks";
import { editCustomOptions, editSourceOptionsForTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { Card, CardContent, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DIALOG_HEIGHT } from "../constants";
import { getAddedDateMilliseconds } from "./helpers";

type Props = {
    id: string;
    name: string;
    children: React.ReactNode;
};
export const EditMenu: React.FC<Props> = ({ children, id, name }) => {
    const { activeMenuLinkStyle, menuLinkStyle, typographyStyle } = useGetMenuItemStyles();
    const sources = trpc.getUnifiedDataSources.useQuery();
    return (
        <Stack gap={2}>
            <Grid container direction="row" gap={2}>
                {sources.isLoading && (
                    <Grid item xs={4}>
                        <CircularProgress />
                    </Grid>
                )}
                {sources?.data && sources?.data.length > 0 && (
                    <Grid item xs={4}>
                        <Stack divider={<Divider />}>
                            <Link
                                activeProps={{ style: activeMenuLinkStyle }}
                                style={menuLinkStyle}
                                search={{ id, name }}
                                to={editCustomOptions.fullPath}
                            >
                                <Stack direction="row" alignItems="center" paddingX={1}>
                                    <StyleOutlinedIcon fontSize="inherit" />
                                    <Typography {...typographyStyle}>
                                        <FormattedMessage
                                            defaultMessage="Name and custom tags"
                                            description="Edit custom options for tag"
                                        />
                                    </Typography>
                                </Stack>
                            </Link>
                        </Stack>
                        {sources.data
                            ?.sort((a, b) => getAddedDateMilliseconds(a) - getAddedDateMilliseconds(b))
                            .map((source) => (
                                <Stack key={source.id}>
                                    <Link
                                        activeProps={{ style: activeMenuLinkStyle }}
                                        style={menuLinkStyle}
                                        search={{ id, name }}
                                        to={editSourceOptionsForTagRoute.fullPath}
                                        params={{ sourceId: source.id, sourceType: source.discriminator }}
                                    >
                                        <Stack
                                            direction="row"
                                            minWidth="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Stack direction="row" alignItems="center" overflow="ellipsis" paddingX={1}>
                                                <Typography {...typographyStyle} textOverflow="ellipsis">
                                                    {source.discriminator === "connection" ? source.name : "Supplier"}
                                                </Typography>
                                            </Stack>
                                            {source.tagColumns?.[id] ? (
                                                <CheckCircleOutlineIcon fontSize="inherit" />
                                            ) : null}
                                        </Stack>
                                    </Link>
                                </Stack>
                            ))}
                    </Grid>
                )}
                <Grid item xs={7} height="100%" minHeight={290}>
                    <Card
                        variant="outlined"
                        sx={{
                            height: DIALOG_HEIGHT - 15,
                        }}
                    >
                        <CardContent> {children}</CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Stack>
    );
};
