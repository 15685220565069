import { TrackSource } from "@/lib/tracking";
import { useGetMessages } from "../../hooks";
import { SourceConfig } from "../../types";
import { ListActionItem } from "../ListActionItem";
import { useGetOnClick } from "./hooks";

type Props = {
    config: SourceConfig;
    trackSource: TrackSource;
};
export const SourcesBreakDownListAction: React.FC<Props> = ({ config, trackSource }) => {
    const messages = useGetMessages(config);
    const onClick = useGetOnClick(config, trackSource);
    if (!messages?.viewBreakdownOfSources || !onClick || !config.year) return null;

    return <ListActionItem onClick={onClick} text={messages.viewBreakdownOfSources} />;
};
