import { SpendFormReturn } from "@/Router/interfaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ButtonRow } from "../../../../Components/PostFormButton";
import { DataColumnSelect } from "../DataColumnSelect";
import {
    getClassificationColumnByGlobalType,
    getDateColumnByGlobalType,
    getNumberColumnByGlobalType,
    getRelationalColumnByGlobalType,
    getReturnValueFromSpendForm,
} from "../helpers";
import { useGetTableAndColumnMessages } from "../hooks";
import { DataColumn, SpendFormType, useGetSpendTableZodSchema } from "../interfaces";
import { TagColumnSelect } from "../TagsColumnPicker";

interface Props {
    columns: DataColumn[];
    tableId: string;
    onSubmitForm: (data: SpendFormReturn) => void;
    defaultValues: Partial<SpendFormType> | undefined;
}

export const SpendDataTableConnectionForm: React.FC<Props> = ({ onSubmitForm, columns, tableId, defaultValues }) => {
    const spendTableZodSchema = useGetSpendTableZodSchema();

    const {
        dateColumnSelectMessage,
        spendColumnSelectMessage,
        emissionFactorColumnSelectMessage,
        emissionStructureColumnSelectMessage,
        supplierColumnSelectMessage,
    } = useGetTableAndColumnMessages();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<SpendFormType>({
        defaultValues: {
            dateColumn: getDateColumnByGlobalType(columns, "spend_date"),
            spendColumn: getNumberColumnByGlobalType(columns, "spend_amount"),
            emissionFactorColumn: getNumberColumnByGlobalType(columns, "exiobase_ef"),
            emissionStructureColumn: getClassificationColumnByGlobalType(columns, "ghg_scopes_and_categories"),
            supplierColumn: getRelationalColumnByGlobalType(columns, "supplier_relation"),
            ...defaultValues,
        },
        resolver: zodResolver(spendTableZodSchema),
    });

    const onSubmit: SubmitHandler<SpendFormType> = useCallback(
        (data) => {
            onSubmitForm(getReturnValueFromSpendForm(data));
        },
        [onSubmitForm]
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
                <Controller
                    control={control}
                    name="dateColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.dateColumn?.message}
                            label={dateColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="date"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="spendColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.spendColumn?.message}
                            label={spendColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="number"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="emissionFactorColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.emissionFactorColumn?.message}
                            label={emissionFactorColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="number"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="emissionStructureColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value}
                            errorMessage={errors.emissionStructureColumn?.message}
                            label={emissionStructureColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="classification"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="supplierColumn"
                    render={({ field: { onChange, value } }) => (
                        <DataColumnSelect
                            value={value ?? null}
                            errorMessage={errors.supplierColumn?.message}
                            label={supplierColumnSelectMessage}
                            onChange={onChange}
                            dataColumns={columns}
                            type="relation"
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="tagColumns"
                    render={({ field: { onChange, value } }) => (
                        <TagColumnSelect
                            columnsSelectedForTags={value || {}}
                            setColumnForTag={(tagId, column) => {
                                if (!column) {
                                    const { [tagId]: _, ...rest } = value || {};
                                    onChange(rest);
                                }
                                onChange({
                                    ...value,
                                    [tagId]: column,
                                });
                            }}
                            tableId={tableId}
                        />
                    )}
                />
                <ButtonRow />
            </Stack>
        </form>
    );
};
