import { SimpleDialog } from "@/Components/SimpleDialog";
import { dataPreferenceCustomCategoriesRoute } from "@/Router/DataPreferencesRoute";
import { Card, CardContent, Paper } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { CustomCategoryWithMetaData } from "router";
import { CustomCategoryForm } from "../CustomCategoryForm";

type Props = {
    category?: CustomCategoryWithMetaData;
    onClose: () => void;
    onRefetch: () => void;
};

export const CustomCategoryDialog: React.FC<Props> = ({ category, onClose, onRefetch }) => {
    const { formatMessage } = useIntl();
    const { newCategory } = useSearch({ from: dataPreferenceCustomCategoriesRoute.fullPath });
    if (!category && !newCategory) {
        return null;
    }

    const isEdit = !!category && !newCategory;
    const messages = defineMessages({
        editTitle: {
            defaultMessage: "Edit custom category",
            description: "Title for edit custom category dialog",
        },
        newTitle: {
            defaultMessage: "New custom category",
            description: "Title for new custom category dialog",
        },
    });

    return (
        <SimpleDialog title={formatMessage(isEdit ? messages.editTitle : messages.newTitle)} open onClose={onClose}>
            <Card component={Paper}>
                <CardContent>
                    <CustomCategoryForm existingCategory={category ?? undefined} refetchCallback={onRefetch} />
                </CardContent>
            </Card>
        </SimpleDialog>
    );
};
