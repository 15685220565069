import { useCallback, useEffect } from "react";

export function useKeyboardShortcut(
    key: string,
    plain: boolean,
    callback: (event: KeyboardEvent) => void,
    active = true
) {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (!active) return;
            if (plain || event.ctrlKey || event.metaKey) {
                if (event.key === key) {
                    event.preventDefault();
                    callback(event);
                }
            }
        },
        [key, plain, callback, active]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);
}
