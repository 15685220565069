import { trpc } from "@/lib/trpc/client";
import { FormFilter } from "@/Components/FormV2/types";
import { Trash } from "@ignite-analytics/icons";
import { Autocomplete, Chip, IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { hasValue } from "utils";
import { DeprecatedEmissionsFilter, FieldOption, FilterField } from "router";
import { FilterFormValue } from "../types";
import { DataTakenIntoAccount } from "../../../types";

interface Props {
    selectedFilter: FilterFormValue;
    changeFilter: (filter: { filterField: FilterField; value?: FieldOption }) => void;
    removeFilter: () => void;
    optionFilter: {
        filterList?: FormFilter[];
        dataTakenIntoAccount: DataTakenIntoAccount;
    };
    error?: { filterField?: { message?: string }; value?: { message?: string } };
}

export const FilterRow: React.FC<Props> = ({
    selectedFilter,
    changeFilter,
    removeFilter,
    optionFilter: { dataTakenIntoAccount, filterList },
    error,
}) => {
    const { supplier, from, to, tags } = dataTakenIntoAccount;
    const customFilter: { field: string; values: string[] }[] | undefined = filterList
        ?.map((filterValue) => {
            if (filterValue.filterField && filterValue.value) {
                return { field: filterValue.filterField.id, values: [filterValue.value.value] };
            }
            return null;
        })
        .filter(hasValue);
    const tagFilter: DeprecatedEmissionsFilter["tag"] = {
        tags: tags?.map((tag) => ({ tagId: tag.id, values: [tag.value.value] })),
    };
    const { formatMessage } = useIntl();
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(selectedFilter.value?.value);
    const filterFields = trpc.filterFields.useQuery({ type: "spend" });
    const fieldOptions = trpc.fieldOptions.useQuery(
        {
            sourceId: { type: "spend" },
            filter: {
                type: selectedFilter?.filterField?.type || "text",
                id: selectedFilter?.filterField?.id || "",
                labelField: selectedFilter.filterField?.labelField,
            },
            searchTerm,
            filters: {
                from,
                to,
                supplierId: supplier?.id,
                customFilter,
                tagFilter,
            },
        },
        { enabled: !!selectedFilter?.filterField?.id }
    );
    const options = filterFields?.data?.filterFields ?? [];
    const fieldOptionsValues = fieldOptions?.data?.fieldOptions.options ?? [];
    return (
        <Stack gap={1} direction="row" alignItems="center">
            <Chip label={formatMessage({ defaultMessage: "if" })} />
            <Autocomplete
                openOnFocus
                disableClearable
                loading={filterFields.status === "loading"}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                    options.find((option) => option.id === selectedFilter?.filterField?.id) ||
                    selectedFilter.filterField
                }
                size="small"
                id="fieldPicker"
                options={options}
                getOptionLabel={(option) => option.label ?? option.id}
                onChange={(_, newValue) => {
                    changeFilter({ filterField: newValue });
                }}
                renderOption={(props, option) => (
                    <Stack {...props} component="li" direction="row" alignItems="center" key={option.id}>
                        {option.label ?? option.id}
                    </Stack>
                )}
                fullWidth
                renderInput={(params) => (
                    <TextField
                        placeholder={formatMessage({ defaultMessage: "Select a field" })}
                        {...params}
                        error={!!error?.filterField}
                        helperText={error?.filterField?.message}
                    />
                )}
            />
            <Chip label={formatMessage({ defaultMessage: "is" })} />
            <Autocomplete
                openOnFocus
                onInputChange={(_, newInputValue) => {
                    setSearchTerm(newInputValue);
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                inputValue={searchTerm}
                loading={fieldOptions.isLoading}
                value={
                    fieldOptionsValues.find((option) => option.value === selectedFilter?.value?.value) ||
                    selectedFilter.value ||
                    null
                }
                disabled={!selectedFilter.filterField || !fieldOptionsValues}
                size="small"
                id="optionPicker"
                options={fieldOptionsValues}
                onChange={(_, newValue) => {
                    selectedFilter?.filterField &&
                        changeFilter({ filterField: selectedFilter?.filterField, value: newValue ?? undefined });
                }}
                fullWidth
                renderInput={(params) => {
                    if (!fieldOptionsValues) {
                        return (
                            <TextField
                                {...params}
                                placeholder={formatMessage({ defaultMessage: "Select an option" })}
                                error={!!error?.value}
                                helperText={error?.value?.message}
                            />
                        );
                    }
                    return (
                        <TextField
                            {...params}
                            placeholder={formatMessage({ defaultMessage: "Select an option" })}
                            error={Boolean(error?.value)}
                            helperText={error?.value?.message}
                        />
                    );
                }}
            />
            <IconButton
                onClick={() => {
                    removeFilter();
                }}
            >
                <Trash fontSize="small" />
            </IconButton>
        </Stack>
    );
};
