import { AlertSnackBar } from "@/Components/AlertSnackBar";
import { Box, Container } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useBreadcrumbs } from "./useBreadcrumbs";

export const Root = () => {
    useBreadcrumbs();
    /**
     * Feature toggle: [LaunchDarkly](https://app.launchdarkly.com/projects/default/flags/carbon-developers/targeting?env=production&env=test&selected-env=production)
     *
     * Should only ever be true for carbon developers, as it's a feature toggle to hide dev tools.
     */
    const isCarbonDev = useFeatureToggle("carbon-developers");
    return (
        <>
            {isCarbonDev && <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />}
            <Box
                /**
                 * We/FE-guild should figure out a better way of handling this, e.g. by adding some flex properties to
                 * the parent containers in web to make the layout easier to handle vertically.
                 *
                 * Calculation:
                 * 64px: App bar
                 */
                sx={{
                    height: "calc(100vh - 64px)",
                    maxHeight: "calc(100vh - 64px)",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                }}
            >
                {/* Grow the main content to fill the available space */}
                <Container
                    maxWidth="xl"
                    sx={{ flex: "1 0 auto", maxHeight: "100%", padding: (t) => `${t.spacing(1, 2, 0, 2)} !important` }}
                >
                    <Outlet />
                    <AlertSnackBar />
                </Container>
            </Box>
        </>
    );
};
