import { OtherTableForm } from "@/Containers/DataSources/DataSourceForms/Other";
import { SpendForm } from "@/Containers/DataSources/DataSourceForms/Spend";
import { SpendBasedEmissionsForm } from "@/Containers/DataSources/DataSourceForms/SpendBasedEmissions";
import { SupplierForm } from "@/Containers/DataSources/DataSourceForms/Supplier";
import { dataPreferenceDataSourcesOverview, editDataSourceRoute } from "@/Router/DataPreferencesRoute";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";

export const EditDataSourceDetails: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams({ from: editDataSourceRoute.id });
    const postSubmit = () => {
        navigate({ to: dataPreferenceDataSourcesOverview.fullPath });
    };
    return (
        <Stack direction="column" spacing={2} padding={2}>
            {params.dataSourceType === "other" && (
                <OtherTableForm postSubmit={postSubmit} connectionId={params.dataSourceId} />
            )}
            {params.dataSourceType === "spend" && (
                <SpendForm postSubmit={postSubmit} connectionId={params.dataSourceId} />
            )}
            {params.dataSourceType === "spend-based" && (
                <SpendBasedEmissionsForm postSubmit={postSubmit} connectionId={params.dataSourceId} />
            )}
            {params.dataSourceType === "supplier" && <SupplierForm postSubmit={postSubmit} />}
        </Stack>
    );
};
