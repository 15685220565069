import { OverviewFilters, useSelectedElectricityBasis, useSelectedYear } from "@/Containers/LandingPage/hooks";
import { trpc } from "@/lib/trpc/client";
import React, { createContext, useCallback, useContext, useMemo } from "react";

type HandleFilter = {
    filter: OverviewFilters;
    setFilter: (filter: OverviewFilters) => void;
    prefetch: (newFilters: OverviewFilters) => void;
};
const FilterContext = createContext<HandleFilter | null>(null);

export const FilterContextProvider: React.FC<Omit<HandleFilter, "prefetch"> & { children: React.ReactNode }> = ({
    children,
    filter,
    setFilter,
}) => {
    const utils = trpc.useContext();
    const prefetch = useCallback(
        (newFilters: OverviewFilters) => {
            const { electricity, ...filters } = newFilters;
            utils.overview.prefetch({ electricity, filters: { ...filters, dateFilter: null } });
        },
        [utils.overview]
    );
    const currentlySelectedYear = useSelectedYear();
    const currentElectricitySetting = useSelectedElectricityBasis();
    const value = useMemo(() => {
        return {
            filter: { ...filter, year: currentlySelectedYear, electricity: currentElectricitySetting },
            setFilter,
            prefetch,
        };
    }, [filter, currentlySelectedYear, currentElectricitySetting, setFilter, prefetch]);

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

const useFilterContext = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error("useFilter must be used within a FilterContextProvider");
    }
    return context;
};

/**
 * Returns the active selection of filters.
 * **NB**: Only for use internally in the filter component. Can not be used to access the filter state from other components.
 */
export const useCurrentFilterMenuValues = () => {
    const { filter } = useFilterContext();
    return filter;
};

const useSetFilterData = () => {
    const { setFilter, prefetch } = useFilterContext();
    const setFilterFunction = (filter: OverviewFilters) => {
        setFilter(filter);
        prefetch(filter);
    };
    return setFilterFunction;
};

export const useFilterHandlers = <K extends keyof OverviewFilters>(key: K) => {
    const setFilterData = useSetFilterData();
    const filters = useCurrentFilterMenuValues();
    const setFilter = useCallback(
        (value: OverviewFilters[K]) => {
            setFilterData({ ...filters, [key]: value });
        },
        [filters, key, setFilterData]
    );
    return { filter: filters[key], setFilter };
};

export const useFilterToggler = (filter: string[] | null, setFilter: (values: string[] | null) => void) => {
    return useCallback(
        (value: string) => {
            let newValues: string[] = filter ?? [];
            const wasSelected = filter?.includes(value);
            if (wasSelected) {
                // Remove the value from the filter
                newValues = newValues.filter((v) => v !== value);
            } else {
                // Add the value to the filter
                newValues = newValues.concat(value);
            }
            // Remove filter if empty
            if (newValues.length === 0) {
                return setFilter(null);
            }
            // Update the filter with the new values.
            return setFilter(newValues);
        },
        [filter, setFilter]
    );
};
