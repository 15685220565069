import { useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { scope1Types, scope2Types, scope3Types } from "router";
import { useGetFill } from "../../../useGetFill";

export const useGetBarElements = (): { id: string; color: string; onClick?: () => void; goBack?: () => void }[] => {
    const setSelectedScope = useSetSelectedScope();
    const selectedScope = useGetSelectedScope();
    const scopes = ["1", "2", "3"] as const;

    const getFill = useGetFill();
    if (selectedScope === "all") {
        return scopes.map((scope, i) => ({
            id: scope,
            color: getFill(i, 3),
            onClick: () => {
                setSelectedScope(scope, "EmissionsByYearChart");
            },
        }));
    }
    if (selectedScope === "1") {
        return scope1Types.map((type, index) => ({
            id: type,
            color: getFill(index, scope1Types.length),
            goBack: () => {
                setSelectedScope("all", "EmissionsByYearChart");
            },
        }));
    }
    if (selectedScope === "2") {
        return scope2Types.map((type, index) => ({
            id: type,
            color: getFill(index, scope2Types.length),
            goBack: () => {
                setSelectedScope("all", "EmissionsByYearChart");
            },
        }));
    }
    return scope3Types.map((type, index) => ({
        id: type,
        color: getFill(index, scope3Types.length),
        goBack: () => {
            setSelectedScope("all", "EmissionsByYearChart");
        },
    }));
};
