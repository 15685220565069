import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { LandingPageWrapper } from "@/Containers/LandingPage/LandingPageWrapper";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { scopeCategoryTypes } from "router";
import { rootRoute } from "..";

export const overviewRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/overview",
    component: LandingPageWrapper,
    validateSearch: z.object({
        activityId: z.string().optional(),
        viewType: z.enum(["demo", "learnMore"]).optional().catch(undefined),
        limit: z.number().optional(),
        breakdown: z
            .object({
                year: z.number(),
                discriminator: z.discriminatedUnion("type", [
                    z.object({
                        type: z.literal("overview"),
                        category: z.enum([...scopeCategoryTypes, "scope1", "scope2", "scope3"]),
                    }),
                    z.object({
                        type: z.literal("year"),
                    }),
                    z.object({ type: z.literal("supplier"), supplierId: z.string(), supplierName: z.string() }),
                ]),
            })
            .optional(),
        tour: z.enum(["emission-intensities-0", "emission-intensities-1"]).optional().catch(undefined),
        emissionIntensity: z.string().optional().catch(undefined),
    }),
    errorComponent: ErrorBoundary,
});
