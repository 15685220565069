import { Button, DialogTitle, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { X } from "@ignite-analytics/icons";
import React from "react";

type Props = {
    onClose: () => void;
};
export const CloseDialogTitle: React.FC<Props> = ({ onClose }) => {
    return (
        <DialogTitle>
            <Stack justifyContent="flex-end" alignItems="center" direction="row">
                <Button onClick={onClose} size="2xsmall" color="ghostGray" endIcon={<X fontSize="inherit" />}>
                    <FormattedMessage defaultMessage="Close" description="Close dialog button" />
                </Button>
            </Stack>
        </DialogTitle>
    );
};
