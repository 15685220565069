import { EmissionChange } from "@/Components/EmissionChange";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { CompactTableEmissionDataRow, getRelevantYears } from "../../helpers";
import { CompactEmissionsCell } from "../CompactEmissionCell";
import { useGetRowName } from "./helpers";

const scopeToScope = {
    "Scope 1": "1" as const,
    "Scope 2": "2" as const,
    "Scope 3": "3" as const,
};

interface Props {
    rowDescriptor: "Scope 1" | "Scope 2" | "Scope 3" | "Total";
    data: CompactTableEmissionDataRow;
}
const CompactEmissionRow: React.FC<Props> = ({ rowDescriptor, data }) => {
    const relevantYears = getRelevantYears();
    const { name, descriptor } = useGetRowName(rowDescriptor);

    return (
        <TableRow>
            <TableCell>
                <Stack>
                    <Typography variant={rowDescriptor === "Total" ? "subtitle2" : "textSm"}>{name}</Typography>
                    <Typography color="GrayText" variant="textSm">
                        {descriptor}
                    </Typography>
                </Stack>
            </TableCell>
            {relevantYears.map((year) => (
                <CompactEmissionsCell
                    key={year}
                    scope={scopeToScope[rowDescriptor] ?? "Total"}
                    year={year}
                    value={data[year]}
                />
            ))}
            <TableCell align="right">
                <EmissionChange perYear={data} year={relevantYears[relevantYears.length - 1]} />
            </TableCell>
        </TableRow>
    );
};

export default CompactEmissionRow;
