import { useAlert } from "@/Providers/Alerts";
import { trpc } from "@/lib/trpc/client";
import { range } from "@ignite-analytics/general-tools";
import { X } from "@ignite-analytics/icons";
import {
    Alert,
    Button,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { RouteButton } from "../../components/RouteButton";
import { StepFooter } from "../components/StepFooter";

type RadioWithLabelProps = {
    primaryText: React.ReactNode;
    secondaryText: React.ReactNode;
    value: string;
};

const RadioWithLabel: React.FC<RadioWithLabelProps> = ({ primaryText, secondaryText, value }) => {
    return (
        <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={<Radio sx={{ minWidth: "16px" }} />}
            value={value}
            label={
                <Stack direction="column" spacing={0.25}>
                    <Typography variant="textSm" fontWeight="medium">
                        {primaryText}
                    </Typography>
                    <Typography variant="textSm" color={(t) => t.palette.text["text-helper"]}>
                        {secondaryText}
                    </Typography>
                </Stack>
            }
        />
    );
};

/**
 * Fetches the classification structure for the company and returns the depth of the structure and examples for each level.
 */
const useQuery = () => {
    const result = trpc.getClassificationStructureDescription.useQuery({ type: "companyStructure" });
    if (result.status === "loading") return undefined;
    if (result.status === "error") {
        if (result.error.data?.code === "NOT_FOUND") {
            return { hasGranularCompanyLevels: false } as const;
        }
        throw result.error;
    }
    return { hasGranularCompanyLevels: true, ...result.data } as const;
};

type FormData = {
    level: string; // 0 means company-wide, 1 means level 1 in company structure, and so on
};

const LevelLabelText = ({ level }: { level: number }) => {
    return (
        <FormattedMessage
            defaultMessage="Level {level}"
            description="Label for the radio button to select a specific level when selecting the level for which to add emission intensities"
            values={{
                level,
            }}
        />
    );
};

const SelectLevel: React.FC = () => {
    const structureData = useQuery();
    const { control, handleSubmit } = useForm<FormData>({
        defaultValues: {
            level: "0",
        },
    });
    const navigate = useNavigate();
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const createEmissionIntensity = trpc.createEmissionIntensity.useMutation();
    const { name, type, unit, fromYear, toYear } = useSearch({
        from: "/data-preferences/emission-intensities/new/step/level",
    });
    const [contactSupportClosed, setContactSupportClosed] = useState(false);
    /**
     * @todo check for company structure etc. always showing this for easy of testing
     */
    const contactSupportOpen = !contactSupportClosed;

    function navigateToNextStep(data: FormData) {
        const level = Number.parseInt(data.level, 10);
        createEmissionIntensity.mutate(
            { name, type, unit, level, startYear: fromYear, endYear: toYear },
            {
                onSuccess(result) {
                    if (result.ok) {
                        navigate({
                            to: "/data-preferences/emission-intensities/new/step/$emissionIntensityId/enter-information",
                            params: { emissionIntensityId: result.data.emissionIntensity.id },
                        });
                    } else {
                        alertUser({
                            severity: "error",
                            value: formatMessage(
                                {
                                    defaultMessage: "An unexpected error occured, error code: { code }",
                                    description:
                                        "Error message displayed if initially adding an emission intensity fails, which is unexpected",
                                },
                                { code: result.error.code }
                            ),
                        });
                    }
                },
            }
        );
    }

    const formId = "select-level-form";

    return (
        <Stack height="100%">
            <Stack spacing={3}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Select a level from the list below"
                            description="Section header for the select level step when adding emission intensities"
                        />
                    </Typography>
                    <Typography variant="textSm" color={(t) => t.palette.text["text-helper"]}>
                        <FormattedMessage
                            defaultMessage="Which level would you like to measure emission intensities for?"
                            description="Section subheader for the select level step when adding emission intensities"
                        />
                    </Typography>
                </Stack>
                {contactSupportOpen && !structureData?.hasGranularCompanyLevels && (
                    <Alert
                        severity="info"
                        action={
                            <IconButton
                                aria-label={formatMessage({
                                    defaultMessage: "Close",
                                    description:
                                        "Aria-label for close icon button for alert about contacting ignite support to set up company structure when creating an emission intensity",
                                })}
                                color="inherit"
                                size="small"
                                onClick={() => setContactSupportClosed(true)}
                            >
                                <X color="secondary" fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <Stack direction="column" alignItems="flex-start" spacing={2}>
                            <Typography variant="textSm" color={(t) => t.palette.text["text-tertiary"]}>
                                <FormattedMessage
                                    defaultMessage="We are not able to find a department structure for your organization in Ignite. You can proceed and add data on the company level, or contact Ignite Support if you are interested in measuring intensities on a more granular level."
                                    description="Info message to encourage the user to contact Ignite Support if they haven't set up a company structure in Ignite."
                                />
                            </Typography>
                            {window.Intercom && (
                                <Button
                                    color="quarternary"
                                    size="2xsmall"
                                    onClick={() => {
                                        window.Intercom(
                                            "showNewMessage",
                                            "Hi, I need help with setting up my company structure in Ignite."
                                        );
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Contact Ignite Support" />
                                </Button>
                            )}
                        </Stack>
                    </Alert>
                )}
            </Stack>

            <form onSubmit={handleSubmit(navigateToNextStep)} id={formId}>
                <Controller
                    control={control}
                    name="level"
                    render={({ field, formState: { disabled } }) => (
                        <FormControl disabled={disabled} fullWidth sx={{ mb: 2 }}>
                            <RadioGroup {...field}>
                                <Stack direction="column" spacing={3} mt={7.5}>
                                    <RadioWithLabel
                                        value="0"
                                        primaryText={
                                            <FormattedMessage
                                                defaultMessage="Company"
                                                description="Label for the radio button to select 'Company level' when selecting the level for which to add emission intensities"
                                            />
                                        }
                                        secondaryText={
                                            <FormattedMessage
                                                defaultMessage="Add data for the whole reporting entity"
                                                description="Helper text for the label for the radio button to select 'Company level' when selecting the level for which to add emission intensities"
                                            />
                                        }
                                    />
                                    {structureData?.hasGranularCompanyLevels && (
                                        <>
                                            <Divider />
                                            {range(1, structureData.depth + 1).map((level) => {
                                                const examples = structureData.examplesPerLevel[level];
                                                return (
                                                    <Stack direction="row" justifyContent="space-between" key={level}>
                                                        <RadioWithLabel
                                                            key={level}
                                                            value={level.toString()}
                                                            primaryText={<LevelLabelText level={level} />}
                                                            secondaryText={
                                                                <FormattedMessage
                                                                    defaultMessage="Examples: {examples}"
                                                                    description="Helper text for the label for the radio button to select the level for which to add emission intensities"
                                                                    values={{
                                                                        examples: `${
                                                                            examples
                                                                                ?.slice(0, 5)
                                                                                .map((example) => example.label)
                                                                                .join(", ") ?? "-"
                                                                        }`,
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        {examples !== undefined && (
                                                            <Chip
                                                                size="small"
                                                                label={
                                                                    <FormattedMessage
                                                                        defaultMessage="{numberOfOptions} options"
                                                                        description="Label for badge displaying the number of options for each level in the company structure when creating an emission intensity"
                                                                        values={{
                                                                            numberOfOptions:
                                                                                structureData.examplesPerLevel[level]
                                                                                    ?.length,
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    </Stack>
                                                );
                                            })}
                                        </>
                                    )}
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    )}
                />
            </form>

            <StepFooter>
                <Stack direction="row" justifyContent="space-between">
                    <RouteButton color="ghostGray" size="small" to="/overview">
                        <FormattedMessage defaultMessage="Cancel" description="Cancel action" />
                    </RouteButton>
                    <Stack direction="row" spacing={2}>
                        <RouteButton
                            color="secondary"
                            size="small"
                            to="/data-preferences/emission-intensities/new/step/select-year"
                            search
                        >
                            <FormattedMessage defaultMessage="Back" description="Previous step" />
                        </RouteButton>
                        <Button size="small" type="submit" form={formId}>
                            <FormattedMessage defaultMessage="Next" description="Next step" />
                        </Button>
                    </Stack>
                </Stack>
            </StepFooter>
        </Stack>
    );
};

export { SelectLevel };
