import { defaultExclude } from "@/Components/AddActivityForm/AddActivity/Form/helpers";
import { DefaultFormType } from "@/Components/AddActivityForm/types";
import { Stack, TextField, Typography, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Supplier } from "router";
import { SupplierOptions } from "../types";

type Props = {
    value: Supplier | null;
    onChange: (input: { id: string; label: string } | null) => void;
    searchPhrase: string;
    onSearch: (phrase: string) => void;
    optionsResult: SupplierOptions;
};

const SupplierOption: React.FC<Supplier> = ({ label, companyRegNr, country }) => {
    return (
        <Stack overflow="hidden">
            <Typography noWrap variant="textMd" textOverflow="ellipsis">
                {label}
            </Typography>
            <Typography noWrap variant="caption">
                {country} {companyRegNr}
            </Typography>
        </Stack>
    );
};

export const InnerSupplierPicker: React.FC<Props> = ({ value, optionsResult, onChange, onSearch, searchPhrase }) => {
    const { status } = optionsResult;
    const { setValue, watch } = useFormContext<DefaultFormType>();

    const removeEmissionsFromSpendSource = watch("removeEmissionsFromSpendSource");
    const scopeType = watch("scopeType");

    const { formatMessage } = useIntl();
    const theme = useTheme();
    return (
        <Autocomplete
            fullWidth
            size="small"
            openOnFocus
            options={status === "success" ? optionsResult.suppliers : []}
            value={value}
            onInputChange={(_, phrase) => {
                onSearch(phrase);
            }}
            inputValue={searchPhrase}
            renderOption={(props, option) => (
                <li {...props}>
                    <SupplierOption {...option} />
                </li>
            )}
            getOptionLabel={(option) => option.label}
            loading={status === "loading"}
            loadingText={formatMessage({ defaultMessage: "Loading", description: "Loading message" })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": "supplier-input",
                    }}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 1,
                    }}
                    error={status === "error"}
                    helperText={status === "error" ? optionsResult.error : undefined}
                    placeholder={formatMessage({
                        defaultMessage: "Select a supplier",
                        description: "Placeholder for supplier picker",
                    })}
                />
            )}
            onChange={(_, selectedValue) => {
                // If the activity has a supplier and is in a certain category, the emissions default to be be removed from the spend source
                if (selectedValue && !removeEmissionsFromSpendSource && defaultExclude.includes(scopeType)) {
                    setValue("removeEmissionsFromSpendSource", true);
                }
                if (selectedValue === null) {
                    setValue("removeEmissionsFromSpendSource", false);
                }
                onChange(selectedValue ? { id: selectedValue.id, label: selectedValue.label } : null);
            }}
        />
    );
};
