import { Divider, Link, Stack, Typography } from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import { FormattedMessage } from "react-intl";

export const PaperWithFooter: React.FC<PaperProps & React.PropsWithChildren> = ({ children, ...props }) => {
    return (
        <Paper {...props}>
            {children}
            <Divider />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ backgroundColor: (theme) => theme.palette.gray.main }}
                width="100%"
                py={0.5}
                px={2}
                gap={1}
                onMouseDown={(e) => {
                    e.preventDefault();
                }}
            >
                <Typography variant="textSm" color="textSecondary">
                    <FormattedMessage
                        defaultMessage="Not the results you expected?"
                        description="Activity picker footer description"
                    />
                </Typography>
                <Typography variant="textSm">
                    <Link href="https://www.ignite.no/carbon-accounting-methodology" target="_blank" underline="none">
                        <FormattedMessage
                            defaultMessage="Learn more"
                            description="Activity picker footer learn more link"
                        />
                    </Link>
                </Typography>
            </Stack>
        </Paper>
    );
};
