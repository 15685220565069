import { useState } from "react";

export const useGetEmissionOverYearsConfig = () => {
    const activiteTabFromLocalStorage = localStorage.getItem("Carbon:tableOrGraph");
    const defaultValueActiveTab = activiteTabFromLocalStorage === "graph" ? "graph" : "table";
    const [activeTab, setTab] = useState<"table" | "graph">(defaultValueActiveTab);

    const compactTableFromLocalStorage = localStorage.getItem("Carbon:compactTable");

    const defaultValueCompactTable = compactTableFromLocalStorage !== "false";

    const [showCompactTable, setShowCompactTable] = useState(defaultValueCompactTable);

    return {
        activeTab,
        setTab: (tab: "table" | "graph") => {
            localStorage.setItem("Carbon:tableOrGraph", tab);
            setTab(tab);
        },
        showCompactTable,
        setShowCompactTable: (show: boolean) => {
            localStorage.setItem("Carbon:compactTable", show.toString());
            setShowCompactTable(show);
        },
    };
};
