import { useKeyboardShortcut } from "@/lib/tools";
import { trpc } from "@/lib/trpc/client";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    ListSubheader,
    MenuList,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Search } from "@ignite-analytics/icons";
import { useDebouncedValue } from "@mantine/hooks";
import { useCallback, useState } from "react";
import { useDebounce } from "@ignite-analytics/general-tools";
import { FieldOption } from "router";
import { OptionRow } from "./optionsRow";

type Props = {
    tagName: string;
    tagId: string;
    onClose: () => void;
    setOptionForTag: (option: FieldOption | undefined) => void;
    selectedOption: FieldOption | undefined;
};
export const OptionsSelectModal: React.FC<Props> = ({ tagName, onClose, setOptionForTag, tagId, selectedOption }) => {
    const [searchPhrase, setSearchPhrase] = useState("");
    const [debouncedSearchPhrase] = useDebouncedValue(searchPhrase, 300);

    const optionsResponse = trpc.getOptionsFromTag.useQuery({
        id: tagId,
        searchString: debouncedSearchPhrase,
    });
    const [focusList, setFocusList] = useState(false);
    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);

    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);
    const onChange = useDebounce((query: string) => {
        setSearchPhrase(query);
    }, 500);

    const handleClose = useCallback(() => {
        onClose();
        setFocusList(false);
        setSearchPhrase("");
    }, [setSearchPhrase, onClose]);

    return (
        <Dialog fullWidth open onClose={handleClose}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <FormattedMessage
                        defaultMessage="Set a tag for {tagName}"
                        description="Title for tag selection modal"
                        values={{ tagName }}
                    />
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <DialogContentText>
                        <FormattedMessage
                            defaultMessage="You can add tags to your activity data to be able to look at your emissions in different dimensions."
                            description="Sub title for tag selection modal"
                        />
                    </DialogContentText>
                    <TextField
                        size="small"
                        fullWidth
                        onChange={(e) => onChange(e.target.value)}
                        onFocus={() => setFocusList(false)}
                        autoFocus={!focusList}
                        placeholder="Search for tag options"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <MenuList autoFocus={focusList} sx={{ maxHeight: 300 }}>
                        {!optionsResponse?.data?.optionsFromConnections.length &&
                            !optionsResponse?.data?.predefinedOptions.length && (
                                <Stack direction="row" justifyContent="center" px={2} pb={2}>
                                    <Typography color="text.secondary">
                                        <FormattedMessage defaultMessage="No results" description="No results text" />
                                    </Typography>
                                </Stack>
                            )}
                        {optionsResponse.error && (
                            <Stack direction="row" justifyContent="center" px={2} pb={2}>
                                <Typography color="text.secondary">
                                    <FormattedMessage
                                        defaultMessage="Failed to load options"
                                        description="Error text"
                                    />
                                </Typography>
                            </Stack>
                        )}
                        {optionsResponse.isSuccess ? (
                            <>
                                {optionsResponse?.data?.optionsFromConnections.length > 0 && (
                                    <>
                                        <ListSubheader>
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    defaultMessage="Options from connections"
                                                    description="Predefined options"
                                                />
                                            </Typography>
                                        </ListSubheader>
                                        {optionsResponse.data.optionsFromConnections.map((option) => (
                                            <OptionRow
                                                optionLabel={option.label}
                                                onClick={() => {
                                                    onClose();
                                                    setOptionForTag(option);
                                                }}
                                                key={option.value}
                                            />
                                        ))}
                                    </>
                                )}
                                {optionsResponse?.data?.predefinedOptions.length > 0 && (
                                    <>
                                        <ListSubheader>
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    defaultMessage="Predefined options"
                                                    description="Predefined options"
                                                />
                                            </Typography>
                                        </ListSubheader>
                                        {optionsResponse.data.predefinedOptions.map((option) => (
                                            <OptionRow
                                                optionLabel={option.label}
                                                onClick={() => {
                                                    onClose();
                                                    setOptionForTag(option);
                                                }}
                                                key={option.value}
                                            />
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Grid container alignItems="center" justifyContent="center">
                                {" "}
                                <CircularProgress color="inherit" size={20} />{" "}
                            </Grid>
                        )}
                    </MenuList>
                </Stack>
            </DialogContent>
            <DialogActions>
                {selectedOption && (
                    <Button
                        onClick={() => {
                            setOptionForTag(undefined);
                            onClose();
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="Remove current value"
                            description="Remove current value button"
                        />
                    </Button>
                )}
                <Button variant="outlined" color="secondary" onClick={handleClose}>
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
