import { trpc } from "@/lib/trpc/client";

import { updateFiles } from "@/Components/FileUploadComponent";
import { UploadFileInfo } from "@/Components/FileUploadComponent/FileListItem/UploadFileInfo.model";
import { CARBON_EVENTS } from "@/lib/tracking";
import { useState } from "react";
import { Activity } from "router";
import { useGetMutationCleanupFunction } from "../hooks";
import { AddActivityForm } from "./Form";

export const AddActivity = () => {
    const { onSuccess, onError } = useGetMutationCleanupFunction();
    const [files, setFile] = useState<UploadFileInfo[] | null>(null);

    const addActivity = trpc.addActivity.useMutation({
        onSuccess: ({ activityId }) => {
            const uploadFileFunction = updateFiles(activityId);
            if (files) {
                onSuccess(activityId);
                uploadFileFunction(files)
                    .catch(onError)
                    .then(() => {
                        setFile(null);
                    });
            } else {
                onSuccess(activityId);
            }
        },
        onError: (error) => {
            onError(error.message);
        },
    });

    const onSubmitFormData = async (formValues: Activity, uploadedFiles: UploadFileInfo[] | null) => {
        const { scopeType } = formValues;
        addActivity.mutate(formValues);
        setFile(uploadedFiles);
        if (formValues.activityL1?.type === "custom" || formValues.activityL2?.type === "custom") {
            CARBON_EVENTS.CUSTOM_CATEGORY_USED({ page: "ActivityForm" }).track();
        }
        if (formValues.tags && Object.keys(formValues.tags).length > 0) {
            CARBON_EVENTS.ACTIVITY_ADDED_WITH_TAGS({ page: "ActivityForm" }).track();
        }
        CARBON_EVENTS.ACTIVITY_DATA_ADDED(
            { page: "ActivityForm" },
            {
                scope: scopeType,
                activityL1: formValues.activityL1,
                activityL2: formValues.activityL2,
                unit: formValues.unitOfMeasurement,
                exclusionFromConnection: formValues.removeEmissionsFromSpendSource,
            }
        ).track();
    };

    return <AddActivityForm onSubmit={onSubmitFormData} />;
};
