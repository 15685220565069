import { findCategory } from "@/Router/DataInputRoute/FormV2";
import { emissionFactorsRoute } from "@/Router/EmissionFactors";
import { MissingSvg } from "@/lib/svg/missingSvg";
import { CARBON_EVENTS } from "@/lib/tracking";

import { Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

export const NoData: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack alignItems="center" gap={3} padding={4}>
            <Stack gap={2} alignItems="center">
                <MissingSvg />
                <Typography variant="textXl" textAlign="center">
                    <FormattedMessage defaultMessage="Add an activity to get started" description="No data message" />
                </Typography>
                <Typography variant="textMd" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Start adding activities to unlock opportunities and insights"
                        description="No data message subtext"
                    />
                </Typography>
            </Stack>
            <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => {
                    CARBON_EVENTS.ADD_ACTIVITY_DATA_CLICKED({
                        page: "NoData",
                        component: "AddActivityButton",
                    }).track();
                    navigate({ to: findCategory.fullPath });
                }}
            >
                <FormattedMessage defaultMessage="Add your first activity" description="Add activity button" />
            </Button>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={0.2}>
                <Typography variant="textMd" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Not sure where to start? {space}"
                        description="Get started message"
                        values={{ space: " \u00a0" }}
                    />
                </Typography>
                <Typography variant="textMd" textAlign="center">
                    <Link
                        style={{ color: "inherit" }}
                        to={emissionFactorsRoute.fullPath}
                        params={{ factorType: "activity" }}
                        onClick={() => {
                            CARBON_EVENTS.VIEW_EMISSION_FACTORS_CLICK({
                                page: "NoData",
                            }).track();
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="View emission factors"
                            description="View emission factors link"
                        />
                    </Link>
                </Typography>
            </Stack>
        </Stack>
    );
};
