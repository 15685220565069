import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { emissionIntensitiesStepLayout } from "../../routes";
import { SelectYear } from "./SelectYear";

const selectYearRoute = createRoute({
    getParentRoute: () => emissionIntensitiesStepLayout,
    path: "/select-year",
    validateSearch: z.object({
        name: z.string(),
        type: z.enum(["Revenue", "Employees", "Other"]),
        unit: z.string(),
    }),
    component: SelectYear,
});

export { selectYearRoute };
