import { defineMessages } from "react-intl";

const messages = defineMessages({
    spendColumn: {
        defaultMessage: "Spend column",
        description: "Spend column label",
    },
    naceColumn: {
        defaultMessage: "NACE column",
        description: "NACE column label",
    },
    currencyLabel: {
        defaultMessage: "Currency",
        description: "Currency label",
    },
    spendPeriodTitle: {
        defaultMessage: "Spend period",
        description: "Spend period title",
    },
    addPeriodButton: {
        defaultMessage: "Add period",
        description: "Add period button label",
    },
});
export default messages;
