import { useCurrency } from "@/Containers/useCurrency";
import { useFormatEmissionIntensityCurrency } from "@/Containers/useFormatEmissionIntensityCurrency";
import { useIntl } from "react-intl";

function useEmissionIntensityUnits(type?: "Revenue" | "Employees" | "Other"): { value: string; name: string }[] {
    const currency = useCurrency();
    const formatCurrency = useFormatEmissionIntensityCurrency();
    const { formatMessage } = useIntl();

    if (type === "Revenue") {
        const currencies: { code: string; modifier: "thousand" | "million" }[] = [
            {
                code: "USD",
                modifier: "million",
            },
            {
                code: "NOK",
                modifier: "thousand",
            },
            {
                code: "NOK",
                modifier: "million",
            },
            {
                code: "EUR",
                modifier: "thousand",
            },
        ];
        const includesPreferredCurrency = currencies.some(
            (curr) => curr.modifier === "million" && curr.code === currency
        );
        if (currency && !includesPreferredCurrency) {
            currencies.unshift({ code: currency, modifier: "million" });
        }

        return currencies.map(({ code, modifier }) => {
            const value = formatCurrency({ currency: code, modifier });
            return {
                name: value,
                value,
            };
        });
    }
    if (type === "Employees") {
        const units = [
            formatMessage({
                defaultMessage: "Full-time equivalents",
                description:
                    "Label for full-time equivalents when selecting a unit for an employees emission intensity",
            }),
            formatMessage({
                defaultMessage: "Head count",
                description: "Label for head count when selecting a unit for an employees emission intensity",
            }),
            formatMessage({
                defaultMessage: "Million working hours",
                description:
                    "Label for million working hours when selecting a unit for an employees emission intensity",
            }),
        ];
        return units.map((unit) => ({ value: unit, name: unit }));
    }
    return [];
}

export { useEmissionIntensityUnits };
