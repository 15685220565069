import { TagColumnPicker } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/TagsColumnPicker/SinglePicker";
import { createOptionsFromSourceForTagRoute, overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import { CARBON_EVENTS } from "@/lib/tracking";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { FilterField, UnifiedDataSource, filterField, unifiedDataSource } from "router";
import { ChangeName } from "../../Components/ChangeName";
import { PickDataSource } from "../../Components/ConnectionPicker";
import { ExamplesFromSource } from "../../ExamplesFromSource";

type ConnectionInput = {
    source: UnifiedDataSource;
    tagField: FilterField;
};

export const CreateOptionsFromSource = () => {
    const utils = trpc.useContext();

    const addTag = trpc.addEmissionTag.useMutation();
    const [editName, setEditName] = React.useState(false);
    const { name } = useSearch({ from: createOptionsFromSourceForTagRoute.id });
    const navigate = useNavigate();

    const editSource = trpc.editConnection.useMutation();
    const editSupplierSource = trpc.editSupplierConnection.useMutation();
    const { control, handleSubmit, watch } = useForm<ConnectionInput>({
        resolver: zodResolver(
            z.object({
                source: unifiedDataSource,
                tagField: filterField,
            })
        ),
        defaultValues: {},
    });
    const sourceValue = watch("source");
    const fieldValue = watch("tagField");

    const fields = trpc.filterFields.useQuery(
        sourceValue?.discriminator === "supplier"
            ? { type: "supplier" }
            : { type: "connection", connectionId: sourceValue?.id },
        { enabled: !!sourceValue }
    );

    const onSubmit = async () => {
        CARBON_EVENTS.ADDED_TAGS_FROM_SOURCE(
            { page: "DataPreferences", component: "Tags" },
            {
                sourceType: sourceValue.discriminator === "connection" ? sourceValue.type : "supplier",
            }
        ).track();

        const tagId = await addTag.mutateAsync({ tag: { name, options: [] } });
        if (sourceValue.discriminator === "connection") {
            await editSource.mutateAsync({
                ...sourceValue,
                tagColumns: { ...(sourceValue.tagColumns || {}), [tagId]: fieldValue },
            });
        } else {
            await editSupplierSource.mutateAsync({
                ...sourceValue,
                tagColumns: { ...(sourceValue.tagColumns || {}), [tagId]: fieldValue },
            });
        }

        utils.getEmissionTagsWithOptions.cancel();
        utils.getEmissionTagsWithOptions.refetch();
        navigate({ to: overviewTagRoute.fullPath });
    };
    const { formatMessage } = useIntl();
    const sourceName = sourceValue?.discriminator === "supplier" ? "Supplier" : (sourceValue?.name ?? "");
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Stack gap={2}>
                    <ChangeName
                        setEdit={setEditName}
                        edit={editName}
                        setName={(inputName) => navigate({ to: ".", search: (prev) => ({ ...prev, name: inputName }) })}
                        name={name}
                    />
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            defaultMessage="Fetch tags from data source"
                            description="Fetch tags from data source"
                        />
                    </Typography>
                    <Controller
                        name="source"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <PickDataSource
                                source={value}
                                onConnectionSelected={(connectionVal) => {
                                    onChange(connectionVal);
                                }}
                                disabled={editName}
                            />
                        )}
                    />
                    <Controller
                        name="tagField"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TagColumnPicker
                                disabled={!sourceValue || editName}
                                tagLabel={formatMessage(
                                    {
                                        defaultMessage: "{tagName} field from {connectionName}",
                                        description: "Tag name and connection name",
                                    },
                                    {
                                        tagName: name,
                                        connectionName: sourceName,
                                    }
                                )}
                                onChange={(changeValue) => {
                                    onChange(changeValue);
                                }}
                                value={value ?? null}
                                loading={fields.isLoading}
                                fields={fields?.data?.filterFields ?? []}
                                placeholder={formatMessage({
                                    defaultMessage: "My example field",
                                    description: "My example field",
                                })}
                            />
                        )}
                    />

                    <Stack gap={1}>
                        <Typography variant="subtitle2">
                            <FormattedMessage defaultMessage="Samples from field" description="Add tag options" />
                        </Typography>
                        {sourceValue && fieldValue ? (
                            <ExamplesFromSource source={sourceValue} field={fieldValue} />
                        ) : (
                            <Typography variant="textSm" color="gray">
                                <FormattedMessage
                                    defaultMessage="You need to select a data source and a field to see samples"
                                    description="Select a field to see examples"
                                />
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    onClick={() => {
                        CARBON_EVENTS.CREATED_NEW_SET_OF_TAGS(
                            { page: "DataPreferences", component: "Tags" },
                            {
                                customOrSource: "source",
                            }
                        ).track();
                    }}
                >
                    <FormattedMessage defaultMessage="Add tag" description="Create button for the Tags component" />
                </Button>
            </DialogActions>
        </form>
    );
};
