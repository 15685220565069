import FileUploadSmartComponent from "@/Components/FileUploadComponent";
import { Stack } from "@mui/material";

interface Props {
    id: string;
    viewOnly?: boolean;
}
export const ActivityDocuments: React.FC<Props> = ({ id, viewOnly }) => {
    return (
        <Stack paddingY={1}>
            <FileUploadSmartComponent referenceId={id} viewOnly={viewOnly} />
        </Stack>
    );
};
