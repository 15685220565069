import { dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { Dialog, DialogContent } from "@mui/material";
import { Outlet, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { EditDataSourceDetails } from "../EditDataSourceDetails";
import { ModalHeader } from "../ModalHeader";

type Props = {
    edit?: boolean;
};
const DataSourceModal: React.FC<Props> = ({ edit }) => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate({ to: dataPreferenceDataSourcesOverview.fullPath });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <ModalHeader />
            {edit && (
                <DialogContent>
                    <EditDataSourceDetails />
                </DialogContent>
            )}
            <DialogContent>
                <Outlet />
            </DialogContent>
        </Dialog>
    );
};

export const EditDataSourceModal: React.FC = () => {
    return <DataSourceModal edit />;
};

export const AddDataSourceModal: React.FC = () => {
    return <DataSourceModal />;
};
