import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { EditRoute } from "@/Containers/EditData";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { scopeCategoryTypes } from "router";
import { dataInputRoute } from "..";

export const editRoute = createRoute({
    getParentRoute: () => dataInputRoute,
    path: "/edit",
    component: EditRoute,
    validateSearch: z.object({
        activityId: z.string().optional(),
        category: z.enum(scopeCategoryTypes).optional(),
        activityL1: z.string().optional(),
        activityL2: z.string().optional(),
        year: z.number().optional(),
        activityIds: z.array(z.string()).optional(),
        supplierId: z.string().optional(),
        searchString: z
            .string()
            .optional()
            .catch((ctx) => `${ctx.input}`),
        scope: z.enum(["1", "2", "3"]).optional(),
        source: z.string().optional(),
    }),
    errorComponent: ErrorBoundary,
});
