import { defineMessages } from "react-intl";

const messages = defineMessages({
    butane: { defaultMessage: "Butane", description: "Activity level 2 butane" },
    cng: { defaultMessage: "CNG", description: "Activity level 2 cng" },
    lng: { defaultMessage: "LNG", description: "Activity level 2 lng" },
    lpg: { defaultMessage: "LPG", description: "Activity level 2 lpg" },
    naturalGas: { defaultMessage: "Natural gas", description: "Activity level 2 naturalGas" },
    naturalGas100MineralBlend: {
        defaultMessage: "Natural gas (100% mineral blend)",
        description: "Activity level 2 naturalGas100MineralBlend",
    },
    otherPetroleumGas: { defaultMessage: "Other petroleum gas", description: "Activity level 2 otherPetroleumGas" },
    propane: { defaultMessage: "Propane", description: "Activity level 2 propane" },
    aviationSpirit: { defaultMessage: "Aviation spirit", description: "Activity level 2 aviationSpirit" },
    burningOil: { defaultMessage: "Burning oil", description: "Activity level 2 burningOil" },
    dieselAverageBiofuelBlend: {
        defaultMessage: "Diesel (average biofuel blend)",
        description: "Activity level 2 dieselAverageBiofuelBlend",
    },
    diesel100MineralDiesel: {
        defaultMessage: "Diesel (100% mineral diesel)",
        description: "Activity level 2 diesel100MineralDiesel",
    },
    fuelOil: { defaultMessage: "Fuel oil", description: "Activity level 2 fuelOil" },
    gasOil: { defaultMessage: "Gas oil", description: "Activity level 2 gasOil" },
    lubricants: { defaultMessage: "Lubricants", description: "Activity level 2 lubricants" },
    naphtha: { defaultMessage: "Naphtha", description: "Activity level 2 naphtha" },
    petrolAverageBiofuelBlend: {
        defaultMessage: "Petrol (average biofuel blend)",
        description: "Activity level 2 petrolAverageBiofuelBlend",
    },
    petrol100MineralPetrol: {
        defaultMessage: "Petrol (100% mineral petrol)",
        description: "Activity level 2 petrol100MineralPetrol",
    },
    processedFuelOilsResidualOil: {
        defaultMessage: "Processed fuel oils - residual oil",
        description: "Activity level 2 processedFuelOilsResidualOil",
    },
    processedFuelOilsDistillateOil: {
        defaultMessage: "Processed fuel oils - distillate oil",
        description: "Activity level 2 processedFuelOilsDistillateOil",
    },
    refineryMiscellaneous: {
        defaultMessage: "Refinery miscellaneous",
        description: "Activity level 2 refineryMiscellaneous",
    },
    wasteOils: { defaultMessage: "Waste oils", description: "Activity level 2 wasteOils" },
    marineGasOil: { defaultMessage: "Marine gas oil", description: "Activity level 2 marineGasOil" },
    coalIndustrial: { defaultMessage: "Coal (industrial)", description: "Activity level 2 coalIndustrial" },
    coalElectricityGeneration: {
        defaultMessage: "Coal (electricity generation)",
        description: "Activity level 2 coalElectricityGeneration",
    },
    coalDomestic: { defaultMessage: "Coal (domestic)", description: "Activity level 2 coalDomestic" },
    cokingCoal: { defaultMessage: "Coking coal", description: "Activity level 2 cokingCoal" },
    petroleumCoke: { defaultMessage: "Petroleum coke", description: "Activity level 2 petroleumCoke" },
    coalElectricityGenerationHomeProducedCoalOnly: {
        defaultMessage: "Coal (electricity generation - home produced coal only)",
        description: "Activity level 2 coalElectricityGenerationHomeProducedCoalOnly",
    },
    bioethanol: { defaultMessage: "Bioethanol", description: "Activity level 2 bioethanol" },
    biodieselMe: { defaultMessage: "Biodiesel ME", description: "Activity level 2 biodieselMe" },
    biomethaneCompressed: {
        defaultMessage: "Biomethane (compressed)",
        description: "Activity level 2 biomethaneCompressed",
    },
    biodieselMeFromUsedCookingOil: {
        defaultMessage: "Biodiesel ME (from used cooking oil)",
        description: "Activity level 2 biodieselMeFromUsedCookingOil",
    },
    biodieselMeFromTallow: {
        defaultMessage: "Biodiesel ME (from tallow)",
        description: "Activity level 2 biodieselMeFromTallow",
    },
    biodieselHvo: { defaultMessage: "Biodiesel HVO", description: "Activity level 2 biodieselHvo" },
    biopropane: { defaultMessage: "Biopropane", description: "Activity level 2 biopropane" },
    bioPetrol: { defaultMessage: "Bio Petrol", description: "Activity level 2 bioPetrol" },
    renewablePetrol: { defaultMessage: "Renewable petrol", description: "Activity level 2 renewablePetrol" },
    offRoadBiodiesel: { defaultMessage: "Off road biodiesel", description: "Activity level 2 offRoadBiodiesel" },
    biomethaneLiquified: {
        defaultMessage: "Biomethane (liquified)",
        description: "Activity level 2 biomethaneLiquified",
    },
    methanolBio: { defaultMessage: "Methanol (bio)", description: "Activity level 2 methanolBio" },
    woodLogs: { defaultMessage: "Wood logs", description: "Activity level 2 woodLogs" },
    woodChips: { defaultMessage: "Wood chips", description: "Activity level 2 woodChips" },
    woodPellets: { defaultMessage: "Wood pellets", description: "Activity level 2 woodPellets" },
    grassstraw: { defaultMessage: "Grass/straw", description: "Activity level 2 grassstraw" },
    biogas: { defaultMessage: "Biogas", description: "Activity level 2 biogas" },
    landfillGas: { defaultMessage: "Landfill gas", description: "Activity level 2 landfillGas" },
    petrol: { defaultMessage: "Petrol", description: "Activity level 2 petrol" },
    diesel: { defaultMessage: "Diesel", description: "Activity level 2 diesel" },
    hybrid: { defaultMessage: "Hybrid", description: "Activity level 2 hybrid" },
    pluginHybrid: { defaultMessage: "Plug-in hybrid", description: "Activity level 2 pluginHybrid" },
    unknown: { defaultMessage: "Unknown", description: "Activity level 2 unknown" },
    "0Laden": { defaultMessage: "0% Laden", description: "Activity level 2 0Laden" },
    "50Laden": { defaultMessage: "50% Laden", description: "Activity level 2 50Laden" },
    "100Laden": { defaultMessage: "100% Laden", description: "Activity level 2 100Laden" },
    averageLaden: { defaultMessage: "Average Laden", description: "Activity level 2 averageLaden" },
    domesticAveragePassenger: {
        defaultMessage: "Domestic (Average passenger)",
        description: "Label for Activity L2 domesticAveragePassenger",
    },
    longhaulInternational3700kmAveragePassenger: {
        defaultMessage: "Long-haul (international >3700km) (Average passenger)",
        description: "Label for Activity L2 longhaulInternational3700kmAveragePassenger",
    },
    longhaulInternational3700kmBusinessClass: {
        defaultMessage: "Long-haul (international >3700km) (Business class)",
        description: "Label for Activity L2 longhaulInternational3700kmBusinessClass",
    },
    longhaulInternational3700kmEconomyClass: {
        defaultMessage: "Long-haul (international >3700km) (Economy class)",
        description: "Label for Activity L2 longhaulInternational3700kmEconomyClass",
    },
    longhaulInternational3700kmFirstClass: {
        defaultMessage: "Long-haul (international >3700km) (First class)",
        description: "Label for Activity L2 longhaulInternational3700kmFirstClass",
    },
    longhaulInternational3700kmPremiumEconomyClass: {
        defaultMessage: "Long-haul (international >3700km) (Premium economy class)",
        description: "Label for Activity L2 longhaulInternational3700kmPremiumEconomyClass",
    },
    shorthaulInternational3700kmAveragePassenger: {
        defaultMessage: "Short-haul (international <3700km) (Average passenger)",
        description: "Label for Activity L2 shorthaulInternational3700kmAveragePassenger",
    },
    shorthaulInternational3700kmBusinessClass: {
        defaultMessage: "Short-haul (international <3700km) (Business class)",
        description: "Label for Activity L2 shorthaulInternational3700kmBusinessClass",
    },
    shorthaulInternational3700kmEconomyClass: {
        defaultMessage: "Short-haul (international <3700km) (Economy class)",
        description: "Label for Activity L2 shorthaulInternational3700kmEconomyClass",
    },
    busCoach: {
        defaultMessage: "Bus (coach)",
        description: "Label for Activity L2 busCoach",
    },
    busLocal: {
        defaultMessage: "Bus (local)",
        description: "Label for Activity L2 busLocal",
    },
    carAverageElectric: {
        defaultMessage: "Car (average, electric)",
        description: "Label for Activity L2 carAverageElectric",
    },
    carAverageIc: {
        defaultMessage: "Car (average, IC)",
        description: "Label for Activity L2 carAverageIc",
    },
    carLargeElectric: {
        defaultMessage: "Car (large, electric)",
        description: "Label for Activity L2 carLargeElectric",
    },
    carLargeIc: {
        defaultMessage: "Car (large, IC)",
        description: "Label for Activity L2 carLargeIc",
    },
    carMediumElectric: {
        defaultMessage: "Car (medium, electric)",
        description: "Label for Activity L2 carMediumElectric",
    },
    carMediumIc: {
        defaultMessage: "Car (medium, IC)",
        description: "Label for Activity L2 carMediumIc",
    },
    carSmallElectric: {
        defaultMessage: "Car (small, electric)",
        description: "Label for Activity L2 carSmallElectric",
    },
    carSmallIc: {
        defaultMessage: "Car (small, IC)",
        description: "Label for Activity L2 carSmallIc",
    },
    metroAndTram: {
        defaultMessage: "Metro and tram",
        description: "Label for Activity L2 metroAndTram",
    },
    motorbikeAverage: {
        defaultMessage: "Motorbike (average)",
        description: "Label for Activity L2 motorbikeAverage",
    },
    motorbikeLarge: {
        defaultMessage: "Motorbike (large)",
        description: "Label for Activity L2 motorbikeLarge",
    },
    motorbikeMedium: {
        defaultMessage: "Motorbike (medium)",
        description: "Label for Activity L2 motorbikeMedium",
    },
    motorbikeSmall: {
        defaultMessage: "Motorbike (small)",
        description: "Label for Activity L2 motorbikeSmall",
    },
    railInternational: {
        defaultMessage: "Rail (international)",
        description: "Label for Activity L2 railInternational",
    },
    railNational: {
        defaultMessage: "Rail (national)",
        description: "Label for Activity L2 railNational",
    },
    taxiLarge: {
        defaultMessage: "Taxi (large)",
        description: "Label for Activity L2 taxiLarge",
    },
    taxiRegular: {
        defaultMessage: "Taxi (regular)",
        description: "Label for Activity L2 taxiRegular",
    },
    ferryAveragePassenger: {
        defaultMessage: "Ferry (average passenger)",
        description: "Label for Activity L2 ferryAveragePassenger",
    },
    ferryCarPassenger: {
        defaultMessage: "Ferry (car passenger)",
        description: "Label for Activity L2 ferryCarPassenger",
    },
    ferryFootPassenger: {
        defaultMessage: "Ferry (foot passenger)",
        description: "Label for Activity L2 ferryFootPassenger",
    },
    None: {
        defaultMessage: "nan",
        description: "Label for Activity L2 None",
    },
    aggregatesClosedloopSource: {
        defaultMessage: "Aggregates (Closed-loop source)",
        description: "Label for Activity L2 aggregatesClosedloopSource",
    },
    aggregatesPrimaryMaterialProduction: {
        defaultMessage: "Aggregates (Primary material production)",
        description: "Label for Activity L2 aggregatesPrimaryMaterialProduction",
    },
    aggregatesReused: {
        defaultMessage: "Aggregates (Re-used)",
        description: "Label for Activity L2 aggregatesReused",
    },
    asbestosPrimaryMaterialProduction: {
        defaultMessage: "Asbestos (Primary material production)",
        description: "Label for Activity L2 asbestosPrimaryMaterialProduction",
    },
    asphaltClosedloopSource: {
        defaultMessage: "Asphalt (Closed-loop source)",
        description: "Label for Activity L2 asphaltClosedloopSource",
    },
    asphaltPrimaryMaterialProduction: {
        defaultMessage: "Asphalt (Primary material production)",
        description: "Label for Activity L2 asphaltPrimaryMaterialProduction",
    },
    asphaltReused: {
        defaultMessage: "Asphalt (Re-used)",
        description: "Label for Activity L2 asphaltReused",
    },
    averageConstructionPrimaryMaterialProduction: {
        defaultMessage: "Average construction (Primary material production)",
        description: "Label for Activity L2 averageConstructionPrimaryMaterialProduction",
    },
    bricksPrimaryMaterialProduction: {
        defaultMessage: "Bricks (Primary material production)",
        description: "Label for Activity L2 bricksPrimaryMaterialProduction",
    },
    concreteClosedloopSource: {
        defaultMessage: "Concrete (Closed-loop source)",
        description: "Label for Activity L2 concreteClosedloopSource",
    },
    concretePrimaryMaterialProduction: {
        defaultMessage: "Concrete (Primary material production)",
        description: "Label for Activity L2 concretePrimaryMaterialProduction",
    },
    insulationClosedloopSource: {
        defaultMessage: "Insulation (Closed-loop source)",
        description: "Label for Activity L2 insulationClosedloopSource",
    },
    insulationPrimaryMaterialProduction: {
        defaultMessage: "Insulation (Primary material production)",
        description: "Label for Activity L2 insulationPrimaryMaterialProduction",
    },
    plasterboardClosedloopSource: {
        defaultMessage: "Plasterboard (Closed-loop source)",
        description: "Label for Activity L2 plasterboardClosedloopSource",
    },
    plasterboardPrimaryMaterialProduction: {
        defaultMessage: "Plasterboard (Primary material production)",
        description: "Label for Activity L2 plasterboardPrimaryMaterialProduction",
    },
    woodClosedloopSource: {
        defaultMessage: "Wood (Closed-loop source)",
        description: "Label for Activity L2 woodClosedloopSource",
    },
    woodPrimaryMaterialProduction: {
        defaultMessage: "Wood (Primary material production)",
        description: "Label for Activity L2 woodPrimaryMaterialProduction",
    },
    woodReused: {
        defaultMessage: "Wood (Re-used)",
        description: "Label for Activity L2 woodReused",
    },
    clothingPrimaryMaterialProduction: {
        defaultMessage: "Clothing (Primary material production)",
        description: "Label for Activity L2 clothingPrimaryMaterialProduction",
    },
    clothingReused: {
        defaultMessage: "Clothing (Re-used)",
        description: "Label for Activity L2 clothingReused",
    },
    batteriesAlkalinePrimaryMaterialProduction: {
        defaultMessage: "Batteries - Alkaline (Primary material production)",
        description: "Label for Activity L2 batteriesAlkalinePrimaryMaterialProduction",
    },
    batteriesLiIonPrimaryMaterialProduction: {
        defaultMessage: "Batteries - Li ion (Primary material production)",
        description: "Label for Activity L2 batteriesLiIonPrimaryMaterialProduction",
    },
    batteriesNimhPrimaryMaterialProduction: {
        defaultMessage: "Batteries - NiMh (Primary material production)",
        description: "Label for Activity L2 batteriesNimhPrimaryMaterialProduction",
    },
    fridgesAndFreezersPrimaryMaterialProduction: {
        defaultMessage: "Fridges and freezers (Primary material production)",
        description: "Label for Activity L2 fridgesAndFreezersPrimaryMaterialProduction",
    },
    itPrimaryMaterialProduction: {
        defaultMessage: "IT (Primary material production)",
        description: "Label for Activity L2 itPrimaryMaterialProduction",
    },
    largeElectricalItemsPrimaryMaterialProduction: {
        defaultMessage: "Large electrical items (Primary material production)",
        description: "Label for Activity L2 largeElectricalItemsPrimaryMaterialProduction",
    },
    smallElectricalItemsPrimaryMaterialProduction: {
        defaultMessage: "Small electrical items (Primary material production)",
        description: "Label for Activity L2 smallElectricalItemsPrimaryMaterialProduction",
    },
    "aluminiumCansAndFoilExcl.FormingClosedloopSource": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Closed-loop source)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingClosedloopSource",
    },
    "aluminiumCansAndFoilExcl.FormingPrimaryMaterialProduction": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Primary material production)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingPrimaryMaterialProduction",
    },
    mixedCansClosedloopSource: {
        defaultMessage: "Mixed cans (Closed-loop source)",
        description: "Label for Activity L2 mixedCansClosedloopSource",
    },
    mixedCansPrimaryMaterialProduction: {
        defaultMessage: "Mixed cans (Primary material production)",
        description: "Label for Activity L2 mixedCansPrimaryMaterialProduction",
    },
    steelCansClosedloopSource: {
        defaultMessage: "Steel cans (Closed-loop source)",
        description: "Label for Activity L2 steelCansClosedloopSource",
    },
    steelCansPrimaryMaterialProduction: {
        defaultMessage: "Steel cans (Primary material production)",
        description: "Label for Activity L2 steelCansPrimaryMaterialProduction",
    },
    foodAndDrinkPrimaryMaterialProduction: {
        defaultMessage: "Food and drink (Primary material production)",
        description: "Label for Activity L2 foodAndDrinkPrimaryMaterialProduction",
    },
    glassClosedloopSource: {
        defaultMessage: "Glass (Closed-loop source)",
        description: "Label for Activity L2 glassClosedloopSource",
    },
    glassPrimaryMaterialProduction: {
        defaultMessage: "Glass (Primary material production)",
        description: "Label for Activity L2 glassPrimaryMaterialProduction",
    },
    metalsClosedloopSource: {
        defaultMessage: "Metals (Closed-loop source)",
        description: "Label for Activity L2 metalsClosedloopSource",
    },
    metalsPrimaryMaterialProduction: {
        defaultMessage: "Metals (Primary material production)",
        description: "Label for Activity L2 metalsPrimaryMaterialProduction",
    },
    scrapMetalClosedloopSource: {
        defaultMessage: "Scrap metal (Closed-loop source)",
        description: "Label for Activity L2 scrapMetalClosedloopSource",
    },
    scrapMetalPrimaryMaterialProduction: {
        defaultMessage: "Scrap metal (Primary material production)",
        description: "Label for Activity L2 scrapMetalPrimaryMaterialProduction",
    },
    compostDerivedFromFoodAndGardenWastePrimaryMaterialProduction: {
        defaultMessage: "Compost derived from food and garden waste (Primary material production)",
        description: "Label for Activity L2 compostDerivedFromFoodAndGardenWastePrimaryMaterialProduction",
    },
    compostDerivedFromGardenWastePrimaryMaterialProduction: {
        defaultMessage: "Compost derived from garden waste (Primary material production)",
        description: "Label for Activity L2 compostDerivedFromGardenWastePrimaryMaterialProduction",
    },
    soilsClosedloopSource: {
        defaultMessage: "Soils (Closed-loop source)",
        description: "Label for Activity L2 soilsClosedloopSource",
    },
    cardboardClosedloopSource: {
        defaultMessage: "Cardboard (Closed-loop source)",
        description: "Label for Activity L2 cardboardClosedloopSource",
    },
    cardboardPrimaryMaterialProduction: {
        defaultMessage: "Cardboard (Primary material production)",
        description: "Label for Activity L2 cardboardPrimaryMaterialProduction",
    },
    mixedClosedloopSource: {
        defaultMessage: "Mixed (Closed-loop source)",
        description: "Label for Activity L2 mixedClosedloopSource",
    },
    mixedPrimaryMaterialProduction: {
        defaultMessage: "Mixed (Primary material production)",
        description: "Label for Activity L2 mixedPrimaryMaterialProduction",
    },
    paperClosedloopSource: {
        defaultMessage: "Paper (Closed-loop source)",
        description: "Label for Activity L2 paperClosedloopSource",
    },
    paperPrimaryMaterialProduction: {
        defaultMessage: "Paper (Primary material production)",
        description: "Label for Activity L2 paperPrimaryMaterialProduction",
    },
    averagePlasticFilmClosedloopSource: {
        defaultMessage: "Average plastic film (Closed-loop source)",
        description: "Label for Activity L2 averagePlasticFilmClosedloopSource",
    },
    averagePlasticFilmPrimaryMaterialProduction: {
        defaultMessage: "Average plastic film (Primary material production)",
        description: "Label for Activity L2 averagePlasticFilmPrimaryMaterialProduction",
    },
    averagePlasticRigidClosedloopSource: {
        defaultMessage: "Average plastic rigid (Closed-loop source)",
        description: "Label for Activity L2 averagePlasticRigidClosedloopSource",
    },
    averagePlasticRigidPrimaryMaterialProduction: {
        defaultMessage: "Average plastic rigid (Primary material production)",
        description: "Label for Activity L2 averagePlasticRigidPrimaryMaterialProduction",
    },
    averagePlasticsClosedloopSource: {
        defaultMessage: "Average plastics (Closed-loop source)",
        description: "Label for Activity L2 averagePlasticsClosedloopSource",
    },
    averagePlasticsPrimaryMaterialProduction: {
        defaultMessage: "Average plastics (Primary material production)",
        description: "Label for Activity L2 averagePlasticsPrimaryMaterialProduction",
    },
    "hdpeIncl.FormingClosedloopSource": {
        defaultMessage: "HDPE (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 hdpeIncl.FormingClosedloopSource",
    },
    "hdpeIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "HDPE (incl. forming) (Primary material production)",
        description: "Label for Activity L2 hdpeIncl.FormingPrimaryMaterialProduction",
    },
    "ldpeAndLldpeIncl.FormingClosedloopSource": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingClosedloopSource",
    },
    "ldpeAndLldpeIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Primary material production)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingPrimaryMaterialProduction",
    },
    mineralOilClosedloopSource: {
        defaultMessage: "Mineral oil (Closed-loop source)",
        description: "Label for Activity L2 mineralOilClosedloopSource",
    },
    mineralOilPrimaryMaterialProduction: {
        defaultMessage: "Mineral oil (Primary material production)",
        description: "Label for Activity L2 mineralOilPrimaryMaterialProduction",
    },
    "petIncl.FormingClosedloopSource": {
        defaultMessage: "PET (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 petIncl.FormingClosedloopSource",
    },
    "petIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "PET (incl. forming) (Primary material production)",
        description: "Label for Activity L2 petIncl.FormingPrimaryMaterialProduction",
    },
    "ppIncl.FormingClosedloopSource": {
        defaultMessage: "PP (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 ppIncl.FormingClosedloopSource",
    },
    "ppIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "PP (incl. forming) (Primary material production)",
        description: "Label for Activity L2 ppIncl.FormingPrimaryMaterialProduction",
    },
    "psIncl.FormingClosedloopSource": {
        defaultMessage: "PS (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 psIncl.FormingClosedloopSource",
    },
    "psIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "PS (incl. forming) (Primary material production)",
        description: "Label for Activity L2 psIncl.FormingPrimaryMaterialProduction",
    },
    "pvcIncl.FormingClosedloopSource": {
        defaultMessage: "PVC (incl. forming) (Closed-loop source)",
        description: "Label for Activity L2 pvcIncl.FormingClosedloopSource",
    },
    "pvcIncl.FormingPrimaryMaterialProduction": {
        defaultMessage: "PVC (incl. forming) (Primary material production)",
        description: "Label for Activity L2 pvcIncl.FormingPrimaryMaterialProduction",
    },
    tyresPrimaryMaterialProduction: {
        defaultMessage: "Tyres (Primary material production)",
        description: "Label for Activity L2 tyresPrimaryMaterialProduction",
    },
    tyresReused: {
        defaultMessage: "Tyres (Re-used)",
        description: "Label for Activity L2 tyresReused",
    },
    waterSupply: {
        defaultMessage: "Water supply",
        description: "Label for Activity L2 waterSupply",
    },
    bulkCarrier09999Dwt: {
        defaultMessage: "Bulk carrier (0–9999 dwt)",
        description: "Label for Activity L2 bulkCarrier09999Dwt",
    },
    bulkCarrier1000034999Dwt: {
        defaultMessage: "Bulk carrier (10,000–34,999 dwt)",
        description: "Label for Activity L2 bulkCarrier1000034999Dwt",
    },
    bulkCarrier100000199999Dwt: {
        defaultMessage: "Bulk carrier (100,000–199,999 dwt)",
        description: "Label for Activity L2 bulkCarrier100000199999Dwt",
    },
    bulkCarrier200000Dwt: {
        defaultMessage: "Bulk carrier (200,000+ dwt)",
        description: "Label for Activity L2 bulkCarrier200000Dwt",
    },
    bulkCarrier3500059999Dwt: {
        defaultMessage: "Bulk carrier (35,000–59,999 dwt)",
        description: "Label for Activity L2 bulkCarrier3500059999Dwt",
    },
    bulkCarrier6000099999Dwt: {
        defaultMessage: "Bulk carrier (60,000–99,999 dwt)",
        description: "Label for Activity L2 bulkCarrier6000099999Dwt",
    },
    bulkCarrierAverage: {
        defaultMessage: "Bulk carrier (Average)",
        description: "Label for Activity L2 bulkCarrierAverage",
    },
    containerShip0999Teu: {
        defaultMessage: "Container ship (0–999 TEU)",
        description: "Label for Activity L2 containerShip0999Teu",
    },
    containerShip10001999Teu: {
        defaultMessage: "Container ship (1000–1999 TEU)",
        description: "Label for Activity L2 containerShip10001999Teu",
    },
    containerShip20002999Teu: {
        defaultMessage: "Container ship (2000–2999 TEU)",
        description: "Label for Activity L2 containerShip20002999Teu",
    },
    containerShip30004999Teu: {
        defaultMessage: "Container ship (3000–4999 TEU)",
        description: "Label for Activity L2 containerShip30004999Teu",
    },
    containerShip50007999Teu: {
        defaultMessage: "Container ship (5000–7999 TEU)",
        description: "Label for Activity L2 containerShip50007999Teu",
    },
    containerShip8000Teu: {
        defaultMessage: "Container ship (8000+ TEU)",
        description: "Label for Activity L2 containerShip8000Teu",
    },
    containerShipAverage: {
        defaultMessage: "Container ship (Average)",
        description: "Label for Activity L2 containerShipAverage",
    },
    generalCargo04999Dwt100Teu: {
        defaultMessage: "General cargo (0–4999 dwt 100+ TEU)",
        description: "Label for Activity L2 generalCargo04999Dwt100Teu",
    },
    generalCargo04999Dwt: {
        defaultMessage: "General cargo (0–4999 dwt)",
        description: "Label for Activity L2 generalCargo04999Dwt",
    },
    generalCargo10000Dwt100Teu: {
        defaultMessage: "General cargo (10,000+ dwt 100+ TEU)",
        description: "Label for Activity L2 generalCargo10000Dwt100Teu",
    },
    generalCargo10000Dwt: {
        defaultMessage: "General cargo (10,000+ dwt)",
        description: "Label for Activity L2 generalCargo10000Dwt",
    },
    generalCargo50009999Dwt100Teu: {
        defaultMessage: "General cargo (5000–9999 dwt 100+ TEU)",
        description: "Label for Activity L2 generalCargo50009999Dwt100Teu",
    },
    generalCargo50009999Dwt: {
        defaultMessage: "General cargo (5000–9999 dwt)",
        description: "Label for Activity L2 generalCargo50009999Dwt",
    },
    generalCargoAverage: {
        defaultMessage: "General cargo (Average)",
        description: "Label for Activity L2 generalCargoAverage",
    },
    largeRopaxFerryAverage: {
        defaultMessage: "Large RoPax ferry (Average)",
        description: "Label for Activity L2 largeRopaxFerryAverage",
    },
    refrigeratedCargoAllDwt: {
        defaultMessage: "Refrigerated cargo (All dwt)",
        description: "Label for Activity L2 refrigeratedCargoAllDwt",
    },
    roroferry01999Lm: {
        defaultMessage: "RoRo-Ferry (0–1999 LM)",
        description: "Label for Activity L2 roroferry01999Lm",
    },
    roroferry2000Lm: {
        defaultMessage: "RoRo-Ferry (2000+ LM)",
        description: "Label for Activity L2 roroferry2000Lm",
    },
    roroferryAverage: {
        defaultMessage: "RoRo-Ferry (Average)",
        description: "Label for Activity L2 roroferryAverage",
    },
    vehicleTransport03999Ceu: {
        defaultMessage: "Vehicle transport (0–3999 CEU)",
        description: "Label for Activity L2 vehicleTransport03999Ceu",
    },
    vehicleTransport4000Ceu: {
        defaultMessage: "Vehicle transport (4000+ CEU)",
        description: "Label for Activity L2 vehicleTransport4000Ceu",
    },
    vehicleTransportAverage: {
        defaultMessage: "Vehicle transport (Average)",
        description: "Label for Activity L2 vehicleTransportAverage",
    },
    domestic: {
        defaultMessage: "Domestic",
        description: "Label for Activity L2 domestic",
    },
    longhaulInternational3700km: {
        defaultMessage: "Long-haul (international >3700km)",
        description: "Label for Activity L2 longhaulInternational3700km",
    },
    shorthaulInternational3700km: {
        defaultMessage: "Short-haul (international <3700km)",
        description: "Label for Activity L2 shorthaulInternational3700km",
    },
    "articulated3.533Tonnes0Laden": {
        defaultMessage: "Articulated (>3.5 - 33 tonnes) (0% Laden)",
        description: "Label for Activity L2 articulated3.533Tonnes0Laden",
    },
    "articulated3.533Tonnes100Laden": {
        defaultMessage: "Articulated (>3.5 - 33 tonnes) (100% Laden)",
        description: "Label for Activity L2 articulated3.533Tonnes100Laden",
    },
    "articulated3.533Tonnes50Laden": {
        defaultMessage: "Articulated (>3.5 - 33 tonnes) (50% Laden)",
        description: "Label for Activity L2 articulated3.533Tonnes50Laden",
    },
    "articulated3.533TonnesAverageLaden": {
        defaultMessage: "Articulated (>3.5 - 33 tonnes) (Average laden)",
        description: "Label for Activity L2 articulated3.533TonnesAverageLaden",
    },
    articulated33Tonnes0Laden: {
        defaultMessage: "Articulated (>33 tonnes) (0% Laden)",
        description: "Label for Activity L2 articulated33Tonnes0Laden",
    },
    articulated33Tonnes100Laden: {
        defaultMessage: "Articulated (>33 tonnes) (100% Laden)",
        description: "Label for Activity L2 articulated33Tonnes100Laden",
    },
    articulated33Tonnes50Laden: {
        defaultMessage: "Articulated (>33 tonnes) (50% Laden)",
        description: "Label for Activity L2 articulated33Tonnes50Laden",
    },
    articulated33TonnesAverageLaden: {
        defaultMessage: "Articulated (>33 tonnes) (Average laden)",
        description: "Label for Activity L2 articulated33TonnesAverageLaden",
    },
    averageHgv0Laden: {
        defaultMessage: "Average HGV (0% Laden)",
        description: "Label for Activity L2 averageHgv0Laden",
    },
    averageHgv100Laden: {
        defaultMessage: "Average HGV (100% Laden)",
        description: "Label for Activity L2 averageHgv100Laden",
    },
    averageHgv50Laden: {
        defaultMessage: "Average HGV (50% Laden)",
        description: "Label for Activity L2 averageHgv50Laden",
    },
    averageHgvAverageLaden: {
        defaultMessage: "Average HGV (Average laden)",
        description: "Label for Activity L2 averageHgvAverageLaden",
    },
    rigid17Tonnes0Laden: {
        defaultMessage: "Rigid (>17 tonnes) (0% Laden)",
        description: "Label for Activity L2 rigid17Tonnes0Laden",
    },
    rigid17Tonnes100Laden: {
        defaultMessage: "Rigid (>17 tonnes) (100% Laden)",
        description: "Label for Activity L2 rigid17Tonnes100Laden",
    },
    rigid17Tonnes50Laden: {
        defaultMessage: "Rigid (>17 tonnes) (50% Laden)",
        description: "Label for Activity L2 rigid17Tonnes50Laden",
    },
    rigid17TonnesAverageLaden: {
        defaultMessage: "Rigid (>17 tonnes) (Average laden)",
        description: "Label for Activity L2 rigid17TonnesAverageLaden",
    },
    "rigid3.57.5Tonnes0Laden": {
        defaultMessage: "Rigid (>3.5 - 7.5 tonnes) (0% Laden)",
        description: "Label for Activity L2 rigid3.57.5Tonnes0Laden",
    },
    "rigid3.57.5Tonnes100Laden": {
        defaultMessage: "Rigid (>3.5 - 7.5 tonnes) (100% Laden)",
        description: "Label for Activity L2 rigid3.57.5Tonnes100Laden",
    },
    "rigid3.57.5Tonnes50Laden": {
        defaultMessage: "Rigid (>3.5 - 7.5 tonnes) (50% Laden)",
        description: "Label for Activity L2 rigid3.57.5Tonnes50Laden",
    },
    "rigid3.57.5TonnesAverageLaden": {
        defaultMessage: "Rigid (>3.5 - 7.5 tonnes) (Average laden)",
        description: "Label for Activity L2 rigid3.57.5TonnesAverageLaden",
    },
    "rigid7.517Tonnes0Laden": {
        defaultMessage: "Rigid (>7.5 - 17 tonnes) (0% Laden)",
        description: "Label for Activity L2 rigid7.517Tonnes0Laden",
    },
    "rigid7.517Tonnes100Laden": {
        defaultMessage: "Rigid (>7.5 - 17 tonnes) (100% Laden)",
        description: "Label for Activity L2 rigid7.517Tonnes100Laden",
    },
    "rigid7.517Tonnes50Laden": {
        defaultMessage: "Rigid (>7.5 - 17 tonnes) (50% Laden)",
        description: "Label for Activity L2 rigid7.517Tonnes50Laden",
    },
    "rigid7.517TonnesAverageLaden": {
        defaultMessage: "Rigid (>7.5 - 17 tonnes) (Average laden)",
        description: "Label for Activity L2 rigid7.517TonnesAverageLaden",
    },
    chemicalTanker04999Dwt: {
        defaultMessage: "Chemical tanker  (0–4999 dwt)",
        description: "Label for Activity L2 chemicalTanker04999Dwt",
    },
    chemicalTanker1000019999Dwt: {
        defaultMessage: "Chemical tanker  (10,000–19,999 dwt)",
        description: "Label for Activity L2 chemicalTanker1000019999Dwt",
    },
    chemicalTanker20000Dwt: {
        defaultMessage: "Chemical tanker  (20,000+ dwt)",
        description: "Label for Activity L2 chemicalTanker20000Dwt",
    },
    chemicalTanker50009999Dwt: {
        defaultMessage: "Chemical tanker  (5000–9999 dwt)",
        description: "Label for Activity L2 chemicalTanker50009999Dwt",
    },
    chemicalTankerAverage: {
        defaultMessage: "Chemical tanker  (Average)",
        description: "Label for Activity L2 chemicalTankerAverage",
    },
    crudeTanker09999Dwt: {
        defaultMessage: "Crude tanker (0–9999 dwt)",
        description: "Label for Activity L2 crudeTanker09999Dwt",
    },
    crudeTanker1000059999Dwt: {
        defaultMessage: "Crude tanker (10,000–59,999 dwt)",
        description: "Label for Activity L2 crudeTanker1000059999Dwt",
    },
    crudeTanker120000199999Dwt: {
        defaultMessage: "Crude tanker (120,000–199,999 dwt)",
        description: "Label for Activity L2 crudeTanker120000199999Dwt",
    },
    crudeTanker200000Dwt: {
        defaultMessage: "Crude tanker (200,000+ dwt)",
        description: "Label for Activity L2 crudeTanker200000Dwt",
    },
    crudeTanker6000079999Dwt: {
        defaultMessage: "Crude tanker (60,000–79,999 dwt)",
        description: "Label for Activity L2 crudeTanker6000079999Dwt",
    },
    crudeTanker80000119999Dwt: {
        defaultMessage: "Crude tanker (80,000–119,999 dwt)",
        description: "Label for Activity L2 crudeTanker80000119999Dwt",
    },
    crudeTankerAverage: {
        defaultMessage: "Crude tanker (Average)",
        description: "Label for Activity L2 crudeTankerAverage",
    },
    lngTanker0199999M3: {
        defaultMessage: "LNG tanker (0–199,999 m3)",
        description: "Label for Activity L2 lngTanker0199999M3",
    },
    lngTanker200000M3: {
        defaultMessage: "LNG tanker (200,000+ m3)",
        description: "Label for Activity L2 lngTanker200000M3",
    },
    lngTankerAverage: {
        defaultMessage: "LNG tanker (Average)",
        description: "Label for Activity L2 lngTankerAverage",
    },
    lpgTanker049999M3: {
        defaultMessage: "LPG Tanker (0–49,999 m3)",
        description: "Label for Activity L2 lpgTanker049999M3",
    },
    lpgTanker50000M3: {
        defaultMessage: "LPG Tanker (50,000+ m3)",
        description: "Label for Activity L2 lpgTanker50000M3",
    },
    lpgTankerAverage: {
        defaultMessage: "LPG Tanker (Average)",
        description: "Label for Activity L2 lpgTankerAverage",
    },
    productsTanker04999Dwt: {
        defaultMessage: "Products tanker  (0–4999 dwt)",
        description: "Label for Activity L2 productsTanker04999Dwt",
    },
    productsTanker1000019999Dwt: {
        defaultMessage: "Products tanker  (10,000–19,999 dwt)",
        description: "Label for Activity L2 productsTanker1000019999Dwt",
    },
    productsTanker2000059999Dwt: {
        defaultMessage: "Products tanker  (20,000–59,999 dwt)",
        description: "Label for Activity L2 productsTanker2000059999Dwt",
    },
    productsTanker50009999Dwt: {
        defaultMessage: "Products tanker  (5000–9999 dwt)",
        description: "Label for Activity L2 productsTanker50009999Dwt",
    },
    productsTanker60000Dwt: {
        defaultMessage: "Products tanker  (60,000+ dwt)",
        description: "Label for Activity L2 productsTanker60000Dwt",
    },
    productsTankerAverage: {
        defaultMessage: "Products tanker  (Average)",
        description: "Label for Activity L2 productsTankerAverage",
    },
    "1.305To1.74TonnesDiesel": {
        defaultMessage: "1.305 to 1.74 tonnes (Diesel)",
        description: "Label for Activity L2 1.305To1.74TonnesDiesel",
    },
    "1.305To1.74TonnesElectric": {
        defaultMessage: "1.305 to 1.74 tonnes (Electric)",
        description: "Label for Activity L2 1.305To1.74TonnesElectric",
    },
    "1.305To1.74TonnesPetrol": {
        defaultMessage: "1.305 to 1.74 tonnes (Petrol)",
        description: "Label for Activity L2 1.305To1.74TonnesPetrol",
    },
    "1.74To3.5TonnesDiesel": {
        defaultMessage: "1.74 to 3.5 tonnes (Diesel)",
        description: "Label for Activity L2 1.74To3.5TonnesDiesel",
    },
    "1.74To3.5TonnesElectric": {
        defaultMessage: "1.74 to 3.5 tonnes (Electric)",
        description: "Label for Activity L2 1.74To3.5TonnesElectric",
    },
    "1.74To3.5TonnesPetrol": {
        defaultMessage: "1.74 to 3.5 tonnes (Petrol)",
        description: "Label for Activity L2 1.74To3.5TonnesPetrol",
    },
    averageSizeDiesel: {
        defaultMessage: "Average size (Diesel)",
        description: "Label for Activity L2 averageSizeDiesel",
    },
    averageSizeElectric: {
        defaultMessage: "Average size (Electric)",
        description: "Label for Activity L2 averageSizeElectric",
    },
    averageSizePetrol: {
        defaultMessage: "Average size (Petrol)",
        description: "Label for Activity L2 averageSizePetrol",
    },
    "upTo1.305TonnesDiesel": {
        defaultMessage: "up to 1.305 tonnes (Diesel)",
        description: "Label for Activity L2 upTo1.305TonnesDiesel",
    },
    "upTo1.305TonnesElectric": {
        defaultMessage: "up to 1.305 tonnes (Electric)",
        description: "Label for Activity L2 upTo1.305TonnesElectric",
    },
    "upTo1.305TonnesPetrol": {
        defaultMessage: "up to 1.305 tonnes (Petrol)",
        description: "Label for Activity L2 upTo1.305TonnesPetrol",
    },
    aggregatesClosedloop: {
        defaultMessage: "Aggregates (Closed-loop)",
        description: "Label for Activity L2 aggregatesClosedloop",
    },
    aggregatesLandfill: {
        defaultMessage: "Aggregates (Landfill)",
        description: "Label for Activity L2 aggregatesLandfill",
    },
    aggregatesOpenloop: {
        defaultMessage: "Aggregates (Open-loop)",
        description: "Label for Activity L2 aggregatesOpenloop",
    },
    asbestosLandfill: {
        defaultMessage: "Asbestos (Landfill)",
        description: "Label for Activity L2 asbestosLandfill",
    },
    asphaltClosedloop: {
        defaultMessage: "Asphalt (Closed-loop)",
        description: "Label for Activity L2 asphaltClosedloop",
    },
    asphaltLandfill: {
        defaultMessage: "Asphalt (Landfill)",
        description: "Label for Activity L2 asphaltLandfill",
    },
    asphaltOpenloop: {
        defaultMessage: "Asphalt (Open-loop)",
        description: "Label for Activity L2 asphaltOpenloop",
    },
    averageConstructionClosedloop: {
        defaultMessage: "Average construction (Closed-loop)",
        description: "Label for Activity L2 averageConstructionClosedloop",
    },
    averageConstructionCombustion: {
        defaultMessage: "Average construction (Combustion)",
        description: "Label for Activity L2 averageConstructionCombustion",
    },
    averageConstructionOpenloop: {
        defaultMessage: "Average construction (Open-loop)",
        description: "Label for Activity L2 averageConstructionOpenloop",
    },
    bricksLandfill: {
        defaultMessage: "Bricks (Landfill)",
        description: "Label for Activity L2 bricksLandfill",
    },
    bricksOpenloop: {
        defaultMessage: "Bricks (Open-loop)",
        description: "Label for Activity L2 bricksOpenloop",
    },
    concreteClosedloop: {
        defaultMessage: "Concrete (Closed-loop)",
        description: "Label for Activity L2 concreteClosedloop",
    },
    concreteLandfill: {
        defaultMessage: "Concrete (Landfill)",
        description: "Label for Activity L2 concreteLandfill",
    },
    concreteOpenloop: {
        defaultMessage: "Concrete (Open-loop)",
        description: "Label for Activity L2 concreteOpenloop",
    },
    insulationClosedloop: {
        defaultMessage: "Insulation (Closed-loop)",
        description: "Label for Activity L2 insulationClosedloop",
    },
    insulationLandfill: {
        defaultMessage: "Insulation (Landfill)",
        description: "Label for Activity L2 insulationLandfill",
    },
    plasterboardClosedloop: {
        defaultMessage: "Plasterboard (Closed-loop)",
        description: "Label for Activity L2 plasterboardClosedloop",
    },
    plasterboardLandfill: {
        defaultMessage: "Plasterboard (Landfill)",
        description: "Label for Activity L2 plasterboardLandfill",
    },
    woodClosedloop: {
        defaultMessage: "Wood (Closed-loop)",
        description: "Label for Activity L2 woodClosedloop",
    },
    woodCombustion: {
        defaultMessage: "Wood (Combustion)",
        description: "Label for Activity L2 woodCombustion",
    },
    woodComposting: {
        defaultMessage: "Wood (Composting)",
        description: "Label for Activity L2 woodComposting",
    },
    woodLandfill: {
        defaultMessage: "Wood (Landfill)",
        description: "Label for Activity L2 woodLandfill",
    },
    clothingClosedloop: {
        defaultMessage: "Clothing (Closed-loop)",
        description: "Label for Activity L2 clothingClosedloop",
    },
    clothingCombustion: {
        defaultMessage: "Clothing (Combustion)",
        description: "Label for Activity L2 clothingCombustion",
    },
    clothingLandfill: {
        defaultMessage: "Clothing (Landfill)",
        description: "Label for Activity L2 clothingLandfill",
    },
    batteriesLandfill: {
        defaultMessage: "Batteries (Landfill)",
        description: "Label for Activity L2 batteriesLandfill",
    },
    batteriesOpenloop: {
        defaultMessage: "Batteries (Open-loop)",
        description: "Label for Activity L2 batteriesOpenloop",
    },
    weeeFridgesAndFreezersLandfill: {
        defaultMessage: "WEEE - fridges and freezers (Landfill)",
        description: "Label for Activity L2 weeeFridgesAndFreezersLandfill",
    },
    weeeFridgesAndFreezersOpenloop: {
        defaultMessage: "WEEE - fridges and freezers (Open-loop)",
        description: "Label for Activity L2 weeeFridgesAndFreezersOpenloop",
    },
    weeeLargeCombustion: {
        defaultMessage: "WEEE - large (Combustion)",
        description: "Label for Activity L2 weeeLargeCombustion",
    },
    weeeLargeLandfill: {
        defaultMessage: "WEEE - large (Landfill)",
        description: "Label for Activity L2 weeeLargeLandfill",
    },
    weeeLargeOpenloop: {
        defaultMessage: "WEEE - large (Open-loop)",
        description: "Label for Activity L2 weeeLargeOpenloop",
    },
    weeeMixedCombustion: {
        defaultMessage: "WEEE - mixed (Combustion)",
        description: "Label for Activity L2 weeeMixedCombustion",
    },
    weeeMixedLandfill: {
        defaultMessage: "WEEE - mixed (Landfill)",
        description: "Label for Activity L2 weeeMixedLandfill",
    },
    weeeMixedOpenloop: {
        defaultMessage: "WEEE - mixed (Open-loop)",
        description: "Label for Activity L2 weeeMixedOpenloop",
    },
    weeeSmallCombustion: {
        defaultMessage: "WEEE - small (Combustion)",
        description: "Label for Activity L2 weeeSmallCombustion",
    },
    weeeSmallLandfill: {
        defaultMessage: "WEEE - small (Landfill)",
        description: "Label for Activity L2 weeeSmallLandfill",
    },
    weeeSmallOpenloop: {
        defaultMessage: "WEEE - small (Open-loop)",
        description: "Label for Activity L2 weeeSmallOpenloop",
    },
    "aluminiumCansAndFoilExcl.FormingClosedloop": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Closed-loop)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingClosedloop",
    },
    "aluminiumCansAndFoilExcl.FormingCombustion": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Combustion)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingCombustion",
    },
    "aluminiumCansAndFoilExcl.FormingLandfill": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Landfill)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingLandfill",
    },
    "aluminiumCansAndFoilExcl.FormingOpenloop": {
        defaultMessage: "Aluminium cans and foil (excl. forming) (Open-loop)",
        description: "Label for Activity L2 aluminiumCansAndFoilExcl.FormingOpenloop",
    },
    mixedCansClosedloop: {
        defaultMessage: "Mixed cans (Closed-loop)",
        description: "Label for Activity L2 mixedCansClosedloop",
    },
    mixedCansCombustion: {
        defaultMessage: "Mixed cans (Combustion)",
        description: "Label for Activity L2 mixedCansCombustion",
    },
    mixedCansLandfill: {
        defaultMessage: "Mixed cans (Landfill)",
        description: "Label for Activity L2 mixedCansLandfill",
    },
    mixedCansOpenloop: {
        defaultMessage: "Mixed cans (Open-loop)",
        description: "Label for Activity L2 mixedCansOpenloop",
    },
    steelCansClosedloop: {
        defaultMessage: "Steel cans (Closed-loop)",
        description: "Label for Activity L2 steelCansClosedloop",
    },
    steelCansCombustion: {
        defaultMessage: "Steel cans (Combustion)",
        description: "Label for Activity L2 steelCansCombustion",
    },
    steelCansLandfill: {
        defaultMessage: "Steel cans (Landfill)",
        description: "Label for Activity L2 steelCansLandfill",
    },
    steelCansOpenloop: {
        defaultMessage: "Steel cans (Open-loop)",
        description: "Label for Activity L2 steelCansOpenloop",
    },
    glassClosedloop: {
        defaultMessage: "Glass (Closed-loop)",
        description: "Label for Activity L2 glassClosedloop",
    },
    glassCombustion: {
        defaultMessage: "Glass (Combustion)",
        description: "Label for Activity L2 glassCombustion",
    },
    glassLandfill: {
        defaultMessage: "Glass (Landfill)",
        description: "Label for Activity L2 glassLandfill",
    },
    glassOpenloop: {
        defaultMessage: "Glass (Open-loop)",
        description: "Label for Activity L2 glassOpenloop",
    },
    metalsClosedloop: {
        defaultMessage: "Metals (Closed-loop)",
        description: "Label for Activity L2 metalsClosedloop",
    },
    metalsLandfill: {
        defaultMessage: "Metals (Landfill)",
        description: "Label for Activity L2 metalsLandfill",
    },
    scrapMetalClosedloop: {
        defaultMessage: "Scrap metal (Closed-loop)",
        description: "Label for Activity L2 scrapMetalClosedloop",
    },
    scrapMetalCombustion: {
        defaultMessage: "Scrap metal (Combustion)",
        description: "Label for Activity L2 scrapMetalCombustion",
    },
    scrapMetalLandfill: {
        defaultMessage: "Scrap metal (Landfill)",
        description: "Label for Activity L2 scrapMetalLandfill",
    },
    scrapMetalOpenloop: {
        defaultMessage: "Scrap metal (Open-loop)",
        description: "Label for Activity L2 scrapMetalOpenloop",
    },
    foodAndDrinkWasteAnaerobicDigestion: {
        defaultMessage: "Food and drink waste (Anaerobic digestion)",
        description: "Label for Activity L2 foodAndDrinkWasteAnaerobicDigestion",
    },
    foodAndDrinkWasteCombustion: {
        defaultMessage: "Food and drink waste (Combustion)",
        description: "Label for Activity L2 foodAndDrinkWasteCombustion",
    },
    foodAndDrinkWasteComposting: {
        defaultMessage: "Food and drink waste (Composting)",
        description: "Label for Activity L2 foodAndDrinkWasteComposting",
    },
    foodAndDrinkWasteLandfill: {
        defaultMessage: "Food and drink waste (Landfill)",
        description: "Label for Activity L2 foodAndDrinkWasteLandfill",
    },
    gardenWasteAnaerobicDigestion: {
        defaultMessage: "Garden waste (Anaerobic digestion)",
        description: "Label for Activity L2 gardenWasteAnaerobicDigestion",
    },
    gardenWasteCombustion: {
        defaultMessage: "Garden waste (Combustion)",
        description: "Label for Activity L2 gardenWasteCombustion",
    },
    gardenWasteComposting: {
        defaultMessage: "Garden waste (Composting)",
        description: "Label for Activity L2 gardenWasteComposting",
    },
    gardenWasteLandfill: {
        defaultMessage: "Garden waste (Landfill)",
        description: "Label for Activity L2 gardenWasteLandfill",
    },
    mixedFoodAndGardenWasteAnaerobicDigestion: {
        defaultMessage: "Mixed food and garden waste (Anaerobic digestion)",
        description: "Label for Activity L2 mixedFoodAndGardenWasteAnaerobicDigestion",
    },
    mixedFoodAndGardenWasteCombustion: {
        defaultMessage: "Mixed food and garden waste (Combustion)",
        description: "Label for Activity L2 mixedFoodAndGardenWasteCombustion",
    },
    mixedFoodAndGardenWasteComposting: {
        defaultMessage: "Mixed food and garden waste (Composting)",
        description: "Label for Activity L2 mixedFoodAndGardenWasteComposting",
    },
    mixedFoodAndGardenWasteLandfill: {
        defaultMessage: "Mixed food and garden waste (Landfill)",
        description: "Label for Activity L2 mixedFoodAndGardenWasteLandfill",
    },
    soilsClosedloop: {
        defaultMessage: "Soils (Closed-loop)",
        description: "Label for Activity L2 soilsClosedloop",
    },
    soilsLandfill: {
        defaultMessage: "Soils (Landfill)",
        description: "Label for Activity L2 soilsLandfill",
    },
    booksClosedloop: {
        defaultMessage: "Books (Closed-loop)",
        description: "Label for Activity L2 booksClosedloop",
    },
    booksCombustion: {
        defaultMessage: "Books (Combustion)",
        description: "Label for Activity L2 booksCombustion",
    },
    booksLandfill: {
        defaultMessage: "Books (Landfill)",
        description: "Label for Activity L2 booksLandfill",
    },
    cardboardClosedloop: {
        defaultMessage: "Cardboard (Closed-loop)",
        description: "Label for Activity L2 cardboardClosedloop",
    },
    cardboardCombustion: {
        defaultMessage: "Cardboard (Combustion)",
        description: "Label for Activity L2 cardboardCombustion",
    },
    cardboardComposting: {
        defaultMessage: "Cardboard (Composting)",
        description: "Label for Activity L2 cardboardComposting",
    },
    cardboardLandfill: {
        defaultMessage: "Cardboard (Landfill)",
        description: "Label for Activity L2 cardboardLandfill",
    },
    mixedClosedloop: {
        defaultMessage: "Mixed (Closed-loop)",
        description: "Label for Activity L2 mixedClosedloop",
    },
    mixedCombustion: {
        defaultMessage: "Mixed (Combustion)",
        description: "Label for Activity L2 mixedCombustion",
    },
    mixedComposting: {
        defaultMessage: "Mixed (Composting)",
        description: "Label for Activity L2 mixedComposting",
    },
    mixedLandfill: {
        defaultMessage: "Mixed (Landfill)",
        description: "Label for Activity L2 mixedLandfill",
    },
    paperClosedloop: {
        defaultMessage: "Paper (Closed-loop)",
        description: "Label for Activity L2 paperClosedloop",
    },
    paperCombustion: {
        defaultMessage: "Paper (Combustion)",
        description: "Label for Activity L2 paperCombustion",
    },
    paperComposting: {
        defaultMessage: "Paper (Composting)",
        description: "Label for Activity L2 paperComposting",
    },
    paperLandfill: {
        defaultMessage: "Paper (Landfill)",
        description: "Label for Activity L2 paperLandfill",
    },
    averagePlasticFilmClosedloop: {
        defaultMessage: "Average plastic film (Closed-loop)",
        description: "Label for Activity L2 averagePlasticFilmClosedloop",
    },
    averagePlasticFilmCombustion: {
        defaultMessage: "Average plastic film (Combustion)",
        description: "Label for Activity L2 averagePlasticFilmCombustion",
    },
    averagePlasticFilmLandfill: {
        defaultMessage: "Average plastic film (Landfill)",
        description: "Label for Activity L2 averagePlasticFilmLandfill",
    },
    averagePlasticFilmOpenloop: {
        defaultMessage: "Average plastic film (Open-loop)",
        description: "Label for Activity L2 averagePlasticFilmOpenloop",
    },
    averagePlasticRigidClosedloop: {
        defaultMessage: "Average plastic rigid (Closed-loop)",
        description: "Label for Activity L2 averagePlasticRigidClosedloop",
    },
    averagePlasticRigidCombustion: {
        defaultMessage: "Average plastic rigid (Combustion)",
        description: "Label for Activity L2 averagePlasticRigidCombustion",
    },
    averagePlasticRigidLandfill: {
        defaultMessage: "Average plastic rigid (Landfill)",
        description: "Label for Activity L2 averagePlasticRigidLandfill",
    },
    averagePlasticRigidOpenloop: {
        defaultMessage: "Average plastic rigid (Open-loop)",
        description: "Label for Activity L2 averagePlasticRigidOpenloop",
    },
    averagePlasticsClosedloop: {
        defaultMessage: "Average plastics (Closed-loop)",
        description: "Label for Activity L2 averagePlasticsClosedloop",
    },
    averagePlasticsCombustion: {
        defaultMessage: "Average plastics (Combustion)",
        description: "Label for Activity L2 averagePlasticsCombustion",
    },
    averagePlasticsLandfill: {
        defaultMessage: "Average plastics (Landfill)",
        description: "Label for Activity L2 averagePlasticsLandfill",
    },
    averagePlasticsOpenloop: {
        defaultMessage: "Average plastics (Open-loop)",
        description: "Label for Activity L2 averagePlasticsOpenloop",
    },
    "hdpeIncl.FormingClosedloop": {
        defaultMessage: "HDPE (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 hdpeIncl.FormingClosedloop",
    },
    "hdpeIncl.FormingCombustion": {
        defaultMessage: "HDPE (incl. forming) (Combustion)",
        description: "Label for Activity L2 hdpeIncl.FormingCombustion",
    },
    "hdpeIncl.FormingLandfill": {
        defaultMessage: "HDPE (incl. forming) (Landfill)",
        description: "Label for Activity L2 hdpeIncl.FormingLandfill",
    },
    "hdpeIncl.FormingOpenloop": {
        defaultMessage: "HDPE (incl. forming) (Open-loop)",
        description: "Label for Activity L2 hdpeIncl.FormingOpenloop",
    },
    "ldpeAndLldpeIncl.FormingClosedloop": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingClosedloop",
    },
    "ldpeAndLldpeIncl.FormingCombustion": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Combustion)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingCombustion",
    },
    "ldpeAndLldpeIncl.FormingLandfill": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Landfill)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingLandfill",
    },
    "ldpeAndLldpeIncl.FormingOpenloop": {
        defaultMessage: "LDPE and LLDPE (incl. forming) (Open-loop)",
        description: "Label for Activity L2 ldpeAndLldpeIncl.FormingOpenloop",
    },
    mineralOilClosedloop: {
        defaultMessage: "Mineral oil (Closed-loop)",
        description: "Label for Activity L2 mineralOilClosedloop",
    },
    mineralOilCombustion: {
        defaultMessage: "Mineral oil (Combustion)",
        description: "Label for Activity L2 mineralOilCombustion",
    },
    "petIncl.FormingClosedloop": {
        defaultMessage: "PET (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 petIncl.FormingClosedloop",
    },
    "petIncl.FormingCombustion": {
        defaultMessage: "PET (incl. forming) (Combustion)",
        description: "Label for Activity L2 petIncl.FormingCombustion",
    },
    "petIncl.FormingLandfill": {
        defaultMessage: "PET (incl. forming) (Landfill)",
        description: "Label for Activity L2 petIncl.FormingLandfill",
    },
    "petIncl.FormingOpenloop": {
        defaultMessage: "PET (incl. forming) (Open-loop)",
        description: "Label for Activity L2 petIncl.FormingOpenloop",
    },
    "ppIncl.FormingClosedloop": {
        defaultMessage: "PP (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 ppIncl.FormingClosedloop",
    },
    "ppIncl.FormingCombustion": {
        defaultMessage: "PP (incl. forming) (Combustion)",
        description: "Label for Activity L2 ppIncl.FormingCombustion",
    },
    "ppIncl.FormingLandfill": {
        defaultMessage: "PP (incl. forming) (Landfill)",
        description: "Label for Activity L2 ppIncl.FormingLandfill",
    },
    "ppIncl.FormingOpenloop": {
        defaultMessage: "PP (incl. forming) (Open-loop)",
        description: "Label for Activity L2 ppIncl.FormingOpenloop",
    },
    "psIncl.FormingClosedloop": {
        defaultMessage: "PS (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 psIncl.FormingClosedloop",
    },
    "psIncl.FormingCombustion": {
        defaultMessage: "PS (incl. forming) (Combustion)",
        description: "Label for Activity L2 psIncl.FormingCombustion",
    },
    "psIncl.FormingLandfill": {
        defaultMessage: "PS (incl. forming) (Landfill)",
        description: "Label for Activity L2 psIncl.FormingLandfill",
    },
    "psIncl.FormingOpenloop": {
        defaultMessage: "PS (incl. forming) (Open-loop)",
        description: "Label for Activity L2 psIncl.FormingOpenloop",
    },
    "pvcIncl.FormingClosedloop": {
        defaultMessage: "PVC (incl. forming) (Closed-loop)",
        description: "Label for Activity L2 pvcIncl.FormingClosedloop",
    },
    "pvcIncl.FormingCombustion": {
        defaultMessage: "PVC (incl. forming) (Combustion)",
        description: "Label for Activity L2 pvcIncl.FormingCombustion",
    },
    "pvcIncl.FormingLandfill": {
        defaultMessage: "PVC (incl. forming) (Landfill)",
        description: "Label for Activity L2 pvcIncl.FormingLandfill",
    },
    "pvcIncl.FormingOpenloop": {
        defaultMessage: "PVC (incl. forming) (Open-loop)",
        description: "Label for Activity L2 pvcIncl.FormingOpenloop",
    },
    tyresClosedloop: {
        defaultMessage: "Tyres (Closed-loop)",
        description: "Label for Activity L2 tyresClosedloop",
    },
    commercialAndIndustrialWasteCombustion: {
        defaultMessage: "Commercial and industrial waste (Combustion)",
        description: "Label for Activity L2 commercialAndIndustrialWasteCombustion",
    },
    commercialAndIndustrialWasteLandfill: {
        defaultMessage: "Commercial and industrial waste (Landfill)",
        description: "Label for Activity L2 commercialAndIndustrialWasteLandfill",
    },
    householdResidualWasteCombustion: {
        defaultMessage: "Household residual waste (Combustion)",
        description: "Label for Activity L2 householdResidualWasteCombustion",
    },
    householdResidualWasteLandfill: {
        defaultMessage: "Household residual waste (Landfill)",
        description: "Label for Activity L2 householdResidualWasteLandfill",
    },
    waterTreatment: {
        defaultMessage: "Water treatment",
        description: "Label for Activity L2 waterTreatment",
    },
});
export default messages;
