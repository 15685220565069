import { useAlert } from "@/Providers/Alerts";
import { track } from "@ignite-analytics/track";
import { useIntl } from "react-intl";

export const useGetFormHandlers = (mode: "edit" | "create", refetchCallback) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const handleSuccess = (data) => {
        alertUser({
            value:
                mode === "edit"
                    ? formatMessage({
                          defaultMessage: "Category successfully saved",
                          description: "Alert message when editing custom category",
                      })
                    : formatMessage({
                          defaultMessage: "Category successfully created",
                          description: "Alert message when creating custom category",
                      }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });

        track("Carbon: custom category created", {
            name: data.name,
            scope: data.scope,
            activityL1: data.activityL1,
            edit: mode === "edit",
        });

        if (refetchCallback) refetchCallback();
    };

    const handleError = () => {
        alertUser({
            value:
                mode === "edit"
                    ? formatMessage({
                          defaultMessage: "Error saving category",
                          description: "Alert message when editing custom category",
                      })
                    : formatMessage({
                          defaultMessage: "Error creating category",
                          description: "Alert message when creating custom category",
                      }),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };

    return { handleSuccess, handleError };
};
