import { graphql } from "@/__generated__";
import { GetTopContributors_OverviewPageDocument } from "@/__generated__/graphql";
import { EmissionChange } from "@/Components/EmissionChange";
import { LoadingRows } from "@/Components/Loading/LoadingTable/LoadingRows";
import {
    mapContributorType,
    useContributorTypeOptions,
} from "@/Containers/LandingPage/components/ContributorTypePicker/helpers";
import { useGetSearchParamFilters, useSelectedElectricityBasis } from "@/Containers/LandingPage/hooks";
import { mapOverviewFiltersToGraphqlEmissionFilter } from "@/lib/filters/helpers";
import { formatNumber } from "@/lib/primitives/numbers";
import { CARBON_EVENTS } from "@/lib/tracking";
import { overviewRoute } from "@/Router/Overview";
import { topContributorsRoute } from "@/Router/TopContributors";
import { useQuery } from "@apollo/client";
import {} from "@ignite-analytics/components";
import { ArrowTopRightOnSquare, DotsVertical } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { sortBy } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ContributorTypePicker } from "../../components/ContributorTypePicker";
import { useSelectedYear } from "../../hooks";
import { useMissingContributorLabel } from "../../TopContributorsPage/components/TopContributorsTable/helpers";
import { ContributorActionPopover } from "./components/ContributorActionPopover";
import { useContributorState } from "./helpers";
import { ErrorOccuredNotice, NoContributorsNotice } from "./statesWithoutData";

graphql(`
    query getTopContributors_OverviewPage(
        $contributorType: ContributorType!
        $level: Int
        $electricityBasis: ElectricityBasis!
        $filter: EmissionsFilter
    ) {
        getTopContributorEmissions(
            contributorType: $contributorType
            level: $level
            electricityBasis: $electricityBasis
            filter: $filter
        ) {
            results {
                supplier {
                    id
                    name
                }
                businessUnit {
                    id
                    name
                }
                spendCategory {
                    id
                    name
                }
                totalEmissions
                emissionsByYear {
                    year
                    value
                }
            }
        }
    }
`);

export const TopContributorsWidget: React.FC = () => {
    const navigate = useNavigate();
    const year = useSelectedYear();
    const electricity = useSelectedElectricityBasis();
    const filters = useGetSearchParamFilters();
    const [selectedContributor, onSelectedContributorChange] = useContributorState({
        contributorType: "suppliers",
        level: undefined,
    });

    const { data, loading, error } = useQuery(GetTopContributors_OverviewPageDocument, {
        variables: {
            contributorType: mapContributorType(selectedContributor.contributorType),
            level: selectedContributor.level,
            electricityBasis: electricity === "locationBased" ? "LOCATION_BASED" : "MARKET_BASED",
            filter: mapOverviewFiltersToGraphqlEmissionFilter(filters),
        },
    });
    const topContributors = useMemo(() => {
        const results = data?.getTopContributorEmissions?.results;
        if (results === undefined) return undefined;

        return sortBy(
            results,
            (contributor) => -(contributor.emissionsByYear.find(({ year: candYear }) => year === candYear)?.value ?? 0)
        ).slice(0, 7);
    }, [data?.getTopContributorEmissions?.results, year]);

    const [anchorEl, setAnchorEl] = React.useState<null | {
        element: HTMLElement;
        id: string;
        name: string;
    }>(null);

    const handleViewAllSuppliers = () => {
        CARBON_EVENTS.LANDING_PAGE_VIEW_ALL_TOP_CONTRIBUTORS_CLICKED({ page: "Overview" }).track();

        navigate({
            to: topContributorsRoute.fullPath,
            from: overviewRoute.fullPath,
            search: (prev) => ({
                ...prev,
                contributorType: selectedContributor.contributorType,
                contributorTypeLevel: selectedContributor.level,
            }),
        });
    };

    const contributorTypeOptions = useContributorTypeOptions();
    const missingContributorLabel = useMissingContributorLabel(selectedContributor.contributorType);
    return (
        <Card
            sx={{
                height: "100%",
            }}
        >
            <CardContent height="100%" component={Stack} spacing={2.5}>
                <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" height={60}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Top contributors by emissions" />
                        </Typography>
                        <Stack direction="row" columnGap={1}>
                            <ContributorTypePicker
                                options={contributorTypeOptions}
                                defaultValue={selectedContributor}
                                onSelect={onSelectedContributorChange}
                            />
                            <Button
                                aria-label="view-all-suppliers"
                                size="small"
                                color="secondary"
                                role="link"
                                onClick={handleViewAllSuppliers}
                            >
                                <FormattedMessage defaultMessage="View all" />
                            </Button>
                        </Stack>
                    </Stack>
                    <Divider />
                </Stack>
                <TableContainer sx={{ border: "none" }}>
                    <Table>
                        <TableBody>
                            {loading && <LoadingRows numberOfRows={8} numberOfColumns={3} large />}
                            {!loading && !topContributors?.length && !error && <NoContributorsNotice />}
                            {!loading && !topContributors?.length && error && <ErrorOccuredNotice />}
                            {topContributors?.length &&
                                topContributors.map((contributor) => {
                                    const emissionsPerYear = contributor.emissionsByYear.reduce((acc, curr) => {
                                        acc[`${curr.year}`] = curr.value;
                                        return acc;
                                    }, {});

                                    let id: string | null;
                                    let name: string;
                                    let href: string | undefined;
                                    switch (selectedContributor.contributorType) {
                                        case "suppliers":
                                            id = contributor.supplier?.id ?? null;
                                            name = contributor.supplier?.name ?? missingContributorLabel;
                                            href = `/supplier-profile/${id}/overview/`;
                                            break;
                                        case "companyStructure":
                                            id = contributor.businessUnit?.id ?? null;
                                            name = contributor.businessUnit?.name ?? missingContributorLabel;
                                            break;
                                        case "spendCategories":
                                            id = contributor.spendCategory?.id ?? null;
                                            name = contributor.spendCategory?.name ?? missingContributorLabel;
                                            href = `/spend/categories/${id}/emissions`;
                                            break;
                                    }
                                    return (
                                        <TableRow key={id}>
                                            <TableCell>
                                                {id && href ? (
                                                    <Button
                                                        LinkComponent="a"
                                                        color="linkPrimary"
                                                        href={href}
                                                        sx={{ minWidth: 0 }}
                                                    >
                                                        <Typography variant="textSm" fontWeight={600}>
                                                            {name}
                                                        </Typography>
                                                    </Button>
                                                ) : (
                                                    <Typography variant="textSm" fontWeight={600}>
                                                        {name}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="textSm" fontWeight={500} textAlign="end" noWrap>
                                                    <FormattedMessage
                                                        defaultMessage="{value} tCO₂e"
                                                        values={{
                                                            value: formatNumber(emissionsPerYear[`${year}`] ?? 0, 2),
                                                        }}
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <EmissionChange year={year} perYear={emissionsPerYear} />
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" justifyContent="flex-end">
                                                    {id && href && (
                                                        <IconButton LinkComponent="a" href={href} target="_blank">
                                                            <ArrowTopRightOnSquare />
                                                        </IconButton>
                                                    )}
                                                    {/** Only supporting actions on suppliers until we have more actions */}
                                                    {id && selectedContributor.contributorType === "suppliers" && (
                                                        <IconButton
                                                            onClick={(event) => {
                                                                setAnchorEl({
                                                                    element: event.currentTarget,
                                                                    id,
                                                                    name,
                                                                });
                                                            }}
                                                        >
                                                            <DotsVertical />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ContributorActionPopover
                    data={
                        anchorEl
                            ? {
                                  id: anchorEl.id,
                                  name: anchorEl.name,
                                  year,
                              }
                            : undefined
                    }
                    anchorEl={anchorEl?.element}
                    handleClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl?.element)}
                    contributorType={selectedContributor.contributorType}
                    trackSource={{ page: "Overview", component: "TopContributorsWidget" }}
                />
            </CardContent>
        </Card>
    );
};
