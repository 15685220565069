import { ChevronRight } from "@ignite-analytics/icons";
import { Collapse, FormControl, List, ListItemIcon, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { range } from "lodash";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const ExpandableMenuItem: React.FC<{
    option: {
        name: string;
        contributorType: ContributorType;
        numLevels?: number;
    };
    currentValue: Contributor | undefined;
    onSelect: (level: number | undefined) => void;
}> = ({ option, onSelect, currentValue }) => {
    const [open, setOpen] = useState(option.contributorType === currentValue?.contributorType);

    const isParentSelected = option.contributorType === currentValue?.contributorType;

    if (!option.numLevels)
        return (
            <MenuItem
                key={option.contributorType}
                value={option.contributorType}
                onClick={() => onSelect(undefined)}
                selected={option.contributorType === currentValue?.contributorType}
            >
                <Typography>{option.name}</Typography>
            </MenuItem>
        );

    if (option.numLevels === 1) {
        return (
            <MenuItem
                key={option.contributorType}
                value={option.contributorType}
                onClick={() => onSelect(1)}
                selected={option.contributorType === currentValue?.contributorType}
            >
                <Typography>{option.name}</Typography>
            </MenuItem>
        );
    }

    return (
        <>
            <MenuItem onClick={() => setOpen((prev) => !prev)}>
                <ListItemIcon>
                    <ChevronRight
                        fontSize="small"
                        sx={{ transform: open ? "rotate(90deg)" : "rotate(0deg)", transition: "transform .2s" }}
                    />
                </ListItemIcon>
                <Typography>{option.name}</Typography>
            </MenuItem>
            <Collapse in={open}>
                <List>
                    {range(1, option.numLevels + 1).map((level) => (
                        <MenuItem
                            key={`${option.contributorType}-${level}`}
                            value={`${option.contributorType}-l${level}`}
                            sx={{ paddingLeft: 4 }}
                            onClick={() => onSelect(level)}
                            selected={isParentSelected && level === currentValue?.level}
                        >
                            <ListItemText>
                                <FormattedMessage
                                    defaultMessage="Level {level}"
                                    description="Menu item level select label"
                                    values={{ level }}
                                />
                            </ListItemText>
                        </MenuItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export type ContributorType = "suppliers" | "spendCategories" | "companyStructure";
export type Contributor = { contributorType: ContributorType; level: number | undefined };
export type ContributorTypeOption = {
    name: string;
    contributorType: ContributorType;
    numLevels?: number;
};

export type ContributorPickerProps = {
    options: ContributorTypeOption[];
    defaultValue: Contributor | undefined;
    onSelect: (contributor: Contributor) => void;
};
export const ContributorTypePicker: React.FC<ContributorPickerProps> = ({ options, defaultValue, onSelect }) => {
    const { formatMessage } = useIntl();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const parseFormValue = (value: string) => {
        const [type, level] = value.split("-l");
        return { type, level: level ? Number(level) : undefined };
    };

    return (
        <FormControl>
            <Select
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={
                    selectedValue?.level
                        ? `${selectedValue.contributorType}-l${selectedValue.level}`
                        : (selectedValue?.contributorType ?? "suppliers")
                }
                label={formatMessage({
                    defaultMessage: "Contributor type",
                    description: "Contributor type selector label",
                })}
                renderValue={(val) => {
                    const { type, level } = parseFormValue(val);
                    const option = options?.find((o) => o.contributorType === type);
                    if (level) {
                        return (
                            <FormattedMessage
                                defaultMessage="{optionLabel} (Level {level})"
                                description="Multilevel option label"
                                values={{ optionLabel: option?.name, level }}
                            />
                        );
                    }
                    return option?.name;
                }}
            >
                {options?.map((option) => {
                    return (
                        <ExpandableMenuItem
                            currentValue={selectedValue}
                            key={option.contributorType}
                            onSelect={(level: number | undefined) => {
                                const value = { contributorType: option.contributorType, level };
                                setSelectedValue(value);
                                onSelect(value);
                                setIsOpen(false);
                            }}
                            option={option}
                        />
                    );
                })}
            </Select>
        </FormControl>
    );
};
