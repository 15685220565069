import { CategoryActionPopover } from "@/Components/ActionPopover/CategoryActionPopover";

import React from "react";
import { ScopeCategory } from "router";

export type PopoverInput = {
    category: ScopeCategory;
    year: number;
    total: number;
} | null;

type TooltipProps = {
    input: PopoverInput | null;
    close: () => void;
};

/**
 * Similar to the CategoryActionPopover, but takes in a fixed x/y position instead of an anchor element.
 */
export const FixedCategoryActionPopover: React.FC<TooltipProps> = ({ input, close }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    // For some reason the margin makes the popover end up in the right place, do not remove
    return (
        <div ref={ref} style={{ margin: 1 }}>
            {ref.current && input && (
                <CategoryActionPopover
                    trackSource={{ page: "Overview", component: "PieChart" }}
                    anchorEl={ref.current}
                    handleClose={close}
                    open={Boolean(ref.current)}
                    config={{
                        type: "emissionsByCategory",
                        ...input,
                    }}
                />
            )}
        </div>
    );
};
