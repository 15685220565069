import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const breakDownOptions = [
    {
        value: "scope",
        label: (
            <Typography variant="textSm">
                <FormattedMessage defaultMessage="Scope" description="Option in picker for breakdown of emissions" />
            </Typography>
        ),
    },
    {
        value: "companyStructure",
        label: (
            <Typography variant="textSm">
                <FormattedMessage
                    defaultMessage="Company structure"
                    description="Option in picker for breakdown of emissions"
                />
            </Typography>
        ),
    },
    {
        value: "spendCategories",
        label: (
            <Typography variant="textSm">
                <FormattedMessage
                    defaultMessage="Spend categories"
                    description="Option in picker for breakdown of emissions"
                />
            </Typography>
        ),
    },
] as const;

export type PossibleBreakdown = (typeof breakDownOptions)[number]["value"];
