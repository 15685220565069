import { CountryCode } from "router";

export interface CountryType {
    code: CountryCode;
    label: string;
    suggested?: boolean;
}

export const countryCodeLabelRecord: Record<CountryCode, CountryType> = {
    AD: { code: "AD", label: "Andorra" },
    AE: { code: "AE", label: "United Arab Emirates" },
    AF: { code: "AF", label: "Afghanistan" },
    AG: { code: "AG", label: "Antigua and Barbuda" },
    AI: { code: "AI", label: "Anguilla" },
    AL: { code: "AL", label: "Albania" },
    AM: { code: "AM", label: "Armenia" },
    AO: { code: "AO", label: "Angola" },
    AQ: { code: "AQ", label: "Antarctica" },
    AR: { code: "AR", label: "Argentina" },
    AS: { code: "AS", label: "American Samoa" },
    AT: { code: "AT", label: "Austria" },
    AU: { code: "AU", label: "Australia" },
    AW: { code: "AW", label: "Aruba" },
    AX: { code: "AX", label: "Alland Islands" },
    AZ: { code: "AZ", label: "Azerbaijan" },
    BA: { code: "BA", label: "Bosnia and Herzegovina" },
    BB: { code: "BB", label: "Barbados" },
    BD: { code: "BD", label: "Bangladesh" },
    BE: { code: "BE", label: "Belgium" },
    BF: { code: "BF", label: "Burkina Faso" },
    BG: { code: "BG", label: "Bulgaria" },
    BH: { code: "BH", label: "Bahrain" },
    BI: { code: "BI", label: "Burundi" },
    BJ: { code: "BJ", label: "Benin" },
    BL: { code: "BL", label: "Saint Barthelemy" },
    BM: { code: "BM", label: "Bermuda" },
    BN: { code: "BN", label: "Brunei Darussalam" },
    BO: { code: "BO", label: "Bolivia" },
    BR: { code: "BR", label: "Brazil" },
    BS: { code: "BS", label: "Bahamas" },
    BT: { code: "BT", label: "Bhutan" },
    BV: { code: "BV", label: "Bouvet Island" },
    BW: { code: "BW", label: "Botswana" },
    BY: { code: "BY", label: "Belarus" },
    BZ: { code: "BZ", label: "Belize" },
    CA: { code: "CA", label: "Canada" },
    CC: { code: "CC", label: "Cocos (Keeling) Islands" },
    CD: { code: "CD", label: "Congo, Democratic Republic of the" },
    CF: { code: "CF", label: "Central African Republic" },
    CG: { code: "CG", label: "Congo, Republic of the" },
    CH: { code: "CH", label: "Switzerland" },
    CI: { code: "CI", label: "Cote d'Ivoire" },
    CK: { code: "CK", label: "Cook Islands" },
    CL: { code: "CL", label: "Chile" },
    CM: { code: "CM", label: "Cameroon" },
    CN: { code: "CN", label: "China" },
    CO: { code: "CO", label: "Colombia" },
    CR: { code: "CR", label: "Costa Rica" },
    CU: { code: "CU", label: "Cuba" },
    CV: { code: "CV", label: "Cape Verde" },
    CW: { code: "CW", label: "Curacao" },
    CX: { code: "CX", label: "Christmas Island" },
    CY: { code: "CY", label: "Cyprus" },
    CZ: { code: "CZ", label: "Czech Republic" },
    DE: { code: "DE", label: "Germany", suggested: true },
    DJ: { code: "DJ", label: "Djibouti" },
    DK: { code: "DK", label: "Denmark", suggested: true },
    DM: { code: "DM", label: "Dominica" },
    DO: { code: "DO", label: "Dominican Republic" },
    DZ: { code: "DZ", label: "Algeria" },
    EC: { code: "EC", label: "Ecuador" },
    EE: { code: "EE", label: "Estonia" },
    EG: { code: "EG", label: "Egypt" },
    EH: { code: "EH", label: "Western Sahara" },
    ER: { code: "ER", label: "Eritrea" },
    ES: { code: "ES", label: "Spain" },
    ET: { code: "ET", label: "Ethiopia" },
    FI: { code: "FI", label: "Finland" },
    FJ: { code: "FJ", label: "Fiji" },
    FK: { code: "FK", label: "Falkland Islands (Malvinas)" },
    FM: { code: "FM", label: "Micronesia, Federated States of" },
    FO: { code: "FO", label: "Faroe Islands" },
    FR: { code: "FR", label: "France" },
    GA: { code: "GA", label: "Gabon" },
    GB: { code: "GB", label: "United Kingdom", suggested: true },
    GD: { code: "GD", label: "Grenada" },
    GE: { code: "GE", label: "Georgia" },
    GF: { code: "GF", label: "French Guiana" },
    GG: { code: "GG", label: "Guernsey" },
    GH: { code: "GH", label: "Ghana" },
    GI: { code: "GI", label: "Gibraltar" },
    GL: { code: "GL", label: "Greenland" },
    GM: { code: "GM", label: "Gambia" },
    GN: { code: "GN", label: "Guinea" },
    GP: { code: "GP", label: "Guadeloupe" },
    GQ: { code: "GQ", label: "Equatorial Guinea" },
    GR: { code: "GR", label: "Greece" },
    GS: { code: "GS", label: "South Georgia and the South Sandwich Islands" },
    GT: { code: "GT", label: "Guatemala" },
    GU: { code: "GU", label: "Guam" },
    GW: { code: "GW", label: "Guinea-Bissau" },
    GY: { code: "GY", label: "Guyana" },
    HK: { code: "HK", label: "Hong Kong" },
    HM: { code: "HM", label: "Heard Island and McDonald Islands" },
    HN: { code: "HN", label: "Honduras" },
    HR: { code: "HR", label: "Croatia" },
    HT: { code: "HT", label: "Haiti" },
    HU: { code: "HU", label: "Hungary" },
    ID: { code: "ID", label: "Indonesia" },
    IE: { code: "IE", label: "Ireland" },
    IL: { code: "IL", label: "Israel" },
    IM: { code: "IM", label: "Isle of Man" },
    IN: { code: "IN", label: "India" },
    IO: { code: "IO", label: "British Indian Ocean Territory" },
    IQ: { code: "IQ", label: "Iraq" },
    IR: { code: "IR", label: "Iran, Islamic Republic of" },
    IS: { code: "IS", label: "Iceland" },
    IT: { code: "IT", label: "Italy" },
    JE: { code: "JE", label: "Jersey" },
    JM: { code: "JM", label: "Jamaica" },
    JO: { code: "JO", label: "Jordan" },
    JP: { code: "JP", label: "Japan" },
    KE: { code: "KE", label: "Kenya" },
    KG: { code: "KG", label: "Kyrgyzstan" },
    KH: { code: "KH", label: "Cambodia" },
    KI: { code: "KI", label: "Kiribati" },
    KM: { code: "KM", label: "Comoros" },
    KN: { code: "KN", label: "Saint Kitts and Nevis" },
    KP: { code: "KP", label: "Korea, Democratic People's Republic of" },
    KR: { code: "KR", label: "Korea, Republic of" },
    KW: { code: "KW", label: "Kuwait" },
    KY: { code: "KY", label: "Cayman Islands" },
    KZ: { code: "KZ", label: "Kazakhstan" },
    LA: { code: "LA", label: "Lao People's Democratic Republic" },
    LB: { code: "LB", label: "Lebanon" },
    LC: { code: "LC", label: "Saint Lucia" },
    LI: { code: "LI", label: "Liechtenstein" },
    LK: { code: "LK", label: "Sri Lanka" },
    LR: { code: "LR", label: "Liberia" },
    LS: { code: "LS", label: "Lesotho" },
    LT: { code: "LT", label: "Lithuania" },
    LU: { code: "LU", label: "Luxembourg" },
    LV: { code: "LV", label: "Latvia" },
    LY: { code: "LY", label: "Libya" },
    MA: { code: "MA", label: "Morocco" },
    MC: { code: "MC", label: "Monaco" },
    MD: { code: "MD", label: "Moldova, Republic of" },
    ME: { code: "ME", label: "Montenegro" },
    MF: { code: "MF", label: "Saint Martin (French part)" },
    MG: { code: "MG", label: "Madagascar" },
    MH: { code: "MH", label: "Marshall Islands" },
    MK: { code: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
    ML: { code: "ML", label: "Mali" },
    MM: { code: "MM", label: "Myanmar" },
    MN: { code: "MN", label: "Mongolia" },
    MO: { code: "MO", label: "Macao" },
    MP: { code: "MP", label: "Northern Mariana Islands" },
    MQ: { code: "MQ", label: "Martinique" },
    MR: { code: "MR", label: "Mauritania" },
    MS: { code: "MS", label: "Montserrat" },
    MT: { code: "MT", label: "Malta" },
    MU: { code: "MU", label: "Mauritius" },
    MV: { code: "MV", label: "Maldives" },
    MW: { code: "MW", label: "Malawi" },
    MX: { code: "MX", label: "Mexico" },
    MY: { code: "MY", label: "Malaysia" },
    MZ: { code: "MZ", label: "Mozambique" },
    NA: { code: "NA", label: "Namibia" },
    NC: { code: "NC", label: "New Caledonia" },
    NE: { code: "NE", label: "Niger" },
    NF: { code: "NF", label: "Norfolk Island" },
    NG: { code: "NG", label: "Nigeria" },
    NI: { code: "NI", label: "Nicaragua" },
    NL: { code: "NL", label: "Netherlands" },
    NO: { code: "NO", label: "Norway", suggested: true },
    NP: { code: "NP", label: "Nepal" },
    NR: { code: "NR", label: "Nauru" },
    NU: { code: "NU", label: "Niue" },
    NZ: { code: "NZ", label: "New Zealand" },
    OM: { code: "OM", label: "Oman" },
    PA: { code: "PA", label: "Panama" },
    PE: { code: "PE", label: "Peru" },
    PF: { code: "PF", label: "French Polynesia" },
    PG: { code: "PG", label: "Papua New Guinea" },
    PH: { code: "PH", label: "Philippines" },
    PK: { code: "PK", label: "Pakistan" },
    PL: { code: "PL", label: "Poland" },
    PM: { code: "PM", label: "Saint Pierre and Miquelon" },
    PN: { code: "PN", label: "Pitcairn" },
    PR: { code: "PR", label: "Puerto Rico" },
    PS: { code: "PS", label: "Palestine, State of" },
    PT: { code: "PT", label: "Portugal" },
    PW: { code: "PW", label: "Palau" },
    PY: { code: "PY", label: "Paraguay" },
    QA: { code: "QA", label: "Qatar" },
    RE: { code: "RE", label: "Reunion" },
    RO: { code: "RO", label: "Romania" },
    RS: { code: "RS", label: "Serbia" },
    RU: { code: "RU", label: "Russian Federation" },
    RW: { code: "RW", label: "Rwanda" },
    SA: { code: "SA", label: "Saudi Arabia" },
    SB: { code: "SB", label: "Solomon Islands" },
    SC: { code: "SC", label: "Seychelles" },
    SD: { code: "SD", label: "Sudan" },
    SE: { code: "SE", label: "Sweden", suggested: true },
    SG: { code: "SG", label: "Singapore" },
    SH: { code: "SH", label: "Saint Helena" },
    SI: { code: "SI", label: "Slovenia" },
    SJ: { code: "SJ", label: "Svalbard and Jan Mayen" },
    SK: { code: "SK", label: "Slovakia" },
    SL: { code: "SL", label: "Sierra Leone" },
    SM: { code: "SM", label: "San Marino" },
    SN: { code: "SN", label: "Senegal" },
    SO: { code: "SO", label: "Somalia" },
    SR: { code: "SR", label: "Suriname" },
    SS: { code: "SS", label: "South Sudan" },
    ST: { code: "ST", label: "Sao Tome and Principe" },
    SV: { code: "SV", label: "El Salvador" },
    SX: { code: "SX", label: "Sint Maarten (Dutch part)" },
    SY: { code: "SY", label: "Syrian Arab Republic" },
    SZ: { code: "SZ", label: "Swaziland" },
    TC: { code: "TC", label: "Turks and Caicos Islands" },
    TD: { code: "TD", label: "Chad" },
    TF: { code: "TF", label: "French Southern Territories" },
    TG: { code: "TG", label: "Togo" },
    TH: { code: "TH", label: "Thailand" },
    TJ: { code: "TJ", label: "Tajikistan" },
    TK: { code: "TK", label: "Tokelau" },
    TL: { code: "TL", label: "Timor-Leste" },
    TM: { code: "TM", label: "Turkmenistan" },
    TN: { code: "TN", label: "Tunisia" },
    TO: { code: "TO", label: "Tonga" },
    TR: { code: "TR", label: "Turkey" },
    TT: { code: "TT", label: "Trinidad and Tobago" },
    TV: { code: "TV", label: "Tuvalu" },
    TW: { code: "TW", label: "Taiwan, Republic of China" },
    TZ: { code: "TZ", label: "United Republic of Tanzania" },
    UA: { code: "UA", label: "Ukraine" },
    UG: { code: "UG", label: "Uganda" },
    US: { code: "US", label: "United States", suggested: true },
    UY: { code: "UY", label: "Uruguay" },
    UZ: { code: "UZ", label: "Uzbekistan" },
    VA: { code: "VA", label: "Holy See (Vatican City State)" },
    VC: { code: "VC", label: "Saint Vincent and the Grenadines" },
    VE: { code: "VE", label: "Venezuela" },
    VG: { code: "VG", label: "British Virgin Islands" },
    VI: { code: "VI", label: "US Virgin Islands" },
    VN: { code: "VN", label: "Vietnam" },
    VU: { code: "VU", label: "Vanuatu" },
    WF: { code: "WF", label: "Wallis and Futuna" },
    WS: { code: "WS", label: "Samoa" },
    XK: { code: "XK", label: "Kosovo" },
    YE: { code: "YE", label: "Yemen" },
    YT: { code: "YT", label: "Mayotte" },
    ZA: { code: "ZA", label: "South Africa" },
    ZM: { code: "ZM", label: "Zambia" },
    ZW: { code: "ZW", label: "Zimbabwe" },
};
