import { zodResolver } from "@hookform/resolvers/zod";
import { ShapeIcon } from "@ignite-analytics/components";
import { Plus, X } from "@ignite-analytics/icons";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";

const schema = z.object({
    name: z.string().min(1),
    unit: z.string().min(1),
});

type NewEmissionIntensityDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: z.infer<typeof schema>) => void;
};

const NewEmissionIntensityDialog: React.FC<NewEmissionIntensityDialogProps> = ({ open, onClose, onSubmit }) => {
    const { register, handleSubmit } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            name: "",
            unit: "",
        },
    });
    const { formatMessage } = useIntl();

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{ mb: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <ShapeIcon color="accent" size="medium">
                            <Plus />
                        </ShapeIcon>
                        <IconButton onClick={() => onClose()}>
                            <X />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Typography variant="textLg" fontWeight="medium">
                                <FormattedMessage
                                    defaultMessage="Add a new emission intensity type"
                                    description="Header when adding a new emission intensity"
                                />
                            </Typography>
                            <Typography variant="textSm" color="text.text-helper">
                                <FormattedMessage
                                    defaultMessage="No changes will apply to the current years and levels."
                                    description="Subheader when adding a new emission intensity"
                                />
                            </Typography>
                        </Stack>
                        <Divider sx={{ margin: (t) => t.spacing(0.25, 0) }} />
                        <TextField
                            {...register("name")}
                            autoComplete="off"
                            label={
                                <FormattedMessage
                                    defaultMessage="Name"
                                    description="Label for name text input when adding a new emission intensity"
                                />
                            }
                            placeholder={formatMessage({
                                defaultMessage: "Enter a name",
                                description:
                                    "Placeholder vlaue for name text input when adding a new emission intensity",
                            })}
                        />
                        <TextField
                            {...register("unit")}
                            label={
                                <FormattedMessage
                                    defaultMessage="Metric"
                                    description="Label for metric text input when adding a new emission intensity"
                                />
                            }
                            placeholder={formatMessage({
                                defaultMessage: "Examples: mNOK, $M, kNOK, k€",
                                description:
                                    "Placeholder vlaue for name metric input when adding a new emission intensity",
                            })}
                        />
                        <Box>
                            <Divider sx={{ margin: (t) => t.spacing(2, 0, 0, 0) }} />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={1.5}>
                        <Button color="secondary" onClick={() => onClose()}>
                            <FormattedMessage defaultMessage="Close" description="Close dialog button" />
                        </Button>
                        <Button type="submit">
                            <FormattedMessage defaultMessage="Save" description="Save new emission intensity button" />
                        </Button>
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export { NewEmissionIntensityDialog };
