import { CloseDialogTitle } from "@/Components/CloseDialog";
import { Dialog, DialogContent, Stack, Alert, Link } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { LearnMoreForm } from "./LearnMoreForm";
import { LearnMoreFinished } from "./LearnMoreFinished";

export const LearnMore = () => {
    const [hasSentMessage, setHasSentMessage] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const sendMessage = (message: string) => {
        if (!process.env.VITE_MODULE_INTEREST_SLACK_WEBHOOK) {
            setError(true);
            return;
        }
        fetch(process.env.VITE_MODULE_INTEREST_SLACK_WEBHOOK, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                module: "carbon",
                message,
            }),
        })
            .then(() => {
                setHasSentMessage(true);
            })
            .catch(() => {
                setError(true);
            });
    };
    const onClose = () => {
        navigate({
            to: ".",
            search: (prev) => ({
                ...prev,
                viewType: undefined,
            }),
        });
    };
    return (
        <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
            <CloseDialogTitle onClose={onClose} />
            <DialogContent>
                <Stack gap={2}>
                    {hasSentMessage ? <LearnMoreFinished /> : <LearnMoreForm sendMessage={sendMessage} />}
                    {error && (
                        <Alert severity="error">
                            <FormattedMessage
                                defaultMessage="We were not able to send the message, please try again or contact us by clicking{space}"
                                description="Error message when the contact me form fails to send"
                                values={{ space: " " }}
                            />
                            <Link href="mailto:cs@ignite.no" target="_blank" rel="noopener noreferrer">
                                <FormattedMessage
                                    defaultMessage="contact us"
                                    description="Link to contact us by email"
                                />
                            </Link>
                        </Alert>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
