import { activitiyLogRoute } from "@/Router/ActivitiesRoute/ActivitiesLogRoute";
import { overviewRoute } from "@/Router/Overview";
import { CARBON_EVENTS, TrackSource } from "@/lib/tracking";
import { useNavigate } from "@tanstack/react-router";
import { SourceConfig } from "../../types";

export const useGetSeeActivitiesClick = (config: SourceConfig, trackSource: TrackSource) => {
    const navigate = useNavigate();

    if (config.type === "supplier") {
        const { year, supplierId, supplierName } = config;
        return () => {
            CARBON_EVENTS.SEE_ALL_ACTIVITIES_CLICK(trackSource, { year, supplierId, supplierName }).track();
            navigate({
                from: overviewRoute.fullPath,
                to: activitiyLogRoute.fullPath,
                search: { year, supplierId },
            });
        };
    }
    if (config.type === "emissionsByScope") {
        const { year, scope } = config;
        return () => {
            CARBON_EVENTS.SEE_ALL_ACTIVITIES_CLICK(trackSource, { year, scope }).track();
            navigate({
                from: overviewRoute.fullPath,
                to: activitiyLogRoute.fullPath,
                search: scope ? { year, scope } : { year },
            });
        };
    }
    if (config.type === "emissionsByCategory") {
        const { year, category } = config;
        return () => {
            CARBON_EVENTS.SEE_ALL_ACTIVITIES_CLICK(trackSource, { year, category }).track();
            navigate({
                from: overviewRoute.fullPath,
                to: activitiyLogRoute.fullPath,
                search: { year, category },
            });
        };
    }

    return undefined;
};
