import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import { PieChartChart } from "./Chart";
import { useGetSeriesData } from "./hooks";

type Props = {
    data: ReturnType<typeof useGetSeriesData>;
};
export const PieChartWrapper: React.FC<Props> = ({ data }) => {
    return (
        <Stack direction="column" height="100%" justifyContent="space-around">
            {data.type === "loading" && (
                <Stack>
                    <Stack direction="row" gap={4} justifyContent="center">
                        <Box width={320} maxHeight={440}>
                            <Skeleton width={320} height={600} sx={{ margin: 0, padding: 0, marginTop: -15 }} />
                        </Box>
                    </Stack>
                    <Box height={62}>
                        <Skeleton width="100%" height={120} sx={{ padding: 0, marginTop: -7 }} />
                    </Box>
                </Stack>
            )}
            {data.type === "success" && <PieChartChart chartData={data.series} year={data.year} />}
        </Stack>
    );
};
