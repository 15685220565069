import { Grid, CircularProgress } from "@mui/material";
import React from "react";

import { EmissionsByYearChart } from "./Chart";
import { useGetEmissionsData } from "./hooks";

export const EmissionsByYear: React.FC = () => {
    const data = useGetEmissionsData();
    return (
        <>
            {data.type === "loading" && (
                <Grid container justifyContent="center" alignItems="center" height={340} width={700}>
                    <Grid item>{data.type !== "loading" && <CircularProgress />}</Grid>
                </Grid>
            )}

            {data.type === "success" && (
                <EmissionsByYearChart
                    emissionsSortedByYear={data.emissionsSortedByYear.emissionsData}
                    showSideMenuItems={data.emissionsSortedByYear.itemsToShow}
                    years={data.years ?? []}
                    total={data.total}
                />
            )}
        </>
    );
};
