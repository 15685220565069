import { IntlShape } from "react-intl";
import { CreatedBy } from "router";

export const getLabelForUser = (obj: CreatedBy | null, intl: IntlShape) => {
    if (!obj) {
        return intl.formatMessage({
            defaultMessage: "Unknown user",
            description: "Unknown data",
        });
    }

    switch (obj.__type) {
        case "found":
            return `${obj.firstName} ${obj.lastName}`;
        case "not-found":
            return intl.formatMessage({
                defaultMessage: "Deleted user",
                description: "Message displayed when user connected to object has been deleted",
            });
        default:
            return intl.formatMessage({
                defaultMessage: "Failed to get user",
                description: "Message displayed when service failed to fetch data about user connected to object",
            });
    }
};
