import { useKeyboardShortcut } from "@/lib/tools";
import { ListItemIcon, Radio } from "@mui/material";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFilterHandlers } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { MenuRow } from "../components/MenuRow";

export const ElectricityTypePicker = () => {
    const { formatMessage } = useIntl();
    const { filter: electricity, setFilter: setElectricity } = useFilterHandlers("electricity");

    const [focusList, setFocusList] = useState(false);

    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);
    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);

    return (
        <FilterValueSelect
            title={<FormattedMessage defaultMessage="Electricity calculation method" description="electricity" />}
        >
            <MenuRow
                optionLabel={formatMessage({
                    defaultMessage: "Market-based",
                    description: "Market-based",
                })}
                onClick={() => setElectricity("marketBased")}
            >
                <ListItemIcon>
                    <Radio size="small" edge="start" checked={electricity === "marketBased"} />
                </ListItemIcon>
            </MenuRow>
            <MenuRow
                optionLabel={formatMessage({
                    defaultMessage: "Location-based",
                    description: "Location-based",
                })}
                onClick={() => setElectricity("locationBased")}
            >
                <ListItemIcon>
                    <Radio size="small" edge="start" checked={electricity === "locationBased"} />
                </ListItemIcon>
            </MenuRow>
        </FilterValueSelect>
    );
};
