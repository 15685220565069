import { CARBON_EVENTS, Component } from "@/lib/tracking";
import React, { createContext, useContext } from "react";

export type ViewType = "all" | "1" | "2" | "3";

type DrillDown = {
    viewType: "all" | "1" | "2" | "3";
    setViewType: (viewType: "all" | "1" | "2" | "3") => void;
    chartType: "stacked column" | "donut";
};

const DrillDownContext = createContext<DrillDown | null>(null);

export const DrillDownContextProvider: React.FC<{
    children: React.ReactNode;
    chartType: "stacked column" | "donut";
}> = ({ children, chartType }) => {
    const [viewType, setViewType] = React.useState<"all" | "1" | "2" | "3">("all");

    const value = React.useMemo(() => {
        return { viewType, setViewType, chartType };
    }, [viewType, chartType]);

    return <DrillDownContext.Provider value={value}>{children}</DrillDownContext.Provider>;
};

export const useGetSelectedScope = () => {
    const context = useContext(DrillDownContext);
    if (!context) {
        throw new Error("useGetViewType must be used within a DrillDownContextProvider");
    }
    return context.viewType;
};

export const useSetSelectedScope = () => {
    const context = useContext(DrillDownContext);
    if (!context) {
        throw new Error("useSetViewType must be used within a DrillDownContextProvider");
    }
    const setFilterFunction = (scope: ViewType, component?: Component) => {
        const formatedScope = scope === "all" ? "go back to all scopes" : `Scope ${scope}`;
        CARBON_EVENTS.CHART_ACTION_DRILLDOWN({ page: "Overview", component }, { scope: formatedScope }).track();

        context.setViewType(scope);

        return setFilterFunction;
    };
    return setFilterFunction;
};
