import { Typography } from "@mui/material";

type TitleCellProps = React.PropsWithChildren;

/**
 * Table cell component that displays a title, which is bold. Intended to be used for the primary column of a table.
 *
 * - Design reference: [Figma](https://www.figma.com/design/Tb6GgTuIDhCTiA132VuYT5/Ignite-Riddle?node-id=4127-40709&t=2qlRRhx3DvUeTUkC-4)
 *
 * Props:
 * - `children` (ReactNode): The title to display.
 */
const TitleCell = ({ children }: TitleCellProps) => {
    return (
        <Typography variant="textSm" fontWeight={500} color={(theme) => theme.palette.text["text-primary"]}>
            {children}
        </Typography>
    );
};

export { TitleCell };
export type { TitleCellProps };
