import React, { useMemo, useState } from "react";

import { Autocomplete, TextField, useAutocomplete } from "@mui/material";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import { LISTBOX_PADDING, ListboxComponent } from "./Components/ListBoxComponent";
import { PaperWithFooter } from "./Components/PaperWithFooter";
import { useSearchPaginatedActivityOptions } from "./hooks";
import { StyledPopper } from "./styled";

export type ActivityOption = {
    id: string;
    name: string;
    category: string;
};

type Props = {
    activityId: string | undefined;
    onActivityChange: (activity: ActivityOption | null) => void;
};

export const ActivityPicker: React.FC<Props> = ({ onActivityChange }) => {
    const { formatMessage } = useIntl();

    const [lastFetchMoreLength, setLastFetchMoreLength] = useState<number>(0);
    const { options, setSearchTerm, searchTerm, fetchMore, loading } = useSearchPaginatedActivityOptions();

    const debouncedHandleSearch = React.useMemo<(value: string) => void>(
        () =>
            debounce((value) => {
                setSearchTerm(value);
            }, 500),
        [setSearchTerm]
    );

    const ListBoxProps: ReturnType<ReturnType<typeof useAutocomplete>["getListboxProps"]> = useMemo(
        () => ({
            onScrollCapture: ({ currentTarget: listboxNode }: React.UIEvent<HTMLUListElement>) => {
                const reachedLastToBottom =
                    listboxNode.scrollHeight - listboxNode.scrollTop <= listboxNode.clientHeight + LISTBOX_PADDING;
                if (reachedLastToBottom) {
                    const alreadyFetchedMore = lastFetchMoreLength === options.length;
                    if (!alreadyFetchedMore) {
                        setLastFetchMoreLength(options.length);
                        fetchMore();
                    }
                }
            },
        }),
        [fetchMore, lastFetchMoreLength, options.length]
    );

    return (
        <Autocomplete
            disableListWrap
            sx={{ width: 700 }}
            getOptionLabel={(option) => option.name}
            onInputChange={(_, newInputValue) => {
                debouncedHandleSearch(newInputValue);
            }}
            loading={loading}
            options={options}
            filterOptions={(x) => x}
            renderOption={(props, option) => [props, option, searchTerm] as React.ReactNode}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={formatMessage({
                        defaultMessage: "Activities",
                        description: "Label for activity picker",
                    })}
                />
            )}
            ListboxProps={ListBoxProps}
            ListboxComponent={ListboxComponent}
            PopperComponent={StyledPopper}
            PaperComponent={PaperWithFooter}
            onChange={(_, value) => {
                onActivityChange(value);
            }}
        />
    );
};
