import { graphql } from "@/__generated__";
import { trpc } from "@/lib/trpc/client";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Autocomplete, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { useMutationCleanupFunctions } from "../hooks";

const CreateEmissionsSetupDocument = graphql(`
    mutation DataSourceForms_CreateEmissionsSetup($input: EmissionsSetupInput!) {
        createEmissionsSetup(input: $input) {
            errorMessage
        }
    }
`);

type Props = {
    connectionId?: string;
    postSubmit?: () => void;
};

const useSchema = () => {
    const { formatMessage } = useIntl();
    const schema = z.object({
        yearColumn: z.object(
            {
                dataColumnId: z.string(),
                prefixId: z.string().optional(),
            },
            {
                errorMap: () => ({
                    message: formatMessage({
                        defaultMessage: "Selecting a year column is required",
                        description: "Year column error message in spend-based emissions data source form",
                    }),
                }),
            }
        ),
    });
    return schema;
};

const GetSpendBasedEmissionsSetupOptionsDocument = graphql(`
    query SpendBasedEmissions_getSpendBasedEmissionsSetupOptions {
        getSpendBasedEmissionsSetupOptions {
            yearColumn {
                name
                dataColumnId
            }
        }
    }
`);

export const SpendBasedEmissionsForm: React.FC<Props> = ({ connectionId, postSubmit }) => {
    const editMode = Boolean(connectionId);

    const { formatMessage } = useIntl();
    const [generalErrorMessage, setGeneralErrorMessage] = useState<string | undefined>();

    const { onCreateSuccess, onError, onEditSuccess } = useMutationCleanupFunctions();
    const [createEmissionsSetup, { loading: createEmissionSetupLoading }] = useMutation(CreateEmissionsSetupDocument);
    const editMutation = trpc.editConnection.useMutation({ onError, onSuccess: onEditSuccess });

    const {
        data: dataEmissionSetupOptions,
        loading: loadingEmissionSetupOptions,
        error: errorEmissionSetupOptions,
    } = useQuery(GetSpendBasedEmissionsSetupOptionsDocument);

    const yearColumnOptions = dataEmissionSetupOptions?.getSpendBasedEmissionsSetupOptions?.yearColumn;

    const existingConnections = trpc.addedConnections.useQuery();
    const existingEmissionColumnSetup = existingConnections.data?.data.find(
        (conn): conn is Extract<typeof conn, { type: "spend" }> =>
            conn.id === connectionId && conn.type === "spend" && conn.yearColumn !== undefined
    );

    let defaultYearColumnValue: { name: string; dataColumnId: string } | null = null;
    if (existingEmissionColumnSetup?.yearColumn?.id !== undefined && yearColumnOptions) {
        defaultYearColumnValue =
            yearColumnOptions.find((col) => col.dataColumnId === existingEmissionColumnSetup?.yearColumn?.id) ?? null;
    }

    const schema = useSchema();
    const { control, handleSubmit } = useForm({
        values: {
            yearColumn: defaultYearColumnValue,
        },
        reValidateMode: "onChange",
        resolver: zodResolver(schema),
    });

    const onSubmit = async ({ yearColumn }: z.infer<typeof schema> | { yearColumn: null }) => {
        if (!yearColumn) return;

        if (connectionId && existingEmissionColumnSetup) {
            editMutation.mutate({
                ...existingEmissionColumnSetup,
                yearColumn: {
                    id: yearColumn.dataColumnId,
                    prefixId: yearColumn.prefixId,
                    type: "string",
                },
                id: connectionId,
            });
            postSubmit?.();
            return;
        }

        const res = await createEmissionsSetup({
            variables: {
                input: {
                    yearColumnId: yearColumn.dataColumnId,
                },
            },
        });
        if (res.data?.createEmissionsSetup?.errorMessage) {
            setGeneralErrorMessage(res.data.createEmissionsSetup.errorMessage);
            return;
        }
        onCreateSuccess();
        postSubmit?.();
    };

    if (existingEmissionColumnSetup && !editMode) {
        return (
            <Stack gap={2}>
                <FormattedMessage
                    defaultMessage="You already have setup spend-based emissions, you can only do this once."
                    description="Error message when trying to add a second spend-based emissions connection"
                />
            </Stack>
        );
    }

    if (yearColumnOptions && yearColumnOptions.length === 0 && !loadingEmissionSetupOptions) {
        return (
            <Stack gap={2}>
                <FormattedMessage
                    defaultMessage="We we're unable to find any text fields in your spend table, contact support if this is not the case"
                    description="Message when no text fields are found in spend table for setting up spend-based emissions"
                />
            </Stack>
        );
    }

    if (errorEmissionSetupOptions) {
        return (
            <Stack gap={2}>
                <FormattedMessage
                    defaultMessage="Something happened while fetching the necessary data. Please try again later."
                    description="Error message when fetching data for spend-based emissions setup fails"
                />
            </Stack>
        );
    }

    return (
        <Stack gap={1.5} pt={1} alignItems="center">
            {!yearColumnOptions && <CircularProgress />}
            {yearColumnOptions && (
                <form onSubmit={handleSubmit(onSubmit)} id="spend-based-emissions-form">
                    <Controller
                        control={control}
                        name="yearColumn"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                                size="small"
                                value={value}
                                options={yearColumnOptions}
                                isOptionEqualToValue={(option, value_) => option.dataColumnId === value_.dataColumnId}
                                autoHighlight
                                getOptionLabel={({ name }) => name}
                                onChange={(_, newValue) => onChange(newValue)}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={formatMessage({
                                            defaultMessage:
                                                "Select a column that represents the year of each of your transactions",
                                            description: "Label for year column select",
                                        })}
                                        error={Boolean(error)}
                                        helperText={error?.message ?? ""}
                                    />
                                )}
                            />
                        )}
                    />
                    <Stack direction="row" pt={2}>
                        <Typography variant="body2">
                            <FormattedMessage
                                defaultMessage="When you submit, we will create the necessary classification structures, populate them with groups and rules and add the necessary emissions column to your spend table to enable analytics on emissions."
                                description="Explanation of what happens when creating spend-based emissions through data sources"
                            />
                        </Typography>
                    </Stack>
                    {generalErrorMessage && (
                        <Alert severity="error" sx={{ width: "100%" }}>
                            {generalErrorMessage}
                        </Alert>
                    )}
                    <Stack direction="row" p={1} width="100%" justifyContent="flex-end">
                        <Button type="submit" disabled={!yearColumnOptions || createEmissionSetupLoading}>
                            {!createEmissionSetupLoading && (
                                <FormattedMessage
                                    defaultMessage="Submit"
                                    description="Submit button label used for adding spend-based emissions"
                                />
                            )}
                            {createEmissionSetupLoading && <CircularProgress size={20} />}
                        </Button>
                    </Stack>
                </form>
            )}
        </Stack>
    );
};
