const NUMBER_OF_BUCKETS = 50;

const putNumbersIntoBucketNumber = (high: number, numberForBucket: number) => {
    const bucketSize = Math.floor(high / NUMBER_OF_BUCKETS);
    const bucketNumber = Math.ceil(numberForBucket / bucketSize);
    return bucketNumber;
};

export const getColorFromValue = (value: number, topValue: number) => {
    if (value === 0) {
        return undefined;
    }
    const bucketNumber = putNumbersIntoBucketNumber(topValue, value);
    const percentage = 97 - bucketNumber;
    return `hsla(0, 0%, ${percentage}%, 0.3)`;
};
