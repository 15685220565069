import { Dialog, DialogContent, DialogTitle, IconButton, Stack, DialogContentProps, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
    children: React.ReactNode;
    title: string;
    subTitle?: string;
    onClose: () => void;
    open: boolean;
    actions?: React.ReactNode;
    dialogContentProps?: Partial<DialogContentProps>;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    white?: boolean;
};
export const SimpleDialog: React.FC<Props> = ({
    title,
    subTitle,
    onClose,
    open,
    children,
    actions,
    dialogContentProps,
    maxWidth,
    white,
}) => (
    <Dialog
        fullWidth
        maxWidth={maxWidth || "xl"}
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: { bgcolor: white ? undefined : "#F6F6F7" },
        }}
    >
        <DialogTitle>
            <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {title}
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Typography variant="caption">{subTitle ?? null}</Typography>
            </Stack>
        </DialogTitle>
        <DialogContent {...dialogContentProps}>{children}</DialogContent>
        {actions}
    </Dialog>
);
