import { Company, LocationPin } from "@ignite-analytics/icons";
import { Card, CardContent, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CalculationInformation } from "../CalculationInformation";
import { ContentHeaderText } from "../Components/ContentHeader";
import { EmissionEstimateMeta } from "../types";

type Props = {
    estimates: {
        locationBased: number;
        marketBased: number;
    };
    emissionFactors: {
        locationBased: number;
        marketBased: number;
    };
    meta: EmissionEstimateMeta | null;

    certificate: boolean;
};
export const ElectricityCard: React.FC<Props> = ({ estimates, emissionFactors, meta, certificate }) => {
    const [emissionFactorType, setEmissionFactorType] = useState<"marketBasedFactor" | "locationBasedFactor">(
        "marketBasedFactor"
    );
    const { formatMessage } = useIntl();
    const extraInformation = certificate
        ? [
              formatMessage({
                  defaultMessage:
                      "This activity is covered by a Renewable Energy Certificate (REC) the market based emissions are therefore set to 0 CO₂e.",
              }),
          ]
        : [];
    const emissionFactorValue =
        emissionFactorType === "marketBasedFactor" ? emissionFactors.marketBased : emissionFactors.locationBased;
    return (
        <Card>
            <CardContent>
                <Stack gap={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <ContentHeaderText />

                        <ToggleButtonGroup value={emissionFactorType} exclusive>
                            <ToggleButton
                                aria-label="set-table-view"
                                color="secondary"
                                size="small"
                                sx={{ p: 1 }}
                                value="locationBasedFactor"
                                onClick={() => setEmissionFactorType("locationBasedFactor")}
                            >
                                <LocationPin fontSize="small" />
                            </ToggleButton>
                            <ToggleButton
                                aria-label="set-graph-view"
                                color="secondary"
                                size="small"
                                sx={{ p: 1 }}
                                value="marketBasedFactor"
                                onClick={() => setEmissionFactorType("marketBasedFactor")}
                            >
                                <Company fontSize="small" />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <CalculationInformation
                        estimate={
                            emissionFactorType === "marketBasedFactor" ? estimates.marketBased : estimates.locationBased
                        }
                        metaData={
                            meta
                                ? {
                                      ...meta,
                                      emissionFactorValue,
                                  }
                                : null
                        }
                        extraInformation={extraInformation}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
};
