import { AddActivityForm } from "@/Components/AddActivityForm";
import { AddActivity } from "@/Components/AddActivityForm/AddActivity";
import { SelectCategoryHeader } from "@/Components/AddActivityForm/Components/SelectCategoryHeader";
import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { isEditorOrRedirect } from "@/Router/isEditorOrRedirect";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { scopeCategoryTypes } from "router";
import { activitiesRootRoute } from "..";

export const addActivityRoute = createRoute({
    getParentRoute: () => activitiesRootRoute,
    path: "/add-activity",
    beforeLoad: isEditorOrRedirect,
    component: AddActivityForm,
    errorComponent: ErrorBoundary,
});

export const addActivityFindCategoryRoute = createRoute({
    getParentRoute: () => addActivityRoute,
    path: "/find-category",
    component: SelectCategoryHeader,
    errorComponent: ErrorBoundary,
});

export const addActivityFormRoute = createRoute({
    getParentRoute: () => addActivityRoute,
    path: "/form/$scopeCategory",
    parseParams: ({ scopeCategory }) => {
        const category = z.enum(scopeCategoryTypes).safeParse(scopeCategory);
        if (!category.success) throw new Error("Invalid data source type");
        return {
            scopeCategory: category.data,
        };
    },
    validateSearch: z.object({
        formType: z.enum(["activityL1", "activityL2"]),
        activityL1: z.string().optional(),
        activityL2: z.string().optional(),
        region: z.string().optional(),
        activityYear: z.number().optional(),
        unit: z.string().optional(),
        supplierId: z.string().optional(),
    }),
    component: AddActivity,
    errorComponent: ErrorBoundary,
});
