import { trpc } from "@/lib/trpc/client";
import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { SpendEmissionFactorContent } from "./SpendFactorContent";

export const SpendFactorTable: React.FC = () => {
    const emissionFactorsResponse = trpc.allSpendBasedEmissionFactors.useQuery();
    if (emissionFactorsResponse.isError) {
        return (
            <Alert severity="error" variant="filled">
                <AlertTitle>
                    <FormattedMessage defaultMessage="Error" />
                </AlertTitle>
                <FormattedMessage defaultMessage="Failed to load spend based emission factors" />
            </Alert>
        );
    }
    return (
        <SpendEmissionFactorContent
            loading={emissionFactorsResponse.isLoading}
            emissionFactorData={Array.isArray(emissionFactorsResponse.data) ? emissionFactorsResponse.data : []}
        />
    );
};
