import { Stack, Tooltip, TooltipProps, Typography } from "@mui/material";
import React from "react";

type Props = Omit<TooltipProps, "title" | "arrow"> & {
    header: React.ReactNode;
    subheader: React.ReactNode;
    children: React.ReactNode;
    actions: React.ReactNode;
};

const EmissionIntensityTour: React.FC<Props> = ({ header, actions, children, subheader, open, ...tooltipProps }) => {
    return (
        <Tooltip
            {...tooltipProps}
            arrow
            open={open}
            title={
                <Stack padding={2} spacing={3}>
                    <Stack spacing={2}>
                        <Typography variant="textMd" fontWeight={500}>
                            {header}
                        </Typography>
                        <Typography variant="textXs" fontWeight={400} color={(t) => t.palette.text.textBody}>
                            {subheader}
                        </Typography>
                    </Stack>
                    <Stack spacing={1.5}>{actions}</Stack>
                </Stack>
            }
        >
            {children}
        </Tooltip>
    );
};

export { EmissionIntensityTour };
