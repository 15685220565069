import { useGetSelectedScope } from "@/Providers/DrilldownContext";
import { useGetScope1MessagesRecord } from "@/lib/commonMessages/useGetScope1Messages";
import { useGetScope2MessagesRecord } from "@/lib/commonMessages/useGetScope2Messages";
import { useGetScope3MessagesRecord } from "@/lib/commonMessages/useGetScope3Messages";
import { scope1Types, scope2Types, scope3Types } from "router";
import { useGetScopeMessages } from "../../hooks";

export const useGetSideMenuOptions = () => {
    const selectedScope = useGetSelectedScope();
    const scopeMessages = useGetScopeMessages();
    const scope1Messages = useGetScope1MessagesRecord();
    const scope2Messages = useGetScope2MessagesRecord();
    const scope3Messages = useGetScope3MessagesRecord();

    if (selectedScope === "1") {
        return [
            ...scope1Types.map((scope1Type) => {
                return { label: scope1Messages[scope1Type].header, value: scope1Type };
            }),
        ];
    }
    if (selectedScope === "2") {
        return [
            ...scope2Types.map((scope1Type) => {
                return { label: scope2Messages[scope1Type].header, value: scope1Type };
            }),
        ];
    }
    if (selectedScope === "3") {
        return [
            ...scope3Types.map((scope1Type) => {
                return { label: scope3Messages[scope1Type].header, value: scope1Type };
            }),
        ];
    }

    return [
        { label: scopeMessages.scope1Message, value: "1" as const },
        { label: scopeMessages.scope2Message, value: "2" as const },
        { label: scopeMessages.scope3Message, value: "3" as const },
    ];
};
