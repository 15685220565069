import { Table, TableBody, TableHead } from "@mui/material";
import React from "react";
import { LoadingRows } from "./LoadingRows";

interface Props {
    numberOfRows: number;
    numberOfColumns: number;
}
export const LoadingTable: React.FC<Props> = ({ numberOfRows, numberOfColumns }) => (
    <Table>
        <TableHead>
            <LoadingRows numberOfRows={1} numberOfColumns={numberOfColumns} />
        </TableHead>
        <TableBody>
            <LoadingRows numberOfRows={numberOfRows} numberOfColumns={numberOfColumns} />
        </TableBody>
    </Table>
);
