import { AlertSnackBar } from "@/Components/AlertSnackBar";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Container, styled } from "@mui/material";
import { Outlet, ScrollRestoration } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useBreadcrumbs } from "./useBreadcrumbs";

/**
 * @deprecated use `AppLayout` or `PageLayout` instead
 *
 * Adds a scrolling container by adding max-height and overflow-y: scroll to the container. This is the old setup
 * which adds a ton of scroll bars, but we need this to avoid migrating all pages at once.
 */
const DeprecatedRoot = styled("div")({
    // If the data-new-layout attribute is set to true, we don't want to apply the styles
    "*:where(html[data-new-layout]) &": {
        flex: "inherit",
        display: "inherit",
        flexDirection: "inherit",
    },
    // If the data-new-layout attribute is not set to true, we want to apply the styles
    "*:where(html:not([data-new-layout])) &": {
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
    },
});

/**
 * @deprecated use `AppLayout` or `PageLayout` instead
 *
 * Adds padding to the container. This is the old setup which adds a ton of padding, but we need this to avoid
 * migrating all pages at once.
 *
 */
const DeprecatedContainer = styled(Container)(({ theme }) => ({
    // If the data-new-layout attribute is set to true, we don't want to apply the styles
    "*:where(html[data-new-layout]) &": {
        flex: "inherit",
        display: "inherit",
        flexDirection: "inherit",
        maxWidth: "inherit",
    },
    // If the data-new-layout attribute is set to false, we want to apply the backwards-compatible
    "*:where(html:not([data-new-layout])) &": {
        maxHeight: "100%",
        flex: "1 0 auto",
        padding: `${theme.spacing(1, 2, 0, 2)} !important`,
    },
}));

export const Root = () => {
    useBreadcrumbs();

    /**
     * Feature toggle: [LaunchDarkly](https://app.launchdarkly.com/projects/default/flags/carbon-developers/targeting?env=production&env=test&selected-env=production)
     *
     * Should only ever be true for carbon developers, as it's a feature toggle to hide dev tools.
     */
    const isCarbonDev = useFeatureToggle("carbon-developers");

    return (
        /**
         * @todo Remove DeprecatedRoot and DeprecatedContainer when all components are migrated to either `AppLayout` or `PageLayout`
         */
        <DeprecatedRoot>
            <DeprecatedContainer maxWidth="xl">
                <ScrollRestoration />
                {isCarbonDev && <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />}
                <Outlet />
                <AlertSnackBar />
            </DeprecatedContainer>
        </DeprecatedRoot>
    );
};
