import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Scope2Type } from "router";

export const useGetScope2MessagesRecord = (): Record<
    Scope2Type,
    { header: string; subHeader: string; longSubHeader: string }
> => {
    const { formatMessage } = useIntl();
    return useMemo(
        () => ({
            electricityConsumption: {
                header: formatMessage({
                    defaultMessage: "Electricity consumption",
                    description: "Label for electricity consumption used for scope 2 emissions",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Indirect emissions as a result of the generation of electricity purchased by the company",
                    description: "Label for electricity scope 2 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "Emissions under this category occur indirectly from the generation of electricity that your company purchases. This can be for any purpose - from running machinery and computers to lighting your offices. The level of emissions depends on the mix of energy sources (coal, gas, nuclear, renewables, etc.) used to generate the electricity, which can vary by supplier and region. In order to calculate these emissions, you need to add the amount of electricity consumed for a select region and time-period, and we will calculate the corresponding location- and market-based emissions. If the electricity is for a rented office space or similar, these emissions can also be categorized into Scope 3 Category 8, upstream leased assets, but the standard is to also include these emissions into Scope 2.",
                    description: "Expanded description for Electricity Consumption",
                }),
            },
            electricVehicles: {
                header: formatMessage({
                    defaultMessage: "Electric vehicles",
                    description: "Label for electricVehicles used for scope 2 emissions",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Indirect emissions as a result of the generation of electricity used for charging company electric vehicles",
                    description: "Label for electricVehicles scope 2 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category refers to emissions indirectly generated from the electricity used to charge vehicles operated by the company, if not already counted in the 'Electricity consumption' category. These emissions are considered 'indirect' because the actual emissions occur at the facility where the electricity is produced, which is likely to be away from where the electricity is used (at your charging stations). The emissions generated will vary based on the source of the electricity (e.g., coal, gas, renewable), this category can be used in the cases where you know the distance driven by different sizes of electric vehicles, without knowing the amount of electricity consumed in kWh. If the charging was done on-site at a place where you purchase the electricity, and already included in the 'Electricity consumption', then it should not be doubly counted here.",
                    description: "Expanded description for Electric Vehicles",
                }),
            },
            districtHeating: {
                header: formatMessage({
                    defaultMessage: "District heating",
                    description: "Label for district heating scope 2 emissions select",
                }),
                subHeader: formatMessage({
                    defaultMessage: "Indirect emissions as a result of the generation of heat purchased by the company",
                    description: "Label for district heating scope 2 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category covers indirect emissions that come from the generation of heat, usually in the form of hot water or steam, that your company purchases. This can include heat used for warming buildings or for industrial processes. District heating systems generally produce fewer emissions overall than individual heating systems, but the exact level of emissions for your company depends on the fuel and technology used at the heat plant. The total amount of heat purchased in kWh is used to calculate the corresponding emissions, using the latest emission factors from the UK government (DEFRA). If you have the actual emissions already calculated from your supplier, then those can be inputted instead.",
                    description: "Expanded description for District Heating",
                }),
            },
        }),
        [formatMessage]
    );
};
