import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { Plus } from "@ignite-analytics/icons";
import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
    name: string;
    onClick: () => void;
};

export const UnSelectedTag: React.FC<Props> = ({ name, onClick }) => {
    return (
        <SquareChip color={getChipColor("gray")} onClick={onClick} sx={{ cursor: "pointer" }}>
            <Stack alignItems="center" direction="row" gap={1}>
                <Plus fontSize="inherit" />
                <Typography>
                    <FormattedMessage
                        defaultMessage="Add {name}"
                        description="Add tag"
                        values={{
                            name,
                        }}
                    />
                </Typography>
            </Stack>
        </SquareChip>
    );
};
