import { getCurrentDomain } from "@/lib/utils/utils";
import { useQuery, QueryOptions } from "@tanstack/react-query";

const isMocked = process.env.REACT_APP_INITIALIZE_MOCK;

type PermissionObject = "general" | "privileged";
type PermissionRelation = "read" | "write";
type PermissionNamespace = "general";

type PermissionRelationTuple = {
    object: PermissionObject;
    relation: PermissionRelation;
};

async function checkPermissions(
    namespace: PermissionNamespace,
    relationTuples: PermissionRelationTuple[]
): Promise<boolean[]> {
    if (isMocked) {
        return Array(relationTuples.length).fill(true);
    }
    const authSidecarUrl =
        getCurrentDomain() === "ignite"
            ? process.env.REACT_APP_AUTHSIDECAR_PUBLIC_IGNITE_URL
            : process.env.REACT_APP_AUTHSIDECAR_PUBLIC_IGNITEPROCUREMENT_URL;
    const res = await fetch(`${authSidecarUrl}/api/v1/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    const data: boolean[] = await res.json();
    return Array.isArray(data) ? data : Array(relationTuples.length).fill(false);
}

async function hasPermission(options: { permission: "read" | "write" }): Promise<boolean | undefined> {
    const permissions = await checkPermissions("general", [{ object: "general", relation: options.permission }]);
    return permissions[0];
}

function hasPermissionQueryOptions(options: { permission: "read" | "write" }) {
    const tenant = localStorage.getItem("tenant") || "";
    const queryOptions = {
        queryKey: ["permissions", tenant, options],
        queryFn: async () => hasPermission(options),
    } satisfies QueryOptions;
    return queryOptions;
}

function usePermission(options: { permission: "read" | "write" }): boolean | undefined {
    const { data } = useQuery(hasPermissionQueryOptions(options));

    return data;
}

export { usePermission, hasPermissionQueryOptions };
