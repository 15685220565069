import { getMessageForUnit } from "@/Containers/ManualEmissions/helpers";
import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { DefaultFormType, OptionForUnitOfMeasure } from "../../types";
import { getUnitOfMeasurementsFromOption } from "./helpers";

interface Props {
    activityOption: OptionForUnitOfMeasure;
    loading?: boolean;
}

export const UnitPicker: React.FC<Props> = ({ activityOption, loading }) => {
    const { formatMessage } = useIntl();
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();
    const errorMessage = errors.unitOfMeasurement?.message;
    const unitOfMeasurement = watch("unitOfMeasurement");
    return (
        <FormControl fullWidth size="small" disabled={!activityOption}>
            <Autocomplete
                openOnFocus
                disabled={activityOption.type === "missingLowestActivityLevel"}
                size="small"
                loading={loading}
                value={unitOfMeasurement || null}
                getOptionLabel={(option) => {
                    const message = getMessageForUnit(option);
                    return message ? formatMessage(message) : option;
                }}
                options={getUnitOfMeasurementsFromOption(activityOption)}
                onChange={(_, selectedValue) => {
                    setValue("unitOfMeasurement", selectedValue);
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                "data-testid": "unit-input",
                            }}
                            error={!!errorMessage}
                            placeholder={formatMessage({
                                defaultMessage: "Select a unit",
                                description: "Select a unit",
                            })}
                            label={
                                <FormattedMessage
                                    defaultMessage="Unit of Measurement"
                                    description="Unit of Measurement used for emissions"
                                />
                            }
                            variant="outlined"
                        />
                    );
                }}
                fullWidth
            />
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};
