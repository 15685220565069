import { UploadFileInfo } from "@/Components/FileUploadComponent/FileListItem/UploadFileInfo.model";
import { useAlert } from "@/Providers/Alerts";
import { addActivityForm, findCategory } from "@/Router/DataInputRoute/FormV2";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Activity, activitySchema } from "router";
import { ActivityEstimatesCard } from "../../Components/ActivityEstimates";
import { BasicDetails } from "../../Components/BasicDetails";
import { CompanyStructurePicker } from "../../Components/CompanyStructurePicker";
import { CountryPicker } from "../../Components/CountryPicker";
import { Description } from "../../Components/Description";
import { EnergyCertificate } from "../../Components/EnergyCertificate";
import { FilePicker } from "../../Components/FilePicker";
import { OverlappingData } from "../../Components/RemoveEmissionFromSpend";
import { SpendCategoryPicker } from "../../Components/SpendCategoryPicker";
import { SupplierPicker } from "../../Components/SupplierPicker";
import { TagsRow } from "../../Components/TagsPicker";
import { useGetValidatorSchemaFromUrl } from "../../hooks";
import { DefaultFormType } from "../../types";
import { useGetDefaultValues } from "./helpers";

type Props = {
    onSubmit: (data: Activity, uploadedFiles: UploadFileInfo[] | null) => void;
};

export const AddActivityForm: React.FC<Props> = ({ onSubmit }) => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const { scopeCategory } = useParams({ from: addActivityForm.fullPath });
    const validator = useGetValidatorSchemaFromUrl();

    const hasEmissionIntensities = useFeatureToggle("carbon-emission-intensities");

    const navigate = useNavigate();
    const getDefaultValues = useGetDefaultValues();

    const methods = useForm<DefaultFormType>({
        resolver: zodResolver(validator),
        defaultValues: getDefaultValues(scopeCategory),
    });
    const { handleSubmit, reset, setValue, watch } = methods;

    const tags = watch("tags");

    const from = watch("from");
    const to = watch("to");
    const scopeType = watch("scopeType");

    useEffect(() => {
        if (scopeCategory !== scopeType) {
            const defaultValues = getDefaultValues(scopeCategory);
            reset({ ...defaultValues, tags, to, from });
        }
    }, [from, getDefaultValues, reset, scopeCategory, scopeType, tags, to]);

    const onSubmitForm: SubmitHandler<DefaultFormType> = async (formValues) => {
        const { removeEmissionsFromSpendSource, filter: filterValue, files, ...values } = formValues;
        const parsedActivityData = activitySchema.safeParse({
            ...values,
            removeEmissionsFromSpendSource,
            filter: removeEmissionsFromSpendSource ? filterValue : [],
        });
        if (!parsedActivityData.success) {
            alertUser({
                title: formatMessage({
                    defaultMessage: "Invalid form",
                    description: "Error message for invalid form",
                }),
                value: formatMessage({
                    defaultMessage: "Please fill in all required fields",
                    description: "Error message for missing fields",
                }),

                severity: "error",
            });
            return;
        }
        onSubmit(parsedActivityData.data, files ?? null);
        setValue("value", null);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack spacing={2}>
                    <BasicDetails />
                    <Card>
                        <CardContent>
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Add recommended information"
                                    description="Header for contextual details"
                                />
                            </Typography>
                            <Stack gap={2} paddingTop={2} divider={<Divider />}>
                                <SupplierPicker />
                                <CountryPicker />
                                {hasEmissionIntensities && <CompanyStructurePicker />}
                                {hasEmissionIntensities && <SpendCategoryPicker />}
                                <TagsRow />
                                <FilePicker />
                                <Description />
                                {scopeCategory === "electricityConsumption" && <EnergyCertificate />}
                            </Stack>
                        </CardContent>
                    </Card>
                    <ActivityEstimatesCard />
                    <OverlappingData />
                </Stack>

                <Stack gap={2} direction="row" justifyContent="end" paddingTop={2}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            navigate({ to: findCategory.fullPath });
                        }}
                    >
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button color="primary" type="submit" data-testid="add-activity-button">
                        <FormattedMessage defaultMessage="Confirm and add activity" />
                    </Button>
                </Stack>
            </form>
        </FormProvider>
    );
};
