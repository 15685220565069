import { getMessageForUnit } from "@/Containers/ManualEmissions/helpers";
import { formatNumber } from "@/lib/primitives/numbers";
import { PopoverWithText } from "@/Components/PopoverWithText";
import { Chip, Grid, Stack, Typography, Link } from "@mui/material";
import { Cloud, InformationCircle } from "@ignite-analytics/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { EmissionEstimateMeta } from "../../types";
import { useGetTextForDatabase } from "./hooks";

type Props = {
    estimate: number;
    metaData: EmissionEstimateMeta;
};

export const MetaDataCalculation: React.FC<Props> = ({ estimate, metaData }) => {
    const { formatMessage } = useIntl();
    const unitMessage = getMessageForUnit(metaData.unitOfMeasurement);
    const unit = formatMessage(unitMessage);
    const activityValue = `${metaData.value} ${unit}`;
    const description = useGetTextForDatabase(metaData.emissionFactorSource);

    const sourceRow = [
        {
            title: formatMessage({
                defaultMessage: "Source",
                description: "Source",
            }),
            text: description,
        },
    ];

    const informationRowsForPopover = metaData.emissionFactorLink
        ? [
              ...sourceRow,
              {
                  title: formatMessage({
                      defaultMessage: "Link",
                      description: "link",
                  }),
                  text: (
                      <Link target="_blank" rel="noopener noreferrer" href={metaData.emissionFactorLink}>
                          {metaData.emissionFactorSource}
                      </Link>
                  ),
              },
          ]
        : sourceRow;

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item direction="row">
                <Stack alignItems="start" gap={1}>
                    <Typography variant="textSm" fontWeight={500}>
                        <FormattedMessage defaultMessage="Activity value" />
                    </Typography>
                    <Chip size="large" label={activityValue} sx={{ width: "100%" }} />
                </Stack>
            </Grid>
            <Grid item>
                <Chip size="large" label="X" />
            </Grid>
            <Grid item direction="row">
                <Stack alignItems="start" gap={1}>
                    <Stack direction="row" gap={0.5} alignItems="center">
                        <Typography variant="textSm" fontWeight={500}>
                            <FormattedMessage defaultMessage="Emission factor" />
                        </Typography>
                        <PopoverWithText
                            header={formatMessage({
                                defaultMessage: "Emission factor details",
                                description: "Database information",
                            })}
                            informationRows={informationRowsForPopover}
                        >
                            <InformationCircle fontSize="inherit" sx={{ cursor: "pointer" }} />
                        </PopoverWithText>
                    </Stack>
                    <Chip
                        size="large"
                        label={formatMessage(
                            {
                                defaultMessage: "{factor} kg CO₂e/{unit}",
                                description: "Emission factor",
                            },
                            {
                                factor: formatNumber(metaData.emissionFactorValue, 2),
                                unit,
                            }
                        )}
                        sx={{ width: "100%" }}
                    />
                </Stack>
            </Grid>
            <Grid item>
                <Chip size="large" label="=" />
            </Grid>
            <Grid item>
                <Chip
                    icon={<Cloud fontSize="inherit" />}
                    size="large"
                    label={
                        <FormattedMessage
                            defaultMessage="Estimate: {estimate} kg CO₂e"
                            description="Emission estimate"
                            values={{ estimate: formatNumber(estimate, 2) }}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
};
