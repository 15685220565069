import { RouteButton } from "@/Components/RouteButton";
import { CARBON_EVENTS } from "@/lib/tracking";
import { usePermission } from "@/lib/permissions/usePermissions";
import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationTriangle } from "@ignite-analytics/icons";
import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmissionIntensitySelect } from "./EmissionIntensityCard";

const GetStartedWithEmissionIntensitiesCard: React.FC<{ type: "revenue" | "employees" }> = ({ type }) => {
    const { formatMessage } = useIntl();
    const isEditor = usePermission({ permission: "write" });

    let contentText: string;
    let header: string;
    if (type === "revenue") {
        contentText = formatMessage({
            defaultMessage: "No revenue data yet.",
            description: "Text to get started with revenue emission intensities",
        });
        header = formatMessage({
            defaultMessage: "Emissions per revenue",
            description: "Header for the emission intensity per revenue card",
        });
    } else {
        contentText = formatMessage({
            defaultMessage: "No employees data yet.",
            description: "Text to get started with employees emission intensities",
        });
        header = formatMessage({
            defaultMessage: "Emissions per employee",
            description: "Header for the emission intensity per employee card",
        });
    }

    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={header}
                action={<EmissionIntensitySelect />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text.textSecondary,
                }}
            />
            <CardContent>
                <Stack direction="row" alignItems="center" spacing={3}>
                    <Stack alignItems="center" justifyContent="center">
                        <ShapeIcon size="small" color="warning" variant="outline">
                            <ExclamationTriangle />
                        </ShapeIcon>
                    </Stack>
                    <Typography variant="textSm" color={(t) => t.palette.text.textBody}>
                        {contentText}
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions>
                <RouteButton
                    color="secondary"
                    size="xsmall"
                    disabled={!isEditor}
                    to="/data-preferences/emission-intensities/new/select-intensity"
                    search={(current) => ({ ...current, emissionIntensityType: type })}
                    onClick={() => {
                        CARBON_EVENTS.INTENSITIES_ADD_DATA_CLICKED(
                            { page: "Overview" },
                            { intensityType: type }
                        ).track();
                    }}
                >
                    <FormattedMessage
                        defaultMessage="Add data to get started"
                        description="Label for the button to get started with emission intensities on the emission intensity card on the overview page"
                    />
                </RouteButton>
            </CardActions>
        </Card>
    );
};

export { GetStartedWithEmissionIntensitiesCard };
