import { addActivityFormRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { overviewRoute } from "@/Router/Overview";

import { CARBON_EVENTS, TrackSource } from "@/lib/tracking";

import { useNavigate } from "@tanstack/react-router";
import { SourceConfig } from "../../types";

export const useGetCreateActivityOnClick = (config: SourceConfig, trackSource: TrackSource) => {
    const navigate = useNavigate();
    if (config.type === "supplier") {
        const { year, supplierId, category } = config;
        if (!category || !supplierId) return undefined;
        const yearNumber = Number(year);

        return () => {
            CARBON_EVENTS.CREATE_NEW_ACTIVITY_CLICK(trackSource, {
                viewType: "supplier",
                category,
                year,
            }).track();
            const searchBase =
                year && category
                    ? { activityYear: yearNumber, supplierId, type: category }
                    : { supplierId, type: category };

            navigate({
                from: overviewRoute.fullPath,
                to: addActivityFormRoute.fullPath,
                params: { scopeCategory: category },
                search: { ...searchBase, formType: "activityL1" },
            });
        };
    }

    if (config.type === "emissionsByCategory") {
        const { year, category } = config;
        const yearNumber = Number(year);

        return () => {
            CARBON_EVENTS.CREATE_NEW_ACTIVITY_CLICK(trackSource, {
                category,
                year,
            }).track();
            navigate({
                from: overviewRoute.fullPath,
                to: addActivityFormRoute.fullPath,
                params: { scopeCategory: category },
                search: { activityYear: yearNumber, formType: "activityL1" },
            });
        };
    }

    return undefined;
};
