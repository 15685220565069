import { toCamelCase } from "@ignite-analytics/api-client";
import JwtDecode from "jwt-decode";
import { getCurrentDomain } from "./utils/utils";

interface JWTData {
    userId: string;
    username: string;
    email: string;
    client: 1;
    availableDepartmentRoles: number[];
    activeDepartmentRole: number;
    departments: number[];
    restricted: boolean;
    tenant: string;
}

export async function getUserInfo(): Promise<{ email: string; tenant: string }> {
    const tenant = localStorage.getItem("tenant");
    try {
        const oryUrl =
            getCurrentDomain() === "ignite"
                ? process.env.REACT_APP_ORY_IGNITE_URL
                : process.env.REACT_APP_ORY_IGNITEPROCUREMENT_URL;
        const res = await fetch(`${oryUrl}/sessions/whoami`, { credentials: "include" });
        const j = await res.json();
        return {
            email: j.identity.traits.email,
            tenant: tenant && tenant?.length > 0 ? tenant : j.identity.metadata_public.tenant,
        };
    } catch (e) {
        console?.error(e);
    }

    const jwtToken = localStorage.getItem("token");
    if (!jwtToken) {
        throw new Error("Couldn't validate user");
    }
    const parsedToken = toCamelCase(JwtDecode(jwtToken), false) as JWTData;
    return { email: parsedToken.email, tenant: tenant && tenant?.length > 0 ? tenant : parsedToken.tenant };
}
