import { createRoute } from "@tanstack/react-router";
import { emissionIntensitiesStepLayout } from "../../routes";
import { EnterInformation } from "./EnterInformation";

const enterInformationRoute = createRoute({
    getParentRoute: () => emissionIntensitiesStepLayout,
    path: "/$emissionIntensityId/enter-information",
    component: EnterInformation,
});

export { enterInformationRoute };
