import { SpendFormReturn, SpendReturnValue } from "@/Router/interfaces";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { Stack, TextField } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SpendDataTableConnectionForm } from "..";
import { TablePicker } from "../Components/TablePicker";
import { SpendFormType } from "../DataTableConnectionForms/interfaces";
import { useFetchConnectionOptions, useMutationCleanupFunctions } from "../hooks";

type Props = {
    connectionId?: string;
    postSubmit?: () => void;
};

export const SpendForm: React.FC<Props> = ({ connectionId, postSubmit }) => {
    const editMode = !!connectionId;
    const { onCreateSuccess, onError, onEditSuccess } = useMutationCleanupFunctions();
    const { tables, dataColumns, selectedTable, tableId } = useFetchConnectionOptions();
    const { formatMessage } = useIntl();
    const addMutation = trpc.addConnection.useMutation({ onError, onSuccess: onCreateSuccess });
    const editMutation = trpc.editConnection.useMutation({ onError, onSuccess: onEditSuccess });
    const onSubmit = (data: SpendReturnValue) => {
        if (connectionId) {
            editMutation.mutate({ ...data, id: connectionId });
            postSubmit?.();
            return;
        }
        addMutation.mutate(data);
        postSubmit?.();
    };

    const addedConns = trpc.addedConnections.useQuery();
    const spendHasBeenAdded =
        addedConns.status === "success" ? addedConns.data.data.some((conn) => conn.type === "spend") : undefined;
    const connection = addedConns.data?.data.find(
        (conn): conn is Extract<typeof conn, { type: "spend" }> => conn.id === connectionId && conn.type === "spend"
    );
    const missingTableMessage = formatMessage({
        defaultMessage: "Please select a table first",
        description: "Message to show when no table is selected",
    });
    const columns = dataColumns.data?.dataColumns;
    const findMatchInColumns = useCallback(
        <T extends string>({ id, prefixId, type }: { id: string; prefixId?: string; type: T }) =>
            columns?.find(
                (col): col is Extract<typeof col, { type: T }> =>
                    col.dataColumnId === id && col.prefixId === prefixId && col.type === type
            ),
        [columns]
    );
    const defaultValuesForForm: Partial<SpendFormType> | undefined = useMemo(() => {
        if (!connection) return undefined;
        return {
            dateColumn: findMatchInColumns(connection.dateColumn),
            spendColumn: findMatchInColumns(connection.spendColumn),
            emissionFactorColumn: findMatchInColumns(connection.emissionFactorColumn),
            emissionStructureColumn: findMatchInColumns(connection.emissionStructureColumn),
            supplierColumn: connection.supplierColumn && findMatchInColumns(connection.supplierColumn),
            tagColumns: connection?.tagColumns,
        };
    }, [connection, findMatchInColumns]);

    if (spendHasBeenAdded && !editMode) {
        return (
            <Stack gap={2}>
                <FormattedMessage
                    defaultMessage="You already have a data source based on spend or supplier number, you can only add one"
                    description="Error message when trying to add a second connection"
                />
            </Stack>
        );
    }

    return (
        <Stack gap={1}>
            <TablePicker tableId={tableId} globalType="spend_table" tables={tables} />
            {columns && selectedTable ? (
                <SpendDataTableConnectionForm
                    onSubmitForm={(data: SpendFormReturn) => {
                        onSubmit({ ...data, name: selectedTable.name, tableId: selectedTable.dataTableId });
                        CARBON_EVENTS.DATA_PREFERENCES_CONNECTION_ADDED(
                            {
                                page: "DataPreferences",
                                component: "SpendForm",
                            },
                            {
                                type: "spend",
                                supplierConnection: !!data.supplierColumn,
                            }
                        ).track();
                    }}
                    columns={columns}
                    defaultValues={defaultValuesForForm}
                    tableId={selectedTable.dataTableId}
                />
            ) : (
                Array.from(Array(5).keys()).map((i) => (
                    <TextField key={i} disabled variant="outlined" label={missingTableMessage} size="small" />
                ))
            )}
        </Stack>
    );
};
