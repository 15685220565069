import { trpc } from "@/lib/trpc/client";
import { CATEGORY_WITHOUT_L1 } from "@/Components/FormV2/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { InfoRounded } from "@mui/icons-material";
import { Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { CustomCategory, CustomCategoryInput, customCategorySchema, isActivityInformation } from "router";
import { ActivityL1Select } from "./Components/ActivityL1Select";
import { ScopeSelect } from "./Components/ScopeSelect";
import { useGetFormHandlers } from "./hooks";

type Props = {
    existingCategory?: CustomCategory;
    refetchCallback: () => void;
};

export const CustomCategoryForm: React.FC<Props> = ({ existingCategory, refetchCallback }) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<CustomCategoryInput>({
        resolver: zodResolver(customCategorySchema),
        defaultValues: existingCategory ?? undefined,
    });

    const { handleSuccess, handleError } = useGetFormHandlers(existingCategory ? "edit" : "create", refetchCallback);
    const scopeSupportsL2Activities = !CATEGORY_WITHOUT_L1.includes(watch("scope"));

    const createMutation = trpc.addCustomCategory.useMutation({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    const editMutation = trpc.editCustomCategory.useMutation({
        onSuccess: handleSuccess,
        onError: handleError,
    });

    const editMode = !!existingCategory;

    const onSubmit = async (data: CustomCategoryInput) => {
        if (editMode) {
            editMutation.mutate({ id: existingCategory.id, ...data });
            return;
        }

        createMutation.mutate(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <Grid padding={2}>
                        <TextField
                            label="Name"
                            variant="standard"
                            size="small"
                            sx={{ width: "100%" }}
                            {...field}
                            error={!!errors.name}
                            autoFocus
                            helperText={errors.name?.message}
                        />
                    </Grid>
                )}
            />
            <Controller
                name="scope"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Grid padding={2}>
                        <ScopeSelect
                            onChange={(newValue) => onChange(newValue?.value ?? null)}
                            errorMessage={errors.scope?.message}
                            disabled={!watch("name")}
                            value={value}
                        />
                    </Grid>
                )}
            />

            {scopeSupportsL2Activities && (
                <>
                    <Grid padding={2}>
                        <Paper
                            elevation={2}
                            sx={{ backgroundColor: "InfoBackground" }}
                            style={!watch("scope") ? { display: "none" } : {}}
                        >
                            <Stack direction="row" spacing={2} padding={2}>
                                <InfoRounded color="primary" />
                                <Typography variant="textMd">
                                    <FormattedMessage
                                        defaultMessage="To create a category for L2 activities, you need to select a L1 activity first."
                                        description="Create info"
                                    />
                                </Typography>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Controller
                        name="activityL1"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Grid padding={2}>
                                <ActivityL1Select
                                    onChange={(newValue) => isActivityInformation(newValue) && onChange(newValue)}
                                    errorMessage={errors.activityL1?.message}
                                    selectedValue={value}
                                    disabled={!watch("scope")}
                                    scope={watch("scope")}
                                />
                            </Grid>
                        )}
                    />
                </>
            )}

            <Stack direction="row" justifyContent="flex-end" padding={2}>
                <Button type="submit">
                    {existingCategory ? (
                        <FormattedMessage defaultMessage="Save" description="Save button edit category" />
                    ) : (
                        <FormattedMessage defaultMessage="Create" description="Create button new category" />
                    )}
                </Button>
            </Stack>
        </form>
    );
};
