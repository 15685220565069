import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { StatusIndicator } from "../dumb/StatusIndicator";
import { UploadFileInfo } from "./UploadFileInfo.model";
import { UploadFileStatus } from "./UploadFileStatus.enum";
import { fileHasError } from "./helpers";

interface Props {
    file: UploadFileInfo;
    onDeleteFile: (file: UploadFileInfo) => void;
    disabled?: boolean;
}

const FileListItem = ({ file, onDeleteFile: deleteFile, disabled = false }: Props) => {
    const getFileUrl = (fileInfo: UploadFileInfo) => {
        return fileInfo.url || (fileInfo.file && window.URL.createObjectURL(new Blob([fileInfo.file])));
    };

    /* Creates a downloadable action in the browser for the file-link and executes it. */
    const downloadFile = (selectedFileInfo: UploadFileInfo) => {
        const url = getFileUrl(selectedFileInfo);
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${selectedFileInfo.name}`);
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noreferrer noopener");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    };

    return (
        <Grid display="flex" alignItems="center" justifyContent="space-between" bgcolor="background.default" px={1}>
            <Box sx={{ alignItems: "center", flex: 1, overflow: "hidden", display: "flex" }}>
                <Tooltip title={file.name} placement="top-start">
                    <Typography variant="textMd">
                        {file.status === UploadFileStatus.Selected && file.url ? (
                            <a href={file.url} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                                {file.name}
                            </a>
                        ) : (
                            file.name
                        )}
                    </Typography>
                </Tooltip>

                <Box alignItems="center" sx={{ pl: 1 }}>
                    <StatusIndicator file={file} />
                </Box>
            </Box>

            <Grid justifyContent="flex-end" display="flex">
                {!fileHasError(file) && file.status !== UploadFileStatus.Uploading && (
                    <IconButton data-testid="file-download-button" size="small" onClick={() => downloadFile(file)}>
                        <FileDownloadIcon fontSize="small" />
                    </IconButton>
                )}
                {!disabled && (
                    <IconButton data-testid="file-delete-button" size="small" onClick={() => deleteFile(file)}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

export default FileListItem;
