import { TagColumnPicker } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/TagsColumnPicker/SinglePicker";
import { editSourceOptionsForTagRoute, overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { FilterField, UnifiedReturnDataSource, filterField } from "router";
import { ExamplesFromSource } from "../../ExamplesFromSource";
import { EditMenu } from "../SideMenu";
import { DIALOG_HEIGHT } from "../constants";

type Field = { tagField: FilterField | null };

type Props = {
    source: UnifiedReturnDataSource;
    tagId: string;
    name: string;
};

const EditOptionsForm: React.FC<Props> = ({ source, tagId, name }) => {
    const { formatMessage } = useIntl();
    const connectionRef = React.useRef(source);
    const navigate = useNavigate();
    const utils = trpc.useContext();
    const editSource = trpc.editConnection.useMutation();
    const editSupplierConnection = trpc.editSupplierConnection.useMutation();
    const { control, handleSubmit, reset, watch } = useForm<Field>({
        resolver: zodResolver(
            z.object({
                tagField: filterField.nullable(),
            })
        ),
        defaultValues: { tagField: source.tagColumns?.[tagId] ?? undefined },
    });

    React.useEffect(() => {
        if (source.id !== connectionRef.current.id) {
            reset({ tagField: source.tagColumns?.[tagId] ?? null });
            connectionRef.current = source;
        }
    }, [source, reset, tagId]);

    const fields = trpc.filterFields.useQuery(
        source.discriminator === "supplier" ? { type: "supplier" } : { type: "connection", connectionId: source.id }
    );
    const fieldValue = watch("tagField");
    const sourceName = source.discriminator === "supplier" ? "Supplier" : (source.name ?? "");

    const onSubmit = async (field: Field | undefined) => {
        const tagColumnValue = { ...(source.tagColumns || {}) };
        if (field?.tagField) {
            tagColumnValue[tagId] = field.tagField;
        } else {
            delete tagColumnValue[tagId];
        }
        if (source.discriminator === "connection") {
            await editSource.mutateAsync({
                ...source,
                tagColumns: tagColumnValue,
            });
        }
        if (source.discriminator === "supplier") {
            await editSupplierConnection.mutateAsync({
                ...source,
                tagColumns: tagColumnValue,
            });
        }
        utils.getEmissionTagsWithOptions.cancel();
        utils.getEmissionTagsWithOptions.refetch();
        reset();
        navigate({ to: overviewTagRoute.fullPath });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{ height: DIALOG_HEIGHT }}>
                <EditMenu name={name} id={tagId}>
                    <Stack gap={1}>
                        <Typography variant="subtitle2">
                            <FormattedMessage
                                defaultMessage="Select {name} tag column from {sourceName}"
                                description="Select tag column from source"
                                values={{
                                    name,
                                    sourceName,
                                }}
                            />
                        </Typography>
                        <Controller
                            name="tagField"
                            control={control}
                            render={({ field: { onChange } }) => {
                                return (
                                    <TagColumnPicker
                                        tagLabel={formatMessage(
                                            {
                                                defaultMessage: "{tagName} field from {connectionName}",
                                                description: "Tag name and connection name",
                                            },
                                            {
                                                tagName: name,
                                                connectionName: sourceName,
                                            }
                                        )}
                                        onChange={(changeValue) => {
                                            onChange(changeValue || null);
                                        }}
                                        value={fieldValue ?? null}
                                        loading={fields.isLoading}
                                        fields={fields?.data?.filterFields ?? []}
                                        placeholder={formatMessage({
                                            defaultMessage: "My example field",
                                            description: "My example field",
                                        })}
                                    />
                                );
                            }}
                        />
                        <Stack gap={1}>
                            <Typography variant="subtitle2">
                                <FormattedMessage defaultMessage="Samples from field" description="Add tag options" />
                            </Typography>
                            {fieldValue ? (
                                <ExamplesFromSource source={source} field={fieldValue} />
                            ) : (
                                <Typography variant="textSm" color="gray">
                                    <FormattedMessage
                                        defaultMessage="You need to select a  a field to see samples"
                                        description="Select a field to see examples"
                                    />
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </EditMenu>
            </DialogContent>
            <DialogActions>
                <Button type="submit">
                    <FormattedMessage defaultMessage="Save changes" description="Save button for the Tags component" />
                </Button>
            </DialogActions>
        </form>
    );
};

export const EditOptionsFromSource = () => {
    const { name, id } = useSearch({ from: editSourceOptionsForTagRoute.id });
    const { sourceType, sourceId } = useParams({ from: editSourceOptionsForTagRoute.id });

    const source = trpc.getUnifiedDataSources.useQuery(
        sourceType === "supplier" ? { sourceType: "supplier" } : { sourceType: "connection", sourceId }
    );

    if (source.isSuccess) {
        return <EditOptionsForm name={name} source={source.data[0]} tagId={id} />;
    }
    if (source.isLoading) {
        return (
            <Stack>
                <DialogContent sx={{ height: DIALOG_HEIGHT + 65 }}>
                    <EditMenu name={name} id={id}>
                        <Stack gap={1}>
                            <Typography variant="subtitle2">
                                <FormattedMessage
                                    defaultMessage="Loading data source"
                                    description="Loading data source"
                                />
                            </Typography>
                            <TextField size="small" sx={{ minWidth: "100%" }} disabled />
                        </Stack>
                    </EditMenu>
                </DialogContent>
            </Stack>
        );
    }
    return (
        <Typography variant="textMd" color="error">
            <FormattedMessage defaultMessage="Error loading data source" description="Error loading data source" />
        </Typography>
    );
};
