import { trpc } from "@/lib/trpc/client";
import React, { useMemo } from "react";

import { Stack, Typography, Card, CardContent } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { ActivityL1Picker } from "../L1Picker";
import { DateRangePickerComponent } from "../DateRangePicker";
import { ActivityL2Picker } from "../L2Picker";
import { UnitPicker } from "../UnitPicker";
import { ValuePicker } from "../ValuePicker";
import { DefaultFormType } from "../../types";
import { getActivityOption } from "./helpers";

export const BasicDetails: React.FC = () => {
    const {
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();

    const options = trpc.getActivityOptions.useQuery();
    const scopeCategory = watch("scopeType");
    const activityL1 = watch("activityL1");
    const activityL2 = watch("activityL2");

    const optionsForL1 = useMemo(
        () => options.data?.data?.filter((option) => option.category === scopeCategory) ?? [],
        [options.data, scopeCategory]
    );
    const optionsForL2 = useMemo(() => {
        if (!activityL1) {
            return [];
        }
        return optionsForL1
            .filter((innerActivity) => innerActivity.activityL1?.value === activityL1.value)
            .filter((activity) => activity.activityL2);
    }, [optionsForL1, activityL1]);

    if (!scopeCategory) {
        return null;
    }
    return (
        <Card variant="outlined">
            <CardContent>
                <Stack gap={1}>
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Fill in basic details"
                            description="Header for basic details"
                        />
                    </Typography>
                    <DateRangePickerComponent />
                    <Stack direction="row" gap={2}>
                        <Stack width={optionsForL2.length ? "50%" : "100%"}>
                            <ActivityL1Picker
                                loading={options.isLoading}
                                activityOptions={optionsForL1}
                                scopeCategory={scopeCategory}
                                errorMessage={errors.activityL1?.message}
                            />
                        </Stack>
                        {optionsForL2.length !== 0 && (
                            <Stack width="50%">
                                <ActivityL2Picker
                                    activityOptions={optionsForL2}
                                    loading={options.isLoading}
                                    scopeCategory={scopeCategory}
                                    errorMessage={errors.activityL2?.message}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Stack width="50%">
                            <ValuePicker />
                        </Stack>
                        <Stack width="50%">
                            <UnitPicker
                                activityOption={getActivityOption(optionsForL1, activityL1, activityL2)}
                                loading={options.isLoading}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
