import { SourceFilterType } from "@/Containers/LandingPage/Filter";
import { useGetSearchParamFilters } from "@/Containers/LandingPage/hooks";
import { useAlert } from "@/Providers/Alerts";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { FileArrowDown } from "@ignite-analytics/icons";
import { Button } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReportOption } from "router";
import { deliverReport } from "./helpers";

export const DownloadReport: React.FC = () => {
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();
    const { electricity, ...filters } = useGetSearchParamFilters();
    const [busy, setBusy] = React.useState(false);

    const onCreateReportSuccess = ({ base64Data, mimeType, reportType, createdAt }) => {
        deliverReport(base64Data, mimeType, reportType, createdAt, "xlsx");
        CARBON_EVENTS.EMISSIONS_REPORT_DOWNLOADED(
            { page: "Overview", component: "DownloadReport" },
            {
                reportType,
                electricityBase: electricity,
                exportedWithTagFilter: Boolean(filters.tagFilters?.length),
                exportedWithSourceFilter: Boolean(filters.sources?.length),
                exportedActivityData:
                    filters.sources?.some(({ filterType }) => filterType === SourceFilterType.ACTIVITY_DATA) ?? false,
                exportedWithCompanyStructureFilter: Boolean(filters.businessUnitIds?.length),
                exportedWithSpendCategoryFilter: Boolean(filters.spendCategoryIds?.length),
                exportedWithSupplierFilter: Boolean(filters.supplierIds?.length),
            }
        ).track();
    };
    const onCreateReportError = (error) => {
        setBusy(false);
        alertUser({
            value: error.message,
            title: formatMessage({
                defaultMessage: "Error creating report",
                description: "Error creating report message",
            }),
            severity: "error",
        });
    };
    const createReport = trpc.createReport.useMutation({
        onError: onCreateReportError,
        onSuccess: onCreateReportSuccess,
        onSettled() {
            setBusy(false);
        },
    });
    const downloadReport = async (type: ReportOption) => {
        if (busy) return;
        setBusy(true);
        createReport.mutate({ reportType: type, format: "xlsx", electricityBase: electricity, filters });
    };

    return (
        <Button
            size="medium"
            color="secondary"
            onClick={() => downloadReport("EmissionsOverview")}
            startIcon={<FileArrowDown />}
        >
            <FormattedMessage defaultMessage="Download" description="Export emissions overview message" />
        </Button>
    );
};
