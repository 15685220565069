/* eslint-disable react/jsx-no-literals */
import { formatNumber } from "@/lib/primitives/numbers";
import { LocationPin, SquareBolt } from "@ignite-analytics/icons";
import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import type { Emissions } from "../../Containers/ActivityLog/Table/RenderFunctions/types";

type Props = {
    emissions: Emissions;
};

export const EstimatesCell: React.FC<Props> = ({ emissions }) => {
    return emissions.type === "regular" ? (
        <Typography variant="textSm">
            <FormattedMessage
                defaultMessage="{amount} tCO₂e"
                description="Total CO2"
                values={{ amount: emissions.estimate ? formatNumber(emissions.estimate / 1000, 2) : 0.0 }}
            />
        </Typography>
    ) : (
        <Stack alignItems="center" justifyContent="end" direction="row" gap={1} height="100%">
            <Tooltip
                placement="bottom"
                title={<FormattedMessage defaultMessage="Location based" description="Location based" />}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <LocationPin fontSize="inherit" />
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="{amount} tCO₂e"
                            description="Total CO2"
                            values={{
                                amount: emissions.estimateLocation
                                    ? formatNumber(emissions.estimateLocation / 1000, 2)
                                    : 0.0,
                            }}
                        />
                    </Typography>
                </Stack>
            </Tooltip>
            <Tooltip
                placement="bottom"
                title={<FormattedMessage defaultMessage="Market based" description="Market based" />}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <SquareBolt fontSize="inherit" />
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="{amount} tCO₂e"
                            description="Total CO2"
                            values={{
                                amount: emissions.estimateMarket
                                    ? formatNumber(emissions.estimateMarket / 1000, 2)
                                    : 0.0,
                            }}
                        />
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>
    );
};
