import { createCustomOptionsForTagRoute, overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { EmissionDataTag } from "router";
import { CustomOptionsForm } from "../../Components/CustomOptionsForm";
import { useGetCleanupFunctions } from "../../hooks";

export const CreateCustomOptions = () => {
    const { onSuccess } = useGetCleanupFunctions();
    const { name } = useSearch({ from: createCustomOptionsForTagRoute.fullPath });
    const navigate = useNavigate();
    const addTag = trpc.addEmissionTag.useMutation({
        onSuccess,
    });

    const onSubmit = async (tag: Omit<EmissionDataTag, "id">) => {
        CARBON_EVENTS.CREATED_NEW_SET_OF_TAGS(
            { page: "DataPreferences", component: "Tags" },
            { customOrSource: "custom" }
        ).track();
        addTag.mutate({ tag });
        navigate({ to: overviewTagRoute.fullPath });
    };
    return (
        <CustomOptionsForm
            name={name}
            setName={(newName) => {
                navigate({ search: (prev) => ({ ...prev, name: newName }) });
            }}
            submit={onSubmit}
        />
    );
};
