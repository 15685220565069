import { track as trackEvent } from "@ignite-analytics/track";

const prefix = "Carbon: ";
type Page = "Overview" | "ActivityForm" | "ActivityLog" | "DataPreferences" | "EmissionFactors" | "NoData";

export type Component =
    | "ChartDonut"
    | "ChartGraph"
    | "ConsolidatedTopSuppliersList"
    | "TopSupplierEmissionsPerYearTable"
    | "OverviewTable"
    | "EmissionsByYearChart"
    | "EmissionOverYearsCard"
    | "PieChart"
    | "ActivityDocsAndNotes"
    | "SimpleActivityNotes"
    | "SimpleFileUpload"
    | "AISearch"
    | "ActivityCategoryHeader"
    | "DownloadReport"
    | "OtherTableForm"
    | "SpendForm"
    | "DataSources"
    | "CustomCategories"
    | "LatestActivitiesCard"
    | "FilterModal"
    | "AddActivityButton"
    | "HeaderButton"
    | "Tags"
    | "NoActivitiesButton"
    | "TopContributorsTable";

type EventName =
    | "FILE_UPLOADED"
    | "SEE_ALL_ACTIVITIES_CLICK"
    | "BREAKDOWN_OF_SOURCES_CLICK"
    | "CREATE_NEW_ACTIVITY_CLICK"
    | "GO_TO_SUPPLIER_CARD_CLICK"
    | "NEED_HELP_CLICK"
    | "VIEW_ALL_ACTIVITIES_CLICK"
    | "RECENT_ACTIVITY_CLICK"
    | "ADD_ACTIVITY_DATA_CLICKED"
    | "OVERVIEW_TOP_SUPPLIER_TABLE_PAGE_VIEWED"
    | "OVERVIEW_USED_FILTERS"
    | "CUSTOM_EMISSION_FACTOR_DELETED"
    | "VIEW_EMISSION_FACTORS_CLICK"
    | "EMISSION_FACTOR_ADD_AS_ACTIVITY_CLICKED"
    | "CREATED_NEW_SET_OF_TAGS"
    | "ADDED_TAGS_FROM_SOURCE"
    | "CREATED_CUSTOM_OPTION_FOR_TAG"
    | "DELETED_CUSTOM_OPTION_FOR_TAG"
    | "CHART_ACTION_DRILLDOWN"
    | "CUSTOM_CATEGORY_USED"
    | "CUSTOM_CATEGORY_CREATED"
    | "CUSTOM_CATEGORY_CONNECTIONS_DELETED"
    | "CUSTOM_CATEGORY_DELETED"
    | "ACTIVITY_ADDED_WITH_TAGS"
    | "ACTIVITY_DATA_ADDED"
    | "ACTIVITY_CATEGORY_DESCRIPTION_SHOW_MORE_CLICK"
    | "ACTIVITY_CATEGORY_DESCRIPTION_SHOW_LESS_CLICK"
    | "ACTIVITY_UPDATE"
    | "ACTIVITIES_DELETED"
    | "ACTIVITY_LOG_PAGE_VIEWED"
    | "AI_SEARCH_SELECTED_CATEGORY"
    | "AI_SEARCH_USED"
    | "LANDING_PAGE_TABLE_SIZE_CHANGED"
    | "LANDING_PAGE_EMISSIONS_PR_YEAR_TAB_CHANGED"
    | "LANDING_PAGE_VIEW_ALL_TOP_CONTRIBUTORS_CLICKED"
    | "LANDING_PAGE_LOADED"
    | "LANDING_PAGE_RECENT_ACTIVITY_CLICKED"
    | "DATA_PREFERENCES_DRIVING_LOG_FILE_UPLOADED"
    | "DATA_PREFERENCES_CONNECTION_ADDED"
    | "DATA_PREFERENCES_CONNECTION_EDITED"
    | "DATA_PREFERENCES_PAGE_VIEWED"
    | "EMISSIONS_REPORT_DOWNLOADED"
    | "COMMENTS_NEW_COMMENT_ADDED"
    | "OVERVIEW_TOP_CONTRIBUTORS_TABLE_PAGE_VIEWED"
    | "INTENSITIES_LEARN_MORE"
    | "INTENSITIES_SWITCH_LEVEL"
    | "INTENSITIES_ADD_CUSTOM"
    | "INTENSITIES_ADD_DATA_CLICKED"
    | "INTENSITIES_STARTED_FLOW"
    | "INTENSITIES_COMPLETED_FLOW"
    | "INTENSITIES_TYPE_SWITCHED"
    | "INTENSITIES_EDIT_CLICKED"
    | "PIECHART_BREAKDOWN_CHANGED";

export type TrackSource = {
    page: Page;
    component?: Component;
};
type CarbonEventFunc = (source: TrackSource, data?: Record<string, unknown>) => CarbonEvent;

const EVENT_NAMES: Record<EventName, `${typeof prefix}${string}`> = {
    // File upload
    FILE_UPLOADED: `${prefix}file uploaded`,

    SEE_ALL_ACTIVITIES_CLICK: `${prefix}"See all activities" clicked`,
    BREAKDOWN_OF_SOURCES_CLICK: `${prefix}"Breakdown of sources" clicked`,
    CREATE_NEW_ACTIVITY_CLICK: `${prefix}"Create new activity" clicked`,
    GO_TO_SUPPLIER_CARD_CLICK: `${prefix}"Go to supplier card" clicked`,
    NEED_HELP_CLICK: `${prefix}"Need help?" clicked'`,
    VIEW_ALL_ACTIVITIES_CLICK: `${prefix}"View all activities" clicked'`,
    RECENT_ACTIVITY_CLICK: `${prefix}recent activity clicked`,
    ADD_ACTIVITY_DATA_CLICKED: `${prefix}"Add activity data" clicked`,

    // Page view events
    OVERVIEW_TOP_SUPPLIER_TABLE_PAGE_VIEWED: `${prefix}overview top supplier table page viewed`,
    OVERVIEW_TOP_CONTRIBUTORS_TABLE_PAGE_VIEWED: `${prefix}overview top contributors table page viewed`,
    OVERVIEW_USED_FILTERS: `${prefix}used filters in overview page`,

    // Emission factors events
    CUSTOM_EMISSION_FACTOR_DELETED: `${prefix}custom factor deleted`,
    VIEW_EMISSION_FACTORS_CLICK: `${prefix}"View emission factors" clicked'`,
    EMISSION_FACTOR_ADD_AS_ACTIVITY_CLICKED: `${prefix}"Add as activity" clicked`,

    // Tags events
    CREATED_NEW_SET_OF_TAGS: `${prefix}created new set of tags`,
    ADDED_TAGS_FROM_SOURCE: `${prefix}added tags from a source`,
    CREATED_CUSTOM_OPTION_FOR_TAG: `${prefix}created custom option for tag`,
    DELETED_CUSTOM_OPTION_FOR_TAG: `${prefix}deleted custom option for tag`,

    // Chart events
    CHART_ACTION_DRILLDOWN: `${prefix}drilldown in chart`,
    PIECHART_BREAKDOWN_CHANGED: `${prefix}breakdown changed`,

    // Custom category events
    CUSTOM_CATEGORY_USED: `${prefix}used custom category`,
    CUSTOM_CATEGORY_CREATED: `${prefix}custom category created`,
    CUSTOM_CATEGORY_CONNECTIONS_DELETED: `${prefix}connections deleted`,
    CUSTOM_CATEGORY_DELETED: `${prefix}custom category deleted`,

    // Activity events
    ACTIVITY_ADDED_WITH_TAGS: `${prefix}added activity with tags`,
    ACTIVITY_DATA_ADDED: `${prefix}activity data added`,
    ACTIVITY_CATEGORY_DESCRIPTION_SHOW_MORE_CLICK: `${prefix}clicked 'show more' for category description`,
    ACTIVITY_CATEGORY_DESCRIPTION_SHOW_LESS_CLICK: `${prefix}clicked 'show less' for category description`,
    ACTIVITY_UPDATE: `${prefix}edit manual data`,
    ACTIVITIES_DELETED: `${prefix}activities deleted`,
    ACTIVITY_LOG_PAGE_VIEWED: `${prefix}activity log page viewed`,

    // AI search
    AI_SEARCH_SELECTED_CATEGORY: `${prefix}selected category after AI search`,
    AI_SEARCH_USED: `${prefix}used AI-based category search`,

    // Landing page
    LANDING_PAGE_TABLE_SIZE_CHANGED: `${prefix}table size changed`,
    LANDING_PAGE_EMISSIONS_PR_YEAR_TAB_CHANGED: `${prefix}"Emissions per year" tab changed'`,
    LANDING_PAGE_VIEW_ALL_TOP_CONTRIBUTORS_CLICKED: `${prefix}"view all contributors" clicked`,
    LANDING_PAGE_LOADED: `${prefix}landing page loaded`,
    LANDING_PAGE_RECENT_ACTIVITY_CLICKED: `${prefix}recent activity clicked`,

    DATA_PREFERENCES_DRIVING_LOG_FILE_UPLOADED: `${prefix}driving log file uploaded`,
    DATA_PREFERENCES_CONNECTION_ADDED: `${prefix}connection added`,
    DATA_PREFERENCES_CONNECTION_EDITED: `${prefix}connection edited`,
    DATA_PREFERENCES_PAGE_VIEWED: `${prefix}data preferences page viewed`,

    EMISSIONS_REPORT_DOWNLOADED: `${prefix}report downloaded`,
    COMMENTS_NEW_COMMENT_ADDED: `${prefix}new comment added`,

    INTENSITIES_LEARN_MORE: `${prefix}intensities learn more clicked`,
    INTENSITIES_ADD_CUSTOM: `${prefix}custom intensity added`,
    INTENSITIES_SWITCH_LEVEL: `${prefix}intensity level switched`,
    INTENSITIES_STARTED_FLOW: `${prefix}intensities flow started`,
    INTENSITIES_COMPLETED_FLOW: `${prefix}intensities flow completed`,
    INTENSITIES_ADD_DATA_CLICKED: `${prefix}intensities add data clicked`,
    INTENSITIES_TYPE_SWITCHED: `${prefix}type switched`,
    INTENSITIES_EDIT_CLICKED: `${prefix}intensities edit data clicked`,
};

class CarbonEvent {
    private page: Page;

    private component?: Component;

    private event: string;

    private data: Record<string, unknown>;

    constructor(source: TrackSource, event: string, data?: Record<string, unknown>) {
        this.page = source.page;
        this.component = source.component;
        this.event = event;
        this.data = data ?? {};
    }

    public track() {
        trackEvent(this.event, { component: this.component, page: this.page, ...this.data });
    }
}

export const CARBON_EVENTS: Record<EventName, CarbonEventFunc> = {
    FILE_UPLOADED: (source, data) => new CarbonEvent(source, EVENT_NAMES.FILE_UPLOADED, data),
    SEE_ALL_ACTIVITIES_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.SEE_ALL_ACTIVITIES_CLICK, data),
    BREAKDOWN_OF_SOURCES_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.BREAKDOWN_OF_SOURCES_CLICK, data),
    CREATE_NEW_ACTIVITY_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.CREATE_NEW_ACTIVITY_CLICK, data),
    GO_TO_SUPPLIER_CARD_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.GO_TO_SUPPLIER_CARD_CLICK, data),
    NEED_HELP_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.NEED_HELP_CLICK, data),
    VIEW_ALL_ACTIVITIES_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.VIEW_ALL_ACTIVITIES_CLICK, data),
    RECENT_ACTIVITY_CLICK: (source, data) => new CarbonEvent(source, EVENT_NAMES.RECENT_ACTIVITY_CLICK, data),
    ADD_ACTIVITY_DATA_CLICKED: (source, data) => new CarbonEvent(source, EVENT_NAMES.ADD_ACTIVITY_DATA_CLICKED, data),
    OVERVIEW_TOP_SUPPLIER_TABLE_PAGE_VIEWED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.OVERVIEW_TOP_SUPPLIER_TABLE_PAGE_VIEWED, data),
    OVERVIEW_TOP_CONTRIBUTORS_TABLE_PAGE_VIEWED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.OVERVIEW_TOP_CONTRIBUTORS_TABLE_PAGE_VIEWED, data),
    OVERVIEW_USED_FILTERS: (source, data) => new CarbonEvent(source, EVENT_NAMES.OVERVIEW_USED_FILTERS, data),
    CUSTOM_EMISSION_FACTOR_DELETED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.CUSTOM_EMISSION_FACTOR_DELETED, data),
    VIEW_EMISSION_FACTORS_CLICK: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.VIEW_EMISSION_FACTORS_CLICK, data),
    EMISSION_FACTOR_ADD_AS_ACTIVITY_CLICKED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.EMISSION_FACTOR_ADD_AS_ACTIVITY_CLICKED, data),

    CREATED_NEW_SET_OF_TAGS: (source, data) => new CarbonEvent(source, EVENT_NAMES.CREATED_NEW_SET_OF_TAGS, data),
    ADDED_TAGS_FROM_SOURCE: (source, data) => new CarbonEvent(source, EVENT_NAMES.ADDED_TAGS_FROM_SOURCE, data),
    CREATED_CUSTOM_OPTION_FOR_TAG: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.CREATED_CUSTOM_OPTION_FOR_TAG, data),
    DELETED_CUSTOM_OPTION_FOR_TAG: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.DELETED_CUSTOM_OPTION_FOR_TAG, data),
    CHART_ACTION_DRILLDOWN: (source, data) => new CarbonEvent(source, EVENT_NAMES.CHART_ACTION_DRILLDOWN, data),
    CUSTOM_CATEGORY_USED: (source, data) => new CarbonEvent(source, EVENT_NAMES.CUSTOM_CATEGORY_USED, data),
    CUSTOM_CATEGORY_CREATED: (source, data) => new CarbonEvent(source, EVENT_NAMES.CUSTOM_CATEGORY_CREATED, data),
    CUSTOM_CATEGORY_CONNECTIONS_DELETED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.CUSTOM_CATEGORY_CONNECTIONS_DELETED, data),
    CUSTOM_CATEGORY_DELETED: (source, data) => new CarbonEvent(source, EVENT_NAMES.CUSTOM_CATEGORY_DELETED, data),

    ACTIVITY_ADDED_WITH_TAGS: (source, data) => new CarbonEvent(source, EVENT_NAMES.ACTIVITY_ADDED_WITH_TAGS, data),
    ACTIVITY_DATA_ADDED: (source, data) => new CarbonEvent(source, EVENT_NAMES.ACTIVITY_DATA_ADDED, data),
    ACTIVITY_CATEGORY_DESCRIPTION_SHOW_MORE_CLICK: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.ACTIVITY_CATEGORY_DESCRIPTION_SHOW_MORE_CLICK, data),
    ACTIVITY_CATEGORY_DESCRIPTION_SHOW_LESS_CLICK: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.ACTIVITY_CATEGORY_DESCRIPTION_SHOW_LESS_CLICK, data),
    ACTIVITY_UPDATE: (source, data) => new CarbonEvent(source, EVENT_NAMES.ACTIVITY_UPDATE, data),
    ACTIVITIES_DELETED: (source, data) => new CarbonEvent(source, EVENT_NAMES.ACTIVITIES_DELETED, data),
    ACTIVITY_LOG_PAGE_VIEWED: (source, data) => new CarbonEvent(source, EVENT_NAMES.ACTIVITY_LOG_PAGE_VIEWED, data),
    AI_SEARCH_SELECTED_CATEGORY: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.AI_SEARCH_SELECTED_CATEGORY, data),
    AI_SEARCH_USED: (source, data) => new CarbonEvent(source, EVENT_NAMES.AI_SEARCH_USED, data),
    LANDING_PAGE_TABLE_SIZE_CHANGED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.LANDING_PAGE_TABLE_SIZE_CHANGED, data),
    LANDING_PAGE_EMISSIONS_PR_YEAR_TAB_CHANGED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.LANDING_PAGE_EMISSIONS_PR_YEAR_TAB_CHANGED, data),
    LANDING_PAGE_VIEW_ALL_TOP_CONTRIBUTORS_CLICKED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.LANDING_PAGE_VIEW_ALL_TOP_CONTRIBUTORS_CLICKED, data),
    LANDING_PAGE_LOADED: (source, data) => new CarbonEvent(source, EVENT_NAMES.LANDING_PAGE_LOADED, data),
    LANDING_PAGE_RECENT_ACTIVITY_CLICKED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.LANDING_PAGE_RECENT_ACTIVITY_CLICKED, data),
    DATA_PREFERENCES_DRIVING_LOG_FILE_UPLOADED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.DATA_PREFERENCES_DRIVING_LOG_FILE_UPLOADED, data),
    DATA_PREFERENCES_CONNECTION_ADDED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.DATA_PREFERENCES_CONNECTION_ADDED, data),
    DATA_PREFERENCES_CONNECTION_EDITED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.DATA_PREFERENCES_CONNECTION_EDITED, data),
    DATA_PREFERENCES_PAGE_VIEWED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.DATA_PREFERENCES_PAGE_VIEWED, data),
    EMISSIONS_REPORT_DOWNLOADED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.EMISSIONS_REPORT_DOWNLOADED, data),
    COMMENTS_NEW_COMMENT_ADDED: (source, data) => new CarbonEvent(source, EVENT_NAMES.COMMENTS_NEW_COMMENT_ADDED, data),
    INTENSITIES_STARTED_FLOW: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_STARTED_FLOW, data),
    INTENSITIES_COMPLETED_FLOW: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_COMPLETED_FLOW, data),
    INTENSITIES_LEARN_MORE: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_LEARN_MORE, data),
    INTENSITIES_ADD_CUSTOM: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_ADD_CUSTOM, data),
    INTENSITIES_SWITCH_LEVEL: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_SWITCH_LEVEL, data),
    INTENSITIES_ADD_DATA_CLICKED: (source, data) =>
        new CarbonEvent(source, EVENT_NAMES.INTENSITIES_ADD_DATA_CLICKED, data),
    INTENSITIES_TYPE_SWITCHED: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_TYPE_SWITCHED, data),
    INTENSITIES_EDIT_CLICKED: (source, data) => new CarbonEvent(source, EVENT_NAMES.INTENSITIES_EDIT_CLICKED, data),
    PIECHART_BREAKDOWN_CHANGED: (source, data) => new CarbonEvent(source, EVENT_NAMES.PIECHART_BREAKDOWN_CHANGED, data),
};
