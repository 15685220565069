import { useEffect, useState } from "react";
import { PossibleBreakdown } from "./options";

function getBreakdownSelectionKey() {
    return `${localStorage.getItem("tenant")}:CARBON:PIE_CHART_BREAKDOWN`;
}

function getBreakdownSelection(): PossibleBreakdown | null {
    const localStorageKey = getBreakdownSelectionKey();
    const breakdown = localStorage.getItem(localStorageKey);
    if (breakdown === "scope" || breakdown === "companyStructure" || breakdown === "spendCategories") {
        return breakdown;
    }
    return null;
}

function useSelectedPieChartBreakdown(): [PossibleBreakdown, React.Dispatch<React.SetStateAction<PossibleBreakdown>>] {
    const [breakdown, setBreakdown] = useState<PossibleBreakdown>(getBreakdownSelection() ?? "scope");

    useEffect(() => {
        const localStorageKey = getBreakdownSelectionKey();
        localStorage.setItem(localStorageKey, breakdown);
    }, [breakdown]);

    return [breakdown, setBreakdown];
}

export { useSelectedPieChartBreakdown };
