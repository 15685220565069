import { trpc } from "@/lib/trpc/client";
import React, { useMemo } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { useFormContext } from "react-hook-form";
import { Truck } from "@ignite-analytics/icons";
import { Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { Supplier } from "router";
import { DefaultFormType } from "../../types";
import { ContextText } from "../ContextText";
import { InnerSupplierPicker } from "./Picker";
import { SupplierOptions } from "./types";

export const SupplierPicker = () => {
    const { formatMessage } = useIntl();
    const { setValue, watch } = useFormContext<DefaultFormType>();

    const supplier = watch("supplierId");
    const [searchPhrase, setSearchPhrase] = React.useState<string>("");
    const [debouncedSearchPhrase] = useDebouncedValue(searchPhrase, 300);
    const suppliers = trpc.suppliers.useQuery({
        searchPhrase: debouncedSearchPhrase,
        supplierId: supplier ?? undefined,
    });
    const suppliersResult: "no-suppliers" | SupplierOptions = useMemo(() => {
        if (suppliers.status === "success") {
            if (suppliers.data.supplierStatus === "no-suppliers") {
                return "no-suppliers";
            }
            return {
                status: "success",
                suppliers: suppliers.data.suppliers,
            };
        }
        if (suppliers.status === "error") {
            return {
                status: "error",
                error: "error",
                message: suppliers.error.message || "",
            };
        }
        return {
            status: "loading",
        };
    }, [suppliers]);

    // This makes sure the supplier picker is not rendered if there are no supplier table/no suppliers
    if (suppliersResult === "no-suppliers") {
        return <> </>;
    }
    const selectedValue: Supplier | null =
        suppliersResult.status === "success"
            ? (suppliersResult.suppliers.find((innerSupplier) => innerSupplier.id === supplier) ?? null)
            : null;
    return (
        <Grid container gap={2} wrap="nowrap" justifyContent="space-between" alignItems="center">
            <Grid item xs={4} sm={5} md={6}>
                <ContextText
                    icon={<Truck fontSize="small" />}
                    header={formatMessage({
                        defaultMessage: "Add supplier",
                        description: "Add supplier",
                    })}
                    text={formatMessage({
                        defaultMessage:
                            "Providing the supplier will make sure you don't double count any emissions from your spend-based estimates. It will also make it easier to follow up with and prioritise suppliers.",
                        description: "Add supplier description",
                    })}
                />
            </Grid>
            <Grid item xs>
                <InnerSupplierPicker
                    value={selectedValue}
                    onChange={(value) => {
                        setValue("supplierId", value?.id ?? null);
                    }}
                    searchPhrase={searchPhrase}
                    onSearch={setSearchPhrase}
                    optionsResult={suppliersResult}
                />
            </Grid>
        </Grid>
    );
};
