import checkPermissions, { PermissionNamespace, PermissionRelationTuple } from "@/lib/permissions/permissions";
import { Cache } from "@/lib/cache";
import React, { Reducer, createContext, useContext, useEffect, useReducer } from "react";

type PermissionState = { permission?: boolean; isLoading: boolean };

type State = Record<string, PermissionState>;

type Action =
    | {
          type: "setPermission";
          key: string;
          permission: boolean;
      }
    | { type: "clearPermissions" }
    | {
          type: "clearPermission";
          key: string;
      }
    | {
          type: "setLoading";
          key: string;
      };

type ContextValue = [State, React.Dispatch<Action>];
const PermissionContextV2 = createContext<ContextValue | null>(null);

const createKey = (tenant: string, namespace: PermissionNamespace, relationTuple: PermissionRelationTuple) => {
    return `${tenant}.${namespace}.${relationTuple.object}:${relationTuple.relation}`;
};

const reducer: Reducer<State, Action> = (prevState, action) => {
    switch (action.type) {
        case "setLoading": {
            return {
                ...prevState,
                [action.key]: {
                    isLoading: true,
                    permission: prevState[action.key] ? prevState[action.key].permission : undefined,
                },
            };
        }
        case "setPermission": {
            return { ...prevState, [action.key]: { permission: action.permission, isLoading: false } };
        }
        case "clearPermission": {
            const newState = { ...prevState };
            delete newState[action.key];
            return newState;
        }
        case "clearPermissions": {
            return {};
        }
    }
};

export const PermissionContextProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const reduction = useReducer(reducer, {});
    return <PermissionContextV2.Provider value={reduction}>{children}</PermissionContextV2.Provider>;
};

const permissionRequestCache = new Cache<Promise<void>>();

export const usePermission = (namespace: PermissionNamespace, relationTuple: PermissionRelationTuple) => {
    const context = useContext(PermissionContextV2);
    if (!context) {
        throw new Error("usePermission can only be used inside PermissionContextV2");
    }
    const [state, dispatch] = context;
    const tenant = localStorage.getItem("tenant") || "";
    // what happens if no tenant?
    const key = createKey(tenant, namespace, relationTuple);
    const value = state[key];
    useEffect(() => {
        if (value === undefined) {
            const objectRelation = { object: relationTuple.object, relation: relationTuple.relation };
            dispatch({
                key,
                type: "setLoading",
            });
            if (!permissionRequestCache.get(key)) {
                const permissionPromise = checkPermissions(namespace, [objectRelation])
                    .then((permissions) => {
                        dispatch({
                            key,
                            type: "setPermission",
                            permission: permissions[0],
                        });
                    })
                    .catch(() => {
                        dispatch({
                            key,
                            type: "clearPermission",
                        });
                    });
                permissionRequestCache.set(key, permissionPromise, 1000);
            }
        }
    }, [key, relationTuple.object, relationTuple.relation, namespace, value, dispatch]);

    return state[key]?.permission;
};
