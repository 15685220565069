import { flatMapDeep } from "lodash";

type StructureNode = {
    value: string;
    label: string;
    children: StructureNode[];
};

export function flattenTree(tree: StructureNode[]): { value: string; label: string }[] {
    return flatMapDeep(tree, (node) =>
        [{ value: node.value, label: node.label }].concat(node.children ? flattenTree(node.children) : [])
    );
}
