import { findCategory } from "@/Router/DataInputRoute/FormV2";
import { emissionFactorsRoute } from "@/Router/EmissionFactors";
import { MissingSvg } from "@/lib/svg/missingSvg";
import { Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

export const SelectCategoryHeader = () => (
    <Stack gap={1} alignItems="center" height="100%" padding={6}>
        <MissingSvg />
        <Stack justifyContent="center" alignItems="center">
            <Typography variant="textMd">
                <FormattedMessage
                    defaultMessage="Ready to level up your carbon tracking? Select a category from left side or "
                    description="Header for add acticity page"
                />
            </Typography>
            <Link from={findCategory.fullPath} to={emissionFactorsRoute.fullPath} params={{ factorType: "activity" }}>
                <Typography variant="textMd">
                    <FormattedMessage
                        defaultMessage="View emission factors"
                        description="View emission factors database link"
                    />
                </Typography>
            </Link>
        </Stack>
    </Stack>
);
