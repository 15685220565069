class InvalidEmissionIntensityValueError extends Error {
    year: number;

    invalidValue: number | string | null;

    rowId: string;

    constructor(
        message: string,
        options: {
            year: number;
            invalidValue: number | string | null;
            rowId: string;
        }
    ) {
        super(message);
        this.year = options.year;
        this.invalidValue = options.invalidValue;
        this.rowId = options.rowId;
    }
}

export { InvalidEmissionIntensityValueError };
