import { trpc } from "@/lib/trpc/client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useFilterHandlers } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { GroupFilterOptions } from "../components/GroupFilterOptions";

export const CompanyStructureFilter: React.FC = () => {
    const { filter, setFilter } = useFilterHandlers("businessUnitIds");
    const query = trpc.getBusinessUnitOptions.useQuery();

    return (
        <FilterValueSelect
            title={
                <FormattedMessage
                    defaultMessage="Business units"
                    description="Title for company structure filter menu"
                />
            }
            onReset={() => setFilter(null)}
            isLoading={query.isLoading}
            isError={query.isError}
        >
            {query.isSuccess && <GroupFilterOptions currentFilter={filter} onChange={setFilter} options={query.data} />}
        </FilterValueSelect>
    );
};
