import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { createRoute, Outlet, redirect } from "@tanstack/react-router";
import { pageLayoutRoute } from "../PageLayoutRoute";

export const activitiesRootRoute = createRoute({
    getParentRoute: () => pageLayoutRoute,
    path: "/activities",
    component: Outlet,
    errorComponent: ErrorBoundary,
});

export const temporaryRedirectLegacyActivityRoute = createRoute({
    getParentRoute: () => pageLayoutRoute,
    path: "/data-input/$",
    beforeLoad: (ctx) => {
        if (ctx.location.pathname.includes("/data-input/")) {
            throw redirect({
                to: ctx.location.pathname.replace("/data-input/", "/activities/"),
                search: ctx.search,
            });
        }
    },
    component: ErrorBoundary,
    errorComponent: ErrorBoundary,
});
