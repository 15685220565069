import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ActivityEmissionFactorContent } from "./ActivityEmissionFactorContent";
import { useGetRows } from "./ActivityEmissionFactorContent/helpers";

export const ActivityFactorTable: React.FC = () => {
    const emissionFactorsResponse = useGetRows();

    if (emissionFactorsResponse === "error") {
        return (
            <Alert severity="error" variant="filled">
                <AlertTitle>
                    <FormattedMessage defaultMessage="Error" />
                </AlertTitle>
                <FormattedMessage defaultMessage="Failed to load activity emission factors" />
            </Alert>
        );
    }
    return (
        <ActivityEmissionFactorContent
            loading={emissionFactorsResponse === "loading"}
            emissionFactorData={Array.isArray(emissionFactorsResponse) ? emissionFactorsResponse : []}
        />
    );
};
