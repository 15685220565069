import { DataGridProProps, gridClasses } from "@mui/x-data-grid-pro";

export const customGridClasses = {
    dragIndicator: "CustomMuiDataGrid-dragIndicator",
    openSupplierButton: "CustomMuiDataGrid-openSupplierButton",
} as const;

/**
 * These are purposely defined outside the component to avoid them being recreated on every render.
 */
export const CUSTOM_GRID_STYLE: DataGridProProps["sx"] = {
    [`& .${gridClasses.columnHeaderDraggableContainer}[draggable="true"] .${customGridClasses.dragIndicator}`]: {
        display: "inline-block",
    },
    // disable cell selection style
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-row--detailPanelExpanded": {
        backgroundColor: "green",
        ":hover": {
            backgroundColor: "green",
        },
    },
};
