import { SxProps, Theme, useTheme } from "@mui/material";
import { useMemo } from "react";

import { CUSTOM_GRID_STYLE } from "./constants";

export const useGetCustomGridStyle = (): SxProps<Theme> => {
    const theme = useTheme();
    const customGridStyle = useMemo<SxProps<Theme>>(() => {
        return {
            ...CUSTOM_GRID_STYLE,
            ".MuiDataGrid-row--detailPanelExpanded": {
                backgroundColor: theme.palette.primary.container,
                ":hover": {
                    backgroundColor: theme.palette.primary.container,
                },
            },
            mb: 4,
        };
    }, [theme.palette.primary.container]);
    return customGridStyle;
};
