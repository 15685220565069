import { useGetSearchParamFilters, useSelectedElectricityBasis } from "@/Containers/LandingPage/hooks";
import { formatNumber } from "@/lib/primitives/numbers";
import { trpc } from "@/lib/trpc/client";
import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TopSupplierEmissionsFromSource } from "router";
import { useGetMessagesFromEmissionsBySource } from "../OverviewSourceBreakdown";
import { ErrorContent, LoadingContent, SourceBreakdownDialog } from "../SourceBreakdownDialog";
import { SourceData } from "./types";

type ContentProps = {
    data: TopSupplierEmissionsFromSource[];
    year: number;
    supplierId: string;
};

const SupplierSourceBreakdownContent: React.FC<ContentProps> = ({ data, year, supplierId }) => {
    const getMessageForSource = useGetMessagesFromEmissionsBySource();
    return (
        <TableBody>
            {data.map((source) => {
                const name = getMessageForSource(source);
                const emissions = source.emissions.find((e) => e.id === supplierId)?.perYear[year] ?? 0;

                return (
                    <TableRow key={name}>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                            <FormattedMessage
                                defaultMessage="{emissions} tCO₂e"
                                description="Emissions"
                                values={{ emissions: formatNumber(emissions ?? 0, 2) }}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

type Props = {
    year: number;
    supplierId: string;
    supplierName: string;
    limit?: number;
    onClose: () => void;
};
export const SupplierSourceBreakdown: React.FC<Props> = ({ year, supplierId, supplierName, limit, onClose }) => {
    const { formatMessage } = useIntl();

    const filters = useGetSearchParamFilters();
    const electricity = useSelectedElectricityBasis();

    const perSourceResult = trpc.getTopSuppliersByEmissionsPerYearPerSource.useQuery({
        limit: limit ?? 50,
        filters,
        electricity,
    });

    const data: SourceData = React.useMemo(() => {
        if (perSourceResult.isSuccess) {
            if (typeof perSourceResult.data.data === "string") {
                return { status: "error" };
            }
            return { data: perSourceResult.data.data, status: "success" };
        }
        return { status: perSourceResult.status };
    }, [perSourceResult]);

    const content: React.ReactNode = React.useMemo(() => {
        switch (data.status) {
            case "success":
                return <SupplierSourceBreakdownContent data={data.data} year={year} supplierId={supplierId} />;
            case "loading":
                return <LoadingContent />;
            case "error":
                return <ErrorContent />;
        }
    }, [data, year, supplierId]);

    const title = formatMessage({
        defaultMessage: "Breakdown of sources",
        description: "Title for the breakdown dialog",
    });

    const subTitle = formatMessage(
        {
            defaultMessage: "{supplierName} in {year}",
            description: "SubTile for the breakdown dialog",
        },
        { supplierName, year }
    );

    return (
        <SourceBreakdownDialog
            content={content}
            onClose={onClose}
            title={title}
            subtitle={subTitle}
            shouldShow={!!data}
        />
    );
};
