import { OtherDataTableConnectionForm } from "@/Containers/DataSources/DataSourceForms";
import { OtherTableFormType } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { OtherFormReturn, OtherReturnValue } from "@/Router/interfaces";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { Stack, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Prettify } from "utils";
import { TablePicker } from "../Components/TablePicker";
import { useFetchConnectionOptions, useMutationCleanupFunctions } from "../hooks";

type Props = {
    connectionId?: string;
    postSubmit?: () => void;
};
export const OtherTableForm: React.FC<Props> = ({ connectionId, postSubmit }) => {
    const { tables, dataColumns, selectedTable, tableId } = useFetchConnectionOptions();
    const editMode = !!connectionId;
    const { onCreateSuccess, onError, onEditSuccess } = useMutationCleanupFunctions();
    const { formatMessage } = useIntl();
    const createMutation = trpc.addConnection.useMutation({ onError, onSuccess: onCreateSuccess });
    const editMutation = trpc.editConnection.useMutation({ onError, onSuccess: onEditSuccess });
    const onSubmit = (data: OtherReturnValue) => {
        if (editMode) {
            editMutation.mutate({ ...data, id: connectionId });
            postSubmit?.();
            return;
        }
        createMutation.mutate(data);
        postSubmit?.();
    };
    const missingTableMessage = formatMessage({
        defaultMessage: "Please select a table first",
        description: "Message to show when no table is selected",
    });
    const addedConns = trpc.addedConnections.useQuery();
    const connection = addedConns.data?.data.find(
        (conn): conn is Prettify<Extract<typeof conn, { type: "other" }>> =>
            conn.id === connectionId && conn.type === "other"
    );
    const columns = dataColumns.data?.dataColumns;
    const findMatchInColumns = useCallback(
        <T extends string>({ id, prefixId, type }: { id: string; prefixId?: string; type: T }) =>
            columns?.find(
                (col): col is Extract<typeof col, { type: T }> =>
                    col.dataColumnId === id && col.prefixId === prefixId && col.type === type
            ),
        [columns]
    );
    const defaultValuesForForm: Partial<OtherTableFormType> | undefined = useMemo(() => {
        if (!connection) return undefined;
        return {
            dateColumn: findMatchInColumns(connection.dateColumn),
            unitOfMeasurement: connection.unitOfMeasurement,
            calculatedEmissionsColumn: findMatchInColumns(connection.calculatedEmissionColumn),
            emissionStructureColumn: findMatchInColumns(connection.emissionStructureColumn),
            supplierColumn: connection.supplierColumn && findMatchInColumns(connection.supplierColumn),
            emissionFactorColumn:
                connection.emissionFactorColumn && findMatchInColumns(connection.emissionFactorColumn),
            tagColumns: connection?.tagColumns,
        };
    }, [connection, findMatchInColumns]);
    return (
        <>
            <TablePicker tableId={tableId} tables={tables} />
            {dataColumns.data?.dataColumns && selectedTable ? (
                <OtherDataTableConnectionForm
                    onSubmitForm={(data: OtherFormReturn) => {
                        onSubmit({ ...data, name: selectedTable.name, tableId: selectedTable.dataTableId });
                        CARBON_EVENTS.DATA_PREFERENCES_CONNECTION_ADDED(
                            { page: "DataPreferences", component: "OtherTableForm" },
                            {
                                type: "other",
                                unit: data.unitOfMeasurement,
                            }
                        ).track();
                    }}
                    columns={dataColumns.data?.dataColumns}
                    defaultValues={defaultValuesForForm}
                    tableId={selectedTable.dataTableId}
                />
            ) : (
                <Stack gap={1}>
                    {Array.from(Array(5).keys()).map((i) => (
                        <TextField key={i} disabled variant="outlined" label={missingTableMessage} size="small" />
                    ))}
                </Stack>
            )}
        </>
    );
};
