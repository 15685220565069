import { Skeleton, Stack } from "@mui/material";

export const LoadingContent = () => {
    return (
        <Stack gap={2}>
            <Stack gap={2} padding={3} alignItems="center">
                <Skeleton variant="rectangular" height={80} width="100%" />
            </Stack>
        </Stack>
    );
};
