import { addDataSourceTypeRoute, dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { Button, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import { Link, useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MenuRadioButtons } from "./MenuRadioButtons";
import { useGetMenuOptions } from "./hooks";

export const AddDataSourceType: React.FC = () => {
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = React.useState("");
    const { options } = useGetMenuOptions();
    const { hideSpendAndSupplier } = useSearch({ from: addDataSourceTypeRoute.fullPath });

    const filteredOptions = React.useMemo(() => {
        if (hideSpendAndSupplier) {
            return options.filter((option) => option.value !== "spend" && option.value !== "supplier");
        }
        return options;
    }, [hideSpendAndSupplier, options]);

    const currentOption = React.useMemo(
        () => options.find((option) => option.value === selectedOption) ?? undefined,
        [selectedOption, options]
    );

    return (
        <>
            <DialogContent>
                <Stack direction="column">
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            defaultMessage="What type of data source are you adding?"
                            description="Add data source title"
                        />
                    </Typography>

                    <MenuRadioButtons
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        options={filteredOptions}
                    />
                    <Stack direction="column" justifyContent="flex-end" sx={{ marginTop: "12px" }}>
                        <Typography variant="subtitle2">
                            <FormattedMessage
                                defaultMessage="Need to upload a new file?"
                                description="Need to upload help text"
                            />
                        </Typography>
                        <Typography variant="textSm" color="GrayText" sx={{ marginBottom: "12px" }}>
                            <FormattedMessage
                                defaultMessage="You can upload a new file using the Data module. Go to Data > Data tables > Add new table > Upload file"
                                description="Need to upload help subtext"
                            />
                        </Typography>

                        <Link href="/data/tables/">
                            <Button variant="outlined" color="secondary">
                                <Typography variant="textSm" color="inherit">
                                    <FormattedMessage
                                        defaultMessage="Go to Data module"
                                        description="Go to Data module link"
                                    />
                                </Typography>
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Link to={dataPreferenceDataSourcesOverview.fullPath}>
                    <Button variant="text" color="secondary">
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button title" />
                        </Typography>
                    </Button>
                </Link>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                        marginLeft: "10px",
                        ":disabled": { backgroundColor: theme.palette.primary.selected },
                    }}
                    disabled={!currentOption}
                    onClick={() => currentOption?.navigateFn()}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Next" description="Next button title" />
                    </Typography>
                </Button>
            </DialogActions>
        </>
    );
};
