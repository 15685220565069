import PermissionContainer from "@/Components/PermissionContainer";
import { RouteButton } from "@/Components/RouteButton";
import { addActivityFindCategoryRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { overviewRoute } from "@/Router/Overview";
import { pixelsToRem } from "@/lib/accessibility";
import { usePermission } from "@/lib/permissions/usePermissions";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import { Company, Gear, LocationPin, Plus } from "@ignite-analytics/icons";
import { Button, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { FilterOverview } from "./Filter";
import { LandingPageContent } from "./LandingPageContent";
import { EmissionIntensityTour } from "./LandingPageContent/EmissionIntensityCard/Tour";
import { LearnMore } from "./LearnMore";
import { NoData } from "./NoData";
import { SourceBreakdownWrapper } from "./SourceBreakdownWrapper";
import { WatchDemo } from "./WatchDemo";
import { setYearPersistant, useGetSearchParamFilters, useSelectedYear } from "./hooks";

export const LandingPage: React.FC = () => {
    const year = useSelectedYear();
    const { electricity, ...filters } = useGetSearchParamFilters();
    const { viewType, tour } = useSearch({ from: overviewRoute.id });
    const utils = trpc.useContext();
    const navigate = useNavigate();
    const { error, isSuccess, data, isLoading } = trpc.overview.useQuery(
        { filters, electricity },
        {
            retry,
        }
    );
    const isEditor = usePermission({ permission: "write" });
    useEffect(() => {
        if (isSuccess) {
            utils.overviewPerSource.prefetch({ filters, electricity });
            utils.getEmissionTags.prefetch();
        }
    }, [isSuccess, utils.overviewPerSource, utils.getEmissionTags, filters, electricity]);

    const handleAddActivityClick = () => {
        CARBON_EVENTS.ADD_ACTIVITY_DATA_CLICKED({
            page: "Overview",
            component: "AddActivityButton",
        }).track();
        navigate({ to: addActivityFindCategoryRoute.fullPath });
    };
    const hasData = (data?.emissionData?.total?.co2 ?? 0) > 0 || isLoading;

    return (
        <Stack spacing={2} pb={4}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" component="h1" fontWeight="bold">
                    <FormattedMessage defaultMessage="Carbon accounting" description="Emissions page overview header" />
                </Typography>
                {isEditor && (
                    <Link to={dataPreferenceDataSourcesOverview.fullPath}>
                        <Button variant="text" size="small" color="ghostGray" startIcon={<Gear />}>
                            <FormattedMessage defaultMessage="Settings" />
                        </Button>
                    </Link>
                )}
            </Stack>
            <PermissionContainer error={error}>
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" gap={2} alignItems="center">
                            <Typography variant="textLg" component="h2" fontWeight={500} noWrap overflow="visible">
                                <FormattedMessage
                                    defaultMessage="Key insights"
                                    description="Emissions page overview header"
                                />
                            </Typography>
                            <ToggleButtonGroup
                                sx={{ height: pixelsToRem(40) }}
                                value={electricity}
                                exclusive
                                onChange={(e, selectedElectricity) => {
                                    if (selectedElectricity === null) return;
                                    CARBON_EVENTS.LANDING_PAGE_ELECTRICITY_BASIS_CHANGED(
                                        {
                                            page: "Overview",
                                        },
                                        {
                                            newScope2Type: selectedElectricity,
                                        }
                                    ).track();
                                    navigate({
                                        to: ".",
                                        search: (prev) => ({ ...prev, electricity: selectedElectricity }),
                                    });
                                }}
                            >
                                <Tooltip
                                    title={
                                        <FormattedMessage
                                            defaultMessage="Location-based estimates for electricity emissions"
                                            description="Tooltip for button group for picking electricity type"
                                        />
                                    }
                                >
                                    <ToggleButton sx={{ p: 1 }} value="locationBased">
                                        <LocationPin sx={{ fontSize: "20px" }} />
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <FormattedMessage
                                            defaultMessage="Market-based estimates for electricity emissions"
                                            description="Tooltip for button group for picking electricity type"
                                        />
                                    }
                                >
                                    <ToggleButton sx={{ p: 1 }} value="marketBased">
                                        <Company sx={{ fontSize: "20px" }} />
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </Stack>
                        {hasData && (
                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                <Select
                                    value={year}
                                    onChange={(e) => {
                                        const selectedYear = e.target.value;
                                        if (typeof selectedYear !== "number") return;
                                        CARBON_EVENTS.LANDING_PAGE_YEAR_CHANGED(
                                            {
                                                page: "Overview",
                                            },
                                            { fromYear: year, toYear: selectedYear }
                                        ).track();
                                        setYearPersistant(selectedYear);
                                        navigate({ to: ".", search: (prev) => ({ ...prev, year: selectedYear }) });
                                    }}
                                    size="small"
                                    sx={{ height: pixelsToRem(40), width: "100px" }}
                                    autoWidth
                                >
                                    {data?.emissionData.years
                                        .sort((a, b) => b - a)
                                        .map((y) => (
                                            <MenuItem value={y} key={y} sx={{ width: "100px" }}>
                                                {y}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <EmissionIntensityTour
                                    open={tour === "emission-intensities-1"}
                                    placement="bottom"
                                    header={
                                        <FormattedMessage
                                            defaultMessage="Emission intensities"
                                            description="Header for the second (and last) step of the emission intensities product tour"
                                        />
                                    }
                                    subheader={
                                        <FormattedMessage
                                            defaultMessage="You can also filter on a specific business unit, to visualize the emissions intensities related to that."
                                            description="Subheader for the second (and last) step of the emission intensities product tour"
                                        />
                                    }
                                    actions={
                                        <RouteButton
                                            size="small"
                                            to="/overview"
                                            search={(prev) => ({ ...prev, tour: undefined })}
                                            replace
                                        >
                                            <FormattedMessage
                                                defaultMessage="Done"
                                                description="Label for the button to finish the emission intensity product tour"
                                            />
                                        </RouteButton>
                                    }
                                >
                                    <span>
                                        <FilterOverview buttonType="secondary" />
                                    </span>
                                </EmissionIntensityTour>
                                <Button
                                    color="primary"
                                    size="small"
                                    disabled={isEditor === false}
                                    onClick={handleAddActivityClick}
                                    startIcon={<Plus />}
                                >
                                    <FormattedMessage defaultMessage="Add activity" />
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                    {hasData ? (
                        <>
                            <LandingPageContent />
                            <SourceBreakdownWrapper />
                        </>
                    ) : (
                        <NoData />
                    )}
                </Stack>
            </PermissionContainer>
            {viewType === "demo" && <WatchDemo />}
            {viewType === "learnMore" && <LearnMore />}
        </Stack>
    );
};
