import {
    DataGrid,
    GridToolbarFilterButton,
    GridToolbarSearchField,
    GridToolbarTotalItemsContainer,
} from "@/Components/DataGrid";
import {
    mapContributorType,
    useContributorTypeOptions,
} from "@/Containers/LandingPage/components/ContributorTypePicker/helpers";
import { topContributorsRoute } from "@/Router/TopContributors";
import { graphql } from "@/__generated__";
import { GetTopContributors_TopContributorsTableDocument } from "@/__generated__/graphql";
import { mapOverviewFiltersToGraphqlEmissionFilter } from "@/lib/filters/helpers";
import { useQuery } from "@apollo/client";
import { ExclamationCircle, Filter } from "@ignite-analytics/icons";
import { Divider, Stack, Tooltip } from "@mui/material";
import {
    DataGridProProps,
    GridFilterModel,
    GridSortModel,
    GridToolbarContainer,
    useGridApiContext,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { FilterModal } from "../../../Filter/FilterModal";
import { useGetSearchParamFilters } from "../../../hooks";
import { ToolbarContributorPicker, ToolbarContributorPickerProps } from "../ToolbarContributorPicker";
import { ToolbarElectricityBasis } from "../ToolbarElectricityBasisPicker";
import { CONTRIBUTOR_NAME_COLUMN, useParseColumnsAndRows } from "./helpers";

const CustomFilterPanel = () => {
    const gridApiRef = useGridApiContext();
    return <FilterModal open onClose={() => gridApiRef.current?.hideFilterPanel()} />;
};

interface ToolbarProps {
    contributorPickerProps: ToolbarContributorPickerProps;
}

// Augment the props for the toolbar slot
declare module "@mui/x-data-grid-pro" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ToolbarPropsOverrides extends ToolbarProps {}
}

const RowConditionalWarningChipIcon = () => {
    const { formatMessage } = useIntl();
    const apiRef = useGridApiContext();
    const totalItems = apiRef.current?.getRowsCount();
    if (totalItems < 1000) {
        return null;
    }
    return (
        <Tooltip
            title={formatMessage({
                defaultMessage: "For large datasets, only the first 1,000 items are displayed.",
                description: "Top contributor large dataset disclaimer over 1000 items.",
            })}
        >
            <ExclamationCircle fontSize="small" />
        </Tooltip>
    );
};

const GridToolbar: React.FC<ToolbarProps> = ({ contributorPickerProps }) => {
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer slotProps={{ chipIcon: <RowConditionalWarningChipIcon /> }} />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                    <GridToolbarFilterButton />
                </Stack>
                <Stack direction="row" alignItems="center" columnGap={1}>
                    <ToolbarElectricityBasis />
                    <ToolbarContributorPicker {...contributorPickerProps} />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};

const slots: DataGridProProps["slots"] = {
    filterPanel: CustomFilterPanel,
    openFilterButtonIcon: Filter,
    toolbar: GridToolbar,
};
const initialState: DataGridProProps["initialState"] = {
    pinnedColumns: { left: [CONTRIBUTOR_NAME_COLUMN] as string[] },
    pagination: { paginationModel: { pageSize: 25 } },
} as const;

graphql(`
    query getTopContributors_TopContributorsTable(
        $contributorType: ContributorType!
        $level: Int
        $electricityBasis: ElectricityBasis!
        $filter: EmissionsFilter
    ) {
        getTopContributorEmissions(
            contributorType: $contributorType
            level: $level
            electricityBasis: $electricityBasis
            filter: $filter
        ) {
            results {
                supplier {
                    id
                    name
                }
                businessUnit {
                    id
                    name
                }
                spendCategory {
                    id
                    name
                }
                totalEmissions
                emissionsByYear {
                    year
                    value
                }
            }
        }
    }
`);
export const TopContributorsTable = () => {
    const { formatMessage } = useIntl();
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const {
        contributorType: _contributorType,
        contributorTypeLevel,
        electricity,
        search,
        year,
    } = useSearch({ from: topContributorsRoute.id });
    const filter = useGetSearchParamFilters();
    const contributorType = _contributorType ?? "suppliers";

    const contributorTypeOptions = useContributorTypeOptions();

    const selectedContributorType = contributorTypeOptions.find((option) => option.contributorType === contributorType);

    const { data, loading } = useQuery(GetTopContributors_TopContributorsTableDocument, {
        variables: {
            contributorType: mapContributorType(contributorType),
            level: contributorTypeLevel,
            electricityBasis: electricity === "locationBased" ? "LOCATION_BASED" : "MARKET_BASED",
            filter: mapOverviewFiltersToGraphqlEmissionFilter(filter),
        },
    });
    const topContributorEmissions = data?.getTopContributorEmissions?.results ?? [];

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [search],
    });

    const [sortModel, setSortModel] = React.useState<GridSortModel>(year ? [{ field: `${year}`, sort: "desc" }] : []);

    const contributorTypeHeader = contributorTypeLevel
        ? formatMessage(
              { defaultMessage: "{optionLabel} (Level {level})", description: "Multilevel option label" },
              { optionLabel: selectedContributorType?.name, level: contributorTypeLevel }
          )
        : selectedContributorType?.name;

    const { rows, columns } = useParseColumnsAndRows(
        contributorTypeHeader ?? contributorType,
        contributorType,
        topContributorEmissions
    );

    const onFilterModelChange = useCallback<NonNullable<DataGridProProps["onFilterModelChange"]>>(
        (model) => {
            setFilterModel(model);
        },
        [setFilterModel]
    );

    const onSortModelChange = useCallback<NonNullable<DataGridProProps["onSortModelChange"]>>(
        (model) => {
            if (loading) return;
            setSortModel(model);
        },
        [setSortModel, loading]
    );

    const numActiveFilters = useMemo(() => {
        const tagNum = filter.tagFilters?.length ?? 0;
        return (
            tagNum +
            Number(Boolean(filter.supplierIds?.length)) +
            Number(Boolean(filter.sources?.length)) +
            Number(Boolean(filter.businessUnitIds?.length)) +
            Number(Boolean(filter.spendCategoryIds?.length))
        );
    }, [filter]);

    const slotProps: DataGridProProps["slotProps"] = useMemo(
        () => ({
            toolbar: {
                filter: {
                    numActiveFilters,
                },
                search: {
                    onChange: (value) => {
                        navigate({ to: ".", search: (prev) => ({ ...prev, search: value || undefined }) });
                    },
                },
                contributorPickerProps: { options: contributorTypeOptions },
            },
        }),
        [contributorTypeOptions, navigate, numActiveFilters]
    );

    return (
        <DataGrid
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnMenu
            pagination
            apiRef={apiRef}
            slots={slots}
            slotProps={slotProps}
            loading={loading}
            initialState={initialState}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            rows={rows}
            columns={columns}
        />
    );
};
