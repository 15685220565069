import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { Pen } from "@ignite-analytics/icons";
import { Stack, Typography } from "@mui/material";

type Props = {
    selectedOption: string;
    color: "red" | "green" | "blue";
    onClick: () => void;
};

export const SelectedTag: React.FC<Props> = ({ selectedOption, color, onClick }) => {
    return (
        <SquareChip color={getChipColor(color)} onClick={onClick}>
            <Stack alignItems="center" direction="row" gap={1}>
                <Typography>{selectedOption}</Typography>
                <Pen fontSize="inherit" />
            </Stack>
        </SquareChip>
    );
};
