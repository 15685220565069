import { OtherFormReturn, SpendFormReturn } from "@/Router/interfaces";
import { SpendFormType, OtherTableFormType, DataColumn, GlobalType } from "./interfaces";

export const getReturnValueFromSpendForm = (formValues: SpendFormType): SpendFormReturn => {
    return {
        dateColumn: {
            id: formValues.dateColumn.dataColumnId,
            prefixId: formValues.dateColumn.prefixId,
            type: formValues.dateColumn?.type,
        },
        spendColumn: {
            id: formValues.spendColumn.dataColumnId,
            prefixId: formValues.spendColumn.prefixId,
            type: formValues.spendColumn?.type,
        },
        emissionFactorColumn: {
            id: formValues.emissionFactorColumn.dataColumnId,
            prefixId: formValues.emissionFactorColumn.prefixId,
            type: formValues.emissionFactorColumn?.type,
        },
        emissionStructureColumn: {
            id: formValues.emissionStructureColumn.dataColumnId,
            prefixId: formValues.emissionStructureColumn.prefixId,
            type: formValues.emissionStructureColumn?.type,
        },
        supplierColumn: formValues.supplierColumn
            ? {
                  id: formValues.supplierColumn.dataColumnId,
                  prefixId: formValues.supplierColumn.prefixId,
                  type: formValues.supplierColumn?.type,
              }
            : undefined,
        type: "spend",
        tagColumns: formValues.tagColumns,
    };
};

export const getReturnValueFromOtherForm = (formValues: OtherTableFormType): OtherFormReturn => {
    return {
        dateColumn: {
            id: formValues.dateColumn.dataColumnId,
            prefixId: formValues.dateColumn.prefixId,
            type: formValues.dateColumn?.type,
        },
        unitOfMeasurement: formValues.unitOfMeasurement,
        emissionStructureColumn: {
            id: formValues.emissionStructureColumn.dataColumnId,
            prefixId: formValues.emissionStructureColumn.prefixId,
            type: formValues.emissionStructureColumn?.type,
        },
        calculatedEmissionColumn: {
            id: formValues.calculatedEmissionsColumn.dataColumnId,
            prefixId: formValues.calculatedEmissionsColumn.prefixId,
            type: formValues.calculatedEmissionsColumn?.type,
        },
        supplierColumn: formValues.supplierColumn
            ? {
                  id: formValues.supplierColumn.dataColumnId,
                  prefixId: formValues.supplierColumn.prefixId,
                  type: formValues.supplierColumn?.type,
              }
            : undefined,
        type: "other",
        emissionFactorColumn: formValues.emissionFactorColumn
            ? {
                  id: formValues.emissionFactorColumn.dataColumnId,
                  prefixId: formValues.emissionFactorColumn.prefixId,
                  type: formValues.emissionFactorColumn?.type,
              }
            : undefined,
        tagColumns: formValues.tagColumns,
    };
};

export const getDateColumnByGlobalType = (
    dataColumns: DataColumn[],
    globalType: GlobalType
): (DataColumn & { type: "date" }) | undefined => {
    const filteredColumns = dataColumns.filter((column) => column.type === "date");
    const dateColumn = filteredColumns.filter((column) => column.globalType === globalType);
    if (dateColumn.length === 0) return undefined;
    const dateColumnWithoutPrefix = dateColumn.find((column) => !column.prefixId);
    const selectedColumn = dateColumnWithoutPrefix || dateColumn[0];
    return { ...selectedColumn, type: "date" };
};

export const getNumberColumnByGlobalType = (
    dataColumns: DataColumn[],
    globalType: GlobalType
): (DataColumn & { type: "number" }) | undefined => {
    const filteredColumns = dataColumns.filter((column) => column.type === "number");
    const numberColumn = filteredColumns.filter((column) => column.globalType === globalType);
    if (numberColumn.length === 0) return undefined;
    const numberColumnWithoutPrefix = numberColumn.find((column) => !column.prefixId);
    const selectedColumn = numberColumnWithoutPrefix || numberColumn[0];
    return { ...selectedColumn, type: "number" };
};

export const getClassificationColumnByGlobalType = (
    dataColumns: DataColumn[],
    globalType: GlobalType
): (DataColumn & { type: "classification" }) | undefined => {
    const filteredColumns = dataColumns.filter((column) => column.type === "classification");
    const classificationColumn = filteredColumns.filter((column) => column.globalType === globalType);
    if (classificationColumn.length === 0) return undefined;
    const classificationColumnWithoutPrefix = classificationColumn.find((column) => !column.prefixId);
    const selectedColumn = classificationColumnWithoutPrefix || classificationColumn[0];
    return { ...selectedColumn, type: "classification" };
};

export const getRelationalColumnByGlobalType = (
    dataColumns: DataColumn[],
    globalType: GlobalType
): (DataColumn & { type: "relation" }) | undefined => {
    const filteredColumns = dataColumns.filter((column) => column.type === "relation");
    const relationalColumn = filteredColumns.filter((column) => column.globalType === globalType);
    if (relationalColumn.length === 0) return undefined;
    const relationalColumnWithoutPrefix = relationalColumn.find((column) => !column.prefixId);
    const selectedColumn = relationalColumnWithoutPrefix || relationalColumn[0];
    return { ...selectedColumn, type: "relation" };
};
