/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "@mui/material";
import React from "react";
import { PieLabelRenderProps } from "recharts";

export interface PointData {
    name: string;
}

const RADIAN = Math.PI / 180;
const CustomLabel: React.FC<PieLabelRenderProps> = ({
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    payload,
    onClick,
    cx,
    cy,
}) => {
    if (!percent || percent < 0.02) {
        return null;
    }
    const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
    const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
    const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

    const rotation = midAngle > -90 && midAngle < 90 ? -midAngle : 180 - midAngle;
    const shouldRotate = percent < 0.1;
    const pointData = payload.payload as PointData;
    const label = pointData.name.substring(0, 22) + (pointData.name.length > 22 ? "..." : "");

    return (
        <Link underline="none" variant="caption" style={{ cursor: "pointer" }}>
            <text
                style={{ pointerEvents: "none" }}
                x={x}
                y={y}
                fill="black"
                stroke="black"
                strokeWidth={1.5}
                textAnchor="middle"
                dominantBaseline="central"
                transform={shouldRotate ? `rotate (${rotation}, ${x}, ${y})` : undefined}
            >
                {label}
            </text>
            <text
                style={{ pointerEvents: "none" }}
                onClick={onClick}
                x={x}
                y={y}
                fill="white"
                stroke="white"
                strokeWidth={1}
                textAnchor="middle"
                dominantBaseline="central"
                transform={shouldRotate ? `rotate (${rotation}, ${x}, ${y})` : undefined}
            >
                {label}
            </text>
        </Link>
    );
};

export { CustomLabel };
