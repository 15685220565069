import { useAlert } from "@/Providers/Alerts";
import { trpc } from "@/lib/trpc/client";
import { TRPCClientErrorLike } from "@trpc/react-query";
import { useIntl } from "react-intl";

export const useGetCleanupFunctions = () => {
    const { formatMessage } = useIntl();
    const utils = trpc.useContext();
    const { alertUser } = useAlert();
    const refetch = () => {
        utils.getEmissionTagsWithOptions.cancel();
        utils.getEmissionTagsWithOptions.refetch();
    };
    const onSuccess = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Successfully added",
                description: "Alert message when adding",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        refetch();
    };
    const onFinishedDelete = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Successfully deleted",
                description: "Alert message when deleting",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        refetch();
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: TRPCClientErrorLike<any>) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred: {error}",
                    description: "Alert message when deleting",
                },
                {
                    error: error.message,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };

    return { onSuccess, onFinishedDelete, onError };
};
