import { editCustomOptions, editSourceOptionsForTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { Menu as MenuIcon, Plus } from "@ignite-analytics/icons";
import { Button, Card, CardContent, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "@tanstack/react-router";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { TagOptions } from "./TagOptions";

type Props = {
    id: string;
    tagName: string;
    customOptions: string[];
    optionsFromSources: {
        sourceName: string;
        total: number;
        options: string[];
    }[];
    deleteTag: () => void;
    sourceMissingOptionExample?: { id: string; discriminator: "connection" | "supplier" };
};

export const TagCard: React.FC<Props> = ({
    id,
    tagName,
    customOptions,
    optionsFromSources,
    deleteTag,
    sourceMissingOptionExample,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Card variant="outlined" sx={{ minHeight: 200 }}>
            <CardContent>
                <Stack gap={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{tagName}</Typography>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MenuIcon fontSize="small" />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    navigate({ to: editCustomOptions.fullPath, search: { id, name: tagName } });
                                    handleClose();
                                }}
                            >
                                <Typography variant="textMd">
                                    <FormattedMessage defaultMessage="Edit" description="Edit for the Tags component" />
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    deleteTag();
                                }}
                            >
                                <Typography variant="textMd">
                                    <FormattedMessage
                                        defaultMessage="Delete"
                                        description="Delete for the Tags component"
                                    />
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Stack>

                    <TagOptions tagType={{ type: "custom" }} options={customOptions} />
                    {optionsFromSources.map((source) => (
                        <TagOptions
                            key={source.sourceName}
                            tagType={{
                                type: "dataSource",
                                sourceName: source.sourceName,
                                numberOfTags: source.total,
                            }}
                            options={source.options}
                        />
                    ))}
                    <Grid item>
                        {sourceMissingOptionExample && (
                            <Link
                                to={editSourceOptionsForTagRoute.fullPath}
                                params={{
                                    sourceId: sourceMissingOptionExample.id,
                                    sourceType: sourceMissingOptionExample.discriminator,
                                }}
                                search={{ id, name: tagName }}
                                color="secondary"
                            >
                                <Button
                                    startIcon={<Plus fontSize="inherit" />}
                                    color="secondary"
                                    variant="outlined"
                                    size="small"
                                >
                                    <FormattedMessage
                                        defaultMessage="Add data source"
                                        description="Add data source for the Tags component"
                                    />
                                </Button>
                            </Link>
                        )}
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};
