import { DataGrid } from "@/Components/DataGrid";
import { DataGridProProps, gridClasses } from "@mui/x-data-grid-pro";
import React from "react";
import { CustomColumnMenu } from "./CustomColumnMenu";
import { Row } from "./types";

const slots: DataGridProProps<Row>["slots"] = {
    columnMenu: CustomColumnMenu,
};

const pinnedColumns: DataGridProProps<Row>["pinnedColumns"] = {
    left: ["entity"],
};

type EmissionIntensityDataGridProps = Pick<
    DataGridProProps,
    "processRowUpdate" | "onProcessRowUpdateError" | "rows" | "columns" | "loading" | "slotProps"
> & {
    highlightFirstCell?: boolean;
    onYearRangeChange?: (data: Partial<{ startYear: number; endYear: number }>) => void;
};

const defaultSx: DataGridProProps<Row>["sx"] = {
    [`& .${gridClasses.row}[data-rowindex="0"] .${gridClasses.cell}[data-colindex="1"]`]: {
        border: (t) => `1px solid ${t.palette.secondary.main}`,
    },
};

const EmissionIntensityDataGrid: React.FC<
    EmissionIntensityDataGridProps & Pick<DataGridProProps, "onCellEditStart" | "onRowEditStart" | "apiRef">
> = ({ highlightFirstCell, ...props }) => {
    return (
        <DataGrid<Row>
            sx={highlightFirstCell ? defaultSx : undefined}
            editMode="row"
            pinnedColumns={pinnedColumns}
            slots={slots}
            disableColumnFilter
            disableColumnReorder
            disableRowSelectionOnClick
            hideFooter
            {...props}
        />
    );
};

export { EmissionIntensityDataGrid };
export type { EmissionIntensityDataGridProps };
