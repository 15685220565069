import { getScopeByCategory } from "@/Router/interfaces";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { SupportingTextCell } from "@/Components/DataGrid";
import React from "react";
import { ScopeCategoryType } from "router";

import { useGetScopeOptionsAndMessages } from "../../RenderFunctions/helpers";

type Props = {
    category: ScopeCategoryType;
};
export const CategoryColumn: React.FC<Props> = ({ category }) => {
    const categoryMessages = useGetAllScopeMessages();
    const { messages } = useGetScopeOptionsAndMessages();
    const scope = getScopeByCategory(category);
    return <SupportingTextCell primary={messages[scope]} secondary={categoryMessages[category].header} />;
};
