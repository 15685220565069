import { ActionPopover } from "../Common";
import { CategoryAndYearTotalHeader } from "../Common/Components/CategoryAndYearTotalHeader";
import { CreateNewActivityListAction } from "../Common/Components/CreateNewActivityListAction";
import { SeeAllActivitiesListAction } from "../Common/Components/SeeAllActivitiesListAction";
import { SourcesBreakDownListAction } from "../Common/Components/SourcesBreakDownListAction";

import { CommonProps, SourceConfig } from "../Common/types";

type Props = CommonProps & {
    config: SourceConfig & { type: "emissionsByCategory" };
};
export const CategoryActionPopover: React.FC<Props> = ({ anchorEl, open, config, trackSource, handleClose }) => {
    return (
        <ActionPopover
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            items={[
                <CategoryAndYearTotalHeader category={config.category} year={config.year} key="header" />,
                <SeeAllActivitiesListAction config={config} trackSource={trackSource} key="see-all-activities" />,
                <CreateNewActivityListAction config={config} trackSource={trackSource} key="create-new-activity" />,
                <SourcesBreakDownListAction config={config} trackSource={trackSource} key="sources-breakdown" />,
            ]}
        />
    );
};
