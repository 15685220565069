import { createRoute } from "@tanstack/react-router";
import { emissionIntensitiesRootRoute } from "../../routes";
import { EmissionIntensities } from "./EmissionIntensities";

const emissionIntensitiesEditRootRoute = createRoute({
    getParentRoute: () => emissionIntensitiesRootRoute,
    path: "/$emissionIntensityId",
    component: EmissionIntensities,
});

export { emissionIntensitiesEditRootRoute };
