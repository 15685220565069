import { DefaultFormType, FormFilter } from "@/Components/AddActivityForm/types";
import { Plus } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { FieldOption, FilterField } from "router";
import { DataTakenIntoAccount } from "../../types";
import { FilterRow } from "./FilterRow";
import { FilterFormValue } from "./types";

interface Props {
    selectedFilters: FilterFormValue[];
    addFilter: (id: string) => void;
    removeFilter: (id: string) => void;
    changeFilter: (id: string, filter: { filterField: FilterField; value?: FieldOption }) => void;
    optionFilter: {
        filterList?: FormFilter[];
        dataTakenIntoAccount: DataTakenIntoAccount;
    };
}

export const FilterPicker: React.FC<Props> = ({
    selectedFilters,
    addFilter,
    removeFilter,
    changeFilter,
    optionFilter,
}) => {
    const { formState } = useFormContext<DefaultFormType>();
    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Add additional conditions to be more specific" />
                </Typography>
                <Button
                    startIcon={<Plus fontSize="inherit" />}
                    variant="outlined"
                    size="2xsmall"
                    color="primary"
                    onClick={() => {
                        addFilter(crypto.randomUUID());
                    }}
                >
                    <FormattedMessage defaultMessage="Add criteria" description="Add criteria" />
                </Button>
            </Stack>
            {selectedFilters.map((filter, i) => {
                return (
                    <FilterRow
                        key={filter.id}
                        selectedFilter={filter}
                        changeFilter={(changedValues) => {
                            changeFilter(filter.id, changedValues);
                        }}
                        removeFilter={() => {
                            removeFilter(filter.id);
                        }}
                        optionFilter={optionFilter}
                        error={formState.errors?.filter?.[i]}
                    />
                );
            })}
        </>
    );
};
