import { useAlert } from "@/Providers/Alerts";
import { Alert as MuiAlert, AlertTitle, Snackbar } from "@mui/material";
import React from "react";

export const AlertSnackBar: React.FC = () => {
    const { alert, onClose } = useAlert();

    return (
        <Snackbar
            key={alert?.timestamp}
            open={Boolean(alert?.message)}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            {...alert?.SnackbarProps}
        >
            <MuiAlert severity={alert?.message.severity} title={alert?.message.title} {...alert?.AlertProps}>
                {alert?.message.title && <AlertTitle>{alert.message.title}</AlertTitle>}
                {alert?.message.value}
            </MuiAlert>
        </Snackbar>
    );
};
