import {
    Contributor,
    ContributorPickerProps,
    ContributorTypePicker,
} from "@/Containers/LandingPage/components/ContributorTypePicker";
import { topContributorsRoute } from "@/Router/TopContributors";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React, { useMemo } from "react";

export type ToolbarContributorPickerProps = {
    options: ContributorPickerProps["options"];
};
export const ToolbarContributorPicker: React.FC<ToolbarContributorPickerProps> = ({ options }) => {
    const { contributorType, contributorTypeLevel } = useSearch({ from: topContributorsRoute.id });

    const defaultValue = useMemo<Contributor | undefined>(
        () => ({
            contributorType: contributorType ?? "suppliers",
            level: contributorTypeLevel,
        }),
        [contributorType, contributorTypeLevel]
    );

    const navigate = useNavigate();
    const handleContributorTypeSelected = (contributor: Contributor) => {
        navigate({
            search: (current) => ({
                ...current,
                contributorType: contributor.contributorType,
                contributorTypeLevel: contributor.level,
            }),
        });
    };

    return (
        <ContributorTypePicker options={options} defaultValue={defaultValue} onSelect={handleContributorTypeSelected} />
    );
};
