import { Divider, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const EmissionsPerScopeHeader = () => {
    return (
        <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" height={60}>
                <Typography variant="textLg" component="h2" fontWeight={500}>
                    <FormattedMessage defaultMessage="Distribution of emissions per scope" />
                </Typography>
            </Stack>
            <Divider />
        </Stack>
    );
};
