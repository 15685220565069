import { useGetLabelForL1AndL2Handler } from "@/Components/AddActivityForm/AddActivity/Form/helpers";
import { UpdatedBy } from "@/Components/UpdatedBy";
import { getMessageForUnit } from "@/Containers/ManualEmissions/helpers";
import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import { getLabelForUser } from "@/lib/labelling/userLabel";
import { formatNumber } from "@/lib/primitives/numbers";
import { ArrowTopRightOnSquare } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as zod from "zod";
import {
    ActivityDataForEditTable,
    ActivityInformation,
    DataGridFilterOptions,
    isActivityInformation,
    isCountryCode,
    isScopeType,
} from "router";
import { EstimatesCell } from "../../../../Components/Estimates";
import { baseColumnProps } from "../Components/BaseColumn";
import { CategoryColumn } from "../Components/CategoryColumn";
import { RegionColumn } from "../Components/RegionColumn";
import {
    activityComparator,
    addingInformationSchema,
    emissionsSchema,
    getRow,
    useGetActivityOptions,
    useGetCategoryOptionsAndMessages,
} from "./helpers";
import type { Column, RowData } from "./types";

export const useGetColumnsAndRows = (
    data: ActivityDataForEditTable[],
    filterOptions: DataGridFilterOptions,
    onEditClick: (id: string) => void
): { columns: Column[]; rows: RowData[] } => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { messages: categoryMessages, filteredOptions: categoryOptions } =
        useGetCategoryOptionsAndMessages(filterOptions);
    const getLabel = useGetLabelForL1AndL2Handler();
    const l1Options = useGetActivityOptions(filterOptions.activityL1);
    const l2Options = useGetActivityOptions(filterOptions.activityL2);

    const rows = useMemo(() => {
        return data.map((row) => getRow(row));
    }, [data]);

    const columns: Column[] = useMemo(() => {
        const returnColumns: Column[] = [
            {
                ...baseColumnProps,
                field: "scope",
                headerName: formatMessage({
                    defaultMessage: "Scope",
                    description: "Header for scope or connection type column on uploaded table",
                }),
                width: 200,
                type: "singleSelect",
                valueFormatter: (value: string) => {
                    switch (value) {
                        case "scope1":
                            return formatMessage({
                                defaultMessage: "Scope 1",
                                description: "Scope 1",
                            });
                        case "scope2":
                            return formatMessage({
                                defaultMessage: "Scope 2",
                                description: "Scope 2",
                            });
                        case "scope3":
                            return formatMessage({
                                defaultMessage: "Scope 3",
                                description: "Scope 3",
                            });
                        default:
                            return "";
                    }
                },
                valueOptions: [
                    {
                        value: "scope1",
                        label: formatMessage({
                            defaultMessage: "Scope 1",
                            description: "Scope 1",
                        }),
                    },
                    {
                        value: "scope2",
                        label: formatMessage({
                            defaultMessage: "Scope 2",
                            description: "Scope 2",
                        }),
                    },
                    {
                        value: "scope3",
                        label: formatMessage({
                            defaultMessage: "Scope 3",
                            description: "Scope 3",
                        }),
                    },
                ],
            },
            {
                ...baseColumnProps,
                field: "category",
                type: "singleSelect",
                headerName: formatMessage({
                    defaultMessage: "Category",
                    description: "Header for category column on uploaded table",
                }),
                width: 240,
                valueOptions: categoryOptions,
                valueFormatter: (value: string) => {
                    if (isScopeType(value)) {
                        return categoryMessages[value].header;
                    }
                    return "";
                },
                renderCell: ({ value }) => {
                    if (isScopeType(value)) {
                        return <CategoryColumn category={value} />;
                    }
                    return "-";
                },
            },
            {
                ...baseColumnProps,
                field: "activityL1",
                type: "singleSelect",
                headerName: formatMessage({
                    defaultMessage: "Activity L1",
                    description: "Header for activity L1 column on uploaded table",
                }),
                flex: 1,
                minWidth: 150,
                sortComparator: activityComparator,
                valueOptions: l1Options,
                valueGetter: (value: RowData["activityL1"]) => {
                    if (isActivityInformation(value)) {
                        // The default value is only used as a placeholder for a category without L1
                        // we don't want to sort on this value an therefore return an empty string
                        if (value.value === "default") {
                            return undefined;
                        }
                        return value;
                    }
                    return undefined;
                },
                valueFormatter: (value: ActivityInformation | undefined) => {
                    if (isActivityInformation(value)) {
                        if (value.type === "custom") {
                            return value.label ?? value.value;
                        }
                        return getLabel(value.value, value.label);
                    }
                    return "-";
                },
            },

            {
                ...baseColumnProps,
                field: "activityL2",
                type: "singleSelect",
                headerName: formatMessage({
                    defaultMessage: "Activity L2",
                    description: "Header for activity L2 column on uploaded table",
                }),
                flex: 1,
                minWidth: 150,
                valueOptions: l2Options,
                sortComparator: activityComparator,
                valueGetter: (value: RowData["activityL2"]) => {
                    if (isActivityInformation(value)) {
                        // The default value is only used as a placeholder for a category without L1
                        // we don't want to sort on this value an therefore return an empty string
                        if (value.value === "default") {
                            return undefined;
                        }
                        return value;
                    }
                    return undefined;
                },
                valueFormatter: (value: ActivityInformation | undefined) => {
                    if (isActivityInformation(value)) {
                        if (value.type === "custom") {
                            return value.label ?? value.value;
                        }
                        return getLabel(value.value, value.label);
                    }
                    return "-";
                },
            },
            {
                ...baseColumnProps,
                type: "number",
                field: "value",
                headerName: formatMessage({
                    defaultMessage: "Activity",
                    description: "Header for activity column on uploaded table",
                }),
                flex: 1,
                minWidth: 150,
                align: "right",
                renderCell: ({ row, value }) => {
                    if (typeof value === "number" && typeof row.unitOfMeasurement === "string") {
                        return `${formatNumber(value, 2)} ${formatMessage(getMessageForUnit(row.unitOfMeasurement))}`;
                    }
                    return "";
                },
            },
            {
                ...baseColumnProps,
                type: "number",
                field: "emissions",
                headerName: formatMessage({
                    defaultMessage: "Estimated Emissions",
                    description: "Header for estimated emissions column on uploaded table",
                }),
                flex: 1,
                minWidth: 150,
                align: "right",
                valueGetter: (value: RowData["emissions"]) => {
                    const emissionsObject = emissionsSchema.safeParse(value);
                    if (emissionsObject.success) {
                        return emissionsObject.data.type === "regular"
                            ? emissionsObject.data.estimate
                            : (emissionsObject.data.estimateLocation ?? emissionsObject.data.estimateMarket ?? 0);
                    }
                    return 0;
                },
                renderCell: ({ row }) => {
                    const emissionsObject = emissionsSchema.safeParse(row.emissions);
                    if (emissionsObject.success) {
                        return <EstimatesCell emissions={emissionsObject.data} />;
                    }
                    return "-";
                },
            },
            {
                ...baseColumnProps,
                field: "year",
                headerName: formatMessage({
                    defaultMessage: "Year",
                    description: "Header for time of emission column on uploaded table",
                }),
                valueGetter: (_, row) => {
                    const parsedDate = zod.z.date().safeParse(row?.from);
                    if (parsedDate.success) {
                        return dayjs(parsedDate.data).format("YYYY");
                    }
                    return "-";
                },
                width: 80,
            },
            {
                ...baseColumnProps,
                type: "date",
                field: "from",
                headerName: formatMessage({
                    defaultMessage: "From",
                    description: "Header for time of emission column on uploaded table",
                }),
                width: 80,
            },
            {
                ...baseColumnProps,
                type: "date",
                field: "to",
                headerName: formatMessage({
                    defaultMessage: "To",
                    description: "Header for time of emission column on uploaded table",
                }),
                width: 100,
            },
            {
                ...baseColumnProps,
                field: "region",
                headerName: formatMessage({
                    defaultMessage: "Region",
                    description: "Header for region column on uploaded table",
                }),

                valueGetter: (value: RowData["region"]) => {
                    if (value) {
                        return countryCodeLabelRecord[value]?.label;
                    }
                    return undefined;
                },
                renderCell: ({ row }) => {
                    const value = row.region;
                    if (isCountryCode(value)) {
                        return <RegionColumn countryCode={value} />;
                    }
                    return "-";
                },
            },
            {
                ...baseColumnProps,
                field: "supplierName",
                headerName: formatMessage({
                    defaultMessage: "Supplier",
                    description: "Header for supplier column in the activity log",
                }),
                renderCell: ({ row }) => {
                    return row.supplierName ?? "-";
                },
            },
            {
                ...baseColumnProps,
                headerName: formatMessage({
                    defaultMessage: "Supplier ID",
                    description: "Header for supplier id column in the activity log",
                }),
                field: "supplierId",
                renderCell: ({ row }) => {
                    return row.supplierId ?? "-";
                },
            },
            {
                ...baseColumnProps,
                field: "spendCategory",
                headerName: formatMessage({
                    defaultMessage: "Spend category",
                    description: "Header for spend category column in the activity log",
                }),
                width: 240,
                valueGetter: (spendCategory: RowData["spendCategory"]) => {
                    return spendCategory?.label;
                },
                renderCell: ({ row }: { row: RowData }) => {
                    if (!row.spendCategory) return null;
                    return (
                        <Chip
                            size="small"
                            color="primary"
                            label={row.spendCategory?.label}
                            sx={{ textOverflow: "ellipsis", maxWidth: "6rem" }}
                        />
                    );
                },
            },
            {
                ...baseColumnProps,
                field: "businessUnit",
                headerName: formatMessage({
                    defaultMessage: "Business unit",
                    description: "Header for business unit column in the activity log",
                }),
                width: 240,
                valueGetter: (businessUnit: RowData["businessUnit"]) => {
                    return businessUnit?.label;
                },
                renderCell: ({ row }: { row: RowData }) => {
                    if (!row.businessUnit) return null;
                    return (
                        <Chip
                            size="small"
                            color="primary"
                            label={row.businessUnit?.label}
                            sx={{ textOverflow: "ellipsis", maxWidth: "6rem" }}
                        />
                    );
                },
            },
            {
                ...baseColumnProps,
                field: "tags",
                headerName: formatMessage({
                    defaultMessage: "Tags",
                    description: "Header for tags column in the activity log",
                }),
                width: 240,
                valueGetter: (value: RowData["tags"]) => {
                    return value.map((tag) => tag.label);
                },
                renderCell: ({ row }: { row: RowData }) => {
                    return (
                        <Stack direction="row" spacing={0.5} alignItems="center" height="100%">
                            {row.tags.map((tag) => {
                                return (
                                    <Tooltip key={tag.tagId} title={tag.label.length > 8 ? tag.label : null}>
                                        <Chip
                                            size="small"
                                            color="primary"
                                            label={tag.label}
                                            sx={{ textOverflow: "ellipsis", maxWidth: "6rem" }}
                                        />
                                    </Tooltip>
                                );
                            })}
                        </Stack>
                    );
                },
            },

            {
                ...baseColumnProps,
                field: "addingInformation",
                headerName: formatMessage({ defaultMessage: "User", description: "User header in activity log table" }),
                width: 20,
                valueGetter: (value: RowData["addingInformation"]) => {
                    const valueObject = addingInformationSchema.safeParse(value);
                    if (valueObject.success) {
                        return getLabelForUser(valueObject.data.user, intl);
                    }
                    return "";
                },
                renderCell: ({ row }) => {
                    const value = row.addingInformation;
                    const valueObject = addingInformationSchema.safeParse(value);
                    const user = valueObject.success ? valueObject.data.user : null;
                    return <UpdatedBy user={user} />;
                },
            },
            {
                resizable: false,
                field: "id",
                type: "actions",
                width: 20,
                getActions(params) {
                    return [
                        <GridActionsCellItem
                            key="edit"
                            icon={<ArrowTopRightOnSquare />}
                            label={formatMessage({
                                defaultMessage: "Edit",
                                description: "Edit button in the table",
                            })}
                            onClick={() => onEditClick(`${params.id}`)}
                        />,
                    ];
                },
            },
            {
                field: "source",
                headerName: formatMessage({
                    defaultMessage: "Linked source",
                    description: "Header for linked source column on uploaded table",
                }),
                ...baseColumnProps,
                filterable: true,
            },
        ];
        return returnColumns;
    }, [categoryOptions, l1Options, l2Options, categoryMessages, getLabel, formatMessage, intl, onEditClick]);
    return { columns, rows };
};
