import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { isUnitOfMeasurement, UnitOfMeasurement } from "router";
import { UnitMessageAndValue } from "./helpers";

interface Props {
    messages: UnitMessageAndValue[];
    selectedValue?: UnitOfMeasurement;
    onChange: (value: UnitOfMeasurement) => void;
    errorMessage?: string;
    disabled?: boolean;
}

export const UnitPicker: React.FC<Props> = ({ messages, selectedValue, onChange, errorMessage, disabled }) => {
    const defaultMessage = "Unit of Measurement";
    const description = "Unit of Measurement used for emissions";
    return (
        <FormControl fullWidth size="small" disabled={disabled}>
            <Autocomplete
                openOnFocus
                disabled={disabled}
                size="small"
                value={selectedValue ?? ""}
                getOptionLabel={(option) => messages.find((message) => message.value === option)?.message ?? ""}
                options={messages.map((message) => message.value)}
                onChange={(e, value) => {
                    if (value && isUnitOfMeasurement(value)) {
                        onChange(value);
                    }
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                "data-testid": "unit-input-data-sources",
                            }}
                            error={!!errorMessage}
                            label={<FormattedMessage defaultMessage={defaultMessage} description={description} />}
                            variant="outlined"
                        />
                    );
                }}
                fullWidth
            />

            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};
