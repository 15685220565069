import { ButtonProps, IconButtonProps, TooltipProps } from "@mui/material";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";
import React from "react";
import { useIntl } from "react-intl";

type GridToolbarDeleteButtonProps = {
    slotProps?: {
        button?: Partial<Omit<ButtonProps, "startIcon">> & { "data-testid"?: string };
        iconButton?: Partial<IconButtonProps> & { "data-testid"?: string };
        tooltip?: Partial<TooltipProps>;
    };
};

const GridToolbarDeleteButton = React.forwardRef<HTMLButtonElement, GridToolbarDeleteButtonProps>(
    function GridToolbarDeleteButton(props, ref) {
        const rootProps = useGridRootProps();
        const apiRef = useGridApiContext();
        const selectedRows = apiRef.current?.getSelectedRows();

        const useButton = Boolean(rootProps?.slotProps?.toolbar?.deleteButton?.useRegularButton);

        const iconButtonProps = props.slotProps?.iconButton ?? {};
        const buttonProps = props.slotProps?.button ?? {};
        const tooltipProps = props.slotProps?.tooltip || {};

        const { formatMessage } = useIntl();
        const defaultIconButtonLabel = formatMessage(
            {
                defaultMessage:
                    "{numberOfRowsToDelete, plural, =0 {Delete # rows} one {Delete # row} other {Delete # rows}}",
                description: "Aria label for delete rows button",
            },
            { numberOfRowsToDelete: selectedRows?.size }
        );

        const defaultButtonLabel = formatMessage({
            defaultMessage: "Delete",
            description: "Default label for toolbar delete button",
        });

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (selectedRows?.size === 0) return;
            rootProps?.slotProps?.toolbar?.deleteButton?.onDelete?.(event);
            iconButtonProps.onClick?.(event);
        };

        let button: JSX.Element | null = null;
        if (useButton) {
            button = (
                <rootProps.slots.baseButton
                    ref={ref}
                    size="small"
                    disabled={selectedRows?.size === 0}
                    aria-label={defaultIconButtonLabel}
                    variant="contained"
                    startIcon={<rootProps.slots.deleteIcon />}
                    {...buttonProps}
                    {...rootProps.slotProps?.baseButton}
                    onClick={handleClick}
                >
                    {buttonProps?.children ? buttonProps.children : defaultButtonLabel}
                </rootProps.slots.baseButton>
            );
        } else {
            button = (
                <rootProps.slots.baseIconButton
                    ref={ref}
                    size="small"
                    disabled={selectedRows?.size === 0}
                    aria-label={defaultIconButtonLabel}
                    color="secondary"
                    {...iconButtonProps}
                    {...rootProps.slotProps?.baseIconButton}
                    onClick={handleClick}
                >
                    <rootProps.slots.deleteIcon />
                </rootProps.slots.baseIconButton>
            );
        }

        return (
            <rootProps.slots.baseTooltip
                title={defaultIconButtonLabel}
                enterDelay={1000}
                {...tooltipProps}
                {...rootProps.slotProps?.baseTooltip}
            >
                {button}
            </rootProps.slots.baseTooltip>
        );
    }
);

interface DeleteIcon {
    // This matches the type of the other icons in the slots.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteIcon: React.JSXElementConstructor<any>;
}

interface CustomToolbarProps {
    deleteButton?: {
        // Use regular button instead of icon button
        useRegularButton?: boolean;
        onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
    };
}

declare module "@mui/x-data-grid-pro" {
    // Disabling this eslint rule as we simply want to extend the module definition with our custom props.
    // The eslint fix breaks the intended behavior.
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface GridProSlotsComponent extends DeleteIcon {}
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ToolbarPropsOverrides extends CustomToolbarProps {}
}

export { GridToolbarDeleteButton };
