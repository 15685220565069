// Rounds to the nearest number with the specified number of decimal places
const roundToNumber = (num: number, decimalPlaces: number) => {
    const p = 10 ** decimalPlaces;
    const n = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
};

export const formatNumber = (num: number, decimalPlaces: number) => {
    return Intl.NumberFormat(undefined, { minimumFractionDigits: decimalPlaces }).format(
        roundToNumber(num, decimalPlaces)
    );
};
