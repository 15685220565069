import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { Card, CardContent, List, ListItem } from "@mui/material";
import { ScopeCategory } from "router";
import { useGetScopeMessages } from "../../hooks";
import { ListItemContent } from "../SideMenu";

export type PayloadType = {
    name: ScopeCategory | "1" | "2" | "3";
    value: number;
};
type Props = {
    payload: PayloadType[];
};

export const RegularTooltip: React.FC<Props> = ({ payload }) => {
    const allScopeMessages = useGetAllScopeMessages();
    const scopeTopLevelMessages = useGetScopeMessages();
    // if there is no elements with value, return null
    if (payload.reduce((acc, item) => acc + item.value, 0) === 0) {
        return null;
    }
    return (
        <Card>
            <CardContent>
                <List>
                    {payload.map((item) => {
                        const { value, name } = item;
                        const label = ["1", "2", "3"].includes(name)
                            ? scopeTopLevelMessages[`scope${name}Message`]
                            : allScopeMessages[name].header;
                        if (!value) {
                            return null;
                        }
                        return (
                            <ListItem disablePadding key={item.name}>
                                <ListItemContent
                                    item={{
                                        value: name,
                                        label,
                                    }}
                                    emissionsValue={value}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </CardContent>
        </Card>
    );
};
