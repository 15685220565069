/* eslint-disable react/jsx-no-literals */
import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { emissionIntensitiesRootRoute, emissionIntensitiesRootRouteChildren } from "@/Containers/EmissionIntensities";
import { selectIntensityRoute } from "@/Containers/EmissionIntensities/features/new/pages";
import { queryClient } from "@/lib/react-query/client";
import { Stack, Typography } from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext, createRoute, createRouter, Link, Navigate } from "@tanstack/react-router";
import { Root } from "../Containers/Root";
import { activitiesRootRoute, temporaryRedirectLegacyActivityRoute } from "./ActivitiesRoute";
import { activitiyLogRoute, temporaryRedirectLegacyActivityLogRoute } from "./ActivitiesRoute/ActivitiesLogRoute";
import {
    addActivityFindCategoryRoute,
    addActivityFormRoute,
    addActivityRoute,
} from "./ActivitiesRoute/AddActivityRoute";
import { appLayoutRoute } from "./AppLayoutRoute";
import { containerLayoutRoute } from "./ContainerLayoutRoute";
import {
    addDataSourceDetailsRoute,
    addDataSourceRoute,
    addDataSourceStart,
    addDataSourceTypeRoute,
    dataPreferenceCustomCategoriesRoute,
    dataPreferenceDataSourcesOverview,
    dataPreferenceDataSourcesRoute,
    dataPreferencesRoute,
    dataPreferenceTagsRoute,
    editDataSourceRoute,
} from "./DataPreferencesRoute";
import {
    createCustomOptionsForTagRoute,
    createOptionsFromSourceForTagRoute,
    createTagNameRoute,
    createTagRoute,
    editCustomOptions,
    editSourceOptionsForTagRoute,
    editTagRoute,
    overviewTagRoute,
} from "./DataPreferencesRoute/TagRoute";
import { emissionFactorsRoute, emissionFactorsWrapperRoute } from "./EmissionFactors";
import { overviewRoute } from "./Overview";
import { pageLayoutRoute } from "./PageLayoutRoute";
import { topContributorsRoute } from "./TopContributors";
import { searchParamsSchema } from "./search";
import { testCodeSplittingRoute } from "./TestCodeSplittingRoute/route";

interface CustomRouteContext {
    queryClient: QueryClient;
}

export const rootRoute = createRootRouteWithContext<CustomRouteContext>()({
    component: Root,
    validateSearch: searchParamsSchema,
});

const Index = () => {
    // These links are just for development purposes the index route will be the default route and is currently not used
    return (
        <Stack>
            <Typography variant="h1">Index</Typography>
            <Link to="/overview">Landing page</Link>
            <Link to="/activities/edit">Activities</Link>
            <Link to={topContributorsRoute.fullPath}> Top contributors page</Link>
            <Link to={dataPreferenceDataSourcesOverview.fullPath}> Data preferences: Data sources</Link>
            <Link to={overviewTagRoute.fullPath}> Data preferences: Tags</Link>
            <Link to={dataPreferenceCustomCategoriesRoute.fullPath}> Data preferences: Custom categories</Link>
            <Link to={selectIntensityRoute.fullPath}> Data preferences: New emission intensity</Link>
            <Link to={emissionIntensitiesRootRoute.fullPath}> Data preferences: View emission intensities</Link>
            <Link to={addActivityFindCategoryRoute.fullPath}>Add activity</Link>
            <Link to={emissionFactorsRoute.fullPath} params={{ factorType: "activity" }}>
                Emission factors
            </Link>
        </Stack>
    );
};

// Create an index route
const indexRoute = createRoute({
    getParentRoute: () => pageLayoutRoute,
    path: "/",
    component: Index,
    errorComponent: ErrorBoundary,
});

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
    pageLayoutRoute.addChildren([
        overviewRoute,
        indexRoute,
        activitiesRootRoute.addChildren([
            addActivityRoute.addChildren([addActivityFormRoute, addActivityFindCategoryRoute]),
        ]),
        temporaryRedirectLegacyActivityRoute, // Leave until we are willing to break legacy URLs
        testCodeSplittingRoute,
    ]),
    appLayoutRoute.addChildren([
        topContributorsRoute,
        emissionFactorsWrapperRoute.addChildren([emissionFactorsRoute]),
        activitiyLogRoute,
        temporaryRedirectLegacyActivityLogRoute, // Leave until we are willing to break legacy URLs
    ]),
    containerLayoutRoute.addChildren([
        dataPreferencesRoute.addChildren([
            dataPreferenceCustomCategoriesRoute,
            dataPreferenceTagsRoute.addChildren([
                createTagRoute.addChildren([
                    overviewTagRoute,
                    createTagNameRoute,
                    createCustomOptionsForTagRoute,
                    createOptionsFromSourceForTagRoute,
                ]),
                editTagRoute.addChildren([editCustomOptions, editSourceOptionsForTagRoute]),
            ]),
            dataPreferenceDataSourcesRoute.addChildren([
                dataPreferenceDataSourcesOverview,
                editDataSourceRoute,
                addDataSourceRoute.addChildren([addDataSourceStart, addDataSourceTypeRoute, addDataSourceDetailsRoute]),
            ]),
            emissionIntensitiesRootRoute.addChildren(emissionIntensitiesRootRouteChildren),
        ]),
    ]),
]);

// Create the router using your route tree
export const router = createRouter({
    routeTree,
    context: { queryClient },
    defaultNotFoundComponent: () => <Navigate to={overviewRoute.fullPath} replace />,
});

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
