import { useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { CARBON_EVENTS } from "@/lib/tracking";
import { track } from "@ignite-analytics/track";
import { Stack, Switch, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { BackButtonForChart } from "../components/BackButtonForChart";
import { DownloadReport } from "./DownloadReport";
import { EmissionsByYear } from "./EmissionsPerYear";
import EmissionsTable from "./EmissionsTable";
import { useGetEmissionOverYearsConfig } from "./hooks";

const EmissionOverYearsCard: React.FC = () => {
    const { activeTab, setTab, showCompactTable, setShowCompactTable } = useGetEmissionOverYearsConfig();
    const handleChange = (_, val: "table" | "graph") => {
        track('Carbon: "Emissions per year" tab changed', { tab: val });
        setTab(val);
    };
    const setSelectedScope = useSetSelectedScope();
    const selectedScope = useGetSelectedScope();
    const handleShowFullTable = () => {
        CARBON_EVENTS.LANDING_PAGE_TABLE_SIZE_CHANGED(
            { page: "Overview", component: "EmissionOverYearsCard" },
            { expanded: !showCompactTable }
        ).track();
        setShowCompactTable(!showCompactTable);
    };
    return (
        <Stack spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" gap={1}>
                    <Typography variant="textLg" component="h2" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Emissions per year tCO₂e"
                            description="Emissions per year (tCO₂e)"
                        />
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    {activeTab === "table" ? (
                        <Stack direction="row" gap={2} alignItems="center">
                            <DownloadReport />
                            <Stack direction="row" alignItems="center" gap={1} justifyContent="end">
                                <Switch color="secondary" checked={!showCompactTable} onChange={handleShowFullTable} />
                                <Typography variant="buttonMedium">
                                    <FormattedMessage
                                        defaultMessage="Show expanded table"
                                        description="Show full table"
                                    />
                                </Typography>
                            </Stack>
                        </Stack>
                    ) : null}
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <BackButtonForChart
                            visible={selectedScope !== "all"}
                            goBack={() => {
                                setSelectedScope("all", "EmissionOverYearsCard");
                            }}
                        />
                        <ToggleButtonGroup value={activeTab} exclusive>
                            <ToggleButton
                                aria-label="set-table-view"
                                size="small"
                                value="table"
                                onClick={(e) => handleChange(e, "table")}
                                sx={{ textTransform: "none" }}
                            >
                                <FormattedMessage defaultMessage="Table view" description="Table view" />
                            </ToggleButton>
                            <ToggleButton
                                aria-label="set-graph-view"
                                size="small"
                                value="graph"
                                sx={{ textTransform: "none" }}
                                onClick={(e) => handleChange(e, "graph")}
                            >
                                <FormattedMessage defaultMessage="Graph view" description="Graph view" />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Stack>
            </Stack>
            {activeTab === "table" ? <EmissionsTable showCompactTable={showCompactTable} /> : <EmissionsByYear />}
        </Stack>
    );
};

export default EmissionOverYearsCard;
