import { Buffer } from "buffer";

export async function deliverReport(
    base64Data: string,
    mimeType: string,
    reportType: string,
    createdAt: Date,
    reportFormat: string
): Promise<void> {
    const blob = new Blob([Buffer.from(base64Data, "base64")], {
        type: mimeType,
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    // eslint-disable-next-line fp/no-mutating-methods
    const formattedDate = createdAt
        .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    link.download = `${reportType}-${formattedDate}.${reportFormat}`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
}
