import { useGetCurrentFormRoute } from "@/Containers/DataSources/DataSourceForms/hooks";
import { dataPreferenceDataSourcesOverview, editDataSourceRoute } from "@/Router/DataPreferencesRoute";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Link, useParams } from "@tanstack/react-router";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useGetMenuOptions } from "../AddDataSourceType/hooks";

export const ModalHeader: React.FC = () => {
    const { formatMessage } = useIntl();
    const route = useGetCurrentFormRoute();
    const pathParams = useParams({ from: route.id });
    const { options } = useGetMenuOptions();
    const edit = route.id === editDataSourceRoute.id;

    const addTitles = defineMessages({
        add: {
            defaultMessage: "Add data source",
            description: "Add data source title",
        },
        "spend-based": {
            defaultMessage: "Add spend-based emissions data",
            description: "Add spend-based emissions data source title",
        },
        spend: {
            defaultMessage: "Add spend data",
            description: "Add spend data source title",
        },
        supplier: {
            defaultMessage: "Add supplier data",
            description: "Add supplier data source title",
        },
        energy: {
            defaultMessage: "Add electricity and district heating data",
            description: "Add energy data source title",
        },
        airTravel: {
            defaultMessage: "Add air travel data",
            description: "Add air travel data source title",
        },
        other: {
            defaultMessage: "Add custom data",
            description: "Add other data source title",
        },

        edit: {
            defaultMessage: "Edit data source",
            description: "Edit data source title",
        },
    });

    const dataSourceTitle = React.useMemo(() => {
        if (edit) {
            return addTitles.edit;
        }
        const type = "dataSourceType" in pathParams ? pathParams.dataSourceType : null;
        const currentOption = options.find((option) => option.value === type);
        return addTitles[currentOption?.value ?? ""] ?? addTitles.add;
    }, [pathParams, options, addTitles, edit]);

    return (
        <DialogTitle>
            <Stack direction="row" gap={1} alignContent="center" justifyContent="start">
                <Typography variant="textLg">{formatMessage(dataSourceTitle)}</Typography>
            </Stack>
            <Link
                to={dataPreferenceDataSourcesOverview.fullPath}
                style={{
                    textDecoration: "none",
                }}
            >
                <IconButton
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Link>
        </DialogTitle>
    );
};
