import { trpc } from "@/lib/trpc/client";
import { useDebounce } from "@ignite-analytics/general-tools";
import { useDebouncedValue } from "@mantine/hooks";
import { Checkbox, ListItemIcon, ListSubheader, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFilterHandlers, useFilterToggler } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { MenuRow } from "../components/MenuRow";

export const SupplierFilter: React.FC = () => {
    const { setFilter, filter } = useFilterHandlers("supplierIds");
    const [searchPhrase, setSearchPhrase] = useState("");
    const [debouncedSearchPhrase] = useDebouncedValue(searchPhrase, 300);

    const optionsResponse = trpc.suppliers.useQuery({
        searchPhrase: debouncedSearchPhrase,
    });
    const onSearch = useDebounce((query: string) => {
        setSearchPhrase(query);
    }, 500);
    const toggleSupplier = useFilterToggler(filter, setFilter);

    return (
        <FilterValueSelect
            title={<FormattedMessage defaultMessage="Suppliers" description="Header for supplier filter" />}
            onReset={() => setFilter(null)}
            onSearch={onSearch}
            isError={optionsResponse.isError}
            isLoading={optionsResponse.isLoading}
        >
            {optionsResponse.isSuccess && optionsResponse.data.supplierStatus === "no-suppliers" && (
                <Stack direction="row" justifyContent="center" px={2} pb={2}>
                    <Typography color="text.secondary">
                        <FormattedMessage defaultMessage="No results" description="No results text" />
                    </Typography>
                </Stack>
            )}
            {optionsResponse.isSuccess && optionsResponse.data.supplierStatus === "success" && (
                <>
                    <ListSubheader sx={{ lineHeight: 1 }}>
                        <Typography variant="caption">
                            <FormattedMessage
                                defaultMessage="Options from connections"
                                description="Predefined options"
                            />
                        </Typography>
                    </ListSubheader>
                    {optionsResponse.data.suppliers.map((supplier) => (
                        <MenuRow
                            optionLabel={supplier.label}
                            onClick={() => toggleSupplier(supplier.id)}
                            chip
                            key={supplier.id}
                        >
                            <ListItemIcon>
                                <Checkbox size="small" edge="start" checked={filter?.includes(supplier.id) ?? false} />
                            </ListItemIcon>
                        </MenuRow>
                    ))}
                </>
            )}
        </FilterValueSelect>
    );
};
