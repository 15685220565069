import { useIntl } from "react-intl";
import { z } from "zod";
import { CreatedBy, filterField, globalTypes, kgCO2e, tCO2e } from "router";

export type GlobalType = (typeof globalTypes)[number];

const tagColumns = z.record(filterField).optional();

export type DataTable = {
    dataTableId: string;
    name: string;
    globalType?: GlobalType;
};

const dataColumnTypes = ["date", "number", "string", "classification", "relation", "emissions"] as const;
export type DataColumnType = (typeof dataColumnTypes)[number];

const useGetSharedErrorMessages = () => {
    const { formatMessage } = useIntl();

    // Shared error messages
    const missingDataTable = formatMessage({
        defaultMessage: "Missing data table",
        description: "Error message for missing data table in the add connection schema",
    });
    const missingDateField = formatMessage({
        defaultMessage: "Missing date field",
        description: "Error message for missing date field in the connection schema",
    });
    const missingEmissionStructureColumnId = formatMessage({
        defaultMessage: "Missing emission structure field",
        description: "Error message for missing emission structure field in the add connection schema",
    });

    return { missingDataTable, missingDateField, missingEmissionStructureColumnId };
};
// Shared objects

const baseColumnSchema = z.object({
    dataColumnId: z.string(),
    name: z.string(),
    prefixId: z.string().optional(),
});

const columnSchema = baseColumnSchema.extend({
    type: z.enum(dataColumnTypes),
    globalType: z.enum(globalTypes).optional(),
});

export type DataColumn = z.infer<typeof columnSchema>;

const emissionStructureColumn = baseColumnSchema.extend({
    type: z.enum(["classification"]),
});

const dateColumn = baseColumnSchema.extend({
    type: z.enum(["date"]),
});

// Spend objects and schema functions
const spendColumn = baseColumnSchema.extend({
    type: z.enum(["number"]),
});
const emissionFactorColumn = baseColumnSchema.extend({
    type: z.enum(["number"]),
});

const supplierColumn = baseColumnSchema
    .extend({
        type: z.enum(["relation"]),
    })
    .nullable()
    .optional();

const spendSchema = z.object({
    dateColumn,
    emissionStructureColumn,
    spendColumn,
    emissionFactorColumn,
    supplierColumn,
    tagColumns,
});

export type SpendFormType = z.infer<typeof spendSchema>;

export const useGetSpendTableZodSchema = () => {
    // Zod types and schemas
    const { formatMessage } = useIntl();
    const { missingDateField, missingEmissionStructureColumnId } = useGetSharedErrorMessages();

    // Specific error messages
    const missingSpendColumnId = formatMessage({
        defaultMessage: "Missing spend field",
        description: "Error message for missing spend field in the add connection schema",
    });
    const missingEmissionFactorColumnId = formatMessage({
        defaultMessage: "Missing emission factor field",
        description: "Error message for missing emission factor field in the add connection schema",
    });

    return z.object({
        dateColumn: dateColumn.extend({
            dataColumnId: z.string({ required_error: missingDateField }),
        }),
        spendColumn: spendColumn.extend({
            dataColumnId: z.string({ required_error: missingSpendColumnId }),
        }),
        emissionFactorColumn: emissionFactorColumn.extend({
            dataColumnId: z.string({ required_error: missingEmissionFactorColumnId }),
        }),
        emissionStructureColumn: emissionStructureColumn.extend({
            dataColumnId: z.string({ required_error: missingEmissionStructureColumnId }),
        }),
        supplierColumn,
        tagColumns,
    });
};

// Other objects and schema functions

const unitOfMeasurement = z.enum([kgCO2e, tCO2e]);
const calculatedEmissionsColumn = baseColumnSchema.extend({
    type: z.enum(["number"]),
});

const otherConnectionSchema = z.object({
    dateColumn,
    unitOfMeasurement,
    calculatedEmissionsColumn,
    emissionStructureColumn,
    supplierColumn,
    emissionFactorColumn,
    tagColumns,
});

export type OtherTableFormType = z.infer<typeof otherConnectionSchema>;

export const useGetOtherTableZodSchema = () => {
    // Zod types and schemas
    const { formatMessage } = useIntl();
    const { missingDateField, missingEmissionStructureColumnId } = useGetSharedErrorMessages();

    // Specific error messages
    const missingUnitOfMeasurement = formatMessage({
        defaultMessage: "Missing unit of measurement",
        description: "Error message for missing unit of measurement in the add connection schema",
    });
    const missingCalculatedEmissionsColumnId = formatMessage({
        defaultMessage: "Missing calculated emissions field",
        description: "Error message for missing calculated emissions field in the add connection schema",
    });
    const missingEmissionFactorColumnId = formatMessage({
        defaultMessage: "Missing emission factor field",
        description: "Error message for missing emission factor field in the add connection schema",
    });
    return z.object({
        dateColumn: dateColumn.extend({
            dataColumnId: z.string({ required_error: missingDateField }),
        }),
        unitOfMeasurement: z.enum([kgCO2e, tCO2e], { required_error: missingUnitOfMeasurement }),
        calculatedEmissionsColumn: calculatedEmissionsColumn.extend({
            dataColumnId: z.string({ required_error: missingCalculatedEmissionsColumnId }),
        }),
        emissionStructureColumn: emissionStructureColumn.extend({
            dataColumnId: z.string({ required_error: missingEmissionStructureColumnId }),
        }),
        emissionFactorColumn: emissionFactorColumn
            .extend({
                dataColumnId: z.string({ required_error: missingEmissionFactorColumnId }),
            })
            .optional(),
        supplierColumn,
        tagColumns,
    });
};

const formTypes = ["spend", "spend-based", "energy", "other", "supplier"] as const;
export type AddedConnection = {
    updatedAt: Date | null;
    createdBy: CreatedBy;
    createdAt: Date;
    id: string;
    formType: (typeof formTypes)[number];
    name: string;
    tableId: string | null;
};
