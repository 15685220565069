import { styled } from "@mui/material/styles";

export type ChipColor = "green" | "blue" | "red" | "gray" | "yellowGreen" | "yellow" | "darkGreen";

export const getChipColor = (value: ChipColor) => {
    switch (value) {
        case "green":
            return "#DDFDF9";
        case "darkGreen":
            return "#D1F4E0";
        case "blue":
            return "#D5E3FE";
        case "gray":
            return "#F1F2F4";
        case "red":
            return "#fed8d8";
        case "yellowGreen":
            return "#dafac5";
        case "yellow":
            return "#FBEEDA";
    }
};

export const SquareChip = styled("div")((props) => ({
    border: "none",
    backgroundColor: props.color ?? "rgba(0, 0, 0, 0.50)",
    font: "inherit",
    borderRadius: "4px",
    fontSize: "inherit",
    fontWeight: "inherit",
    padding: "0 12px",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    width: "fit-content",
    textOverflow: "ellipsis",
    textAlign: "center",
    display: "flex",
}));
