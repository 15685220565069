import { UpdateActivity } from "@/Components/AddActivityForm/UpdateActivity";
import { SimpleDialog } from "@/Components/SimpleDialog";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { trpc } from "@/lib/trpc/client";
import { Card, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ActivityAuditLog } from "./ActivityAuditLog";
import { ActivityDocuments } from "./ActivityDocuments";
import { ActivityNotes } from "./ActivityNotes";

export const ManageActivityDialog: React.FC = () => {
    const scopeMessages = useGetAllScopeMessages();
    const params: { activityId?: string } = useSearch({ strict: false });
    const activityId = params?.activityId;
    const navigate = useNavigate();

    const { data: activityData } = trpc.getActivity.useQuery(activityId ? { id: activityId } : { id: "" }, {
        enabled: Boolean(activityId),
    });
    const handleClose = () => {
        navigate({ to: ".", search: (prev) => ({ ...prev, activityId: undefined }) });
    };
    return (
        <SimpleDialog
            onClose={handleClose}
            open={Boolean(activityId)}
            title={activityData ? scopeMessages[activityData.scopeType]?.header : ""}
        >
            {activityId ? (
                <Grid container spacing={3} style={{ height: "80vh" }}>
                    <Grid item xs={2} height="100%">
                        <Card component={Paper} sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography variant="textLg" color="black">
                                    <FormattedMessage
                                        defaultMessage="Timeline"
                                        description="Title for card with timeline"
                                    />
                                </Typography>
                                <ActivityAuditLog id={activityId} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} height="100%">
                        <Card component={Paper} sx={{ height: "100%", overflow: "auto" }}>
                            <CardContent>
                                {activityData ? (
                                    <UpdateActivity
                                        id={activityId}
                                        activityValues={activityData}
                                        onCancel={handleClose}
                                    />
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} height="100%" overflow="auto">
                        <Stack spacing={1}>
                            <Card component={Paper}>
                                <CardContent>
                                    <Typography variant="textLg" color="black">
                                        <FormattedMessage
                                            defaultMessage="Documents"
                                            description="Title for documents sections"
                                        />
                                    </Typography>
                                    <ActivityDocuments id={activityId} />
                                </CardContent>
                            </Card>
                            <Card component={Paper}>
                                <CardContent>
                                    <Typography variant="textLg" color="black">
                                        <FormattedMessage
                                            defaultMessage="Notes"
                                            description="Title for documents sections"
                                        />
                                    </Typography>
                                    <ActivityNotes id={activityId} />
                                </CardContent>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
            ) : null}
        </SimpleDialog>
    );
};
