import { RouterType } from "@/types";
import {
    createTRPCProxyClient,
    createTRPCReact,
    httpBatchLink,
    httpLink,
    HTTPLinkOptions,
    splitLink,
} from "@trpc/react-query";
import superjson from "superjson";
import { getCurrentDomain } from "../utils/utils";

export const trpc = createTRPCReact<RouterType>();

export const getTRPCProxyClient = () => {
    const tenant = localStorage.getItem("tenant");

    const trpcURL =
        getCurrentDomain() === "ignite"
            ? (process.env.REACT_APP_IGNITE_TRPC as string)
            : (process.env.REACT_APP_IGNITEPROCUREMENT_TRPC as string);
    const urlParams: HTTPLinkOptions = {
        url: trpcURL,
        headers() {
            return {
                ...(tenant ? { "x-tenant-id": tenant } : {}),
            };
        },
        fetch(url, options) {
            return fetch(url, {
                ...options,
                credentials: "include",
            });
        },
    } as const;

    return createTRPCProxyClient<RouterType>({
        transformer: superjson,
        links: [httpLink(urlParams)],
    });
};

export const getTRPCClient = () => {
    const tenant = localStorage.getItem("tenant");
    const trpcURL =
        getCurrentDomain() === "ignite"
            ? (process.env.REACT_APP_IGNITE_TRPC as string)
            : (process.env.REACT_APP_IGNITEPROCUREMENT_TRPC as string);

    const urlParams = {
        url: trpcURL,
        headers() {
            return {
                ...(tenant ? { "x-tenant-id": tenant } : {}),
            };
        },
        fetch(url, options) {
            return fetch(url, {
                ...options,
                credentials: "include",
            });
        },
    } as const;

    return trpc.createClient({
        transformer: superjson,
        links: [
            splitLink({
                condition(op) {
                    // check for context property `skipBatch`
                    return op.context.skipBatch === true;
                },
                // when condition is true, use normal request
                true: httpLink({
                    ...urlParams,
                }),
                // when condition is false, use batching
                false: httpBatchLink({
                    ...urlParams,
                }),
            }),
        ],
    });
};
