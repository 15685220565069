import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { trpc } from "@/lib/trpc/client";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { FilterField, UnifiedDataSource } from "router";

type ShowExamplesProps = {
    field: FilterField;
    source: UnifiedDataSource;
};

export const ExamplesFromSource: React.FC<ShowExamplesProps> = ({ field, source }) => {
    const { formatMessage } = useIntl();

    const options = trpc.fieldOptionsForDataSource.useQuery(
        source?.discriminator === "connection"
            ? {
                  type: "connection",
                  filter: field,
                  connectionId: source?.id,
              }
            : { type: "supplier", columnId: field.id, filter: field },
        { enabled: !!source }
    );

    if (!options.isSuccess || options.data?.data?.options.length === 0) {
        const getMessage = () => {
            if (options.isSuccess && options.data?.data?.options.length === 0) {
                return formatMessage({
                    defaultMessage: "No examples on this field",
                    description: "No examples found",
                });
            }
            if (options.isError) {
                return formatMessage(
                    {
                        defaultMessage: "Error loading examples: {error}",
                        description: "Error loading examples",
                    },
                    {
                        error: options.error.message,
                    }
                );
            }
            if (options.isLoading) {
                return formatMessage({
                    defaultMessage: "Loading examples...",
                    description: "Loading examples",
                });
            }
        };
        return (
            <Typography variant="textSm" color="gray">
                {getMessage()}
            </Typography>
        );
    }
    return (
        <Grid container gap={1}>
            {options.data.data.options.map((innerOption) => (
                <Grid item key={innerOption.value}>
                    <SquareChip color={getChipColor("blue")}>
                        <Typography>{innerOption.label}</Typography>
                    </SquareChip>
                </Grid>
            ))}
        </Grid>
    );
};
