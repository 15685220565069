import { SimpleDialog } from "@/Components/SimpleDialog";
import { OverviewFilters, setYearPersistant, useGetSearchParamFilters } from "@/Containers/LandingPage/hooks";
import { searchParamsSchema } from "@/Router/search";
import { CARBON_EVENTS } from "@/lib/tracking";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import RestoreIcon from "@mui/icons-material/Restore";
import { Button, Card, CardContent, DialogActions, Grid, Stack } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { assertEmpty } from "utils";
import { z } from "zod";
import { SourceFilterType } from "../constants";
import { FilterContextProvider } from "../filterContext";
import { FilterType } from "../interfaces";
import { FilterMenu } from "./FilterMenu";
import { FilterSideMenu } from "./SideMenu";

interface Props {
    open: boolean;
    onClose: () => void;
}
export const FilterModal: React.FC<Props> = ({ open, onClose }) => {
    const hasNewHeader = useFeatureToggle("carbon-new-header-for-overview");
    const [selectedFilterView, setSelectedFilterView] = useState<FilterType>(
        hasNewHeader ? { type: "sources" } : { type: "year" }
    );

    const filtersFromUrl = useGetSearchParamFilters();

    const [filter, setFilter] = useState<OverviewFilters>(filtersFromUrl);

    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        setFilter(filtersFromUrl);
        onClose();
    }, [filtersFromUrl, onClose]);

    const handleSubmit = useCallback(() => {
        const {
            tagFilters,
            sources,
            electricity,
            businessUnitIds,
            spendCategoryIds,
            supplierIds,
            year,
            dateFilter,
            ...rest
        } = filter;
        // Ensure that all properties have been unpacked (see docstring for assertEmpty)
        assertEmpty(rest);
        CARBON_EVENTS.OVERVIEW_USED_FILTERS(
            { page: "Overview", component: "FilterModal" },
            {
                filterOnTag: Boolean(tagFilters?.length),
                filterOnActivities:
                    sources?.some((source) => source.filterType === SourceFilterType.ACTIVITY_DATA) ?? false,
                filterOnOtherSource: sources?.some((source) => source.filterType === SourceFilterType.SOURCE) ?? false,
            }
        ).track();
        setYearPersistant(year);
        navigate({
            // Explicit return type to ensure that properties are not added with misspelled keys
            search: (prev): z.infer<typeof searchParamsSchema> => ({
                ...prev,
                tagFilters: tagFilters ?? undefined,
                sources:
                    sources?.map((source) =>
                        source.filterType === SourceFilterType.ACTIVITY_DATA
                            ? SourceFilterType.ACTIVITY_DATA
                            : source.id
                    ) ?? undefined,
                electricity,
                year,
                businessUnitIds: businessUnitIds ?? undefined,
                spendCategoryIds: spendCategoryIds ?? undefined,
                supplierIds: supplierIds ?? undefined,
            }),
        });
        onClose();
    }, [filter, navigate, onClose]);

    const { formatMessage } = useIntl();

    const setFilterFunction = useCallback(
        (newFilter: OverviewFilters) => {
            setFilter((prev) => ({ ...prev, ...newFilter }));
        },
        [setFilter]
    );
    return (
        <FilterContextProvider filter={filter} setFilter={setFilterFunction}>
            <SimpleDialog
                white
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                title={formatMessage({
                    defaultMessage: "Overview page display preferences",
                    description: "Overview page display preferences",
                })}
                subTitle={formatMessage({
                    defaultMessage:
                        "Here you can filter the overview page based on different dimensions like year, tags and data sources.",
                    description: "You can filter the data displayed in the overview page by source, tag and year.",
                })}
                dialogContentProps={{
                    sx: {
                        height: "60vh",
                        minHeight: "500px",
                    },
                }}
                actions={
                    <DialogActions>
                        <Button
                            startIcon={<RestoreIcon fontSize="inherit" />}
                            onClick={() => {
                                const filtersValue: OverviewFilters = {
                                    sources: null,
                                    tagFilters: [],
                                    businessUnitIds: null,
                                    spendCategoryIds: null,
                                    supplierIds: null,
                                    // Don't change year or electricity on reset
                                    year: filtersFromUrl.year,
                                    electricity: filtersFromUrl.electricity,
                                    dateFilter: null,
                                };
                                setFilter(filtersValue);
                                navigate({ search: {} });
                                onClose();
                            }}
                            variant="outlined"
                            color="secondary"
                        >
                            <FormattedMessage
                                defaultMessage="Reset all preferences"
                                description="Reset all preferences"
                            />
                        </Button>
                        <Button onClick={handleSubmit} color="primary">
                            <FormattedMessage defaultMessage="Save changes" description="Save changes" />
                        </Button>
                    </DialogActions>
                }
            >
                <Stack direction="row" gap={2} minHeight="350px">
                    <Grid minWidth={200}>
                        <FilterSideMenu filterType={selectedFilterView} onSelect={setSelectedFilterView} />
                    </Grid>
                    <Grid width="100%" maxHeight="60vh" minHeight="350px" overflow="hidden">
                        <Card variant="outlined" sx={{ height: "100%" }}>
                            <CardContent>
                                <FilterMenu filterType={selectedFilterView} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Stack>
            </SimpleDialog>
        </FilterContextProvider>
    );
};
