import { Stack, Typography, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    sendMessage: (message: string) => void;
};
export const LearnMoreForm: React.FC<Props> = ({ sendMessage }) => {
    const [contactMessage, setContactMessage] = useState("");
    return (
        <Stack justifyContent="center" alignItems="center" gap={2}>
            <Stack alignItems="center" gap={1}>
                <Typography variant="displayXs" fontWeight={500}>
                    <FormattedMessage defaultMessage="Learn more" />
                </Typography>
                <Typography textAlign="center" variant="textSm" color="text.text-helper">
                    <FormattedMessage defaultMessage="Schedule a meeting with your Customer Success Manager and see what Carbon can do for you" />
                </Typography>
            </Stack>
            <TextField
                multiline
                rows={5}
                placeholder="Want to add anything?"
                onChange={(e) => setContactMessage(e.target.value)}
                sx={{ width: "100%" }}
            />
            <Button
                fullWidth
                onClick={() => {
                    sendMessage(contactMessage);
                }}
            >
                <FormattedMessage defaultMessage="Contact me" description="Contact me button" />
            </Button>
        </Stack>
    );
};
