import { isNil } from "lodash";

/**
 * @returns `true` if `value` is not `null` or `undefined`, `false` otherwise.
 */
function hasValue<T>(value: T | null | undefined): value is T {
    return !isNil(value);
}

export { hasValue };
