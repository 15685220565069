import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    ApolloProvider as _ApolloProvider,
    from,
    InMemoryCacheConfig,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

/**
 * Copied from `@ignite-analytics/graphql-utilities`
 * to take more control over the GraphQL client creation, e.g. to add dev tools config.
 */
export const WINDOW_MESSAGE_SOURCE_UNAUTHENTICATED = "graphql-unauthenticated";
const unauthenticatedLink = onError(({ graphQLErrors, networkError }) => {
    const isUnauthenticatedNetwork = networkError && "statusCode" in networkError && networkError.statusCode === 401;
    const isUnauthenticatedGraphQL =
        graphQLErrors && graphQLErrors.some((error) => error.extensions?.code === "UNAUTHENTICATED");
    if (isUnauthenticatedNetwork || isUnauthenticatedGraphQL) {
        window.postMessage({ source: WINDOW_MESSAGE_SOURCE_UNAUTHENTICATED }, window.location.origin);
    }
});

export const ApolloProvider = _ApolloProvider;

/**
 * @param uri The uri of the graphql server
 * @param errorHandler a custom error handler
 * @param cacheConfig a custom cache configuration
 * @returns an ApolloClient instance
 */
export function createGraphqlClient(uri: string, errorHandler?: ApolloLink, cacheConfig?: InMemoryCacheConfig) {
    const authenticationLink = setContext(async () => {
        const tenant = localStorage.getItem("tenant");
        const headers = tenant ? { "x-tenant-id": tenant } : {};
        return {
            headers,
            fetchOptions: {
                credentials: "include",
            },
        };
    });
    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 3,
            retryIf: (error, _operation) => !!error && typeof error === "object" && error.message === "Failed to fetch",
        },
    });
    const errorHandlerList = errorHandler ? [errorHandler, unauthenticatedLink] : [unauthenticatedLink];
    const httpLink = new HttpLink({ uri, credentials: "include" });
    const apolloClient = new ApolloClient({
        devtools: {
            enabled: process.env.NODE_ENV !== "production",
            name: "Carbon",
        },
        cache: new InMemoryCache(cacheConfig),
        link: from([...errorHandlerList, authenticationLink, retryLink, httpLink]),
    });

    return apolloClient;
}
