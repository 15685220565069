import { TableCell, Typography, useTheme } from "@mui/material";
import React from "react";

interface Props {
    colSpan?: number;
    rowSpan?: number;
    children: React.ReactNode;
    highlight?: boolean;
    highlightRight?: boolean;
    number?: boolean;
}

export const HeaderCell: React.FC<Props> = ({ colSpan, rowSpan, children, highlight, highlightRight, number }) => {
    const theme = useTheme();

    const borderRightStyle = React.useMemo(
        () =>
            highlightRight
                ? {
                      borderRightColor: theme.palette.primary.main,
                  }
                : {},
        [highlightRight, theme.palette.primary.main]
    );

    const borderTopStyle = React.useMemo(
        () =>
            highlight
                ? {
                      borderTop: 1,
                      borderTopColor: theme.palette.primary.main,
                  }
                : {},
        [highlight, theme.palette.primary.main]
    );

    return (
        <TableCell
            colSpan={colSpan}
            rowSpan={rowSpan}
            sx={{ ...borderTopStyle, ...borderRightStyle }}
            align={number ? "right" : "left"}
        >
            <Typography variant="caption">{children}</Typography>
        </TableCell>
    );
};
