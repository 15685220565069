import { Card, CardContent, Stack } from "@mui/material";
import { LoadingContent } from "./LoadingContent";
import { MissingDataContent } from "./MissingDataContent";
import { ContentHeaderText } from "./Components/ContentHeader";
import { useGetActivityEstimate } from "./hooks";
import { ElectricityCard } from "./ElectricityCard";
import { CalculationInformation } from "./CalculationInformation";

export const ActivityEstimatesCard: React.FC = () => {
    const liveData = useGetActivityEstimate();
    if (liveData.type === "electricity") {
        return (
            <ElectricityCard
                estimates={liveData.estimates}
                emissionFactors={liveData.emissionFactors}
                meta={liveData.metaData}
                certificate={liveData.certificate}
            />
        );
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack gap={2}>
                    <ContentHeaderText />
                    {liveData.type === "loading" && <LoadingContent />}
                    {liveData.type === "missingData" && <MissingDataContent />}
                    {liveData.type === "regularData" && (
                        <CalculationInformation estimate={liveData.estimate} metaData={liveData.metaData} />
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};
