import { Button, Card, CardActions, CardContent, CardHeader, createSvgIcon, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EmissionIntensitySelect } from "./EmissionIntensityCard";

const ErrorShapeIcon = createSvgIcon(
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="8" width="32" height="32" rx="16" fill="#FEF1F1" />
        <rect x="1" y="1" width="46" height="46" rx="23" stroke="#F86F6E" strokeOpacity="0.16" strokeWidth="2" />
        <rect x="5" y="5" width="38" height="38" rx="19" stroke="#F86F6E" strokeOpacity="0.32" strokeWidth="2" />
        <g clipPath="url(#clip0_1433_26358)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9996 17.9982C20.685 17.9982 17.998 20.6853 17.998 23.9999C17.998 27.3145 20.685 30.0015 23.9996 30.0015C27.3143 30.0015 30.0013 27.3145 30.0013 23.9999C30.0013 20.6853 27.3143 17.9982 23.9996 17.9982ZM16.668 23.9999C16.668 19.9507 19.9505 16.6682 23.9996 16.6682C28.0488 16.6682 31.3313 19.9507 31.3313 23.9999C31.3313 28.049 28.0488 31.3315 23.9996 31.3315C19.9505 31.3315 16.668 28.049 16.668 23.9999ZM23.9996 20.0015C24.3669 20.0015 24.6646 20.2993 24.6646 20.6665V24.6665C24.6646 25.0338 24.3669 25.3315 23.9996 25.3315C23.6324 25.3315 23.3346 25.0338 23.3346 24.6665V20.6665C23.3346 20.2993 23.6324 20.0015 23.9996 20.0015ZM23.3346 27.3332C23.3346 26.9659 23.6324 26.6682 23.9996 26.6682H24.0063C24.3736 26.6682 24.6713 26.9659 24.6713 27.3332C24.6713 27.7005 24.3736 27.9982 24.0063 27.9982H23.9996C23.6324 27.9982 23.3346 27.7005 23.3346 27.3332Z"
                fill="#EB4143"
            />
        </g>
        <defs>
            <clipPath id="clip0_1433_26358">
                <rect width="16" height="16" fill="white" transform="translate(16 16)" />
            </clipPath>
        </defs>
    </svg>,
    "ErrorShapeIcon"
);

const ErrorEmissionIntensityCard: React.FC = () => {
    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={
                    <FormattedMessage
                        defaultMessage="Emission Intensity"
                        description="Header for emission intensity widget on the overview page when an error occurs"
                    />
                }
                action={<EmissionIntensitySelect />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text["text-secondary"],
                }}
            />
            <CardContent
                component={Stack}
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                spacing={3}
            >
                <ErrorShapeIcon sx={{ height: "44px", width: "44px", margin: "-4px" }} />
                <Typography variant="textSm" color={(t) => t.palette.text["text-body"]}>
                    <FormattedMessage
                        defaultMessage="Data could not be loaded. Refresh the page or contact Ignite support if the problem persists."
                        description="Error message shown in the emission intensity widget if an error occurs"
                    />
                </Typography>
            </CardContent>
            {window.Intercom && (
                <CardActions>
                    <Button
                        color="secondary"
                        size="xsmall"
                        onClick={() =>
                            window.Intercom(
                                "showNewMessage",
                                "Hi, I'm encountering a problem with my emission intensities on the Emissions overview page."
                            )
                        }
                    >
                        <FormattedMessage
                            defaultMessage="Contact Ignite Support"
                            description="CTA to contact Ignite support when an error occurs when trying to show an emission intensity"
                        />
                    </Button>
                </CardActions>
            )}
            {!window.Intercom && (
                <CardActions>
                    <Button size="xsmall" sx={{ visibility: "hidden" }} disabled />
                </CardActions>
            )}
        </Card>
    );
};

export { ErrorEmissionIntensityCard };
