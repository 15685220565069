import { InfoCircle } from "@/lib/svg/InfoCircle";
import { FormattedMessage } from "react-intl";
import { Stack, Typography } from "@mui/material";

type Props = {
    type: "supplier" | "spend";
};
export const MissingInformationContent: React.FC<Props> = ({ type }) => {
    return (
        <Stack padding={4} gap={2} alignItems="center" textAlign="center">
            <InfoCircle />
            <Typography variant="textMd">
                {type === "supplier" ? (
                    <FormattedMessage
                        defaultMessage="Please select a supplier and a date range to see how much CO₂e will be removed from the spend based estimates."
                        description="Text for the remove emissions from spend section"
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage="Please select a date range to see how much CO₂e will be removed from the spend data."
                        description="Text for the remove emissions from spend section"
                    />
                )}
            </Typography>
        </Stack>
    );
};
