import { styled, alpha } from "@mui/material";

/**
 * TODO: This should likely be a shared component in the future. Not sure if we should solve like this in CSS or just use SVG ref theme-package alerts.
 */
export const AlertIconWrapper = styled("div")<{ color: "error" | "warning" | "success" }>(({ theme, color }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: alpha(theme.palette[color].main, 0.08),
    borderRadius: "50%",
    position: "relative",
    padding: theme.spacing(1),
    "&::before, &::after": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
    },
    "&::before": {
        border: `2px solid ${alpha(theme.palette[color].main, 0.32)}`,
        transform: "scale(1.2)",
    },
    "&::after": {
        border: `2px solid ${alpha(theme.palette[color].main, 0.16)}`,
        transform: "scale(1.4)",
    },
}));
