/* eslint-disable no-nested-ternary */
import { useAlert } from "@/Providers/Alerts";
import { trpc } from "@/lib/trpc/client";
import { useIntl } from "react-intl";

export const useGetMutationCleanupFunction = () => {
    const { formatMessage } = useIntl();
    const utils = trpc.useContext();
    const { alertUser } = useAlert();
    const onSuccess = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Activity was created",
                description: "Alert message when adding activity data",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.activityDataInEditTable.cancel();
        utils.activityDataInEditTable.refetch();
        utils.overview.cancel();
        utils.overview.refetch();
    };
    const onFinishedDelete = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Activity was deleted",
                description: "Alert message when deleting activity data",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.activityDataInEditTable.cancel();
        utils.activityDataInEditTable.refetch();
        utils.overview.cancel();
        utils.overview.refetch();
    };
    const onErrorDelete = (errorMessage: string) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred while deleting activity: {error}",
                    description: "Alert message when deleting activity data",
                },
                {
                    error: errorMessage,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };
    const onError = (errorMessage: string) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred while adding emissions: {error}",
                    description: "Alert message when adding emissions data",
                },
                {
                    error: errorMessage,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };
    const onSuccessfulSync = () => {
        alertUser({
            value: formatMessage({
                defaultMessage: "Successfully synced",
                description: "Alert message when syncing total values",
            }),
            title: formatMessage({
                defaultMessage: "Success",
                description: "Success",
            }),
            severity: "success",
        });
        utils.activityDataInEditTable.cancel();
        utils.activityDataInEditTable.refetch();
        utils.overview.cancel();
        utils.overview.refetch();
    };
    const onErrorSync = (errorMessage: string) => {
        alertUser({
            value: formatMessage(
                {
                    defaultMessage: "An error occurred while syncing: {error}",
                    description: "Alert message when syncing total values",
                },
                {
                    error: errorMessage,
                }
            ),
            title: formatMessage({
                defaultMessage: "Error",
                description: "Error",
            }),
            severity: "error",
        });
    };

    return { onSuccess, onError, onFinishedDelete, onErrorDelete, onSuccessfulSync, onErrorSync };
};
