import { PermissionRouterContext } from "@/Router";
import { redirect } from "@tanstack/react-router";
import { getCurrentDomain } from "../utils/utils";

type PermissionObject = "general" | "privileged";
type PermissionRelation = "read" | "write";

type PermissionNamespace = "general";

type PermissionRelationTuple = {
    object: PermissionObject;
    relation: PermissionRelation;
};

const isMocked = process.env.REACT_APP_INITIALIZE_MOCK;

async function checkPermissions(
    namespace: PermissionNamespace,
    relationTuples: PermissionRelationTuple[]
): Promise<boolean[]> {
    if (isMocked) {
        return Array(relationTuples.length).fill(true);
    }
    const authSidecarUrl =
        getCurrentDomain() === "ignite"
            ? process.env.REACT_APP_AUTHSIDECAR_PUBLIC_IGNITE_URL
            : process.env.REACT_APP_AUTHSIDECAR_PUBLIC_IGNITEPROCUREMENT_URL;
    const res = await fetch(`${authSidecarUrl}/api/v1/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    const data: boolean[] = await res.json();
    return Array.isArray(data) ? data : Array(relationTuples.length).fill(false);
}

export default checkPermissions;
export type { PermissionNamespace, PermissionRelationTuple };

/**
 * A function to check if the user has the editor permission, redirects to the overview page if the user is not an editor.
 * Use this function in the beforeLoad of a route.
 * @param routeMeta An object with the router context
 */
export async function isEditorRoute(routeMeta: { context: PermissionRouterContext }) {
    if (routeMeta.context?.isEditor === false) {
        throw redirect({
            to: "/overview",
        });
    }
    if (routeMeta.context?.isEditor === undefined) {
        const isEditor = await checkPermissions("general", [{ object: "general", relation: "write" }]);
        if (!isEditor[0]) {
            throw redirect({
                to: "/overview",
            });
        }
    }
}
