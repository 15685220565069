/* eslint-disable fp/no-mutating-methods */
import {
    DataGrid,
    GridToolbarDeleteButton,
    GridToolbarFilterButton,
    GridToolbarSearchField,
    GridToolbarTotalItemsContainer,
} from "@/Components/DataGrid";
import { DeleteModal } from "@/Components/DeleteModal";
import { ManageActivityDialog } from "@/Containers/ManageActivity";
import { usePermission } from "@/Providers/permissions";
import { editRoute } from "@/Router/DataInputRoute/EditRoute";
import { findCategory } from "@/Router/DataInputRoute/FormV2";
import { emissionFactorsRoute } from "@/Router/EmissionFactors";
import { CARBON_EVENTS } from "@/lib/tracking";
import { Plus } from "@ignite-analytics/icons";
import { Button, Divider, Stack, Typography } from "@mui/material";
import {
    DataGridProProps,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridFilterItem,
    GridFilterModel,
    GridRowSelectionModel,
    GridToolbarContainer,
    useGridApiContext,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useNavigate, useSearch } from "@tanstack/react-router";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";

import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps";
import { ActivityDataForEditTable, DataGridFilterOptions } from "router";
import { CustomFilterPanel } from "./Components/FilterPanel";
import { useGetColumnsAndRows } from "./RenderFunctions";
import { useGetCustomGridStyle } from "./hooks";

const arrayOfStrings = z.array(z.string());

export type ActivityData =
    | { status: "error" }
    | { status: "loading" }
    | { status: "success"; activityData: ActivityDataForEditTable[] };

const initialState: DataGridProProps["initialState"] = {
    columns: {
        columnVisibilityModel: {
            scope: false,
            source: false,
            supplierId: false,
            to: false,
            from: false,
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        },
    },
};

type FilterOptions = { status: "error" } | { status: "loading" } | { status: "success"; data: DataGridFilterOptions };

type Props = {
    addedActivityData: ActivityData;
    filterOptions: FilterOptions;
    deleteActivities: (ids: string[]) => void;
};

const GridToolbar = () => {
    const isEditor = usePermission("general", { object: "general", relation: "write" });
    const { formatMessage } = useIntl();
    const selectedRows = useGridApiContext().current?.getSelectedRows();
    const deleteRowsButtonLabel = formatMessage(
        {
            defaultMessage: `{numberOfActivitiesToDelete, plural,
                    =0 {Delete activity}
                    one {Delete # activity}
                    other {Delete # activities}
                }`,
            description: "Aria label for delete activities button",
        },
        {
            numberOfActivitiesToDelete: selectedRows?.size,
        }
    );
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                    <GridToolbarFilterButton />
                </Stack>
                <Stack direction="row" alignItems="center" columnGap={1}>
                    <GridToolbarDeleteButton
                        slotProps={{
                            button: {
                                "data-testid": "delete-activities",
                                "aria-label": deleteRowsButtonLabel,
                                children: deleteRowsButtonLabel,
                                disabled: !isEditor || selectedRows?.size === 0,
                            },
                        }}
                    />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};
const slots: DataGridProProps["slots"] = {
    toolbar: GridToolbar,
    filterPanel: CustomFilterPanel,
};

export const ActivityHistoryPage: React.FC<Props> = ({ addedActivityData, deleteActivities, filterOptions }) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = React.useState(false);
    const { activityL1, activityL2, activityIds, year, category, scope, supplierId, source, searchString } = useSearch({
        from: editRoute.id,
    });
    const { formatMessage } = useIntl();
    const apiRef = useGridApiRef();
    // For handling search params
    const customGridStyle = useGetCustomGridStyle();
    const [activitiesToDelete, setActivitiesToDelete] = React.useState<string[]>([]);
    const activityFilters: GridFilterItem[] = React.useMemo(() => {
        const filters: GridFilterItem[] = [];
        activityL1 && filters.push({ field: "activityL1", operator: "is", value: activityL1 });
        activityL2 && filters.push({ field: "activityL2", operator: "is", value: activityL2 });
        activityIds && filters.push({ field: "id", operator: "isAnyOf", value: activityIds });
        source && filters.push({ field: "source", operator: "equals", value: source });
        year &&
            filters.push({
                field: "year",
                operator: "contains",
                value: year.toString(),
            });
        category && filters.push({ field: "category", operator: "is", value: category });
        scope && filters.push({ field: "scope", operator: "is", value: `scope${scope}` });
        supplierId && filters.push({ field: "supplierId", operator: "equals", value: supplierId.toString() });
        return filters;
    }, [activityL1, activityL2, activityIds, source, year, category, scope, supplierId]);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [...activityFilters],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [searchString],
    });

    const deleteSelectedActivities = () => {
        deleteActivities(activitiesToDelete);
        CARBON_EVENTS.ACTIVITIES_DELETED(
            { page: "ActivityLog" },
            {
                numberOfActivities: activitiesToDelete.length,
            }
        ).track();
        setActivitiesToDelete([]);
    };
    const activityData = useMemo(() => {
        if (addedActivityData.status === "success") {
            return addedActivityData.activityData;
        }
        return [];
    }, [addedActivityData]);

    const filterOptionsData = useMemo(() => {
        if (filterOptions.status === "success") {
            return filterOptions.data;
        }
        return { activityL1: [], activityL2: [], scope: [], dataSourceType: [] };
    }, [filterOptions]);

    const onActivityClick = (activityId: string) => {
        navigate({ search: (prev) => ({ ...prev, activityId }) });
    };
    const { columns, rows } = useGetColumnsAndRows(activityData, filterOptionsData, onActivityClick);

    const slotProps: GridProSlotProps = useMemo(
        () => ({
            toolbar: {
                filter: {
                    numActiveFilters: filterModel.items.length,
                },
                deleteButton: {
                    useRegularButton: true,
                    onDelete: () => setOpenModal(true),
                },
                search: {
                    onChange: (value) => {
                        navigate({ search: (prev) => ({ ...prev, searchString: value || undefined }), replace: true });
                    },
                },
            },
        }),
        [navigate, filterModel.items.length]
    );

    const onRowSelectionModelChange = useCallback(
        (ids: GridRowSelectionModel) => {
            const parsedIds = arrayOfStrings.safeParse(ids);
            if (parsedIds.success) setActivitiesToDelete(parsedIds.data);
        },
        [setActivitiesToDelete]
    );

    const onFilterModelChange = useCallback(
        (model: GridFilterModel) => {
            setFilterModel(model);
        },
        [setFilterModel]
    );

    return (
        <>
            <Stack direction="row" py={3} justifyContent="space-between" alignItems="center">
                <Stack gap={1}>
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Activity log" description="Activity log header" />
                    </Typography>
                    <Typography variant="textSm" color={(theme) => theme.palette.text["text-placeholder"]}>
                        <FormattedMessage
                            defaultMessage="Collection of all your activities"
                            description="Activity log header description"
                        />
                    </Typography>
                </Stack>
                <Stack direction="row" columnGap={2}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => {
                            CARBON_EVENTS.VIEW_EMISSION_FACTORS_CLICK({
                                page: "ActivityLog",
                            }).track();
                            navigate({
                                from: editRoute.fullPath,
                                to: emissionFactorsRoute.fullPath,
                                params: { factorType: "activity" },
                            });
                        }}
                    >
                        <FormattedMessage
                            defaultMessage="View emission factors"
                            description="Button label for navigating to emission factor data base"
                        />
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            CARBON_EVENTS.ADD_ACTIVITY_DATA_CLICKED({
                                page: "ActivityLog",
                            }).track();
                            navigate({ from: editRoute.fullPath, to: findCategory.fullPath });
                        }}
                        startIcon={<Plus fontSize="inherit" />}
                    >
                        <FormattedMessage
                            defaultMessage="Add activity"
                            description="Button label for navigating to adding emission log data"
                        />
                    </Button>
                </Stack>
            </Stack>
            <Stack direction="column" spacing={3}>
                <div style={{ boxSizing: "border-box", height: "100%", position: "relative", background: "white" }}>
                    <Stack position="absolute" top={0} bottom={0} right={0} left={0} display="flex" gap={2}>
                        <Stack
                            sx={{
                                background: "white",
                                width: "100%",
                                height: "100%",
                                overflowX: "auto",
                                minHeight: "79vh",
                            }}
                        >
                            <DataGrid
                                disableColumnMenu
                                disableColumnReorder
                                sx={customGridStyle}
                                apiRef={apiRef}
                                slots={slots}
                                slotProps={slotProps}
                                checkboxSelection
                                loading={addedActivityData.status === "loading"}
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={onRowSelectionModelChange}
                                columns={columns}
                                rows={rows}
                                initialState={initialState}
                                filterModel={filterModel}
                                onFilterModelChange={onFilterModelChange}
                            />
                        </Stack>
                    </Stack>
                </div>
            </Stack>
            <ManageActivityDialog />
            <DeleteModal
                onDelete={() => {
                    deleteSelectedActivities();
                    setOpenModal(false);
                }}
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={formatMessage({
                    defaultMessage: "Delete activities",
                    description: "Delete activities header",
                })}
                subTitle={formatMessage(
                    {
                        defaultMessage:
                            "Are you sure you want to delete {number} activities? This is a permanent action.",
                        description: "Delete activities subtitle",
                    },
                    { number: activitiesToDelete.length }
                )}
            />
        </>
    );
};
