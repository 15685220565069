import axios, { AxiosHeaders, AxiosRequestConfig } from "axios";
import { v4 } from "uuid";
import { getCurrentDomain } from "../utils/utils";

const TOKEN = "token";
const filesUrl =
    (getCurrentDomain() === "ignite"
        ? process.env.REACT_APP_FILES_SERVICE_BASE_IGNITE_URL
        : process.env.REACT_APP_FILES_SERVICE_BASE_IGNITEPROCUREMENT_URL) ??
    "https://files-service.igniteprocurement.com";

const getToken = () => localStorage.getItem(TOKEN);

const contentTypeExists = (headers: Record<string, string>): boolean => {
    return Object.keys(headers).findIndex((key) => key.toLocaleLowerCase() === "content-type") !== -1;
};

const applyHeadersMiddleware = async (config: AxiosRequestConfig) => {
    const token = getToken();
    const headers = config.headers as AxiosHeaders;
    headers.Authorization = `JWT ${token}`;
    headers["x-request-id"] = v4();
    if (!contentTypeExists(headers)) {
        headers["Content-Type"] = "application/json";
    }
    return {
        ...config,
        headers,
    };
};

const createClient = (config: AxiosRequestConfig) => {
    const client = axios.create(config);
    client.interceptors.request.use(applyHeadersMiddleware);
    return client;
};

export const filesServiceHttp = createClient({ baseURL: filesUrl, withCredentials: true });
