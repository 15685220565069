import { RouterType } from "@/types";
import { WarningCircle } from "@/lib/svg/warningCircle";
import { FormattedMessage } from "react-intl";
import { Container, Stack, Typography } from "@mui/material";
import { TRPCClientErrorLike } from "@trpc/client";
import React from "react";

import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

interface Props {
    error: TRPCClientErrorLike<RouterType> | null;
}
const PermissionContainer: React.FC<React.PropsWithChildren<Props>> = ({ error, children }) => {
    if (error?.data) {
        if (error.data.code === "FORBIDDEN") {
            return (
                <Container maxWidth={false}>
                    <Stack direction="column" justifyContent="center" alignItems="center" height="calc(100vh - 200px)">
                        <Stack direction="row" paddingBottom={4}>
                            <WarningCircle />
                        </Stack>
                        <Typography variant="h3">
                            <FormattedMessage
                                defaultMessage="You don't have access"
                                description="Accessmessage in permission container"
                            />
                        </Typography>
                        <Typography variant="textLg">
                            <FormattedMessage
                                defaultMessage="Oh no! You don't have permission to access this page."
                                description="Accessmessage in permission container"
                            />
                        </Typography>
                    </Stack>
                </Container>
            );
        }
        if (error.data.code === "UNAUTHORIZED") {
            return (
                <Container maxWidth={false}>
                    <Stack direction="column" justifyContent="center" alignItems="center" height="calc(100vh - 200px)">
                        <Stack direction="row" paddingBottom={4}>
                            <WarningCircle />
                        </Stack>
                        <Typography variant="h3">
                            <FormattedMessage
                                defaultMessage="You are not logged in"
                                description="Accessmessage in permission container"
                            />
                        </Typography>
                        <Typography variant="textLg">
                            <FormattedMessage
                                defaultMessage="Oh no! You don't have permission to access this page."
                                description="Accessmessage in permission container"
                            />
                        </Typography>
                    </Stack>
                </Container>
            );
        }
    }
    return <ErrorBoundary error={error}>{children}</ErrorBoundary>;
};

export default PermissionContainer;
