import { ShapeIcon } from "@ignite-analytics/components";
import { ExclamationCircle } from "@ignite-analytics/icons";
import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EmissionIntensitySelect } from "./EmissionIntensityCard";

const ErrorEmissionIntensityCard: React.FC = () => {
    return (
        <Card height="100%" component={Stack} justifyContent="space-between">
            <CardHeader
                title={
                    <FormattedMessage
                        defaultMessage="Emission Intensity"
                        description="Header for emission intensity widget on the overview page when an error occurs"
                    />
                }
                action={<EmissionIntensitySelect />}
                titleTypographyProps={{
                    variant: "textSm",
                    fontWeight: 500,
                    color: (t) => t.palette.text.textSecondary,
                }}
            />
            <CardContent
                component={Stack}
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                spacing={3}
            >
                <ShapeIcon size="small" variant="outline" color="error">
                    <ExclamationCircle />
                </ShapeIcon>
                <Typography variant="textSm" color={(t) => t.palette.text.textBody}>
                    <FormattedMessage
                        defaultMessage="Data could not be loaded. Refresh the page or contact Ignite support if the problem persists."
                        description="Error message shown in the emission intensity widget if an error occurs"
                    />
                </Typography>
            </CardContent>
            {window.Intercom && (
                <CardActions>
                    <Button
                        color="secondary"
                        size="xsmall"
                        onClick={() =>
                            window.Intercom(
                                "showNewMessage",
                                "Hi, I'm encountering a problem with my emission intensities on the Emissions overview page."
                            )
                        }
                    >
                        <FormattedMessage
                            defaultMessage="Contact Ignite Support"
                            description="CTA to contact Ignite support when an error occurs when trying to show an emission intensity"
                        />
                    </Button>
                </CardActions>
            )}
            {!window.Intercom && (
                <CardActions>
                    <Button size="xsmall" sx={{ visibility: "hidden" }} disabled />
                </CardActions>
            )}
        </Card>
    );
};

export { ErrorEmissionIntensityCard };
