import { RegionColumn } from "@/Containers/ActivityLog/Table/Components/RegionColumn";
import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import { Link, Stack, Typography, useTheme } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useIntl } from "react-intl";
import { countryCodeOptions } from "router";

import { ColumnSpendBased } from "./types";

const DEFAULT_COLUMN_WIDTH = 150;

const baseColumnProps: Partial<ColumnSpendBased> = {
    hideSortIcons: true,
    align: "left",
    headerAlign: "left",
    width: DEFAULT_COLUMN_WIDTH,
    renderHeader: (params: GridColumnHeaderParams) => {
        return (
            <Stack direction="row" alignItems="center">
                <Typography variant="inherit">{params.colDef.headerName}</Typography>
            </Stack>
        );
    },
};

export const useGetColumns = (): ColumnSpendBased[] => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    // Column specific to activity based calculations
    const spendBasedColumns: ColumnSpendBased[] = [
        {
            ...baseColumnProps,
            field: "category",
            headerName: formatMessage({
                defaultMessage: "Category",
                description: "Header for category column on uploaded table",
            }),
            width: 200,
            flex: 0.3,
        },
        {
            ...baseColumnProps,
            field: "emissionFactor",
            headerName: formatMessage({
                defaultMessage: "Emission factor [kg CO₂e/€]",
                description: "Header for emission factor column on uploaded table",
            }),
            width: 250,
            type: "number",
        },
        {
            ...baseColumnProps,
            field: "region",
            headerName: formatMessage({
                defaultMessage: "Region",
                description: "Header for region column on uploaded table",
            }),
            width: 200,
            valueGetter: (value: string) => {
                if (value) {
                    return countryCodeLabelRecord[value]?.label;
                }
                return undefined;
            },
            renderCell: (row) => {
                const value = row.row.region;
                if (value && countryCodeOptions.includes(value)) {
                    return <RegionColumn countryCode={value} />;
                }
                return "-";
            },
        },

        {
            ...baseColumnProps,
            field: "currency",
            headerName: formatMessage({
                defaultMessage: "Currency",
                description: "Header for currency column on uploaded table",
            }),
            width: 150,
        },
        {
            ...baseColumnProps,
            field: "year",
            headerName: formatMessage({
                defaultMessage: "Year",
                description: "Header for year column on uploaded table",
            }),
            width: 100,
        },
        {
            ...baseColumnProps,
            field: "source",
            headerName: formatMessage({
                defaultMessage: "Source",
                description: "Header for source column on uploaded table",
            }),
            width: 300,
            renderCell: ({ row }) => {
                const { link, source } = row;
                if (!link) {
                    return <Typography variant="textSm">{source}</Typography>;
                }
                return (
                    <Stack direction="row" gap={0.3} alignItems="center">
                        <Link href={link} target="_blank" rel="noopener noreferrer" color={theme.palette.primary.main}>
                            <Typography variant="textSm">{source}</Typography>
                        </Link>
                    </Stack>
                );
            },
        },
        {
            ...baseColumnProps,
            type: "boolean",
            field: "adjustedByIgnite",
            headerName: formatMessage({
                defaultMessage: "Adjusted by Ignite",
                description: "Header for adjusted by Ignite column on uploaded table",
            }),
        },
    ];

    return spendBasedColumns;
};
