import PermissionContainer from "@/Components/PermissionContainer";
import { RouteButton } from "@/Components/RouteButton";
import { usePermission } from "@/Providers/permissions";
import { findCategory } from "@/Router/DataInputRoute/FormV2";
import { dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { overviewRoute } from "@/Router/Overview";
import { pixelsToRem } from "@/lib/accessibility";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Company, Gear, LocationPin, Plus } from "@ignite-analytics/icons";
import { Button, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import MigrationTools from "../Components/MigrationTools";
import { FilterOverview } from "./Filter";
import { LandingPageContent } from "./LandingPageContent";
import { EmissionIntensityTour } from "./LandingPageContent/EmissionIntensityCard/Tour";
import { LearnMore } from "./LearnMore";
import { NoData } from "./NoData";
import { SourceBreakdownWrapper } from "./SourceBreakdownWrapper";
import { WatchDemo } from "./WatchDemo";
import { setYearPersistant, useGetSearchParamFilters, useSelectedYear } from "./hooks";

export const LandingPage: React.FC = () => {
    const year = useSelectedYear();
    const { electricity, ...filters } = useGetSearchParamFilters();
    const { viewType, tour } = useSearch({ from: overviewRoute.fullPath });
    const utils = trpc.useContext();
    const navigate = useNavigate();
    const newHeader = useFeatureToggle("carbon-new-header-for-overview");
    const { error, isSuccess, data, isLoading } = trpc.overview.useQuery(
        { filters, electricity },
        {
            retry,
        }
    );
    const isEditor = usePermission("general", { object: "general", relation: "write" });
    useEffect(() => {
        if (isSuccess) {
            utils.overviewPerSource.prefetch({ filters, electricity });
            utils.getEmissionTags.prefetch();
        }
    }, [isSuccess, utils.overviewPerSource, utils.getEmissionTags, filters, electricity]);

    const handleAddActivityClick = () => {
        CARBON_EVENTS.ADD_ACTIVITY_DATA_CLICKED({
            page: "Overview",
            component: "AddActivityButton",
        }).track();
        navigate({ to: findCategory.fullPath });
    };
    const hasData = (data?.emissionData?.total?.co2 ?? 0) > 0 || isLoading;

    return (
        <Stack spacing={2} pb={4}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" component="h1" fontWeight="bold">
                    <FormattedMessage defaultMessage="Carbon accounting" description="Emissions page overview header" />
                </Typography>
                {isEditor && (
                    <Link to={dataPreferenceDataSourcesOverview.fullPath}>
                        <Button variant="text" size="small" color="ghostGray" startIcon={<Gear />}>
                            <FormattedMessage defaultMessage="Settings" />
                        </Button>
                    </Link>
                )}
            </Stack>
            <PermissionContainer error={error}>
                <Stack spacing={2}>
                    {newHeader ? (
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" gap={2} alignItems="center">
                                <Typography variant="textLg" component="h2" fontWeight={500} noWrap overflow="visible">
                                    <FormattedMessage
                                        defaultMessage="Key insights"
                                        description="Emissions page overview header"
                                    />
                                </Typography>
                                <ToggleButtonGroup
                                    sx={{ height: pixelsToRem(40) }}
                                    value={electricity}
                                    exclusive
                                    onChange={(e, selectedElectricity) => {
                                        if (selectedElectricity === null) return;
                                        navigate({ search: (prev) => ({ ...prev, electricity: selectedElectricity }) });
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                defaultMessage="Location-based estimates for electricity emissions"
                                                description="Tooltip for button group for picking electricity type"
                                            />
                                        }
                                    >
                                        <ToggleButton sx={{ p: 1 }} value="locationBased">
                                            <LocationPin sx={{ fontSize: "20px" }} />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                defaultMessage="Market-based estimates for electricity emissions"
                                                description="Tooltip for button group for picking electricity type"
                                            />
                                        }
                                    >
                                        <ToggleButton sx={{ p: 1 }} value="marketBased">
                                            <Company sx={{ fontSize: "20px" }} />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Stack>
                            {hasData && (
                                <Stack direction="row" alignItems="center" spacing={1.5}>
                                    <Select
                                        value={year}
                                        onChange={(e) => {
                                            const selectedYear = e.target.value;
                                            if (typeof selectedYear !== "number") return;
                                            setYearPersistant(selectedYear);
                                            navigate({ search: (prev) => ({ ...prev, year: selectedYear }) });
                                        }}
                                        size="small"
                                        sx={{ height: pixelsToRem(40), width: "100px" }}
                                        autoWidth
                                    >
                                        {data?.emissionData.years
                                            .sort((a, b) => b - a)
                                            .map((y) => (
                                                <MenuItem value={y} key={y} sx={{ width: "100px" }}>
                                                    {y}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    <EmissionIntensityTour
                                        open={tour === "emission-intensities-1"}
                                        placement="bottom"
                                        header={
                                            <FormattedMessage
                                                defaultMessage="Emission intensities"
                                                description="Header for the second (and last) step of the emission intensities product tour"
                                            />
                                        }
                                        subheader={
                                            <FormattedMessage
                                                defaultMessage="You can also filter on a specific business unit, to visualize the emissions intensities related to that."
                                                description="Subheader for the second (and last) step of the emission intensities product tour"
                                            />
                                        }
                                        actions={
                                            <RouteButton
                                                size="small"
                                                to="/overview"
                                                search={(prev) => ({ ...prev, tour: undefined })}
                                                replace
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Done"
                                                    description="Label for the button to finish the emission intensity product tour"
                                                />
                                            </RouteButton>
                                        }
                                    >
                                        <span>
                                            <FilterOverview buttonType="secondary" />
                                        </span>
                                    </EmissionIntensityTour>
                                    <Button
                                        color="primary"
                                        size="small"
                                        disabled={isEditor === false}
                                        onClick={handleAddActivityClick}
                                        startIcon={<Plus />}
                                    >
                                        <FormattedMessage defaultMessage="Add activity" />
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" gap={1} alignItems="center">
                                <Typography variant="textLg" component="h2" fontWeight={500} noWrap overflow="visible">
                                    <FormattedMessage
                                        defaultMessage="Key insights"
                                        description="Emissions page overview header"
                                    />
                                </Typography>
                                <Typography variant="textLg" component="h2" fontWeight={500} noWrap overflow="visible">
                                    {year}
                                </Typography>
                            </Stack>
                            {hasData && (
                                <Stack direction="row" alignItems="center" spacing={1.5}>
                                    <MigrationTools />
                                    <Button
                                        color="secondary"
                                        size="small"
                                        disabled={isEditor === false}
                                        onClick={handleAddActivityClick}
                                        startIcon={<Plus />}
                                    >
                                        <FormattedMessage defaultMessage="Add activity" />
                                    </Button>
                                    <FilterOverview />
                                </Stack>
                            )}
                        </Stack>
                    )}
                    {hasData ? (
                        <>
                            <LandingPageContent />
                            <SourceBreakdownWrapper />
                        </>
                    ) : (
                        <NoData />
                    )}
                </Stack>
            </PermissionContainer>
            {viewType === "demo" && <WatchDemo />}
            {viewType === "learnMore" && <LearnMore />}
        </Stack>
    );
};
