import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { FilterField } from "router";

interface Props {
    tagLabel: string;
    onChange: (value: FilterField | undefined) => void;
    value: FilterField | null;
    fields: FilterField[];
    loading?: boolean;
    placeholder?: string;
    disabled?: boolean;
}
export const TagColumnPicker: React.FC<Props> = ({
    tagLabel,
    onChange,
    value,
    fields,
    loading,
    placeholder,
    disabled,
}) => {
    return (
        <Autocomplete
            size="small"
            value={value}
            options={fields}
            disabled={disabled}
            isOptionEqualToValue={(option, value_) => option.id === value_.id}
            getOptionLabel={(option) => option.label ?? option.id}
            onChange={(_, newValue) => {
                const field = newValue ?? undefined;
                onChange(field);
            }}
            fullWidth
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={tagLabel}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                    }}
                />
            )}
        />
    );
};
