import { formatNumber } from "@/lib/primitives/numbers";
import { TableCell, Typography } from "@mui/material";
import React from "react";
import { EmissionsByScopeActionPopover } from "./EmissionsByScopeActionPopover";

type Props = {
    scope: "1" | "2" | "3" | "Total";
    year: number;
    value: number;
};

export const CompactEmissionsCell: React.FC<Props> = ({ scope, year, value }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLTableCellElement> | null) => {
        if (!event) return;
        setAnchorEl(event.currentTarget);
    };

    const selectedStyle = React.useMemo(() => {
        return anchorEl && scope !== "Total" ? { outline: "1px solid red" } : {};
    }, [anchorEl, scope]);

    const open = Boolean(anchorEl);

    return (
        <>
            <TableCell
                key={year}
                align="right"
                sx={{
                    cursor: "pointer",
                    ...selectedStyle,
                }}
                onClick={(e) => handleClick(e)}
            >
                <Typography variant="textSm">{value ? formatNumber(value, 2) : "-"}</Typography>
            </TableCell>
            {scope !== "Total" && (
                <EmissionsByScopeActionPopover
                    config={{
                        type: "emissionsByScope",
                        year,
                        scope,
                    }}
                    anchorEl={anchorEl ?? undefined}
                    handleClose={() => setAnchorEl(null)}
                    open={open}
                    trackSource={{
                        page: "Overview",
                        component: "EmissionOverYearsCard",
                    }}
                />
            )}
        </>
    );
};
