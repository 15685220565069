import { WarningCircle } from "@/lib/svg/warningCircle";
import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const MissingData: React.FC = () => {
    return (
        <Stack paddingTop={10} gap={3} alignItems="center">
            <WarningCircle />
            <Stack gap={2} alignItems="center">
                <Typography variant="textXl">
                    <FormattedMessage
                        defaultMessage="We can't find any emissions data"
                        description="Missing data message"
                    />
                </Typography>
                <Typography variant="textMd">
                    <FormattedMessage
                        defaultMessage="Consider removing some filters."
                        description="Missing data message"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};
