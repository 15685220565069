import { ChangePercentBadge } from "@/Containers/LandingPage/LandingPageContent/components/ChangePercentBadge";
import React from "react";

type Props = {
    year: number;
    perYear: Partial<Record<string, number>>;
};

export const EmissionChange: React.FC<Props> = ({ perYear, year }) => {
    const emissions = perYear[year.toString()];
    const emissionsLastYear = perYear[(year - 1).toString()];

    let changePercent: number | undefined;
    if (emissions !== undefined && emissionsLastYear) {
        changePercent = (emissions - emissionsLastYear) / emissionsLastYear;
    }

    return <ChangePercentBadge change={changePercent} />;
};
