import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { EmissionFactors } from "@/Containers/EmissionFactors";
import { TableOutlet } from "@/Containers/EmissionFactors/TableOutlet";
import { z } from "zod";
import { Route } from "@tanstack/react-router";
import { rootRoute } from "..";

export const emissionFactorsWrapperRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/emission-factors",
    component: EmissionFactors,
    errorComponent: ErrorBoundary,
});

export const emissionFactorsRoute = new Route({
    getParentRoute: () => emissionFactorsWrapperRoute,
    path: "/$factorType",
    parseParams: ({ factorType }) => {
        const factor = z.enum(["spend", "activity"]).catch("activity").parse(factorType);
        return {
            factorType: factor,
        };
    },
    component: TableOutlet,
    errorComponent: ErrorBoundary,
});
