import { SearchField } from "@/Components/SearchField";
import { Filter } from "@ignite-analytics/icons";
import { Button, Divider, Stack } from "@mui/material";
import { DataGridPro, GridFilterModel, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useGetColumns } from "./renderFunctions";
import { RowData } from "./types";

type Props = {
    emissionFactorData: RowData[];
    loading?: boolean;
};
export const ActivityEmissionFactorContent: React.FC<Props> = ({ emissionFactorData, loading }) => {
    const apiRef = useGridApiRef();

    const onChangeSearch = useCallback(
        (text: string) => {
            apiRef.current.setQuickFilterValues([text]);
        },
        [apiRef]
    );

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        quickFilterExcludeHiddenColumns: true,
    });
    const columns = useGetColumns();
    return (
        <div style={{ boxSizing: "border-box", height: "100%", position: "relative" }}>
            <Stack position="absolute" top={0} bottom={0} right={0} left={0} display="flex" gap={2}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={1}
                    divider={<Divider orientation="vertical" />}
                >
                    <SearchField onChange={(_e, value) => onChangeSearch(value)} />
                    <Button
                        startIcon={<Filter />}
                        variant="text"
                        color="inherit"
                        size="medium"
                        onClick={() => {
                            apiRef.current.showFilterPanel();
                        }}
                    >
                        <FormattedMessage defaultMessage="Filter" description="Filter button" />
                    </Button>
                </Stack>
                <Stack
                    sx={{
                        background: "white",
                        width: "100%",
                        height: "100%",
                        overflowX: "auto",
                        minHeight: "76vh",
                    }}
                >
                    <DataGridPro
                        apiRef={apiRef}
                        loading={loading}
                        disableRowSelectionOnClick
                        columns={columns}
                        rows={emissionFactorData}
                        initialState={{
                            pinnedColumns: { right: ["linkToForm"] },
                            pagination: {
                                paginationModel: {
                                    pageSize: 500,
                                },
                            },
                            columns: {
                                columnVisibilityModel: {
                                    scope: false,
                                    to: false,
                                    from: false,
                                },
                            },
                        }}
                        pagination
                        pageSizeOptions={[100, 500, 1000]}
                        filterModel={filterModel}
                        onFilterModelChange={(model) => setFilterModel(model)}
                    />
                </Stack>
            </Stack>
        </div>
    );
};
