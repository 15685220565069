import { WarningCircle } from "@/lib/svg/warningCircle";
import { ExclamationTriangle } from "@ignite-analytics/icons";
import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const NoContributorsNotice: React.FC = () => {
    return (
        <Stack paddingTop={10} gap={3} alignItems="center">
            <WarningCircle />
            <Stack gap={2} alignItems="center">
                <Typography variant="textXl">
                    <FormattedMessage defaultMessage="We can't find any data" description="Missing data message" />
                </Typography>
                <Typography variant="textMd">
                    <FormattedMessage
                        defaultMessage="Consider removing some filters or add additional data."
                        description="Missing data message"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};

export const ErrorOccuredNotice: React.FC = () => {
    return (
        <Stack paddingTop={10} gap={3} alignItems="center">
            <ExclamationTriangle />
            <Stack gap={2} alignItems="center">
                <Typography variant="textXl">
                    <FormattedMessage
                        defaultMessage="We've encountered an error, please try again later."
                        description="Failed to fetch data - top contributor widget"
                    />
                </Typography>
                <Typography variant="textMd">
                    <FormattedMessage
                        defaultMessage="Consider contacting support, if you believe it is unrelated to your active filters."
                        description="Failed to fetch data description - top contributor widget"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};
