import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Scope1Type } from "router";

export const useGetScope1MessagesRecord = (): Record<
    Scope1Type,
    { header: string; subHeader: string; longSubHeader: string }
> => {
    const { formatMessage } = useIntl();
    return useMemo(
        () => ({
            icVehicles: {
                header: formatMessage({
                    defaultMessage: "IC vehicles",
                    description: "Label for direct IC vehicles scope 1 emissions select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions as a result of fuel combustion in internal combustion vehicles owned or controlled by the company",
                    description: "Label for direct scope 1 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category pertains to emissions resulting from the combustion of fuel in internal combustion vehicles that your company owns or controls. This could include company cars or heavy goods vehicles. The exact emissions generated can vary depending on the type of fuel used (e.g., petrol, diesel, LPG, etc.), as well as the efficiency of the vehicle. To estimate these emissions, we match the information you provide on size and type of vehicle, together with distance driven, with the latest emission factors from the UK government (DEFRA).",
                    description: "Expanded description for IC vehicles",
                }),
            },
            fuelCombustion: {
                header: formatMessage({
                    defaultMessage: "Fuel combustion",
                    description: "Label for direct fuel combustion scope 1 emissions select",
                }),
                subHeader: formatMessage({
                    defaultMessage: "Emissions as a result of fuel combustion in owned or controlled facilities",
                    description: "Label for direct scope 1 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category refers to emissions produced by the combustion of fuels in facilities that your company owns or controls. This could include fuel for company vehicles and machinery, the firing of boilers or heaters, the running of backup generators, or any other use of fossil fuels for energy production. It's important to consider all types of fuel used across your sites, such as natural gas, coal, or oil. With information on the amount and type of fuel used, we calculate the corresponding emissions using the latest conversion factors from the UK government (DEFRA).",
                    description: "Expanded description for Fuel combustion",
                }),
            },
            directGhgEmissions: {
                header: formatMessage({
                    defaultMessage: "Direct GHG emissions",
                    description: "Label for direct GHG scope 1 emissions select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions as a result of direct greenhouse gas emissions covered by the Kyoto protocol",
                    description: "Label for direct scope 1 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category covers emissions directly produced by activities within your company and includes seven types of greenhouse gases outlined in the Kyoto Protocol. These comprise Carbon Dioxide (CO₂), Methane (CH₄), Nitrous Oxide (N₂O), Hydrofluorocarbons (HFCs), Perfluorocarbons (PFCs), Sulfur Hexafluoride (SF₆), and Nitrogen Triflouride (NF₃). They are typically generated by processes like fuel combustion in company-owned/controlled engines and facilities but also from chemical reactions in the production process and leaks from cooling equipment. We combine the information about the quantity of each gas emitted with the lates assessment report (AR6) from the Intergovernmental Panel on Climate Change (IPCC) to calculate the total emissions in CO₂-equivalents (CO₂e).",
                    description: "Expanded description for Direct GHG Emissions",
                }),
            },
            otherScope1: {
                header: formatMessage({
                    defaultMessage: "Other scope 1",
                    description: "Label for other scope 1 emissions select",
                }),
                subHeader: formatMessage({
                    defaultMessage: "Other scope 1 emissions not covered by the other scope 1 categories",
                    description: "Label for direct scope 1 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category refers to other Scope 1 emissions that have not been covered by the other categories we have available. These might include emissions from other non-specified combustion processes, intentional or unintentional venting or leaking of greenhouse gases, or one-off emissions from unusual events. Ensuring that all sources of Scope 1 emissions are accounted for, even if not covered by the main categories, is essential for the completeness of your inventory. The process for calculating these emissions will depend on the nature of the individual source, but this is something you will have to do, before adding the resulting emissions here. This category can also be used if you have already calculated a total sum of Scope 1 emissions for a certain time period, and want to add it to the Ignite overview, without specifying the exact sources of emissions.",
                    description: "Expanded description for Other Scope 1 emissions",
                }),
            },
        }),
        [formatMessage]
    );
};
