import { formatNumber } from "@/lib/primitives/numbers";
import React from "react";
import { Typography, Stack, Tooltip } from "@mui/material";
import { SquareBolt, LocationPin } from "@ignite-analytics/icons";
import { FormattedMessage } from "react-intl";

type Props = {
    emissionsFactors: {
        regular?: number;
        locationBased?: number;
        marketBased?: number;
    };
};

const RegularFactor = ({ factor }: { factor: number }) => {
    return (
        <Typography variant="textSm">
            <FormattedMessage
                defaultMessage="{factor} [kg CO₂e/unit]"
                description="Total CO2"
                values={{
                    factor: formatNumber(factor, 2),
                }}
            />
        </Typography>
    );
};

export const EmissionFactorCell: React.FC<Props> = ({ emissionsFactors }) => {
    if (!emissionsFactors.locationBased && !emissionsFactors.marketBased) {
        return <RegularFactor factor={emissionsFactors.regular ?? 0} />;
    }
    return (
        <Stack gap={0.5}>
            <Typography variant="textSm">
                <Tooltip
                    title={
                        <FormattedMessage defaultMessage="Location-based factor" description="Location based factor" />
                    }
                >
                    <Stack direction="row">
                        <LocationPin sx={{ height: 15, marginLeft: -0.5 }} />
                        <FormattedMessage
                            defaultMessage="{factor} [kg CO₂e/unit]"
                            description="Total CO2"
                            values={{
                                factor: formatNumber(emissionsFactors.locationBased ?? 0, 2),
                            }}
                        />
                    </Stack>
                </Tooltip>
            </Typography>
            <Typography variant="textSm">
                <Tooltip
                    title={<FormattedMessage defaultMessage="Market-based factor" description="Market based factor" />}
                >
                    <Stack direction="row">
                        <SquareBolt sx={{ height: 15, marginLeft: -0.5 }} />

                        <FormattedMessage
                            defaultMessage="{factor} [kg CO₂e/unit]"
                            description="Total CO2"
                            values={{
                                factor: formatNumber(emissionsFactors.marketBased ?? 0, 2),
                            }}
                        />
                    </Stack>
                </Tooltip>
            </Typography>
        </Stack>
    );
};
