import { FormControl, FormControlLabel, MenuItem, MenuList, Radio, RadioGroup, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { MenuOption } from "../types";

type Props = {
    selectedOption: string;
    setSelectedOption: (option: string) => void;
    options: MenuOption[];
};
export const MenuRadioButtons: React.FC<Props> = ({ selectedOption, setSelectedOption, options }) => {
    const { formatMessage } = useIntl();

    return (
        <MenuList sx={{ padding: "1px" }}>
            <FormControl required fullWidth>
                <RadioGroup value={selectedOption} onChange={(event) => setSelectedOption(event.target.value)}>
                    {options.map((option) => (
                        <MenuItem
                            key={option.message.id}
                            sx={{
                                borderRadius: "15px",
                                backgroundColor: "#F6F6F7",
                                marginTop: "10px",
                            }}
                            onClick={() => {
                                setSelectedOption(option.value);
                            }}
                        >
                            <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                aria-label={formatMessage(option.message)}
                                label=""
                            />
                            <Typography variant="textSm" sx={{ marginLeft: "10px" }}>
                                {formatMessage(option.message)}
                            </Typography>
                        </MenuItem>
                    ))}
                </RadioGroup>
            </FormControl>
        </MenuList>
    );
};
