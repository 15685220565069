import { useQuery } from "@tanstack/react-query";

type PermissionsResponse = {
    carbon: {
        included: boolean;
    };
};

const isPermission = (data: unknown): data is PermissionsResponse => {
    if (typeof data !== "object" || data === null) {
        return false;
    }
    if (typeof (data as PermissionsResponse).carbon?.included !== "boolean") {
        return false;
    }
    return true;
};

export const useAccess = () => {
    return useQuery({
        queryKey: ["access"],
        queryFn: async () => {
            const response = await fetch(`${process.env.VITE_CUSTOMERS_URL}/modules`, {
                credentials: "include",
            });
            const data = await response.json();
            if (!isPermission(data)) {
                return true;
            }
            return data.carbon.included;
        },
    });
};
