/* eslint-disable react/jsx-no-literals */
import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { emissionIntensitiesRootRoute, emissionIntensitiesRootRouteChildren } from "@/Containers/EmissionIntensities";
import { selectIntensityRoute } from "@/Containers/EmissionIntensities/features/new/pages";
import { Stack, Typography } from "@mui/material";
import { createRootRouteWithContext, createRoute, createRouter, Link, Navigate } from "@tanstack/react-router";
import { Root } from "../Containers/Root";
import { dataInputRoute } from "./DataInputRoute";
import { editRoute } from "./DataInputRoute/EditRoute";
import { addActivityForm, addActivityV2, findCategory } from "./DataInputRoute/FormV2";
import {
    addDataSourceDetailsRoute,
    addDataSourceRoute,
    addDataSourceStart,
    addDataSourceTypeRoute,
    dataPreferenceCustomCategoriesRoute,
    dataPreferenceDataSourcesOverview,
    dataPreferenceDataSourcesRoute,
    dataPreferencesRoute,
    dataPreferenceTagsRoute,
    editDataSourceRoute,
} from "./DataPreferencesRoute";
import {
    createCustomOptionsForTagRoute,
    createOptionsFromSourceForTagRoute,
    createTagNameRoute,
    createTagRoute,
    editCustomOptions,
    editSourceOptionsForTagRoute,
    editTagRoute,
    overviewTagRoute,
} from "./DataPreferencesRoute/TagRoute";
import { emissionFactorsRoute, emissionFactorsWrapperRoute } from "./EmissionFactors";
import { overviewRoute } from "./Overview";
import { topContributorsRoute } from "./TopContributors";
import { searchParamsSchema } from "./search";

export interface PermissionRouterContext {
    isEditor?: boolean;
}

export const rootRoute = createRootRouteWithContext<PermissionRouterContext>()({
    component: Root,
    validateSearch: searchParamsSchema,
});

const Index = () => {
    // These links are just for development purposes the index route will be the default route and is currently not used
    return (
        <Stack>
            <Typography variant="h1">Index</Typography>
            <Link to="/overview">Landing page</Link>
            <Link to="/data-input/edit"> Edit</Link>
            <Link to={topContributorsRoute.fullPath}> Top contributors page</Link>
            <Link to={dataPreferenceDataSourcesOverview.fullPath}> Data preferences: Data sources</Link>
            <Link to={overviewTagRoute.fullPath}> Data preferences: Tags</Link>
            <Link to={dataPreferenceCustomCategoriesRoute.fullPath}> Data preferences: Custom categories</Link>
            <Link to={selectIntensityRoute.fullPath}> Data preferences: New emission intensity</Link>
            <Link to={emissionIntensitiesRootRoute.fullPath}> Data preferences: View emission intensities</Link>
            <Link to={findCategory.fullPath}>New form</Link>
            <Link to={emissionFactorsRoute.fullPath} params={{ factorType: "activity" }}>
                Emission factors
            </Link>
        </Stack>
    );
};

// Create an index route
const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: Index,
    errorComponent: ErrorBoundary,
});

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
    indexRoute,
    overviewRoute,
    topContributorsRoute,
    emissionFactorsWrapperRoute.addChildren([emissionFactorsRoute]),
    dataInputRoute.addChildren([addActivityV2.addChildren([addActivityForm, findCategory]), editRoute]),
    dataPreferencesRoute.addChildren([
        dataPreferenceDataSourcesRoute.addChildren([
            dataPreferenceDataSourcesOverview,
            editDataSourceRoute,
            addDataSourceRoute.addChildren([addDataSourceStart, addDataSourceTypeRoute, addDataSourceDetailsRoute]),
        ]),
        dataPreferenceTagsRoute.addChildren([
            createTagRoute.addChildren([
                overviewTagRoute,
                createTagNameRoute,
                createCustomOptionsForTagRoute,
                createOptionsFromSourceForTagRoute,
            ]),
            editTagRoute.addChildren([editCustomOptions, editSourceOptionsForTagRoute]),
        ]),
        dataPreferenceCustomCategoriesRoute,
        emissionIntensitiesRootRoute.addChildren(emissionIntensitiesRootRouteChildren),
    ]),
]);

// Create the router using your route tree
export const router = createRouter({
    routeTree,
    context: { isEditor: undefined },
    defaultNotFoundComponent: () => <Navigate to={overviewRoute.fullPath} replace />,
});

// Register your router for maximum type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
