import { Box, Divider, List, Popover } from "@mui/material";
import React from "react";
import { AnchorPosition, CommonProps } from "./types";

function getOptimalAnchorPosition(anchorEl: HTMLElement | undefined, open: boolean): AnchorPosition {
    if (!anchorEl || !open) return { vertical: "top", horizontal: "left" };

    const rect = anchorEl.getBoundingClientRect();

    const position: AnchorPosition = {
        horizontal: 0,
        vertical: rect.height + 2,
    };
    return position;
}

type Props = Pick<CommonProps, "anchorEl" | "handleClose" | "open"> & {
    items: React.ReactNode[];
};
export const ActionPopover: React.FC<Props> = ({ items, handleClose, anchorEl, open }) => {
    const anchorPosition = getOptimalAnchorPosition(anchorEl, open);

    return (
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={{ ...anchorPosition }}>
            <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <List>
                    {items.map((item, index) => (
                        <>
                            {index !== 0 && <Divider />}
                            {item}
                        </>
                    ))}
                </List>
            </Box>
        </Popover>
    );
};
