import { OverviewData } from "@/Containers/LandingPage/types";
import { overviewRoute } from "@/Router/Overview";
import { useGetScope3MessagesRecord } from "@/lib/commonMessages/useGetScope3Messages";
import { TableRow } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { scope3Types } from "router";
import { Cell } from "../Cell";
import { HeaderCell } from "../HeaderCell";

type Props = {
    data: Pick<OverviewData, "scope3">;
    sortedYears: number[];
    topValue: number;
};

export const Scope3Rows: React.FC<Props> = ({ data, sortedYears, topValue }) => {
    const scope3Messages = useGetScope3MessagesRecord();
    const { year: focusYear } = useSearch({ from: overviewRoute.fullPath });
    return (
        <>
            <TableRow>
                <HeaderCell rowSpan={16}>
                    <FormattedMessage defaultMessage="Scope 3" description="Scope 3" />
                </HeaderCell>
            </TableRow>
            {scope3Types.map((scope3Type) => {
                const highlightRowHeader = focusYear === sortedYears[0];
                const scope3Val = data.scope3[scope3Type] ? (data.scope3[scope3Type] ?? 0) : 0;
                return (
                    <TableRow key={scope3Type}>
                        <HeaderCell highlightRight={highlightRowHeader}>{scope3Messages[scope3Type].header}</HeaderCell>
                        {sortedYears.map((year, index) => {
                            const highlight =
                                focusYear === year || !!(sortedYears.length && focusYear === sortedYears[index + 1]);
                            const val = scope3Val[year] ?? 0;
                            return (
                                <Cell
                                    key={year}
                                    year={year}
                                    category={scope3Type}
                                    highlightRight={highlight}
                                    topValue={topValue}
                                    value={val}
                                    bottom={scope3Type === "investments" && focusYear === year}
                                />
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};
