import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { useIntl } from "react-intl";

import { scope1Types, scope2Types, scope3Types } from "router";

export const useCategoryAutocompleteOptionsAndHandlers = () => {
    const scopeMessages = useGetAllScopeMessages();
    const { formatMessage } = useIntl();

    const options = [
        ...scope1Types.map((category) => {
            return {
                scope: 1,
                value: category,
                label: scopeMessages[category].header,
            };
        }),
        ...scope2Types.map((category) => {
            return {
                scope: 2,
                value: category,
                label: scopeMessages[category].header,
            };
        }),
        ...scope3Types.map((category) => {
            return {
                scope: 3,
                value: category,
                label: scopeMessages[category].header,
            };
        }),
    ];

    const handleGroupBy = (option) => {
        const match = options.find((o) => o.value === option);
        return formatMessage(
            {
                defaultMessage: "Scope {scope}",
                description: "Scope category",
            },
            { scope: match?.scope ?? "" }
        );
    };

    const handleOptionLabel = (option) => {
        return scopeMessages[option].header;
    };

    return { options, handleGroupBy, handleOptionLabel };
};
