import { trpc } from "@/lib/trpc/client";
import { EmissionsExcludedFromSpendSource } from "./SpendSource";
import { EmissionsExcludedFromSupplierSource } from "./SupplierSource";

export const OverlappingData = () => {
    const supplierConnection = trpc.supplierConnection.useQuery();
    const filterFields = trpc.filterFields.useQuery({ type: "spend" }).data?.filterFields ?? [];

    if (supplierConnection.data) {
        return <EmissionsExcludedFromSupplierSource />;
    }
    if (filterFields.length > 0) {
        return <EmissionsExcludedFromSpendSource />;
    }
    return null;
};
