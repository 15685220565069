import { SquareChip, getChipColor } from "@/Containers/Components/SquareChip";
import { ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";

interface Props {
    optionLabel: string;
    onClick: () => void;
    chip?: boolean;
    children?: React.ReactNode;
}

export const MenuRow: React.FC<Props> = ({ onClick, optionLabel, children, chip }) => {
    const truncatedLabel = optionLabel.length > 30 ? `${optionLabel.slice(0, 30)}...` : optionLabel;
    return (
        <MenuItem
            onKeyDown={(e) => {
                e.key === "Enter" && onClick();
            }}
            onClick={onClick}
            tabIndex={0}
        >
            {children}
            <ListItemText>
                {chip ? (
                    <SquareChip color={getChipColor("green")}>
                        <Tooltip title={optionLabel !== truncatedLabel ? optionLabel : undefined}>
                            <Typography variant="textSm">{truncatedLabel}</Typography>
                        </Tooltip>
                    </SquareChip>
                ) : (
                    <Tooltip title={optionLabel !== truncatedLabel ? optionLabel : undefined}>
                        <Typography variant="textSm">{truncatedLabel}</Typography>
                    </Tooltip>
                )}
            </ListItemText>
        </MenuItem>
    );
};
