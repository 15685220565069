import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import { useIntl } from "react-intl";
import { Autocomplete, Box, Grid, TextField, useTheme } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { LocationPin } from "@ignite-analytics/icons";
import { CountryType, DefaultFormType } from "../../types";

import { ContextText } from "../ContextText";
import { getSortedCountries } from "./helpers";

export const CountryPicker = () => {
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();

    const errorMessage = errors.region?.message;
    const sortedCountries = getSortedCountries();
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const region = watch("region");
    return (
        <Grid container wrap="nowrap" gap={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={4} sm={5} md={6}>
                <ContextText
                    icon={<LocationPin fontSize="small" />}
                    header={formatMessage({
                        defaultMessage: "Add region",
                        description: "Add region",
                    })}
                    text={formatMessage({
                        defaultMessage:
                            "Adding the region can increase the accuracy of your estimate as we take this information into account when selecting the best-fitting emission factor.",
                        description: "Add region description",
                    })}
                />
            </Grid>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    id="country-select"
                    value={region ? countryCodeLabelRecord[region ?? ""] : null}
                    options={sortedCountries}
                    openOnFocus
                    getOptionLabel={(option) => option.label}
                    groupBy={(option) =>
                        option.suggested
                            ? formatMessage({
                                  defaultMessage: "Suggested",
                                  description: "Used as label for suggested countries in country picker",
                              })
                            : formatMessage({
                                  defaultMessage: "All",
                                  description: "Label for all (non-suggested) countries in country picker",
                              })
                    }
                    onChange={(_, newValue: CountryType | null) => {
                        setValue("region", newValue?.code ?? null);
                    }}
                    fullWidth
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                crossOrigin="anonymous"
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {/* eslint-disable-next-line react/jsx-no-literals */}
                            {option.label} {`(${option.code})`}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: 1,
                            }}
                            placeholder={formatMessage({
                                defaultMessage: "Select a region",
                                description: "Placeholder for country picker",
                            })}
                            error={!!errorMessage}
                            helperText={errorMessage ?? ""}
                            inputProps={{
                                ...params.inputProps,
                                "data-testid": "region-input",
                                autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
