import { getMessageForActivity } from "@/Containers/ManualEmissions/helpers.activityL1";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { addActivityFormRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { useSearch } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ActivityInformation, ScopeCategory, isCountryCode, isUnitOfMeasurement } from "router";

import { CATEGORY_WITHOUT_L1 } from "../../constants";
import { DefaultFormType } from "../../types";

export const useGetLabelForL1AndL2Handler = (scopeType?: ScopeCategory) => {
    const { formatMessage } = useIntl();
    const scopeMessagesRecord = useGetAllScopeMessages();
    const getLabel = useCallback(
        (activityLevelInformation: string, fallback?: string): string => {
            if (activityLevelInformation === "default" && scopeType) {
                return scopeMessagesRecord[scopeType].header;
            }
            const message = getMessageForActivity(activityLevelInformation);
            if (message) {
                return formatMessage(message);
            }
            return fallback ?? activityLevelInformation;
        },
        [formatMessage, scopeMessagesRecord, scopeType]
    );
    return getLabel;
};

export const defaultExclude: ScopeCategory[] = [
    "purchasedGoodsAndServices",
    "capitalGoods",
    "fuelAndEnergyRelatedActivities",
    "upstreamLeasedAssets",
    "wasteGeneratedInOperations",
    "businessTravel",
];

export const useGetDefaultValues = (): ((scopeCategory: ScopeCategory) => DefaultFormType) => {
    const scopeMessagesRecord = useGetAllScopeMessages();
    const { activityL1, activityL2, region, activityYear, unit, supplierId } = useSearch({
        from: addActivityFormRoute.id,
    });

    const activityL1Value: ActivityInformation | null = activityL1
        ? {
              type: "predefined",
              value: activityL1,
          }
        : null;
    const activityL2Value: ActivityInformation | null = activityL2
        ? {
              type: "predefined",
              value: activityL2,
          }
        : null;
    const thisYear = dayjs(Date.now()).subtract(1, "year").year();
    const country = region && isCountryCode(region) ? region : null;
    const from = activityYear ? dayjs(`${activityYear}-01-01Z`).toDate() : dayjs(`${thisYear}-01-01Z`).toDate();
    const to = activityYear ? dayjs(`${activityYear}-12-31Z`).toDate() : dayjs(`${thisYear}-12-31Z`).toDate();

    const getDefaultValue = (scopeCategoryInput: ScopeCategory): DefaultFormType => {
        const common: DefaultFormType = {
            scopeType: scopeCategoryInput,
            region: country ?? null,
            supplierId: supplierId ?? null,
            energyCertificate: null,
            filter: [],
            removeEmissionsFromSpendSource: defaultExclude.includes(scopeCategoryInput) && !!supplierId,
            activityL2: activityL2Value,
            activityL1: activityL1Value,
            locationBasedValue: null,
            tags: null,
            value: null,
            from,
            to,
            files: [],
            unitOfMeasurement: isUnitOfMeasurement(unit) ? unit : null,
            initialDescription: undefined,
            businessUnitId: null,
            spendCategoryId: null,
        };
        if (CATEGORY_WITHOUT_L1.includes(scopeCategoryInput)) {
            return {
                ...common,
                activityL1: {
                    type: "predefined",
                    value: "default",
                    label: scopeMessagesRecord[scopeCategoryInput].header,
                } as const,
            };
        }
        return common;
    };
    return getDefaultValue;
};
