import { Emissions } from "@/Containers/ActivityLog/Table/RenderFunctions/types";
import { getMessageForActivity } from "@/Containers/ManualEmissions/helpers.activityL1";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ActivityDataForEditTable } from "router";

export const useGetLowestLevelHandler = () => {
    const scopeCategoryMessages = useGetAllScopeMessages();
    const { formatMessage } = useIntl();
    const getCategoryAndLowestActivity = (activity: ActivityDataForEditTable) => {
        const { activityL1, activityL2 } = activity;
        const scopeCategory = scopeCategoryMessages[activity.scopeType];

        // Custom categories will alway be the lowest level of the category
        if (activityL1?.type === "custom") {
            return {
                category: scopeCategory.header,
                lowestActivity: activityL1.label,
            };
        }
        if (activityL2?.type === "custom") {
            return {
                category: scopeCategory.header,
                lowestActivity: activityL2.label,
            };
        }
        const activityL2Message = activityL2 ? getMessageForActivity(activityL2?.value) : undefined;
        const activityL1Message = activityL1 ? getMessageForActivity(activityL1?.value) : undefined;
        if (activityL1?.value === "default") {
            return {
                category: scopeCategory.header,
                lowestActivity: scopeCategory.header,
            };
        }
        if (activityL2Message) {
            return {
                category: scopeCategory.header,
                lowestActivity: formatMessage(activityL2Message),
            };
        }
        if (activityL1Message) {
            return {
                category: scopeCategory.header,
                lowestActivity: formatMessage(activityL1Message),
            };
        }
        return {
            category: scopeCategory.header,
            lowestActivity: scopeCategory.header,
        };
    };
    return getCategoryAndLowestActivity;
};

export const getEmissionsFormattedFromActivityRow = (row: ActivityDataForEditTable): Emissions => {
    const { energyCertificate, estimateMarket, estimateLocation, estimate } = row;
    return estimateLocation && estimateLocation > 0
        ? {
              type: "electricity",
              estimateMarket: energyCertificate ? 0 : (estimateMarket ?? 0),
              estimateLocation: estimateLocation ?? 0,
          }
        : {
              type: "regular",
              estimate,
          };
};

export const getDateRepresentation = (date: Date): ReactElement => {
    const today = dayjs().startOf("day");
    const yesterday = dayjs().subtract(1, "day").startOf("day");
    if (dayjs(date).isSame(today, "d")) {
        return <FormattedMessage defaultMessage="Today" description="Today" />;
    }
    if (dayjs(date).isSame(yesterday, "d")) {
        return <FormattedMessage defaultMessage="Yesterday" description="Yesterday" />;
    }

    return <FormattedMessage defaultMessage="{date}" description="Date" values={{ date: date.toLocaleDateString() }} />;
};
