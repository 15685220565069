import {
    dataPreferenceCustomCategoriesRoute,
    dataPreferenceDataSourcesOverview,
    dataPreferenceDataSourcesRoute,
    dataPreferenceTagsRoute,
} from "@/Router/DataPreferencesRoute";
import { overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";

import { Stack, Tab, Tabs } from "@mui/material";
import { Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { emissionIntensitiesRootRoute } from "../EmissionIntensities";

export const DataPreferencesRoute = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const matches = useMatches();

    /**
     * Feature flag: [LaunchDarkly](https://app.launchdarkly.com/projects/default/flags/carbon-emission-intensities)
     */
    const hasEmissionIntensities = useFeatureToggle("carbon-emission-intensities");

    const value = useMemo(() => {
        if (matches.find((match) => match.id.includes(dataPreferenceDataSourcesRoute.id))) {
            return "dataSources";
        }
        if (matches.find((match) => match.id.includes(dataPreferenceTagsRoute.id))) {
            return "tags";
        }
        if (matches.find((match) => match.id.includes(emissionIntensitiesRootRoute.id))) {
            return "emission-intensities";
        }
        return "customCategories";
    }, [matches]);

    return (
        <Stack height="100%">
            <Tabs
                sx={{ mb: 2 }}
                value={value}
                onChange={(_, newValue) => {
                    if (newValue === "dataSources") {
                        navigate({ to: dataPreferenceDataSourcesOverview.fullPath });
                    } else if (newValue === "tags") {
                        navigate({ to: overviewTagRoute.fullPath });
                    }
                    if (newValue === "customCategories") {
                        navigate({ to: dataPreferenceCustomCategoriesRoute.fullPath });
                    }
                    if (newValue === "emission-intensities") {
                        navigate({
                            to: emissionIntensitiesRootRoute.fullPath,
                        });
                    }
                }}
            >
                <Tab
                    label={formatMessage({
                        defaultMessage: "Data sources",
                        description: "Data sources title",
                    })}
                    iconPosition="start"
                    value="dataSources"
                />
                <Tab
                    label={formatMessage({
                        defaultMessage: "Tags",
                        description: "Tags title",
                    })}
                    iconPosition="start"
                    value="tags"
                />
                <Tab
                    label={formatMessage({
                        defaultMessage: "Custom categories",
                        description: "Custom categories title",
                    })}
                    iconPosition="start"
                    value="customCategories"
                />
                {hasEmissionIntensities && (
                    <Tab
                        label={
                            <FormattedMessage
                                defaultMessage="Emission intensities"
                                description="Tab name for emission intensities in settings"
                            />
                        }
                        value="emission-intensities"
                    />
                )}
            </Tabs>
            <Outlet />
        </Stack>
    );
};
