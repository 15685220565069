import { FormattedMessage } from "react-intl";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { EmissionEstimateMeta } from "../../types";

type Props = {
    metaData: EmissionEstimateMeta | null;
    extraInformation?: string[];
};

type MetaProps = {
    metaData: EmissionEstimateMeta;
};
const ActivityInfo: React.FC<MetaProps> = ({ metaData }) => {
    const { year, region } = metaData;
    if (!region) {
        return (
            <Typography variant="textSm" fontWeight={400}>
                <FormattedMessage
                    defaultMessage="For your activity details, you used {year} for Year."
                    values={{ year: <b> {year}</b> }}
                />
            </Typography>
        );
    }
    return (
        <Typography variant="textSm" fontWeight={400}>
            <FormattedMessage
                defaultMessage="For your activity details, you used {year} for Year and {region} for Region."
                values={{ year: <b> {year}</b>, region: <b> {region}</b> }}
            />
        </Typography>
    );
};

const EmissionFactorInfo: React.FC<MetaProps> = ({ metaData }) => {
    const { emissionFactorYear, emissionFactorRegion } = metaData;
    if (!emissionFactorYear) {
        return null;
    }
    if (!emissionFactorRegion) {
        return (
            <Typography variant="textSm" fontWeight={400}>
                <FormattedMessage
                    defaultMessage="For your emission factor, we used {year} for Year and a global region."
                    values={{ year: <b> {emissionFactorYear}</b> }}
                />
            </Typography>
        );
    }
    return (
        <Typography variant="textSm" fontWeight={400}>
            <FormattedMessage
                defaultMessage="For your emission factor, we selected {emissionFactorYear} for Year and {emissionFactorRegion} for Region."
                values={{
                    emissionFactorYear: <b> {emissionFactorYear}</b>,
                    emissionFactorRegion: <b> {emissionFactorRegion}</b>,
                }}
            />
        </Typography>
    );
};

export const CalculationExplanation: React.FC<Props> = ({ metaData, extraInformation }) => {
    if (metaData === null) {
        return (
            <Stack>
                {extraInformation?.map((info) => (
                    <Typography variant="textSm" fontWeight={400} key={info}>
                        {info}
                    </Typography>
                ))}
            </Stack>
        );
    }

    return (
        <Stack>
            <ActivityInfo metaData={metaData} />
            <EmissionFactorInfo metaData={metaData} />
            {extraInformation?.map((info) => (
                <Typography variant="textSm" fontWeight={400} key={info}>
                    {info}
                </Typography>
            ))}
        </Stack>
    );
};
