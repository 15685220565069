import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { addActivityFormRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { ScopeCategoryType } from "router";
import { CATEGORY_WITHOUT_L1 } from "../../constants";

interface Props {
    scopeTypes: readonly ScopeCategoryType[];
    headerMessage: string;
    onChange?: (scopeCategory: ScopeCategoryType) => void;
}

export const TabSection: React.FC<Props> = ({ headerMessage, scopeTypes, onChange }) => {
    const addActivityMatch = useMatch({
        from: "/_pageLayout/activities/add-activity/form/$scopeCategory",
        shouldThrow: false,
    });

    const scopeMessages = useGetAllScopeMessages();
    const navigate = useNavigate();

    const scopeCategory = addActivityMatch?.params.scopeCategory;

    if (scopeTypes.length === 0) {
        return null;
    }

    return (
        <Stack gap={1}>
            <Stack gap={2}>
                <Typography variant="textSm" fontWeight={300}>
                    {headerMessage}
                </Typography>
            </Stack>
            <Tabs orientation="vertical" value={scopeCategory} aria-label="Vertical tabs scope 1">
                {scopeTypes.map((scopeType) => (
                    <Tab
                        key={scopeType}
                        label={scopeMessages[scopeType].header}
                        onClick={() => {
                            navigate({
                                to: addActivityFormRoute.fullPath,
                                params: { scopeCategory: scopeType },
                                search: (prev) => ({
                                    supplierId: prev?.supplierId,
                                    formType: CATEGORY_WITHOUT_L1.includes(scopeType) ? "activityL1" : "activityL2",
                                }),
                            });
                            onChange?.(scopeType);
                        }}
                        value={scopeType}
                    />
                ))}
            </Tabs>
        </Stack>
    );
};
