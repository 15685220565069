import { graphql } from "@/__generated__";
import { useQuery } from "@apollo/client";

function useCurrency(): string | undefined {
    const { data } = useQuery(
        graphql(`
            query Carbon_useCurrency_getCurrency {
                getCurrentCustomer {
                    id
                    currency
                }
            }
        `)
    );

    return data?.getCurrentCustomer?.currency;
}

export { useCurrency };
