import { createRoot } from "react-dom/client";

import App from "./App";
import { setupProxyAuth } from "./setupProxyAuth";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

const useMockServer = process.env.REACT_APP_INITIALIZE_MOCK;
const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";
async function main() {
    if (useMockServer) {
        localStorage.setItem("tenant", process.env.REACT_APP_DEFAULT_TENANT || "");
        localStorage.setItem("token", process.env.REACT_APP_DEFAULT_TOKEN || "");
    }
    if (isProxyToProductionBackend) {
        await setupProxyAuth();
    }

    let container = document.getElementById("Carbon-container");
    let root = container ? createRoot(container) : null;
    window.renderCarbon = (containerId, props) => {
        container = document.getElementById(containerId);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        root = createRoot(container!);
        root.render(<App {...props} />);
    };

    window.unmountCarbon = (_) => {
        root?.unmount();
    };
    if (!container) {
        container = document.getElementById("root");
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        root = createRoot(container!);
        root.render(<App />);
    }
}

main();
