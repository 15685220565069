import { DefaultFormType } from "@/Components/AddActivityForm/types";
import { hasValue } from "@/lib/hasValue";
import { useFormContext } from "react-hook-form";
import { FieldOption, FilterField } from "router";

export const useGetFormData = () => {
    const { setValue, watch } = useFormContext<DefaultFormType>();

    const filter = watch("filter") ?? [];
    const disabled = !watch("removeEmissionsFromSpendSource");
    const supplierId = watch("supplierId");
    const businessUnitId = watch("businessUnitId");
    const spendCategoryId = watch("spendCategoryId");
    const tags = watch("tags");

    const changeDisabled = () => {
        setValue("removeEmissionsFromSpendSource", !watch("removeEmissionsFromSpendSource"));
        setValue("filter", []);
    };

    const addFilter = (id: string) => {
        setValue("filter", [...(filter ?? []), { id }]);
    };

    const removeFilter = (id: string) => {
        setValue(
            "filter",
            filter?.filter((innerFilter) => innerFilter.id !== id)
        );
    };

    const changeFilter = (id: string, newFilter: { filterField: FilterField; value?: FieldOption }) => {
        const updateFilters = filter
            ?.map((innerFilter) => (innerFilter.id === id ? { ...newFilter, id: innerFilter.id } : innerFilter))
            .filter(hasValue);
        setValue("filter", updateFilters);
    };
    const dataTakenIntoAccount = {
        supplierId: supplierId ?? undefined,
        businessUnitId: businessUnitId ?? undefined,
        spendCategoryId: spendCategoryId ?? undefined,
        from: watch("from"),
        to: watch("to"),
        tags: Object.keys(tags ?? {})
            .map((key) => {
                if (tags) {
                    return {
                        id: key,
                        value: tags[key],
                    };
                }
                return null;
            })
            .filter(hasValue),
    };

    return { filter, disabled, dataTakenIntoAccount, changeDisabled, addFilter, removeFilter, changeFilter };
};
