import { Grid, Stack } from "@mui/material";
import { LandingPage } from "..";
import { MissingModuleAccess } from "./MissingModuleAccess";
import { useAccess } from "./hooks";

export const LandingPageWrapper = () => {
    const access = useAccess();

    if (access.isSuccess && access.data === false) {
        return (
            <Stack gap={2}>
                <MissingModuleAccess />
                {/* This will render a un-clickable blurred landing page */}
                <Grid style={{ filter: "blur(2px)", pointerEvents: "none" }}>
                    <LandingPage />
                </Grid>
            </Stack>
        );
    }
    return <LandingPage />;
};
