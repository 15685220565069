import { useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { Box } from "@mui/material";
import React from "react";
import { BackButtonForChart } from "../../components/BackButtonForChart";
import { PieChartWrapper } from "../../EmissionsPerScopeCard/PieChart";
import { useGetSeriesData } from "../../EmissionsPerScopeCard/PieChart/hooks";

/**
 * **NB**: This is the component that is used in the feature toggled EmissionsBreakdownCard where it's possible to
 * switch between different breakdowns. The old component is called EmissionsPerScopeCard.
 */
const EmissionsByScopeChart: React.FC = () => {
    const chartData = useGetSeriesData();
    const setSelectedScope = useSetSelectedScope();
    const selectedScope = useGetSelectedScope();
    return (
        <>
            <Box position="absolute" top={0} right={0} zIndex={1}>
                <BackButtonForChart
                    visible={selectedScope !== "all"}
                    goBack={() => {
                        setSelectedScope("all", "EmissionOverYearsCard");
                    }}
                />
            </Box>
            <PieChartWrapper data={chartData} />
        </>
    );
};

export default EmissionsByScopeChart;
