import { overviewRoute } from "@/Router/Overview";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { OverviewSourceBreakdown } from "./OverviewSourceBreakdown";
import { SupplierSourceBreakdown } from "./SupplierSourceBreakdown";

export const SourceBreakdownWrapper = () => {
    const { breakdown } = useSearch({ from: overviewRoute.fullPath });
    const navigate = useNavigate();

    if (!breakdown) {
        return null;
    }

    const { type } = breakdown.discriminator;

    const handleClose = () => {
        navigate({
            from: overviewRoute.fullPath,
            search: (prev) => ({ ...prev, breakdown: undefined }),
            to: overviewRoute.fullPath,
        });
    };

    if (type === "supplier") {
        return (
            <SupplierSourceBreakdown
                supplierId={breakdown.discriminator.supplierId}
                supplierName={breakdown.discriminator.supplierName}
                year={breakdown.year}
                onClose={handleClose}
            />
        );
    }

    return (
        <OverviewSourceBreakdown
            category={type === "year" ? null : breakdown.discriminator.category}
            year={breakdown.year}
            onClose={handleClose}
        />
    );
};
