import { Filter } from "@ignite-analytics/icons";
import { Badge } from "@mui/material";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";

export const GridToolbarFilterButton: React.FC = () => {
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();

    const handleClick = () => {
        apiRef.current.showFilterPanel();
    };

    return (
        <Badge color="primary" badgeContent={rootProps.slotProps?.toolbar?.filter?.numActiveFilters}>
            <rootProps.slots.baseIconButton size="small" color="secondary" onClick={handleClick}>
                <Filter />
            </rootProps.slots.baseIconButton>
        </Badge>
    );
};

interface CustomToolbarProps {
    filter?: {
        numActiveFilters: number;
    };
}

declare module "@mui/x-data-grid-pro" {
    // Disabling this eslint rule as we simply want to extend the module definition with our custom props.
    // The eslint fix breaks the intended behavior.
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ToolbarPropsOverrides extends CustomToolbarProps {}
}
