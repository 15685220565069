import { RouteButton } from "@/Components/RouteButton";
import { ChevronDown } from "@ignite-analytics/icons";
import { Card, CardActions, CardContent, CardHeader, Chip, IconButton, Stack, Typography } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { ChangePercentBadge } from "../components/ChangePercentBadge";
import { useSelectedEmissionIntensityId } from "../../hooks";
import { SelectEmissionIntensityMenu } from "./SelectEmissionIntensityMenu";
import { EmissionIntensityTour } from "./Tour";

type Props = {
    emissionIntensityFactor?: number;
    changeSinceLastPeriod?: number;
    units?: {
        emissions: string;
        emissionIntensity: string;
    };
    header: React.ReactNode;
    action: React.ReactNode;
};

const EmissionIntensityFactor: React.FC<{
    emissionIntensityFactor?: number;
    changeSinceLastPeriod?: number;
    units?: {
        emissions: string;
        emissionIntensity: string;
    };
}> = ({ emissionIntensityFactor, changeSinceLastPeriod, units }) => {
    return (
        <Stack alignItems="flex-start" spacing={1}>
            <Stack spacing={1} direction="row">
                <Typography variant="displayMd">
                    {emissionIntensityFactor === undefined && "-"}
                    {emissionIntensityFactor !== undefined && (
                        <FormattedNumber value={emissionIntensityFactor} maximumFractionDigits={2} />
                    )}
                </Typography>
                {changeSinceLastPeriod !== undefined && <ChangePercentBadge change={changeSinceLastPeriod} />}
                {changeSinceLastPeriod === undefined && (
                    <Chip
                        color="neutral"
                        variant="status"
                        label={
                            <FormattedMessage
                                defaultMessage="No data"
                                description="Label for the chip when there is no emission intensity data to display for the selected emission intensity"
                            />
                        }
                    />
                )}
            </Stack>
            {units && (
                <Typography variant="textSm">
                    {units.emissions} / {units.emissionIntensity}
                </Typography>
            )}
            {/* Intentionally added to maintain the spacing between components */}
            {!units && <Typography variant="textSm">&nbsp;</Typography>}
        </Stack>
    );
};

const EmissionIntensitySelect: React.FC<{
    disabled?: boolean;
}> = ({ disabled }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [, setEmissionIntensityId] = useSelectedEmissionIntensityId();
    return (
        <>
            <IconButton
                size="2xsmall"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="secondary"
                disabled={disabled}
            >
                <ChevronDown />
            </IconButton>
            <SelectEmissionIntensityMenu
                open={open}
                onEmissionIntensityChange={(id) => {
                    setAnchorEl(null);
                    setEmissionIntensityId(id);
                }}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            />
        </>
    );
};

const EmissionIntensityCard: React.FC<Props> = ({
    emissionIntensityFactor,
    changeSinceLastPeriod,
    action,
    header,
    units,
}) => {
    const { tour } = useSearch({ from: "/overview" });

    return (
        <EmissionIntensityTour
            placement="left"
            open={tour === "emission-intensities-0"}
            header={
                <FormattedMessage
                    defaultMessage="Emission intensities"
                    description="Header for the first step of the emission intensities product tour"
                />
            }
            subheader={
                <FormattedMessage
                    defaultMessage="Here you can now find your emissions intensities by {dimension}. You can switch between dimensions by clicking on the chevron icon."
                    description="Subheader for the first step of the emission intensities product tour"
                    values={{
                        dimension: "spend",
                    }}
                />
            }
            actions={
                <>
                    <RouteButton
                        to="/overview"
                        search={(prev) => ({ ...prev, tour: "emission-intensities-1" })}
                        replace
                        size="small"
                    >
                        <FormattedMessage
                            defaultMessage="Next"
                            description="Label for the button to go to the next step in the product tour for emission intensities"
                        />
                    </RouteButton>
                    <RouteButton
                        color="secondary"
                        to="/overview"
                        search={(prev) => ({ ...prev, tour: undefined })}
                        replace
                        size="small"
                    >
                        <FormattedMessage
                            defaultMessage="Skip tour"
                            description="Label for the button to skip the product tour for emission intensities"
                        />
                    </RouteButton>
                </>
            }
        >
            <Card height="100%" component={Stack} justifyContent="space-between">
                <CardHeader
                    action={<EmissionIntensitySelect />}
                    title={header}
                    titleTypographyProps={{
                        variant: "textSm",
                        fontWeight: 500,
                        color: (t) => t.palette.text["text-secondary"],
                    }}
                />
                <CardContent>
                    <EmissionIntensityFactor
                        units={units}
                        emissionIntensityFactor={emissionIntensityFactor}
                        changeSinceLastPeriod={changeSinceLastPeriod}
                    />
                </CardContent>
                <CardActions>{action}</CardActions>
            </Card>
        </EmissionIntensityTour>
    );
};

export { EmissionIntensityCard, EmissionIntensitySelect };
export type { Props as EmissionIntensityCardProps };
