import { useGetMenuItemStyles } from "@/Containers/utils/hooks";
import { trpc } from "@/lib/trpc/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Calendar, Company, List } from "@ignite-analytics/icons";
import { AccountTreeOutlined, LocalShippingOutlined } from "@mui/icons-material";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import CheckCircle from "@mui/icons-material/CheckCircleOutline";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useCurrentFilterMenuValues } from "../../filterContext";
import { FilterType } from "../../interfaces";
import SideMenuItem from "./SideMenuItem";

interface Props {
    filterType: FilterType;
    onSelect: (val: FilterType) => void;
}
export const FilterSideMenu: React.FC<Props> = ({ filterType, onSelect }) => {
    const {
        tagFilters,
        sources,
        electricity,
        spendCategoryIds,
        businessUnitIds,
        year,
        supplierIds,
        dateFilter,
        ...rest
    } = useCurrentFilterMenuValues();
    rest satisfies Record<string, never>; // Ensure future filters are made available in menu
    const { activeMenuLinkStyle, menuLinkStyle, typographyStyle } = useGetMenuItemStyles();
    const tags = trpc.getEmissionTags.useQuery();
    const newHeader = useFeatureToggle("carbon-new-header-for-overview");
    const hasEmissionIntensities = useFeatureToggle("carbon-emission-intensities");

    if (tags.isLoading) {
        return (
            <Grid item xs={4}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Stack divider={<Divider />}>
            {!newHeader && (
                <>
                    <Stack
                        style={filterType.type === "year" ? activeMenuLinkStyle : menuLinkStyle}
                        direction="row"
                        minWidth="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => onSelect({ type: "year" })}
                    >
                        <Stack direction="row" alignItems="center" paddingX={1}>
                            <Calendar fontSize="inherit" />
                            <Typography {...typographyStyle}>
                                <FormattedMessage defaultMessage="Year" description="Year" />
                            </Typography>
                        </Stack>
                        {year && <CheckCircle fontSize="inherit" />}
                    </Stack>
                    <Stack
                        style={filterType.type === "electricity" ? activeMenuLinkStyle : menuLinkStyle}
                        direction="row"
                        minWidth="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => onSelect({ type: "electricity" })}
                    >
                        <Stack direction="row" alignItems="center" paddingX={1}>
                            <BoltRoundedIcon fontSize="inherit" />
                            <Typography {...typographyStyle}>
                                <FormattedMessage defaultMessage="Electricity" description="electricity" />
                            </Typography>
                        </Stack>
                        {electricity && <CheckCircle fontSize="inherit" />}
                    </Stack>
                </>
            )}
            <SideMenuItem
                filterType={{ type: "sources" }}
                selectedFilterType={filterType}
                isActive={Boolean(sources?.length)}
                onSelect={() => onSelect({ type: "sources" })}
                Icon={List}
            >
                <FormattedMessage defaultMessage="Data source" description="Text in side menu of filter modal" />
            </SideMenuItem>
            {hasEmissionIntensities && (
                <SideMenuItem
                    filterType={{ type: "businessUnitIds" }}
                    selectedFilterType={filterType}
                    isActive={Boolean(businessUnitIds?.length)}
                    onSelect={() => onSelect({ type: "businessUnitIds" })}
                    Icon={Company}
                >
                    <FormattedMessage defaultMessage="Business unit" description="Text in side menu of filter modal" />
                </SideMenuItem>
            )}
            {hasEmissionIntensities && (
                <SideMenuItem
                    filterType={{ type: "spendCategoryIds" }}
                    selectedFilterType={filterType}
                    isActive={Boolean(spendCategoryIds?.length)}
                    onSelect={() => onSelect({ type: "spendCategoryIds" })}
                    Icon={AccountTreeOutlined}
                >
                    <FormattedMessage defaultMessage="Spend category" description="Text in side menu of filter modal" />
                </SideMenuItem>
            )}
            {hasEmissionIntensities && (
                <SideMenuItem
                    filterType={{ type: "supplierIds" }}
                    Icon={LocalShippingOutlined}
                    selectedFilterType={filterType}
                    isActive={Boolean(supplierIds?.length)}
                    onSelect={() => onSelect({ type: "supplierIds" })}
                >
                    <FormattedMessage defaultMessage="Supplier" description="Text in side menu of filter modal" />
                </SideMenuItem>
            )}
            {tags.data?.tags.map((tag) => (
                <SideMenuItem
                    filterType={{ type: "tag", tagId: tag.id }}
                    selectedFilterType={filterType}
                    isActive={Boolean(tagFilters?.find((filter) => filter.tagId === tag.id))}
                    onSelect={() => onSelect({ type: "tag", tagId: tag.id })}
                    Icon={StyleOutlinedIcon}
                    key={tag.id}
                >
                    {tag.name}
                </SideMenuItem>
            ))}
        </Stack>
    );
};
