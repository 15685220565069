import { GetTopContributors_TopContributorsTableDocument } from "@/__generated__/graphql";
import { hasValue } from "@/lib/hasValue";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ValueCell } from "../ValueCell";

export const CONTRIBUTOR_NAME_COLUMN = "entityName";
const TOTAL_COLUMN = "totalEmissions";
const MISSING_ID = "No ID";

export function useMissingContributorLabel(contributorType: "suppliers" | "spendCategories" | "companyStructure") {
    const { formatMessage } = useIntl();
    switch (contributorType) {
        case "suppliers":
            return formatMessage({
                description: "Label when entity name is missing",
                defaultMessage: "Missing supplier",
            });

        case "companyStructure":
            return formatMessage({
                description: "Label when entity name is missing",
                defaultMessage: "Missing business unit",
            });

        case "spendCategories":
            return formatMessage({
                description: "Label when entity name is missing",
                defaultMessage: "Missing spend category",
            });
    }
}
export const useParseColumnsAndRows = (
    entityHeaderLabel: string,
    entityType: "suppliers" | "spendCategories" | "companyStructure",
    results: NonNullable<
        ResultOf<typeof GetTopContributors_TopContributorsTableDocument>["getTopContributorEmissions"]
    >["results"]
) => {
    const betaEnabled = Boolean(Number(localStorage.getItem("betaEnabled")));
    const { formatMessage } = useIntl();
    const missingNameLabel = useMissingContributorLabel(entityType);
    const memoizedColumnsAndRows = useMemo(() => {
        const years = new Set<number>();
        const rows = results
            .map((contributor) => {
                let id: string | undefined;
                let name: string | undefined;
                switch (entityType) {
                    case "suppliers":
                        id = contributor.supplier?.id;
                        name = contributor.supplier?.name;
                        break;
                    case "spendCategories":
                        id = contributor.spendCategory?.id;
                        name = contributor.spendCategory?.name;
                        break;
                    case "companyStructure":
                        id = contributor.businessUnit?.id;
                        name = contributor.businessUnit?.name;
                        break;
                }
                const row = {
                    id: id ?? MISSING_ID,
                    [CONTRIBUTOR_NAME_COLUMN]: name ?? missingNameLabel,
                    [TOTAL_COLUMN]: contributor.totalEmissions,
                };
                contributor.emissionsByYear.forEach(({ year, value }) => {
                    years.add(year);
                    row[`${year}`] = value;
                });

                return row;
            })
            .filter(hasValue);

        const sortedYears = sortBy(Array.from(years));
        const columns: GridColDef[] = [
            {
                field: CONTRIBUTOR_NAME_COLUMN,
                headerName: entityHeaderLabel,
                type: "string",
                width: 250,
                renderCell(params) {
                    let href: string | undefined;
                    switch (entityType) {
                        case "suppliers":
                            if (betaEnabled) {
                                href = `/supplier-profile/${params.row.id}/overview/`;
                            } else {
                                href = `supplier-page/${params.row.id}/overview/`;
                            }
                            break;
                        case "spendCategories":
                            if (betaEnabled) {
                                href = `/spend/categories/${params.row.id}/emissions`;
                            }
                            break;
                        case "companyStructure":
                            break;
                    }
                    const rowHasId = params.row.id !== MISSING_ID;
                    return (
                        <Tooltip title={params.row.name} placement="top-start">
                            {rowHasId && href ? (
                                <Button LinkComponent="a" color="linkPrimary" href={href} sx={{ minWidth: 0 }}>
                                    <Typography>{params.value}</Typography>
                                </Button>
                            ) : (
                                <Stack direction="row" alignItems="center" height="100%">
                                    <Typography>{params.value}</Typography>
                                </Stack>
                            )}
                        </Tooltip>
                    );
                },
            },

            {
                field: TOTAL_COLUMN,
                headerName: formatMessage({
                    defaultMessage: "Total emissions (t CO₂e)",
                    description: "Header name for total emissions in top contributors table",
                }),
                type: "number",
                align: "left",
                headerAlign: "left",
                width: 200,
                sortingOrder: ["desc", "asc"],
                renderCell: (params) => {
                    return <ValueCell value={params.value} />;
                },
            },
            ...sortedYears.map<GridColDef>((year) => {
                return {
                    flex: 1,
                    field: `${year}`,
                    headerName: `${year}`,
                    type: "number",
                    align: "right",
                    headerAlign: "right",
                    sortingOrder: ["desc", "asc"],
                    renderCell: (params) => {
                        return <ValueCell value={params.value} />;
                    },
                };
            }),
        ];
        return { columns, rows };
    }, [entityHeaderLabel, entityType, formatMessage, results, betaEnabled, missingNameLabel]);

    return memoizedColumnsAndRows;
};
