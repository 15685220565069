import { CARBON_EVENTS } from "@/lib/tracking";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRight } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    Container,
    Divider,
    FormControl,
    FormGroup,
    FormHelperText,
    Unstable_Grid2 as Grid,
    List,
    ListItem,
    Stack,
    Typography,
    cardClasses,
    formGroupClasses,
} from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useController, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { EmissionType } from "./EmissionType";
import { Widget } from "./Widget";

const useValidationSchema = () => {
    const { formatMessage } = useIntl();
    return z.object({
        intensityType: z.enum(["revenue", "employees"], {
            errorMap: () => ({
                message: formatMessage({
                    defaultMessage: "Please select a type",
                    description:
                        "Error message displayed when the user hasn't select at least one emission intensity type",
                }),
            }),
        }),
    });
};

type FormData = z.infer<ReturnType<typeof useValidationSchema>>;

const SelectIntensity = () => {
    const navigate = useNavigate();
    const { emissionIntensityType } = useSearch({
        from: "/data-preferences/emission-intensities/new/select-intensity",
    });

    const validationSchema = useValidationSchema();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<FormData>({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            intensityType: emissionIntensityType,
        },
    });

    const { formatMessage } = useIntl();

    const { field } = useController({ name: "intensityType", control });

    function onSubmit(formData: FormData): Promise<void> {
        CARBON_EVENTS.INTENSITIES_STARTED_FLOW(
            { page: "DataPreferences" },
            { intensityType: formData.intensityType }
        ).track();
        switch (formData.intensityType) {
            case "revenue":
                return navigate({
                    to: "/data-preferences/emission-intensities/new/step/select-year",
                    search: {
                        name: formatMessage({
                            defaultMessage: "Revenue",
                            description: "Name for the revenue intensity type",
                        }),
                        type: "Revenue",
                        unit: "kEUR",
                    },
                });
            case "employees":
                return navigate({
                    to: "/data-preferences/emission-intensities/new/step/select-year",
                    search: {
                        name: formatMessage({
                            defaultMessage: "Employees",
                            description: "Name for the employee count intensity type",
                        }),
                        type: "Employees",
                        unit: "Full-time equivalents",
                    },
                });
        }
    }

    return (
        <Container maxWidth="sm">
            <Stack direction="column" divider={<Divider />} spacing={2}>
                <div>
                    <Stack direction="column">
                        <Typography variant="textLg" fontWeight="bold">
                            <FormattedMessage
                                defaultMessage="Get started"
                                description="Header for the first meeting with emission intensities in settings"
                            />
                        </Typography>
                        <Typography variant="textSm" color={(t) => t.palette.text["text-helper"]}>
                            <FormattedMessage
                                defaultMessage="Select one option to start. You can always add the other one later."
                                description="Subheader for the first meeting with emission intensities in settings"
                            />
                        </Typography>
                    </Stack>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            error={Boolean(errors.intensityType)}
                            sx={{
                                /**
                                 * The card doesn't have an `Mui-Error` class, so we style it based on the form group
                                 */
                                [`& .${formGroupClasses.root}.${formGroupClasses.error} .${cardClasses.root}`]: {
                                    borderColor: (t) => t.palette.error.main,
                                },
                            }}
                        >
                            <FormGroup>
                                <Stack direction="column" spacing={2} mt={1} alignItems="flex-end">
                                    <List>
                                        <ListItem disableGutters>
                                            <EmissionType
                                                name="revenue"
                                                onChange={(_, checked) => {
                                                    if (checked) {
                                                        setValue("intensityType", "revenue");
                                                    }
                                                }}
                                                checked={field.value === "revenue"}
                                                header={
                                                    <FormattedMessage
                                                        defaultMessage="Revenue"
                                                        description="Header for the revenue option when selecting emission intensity type"
                                                    />
                                                }
                                                subHeader={
                                                    <FormattedMessage
                                                        defaultMessage="Choose this if you want to measure intensities based on the net revenues matching your financial reporting."
                                                        description="Description for the revenue option when selecting emission intensity type"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem disableGutters>
                                            <EmissionType
                                                name="employees"
                                                onChange={(_, checked) => {
                                                    if (checked) {
                                                        setValue("intensityType", "employees");
                                                    }
                                                }}
                                                checked={field.value === "employees"}
                                                header={
                                                    <FormattedMessage
                                                        defaultMessage="Employee count"
                                                        description="Header for the employee count option when selecting emission intensity type"
                                                    />
                                                }
                                                subHeader={
                                                    <FormattedMessage
                                                        defaultMessage="Choose this if you want to measure emissions based on the total number of employees or on the equivalent number of full-time employees."
                                                        description="Description for the employee count option when selecting emission intensity type"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                        {errors.intensityType && (
                                            <ListItem disableGutters>
                                                <FormHelperText>{errors.intensityType.message}</FormHelperText>
                                            </ListItem>
                                        )}
                                    </List>

                                    <Button color="primary" size="small" type="submit">
                                        <FormattedMessage
                                            defaultMessage="Start"
                                            description="Label for button to start adding emission intensities"
                                        />
                                    </Button>
                                </Stack>
                            </FormGroup>
                        </FormControl>
                    </form>
                </div>
                <Stack direction="column" spacing={1}>
                    <Typography variant="textSm" color={(t) => t.palette.text["text-helper"]}>
                        <FormattedMessage
                            defaultMessage="Need help?"
                            description="Help text when selecting emission intensity types"
                        />
                    </Typography>
                    <Card>
                        <Grid container direction="row" justifyContent="space-between" position="relative">
                            <Grid xs={7}>
                                <Stack direction="column" p={2} spacing={2.5} alignItems="flex-start">
                                    <Stack direction="column" spacing={1}>
                                        <Typography
                                            variant="textLg"
                                            fontWeight="bold"
                                            color={(t) => t.palette.text["text-primary"]}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Learn about emission intensities"
                                                description="Header for an info box informing the users about our learning material for emission intensities"
                                            />
                                        </Typography>
                                        <Typography
                                            variant="textSm"
                                            color={(t) => t.palette.text["text-body"]}
                                            fontWeight={400}
                                        >
                                            <FormattedMessage
                                                defaultMessage="By comparing emissions to key business metrics like revenue, number of employees, and spend, you can understand if your emissions are growing due to business expansion or other factors."
                                                description="Description for an info box informing the users about our learning material for emission intensities"
                                            />
                                        </Typography>
                                    </Stack>
                                    <Button
                                        color="secondary"
                                        size="xsmall"
                                        endIcon={<ArrowRight />}
                                        // TODO: replace this placeholder link
                                        href="https://help-eu.igniteprocurement.com/en/"
                                        onClick={() => {
                                            CARBON_EVENTS.INTENSITIES_LEARN_MORE({ page: "DataPreferences" }).track();
                                        }}
                                        target="_blank"
                                    >
                                        <FormattedMessage
                                            defaultMessage="Learn more"
                                            description="Label for button that naviagtes to our learning pages about emission intensities"
                                        />
                                    </Button>
                                </Stack>
                            </Grid>
                            <Widget
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    height: "100%",
                                    maxWidth: "calc(5 / 12 * 100%)",
                                }}
                            />
                        </Grid>
                    </Card>
                </Stack>
            </Stack>
        </Container>
    );
};

export { SelectIntensity };
