import { useKeyboardShortcut } from "@/lib/tools";
import { trpc } from "@/lib/trpc/client";
import { Checkbox, ListItemIcon } from "@mui/material";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { SourceFilter, SourceId } from "router";
import { SourceFilterType } from "../../../constants";
import { useFilterHandlers } from "../../../filterContext";
import { FilterValueSelect } from "../components/FilterValueSelect";
import { MenuRow } from "../components/MenuRow";

const isEqual = (sourceA: SourceId, sourceB: SourceId) => {
    return (
        (sourceA.filterType === "activityData" && sourceB.filterType === "activityData") ||
        (sourceA.filterType === "source" && sourceB.filterType === "source" && sourceA.id === sourceB.id)
    );
};

export const SourceFilterMenu: React.FC = () => {
    const { formatMessage } = useIntl();
    const { filter, setFilter } = useFilterHandlers("sources");
    const [focusList, setFocusList] = useState(false);
    const downCallbackFunction = useCallback(() => {
        setFocusList(true);
    }, []);
    const sources = trpc.allAddedConnections.useQuery();
    useKeyboardShortcut("ArrowDown", true, downCallbackFunction);
    useKeyboardShortcut("Tab", true, downCallbackFunction, !focusList);

    const isSelected = useCallback(
        (source: SourceId) => {
            return filter?.some((s) => isEqual(s, source)) ?? false;
        },
        [filter]
    );

    const toggleSource = useCallback(
        (source: SourceId) => {
            const alreadySelected = isSelected(source);
            let newSourceFilter: SourceFilter | null = [...(filter ?? [])];
            if (alreadySelected) {
                newSourceFilter = newSourceFilter.filter((s) => !isEqual(s, source));
            } else {
                newSourceFilter = newSourceFilter.concat(source);
            }
            // If the filter is empty, we remove it entirely as a filter
            if (newSourceFilter.length === 0) {
                newSourceFilter = null;
            }
            setFilter(newSourceFilter);
        },
        [filter, isSelected, setFilter]
    );

    return (
        <FilterValueSelect
            title={formatMessage({ defaultMessage: "Data sources", description: "Data sources" })}
            onReset={() => setFilter(null)}
            isLoading={sources.isLoading}
            isError={sources.isError}
        >
            {sources.isSuccess && (
                <>
                    <MenuRow
                        optionLabel={formatMessage({
                            defaultMessage: "Activity data",
                            description: "activity data",
                        })}
                        onClick={() => {
                            toggleSource({ filterType: SourceFilterType.ACTIVITY_DATA });
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                size="small"
                                edge="start"
                                checked={isSelected({ filterType: SourceFilterType.ACTIVITY_DATA })}
                            />
                        </ListItemIcon>
                    </MenuRow>
                    {sources?.data?.data?.map((source) => {
                        const sourceId = {
                            filterType: SourceFilterType.SOURCE,
                            id: source.id,
                        };
                        return (
                            <MenuRow optionLabel={source.name} onClick={() => toggleSource(sourceId)} key={source.id}>
                                <ListItemIcon>
                                    <Checkbox size="small" edge="start" checked={isSelected(sourceId)} />
                                </ListItemIcon>
                            </MenuRow>
                        );
                    })}
                </>
            )}
        </FilterValueSelect>
    );
};
