import { LoadingTable } from "@/Components/Loading/LoadingTable";
import PermissionContainer from "@/Components/PermissionContainer";
import { useGetSearchParamFilters } from "@/Containers/LandingPage/hooks";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import React from "react";

import CompactTable from "./CompactTable";
import { DetailedTable } from "./DetailedTable";

interface Props {
    showCompactTable: boolean;
}
const EmissionsTable: React.FC<Props> = ({ showCompactTable }) => {
    const { electricity, ...filters } = useGetSearchParamFilters();
    const overviewResult = trpc.overview.useQuery(
        { filters, electricity },
        {
            retry,
            trpc: {
                context: {
                    skipBatch: true,
                },
            },
        }
    );

    if (overviewResult.error) {
        return <PermissionContainer error={overviewResult.error} />;
    }
    if (overviewResult.isLoading) {
        return <LoadingTable numberOfColumns={5} numberOfRows={showCompactTable ? 4 : 22} />;
    }
    if (showCompactTable) return <CompactTable data={overviewResult.data.emissionData} />;

    return (
        <DetailedTable
            data={overviewResult.data.emissionData}
            topValue={overviewResult.data.highestNumberEmissionNumber}
        />
    );
};

export default EmissionsTable;
