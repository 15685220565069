import { ActivityPickerDeprecated } from "@/Components/AddActivityForm/Components/ActivityPickerDeprecated";
import { hasValue } from "@/lib/hasValue";
import { trpc } from "@/lib/trpc/client";
import React from "react";
import { useIntl } from "react-intl";
import { ActivityInformation, ScopeCategoryType } from "router";

interface ActivityL1SelectProps {
    onChange: (value: ActivityInformation | null) => void;
    errorMessage?: string;
    disabled?: boolean;
    scope: ScopeCategoryType;
    selectedValue: ActivityInformation | null | undefined;
}

export const ActivityL1Select: React.FC<ActivityL1SelectProps> = ({
    onChange,
    errorMessage,
    selectedValue,
    disabled,
    scope,
}) => {
    const options = trpc.getActivityOptions.useQuery();
    const filteredOptions =
        options.data?.data
            ?.filter((option) => option.category === scope)
            .map((option) => option.activityL1)
            .filter((option) => option?.type === "predefined")
            .filter(hasValue) ?? [];
    const { formatMessage } = useIntl();

    const getValue = () => {
        if (selectedValue) {
            const match = filteredOptions.find((option) => option?.value === selectedValue.value);

            return match ?? null;
        }
        return null;
    };

    return (
        <ActivityPickerDeprecated
            scopeCategory={scope}
            activityOptions={filteredOptions}
            activity={getValue()}
            setValue={onChange}
            loading={options.isLoading}
            label={formatMessage({ defaultMessage: "Activity" })}
            errorMessage={errorMessage}
            dataTestId="activity-l1-select-custom-category"
            disabled={disabled}
        />
    );
};
