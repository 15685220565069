import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Scope3Type } from "router";

export const useGetScope3MessagesRecord = (): Record<
    Scope3Type,
    { header: string; subHeader: string; longSubHeader: string }
> => {
    const { formatMessage } = useIntl();
    return useMemo(
        () => ({
            purchasedGoodsAndServices: {
                header: formatMessage({
                    defaultMessage: "1 - Purchased goods and services",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage: "Emissions from the production of goods and services procured by the organization",
                    description: "Label for goods and services scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These emissions arise from the production of goods and services that your company procures, emitted throughout the value-chain for those goods and services services before they are delivered to your company. Estimation of these emissions requires detailed data about the products and services you purchase and are often covered initially by connecting your supplier or spend table in Ignite to the Carbon Accounting module. Note that this category includes all upstream (i.e., cradle-to-gate) emissions from the production of products purchased or acquired by the reporting company in the reporting year. Products include both goods (tangible products) and services (intangible products). Category 1 includes emissions from all purchased goods and services not otherwise included in the other categories of upstream scope 3 emissions (i.e., category 2 through category 8). Specific categories of upstream emissions are separately reported in category 2 through category 8 to enhance the transparency and consistency of scope 3 reports. Emissions from the transportation of purchased products from a tier one (direct) supplier to the reporting company (in vehicles not owned or controlled by the reporting company) are accounted for in category 4 (Upstream transportation and distribution).",
                    description: "Expanded description for Purchased Goods and Services",
                }),
            },
            capitalGoods: {
                header: formatMessage({
                    defaultMessage: "2 - Capital goods",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the production of capital assets, such as equipment and machinery used by the organization",
                    description: "Label for capital goods scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This refers to greenhouse gas emissions embodied in the production of capital goods your organization procures. Capital goods include any substantial equipment, installations, buildings, or vehicles that are not consumed throughout the ordinary course of business operation. The emissions arise from the resource extraction, manufacturing, and transportation phases of the capital goods before reaching your organization's control (except between your tier 1 supplier and you). This category includes all upstream (i.e., cradle-to-gate) emissions from the production of capital goods purchased or acquired by the reporting company in the reporting year. Emissions from the use of capital goods by the reporting company are accounted for in either scope 1 (e.g., for fuel use) or scope 2 (e.g., for electricity use), rather than in scope 3. Capital goods are final products that have an extended life and are used by the company to manufacture a product; provide a service; or sell, store, and deliver merchandise. In financial accounting, capital goods are treated as fixed assets or as plant, property, and equipment (PP&E). Examples of capital goods include equipment, machinery, buildings, facilities, and vehicles. In certain cases, there may be ambiguity over whether a particular purchased product is a capital good (to be reported in category 2) or a purchased good (to be reported in category 1). Companies should follow their own financial accounting procedures to determine whether to account for a purchased product as a capital good in this category or as a purchased good or service in category 1. Companies should not double count emissions between category 1 and category 2.",
                    description: "Expanded description for Capital Goods",
                }),
            },
            fuelAndEnergyRelatedActivities: {
                header: formatMessage({
                    defaultMessage: "3 - Fuel- and energy-related activities",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the extraction, production, and transportation of fuels and energy not covered in Scope 1 or 2",
                    description: "Label for fuel and energy scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category includes emissions related to the production of fuels and energy purchased and consumed by the reporting company in the reporting year that are not included in scope 1 or scope 2. Examples could include emissions from the extraction and refining stages of the petrol used in your company vehicles or coal for your power stations, or losses from network distribution of electrical power or heat. Category 3 excludes emissions from the combustion of fuels or electricity consumed by the reporting company because they are already included in scope 1 or scope 2. Scope 1 includes emissions from the combustion of fuels by sources owned or controlled by the reporting company. Scope 2 includes the emissions from the combustion of fuels to generate electricity, steam, heating, and cooling purchased and consumed by the reporting company.",
                    description: "Expanded description for Fuel and Energy Related Activities",
                }),
            },
            upstreamTransportationAndDistribution: {
                header: formatMessage({
                    defaultMessage: "4 - Upstream transportation and distribution",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the transportation and distribution of goods in the organization's supply chain",
                    description: "Label for transportation and distribution scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These are the emissions arising from the transport and distribution of goods in vehicles not owned or controlled by your organization. They may include emissions from the vehicles of third-party logistics providers, or ships, trains, and aircraft carrying goods. Note that this category includes both the transportation and distribution of products purchased in the reporting year, between a company’s tier 1 suppliers and its own operations in vehicles not owned or operated by the reporting company (including multi-modal shipping where multiple carriers are involved in the delivery of a product, but excluding fuel and energy products), as well as third-party transportation and distribution services purchased by the reporting company in the reporting year (either directly or through an intermediary), including inbound logistics, outbound logistics (e.g., of sold products), and third-party transportation and distribution between a company’s own facilities.",
                    description: "Expanded description for Upstream Transportation and Distribution",
                }),
            },
            wasteGeneratedInOperations: {
                header: formatMessage({
                    defaultMessage: "5 - Waste generated in operations",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from waste disposal generated by the organization's operational activities",
                    description: "Label for waste scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category includes emissions from third-party disposal and treatment of waste generated in the reporting company’s owned or controlled operations in the reporting year. The emissions largely depend on the type of waste and the waste management method applied as the greenhouse gases can be released directly through the decay of organic waste in landfills or indirectly from the combustion of waste to generate energy. Note that this category includes emissions from disposal of both solid waste and wastewater. Only waste-treatment in facilities owned or operated by third parties is included in scope 3. Waste treatment at facilities owned or controlled by the reporting company is accounted for in scope 1 and scope 2. Treatment of waste generated in operations is categorized as an upstream scope 3 category because waste management services are purchased by the reporting company. This category includes all future emissions that result from waste generated in the reporting year.",
                    description: "Expanded description for Waste Generated in Operations",
                }),
            },
            businessTravel: {
                header: formatMessage({
                    defaultMessage: "6 - Business travel",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from employee business travel using various modes of transportation, including flights and ground transportation",
                    description: "Label for business travel scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category includes emissions from the transportation of employees for business-related activities in vehicles owned or operated by third parties, such as aircraft, trains, buses, and passenger cars. The calculation of these emissions depends on the distance traveled, mode of transport, fuel type, and occupancy levels. Emissions from transportation in vehicles owned or controlled by the reporting company are accounted for in either scope 1 (for fuel use), or in the case of electric vehicles, scope 2 (for electricity use). Emissions from leased vehicles operated by the reporting company not included in scope 1 or scope 2 are accounted for in scope 3, category 8 (Upstream leased assets). Emissions from transportation of employees to and from work are accounted for in scope 3, category 7 (Employee commuting). The indirect emissions for staying at hotels or other accommodations can either be accounted for here or in scope 3, category 1 (Purchased goods and services).",
                    description: "Expanded description for Business Travel",
                }),
            },
            employeeCommuting: {
                header: formatMessage({
                    defaultMessage: "7 - Employee commuting",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from employee commuting to and from work using various modes of transportation",
                    description: "Label for employee commute scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These emissions come from commuting between home and the workplace by employees in vehicles not owned or controlled by your organization. This can include car travel, public transportation, biking, walking, etc. Greenhouse gas emissions are estimated based on distance traveled, transport mode, and the region where the travel took place. Companies may include emissions from teleworking (i.e., employees working remotely) in this category. A reporting company’s scope 3 emissions from employee commuting include the scope 1 and scope 2 emissions of employees and third-party transportation providers.",
                    description: "Expanded description for Employee Commuting",
                }),
            },
            upstreamLeasedAssets: {
                header: formatMessage({
                    defaultMessage: "8 - Upstream leased assets",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from leased assets that are not controlled by the organization but are part of its upstream activities",
                    description: "Label for upstream leased assets scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "Category 8 includes emissions from the operation of assets that are leased by the reporting company in the reporting year and not already included in the reporting company’s scope 1 or scope 2 inventories. This category is applicable only to companies that operate leased assets (i.e., lessees). For companies that own and lease assets to others (i.e., lessors), see category 13 (Downstream leased assets). Leased assets may be included in a company’s scope 1 or scope 2 inventory depending on the type of lease and the consolidation approach the company uses to define its organizational boundaries. If the reporting company leases an asset for only part of the reporting year, it should account for emissions for the portion of the year that the asset was leased. A reporting company’s scope 3 emissions from upstream leased assets include the scope 1 and scope 2 emissions of lessors (depending on the lessor’s consolidation approach).",
                    description: "Expanded description for Upstream Leased Assets",
                }),
            },
            downstreamTransportationAndDistribution: {
                header: formatMessage({
                    defaultMessage: "9 - Downstream transportation and distribution",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the transportation and distribution of goods in the organization's distribution network, not paid for by the organization",
                    description: "Label for downstream transportation and distribution scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category includes emissions that occur in the reporting year from transportation and distribution of sold products in vehicles and facilities not owned or controlled by the reporting company. This category also includes emissions from retail and storage. Outbound transportation and distribution services that are purchased by the reporting company are excluded from category 9 and included in category 4 (Upstream transportation and distribution) because the reporting company purchases the service. Category 9 includes only emissions from transportation and distribution of products after the point of sale.",
                    description: "Expanded description for Downstream Transportation and Distribution",
                }),
            },
            processingOfSoldProducts: {
                header: formatMessage({
                    defaultMessage: "10 - Processing of sold products",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from processing of products sold by the organization after they leave its control",
                    description: "Label for processing of sold products scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category emissions from processing of sold intermediate products by third parties (e.g., manufacturers) subsequent to sale by the reporting company. Intermediate products are products that require further processing, transformation, or inclusion in another product before use, and therefore result in emissions from processing subsequent to sale by the reporting company and before use by the end consumer. Emissions from processing should be allocated to the intermediate product. In certain cases, the eventual end use of sold intermediate products may be unknown. For example, a company that produces an intermediate product with many potential downstream applications, each of which has a different GHG emissions profile, may be unable to reasonably estimate the down stream emissions associated with these various end uses.",
                    description: "Expanded description for Processing of Sold Products",
                }),
            },
            useOfSoldProducts: {
                header: formatMessage({
                    defaultMessage: "11 - Use of sold products",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the use of products sold by the organization in their intended applications",
                    description: "Label for use of sold products scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These are emissions generated during the use phase of products or services sold by your company in the reporting year. A reporting company’s scope 3 emissions from use of sold products include the scope 1 and scope 2 emissions of end users. End users include both consumers and business customers that use final products. For example, emissions from computers during their operating lifetime, emissions from fuel when it is consumed, or emissions from appliances powered by electricity during their operation. This category could include significant emissions, depending on the type of products sold. The Scope 3 Standard divides emissions from the use of sold products into two types: Direct use-phase emissions and Indirect use-phase emissions. In category 11, companies are required to include direct use-phase emissions of sold products. Companies may also account for indirect use-phase emissions of sold products, and should do so when indirect use-phase emissions are expected to be significant.",
                    description: "Expanded description for Use of Sold Products",
                }),
            },
            endOfLifeTreatmentOfSoldProducts: {
                header: formatMessage({
                    defaultMessage: "12 - End-of-life treatment of sold products",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from the waste treatment and disposal of products sold by the organization at the end of their life",
                    description: "Label for end-of-life treatment of sold products scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These emissions includes emissions from the waste disposal and treatment of products sold by the reporting company (in the reporting year) at the end of their life. This category includes the total expected end-of-life emissions from all products sold in the reporting year. This includes emissions from incinerating product materials, emissions from material decomposition in landfill conditions, or emissions from the energy consumed during the product dismantling or recycling process. End-of-life treatment methods (e.g., landfilling, incineration, and recycling) are described in category 5 (Waste generated in operations) and apply to both category 5 and category 12. Calculating emissions from category 12 requires assumptions about the end-of-life treatment methods used by consumers. Companies are required to report a description of the methodologies and assumptions used to calculate emissions. For sold intermediate products, companies should account for the emissions from disposing of the intermediate product at the end of its life, not the final product.",
                    description: "Expanded description for End-of-Life Treatment of Sold Products",
                }),
            },
            downstreamLeasedAssets: {
                header: formatMessage({
                    defaultMessage: "13 - Downstream leased assets",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions from leased assets that are not controlled by the organization but are part of its downstream activities",
                    description: "Label for downstream leased assets scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These are the emissions from the operation of assets that are owned by the reporting company (acting as lessor) and leased to other entities in the reporting year that are not already included in scope 1 or scope 2. This category is applicable to lessors (i.e., companies that receive payments from lessees). Companies that operate leased assets (i.e., lessees) should refer to category 8 (Upstream leased assets). This category could include emissions from the use of appliances or machinery leased to others, or emissions from the use of buildings leased to tenants. Leased assets may be included in a company’s scope 1 or scope 2 inventory depending on the type of lease and the consolidation approach the company uses to define its organizational boundaries. If the reporting company leases an asset for only part of the reporting year, the reporting company should account for emissions from the portion of the year that the asset was leased.",
                    description: "Expanded description for Downstream Leased Assets",
                }),
            },
            franchises: {
                header: formatMessage({
                    defaultMessage: "14 - Franchises",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage: "Emissions from the operations of franchises owned or operated by the organization",
                    description: "Label for franchises scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "These are emissions that come from the operation of franchises associated with your organization - this could include emissions from running equipment, preparing goods for sale, heating and cooling the building, and any other activity at the franchise where greenhouse gases might be released. Category 14 includes emissions from the operation of franchises not included in scope 1 or scope 2. A franchise is a business operating under a license to sell or distribute another company’s goods or services within a certain location. This category is applicable to franchisors (i.e., companies that grant licenses to other entities to sell or distribute its goods or services in return for payments, such as royalties for the use of trademarks and other services). Franchisors should account for emissions that occur from the operation of franchises (i.e., the scope 1 and scope 2 emissions of franchisees) in this category.Your organization can have influence over some of these emissions even if it does not have operational control. Franchisees (i.e., companies that operate franchises and pay fees to a franchisor) should include emissions from operations under their control in this category if they have not included those emissions in scope 1 and scope 2 due to their choice of consolidation approach. Franchisees may optionally report upstream scope 3 emissions associated with the franchisor’s operations (i.e., the scope 1 and scope 2 emissions of the franchisor) in category 1 (Purchased goods and services).",
                    description: "Expanded description for Franchises",
                }),
            },
            investments: {
                header: formatMessage({
                    defaultMessage: "15 - Investments",
                    description: "Label for scope 3 type select",
                }),
                subHeader: formatMessage({
                    defaultMessage:
                        "Emissions associated with the organization's financial investments, such as equity, debt, or project finance",
                    description: "Label for investments scope 3 emissions select",
                }),
                longSubHeader: formatMessage({
                    defaultMessage:
                        "This category includes scope 3 emissions associated with the reporting company’s investments in the reporting year, not already included in scope 1 or scope 2. This category is applicable to investors (i.e., companies that make an investment with the objective of making a profit) and companies that provide financial services. This category also applies to investors that are not profit driven (e.g. multilateral development banks), and the same calculation methods should be used. Investments are categorized as a downstream scope 3 category because providing capital or financing is a service provided by the reporting company. Category 15 is designed primarily for private financial institutions (e.g., commercial banks), but is also relevant to public financial institutions (e.g., multilateral development banks, export credit agencies) and other entities with investments not included in scope 1 and scope 2. Depending on the nature of the businesses you invest in, these indirect, Scope 3 emissions can potentially be large, and for most financial institutions, this category is their largest portion of emissions. In addition to the Greenhouse Gas Protocol, there is also an additional framework describing the calculation method for this category in more depth, Partnership for Carbon Accounting Financials (PCAF).",
                    description: "Expanded description for Investments",
                }),
            },
        }),
        [formatMessage]
    );
};
