import { Skeleton, TableCell, TableRow } from "@mui/material";

import React from "react";

interface Props {
    numberOfRows: number;
    numberOfColumns: number;
    narrowFirstColumn?: boolean;
    narrowLastColumn?: boolean;
    large?: boolean;
}
export const LoadingRows: React.FC<Props> = ({
    numberOfRows,
    numberOfColumns,
    narrowFirstColumn,
    narrowLastColumn,
    large,
}) => (
    <>
        {Array.from({ length: numberOfRows }).map((_, i) => {
            const key = i;
            return (
                <TableRow key={key}>
                    {Array.from({ length: numberOfColumns }).map((__, i2) => {
                        const innerKey = `columns-${i2}${key}`;
                        if ((narrowFirstColumn && i2 === 0) || (narrowLastColumn && i2 === numberOfColumns - 1)) {
                            return (
                                <TableCell key={innerKey} width={5} padding="checkbox">
                                    <Skeleton />
                                </TableCell>
                            );
                        }
                        return (
                            <TableCell key={innerKey} width={100}>
                                <Skeleton height={large ? 27 : undefined} />
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        })}
    </>
);
