import { LoadingRows } from "@/Components/Loading/LoadingTable/LoadingRows";
import { SimpleDialog } from "@/Components/SimpleDialog";
import { Alert, AlertTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    content: React.ReactNode;
    onClose: () => void;
    title: string;
    subtitle: string;
    shouldShow: boolean;
};
export const SourceBreakdownDialog: React.FC<Props> = ({ content, onClose, title, subtitle, shouldShow }) => {
    return (
        <SimpleDialog maxWidth="sm" open={shouldShow} onClose={onClose} title={title} subTitle={subtitle}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage defaultMessage="Source" />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage defaultMessage="Emissions" />
                        </TableCell>
                    </TableRow>
                </TableHead>
                {content}
            </Table>
        </SimpleDialog>
    );
};

export const LoadingContent = () => {
    return (
        <TableBody>
            <LoadingRows numberOfColumns={2} numberOfRows={2} />
        </TableBody>
    );
};

export const ErrorContent = () => {
    return (
        <Alert severity="error">
            <AlertTitle>
                <FormattedMessage defaultMessage="Failed to get data by source" />
            </AlertTitle>
            <FormattedMessage
                defaultMessage="When trying to get data by source something wrong happened. Please try again later."
                description="Sub header for message on uploaded table"
            />
        </Alert>
    );
};
