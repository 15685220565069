import { Autocomplete, createFilterOptions, FilterOptionsState, TextField } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { hasValue } from "utils";
import HierarchicalMenuItem, { Item, SEARCH_TERM_MIN_LENGTH } from "./HierarchicalMenuItem";
import { sortByIfHasChildren } from "./HierarchicalMenuItem/helpers";

export type Props = {
    onSearch?: (search: string) => void;
    items: Item[];
    TextFieldProps?: React.ComponentProps<typeof TextField>;
    placeholder?: string;
    errorMessage?: string;
} & (
    | {
          multiSelect: true;
          selected: string[] | undefined;
          onSelect: (value: string[] | undefined) => void;
      }
    | {
          multiSelect: false;
          selected: string | undefined;
          onSelect: (value: string | undefined) => void;
      }
);

/**
 * When searching for an item, we want to search through all of its children as well.
 * Thus, we need to create a string that contains the label of the item and all of its children.
 */
const getSearchString = (item: Item): string =>
    [item.label ?? item.value, ...(item.children?.map(getSearchString) ?? [])].join(",");

const flattenItems = (structure: Item[]): Item[] => [
    ...structure,
    ...structure.flatMap((unit) => flattenItems(unit.children ?? [])),
];

const AdvancedSelect: React.FC<Props> = ({
    items,
    onSearch,
    TextFieldProps,
    placeholder,
    errorMessage,
    ...propsToBeForwarded
}) => {
    const { selected, multiSelect } = propsToBeForwarded;
    const sortedItems = useMemo(() => sortByIfHasChildren(items), [items]);
    const [searchTerm, setSearchTerm] = React.useState("");

    // Modified filterOptions to only filter if the search term has 2 or more characters
    const filterOptions = useCallback((options: Item[], state: FilterOptionsState<Item>) => {
        const inputValue = state.inputValue.toLowerCase();
        if (inputValue.length < SEARCH_TERM_MIN_LENGTH) {
            return options;
        }
        return createFilterOptions({
            stringify: getSearchString,
        })(options, state);
    }, []);

    const flatItems = useMemo(() => flattenItems(items ?? []), [items]);

    const value = useMemo(() => {
        if (multiSelect) {
            return selected?.map((val) => flatItems.find((item) => item.value === val)).filter(hasValue) ?? [];
        }
        return flatItems.find((i) => i.value === selected);
    }, [flatItems, multiSelect, selected]);

    return (
        <Autocomplete
            size="small"
            multiple={multiSelect}
            options={sortedItems}
            value={value}
            onChange={(event, newValue) => {
                // Only handle `newValue` if it's null here, because the rest is handled by HierarchicalMenuItem
                if (newValue === null) {
                    propsToBeForwarded.onSelect(undefined);
                }
            }}
            onInputChange={(event, newInputValue) => {
                if (onSearch) {
                    onSearch(newInputValue);
                }
                setSearchTerm(newInputValue);
            }}
            filterOptions={filterOptions}
            getOptionLabel={(v) =>
                Array.isArray(v) ? v.map((item) => item.label ?? item.value).join(", ") : (v.label ?? v.value)
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                />
            )}
            renderOption={(props, opt) => (
                <HierarchicalMenuItem searchTerm={searchTerm} key={opt.value} item={opt} {...propsToBeForwarded} />
            )}
        />
    );
};

export default AdvancedSelect;
