import { Avatar, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { CreatedBy } from "router";
import { getLabelForUser } from "../../lib/labelling/userLabel";

interface Props {
    user: CreatedBy | null;
    color?: string;
}
export const UpdatedBy: React.FC<Props> = ({ user, color }) => {
    const label = getLabelForUser(user, useIntl());
    return (
        <Stack direction="column" height="100%" justifyContent="center">
            <Tooltip title={label}>
                <Avatar
                    alt={label}
                    src="/"
                    sx={{ width: 25, height: 25, padding: null, margin: null, bgcolor: color, fontSize: "inherit" }}
                />
            </Tooltip>
        </Stack>
    );
};
