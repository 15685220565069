import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { hasValue } from "@/lib/hasValue";
import React from "react";

import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { ActivityOption, ScopeCategory } from "router";
import { useGetChangeHandlers } from "../../hooks";
import { DefaultFormType } from "../../types";
import { ActivityPickerDeprecated } from "../ActivityPickerDeprecated";

type Props = {
    activityOptions: ActivityOption[];
    scopeCategory: ScopeCategory;
    errorMessage?: string;
    loading?: boolean;
};

export const ActivityL1Picker: React.FC<Props> = ({ activityOptions, loading, scopeCategory, errorMessage }) => {
    const { formatMessage } = useIntl();
    const { setValue, watch } = useFormContext<DefaultFormType>();
    const { handleChangeL1 } = useGetChangeHandlers(
        (l2) => setValue("activityL2", l2),
        (unit) => setValue("unitOfMeasurement", unit)
    );

    const scopeCategoryMessageRecord = useGetAllScopeMessages();
    const options = activityOptions.length
        ? activityOptions
        : [{ type: "predefined", value: "default", label: scopeCategoryMessageRecord[scopeCategory].header } as const];

    const activityL1 = watch("activityL1");
    const activityL2 = watch("activityL2");

    return (
        <ActivityPickerDeprecated
            scopeCategory={scopeCategory}
            loading={loading}
            dataTestId="activity-l1-picker"
            activityOptions={options.map((option) => option.activityL1).filter(hasValue)}
            activity={activityL1}
            setValue={(updateValue) => {
                handleChangeL1(
                    activityOptions.filter((innerOption) => innerOption?.activityL1?.value === updateValue?.value),
                    activityL2
                );
                setValue("activityL1", updateValue);
                setValue("locationBasedValue", null);
            }}
            label={formatMessage({
                defaultMessage: "Activity level 1",
                description: "Header for activity column on uploaded table",
            })}
            errorMessage={errorMessage}
        />
    );
};
