import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "@ignite-analytics/icons";
import {
    Box,
    Button,
    createSvgIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";

const schema = z.object({
    name: z.string().min(1),
    unit: z.string().min(1),
});

const ShapeIcon = createSvgIcon(
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5H32.5C36.9183 0.5 40.5 4.08172 40.5 8.5V32.5C40.5 36.9183 36.9183 40.5 32.5 40.5H8.5C4.08172 40.5 0.5 36.9183 0.5 32.5V8.5Z"
            fill="#EFF8FE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5004 13.832C20.9615 13.832 21.3354 14.2059 21.3354 14.667V19.6654H26.3337C26.7949 19.6654 27.1687 20.0392 27.1687 20.5004C27.1687 20.9615 26.7949 21.3354 26.3337 21.3354H21.3354V26.3337C21.3354 26.7949 20.9615 27.1687 20.5004 27.1687C20.0392 27.1687 19.6654 26.7949 19.6654 26.3337V21.3354H14.667C14.2059 21.3354 13.832 20.9615 13.832 20.5004C13.832 20.0392 14.2059 19.6654 14.667 19.6654H19.6654V14.667C19.6654 14.2059 20.0392 13.832 20.5004 13.832Z"
            fill="#069CDF"
        />
    </svg>,
    "ShapeIcon"
);

type NewEmissionIntensityDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: z.infer<typeof schema>) => void;
};

const NewEmissionIntensityDialog: React.FC<NewEmissionIntensityDialogProps> = ({ open, onClose, onSubmit }) => {
    const { register, handleSubmit } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            name: "",
            unit: "",
        },
    });
    const { formatMessage } = useIntl();

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="xs" fullWidth>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle sx={{ mb: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <ShapeIcon sx={{ height: 40, width: 40 }} />
                        <IconButton onClick={() => onClose()}>
                            <X />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Typography variant="textLg" fontWeight="medium">
                                <FormattedMessage
                                    defaultMessage="Add a new emission intensity type"
                                    description="Header when adding a new emission intensity"
                                />
                            </Typography>
                            <Typography variant="textSm" color="text.text-helper">
                                <FormattedMessage
                                    defaultMessage="No changes will apply to the current years and levels."
                                    description="Subheader when adding a new emission intensity"
                                />
                            </Typography>
                        </Stack>
                        <Divider sx={{ margin: (t) => t.spacing(0.25, 0) }} />
                        <TextField
                            {...register("name")}
                            autoComplete="off"
                            label={
                                <FormattedMessage
                                    defaultMessage="Name"
                                    description="Label for name text input when adding a new emission intensity"
                                />
                            }
                            placeholder={formatMessage({
                                defaultMessage: "Enter a name",
                                description:
                                    "Placeholder vlaue for name text input when adding a new emission intensity",
                            })}
                        />
                        <TextField
                            {...register("unit")}
                            label={
                                <FormattedMessage
                                    defaultMessage="Metric"
                                    description="Label for metric text input when adding a new emission intensity"
                                />
                            }
                            placeholder={formatMessage({
                                defaultMessage: "Examples: mNOK, $M, kNOK, k€",
                                description:
                                    "Placeholder vlaue for name metric input when adding a new emission intensity",
                            })}
                        />
                        <Box>
                            <Divider sx={{ margin: (t) => t.spacing(2, 0, 0, 0) }} />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={1.5}>
                        <Button color="secondary" onClick={() => onClose()}>
                            <FormattedMessage defaultMessage="Close" description="Close dialog button" />
                        </Button>
                        <Button type="submit">
                            <FormattedMessage defaultMessage="Save" description="Save new emission intensity button" />
                        </Button>
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export { NewEmissionIntensityDialog };
