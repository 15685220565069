import { hasValue } from "@/lib/hasValue";
import { trpc } from "@/lib/trpc/client";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DataTakenIntoAccount } from "../../types";
import { flattenTree } from "./helpers";

type Props = {
    dataTakenIntoAccount: DataTakenIntoAccount;
};
export const DataTakenIntoAccountComponent: React.FC<Props> = ({ dataTakenIntoAccount }) => {
    const { supplierId, businessUnitId, spendCategoryId, from, to, tags } = dataTakenIntoAccount;
    const fetchedTags = trpc.getEmissionTags.useQuery();
    const suppliers = trpc.suppliers.useQuery(
        {
            searchPhrase: "",
            supplierId: supplierId ?? undefined,
        },
        { enabled: Boolean(supplierId) }
    );
    const businessUnitsRes = trpc.getBusinessUnitOptions.useQuery(undefined, { enabled: Boolean(businessUnitId) });
    const businessUnits = useMemo(() => {
        if (businessUnitsRes.isSuccess) {
            return flattenTree(businessUnitsRes.data);
        }
        return [];
    }, [businessUnitsRes.data, businessUnitsRes.isSuccess]);
    const spendCategoriesRes = trpc.getSpendCategoryOptions.useQuery(undefined, { enabled: Boolean(spendCategoryId) });
    const spendCategories = useMemo(() => {
        if (spendCategoriesRes.isSuccess) {
            return flattenTree(spendCategoriesRes.data);
        }
        return [];
    }, [spendCategoriesRes.data, spendCategoriesRes.isSuccess]);
    const tagsWithLabel = useMemo(() => {
        if (!fetchedTags.isSuccess) {
            return [];
        }
        return tags
            .map((tag) => {
                const foundTag = fetchedTags.data.tags.find((t) => t.id === tag.id);
                return foundTag ? { label: capitalize(foundTag.name), value: tag.value.label } : null;
            })
            .filter(hasValue);
    }, [fetchedTags.data, fetchedTags.isSuccess, tags]);

    const { formatMessage } = useIntl();

    const formattedData = useMemo(() => {
        const dateString = formatMessage(
            { defaultMessage: "Dates: {from} - {to}" },
            {
                from: from.toLocaleDateString(undefined, { month: "short", year: "numeric", day: "numeric" }),
                to: to.toLocaleDateString(undefined, { month: "short", year: "numeric", day: "numeric" }),
            }
        );
        let textList: string[] = [dateString];
        if (supplierId) {
            const supplier =
                suppliers.data?.supplierStatus === "success"
                    ? suppliers.data?.suppliers.find((s) => s.id === supplierId)
                    : undefined;
            const supplierText = formatMessage(
                { defaultMessage: "Supplier: {supplierName}" },
                { supplierName: supplier?.label }
            );
            textList = [...textList, supplierText];
        }
        if (businessUnitId) {
            const businessUnitName = businessUnits.find((bu) => bu.value === businessUnitId)?.label;
            const businessUnitText = formatMessage(
                { defaultMessage: "Business unit: {businessUnitName}" },
                { businessUnitName }
            );
            textList = [...textList, businessUnitText];
        }
        if (spendCategoryId) {
            const spendCategoryName = spendCategories.find((sc) => sc.value === spendCategoryId)?.label;
            const spendCategoryText = formatMessage(
                { defaultMessage: "Spend category: {spendCategoryName}" },
                { spendCategoryName }
            );
            textList = [...textList, spendCategoryText];
        }
        tagsWithLabel.forEach((tag) => {
            textList = [...textList, `${tag.label}: ${tag.value}`];
        });
        return textList;
    }, [
        supplierId,
        businessUnitId,
        spendCategoryId,
        from,
        to,
        formatMessage,
        tagsWithLabel,
        suppliers,
        businessUnits,
        spendCategories,
    ]);

    return (
        <Stack spacing={0.5}>
            <Typography variant="textSm" fontWeight={400}>
                <FormattedMessage
                    defaultMessage="The following information is already taken into account:"
                    description="Data taken into account description"
                />
            </Typography>
            <Grid container direction="row" spacing={1}>
                {formattedData.map((text) => (
                    <Grid item key={text}>
                        <Chip label={text} />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};
