import { defineMessages } from "react-intl";

// These are static definitions
const messages = defineMessages({
    kg: {
        defaultMessage: "kg",
        description: "Label for kg as the unit of measure",
    },
    t: {
        defaultMessage: "t (tonnes)",
        description: "Label for ton as the unit of measure",
    },
    l: {
        defaultMessage: "l (liter)",
        description: "Label for litre as the unit of measure",
    },
    m3: {
        defaultMessage: "m3 (cubic meter)",
        description: "Label for m3 as the unit of measure",
    },
    kgCO2e: {
        defaultMessage: "kg CO₂e",
        description: "Label for kg CO2e as the unit of measure",
    },
    tCO2e: {
        defaultMessage: "tCO₂e",
        description: "Label for t CO2e as the unit of measure",
    },
    kWh: {
        defaultMessage: "kWh",
        description: "Label for kWh as the unit of measure",
    },
    kWhGross: {
        defaultMessage: "kWh (gross)",
        description: "Label for kWh gross as the unit of measure",
    },
    GJ: {
        defaultMessage: "GJ",
        description: "Label for GJ as the unit of measure",
    },
    km: {
        defaultMessage: "km",
        description: "Label for km as the unit of measure",
    },
    miles: {
        defaultMessage: "miles",
        description: "Label for miles as the unit of measure",
    },
    roomsPerNight: {
        defaultMessage: "Hotel nights",
        description: "Label for roomsPerNight as the unit of measure",
    },
    pkm: {
        defaultMessage: "person-km",
        description: "Label for pkm as the unit of measure",
    },
    Ml: {
        defaultMessage: "million litres",
        description: "Label for Ml as the unit of measure",
    },
    tkm: {
        defaultMessage: "tonne-km",
        description: "Label for tkm as the unit of measure",
    },
    FTEhour: {
        defaultMessage: "Full-time employee work hour",
        description: "Label for FTEhour as the unit of measure",
    },
    kgDivided: {
        defaultMessage: "{value} kg CO₂e/kg",
        description: "Label for kg as the unit of measure",
    },
    tDivided: {
        defaultMessage: "{value} kg CO₂e/t",
        description: "Label for ton as the unit of measure",
    },
    lDivided: {
        defaultMessage: "{value} kg CO₂e/l",
        description: "Label for litre as the unit of measure",
    },
    m3Divided: {
        defaultMessage: "{value} kg CO₂e/m3",
        description: "Label for m3 as the unit of measure",
    },
    kgCO2eDivided: {
        defaultMessage: "{value} kg CO₂e",
        description: "Label for kg CO2e as the unit of measure",
    },
    tCO2eDivided: {
        defaultMessage: "{value} t CO₂e/1000",
        description: "Label for t CO2e as the unit of measure",
    },
    kWhDivided: {
        defaultMessage: "{value} kg CO₂e/kWh",
        description: "Label for kWh as the unit of measure",
    },
    kWhGrossDivided: {
        defaultMessage: "{value} kg CO₂e/kWh (g)",
        description: "Label for kWh gross as the unit of measure",
    },
    GJDivided: {
        defaultMessage: "{value} kg CO₂e/GJ",
        description: "Label for GJ as the unit of measure",
    },
    kmDivided: {
        defaultMessage: "{value} kg CO₂e/km",
        description: "Label for km as the unit of measure",
    },
    milesDivided: {
        defaultMessage: "{value} kg CO₂e/miles",
        description: "Label for miles as the unit of measure",
    },
    roomsPerNightDivided: {
        defaultMessage: "{value} kg CO₂e/Rooms",
        description: "Label for roomsPerNight as the unit of measure",
    },
    pkmDivided: {
        defaultMessage: "{value} kg CO₂e/person-km",
        description: "Label for pkm as the unit of measure",
    },
    MlDivided: {
        defaultMessage: "{value} kg CO₂e/million l",
        description: "Label for Ml as the unit of measure",
    },
    tkmDivided: {
        defaultMessage: "{value} kg CO₂e/t-km",
        description: "Label for tkm as the unit of measure",
    },
    FTEhourDivided: {
        defaultMessage: "{value} kg CO₂e/work hour",
        description: "Label for FTEhour as the unit of measure",
    },
});
export default messages;
