import { useGetMutationCleanupFunction } from "@/Containers/ManualEmissions/hooks";
import { trpc } from "@/lib/trpc/client";
import { Card, Stack } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import React from "react";
import { DataSourceGrid } from "./Components/DataSourceGrid";
import { EmptyState } from "./Components/EmptyState";
import { DataSourceData } from "./types";

export const DataSourcesPage: React.FC = () => {
    const { onFinishedDelete, onErrorDelete } = useGetMutationCleanupFunction();

    const connections = trpc.allAddedConnections.useQuery();
    const deleteDataSource = trpc.deleteConnection.useMutation({
        onError: (error) => {
            onErrorDelete(error.message);
        },
        onSuccess: () => {
            onFinishedDelete();
            connections.refetch();
        },
    });
    const deleteSupplierDataSource = trpc.deleteSupplierConnection.useMutation({
        onError: (error) => {
            onErrorDelete(error.message);
        },
        onSuccess: () => {
            onFinishedDelete();
            connections.refetch();
        },
    });

    const dataSources: DataSourceData = React.useMemo(() => {
        if (connections.status === "success") {
            return {
                status: "success",
                connections: connections.data.data,
            };
        }
        return {
            status: connections.status,
        };
    }, [connections]);

    const showEmptyState = connections.status === "success" && connections.data.data.length === 0;
    return (
        <Card variant="outlined" sx={{ height: "100%", mb: 4 }}>
            {showEmptyState && <EmptyState />}
            <Stack direction="row" spacing={2}>
                {dataSources.status === "success" && !showEmptyState && (
                    <DataSourceGrid
                        dataSources={dataSources}
                        deleteDataSource={(identifier) => {
                            // TODO: This is a temporary solution to handle the different types of data sources should be moved to the server
                            if (identifier.type === "supplier") {
                                deleteSupplierDataSource.mutateAsync({ id: identifier.id });
                                return;
                            }
                            deleteDataSource.mutateAsync({ id: identifier.id });
                        }}
                    />
                )}
            </Stack>
            <Outlet />
        </Card>
    );
};
