import { autocompleteClasses } from "@mui/material";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

export const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});
