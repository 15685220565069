import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { FormV2 } from "@/Components/FormV2";
import { AddActivity } from "@/Components/FormV2/AddActivity";
import { SelectCategoryHeader } from "@/Components/FormV2/Components/SelectCategoryHeader";
import { isEditorRoute } from "@/lib/permissions/permissions";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { scopeCategoryTypes } from "router";
import { dataInputRoute } from "..";

export const addActivityV2 = createRoute({
    getParentRoute: () => dataInputRoute,
    path: "/add-activity",
    beforeLoad: isEditorRoute,
    component: FormV2,
    errorComponent: ErrorBoundary,
});

export const findCategory = createRoute({
    getParentRoute: () => addActivityV2,
    path: "/find-category",
    component: SelectCategoryHeader,
    errorComponent: ErrorBoundary,
});

export const addActivityForm = createRoute({
    getParentRoute: () => addActivityV2,
    path: "/form/$scopeCategory",
    parseParams: ({ scopeCategory }) => {
        const category = z.enum(scopeCategoryTypes).safeParse(scopeCategory);
        if (!category.success) throw new Error("Invalid data source type");
        return {
            scopeCategory: category.data,
        };
    },
    validateSearch: z.object({
        formType: z.enum(["activityL1", "activityL2"]),
        activityL1: z.string().optional(),
        activityL2: z.string().optional(),
        region: z.string().optional(),
        activityYear: z.number().optional(),
        unit: z.string().optional(),
        supplierId: z.string().optional(),
    }),
    component: AddActivity,
    errorComponent: ErrorBoundary,
});
