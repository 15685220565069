import { trpc } from "@/lib/trpc/client";
import { Grid, Skeleton } from "@mui/material";
import { useState } from "react";
import { EmissionDataTag, FieldOption } from "router";
import { SelectedTag } from "../SelectedTag";
import { UnSelectedTag } from "../UnSelectedTag";
import { OptionsSelectModal } from "../OptionsSelectModal";

type Props = {
    optionsSelectedForTags: Record<string, FieldOption>;
    setOptionForTag: (tagId: string, optionId: FieldOption | undefined) => void;
};

export const TagsForActivity: React.FC<Props> = ({ optionsSelectedForTags, setOptionForTag }) => {
    const tags = trpc.getEmissionTags.useQuery();
    const [selectedTag, setSelectedTag] = useState<EmissionDataTag | null>(null);
    if (tags.isError) {
        return null;
    }
    if (tags.isLoading) {
        return <Skeleton variant="text" width={100} />;
    }
    const { data } = tags;
    const getColor = (num: number) => {
        if (num === 0) {
            return "green";
        }
        if (num === 1) {
            return "blue";
        }
        return "red";
    };
    return (
        <Grid container direction="row" gap={1} justifyContent="flex-end">
            {data.tags.map((tag, i) => {
                const { id, name } = tag;
                const value = optionsSelectedForTags[id];
                return value ? (
                    <SelectedTag
                        key={id}
                        color={getColor(i)}
                        selectedOption={value.label}
                        onClick={() => setSelectedTag(tag)}
                    />
                ) : (
                    <UnSelectedTag key={id} name={name} onClick={() => setSelectedTag(tag)} />
                );
            })}
            {selectedTag && (
                <OptionsSelectModal
                    setOptionForTag={(option: FieldOption | undefined) => {
                        setOptionForTag(selectedTag.id, option);
                    }}
                    tagName={selectedTag.name}
                    tagId={selectedTag.id}
                    onClose={() => setSelectedTag(null)}
                    selectedOption={optionsSelectedForTags[selectedTag.id]}
                />
            )}
        </Grid>
    );
};
