import { trpc } from "@/lib/trpc/client";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { UnifiedDataSource } from "router";

type Props = {
    onConnectionSelected: (source?: UnifiedDataSource) => void;
    source?: UnifiedDataSource;
    disabled?: boolean;
};

export const PickDataSource: React.FC<Props> = ({ onConnectionSelected, source, disabled }) => {
    const sources = trpc.getUnifiedDataSources.useQuery();

    const { formatMessage } = useIntl();
    return (
        <Autocomplete
            size="small"
            value={source}
            options={sources.data ?? []}
            isOptionEqualToValue={(option, value_) => option?.id === value_?.id}
            getOptionLabel={(option) => {
                return option.discriminator === "connection" ? option.name : "Supplier";
            }}
            onChange={(_, newValue) => {
                const field = newValue ?? undefined;
                onConnectionSelected(field);
            }}
            disabled={disabled}
            fullWidth
            loading={sources.isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={formatMessage({
                        defaultMessage: "My example source",
                        description: "My example source",
                    })}
                    label={<FormattedMessage defaultMessage="Pick data source" description="Pick source" />}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: "new-password",
                    }}
                />
            )}
        />
    );
};
