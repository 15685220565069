import { defineMessages } from "react-intl";

const messages = defineMessages({
    gaseousFuels: {
        defaultMessage: "Gaseous fuels",
        description: "Label for Activity L1 gaseousFuels",
    },
    liquidFuels: {
        defaultMessage: "Liquid fuels",
        description: "Label for Activity L1 liquidFuels",
    },
    solidFuels: {
        defaultMessage: "Solid fuels",
        description: "Label for Activity L1 solidFuels",
    },
    biofuel: {
        defaultMessage: "Biofuel",
        description: "Label for Activity L1 ioFuel",
    },
    biomass: {
        defaultMessage: "Biomass",
        description: "Label for Activity L1 biomass",
    },
    biogas: {
        defaultMessage: "Biogas",
        description: "Label for Activity L1 biogas",
    },
    carbonDioxideCo2: {
        defaultMessage: "Carbon dioxide (CO₂)",
        description: "Label for Activity L1 carbonDioxideCo2",
    },
    methaneCh4: {
        defaultMessage: "Methane (CH₄)",
        description: "Label for Activity L1 methaneCh4",
    },
    nitrousOxideN2o: {
        defaultMessage: "Nitrous oxide (N₂O)",
        description: "Label for Activity L1 nitrousOxideN2o",
    },
    nitrogenTrifluorideNf3: {
        defaultMessage: "Nitrogen trifluoride (NF₃)",
        description: "Label for Activity L1 nitrogenTrifluorideNf3",
    },
    sulphurHexafluorideSf6: {
        defaultMessage: "Sulphur hexafluoride (SF₆)",
        description: "Label for Activity L1 sulphurHexafluorideSf6",
    },
    smallCar: {
        defaultMessage: "Small car",
        description: "Label for Activity L1 smallCar",
    },
    mediumCar: {
        defaultMessage: "Medium car",
        description: "Label for Activity L1 mediumCar",
    },
    largeCar: {
        defaultMessage: "Large car",
        description: "Label for Activity L1 largeCar",
    },
    averageCar: {
        defaultMessage: "Average car",
        description: "Label for Activity L1 averageCar",
    },
    default: {
        defaultMessage: "Default",
        description: "Label for Activity L1 default",
    },
    hotelStay: {
        defaultMessage: "Hotel stay",
        description: "Label for Activity L1 hotelStay",
    },
    largeMotorbike: {
        defaultMessage: "Large motorbike",
        description: "Label for Activity L1 largeMotorbike",
    },
    mediumMotorbike: {
        defaultMessage: "Medium motorbike",
        description: "Label for Activity L1 mediumMotorbike",
    },
    smallMotorbike: {
        defaultMessage: "Small motorbike",
        description: "Label for Activity L1 smallMotorbike",
    },
    averageMotorbike: {
        defaultMessage: "Average motorbike",
        description: "Label for Activity L1 smallMotorbike",
    },
    hgvs17Tonnes: {
        defaultMessage: "HGVs (>17 tonnes)",
        description: "Label for Activity L1 hgvs17Tonnes",
    },
    "hgvs7.5Tonnes17Tonnes": {
        defaultMessage: "HGVs (>7.5 - 17 tonnes)",
        description: "Label for Activity L1 hgvs7.5Tonnes17Tonnes",
    },
    "hgvs3.57.5Tonnes": {
        defaultMessage: "HGVs (>3.5 - 7.5 tonnes)",
        description: "Label for Activity L1 hgvs3.5Tonnes7.5Tonnes",
    },
    "hgvs3.533t": {
        defaultMessage: "HGVs (>3.5 - 33t)",
        description: "Label for Activity L1 hgvs3.533t",
    },
    hgvs33t: {
        defaultMessage: "HGVs (>33t)",
        description: "Label for Activity L1 hgvs33t",
    },
    refrigeratedHgvs17Tonnes: {
        defaultMessage: "Refrigerated HGVs (>17 tonnes)",
        description: "Label for Activity L1 refrigeratedHgvs17Tonnes",
    },
    "refrigeratedHgvs7.5Tonnes17Tonnes": {
        defaultMessage: "Refrigerated HGVs (>7.5 - 17 tonnes)",
        description: "Label for Activity L1 refrigeratedHgvs7.5Tonnes17Tonnes",
    },
    "refrigeratedHgvs3.57.5Tonnes": {
        defaultMessage: "Refrigerated HGVs (>3.5 - 7.5 tonnes)",
        description: "Label for Activity L1 refrigeratedHgvs3.5Tonnes7.5Tonnes",
    },
    "refrigeratedHgvs3.533t": {
        defaultMessage: "Refrigerated HGVs (>3.5 - 33t)",
        description: "Label for Activity L1 refrigeratedHgvs3.533t",
    },
    refrigeratedHgvs33t: {
        defaultMessage: "Refrigerated HGVs (>33t)",
        description: "Label for Activity L1 refrigeratedHgvs33t",
    },
    "vans1.305To1.74Tonnes": {
        defaultMessage: "Vans (1.305 to 1.74 tonnes)",
        description: "Label for Activity L1 vans1.305To1.74Tonnes",
    },
    "vans1.74To3.5Tonnes": {
        defaultMessage: "Vans (1.74 to 3.5 tonnes)",
        description: "Label for Activity L1 vans1.74To3.5Tonnes",
    },
    "vansUpTo1.305Tonnes": {
        defaultMessage: "Vans (up to 1.305 tonnes)",
        description: "Label for Activity L1 vansUpTo1.305Tonnes",
    },
    flights: {
        defaultMessage: "Flights",
        description: "Label for Activity L1 flights",
    },
    landTravel: {
        defaultMessage: "Land travel",
        description: "Label for Activity L1 landTravel",
    },
    seaTravel: {
        defaultMessage: "Sea travel",
        description: "Label for Activity L1 seaTravel",
    },
    homeOffice: {
        defaultMessage: "Home office",
        description: "Label for Activity L1 homeOffice",
    },
    buildingMaterials: {
        defaultMessage: "Building materials",
        description: "Label for Activity L1 buildingMaterials",
    },
    clothingAndFootwear: {
        defaultMessage: "Clothing and footwear",
        description: "Label for Activity L1 clothingAndFootwear",
    },
    electricalEquipment: {
        defaultMessage: "Electrical equipment",
        description: "Label for Activity L1 electricalEquipment",
    },
    fabricatedMetalProducts: {
        defaultMessage: "Fabricated metal products",
        description: "Label for Activity L1 fabricatedMetalProducts",
    },
    foodAndDrink: {
        defaultMessage: "Food and drink",
        description: "Label for Activity L1 foodAndDrink",
    },
    glass: {
        defaultMessage: "Glass",
        description: "Label for Activity L1 glass",
    },
    metals: {
        defaultMessage: "Metals",
        description: "Label for Activity L1 metals",
    },
    organic: {
        defaultMessage: "Organic",
        description: "Label for Activity L1 organic",
    },
    paperAndCardboard: {
        defaultMessage: "Paper and cardboard",
        description: "Label for Activity L1 paperAndCardboard",
    },
    petroleumProducts: {
        defaultMessage: "Petroleum products",
        description: "Label for Activity L1 petroleumProducts",
    },
    water: {
        defaultMessage: "Water",
        description: "Label for Activity L1 water",
    },
    cargoShip: {
        defaultMessage: "Cargo ship",
        description: "Label for Activity L1 cargoShip",
    },
    freightFlights: {
        defaultMessage: "Freight flights",
        description: "Label for Activity L1 freightFlights",
    },
    freightTrain: {
        defaultMessage: "Freight train",
        description: "Label for Activity L1 freightTrain",
    },
    hgvs: {
        defaultMessage: "HGVs",
        description: "Label for Activity L1 hgvs",
    },
    refrigeratedHgvs: {
        defaultMessage: "Refrigerated HGVs",
        description: "Label for Activity L1 refrigeratedHgvs",
    },
    seaTanker: {
        defaultMessage: "Sea tanker",
        description: "Label for Activity L1 seaTanker",
    },
    vans: {
        defaultMessage: "Vans",
        description: "Label for Activity L1 vans",
    },
    refuse: {
        defaultMessage: "Refuse",
        description: "Label for Activity L1 refuse",
    },
    wasteWater: {
        defaultMessage: "Waste water",
        description: "Label for Activity L1 wasteWater",
    },
    defaultMessageForActivityL1: {
        defaultMessage: "{activity}",
        description: "Label for Activity L1 defaultMessageForActivityL1",
    },
});
export default messages;
