import { OverviewData } from "router";

/**
 * @returns The last three years not including current
 */
export function getRelevantYears() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 3, currentYear - 2, currentYear - 1];
}

export type CompactTableEmissionDataRow = {
    [year: number]: number;
    pctChange: number;
};
type CompactTableEmissionData = {
    scope1: CompactTableEmissionDataRow;
    scope2: CompactTableEmissionDataRow;
    scope3: CompactTableEmissionDataRow;
    total: CompactTableEmissionDataRow;
    largestNumber: number;
};

/**
 * Transforms the emissions data from the API into a structured format used in the compact table.
 * It also calculates the change from the last two years.
 * @param emissionsData Emissions data from the API
 * @returns
 */
export function getStructuredData(emissionsData: OverviewData["emissionData"]): CompactTableEmissionData {
    const relevantYears = getRelevantYears();
    const data = relevantYears.reduce<CompactTableEmissionData>(
        (acc, year, i) => {
            acc.scope1[year] = emissionsData.scope1.categoryTotal.perYear?.[year] ?? 0;
            acc.scope2[year] = emissionsData.scope2.categoryTotal.perYear?.[year] ?? 0;
            acc.scope3[year] = emissionsData.scope3.categoryTotal.perYear?.[year] ?? 0;
            acc.total[year] = emissionsData.total.perYear?.[year] ?? 0;
            if (i === relevantYears.length - 1) {
                const thisYear = relevantYears[i];
                const lastYear = relevantYears[i - 1];
                acc.scope1.pctChange = (acc.scope1[thisYear] - acc.scope1[lastYear]) / acc.scope1[lastYear];
                acc.scope2.pctChange = (acc.scope2[thisYear] - acc.scope2[lastYear]) / acc.scope2[lastYear];
                acc.scope3.pctChange = (acc.scope3[thisYear] - acc.scope3[lastYear]) / acc.scope3[lastYear];
                acc.total.pctChange = (acc.total[thisYear] - acc.total[lastYear]) / acc.total[lastYear];
            }
            return acc;
        },
        {
            scope1: { pctChange: 0 },
            scope2: { pctChange: 0 },
            scope3: { pctChange: 0 },
            total: { pctChange: 0 },
            largestNumber: 0,
        }
    );
    data.largestNumber = Math.max(...Object.values(data.total));
    return data;
}
