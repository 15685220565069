import { defineMessages, useIntl } from "react-intl";
import { SourceConfig } from "./types";

const messages = defineMessages({
    viewActivities: {
        defaultMessage: "View activities",
        description: "View activities",
    },
    viewBreakdownOfSources: {
        defaultMessage: "View breakdown of sources",
        description: "View breakdown of sources",
    },
    drillDownInGraph: {
        defaultMessage: "Drill down in graph",
        description: "Drilldown on graph",
    },
    scopeYearTotal: {
        defaultMessage: "Scope {scope} ({year}) - {total}  tCO₂e",
        description: "Category header for overview table",
    },
    goToSupplierCardForSupplierName: {
        defaultMessage: "Go to supplier profile for {supplierName}",
        description: "Header for go to supplier action",
    },
    viewSupplierCard: {
        defaultMessage: "View supplier profile",
        description: "View supplier profile",
    },
    createNewActivity: {
        defaultMessage: "Create new activity",
        description: "Create new activity",
    },
});

export const useGetMessages = (config: SourceConfig) => {
    const { formatMessage } = useIntl();

    switch (config.type) {
        case "supplier":
            return {
                seeAllActivities: formatMessage(messages.viewActivities),
                viewBreakdownOfSources: formatMessage(messages.viewBreakdownOfSources),
                viewSupplierCard: formatMessage(messages.viewSupplierCard),
                createNewActivity: formatMessage(messages.createNewActivity),
            };
        case "emissionsByScope":
            return {
                viewBreakdownOfSources: formatMessage(messages.viewBreakdownOfSources),
                drillDownInGraph: formatMessage(messages.drillDownInGraph),

                seeAllActivities: formatMessage(messages.viewActivities),
                createNewActivity: formatMessage(messages.createNewActivity),
            };
        case "emissionsByCategory":
            return {
                viewBreakdownOfSources: formatMessage(messages.viewBreakdownOfSources),
                seeAllActivities: formatMessage(messages.viewActivities),
                createNewActivity: formatMessage(messages.createNewActivity),
            };
    }

    return {};
};
