import { addDataSourceTypeRoute, dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import { Link, useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { MenuRadioButtons } from "./MenuRadioButtons";
import { useGetMenuOptions } from "./hooks";

export const AddDataSourceType: React.FC = () => {
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = React.useState("");
    const { options } = useGetMenuOptions();

    const enableEmissionsSetup = useFeatureToggle("use-emissions-column-setup", false);
    const { hideSpendAndSupplier } = useSearch({ from: addDataSourceTypeRoute.id });

    const filteredOptions = React.useMemo(() => {
        let optionsToReturn = options;
        if (hideSpendAndSupplier) {
            optionsToReturn = optionsToReturn.filter(
                (option) => option.value !== "spend" && option.value !== "supplier"
            );
        }
        if (!enableEmissionsSetup) {
            // If we are not using the new setup, exclude it
            optionsToReturn = optionsToReturn.filter((option) => option.tableType !== "spend-based");
        } else {
            // If we are using the new setup, exclude the old spend setup
            optionsToReturn = optionsToReturn.filter((option) => option.tableType !== "spend");
        }
        return optionsToReturn;
    }, [enableEmissionsSetup, hideSpendAndSupplier, options]);

    const currentOption = React.useMemo(
        () => options.find((option) => option.value === selectedOption) ?? undefined,
        [selectedOption, options]
    );

    return (
        <>
            <DialogContent>
                <Stack direction="column">
                    <Typography variant="subtitle2">
                        <FormattedMessage
                            defaultMessage="What type of data source are you adding?"
                            description="Add data source title"
                        />
                    </Typography>

                    <MenuRadioButtons
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        options={filteredOptions}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Link to={dataPreferenceDataSourcesOverview.fullPath}>
                    <Button variant="text" color="secondary">
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button title" />
                        </Typography>
                    </Button>
                </Link>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                        marginLeft: "10px",
                        ":disabled": { backgroundColor: theme.palette.primary.selected },
                    }}
                    disabled={!currentOption}
                    onClick={() => currentOption?.navigateFn()}
                >
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Next" description="Next button title" />
                    </Typography>
                </Button>
            </DialogActions>
        </>
    );
};
