import { ScopeCategory, isScope1Type, isScope2Type, isScope3Type } from "router";

export function getScopeByCategory(scope: ScopeCategory) {
    if (isScope1Type(scope)) {
        return "scope1";
    }
    if (isScope2Type(scope)) {
        return "scope2";
    }
    if (isScope3Type(scope)) {
        return "scope3";
    }
    throw new Error("Scope not found");
}
