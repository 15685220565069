import { Stack, Typography } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { DragIndicator } from "../DraggableIcon";
import type { Column } from "../../RenderFunctions/types";

const DEFAULT_COLUMN_WIDTH = 150;

export const baseColumnProps: Partial<Column> = {
    hideSortIcons: false,
    align: "left",
    headerAlign: "left",
    width: DEFAULT_COLUMN_WIDTH,
    renderHeader: (params: GridColumnHeaderParams) => {
        return (
            <Stack direction="row" alignItems="center">
                <DragIndicator />
                <Typography variant="inherit">{params.colDef.headerName}</Typography>
            </Stack>
        );
    },
};
