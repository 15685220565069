import { DataGrid } from "@/Components/DataGrid";
import { SearchField } from "@/Components/SearchField";
import { Filter } from "@ignite-analytics/icons";
import { Button, Divider, Stack } from "@mui/material";
import { GridFilterModel, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useGetColumns } from "./renderFunctions";
import { RowDataSpendBased } from "./types";

type Props = {
    emissionFactorData: RowDataSpendBased[];
    loading?: boolean;
};
export const SpendEmissionFactorContent: React.FC<Props> = ({ emissionFactorData, loading }) => {
    const apiRef = useGridApiRef();

    const onChangeSearch = useCallback(
        (text: string) => {
            apiRef.current.setQuickFilterValues([text]);
        },
        [apiRef]
    );

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
        quickFilterExcludeHiddenColumns: true,
    });

    const columns = useGetColumns();
    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={1}
                divider={<Divider orientation="vertical" />}
            >
                <SearchField onChange={(_e, value) => onChangeSearch(value)} />
                <Button
                    startIcon={<Filter />}
                    variant="text"
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        apiRef.current.showFilterPanel();
                    }}
                >
                    <FormattedMessage defaultMessage="Filter" description="Filter button" />
                </Button>
            </Stack>
            <DataGrid
                apiRef={apiRef}
                loading={loading}
                disableRowSelectionOnClick
                columns={columns}
                rows={emissionFactorData}
                initialState={{
                    pinnedColumns: { right: ["linkToForm"] },
                    pagination: {
                        paginationModel: {
                            pageSize: 500,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            scope: false,
                            to: false,
                            from: false,
                        },
                    },
                }}
                pageSizeOptions={[100, 500, 1000]}
                pagination
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
            />
        </>
    );
};
