import { TitleCell } from "@/Components/DataGrid";
import { baseColumnProps } from "@/Containers/EditData/Table/Components/BaseColumn";
import { CategoryColumn } from "@/Containers/EditData/Table/Components/CategoryColumn";
import { useGetCategoryOptionsAndMessages } from "@/Containers/EditData/Table/RenderFunctions/helpers";
import { editRoute } from "@/Router/DataInputRoute/EditRoute";
import { CARBON_EVENTS } from "@/lib/tracking";
import { Pen } from "@ignite-analytics/icons";
import { Button, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { isScopeType } from "router";
import { CustomCategoryData, Row } from ".";

export const useDataGridHandlers = (
    categoriesToDelete,
    setCategoriesToDelete,
    setFilterModel,
    deleteCategoriesByIds: (ids: string[]) => void
) => {
    const navigate = useNavigate();

    const onRowSelectionModelChange = (ids) => {
        const parsedIds = z.array(z.string()).safeParse(ids);
        parsedIds.success && setCategoriesToDelete(parsedIds.data);
    };

    const onFilterModelChange = (model) => {
        setFilterModel(model);
    };

    const buildGetOnEditClick = (row) => {
        return (e) => {
            e.stopPropagation();
            navigate({ search: (prev) => ({ ...prev, categoryId: row.id }) });
        };
    };

    const buildGetOnNavigateToActivityClick = (row: ReturnType<typeof useDataGridRows>[number]) => {
        return (e) => {
            e.stopPropagation();
            navigate({
                to: editRoute.id,
                search: row.activityLevel === "1" ? { activityL1: row.id } : { activityL2: row.id },
            });
        };
    };

    const onDelete = () => {
        deleteCategoriesByIds(categoriesToDelete);
        CARBON_EVENTS.CUSTOM_CATEGORY_CONNECTIONS_DELETED(
            {
                page: "DataPreferences",
                component: "CustomCategories",
            },
            { numberOfCategories: categoriesToDelete.length }
        ).track();
        setCategoriesToDelete([]);
    };

    const onSearch = (value: string) => {
        navigate({ search: (prev) => ({ ...prev, searchString: value || undefined }), replace: true });
    };

    return {
        onRowSelectionModelChange,
        onFilterModelChange,
        buildGetOnEditClick,
        onDelete,
        onSearch,
        buildGetOnNavigateToActivityClick,
    };
};

export const useDataGridColumns = (
    buildGetOnEditClick,
    buildGetOnNavigateToActivityClick,
    filterOptionsData
): GridColDef<Row>[] => {
    const { formatMessage } = useIntl();
    const { messages: categoryMessages, filteredOptions: categoryOptions } =
        useGetCategoryOptionsAndMessages(filterOptionsData);

    return useMemo<GridColDef<Row>[]>(
        () => [
            {
                ...baseColumnProps,
                field: "name",
                headerName: formatMessage({
                    defaultMessage: "Name",
                    description: "Header for the column showing the name of a category in the categories table.",
                }),
                align: "left",
                headerAlign: "left",
                minWidth: 150,
                maxWidth: 300,
                flex: 0.5,

                renderCell: ({ value, row }) => {
                    if (row.inUseByActivityNumber > 0) {
                        return (
                            <Tooltip
                                title={formatMessage({
                                    defaultMessage: "This category is in use and can't be deleted",
                                    description: "Tooltip for in use category",
                                })}
                            >
                                <span>
                                    <TitleCell>{value}</TitleCell>
                                </span>
                            </Tooltip>
                        );
                    }
                    return <TitleCell>{value}</TitleCell>;
                },
            },
            {
                ...baseColumnProps,
                field: "scope",
                headerName: formatMessage({
                    defaultMessage: "Scope",
                    description: "Header for the column showing the scope of a category in the categories table.",
                }),
                align: "left",
                minWidth: 100,
                maxWidth: 300,
                type: "singleSelect",
                valueOptions: categoryOptions,
                valueFormatter: (value: string) => {
                    if (isScopeType(value)) {
                        return categoryMessages[value].header;
                    }
                    return "";
                },
                renderCell: ({ value }) => {
                    if (isScopeType(value)) {
                        return <CategoryColumn category={value} />;
                    }
                    return null;
                },
            },
            {
                ...baseColumnProps,
                field: "activityLevel",
                headerName: formatMessage({
                    defaultMessage: "Activity level",
                    description:
                        "Header for the column showing the activity level of a category in the categories table.",
                }),
                align: "left",
                headerAlign: "left",

                minWidth: 50,
                maxWidth: 200,
            },
            {
                ...baseColumnProps,
                field: "createdAt",
                headerName: formatMessage({
                    defaultMessage: "Created",
                    description:
                        "Header for the column showing the date a category was created in the categories table.",
                }),
                align: "left",
                headerAlign: "left",
                minWidth: 50,
                maxWidth: 150,
                type: "date",
                valueFormatter({ value }) {
                    if (!value) return "";
                    return dayjs(value).format("DD MMM YYYY");
                },
            },
            {
                ...baseColumnProps,
                field: "inUseByActivityNumber",
                headerName: formatMessage({
                    defaultMessage: "Related activities",
                    description:
                        "Header for the column showing the number of activities using a category in the categories table.",
                }),
                align: "left",
                headerAlign: "left",
                minWidth: 100,
                flex: 1,
                type: "singleSelect",
                renderCell({ value, row }) {
                    return (
                        <Button
                            disabled={row.inUseByActivityNumber <= 0}
                            color="linkPrimary"
                            size="xsmall"
                            onClick={buildGetOnNavigateToActivityClick(row)}
                            key={row.id}
                        >
                            <FormattedMessage
                                defaultMessage="{numberOfSelectedActivities, plural,
                                =0 {No activities}
                                one {# activity}
                                other {# activities}
                            }"
                                description="Number of activities using this category, displayed in a cell in the categories table."
                                values={{ numberOfSelectedActivities: value }}
                            />
                        </Button>
                    );
                },
            },

            {
                ...baseColumnProps,
                resizable: false,
                sortable: false,
                field: "actions",
                type: "actions",
                align: "center",
                disableReorder: true,
                getActions: (params) => [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Pen />}
                        data-testid="edit-action"
                        label={formatMessage({
                            defaultMessage: "Edit",
                            description: "Edit action in the custom categories table",
                        })}
                        onClick={buildGetOnEditClick(params.row)}
                    />,
                ],
            },
        ],
        [formatMessage, buildGetOnEditClick, buildGetOnNavigateToActivityClick, categoryMessages, categoryOptions]
    );
};

export const useDataGridRows = (data: CustomCategoryData): Row[] => {
    return React.useMemo(() => {
        if (data.status === "success") {
            return data.categories.map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                    scope: category.scope,
                    activityLevel: category.activityL1 ? "2" : "1",
                    createdAt: category.addedAt,
                    inUseByActivityNumber: category.emissionIds.length,
                };
            });
        }
        return [];
    }, [data]);
};
