import { OverviewData } from "@/Containers/LandingPage/types";
import { overviewRoute } from "@/Router/Overview";
import { useGetScope1MessagesRecord } from "@/lib/commonMessages/useGetScope1Messages";
import { TableRow } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { scope1Types } from "router";
import { Cell } from "../Cell";
import { HeaderCell } from "../HeaderCell";

type Props = {
    data: Pick<OverviewData, "scope1">;
    sortedYears: number[];
    topValue: number;
};

export const Scope1Rows: React.FC<Props> = ({ data, sortedYears, topValue }) => {
    const scope1Messages = useGetScope1MessagesRecord();
    const { year: focusYear } = useSearch({ from: overviewRoute.fullPath });
    return (
        <>
            <TableRow>
                <HeaderCell rowSpan={5}>
                    <FormattedMessage defaultMessage="Scope 1" description="Scope 1" />
                </HeaderCell>
            </TableRow>
            {scope1Types.map((scope1Type) => {
                const highlightRowHeader = focusYear === sortedYears[0];
                const scope1Val = data.scope1[scope1Type] ? (data.scope1[scope1Type] ?? 0) : 0;
                return (
                    <TableRow key={scope1Type}>
                        <HeaderCell highlightRight={highlightRowHeader}>{scope1Messages[scope1Type].header}</HeaderCell>
                        {sortedYears.map((year, index) => {
                            const highlight =
                                focusYear === year || !!(sortedYears.length && focusYear === sortedYears[index + 1]);
                            const val = scope1Val[year] ?? 0;
                            return (
                                <Cell
                                    key={year}
                                    year={year}
                                    category={scope1Type}
                                    highlightRight={highlight}
                                    value={val}
                                    topValue={topValue}
                                />
                            );
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};
