import { useSelectedYear } from "@/Containers/LandingPage/hooks";
import { useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { useGetScope1MessagesRecord } from "@/lib/commonMessages/useGetScope1Messages";
import { useGetScope2MessagesRecord } from "@/lib/commonMessages/useGetScope2Messages";
import { useGetScope3MessagesRecord } from "@/lib/commonMessages/useGetScope3Messages";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import { useMemo } from "react";
import { scope1Types, scope2Types, scope3Types } from "router";
import { useGetSearchParamFilters } from "../../../hooks";
import { useGetScopeMessages } from "../../EmissionOverYearsCard/EmissionsPerYear/hooks";

export const useGetSeriesData = () => {
    const selectedScope = useGetSelectedScope();
    const setScope = useSetSelectedScope();
    const { electricity, ...filters } = useGetSearchParamFilters();
    const selectedYear = useSelectedYear();
    const scope1Messages = useGetScope1MessagesRecord();
    const scope2Messages = useGetScope2MessagesRecord();
    const scope3Messages = useGetScope3MessagesRecord();

    const { scope3Message, scope2Message, scope1Message } = useGetScopeMessages();
    const fetchResult = trpc.overview.useQuery(
        { filters, electricity },
        {
            retry,
            trpc: {
                context: {
                    skipBatch: true,
                },
            },
        }
    );

    const data = useMemo(() => {
        if (fetchResult.error) {
            return { type: "error" } as const;
        }

        if (fetchResult.isLoading) {
            return { type: "loading" } as const;
        }
        const { emissionData } = fetchResult.data;

        const goBack = () => {
            setScope("all", "ChartDonut");
        };
        if (selectedScope === "all") {
            return {
                type: "success",
                series: [
                    {
                        name: scope1Message,
                        value: emissionData.scope1?.categoryTotal.perYear?.[selectedYear] || 0,
                        onClick: () => {
                            setScope("1", "ChartDonut");
                        },
                    },
                    {
                        name: scope2Message,
                        value: emissionData.scope2?.categoryTotal.perYear?.[selectedYear] || 0,
                        onClick: () => {
                            setScope("2", "ChartDonut");
                        },
                    },
                    {
                        name: scope3Message,
                        value: emissionData.scope3?.categoryTotal.perYear?.[selectedYear] || 0,
                        onClick: () => {
                            setScope("3", "ChartDonut");
                        },
                    },
                ],
                year: selectedYear,
            };
        }
        if (selectedScope === "1") {
            return {
                type: "success",
                series: scope1Types.map((scope1Type) => ({
                    name: scope1Messages[scope1Type].header,
                    value: emissionData.scope1?.[scope1Type]?.[selectedYear] || 0,
                    category: scope1Type,
                })),
                goBack,
                year: selectedYear,
            };
        }
        if (selectedScope === "2") {
            return {
                type: "success",
                series: scope2Types.map((scope2Type) => ({
                    name: scope2Messages[scope2Type].header,
                    value: emissionData.scope2?.[scope2Type]?.[selectedYear] || 0,
                    category: scope2Type,
                })),
                goBack,
                year: selectedYear,
            };
        }

        return {
            type: "success",
            series: scope3Types.map((scope3Type) => ({
                name: scope3Messages[scope3Type].header,
                value: emissionData.scope3?.[scope3Type]?.[selectedYear] || 0,
                category: scope3Type,
            })),
            goBack,
            year: selectedYear,
        };
    }, [
        selectedScope,
        fetchResult,
        selectedYear,
        scope1Message,
        scope2Message,
        scope3Message,
        scope1Messages,
        scope2Messages,
        scope3Messages,
        setScope,
    ]);
    return data;
};
