import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import React, { useEffect } from "react";
import { useGetMutationCleanupFunction } from "../ManualEmissions/hooks";
import { ActivityData, ActivityLogTable } from "./Table";

export const ActivityLog: React.FC = () => {
    // Settings modal handling
    const { onFinishedDelete, onErrorDelete } = useGetMutationCleanupFunction();

    const deleteConnections = trpc.deleteActivities.useMutation({
        onError: (error) => {
            onErrorDelete(error.message);
        },
        onSuccess: () => {
            onFinishedDelete();
        },
    });
    const onDelete = (ids: string[]) => {
        deleteConnections.mutate({ ids });
    };
    const addedDataResult = trpc.activityDataInEditTable.useQuery({});
    const filterOptionsResult = trpc.getDataGridFilterOptions.useQuery();

    const filterOptions =
        filterOptionsResult.status === "success"
            ? {
                  data: filterOptionsResult.data,
                  status: filterOptionsResult.status,
              }
            : { status: filterOptionsResult.status };

    const activityData: ActivityData =
        addedDataResult.status === "success"
            ? {
                  status: addedDataResult.status,
                  activityData: addedDataResult.data.activityData,
              }
            : {
                  status: addedDataResult.status,
              };

    useEffect(() => {
        CARBON_EVENTS.ACTIVITY_LOG_PAGE_VIEWED({ page: "ActivityLog" }).track();
    }, []);

    return (
        <ActivityLogTable addedActivityData={activityData} filterOptions={filterOptions} deleteActivities={onDelete} />
    );
};
