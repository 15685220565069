import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { DataColumn, DataColumnType } from "../interfaces";

interface Props {
    label: string;
    onChange: (value: DataColumn | null) => void;
    errorMessage?: string;
    value: DataColumn | null;
    dataColumns: DataColumn[];
    type: DataColumnType;
    disabled?: boolean;
}
export const DataColumnSelect: React.FC<Props> = ({
    label,
    onChange,
    errorMessage,
    value,
    dataColumns,
    type,
    disabled,
}) => {
    const columns = dataColumns.filter((column) => column.type === type);
    return (
        <Autocomplete
            disabled={disabled}
            size="small"
            id="data-column-select"
            value={value ?? null}
            options={columns}
            isOptionEqualToValue={(option, value_) => {
                return (
                    option.dataColumnId === value_.dataColumnId &&
                    option.name === value_.name &&
                    option.type === value_.type
                );
            }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue: DataColumn | null) => {
                onChange(newValue);
            }}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
