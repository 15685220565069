import { AlertColor, AlertProps, SnackbarProps } from "@mui/material";
import React, { useCallback, useContext } from "react";

type Message = {
    value: string;
    title?: string;
    severity?: AlertColor;
};

type Alert = {
    message: Message;
    AlertProps?: Omit<AlertProps, "severity">;
    SnackbarProps?: SnackbarProps;
    timestamp: number;
};

type AlertOptions = {
    AlertProps?: Omit<AlertProps, "severity">;
    SnackbarProps?: SnackbarProps;
};

type AlertContextValues = {
    alertUser: (alert: Message, options?: AlertOptions) => void;
    onClose: () => void;
    alert: Alert | null;
};

const AlertContext = React.createContext<AlertContextValues>({
    alertUser: () => null,
    onClose: () => null,
    alert: null,
});

export function useAlert() {
    return useContext(AlertContext);
}

export const AlertProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [alert, setAlert] = React.useState<Alert | null>(null);

    const alertUser = useCallback((message: Message, options?: AlertOptions) => {
        setAlert({ message, timestamp: new Date().getTime(), ...options });
    }, []);

    const handleClose = useCallback(() => {
        setAlert(null);
    }, []);

    const value = React.useMemo(() => ({ alertUser, alert, onClose: handleClose }), [alertUser, alert, handleClose]);
    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
