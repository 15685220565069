import { useState } from "react";
import { Contributor } from "../../components/ContributorTypePicker";

const getContributorLocalStorageKey = () => `Carbon:TopContributorWidget-type-${localStorage.getItem("tenant")}`;

export const useContributorState = (
    initialState: Contributor
): [Contributor, (newContributor: Contributor) => void] => {
    const [contributor, setContributor] = useState<Contributor>(() => {
        const value = localStorage.getItem(getContributorLocalStorageKey());
        return value ? JSON.parse(value) : initialState;
    });
    function onContributorChange(newContributor: Contributor) {
        setContributor(newContributor);
        localStorage.setItem(getContributorLocalStorageKey(), JSON.stringify(newContributor));
    }
    return [contributor, onContributorChange] as const;
};
