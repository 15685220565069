import { formatNumber } from "@/lib/primitives/numbers";
import { Icon, IconButton, Stack, Typography } from "@mui/material";
import { Check, Trash } from "@ignite-analytics/icons";

type Props = {
    files: File[];
    deleteFile: (fileName: string) => void;
};
export const FileUploadList: React.FC<Props> = ({ files, deleteFile }) => {
    if (!files.length) {
        return null;
    }
    return (
        <Stack gap={2}>
            {files.map((file) => {
                const size = file.size ? `${formatNumber(file.size / 1000, 1)}kb` : "0kb";
                return (
                    <Stack direction="row" alignItems="start" justifyContent="space-between" key={file.name}>
                        <Stack>
                            <Typography variant="textSm" fontWeight={500}>
                                {file.name}
                            </Typography>
                            <Typography variant="textXs" fontWeight={400}>
                                {size}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={2}>
                            <Icon color="success" fontSize="small">
                                <Check fontSize="inherit" />
                            </Icon>
                            <IconButton color="secondary" onClick={() => deleteFile(file.name)} size="2xsmall">
                                <Trash fontSize="inherit" />
                            </IconButton>
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
};
