import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { newEmissionIntensitiesRootRoute } from "../../routes";
import { SelectIntensity } from "./SelectIntensity";

const selectIntensityRoute = createRoute({
    getParentRoute: () => newEmissionIntensitiesRootRoute,
    path: "/select-intensity",
    component: SelectIntensity,
    validateSearch: z.object({
        emissionIntensityType: z.enum(["revenue", "employees"]).optional().catch(undefined),
    }),
});

export { selectIntensityRoute };
