import { editCustomOptions, overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { trpc } from "@/lib/trpc/client";
import { Typography, CircularProgress, Grid, Stack, DialogContent, DialogActions, Button } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EmissionDataTag, emissionDataTagSchema } from "router";
import { EditMenu } from "../SideMenu";
import { ChangeName } from "../../Components/ChangeName";
import { OptionsList } from "../../CustomOptionsList";
import { DIALOG_HEIGHT } from "../constants";

type FormProps = {
    initialTag: EmissionDataTag;
    submit: (tag: EmissionDataTag) => void;
};

const EditCustomForm: React.FC<FormProps> = ({ submit, initialTag }) => {
    const [editName, setEditName] = React.useState(false);
    const { name } = useSearch({ from: editCustomOptions.fullPath });
    const navigate = useNavigate();
    const setName = (newName: string) => {
        navigate({ search: (prev) => ({ ...prev, name: newName }) });
    };
    const { control, handleSubmit, reset } = useForm<EmissionDataTag>({
        resolver: zodResolver(emissionDataTagSchema.omit({ id: true, name: true })),
        defaultValues: { ...initialTag, options: initialTag?.options || [] },
    });
    const onSubmit = async (tag: Omit<EmissionDataTag, "id" | "name">) => {
        submit({ ...tag, name, id: initialTag.id });

        reset();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{ height: DIALOG_HEIGHT }}>
                <EditMenu name={name} id={initialTag.id}>
                    <Stack gap={1}>
                        <ChangeName
                            setEdit={setEditName}
                            edit={editName}
                            setName={(inputName) => setName(inputName)}
                            name={name}
                        />
                        <Controller
                            name="options"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <OptionsList
                                    disabled={editName}
                                    options={value}
                                    addOption={(option) => {
                                        onChange([...new Set([...value, option])]);
                                    }}
                                    deleteOption={(option) => {
                                        onChange(value.filter((v) => v !== option));
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </EditMenu>
            </DialogContent>
            <DialogActions>
                <Button type="submit">
                    {initialTag ? (
                        <FormattedMessage defaultMessage="Save" description="Save button for the Tags component" />
                    ) : (
                        <FormattedMessage defaultMessage="Create" description="Create button for the Tags component" />
                    )}
                </Button>
            </DialogActions>
        </form>
    );
};

export const EditCustomOptionsAndName: React.FC = () => {
    const { id } = useSearch({ from: editCustomOptions.fullPath });
    const utils = trpc.useContext();
    const navigate = useNavigate();

    const tag = trpc.getEmissionTag.useQuery({ id });
    const addTag = trpc.updateEmissionTag.useMutation({
        onSuccess: () => {
            utils.getEmissionTagsWithOptions.cancel();
            utils.getEmissionTagsWithOptions.refetch();
        },
    });

    if (tag.isLoading) {
        return (
            <DialogContent sx={{ minHeight: DIALOG_HEIGHT + 65 }}>
                <Grid container justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Grid>
            </DialogContent>
        );
    }
    if (tag.isError || !tag.data) {
        return (
            <DialogContent sx={{ minHeight: DIALOG_HEIGHT + 65 }}>
                <Grid container justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error" variant="textLg">
                        <FormattedMessage defaultMessage="Error loading tag" />
                    </Typography>
                </Grid>
            </DialogContent>
        );
    }
    return (
        <EditCustomForm
            initialTag={tag.data}
            submit={(tagValue) => {
                addTag.mutate({ tag: tagValue });
                navigate({ to: overviewTagRoute.fullPath });
            }}
        />
    );
};
