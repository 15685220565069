import { formatNumber } from "@/lib/primitives/numbers";
import { useMemo } from "react";
import { useIntl } from "react-intl";

type InformationReturn = {
    changePercent: number;
    message: string;
};

export const useGetEmissionsCellInformation = (
    year: number,
    perYear: Partial<Record<string, number>>
): InformationReturn => {
    const { formatMessage } = useIntl();
    const emissions = perYear[year.toString()] ?? 0;
    const emissionsLastYear = perYear[(year - 1).toString()] ?? 0;
    const information = useMemo(() => {
        if (emissionsLastYear === 0) {
            return {
                changePercent: 0,
                message: formatMessage(
                    {
                        defaultMessage: "No data",
                    },
                    {
                        year: year - 1,
                    }
                ),
            };
        }
        const percentageDiff = ((emissions - emissionsLastYear) / Math.abs(emissionsLastYear)) * 100;
        if (percentageDiff === Infinity) {
            return {
                message: "N/A",
                changePercent: 0,
            } as const;
        }
        if (percentageDiff === 0) {
            return {
                changePercent: percentageDiff,
                message: "0%",
            } as const;
        }
        if (percentageDiff > 0) {
            return {
                message: `+${formatNumber(percentageDiff, 2)}%`,
                changePercent: percentageDiff,
            } as const;
        }

        return {
            message: `${formatNumber(percentageDiff, 2)}%`,
            changePercent: percentageDiff,
        } as const;
    }, [emissions, emissionsLastYear, formatMessage, year]);

    return information;
};
