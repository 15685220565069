import { ArrowNarrowLeft, ArrowNarrowRight, XCircle } from "@ignite-analytics/icons";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import {
    ColumnMenuPropsOverrides,
    GridApiPro,
    GridColDef,
    GridColumnMenuContainer,
    GridColumnMenuProps,
    useGridApiContext,
} from "@mui/x-data-grid-pro";
import { isNil } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { yearColDef } from "./yearColDef";
import type { Row } from "./types";

const AddYearLabel: React.FC<{ year: number }> = ({ year }) => {
    return (
        <FormattedMessage
            defaultMessage="Add {year}"
            description="Label for column menu item to add a new year when adding entering information for emission intensities"
            values={{ year }}
        />
    );
};

const hasColumnAnyValues = (data: { gridApi?: GridApiPro; columnField: string }) => {
    if (!data.gridApi) {
        return false;
    }
    // This returns an iterator from MUI, so we kinda have to support it. Most browsers do anyway
    // eslint-disable-next-line no-restricted-syntax
    for (const row of data.gridApi.getRowModels().values()) {
        if (!isNil(row[data.columnField])) {
            return true;
        }
    }
    return false;
};

/**
 * Component for the column menh in our data grid.
 *
 * This column enables users to add and remove years from the table.
 *
 * If there is a gap year to the left or right of the current year, the user can add a new year in that direction.
 * When a year is removed, the column is hidden from the table, and not deleted. This is done for two reasons:
 * 1. There's no simple way to delete columns
 * 2. Allows us to keep the entered data if the user accidentally removes a year, allowing them to easily add it back.
 */
const CustomColumnMenu: React.FC<GridColumnMenuProps & ColumnMenuPropsOverrides> = ({
    onYearRangeChange,
    ...props
}) => {
    const gridApiRef = useGridApiContext();
    // We're passing the entire props object later on, destructuring it would be cumbersome.
    // eslint-disable-next-line react/destructuring-assignment
    const columnField = props.colDef.field;
    // This is a bit naive, as we just parse the field into a number. However, it get the just done for now.
    const currentYear = Number.parseInt(columnField, 10);

    if (Number.isNaN(currentYear)) {
        return null;
    }

    /**
     * To determine if there is a gap year to the left or right of the current year, we check for presence of the
     * previous and next year in the visible columns. If either is present, there is a gap.
     */
    const nonPinnedVisibleColumns = gridApiRef.current
        ?.getVisibleColumns()
        .filter((col) => !gridApiRef.current?.isColumnPinned(col.field));

    const hasGapYearToTheLeft = !nonPinnedVisibleColumns?.some((col) => col.field === `${currentYear - 1}`);
    const hasGapYearToTheRight = !nonPinnedVisibleColumns?.some((col) => col.field === `${currentYear + 1}`);
    const isRightmostColumn = nonPinnedVisibleColumns?.[nonPinnedVisibleColumns.length - 1].field === columnField;
    const isLeftmostColumn = nonPinnedVisibleColumns?.[0].field === columnField;

    const shouldSeeAddYearToTheRight = isRightmostColumn && hasGapYearToTheRight;
    const shouldSeeAddYearToTheLeft = isLeftmostColumn && hasGapYearToTheLeft;

    const hasDataInColumn = hasColumnAnyValues({ gridApi: gridApiRef.current, columnField });
    const isOnlyColumn = isRightmostColumn && isLeftmostColumn;
    const shouldSeeRemoveYear = (isRightmostColumn || isLeftmostColumn) && !isOnlyColumn && !hasDataInColumn;

    function addYear(year: number, placement: "left" | "right") {
        const newColumns: GridColDef<Row>[] = [
            {
                field: `${year}`,
                headerName: `${year}`,
                ...yearColDef,
            },
        ];
        // We add the new column to the existing columns
        gridApiRef.current?.updateColumns(newColumns);
        // If the column was previously added and then removed, we need to make it visible again
        gridApiRef.current?.setColumnVisibility(`${year}`, true);

        /**
         * Determines where to place the new column, either to the left or right of the current column.
         */
        const currentColumnIndex = gridApiRef.current?.getColumnIndex(columnField, false);
        let columnIndex: number;
        if (placement === "right") {
            columnIndex = currentColumnIndex + 1;
        } else {
            columnIndex = Math.max(currentColumnIndex - 1, 1);
        }
        gridApiRef.current?.setColumnIndex(`${year}`, columnIndex);
        gridApiRef.current?.hideColumnMenu();
        if (placement === "right") {
            onYearRangeChange?.({ endYear: year });
        } else if (placement === "left") {
            onYearRangeChange?.({ startYear: year });
        }
    }

    function removeYear(year: number, placement: "start" | "end") {
        if (placement === "start") {
            onYearRangeChange?.({ startYear: year + 1 });
        } else if (placement === "end") {
            onYearRangeChange?.({ endYear: year - 1 });
        }
        gridApiRef.current?.setColumnVisibility(`${year}`, false);
        gridApiRef.current?.hideColumnMenu();
    }

    return (
        <GridColumnMenuContainer {...props}>
            {shouldSeeAddYearToTheRight && (
                <MenuItem onClick={() => addYear(currentYear + 1, "right")}>
                    <ListItemIcon sx={{ fontSize: "16px", width: "26px" }}>
                        <ArrowNarrowRight fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ variant: "textSm" }}>
                        <AddYearLabel year={currentYear + 1} />
                    </ListItemText>
                </MenuItem>
            )}
            {shouldSeeAddYearToTheLeft && (
                <MenuItem onClick={() => addYear(currentYear - 1, "left")}>
                    <ListItemIcon sx={{ fontSize: "16px", width: "26px" }}>
                        <ArrowNarrowLeft fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ variant: "textSm" }}>
                        <AddYearLabel year={currentYear - 1} />
                    </ListItemText>
                </MenuItem>
            )}
            <MenuItem
                onClick={() => {
                    let placement: "start" | "end" | undefined;
                    if (isRightmostColumn) {
                        placement = "end";
                    } else if (isLeftmostColumn) {
                        placement = "start";
                    }
                    if (placement) {
                        removeYear(currentYear, placement);
                    }
                }}
                color="error"
                disabled={!shouldSeeRemoveYear}
            >
                <ListItemIcon sx={{ fontSize: "16px", width: "26px" }}>
                    <XCircle fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "textSm" }}>
                    <FormattedMessage
                        defaultMessage="Remove column"
                        description="Label for column menu item to a year column from the table when entering information for emission intensities"
                    />
                </ListItemText>
            </MenuItem>
        </GridColumnMenuContainer>
    );
};

export { CustomColumnMenu };

declare module "@mui/x-data-grid-pro" {
    interface ColumnMenuPropsOverrides {
        onYearRangeChange?: (data: Partial<{ startYear: number; endYear: number }>) => void;
    }
}
