import { Grid } from "@mui/material";
import { Tag } from "@ignite-analytics/icons";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { FieldOption } from "router";
import { ContextText } from "../ContextText";
import { DefaultFormType } from "../../types";
import { TagsForActivity } from "./TagsForActivity";

export const TagsRow = () => {
    const { setValue, watch } = useFormContext<DefaultFormType>();
    const { formatMessage } = useIntl();

    const tagsValue = watch("tags") ?? {};
    const onChange = (value: Record<string, FieldOption>) => {
        setValue("tags", value);
    };
    return (
        <Grid container wrap="nowrap" gap={2} justifyContent="space-between">
            <Grid item minWidth={180}>
                <ContextText
                    header={formatMessage({ defaultMessage: "Add tags", description: "Header for predefined tags" })}
                    text={formatMessage({
                        defaultMessage:
                            "Add tags to the activity in order to to improve accuracy and make filtering possible.",
                        description: "text for predefined tags",
                    })}
                    icon={<Tag fontSize="small" />}
                />
            </Grid>
            <Grid item direction="column">
                <TagsForActivity
                    setOptionForTag={(id, option) => {
                        const filteredTags = Object.keys(tagsValue).reduce<Record<string, FieldOption>>(
                            (acc, tagKey) => {
                                if (!(tagKey === id)) {
                                    acc[tagKey] = tagsValue[tagKey];
                                }
                                return acc;
                            },
                            {}
                        );
                        if (!option) {
                            onChange(filteredTags);
                            return;
                        }
                        onChange({ ...filteredTags, [id]: option });
                    }}
                    optionsSelectedForTags={tagsValue}
                />
            </Grid>
        </Grid>
    );
};
