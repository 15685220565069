import { Card, FormControlLabel, FormControlLabelProps, Radio, Stack, Typography } from "@mui/material";
import React from "react";

type EmissionTypeProps = {
    header: React.ReactNode;
    subHeader: React.ReactNode;
} & Partial<FormControlLabelProps>;
export const EmissionType = React.forwardRef<HTMLInputElement, EmissionTypeProps>(function EmissionType(
    { header, subHeader, ...formControlLabelProps },
    ref
) {
    return (
        <Card sx={{ padding: (t) => t.spacing(2) }}>
            <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                control={
                    <Radio
                        sx={{
                            // For some reason the CSS width property of 16px set by MUI is not respected, so need minWidth here
                            minWidth: "16px",
                        }}
                    />
                }
                label={
                    <Stack direction="column">
                        <Typography variant="textSm" fontWeight="medium">
                            {header}
                        </Typography>
                        <Typography color={(t) => t.palette.text.textHelper} variant="textSm">
                            {subHeader}
                        </Typography>
                    </Stack>
                }
                {...formControlLabelProps}
                ref={ref}
            />
        </Card>
    );
});
