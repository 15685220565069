import { ButtonProps, TooltipProps } from "@mui/material";
import { useGridRootProps } from "@mui/x-data-grid-pro";
import React from "react";
import { useIntl } from "react-intl";

type GridToolbarActionButtonProps = {
    slotProps?: { button?: Partial<ButtonProps> & { "data-testid"?: string }; tooltip?: Partial<TooltipProps> };
};

const GridToolbarActionButton = React.forwardRef<HTMLButtonElement, GridToolbarActionButtonProps>(
    function GridToolbarActionButton(props, ref) {
        const tooltipProps = props.slotProps?.tooltip || {};
        const buttonProps = props.slotProps?.button || {};
        const rootProps = useGridRootProps();
        const { formatMessage } = useIntl();
        const defaultLabel = formatMessage({
            defaultMessage: "Add",
            description: "Default label for toolbar action button",
        });

        function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
            rootProps?.slotProps?.toolbar?.actionButton?.onClick?.(event);
            buttonProps.onClick?.(event);
        }

        return (
            <rootProps.slots.baseTooltip
                title={defaultLabel}
                enterDelay={1000}
                {...tooltipProps}
                {...rootProps.slotProps?.baseTooltip}
            >
                <rootProps.slots.baseButton
                    ref={ref}
                    size="small"
                    color="primary"
                    aria-label={defaultLabel}
                    startIcon={<rootProps.slots.actionIcon />}
                    {...buttonProps}
                    {...rootProps.slotProps?.baseButton}
                    onClick={handleClick}
                >
                    {buttonProps?.children ? buttonProps.children : defaultLabel}
                </rootProps.slots.baseButton>
            </rootProps.slots.baseTooltip>
        );
    }
);

interface ActionIcon {
    // This matches the type of the other icons in the slots.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actionIcon: React.JSXElementConstructor<any>;
}

interface CustomToolbarProps {
    actionButton?: {
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    };
}

declare module "@mui/x-data-grid-pro" {
    // Disabling this eslint rule as we simply want to extend the module definition with our custom props.
    // The eslint fix breaks the intended behavior.
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface GridProSlotsComponent extends ActionIcon {}
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ToolbarPropsOverrides extends CustomToolbarProps {}
}

export { GridToolbarActionButton };
