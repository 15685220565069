import { AddedConnection } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { DataSourceIdentifier } from "@/Containers/DataSources/DataSourcesPage/types";
import { editDataSourceRoute } from "@/Router/DataPreferencesRoute";
import { trpc } from "@/lib/trpc/client";
import { Pen, Trash, Truck } from "@ignite-analytics/icons";
import { MonetizationOnOutlined, PowerOutlined } from "@mui/icons-material";
import {
    ButtonGroup,
    Card,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import React from "react";
import { DataSourceExpandedCard } from "../DataSourceExpandedCard";
import { DataSourceInfo } from "../DataSourceInfo";

type DataSourceDetailsProps = {
    data: AddedConnection;
    deleteDataSource: (id: DataSourceIdentifier) => void;
};

type DataSourceHeaderPros = {
    data: AddedConnection;
    loading: boolean;
    deleteDataSource: (id: DataSourceIdentifier) => void;
};

const DataSourceHeader: React.FC<DataSourceHeaderPros> = ({ data, loading, deleteDataSource }) => {
    const navigate = useNavigate();

    const onEditClick = () => {
        navigate({
            to: editDataSourceRoute.fullPath,
            params: {
                dataSourceType: data.formType,
                dataSourceId: data.id,
            },
            search: { tableId: data.tableId || undefined },
        });
    };
    if (loading)
        return (
            <Stack direction="row">
                <Skeleton variant="text" width={200} height={40} />
                <Stack direction="column" flex={1} />
                <Skeleton variant="rounded" width={80} height={40} />
            </Stack>
        );
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {data.formType === "spend" && <MonetizationOnOutlined fontSize="small" />}
            {data.formType === "energy" && <PowerOutlined fontSize="small" />}
            {data.formType === "supplier" && <Truck fontSize="small" />}
            <Tooltip title={data.name}>
                <Typography
                    variant="subtitle2"
                    sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxLines: 1 }}
                >
                    {data.name}
                </Typography>
            </Tooltip>

            <Stack direction="column" flex={1} />
            <ButtonGroup
                size="small"
                sx={{
                    /**
                     * Our custom adds padding for button groups, which I don't think is intended. Regardless,
                     * it does not work in this context, so I'm removing it.
                     */
                    padding: 0,
                }}
            >
                <IconButton onClick={onEditClick} data-testid="edit-data-source-button">
                    <Pen />
                </IconButton>
                <IconButton
                    onClick={() =>
                        deleteDataSource({
                            id: data.id,
                            type: data.formType === "supplier" ? "supplier" : "connection",
                        })
                    }
                    data-testid="delete-data-source-button"
                >
                    <Trash />
                </IconButton>
            </ButtonGroup>
        </Stack>
    );
};
export const DataSourceCard: React.FC<DataSourceDetailsProps> = ({ data, deleteDataSource }) => {
    const connectionDetails = trpc.getConnectionDetails.useQuery({
        id: data.id,
    });
    const [showDetails, setShowDetails] = React.useState(false);

    return (
        <Grid item key={data.id} padding={2} md={6} xs={12}>
            <Card
                component={Paper}
                sx={{
                    height: "100%",
                }}
            >
                <CardContent sx={{ height: "100%" }}>
                    <Stack direction="column">
                        <DataSourceHeader
                            data={data}
                            loading={connectionDetails.isLoading}
                            deleteDataSource={deleteDataSource}
                        />
                        <DataSourceInfo
                            data={data}
                            totalEmissions={connectionDetails?.data?.totalEmissions}
                            loading={connectionDetails.isLoading}
                            numberOfActivities={connectionDetails?.data?.numberOfActivities ?? 0}
                        />
                    </Stack>
                </CardContent>
            </Card>
            {showDetails && <DataSourceExpandedCard data={data} onClose={() => setShowDetails(false)} />}
        </Grid>
    );
};
