import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { ScopeCategory } from "router";
import { useCategoryAutocompleteOptionsAndHandlers } from "../helpers";
import { AutocompleteOption } from "../types";

interface ScopeSelectProps {
    onChange: (value: AutocompleteOption | null) => void;
    errorMessage?: string;
    disabled?: boolean;
    value: ScopeCategory;
}

export const ScopeSelect: React.FC<ScopeSelectProps> = ({ onChange, errorMessage, value, disabled }) => {
    const { options, handleGroupBy, handleOptionLabel } = useCategoryAutocompleteOptionsAndHandlers();
    const { formatMessage } = useIntl();
    return (
        <Autocomplete
            disabled={disabled}
            size="small"
            id="data-column-select"
            value={value ?? null}
            options={options.map((option) => option.value)}
            groupBy={handleGroupBy}
            autoHighlight
            getOptionLabel={handleOptionLabel}
            onChange={(_, newValue: ScopeCategory | null) => {
                const match = options.find((option) => option.value === newValue);
                match && onChange(match);
            }}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={formatMessage({
                        defaultMessage: "Scope category",
                        description: "Scope category",
                    })}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};
