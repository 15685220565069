import { Diamond, Trash } from "@ignite-analytics/icons";
import { DataGridPro, DataGridProProps, gridClasses, GridValidRowModel } from "@mui/x-data-grid-pro";
import { useMemo } from "react";

const defaultSlots: DataGridProProps["slots"] = {
    deleteIcon: Trash,
    actionIcon: Diamond,
};

const defaultSlotProps: DataGridProProps["slotProps"] = {
    baseButton: {
        color: "secondary",
        size: "small",
    },
};

const defaultSx: DataGridProProps["sx"] = {
    [`& .${gridClasses.toolbarContainer}`]: {
        padding: (t) => t.spacing(2.5),
        display: "flex",
        flexDirection: "row",
        spacing: (t) => t.spacing(1.5),
        justifyContent: "flex-end",
        alignItems: "center",
        borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
    },
    /**
     * @todo This should be fixed in the theme package. Sets the background color of the pinned columns to the same as the container background.
     * Intentionally adding here to test get feedback from the design team on the interactions before
     * addressing it in the theme package.
     */
    [`& .${gridClasses.columnHeader}`]: {
        "--DataGrid-pinnedBackground": "var(--DataGrid-containerBackground)",
    },
    [`& .${gridClasses.editInputCell}`]: {
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editing"]}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editable"]}:hover`]: {
        outline: (t) => `1px solid ${t.palette.secondary.main}`,
        outlineOffset: "-1px",
        borderRadius: "4px",
    },
};

const DataGrid = <R extends GridValidRowModel = any>({
    slots,
    sx,
    slotProps,
    ...props
}: DataGridProProps<R> & React.RefAttributes<HTMLDivElement>) => {
    const mergedSlotProps = useMemo(() => ({ ...defaultSlotProps, ...slotProps }), [slotProps]);
    const mergedSlots = useMemo(() => ({ ...defaultSlots, ...slots }), [slots]);
    const mergedSx = useMemo(() => ({ ...defaultSx, ...sx }), [sx]);
    return <DataGridPro slotProps={mergedSlotProps} slots={mergedSlots} sx={mergedSx} {...props} />;
};

export { DataGrid };
