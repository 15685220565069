import { ActivityOption, ActivityInformation } from "router";
import { OptionForUnitOfMeasure } from "../../types";

export const getActivityOption = (
    activityOptions: ActivityOption[],
    activityL1: ActivityInformation | null,
    activityL2: ActivityInformation | null
): OptionForUnitOfMeasure => {
    if (!activityL1) {
        return {
            type: "missingLowestActivityLevel",
        };
    }
    if (activityL1.value === "default") {
        return {
            type: "noActivityL1Available",
            option: activityOptions.length > 0 ? activityOptions[0] : null,
        };
    }
    if (!activityL2) {
        const l1Option = activityOptions.find((option) => option.activityL1?.value === activityL1.value);
        if (l1Option?.activityL2 || !l1Option) {
            return {
                type: "missingLowestActivityLevel",
            };
        }
        return {
            type: "unitOfMeasurementAvailable",
            option: l1Option,
        };
    }
    const l2Option = activityOptions.find(
        (option) => option.activityL1?.value === activityL1.value && option.activityL2?.value === activityL2.value
    );
    if (!l2Option) {
        return {
            type: "missingLowestActivityLevel",
        };
    }
    return {
        type: "unitOfMeasurementAvailable",
        option: l2Option,
    };
};
