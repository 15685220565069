import React from "react";
import { FilterType } from "../../interfaces";
import { CompanyStructureFilter } from "./CompanyStructureFilter";
import { DateFilterMenu } from "./DateFilter";
import { ElectricityTypePicker } from "./ElectricityPicker";
import { SourceFilterMenu } from "./SourceFilter";
import { SpendCategoriesFilter } from "./SpendCategoriesFilter";
import { SupplierFilter } from "./SupplierFilter";
import { TagFilterMenu } from "./TagFilter";

interface Props {
    filterType: FilterType;
}

export const FilterMenu: React.FC<Props> = ({ filterType }): JSX.Element => {
    switch (filterType.type) {
        case "sources":
            return <SourceFilterMenu />;
        case "year":
            return <DateFilterMenu />;
        case "electricity":
            return <ElectricityTypePicker />;
        case "businessUnitIds":
            return <CompanyStructureFilter />;
        case "spendCategoryIds":
            return <SpendCategoriesFilter />;
        case "tag":
            return <TagFilterMenu tagId={filterType.tagId} />;
        case "supplierIds":
            return <SupplierFilter />;
    }
};
