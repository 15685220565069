import { InformationCircle } from "@ignite-analytics/icons";
import { Stack, Typography, Tooltip } from "@mui/material";

type Props = {
    icon: React.ReactNode;
    header: string;
    text: string;
};

export const ContextText: React.FC<Props> = ({ icon, header, text }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Stack>{icon}</Stack>
            <Stack>
                <Tooltip title={text}>
                    <Stack direction="row" gap={0.5} alignItems="center">
                        <Typography variant="textSm" fontWeight={500}>
                            {header}
                        </Typography>
                        <InformationCircle fontSize="inherit" />
                    </Stack>
                </Tooltip>
            </Stack>
        </Stack>
    );
};
