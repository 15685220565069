import { formatNumber } from "@/lib/primitives/numbers";
import { Chip, Grid, Stack } from "@mui/material";
import { Cloud } from "@ignite-analytics/icons";
import { FormattedMessage } from "react-intl";
import { EmissionEstimateMeta } from "../types";
import { CalculationExplanation } from "../Components/CalculationExplanation";
import { MetaDataCalculation } from "../Components/MetaDataCalculation";

type Props = {
    estimate: number;
    metaData: EmissionEstimateMeta | null;
    extraInformation?: string[];
};
export const CalculationInformation: React.FC<Props> = ({ metaData, extraInformation, estimate }) => {
    if (metaData === null) {
        return (
            <Stack>
                <Grid paddingBottom={1}>
                    <Chip
                        size="large"
                        icon={<Cloud fontSize="inherit" />}
                        label={
                            <FormattedMessage
                                defaultMessage="Estimate: {estimate}"
                                description="Emission estimate"
                                values={{ estimate: formatNumber(estimate, 2) }}
                            />
                        }
                    />
                </Grid>
                <CalculationExplanation metaData={metaData} extraInformation={extraInformation} />
            </Stack>
        );
    }
    return (
        <Stack gap={1}>
            <MetaDataCalculation estimate={estimate} metaData={metaData} />
            <CalculationExplanation metaData={metaData} extraInformation={extraInformation} />
        </Stack>
    );
};
