import { Checkbox, Stack, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { DefaultFormType } from "../../types";

export const EnergyCertificate = () => {
    const { setValue, watch } = useFormContext<DefaultFormType>();

    const energyCertificate = watch("energyCertificate");

    return (
        <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
                <Checkbox
                    size="large"
                    checked={energyCertificate ?? false}
                    onChange={(e) => {
                        setValue("energyCertificate", e.target.checked);
                    }}
                    name="energyCertificate"
                />
            </Grid>
            <Grid item justifyContent="start" alignItems="start">
                <Stack justifyContent="start" alignItems="start">
                    <Typography variant="textSm" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Add renewable energy certificate (REC)"
                            description="Energy certificate label"
                        />
                    </Typography>
                    <Typography variant="textSm" fontWeight={400}>
                        <FormattedMessage
                            defaultMessage="This activity has documentation validating renewable energy. RECs can be bought to offset market-based scope 2 emissions."
                            description="Energy certificate description text"
                        />
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};
