import { PageLayout } from "@/Components/Layout/PageLayout";
import { createRoute, Outlet } from "@tanstack/react-router";
import { rootRoute } from "..";

const PageLayoutComponent = () => {
    return (
        <PageLayout>
            <Outlet />
        </PageLayout>
    );
};

const pageLayoutRoute = createRoute({
    id: "_pageLayout",
    getParentRoute: () => rootRoute,
    component: PageLayoutComponent,
});

export { pageLayoutRoute };
