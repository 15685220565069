import { trpc } from "@/lib/trpc/client";
import { overviewRoute } from "@/Router/Overview";

import { CARBON_EVENTS } from "@/lib/tracking";
import { useMatchRoute } from "@tanstack/react-router";
import React from "react";

import { Activity } from "router";
import { useGetMutationCleanupFunction } from "../hooks";
import { UpdateActivityForm } from "./Form";
import { formValuesToDefaultValues } from "./helpers";

type Props = {
    id: string;
    activityValues: Activity;
    onCancel: () => void;
};

export const UpdateActivity: React.FC<Props> = ({ id, onCancel, activityValues }) => {
    const { onSuccess, onError } = useGetMutationCleanupFunction();
    const match = useMatchRoute();

    const landingPage = match({ to: overviewRoute.fullPath, fuzzy: true });
    const addActivity = trpc.updateActivity.useMutation({
        onSuccess: () => {
            onSuccess(id);
            onCancel();
        },
        onError: (error) => {
            onError(error.message);
        },
    });

    const onSubmitFormData = async (activity: Activity) => {
        addActivity.mutate({ id, activity });
        CARBON_EVENTS.ACTIVITY_UPDATE(
            { page: landingPage ? "Overview" : "ActivityLog" },
            {
                scope: activity.scopeType,
                activityL1: activity.activityL1,
                activityL2: activity.activityL2,
                unit: activity.unitOfMeasurement,
                exclusionFromConnection: activity.removeEmissionsFromSpendSource,
            }
        ).track();
    };

    return (
        <UpdateActivityForm
            onSubmit={onSubmitFormData}
            onCancel={onCancel}
            defaultValues={formValuesToDefaultValues(activityValues)}
        />
    );
};
