import { useGetSelectedScope } from "@/Providers/DrilldownContext";
import { trpc } from "@/lib/trpc/client";
import { retry } from "@/lib/utils/utils";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { EmissionsDataByYear, Scope1Type, Scope2Type, Scope3Type, scope1Types, scope2Types, scope3Types } from "router";
import { useGetSearchParamFilters } from "../../../hooks";
import { categoryHasValue } from "./helpers";

export type EmissionsData = Record<string, string | number>;

export const useGetScopeMessages = () => {
    const { formatMessage } = useIntl();
    const scope1Message = formatMessage({
        defaultMessage: "Scope 1",
        description: "Scope 1 label",
    });
    const scope2Message = formatMessage({
        defaultMessage: "Scope 2",
        description: "Scope 2 label",
    });
    const scope3Message = formatMessage({
        defaultMessage: "Scope 3",
        description: "Scope 3 label",
    });
    return { scope1Message, scope2Message, scope3Message };
};

const getScopeData = (
    emissionsData: EmissionsDataByYear,
    years: number[]
): { emissionsData: EmissionsData[]; itemsToShow: Record<"1" | "2" | "3", boolean> } => {
    const { scope1, scope2, scope3 } = emissionsData;
    const itemsToShow = {
        "1": emissionsData.scope1.categoryTotal.co2 > 0,
        "2": emissionsData.scope2.categoryTotal.co2 > 0,
        "3": emissionsData.scope3.categoryTotal.co2 > 0,
    };

    const yearData = years.map((year) => {
        return {
            name: `${year}`,
            "1": scope1?.categoryTotal.perYear?.[year] || 0,
            "2": scope2?.categoryTotal.perYear?.[year] || 0,
            "3": scope3?.categoryTotal.perYear?.[year] || 0,
        };
    });

    return { emissionsData: yearData, itemsToShow };
};

const getScope1Data = (
    emissionsData: EmissionsDataByYear,
    years: number[]
): { emissionsData: EmissionsData[]; itemsToShow: Record<Scope1Type, boolean> } => {
    const { scope1 } = emissionsData;
    const itemsToShow = scope1Types.reduce<Record<Scope1Type, boolean>>(
        (acc: Record<Scope1Type, boolean>, curr) => {
            if (categoryHasValue(emissionsData?.scope1?.[curr])) {
                return { ...acc, [curr]: true };
            }
            return { ...acc, [curr]: false };
        },
        {} as Record<Scope1Type, boolean>
    );

    const yearData = years.map((year) => {
        return {
            name: `${year}`,
            ...scope1Types.reduce(
                (acc, curr) => {
                    return { ...acc, [curr]: scope1?.[curr]?.[year] || 0 };
                },
                {} as Record<Scope1Type, number>
            ),
        };
    });

    return { emissionsData: yearData, itemsToShow };
};

const getScope2Data = (
    emissionsData: EmissionsDataByYear,
    years: number[]
): { emissionsData: EmissionsData[]; itemsToShow: Record<Scope2Type, boolean> } => {
    const { scope2 } = emissionsData;
    const itemsToShow = scope2Types.reduce<Record<Scope2Type, boolean>>(
        (acc: Record<Scope2Type, boolean>, curr) => {
            if (categoryHasValue(emissionsData.scope2[curr])) {
                return { ...acc, [curr]: true };
            }
            return { ...acc, [curr]: false };
        },
        {} as Record<Scope2Type, boolean>
    );

    const yearData = years.map((year) => {
        return {
            name: `${year}`,
            ...scope2Types.reduce(
                (acc, curr) => {
                    return { ...acc, [curr]: scope2?.[curr]?.[year] || 0 };
                },
                {} as Record<Scope2Type, number>
            ),
        };
    });
    return { emissionsData: yearData, itemsToShow };
};

const getScope3Data = (
    emissionsData: EmissionsDataByYear,
    years: number[]
): { emissionsData: EmissionsData[]; itemsToShow: Record<Scope3Type, boolean> } => {
    const { scope3 } = emissionsData;
    const itemsToShow = scope3Types.reduce<Record<Scope3Type, boolean>>(
        (acc: Record<Scope3Type, boolean>, curr) => {
            if (categoryHasValue(emissionsData.scope3[curr])) {
                return { ...acc, [curr]: true };
            }
            return { ...acc, [curr]: false };
        },
        {} as Record<Scope3Type, boolean>
    );

    const yearData = years.map((year) => {
        return {
            name: `${year}`,
            ...scope3Types.reduce(
                (acc, curr) => {
                    return { ...acc, [curr]: scope3?.[curr]?.[year] || 0 };
                },
                {} as Record<Scope3Type, number>
            ),
        };
    });

    return { emissionsData: yearData, itemsToShow };
};

export const useGetEmissionsData = () => {
    const selectedScope = useGetSelectedScope();
    const { electricity, ...filters } = useGetSearchParamFilters();

    const fetchResult = trpc.overview.useQuery({ filters, electricity }, { retry });

    const data = useMemo(() => {
        if (fetchResult.error) {
            return { type: "error" } as const;
        }

        if (fetchResult.isLoading) {
            return { type: "loading" } as const;
        }
        const { emissionData } = fetchResult.data;
        // eslint-disable-next-line fp/no-mutating-methods
        const years = [...emissionData.years].sort((a, b) => a - b);
        if (selectedScope === "all") {
            const emissionsSortedByYear = getScopeData(fetchResult.data.emissionData, years);
            return {
                type: "success",
                emissionsSortedByYear,
                years,
                total: emissionData.total.co2,
            };
        }
        if (selectedScope === "1") {
            const emissionsSortedByYear = getScope1Data(fetchResult.data.emissionData, years);
            return {
                type: "success",
                emissionsSortedByYear,
                years,
                total: emissionData.scope1.categoryTotal.co2,
            };
        }
        if (selectedScope === "2") {
            const emissionsSortedByYear = getScope2Data(fetchResult.data.emissionData, years);
            return {
                type: "success",
                emissionsSortedByYear,
                years,
                total: emissionData.scope2.categoryTotal.co2,
            };
        }
        const emissionsSortedByYear = getScope3Data(fetchResult.data.emissionData, years);
        return {
            type: "success",
            emissionsSortedByYear,
            years,
            total: emissionData.scope3.categoryTotal.co2,
        };
    }, [fetchResult, selectedScope]);
    return data;
};
