import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Avatar, Button, Divider, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import { getLabelForUser } from "../../../lib/labelling/userLabel";

type NewComment = { comment: string };

interface Props {
    id: string;
    viewOnly?: boolean;
}

export const ActivityNotes: React.FC<Props> = ({ id, viewOnly }) => {
    const utils = trpc.useContext();
    const intl = useIntl();
    const { control, handleSubmit, reset } = useForm<NewComment>({
        resolver: zodResolver(z.object({ comment: z.string() })),
        defaultValues: { comment: "" },
    });

    const { data } = trpc.comments.useQuery({ activityId: id });
    const update = trpc.comment.useMutation();

    const onSubmit = async ({ comment }: NewComment) => {
        const newComment = await update.mutateAsync({ activityId: id, comment });
        utils.comments.setData({ activityId: id }, (oldData) => (oldData ? [...oldData, newComment] : [newComment]));
        CARBON_EVENTS.COMMENTS_NEW_COMMENT_ADDED({ page: "ActivityForm", component: "ActivityDocsAndNotes" }).track();

        reset();
    };
    return (
        <>
            <List style={{ maxHeight: "100%", overflow: "auto" }}>
                {data?.map((note) => {
                    return (
                        <React.Fragment key={note.id}>
                            <ListItem alignItems="flex-start" key={note.id}>
                                <ListItemText
                                    primary={
                                        <Stack direction="row" spacing={1.5} alignItems="center" sx={{ pb: 1 }}>
                                            <Avatar
                                                sx={{ width: 18, height: 18 }}
                                                alt={getLabelForUser(note.createdBy, intl)}
                                            />
                                            <Typography sx={{ display: "inline" }} component="span" variant="overline">
                                                {/* eslint-disable-next-line react/jsx-no-literals */}
                                                {getLabelForUser(note.createdBy, intl)} -{" "}
                                                {dayjs(note.updatedAt).format("DD/MM/YYYY, HH:mm")}
                                            </Typography>
                                        </Stack>
                                    }
                                    secondary={<Typography variant="textSm">{note.text}</Typography>}
                                />
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );
                }) ?? null}
            </List>
            {!viewOnly && (
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "auto" }}>
                    <Stack spacing={1} alignItems="flex-end" sx={{ pt: 1 }}>
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    size="small"
                                    onChange={onChange}
                                    value={value}
                                    label={
                                        <FormattedMessage description="Label for new note" defaultMessage="New note" />
                                    }
                                    multiline
                                    fullWidth
                                />
                            )}
                        />
                        <Button color="secondary" type="submit">
                            <Typography variant="buttonSmall" sx={{ whiteSpace: "nowrap" }}>
                                <FormattedMessage description="Label for add note" defaultMessage="Add note" />
                            </Typography>
                        </Button>
                    </Stack>
                </form>
            )}
        </>
    );
};
