import { graphql } from "@/__generated__";
import { RouteButton } from "@/Components/RouteButton";
import { mapOverviewFiltersToGraphqlEmissionFilter } from "@/lib/filters/helpers";
import { useQuery } from "@apollo/client";
import { ArrowRight } from "@ignite-analytics/icons";
import { Card, CardActions, CardContent, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useGetSearchParamFilters, useSelectedElectricityBasis, useSelectedYear } from "../../hooks";
import { ChangePercentBadge } from "../components/ChangePercentBadge";

const TotalEmissionsDocument = graphql(`
    query TotalEmissionsCard_GetTotalEmissionsByYear(
        $year: Int!
        $electricityBasis: ElectricityBasis!
        $filter: EmissionsFilter!
    ) {
        getTotalEmissionsByYear(
            electricityBasis: $electricityBasis
            endYear: $year
            startYear: $year
            filter: $filter
        ) {
            results {
                year
                totalEmissions {
                    value
                    percentChange
                }
            }
        }
    }
`);

const TotalEmissionsCard: React.FC = () => {
    const year = useSelectedYear();
    const electricityBasis = useSelectedElectricityBasis();
    const filter = useGetSearchParamFilters();

    const { data, loading, error } = useQuery(TotalEmissionsDocument, {
        variables: {
            year,
            electricityBasis: electricityBasis === "locationBased" ? "LOCATION_BASED" : "MARKET_BASED",
            filter: mapOverviewFiltersToGraphqlEmissionFilter(filter),
        },
    });

    const totalEmissions = data?.getTotalEmissionsByYear?.results[0]?.totalEmissions;
    return (
        <Card sx={{ height: "100%" }} component={Stack} justifyContent="space-between">
            <CardHeader
                title={
                    <FormattedMessage defaultMessage="Total emissions (tCO₂e)" description="Title for summary number" />
                }
                titleTypographyProps={{ variant: "textSm", fontWeight: 500, color: "text.text-secondary" }}
            />
            <CardContent>
                {loading && (
                    <>
                        <Skeleton variant="text" width={100} height={44} />
                        <Skeleton variant="text" width={90} height={32} />
                    </>
                )}
                {error && (
                    <Typography>
                        <FormattedMessage
                            defaultMessage="An unexpected error occurred"
                            description="Used when summary numbers fail to load"
                        />
                    </Typography>
                )}
                {totalEmissions && (
                    <Stack direction="row" spacing={1}>
                        <Typography variant="displayMd">
                            <FormattedNumber value={totalEmissions.value} maximumFractionDigits={2} />
                        </Typography>
                        <ChangePercentBadge change={totalEmissions.percentChange ?? 0} />{" "}
                    </Stack>
                )}
            </CardContent>
            <CardActions>
                <RouteButton to="/top-contributors" endIcon={<ArrowRight />} color="secondary" size="small">
                    <FormattedMessage
                        defaultMessage="View top contributors"
                        description="Button to view top contributors to emissions"
                    />
                </RouteButton>
            </CardActions>
        </Card>
    );
};

export default TotalEmissionsCard;
