import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import React from "react";
import { CountryCode } from "router";

type Props = {
    countryCode: CountryCode;
};

export const RegionColumn: React.FC<Props> = ({ countryCode }) => {
    const country = countryCodeLabelRecord[countryCode];
    return country.label;
};
