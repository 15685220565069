import { useFormatEmissionIntensityCurrency } from "@/Containers/useFormatEmissionIntensityCurrency";
import { trpc } from "@/lib/trpc/client";
import { fromEntries } from "@ignite-analytics/general-tools";
import { Button } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useCurrency } from "../../../useCurrency";
import { useGetSearchParamFilters, useSelectedYear, useTotalsPerYear } from "../../hooks";
import { EmissionIntensityCard } from "./EmissionIntensityCard";

const calculateIntensitiesPerYear = (
    denominatorsPerYear: Partial<Record<string, number>>,
    totalsPerYear: Partial<Record<string, number>>
) => {
    const years = Object.keys(denominatorsPerYear).filter((y) => totalsPerYear[y] && denominatorsPerYear[y]);
    return fromEntries(
        years.map((y) => {
            const total = totalsPerYear[y];
            const denominator = denominatorsPerYear[y];
            return [
                y,
                denominator !== undefined && total
                    ? total /
                      (denominator /
                          // We currently use million units of currency as the denominator
                          1e6)
                    : undefined,
            ];
        })
    );
};

const SpendEmissionIntensityCard: React.FC = () => {
    const filters = useGetSearchParamFilters();
    const currency = useCurrency();
    const formatCurrency = useFormatEmissionIntensityCurrency();
    const emissionIntensityDenominator = trpc.getEmissionIntensityDenominator.useQuery(
        {
            denominator: { type: "spend" },
            filters,
        },
        { retry: 0 }
    );

    const totalsPerYear = useTotalsPerYear({ type: "total" });
    const year = useSelectedYear();

    let intensityThisYear: number | undefined;
    let changeSinceLastPeriod: number | undefined;
    if (emissionIntensityDenominator.data?.type === "success" && typeof totalsPerYear === "object") {
        const denominatorsPerYear = emissionIntensityDenominator.data.data;
        const intensitiesPerYear = calculateIntensitiesPerYear(denominatorsPerYear, totalsPerYear);
        intensityThisYear = intensitiesPerYear[year];
        const intensityLastYear = intensitiesPerYear[(year - 1).toString()];
        if (intensityThisYear && intensityLastYear) {
            changeSinceLastPeriod = (intensityThisYear - intensityLastYear) / intensityLastYear;
        }
    }

    return (
        <EmissionIntensityCard
            emissionIntensityFactor={intensityThisYear}
            changeSinceLastPeriod={changeSinceLastPeriod}
            units={{
                emissionIntensity: formatCurrency({ currency, modifier: "million" }),
                emissions: "tCO₂e",
            }}
            header={
                <FormattedMessage
                    defaultMessage="Emissions per spend"
                    description="Title for emission intensity per spend widget on the overview page"
                />
            }
            action={
                <Button color="secondary" size="xsmall" disabled>
                    <FormattedMessage
                        defaultMessage="Edit data"
                        description="Label for the button to edit data in the emission intensity card on the overview page"
                    />
                </Button>
            }
        />
    );
};

export { SpendEmissionIntensityCard };
