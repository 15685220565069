import { trpc } from "@/lib/trpc/client";
import { X } from "@ignite-analytics/icons";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

const SwitchLevelDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    onSave: (data: { level: string }) => void;
    currentLevel: number;
}> = ({ open, onClose, onSave, currentLevel }) => {
    const { handleSubmit, control } = useForm<{
        level: string;
    }>({
        defaultValues: {
            level: currentLevel.toString(),
        },
    });

    const { data } = trpc.getClassificationStructureDescription.useQuery({ type: "companyStructure" });
    const levelsFromData = Object.keys(data?.examplesPerLevel ?? {}).map((level) => parseInt(level, 10));
    const levels = [0, ...levelsFromData];
    const { formatMessage } = useIntl();

    return (
        <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xs">
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack spacing={1}>
                            <Typography variant="inherit">
                                <FormattedMessage
                                    defaultMessage="Switch level"
                                    description="Title for the dialog to switch the level for which to see emission intensities"
                                />
                            </Typography>
                            <Typography variant="textSm" color={(t) => t.palette.text.textHelper} fontWeight={400}>
                                <FormattedMessage
                                    defaultMessage="No changes will apply to the current years or emission intensity types."
                                    description="Subheader for the dialog when switching the level for which to see emission intensities"
                                />
                            </Typography>
                        </Stack>
                        <IconButton
                            size="xsmall"
                            aria-label={formatMessage({
                                defaultMessage: "Close",
                                description: "Aria label for the close icon on the switch level dialog",
                            })}
                            onClick={onClose}
                        >
                            <X />
                        </IconButton>
                    </Stack>

                    <Divider sx={{ m: (t) => t.spacing(2.5, 0) }} />
                </DialogTitle>
                <DialogContent>
                    <Controller
                        name="level"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup {...field} onChange={(_e, value) => field.onChange(value)}>
                                <Stack spacing={2}>
                                    {levels.map((level) => (
                                        <Stack key={level} direction="row" justifyContent="space-between">
                                            <FormControlLabel
                                                value={level}
                                                control={<Radio />}
                                                label={
                                                    <FormattedMessage
                                                        defaultMessage="Level {level}"
                                                        description="Label for level radio button when switching levels for emission intensities"
                                                        values={{ level }}
                                                    />
                                                }
                                            />
                                            <Stack direction="row" spacing={1}>
                                                {currentLevel === level && (
                                                    <Chip
                                                        color="primary"
                                                        label={
                                                            <FormattedMessage
                                                                defaultMessage="Current"
                                                                description="Label for the badge showing the currently selected level when changing which level for which to see emission intensities."
                                                            />
                                                        }
                                                    />
                                                )}
                                                {data?.examplesPerLevel[level] && (
                                                    <Chip
                                                        label={
                                                            <FormattedMessage
                                                                defaultMessage="{numberOfOptions} options"
                                                                values={{
                                                                    numberOfOptions:
                                                                        data?.examplesPerLevel[level]?.length,
                                                                }}
                                                                description="Label for the badge showing the number of options available for the selected level when changing which level for which to see emission intensities."
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Stack>
                            </RadioGroup>
                        )}
                    />
                    <Divider sx={{ mt: 3 }} />
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" width="100%" spacing={1}>
                        <Button fullWidth color="secondary" onClick={() => onClose()}>
                            <FormattedMessage defaultMessage="Cancel" description="Button to cancel changes" />
                        </Button>
                        <Button fullWidth type="submit">
                            <FormattedMessage defaultMessage="Save" description="Button to save changes" />
                        </Button>
                    </Stack>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export { SwitchLevelDialog };
