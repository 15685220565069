export class Cache<T> {
    private cache: Record<string, { expiry: number; value: T }> = {};

    set(key: string, value: T, ttlMs: number) {
        const now = new Date();
        const item = {
            value,
            expiry: now.getTime() + ttlMs,
        };
        this.cache[key] = item;
    }

    get(key: string): T | null {
        const item = this.cache[key];
        if (!item) {
            return null;
        }
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from cache
            // and return null
            delete this.cache[key];
            return null;
        }
        return item.value;
    }
}
