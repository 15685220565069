import { formatNumber } from "@/lib/primitives/numbers";
import { Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ImprovedEmissionsEstimate } from "router";

type Props = {
    improvedEstimates: Extract<ImprovedEmissionsEstimate, { type: "firstActivitySupplier" }>;
};

export const FirstSupplierActivity: React.FC<Props> = ({ improvedEstimates }) => {
    const theme = useTheme();
    if (!improvedEstimates) return null;
    return (
        <Stack gap={1}>
            <Typography variant="textMd">
                <FormattedMessage defaultMessage="The old, spend-based estimate will not be counted anymore, The new estimate will be based on the activity you're adding." />
            </Typography>

            <Typography variant="textMd" color={theme.palette.grey["600"]}>
                <FormattedMessage
                    defaultMessage="Old spend-based estimate = {total} kg CO₂e ({spend} {currency})"
                    values={{
                        spend: <b>{formatNumber(improvedEstimates.spendBasedEstimate.spendOriginalCurrency, 2)}</b>,
                        currency: improvedEstimates.currency,
                        total: (
                            <b>
                                {formatNumber(
                                    improvedEstimates.spendBasedEstimate.factor *
                                        improvedEstimates.spendBasedEstimate.spend,
                                    2
                                )}
                            </b>
                        ),
                    }}
                />
            </Typography>
        </Stack>
    );
};
