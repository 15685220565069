import { SearchSvg } from "@/lib/svg/search";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    subTitle: string;
};

export const MissingSearch: React.FC<Props> = ({ subTitle }) => {
    return (
        <Stack gap={2} alignItems="center" padding={5}>
            <SearchSvg />
            <Typography variant="textXl" textAlign="center">
                <FormattedMessage defaultMessage="No result found" description="No data message" />
            </Typography>
            <Typography variant="textMd" textAlign="center">
                {subTitle}
            </Typography>
        </Stack>
    );
};
