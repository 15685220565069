import { editTagRoute, overviewTagRoute } from "@/Router/DataPreferencesRoute/TagRoute";
import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { Outlet, useNavigate, useSearch } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";

export const EditModal: React.FC = () => {
    const { name } = useSearch({ from: editTagRoute.fullPath });
    const navigate = useNavigate();
    const close = () => {
        navigate({ to: overviewTagRoute.fullPath });
    };
    return (
        <Dialog open onClose={close} fullWidth maxWidth="lg">
            <Stack justifyContent="space-between">
                <Stack direction="row">
                    <DialogTitle>
                        <FormattedMessage
                            defaultMessage="Edit tag - {tagName}"
                            description="Edit Tag for the Tags component"
                            values={{ tagName: name }}
                        />
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Outlet />
        </Dialog>
    );
};
