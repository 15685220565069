export const WarningCircle = () => {
    return (
        <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="7.5" width="38" height="38" rx="19" fill="#FEF9EA" />
            <rect
                x="1.76562"
                y="1.26562"
                width="50.4688"
                height="50.4688"
                rx="25.2344"
                stroke="#FCB823"
                strokeOpacity="0.16"
                strokeWidth="1.78125"
            />
            <rect
                x="5.32812"
                y="4.82812"
                width="43.3438"
                height="43.3438"
                rx="21.0781"
                stroke="#FCB823"
                strokeOpacity="0.64"
                strokeWidth="1.78125"
            />
            <path
                d="M39.259 32.0296L31.2154 17.4744C29.5985 14.5486 25.3927 14.5486 23.7758 17.4744L15.7321 32.0296C14.1668 34.8622 16.2156 38.3353 19.4519 38.3353H35.5393C38.7756 38.3353 40.8244 34.8622 39.259 32.0296Z"
                stroke="#FCB823"
                strokeOpacity="0.64"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.7628 30.0948L34.7191 15.5396C31.5805 9.86022 23.4163 9.86011 20.2776 15.5396L12.234 30.0948C9.19528 35.5934 13.1724 42.3353 19.4547 42.3353H35.5421C41.8244 42.3353 45.8015 35.5934 42.7628 30.0948Z"
                stroke="#FCB823"
                strokeOpacity="0.16"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.7101 19.7609C28.7678 18.0033 26.2478 18.0024 25.3042 19.7593L18.9182 31.6509C18.0238 33.3164 19.2302 35.3337 21.1207 35.3337H33.8818C35.7715 35.3337 36.978 33.318 36.0851 31.6525L29.7101 19.7609ZM28.3332 24.5001C28.3332 24.0398 27.9601 23.6667 27.4998 23.6667C27.0396 23.6667 26.6665 24.0398 26.6665 24.5001V27.8334C26.6665 28.2937 27.0396 28.6667 27.4998 28.6667C27.9601 28.6667 28.3332 28.2937 28.3332 27.8334V24.5001ZM27.4998 30.3334C27.0396 30.3334 26.6665 30.7065 26.6665 31.1667C26.6665 31.627 27.0396 32.0001 27.4998 32.0001H27.5082C27.9684 32.0001 28.3415 31.627 28.3415 31.1667C28.3415 30.7065 27.9684 30.3334 27.5082 30.3334H27.4998Z"
                fill="#F6970A"
            />
        </svg>
    );
};
