import Grid from "@mui/material/Grid";
import React from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FileListItem from "../FileListItem";
import { UploadFileInfo } from "../FileListItem/UploadFileInfo.model";

interface FilesListProps {
    files: UploadFileInfo[];
    onFileRemove?: (removedFile: UploadFileInfo) => void;
    disabled?: boolean;
    "data-testid"?: string;
}

const FilesList: React.FC<FilesListProps> = ({ files, onFileRemove, disabled, "data-testid": dataTestId }) => {
    const handleDeleteFile = (fileForDelete: UploadFileInfo) => {
        if (files && onFileRemove && !disabled) {
            onFileRemove(fileForDelete);
        }
    };
    if (!files.length) {
        return (
            <Typography variant="textSm" color="GrayText">
                <FormattedMessage defaultMessage="No files added yet" description="No files added yet" />
            </Typography>
        );
    }
    return (
        <Grid data-testid={dataTestId}>
            {files?.map((file: UploadFileInfo) => (
                <FileListItem
                    // newly uploaded files does not have ids, thats why key is generated base on name and index
                    // eslint-disable-next-line react/no-array-index-key
                    key={file.name + file.id}
                    file={file}
                    onDeleteFile={handleDeleteFile}
                    disabled={disabled}
                />
            ))}
        </Grid>
    );
};

export default FilesList;
