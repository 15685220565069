import { useReducer, useEffect } from "react";

type State = {
    data: string | null;
    loading: boolean;
    error: Error | null;
};

type Action = { type: "LOADING" } | { type: "SUCCESS"; payload: string } | { type: "ERROR"; payload: Error };

const dataFetchReducer =
    () =>
    (state: State, action: Action): State => {
        switch (action.type) {
            case "LOADING":
                return { ...state, loading: true };
            case "SUCCESS":
                return { ...state, data: action.payload, loading: false };
            case "ERROR":
                return { ...state, error: action.payload, loading: false };
        }
    };

/*
 * This is a simple custom hook to fetcing image data and get loading and error state
 * if we want more complex functionality we should migrate this to  use react-query or SWR
 * @param url - the url to fetch data from
 * @param typeGuard - a type guard to ensure the data is of the right type
 */
export const useImage = (
    url: string
): {
    data: string | null;
    loading: boolean;
    error: Error | null;
} => {
    const [state, dispatch] = useReducer(dataFetchReducer(), {
        data: null,
        loading: false,
        error: null,
    });
    useEffect(() => {
        let didCancel = false;

        async function fetchData() {
            dispatch({ type: "LOADING" });
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                if (!didCancel) {
                    const res = await fetch(url);
                    const imageBlob = await res.blob();
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    dispatch({
                        type: "SUCCESS",
                        payload: imageObjectURL,
                    });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({
                        type: "ERROR",
                        payload: error instanceof Error ? error : Error("Something went wrong"),
                    });
                }
            }
        }

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [url]);

    return { ...state };
};
