import { CARBON_EVENTS, TrackSource } from "@/lib/tracking";
import { overviewRoute } from "@/Router/Overview";
import { useNavigate } from "@tanstack/react-router";
import { SourceConfig } from "../../types";

export const useGetOnClick = (config: SourceConfig, trackSource: TrackSource) => {
    const navigate = useNavigate();

    if (config.type === "supplier") {
        const { year, supplierId, supplierName } = config;
        return () => {
            CARBON_EVENTS.BREAKDOWN_OF_SOURCES_CLICK?.(trackSource, {
                viewType: "supplier",
                year,
            }).track();
            if (!year) return;

            navigate({
                search: (prev) => ({
                    ...prev,
                    breakdown: {
                        year: Number(year),
                        discriminator: { type: "supplier", supplierId, supplierName },
                    },
                }),
            });
        };
    }
    if (config.type === "emissionsByScope") {
        const { year, scope } = config;
        return () => {
            CARBON_EVENTS.BREAKDOWN_OF_SOURCES_CLICK?.(trackSource, {
                year,
                scope,
            }).track();

            navigate({
                from: overviewRoute.fullPath,
                to: overviewRoute.fullPath,
                search: (prev) => ({
                    ...prev,
                    breakdown: { year, discriminator: { type: "overview", category: `scope${scope}` } },
                }),
            });
        };
    }
    if (config.type === "emissionsByCategory") {
        const { year, category } = config;
        return () => {
            CARBON_EVENTS.BREAKDOWN_OF_SOURCES_CLICK?.(trackSource, {
                year,
                category,
            }).track();

            navigate({
                from: overviewRoute.fullPath,
                to: overviewRoute.fullPath,
                search: (prev) => ({
                    ...prev,
                    breakdown: { year, discriminator: { type: "overview", category } },
                }),
            });
        };
    }

    return undefined;
};
