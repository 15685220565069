import React, { useMemo } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { ActivityInformation, ScopeCategory } from "router";
import { useGetLabelForL1AndL2Handler } from "../../AddActivity/Form/helpers";

type Props = {
    activityOptions: ActivityInformation[];
    activity: ActivityInformation | null;
    setValue: (id: ActivityInformation | null) => void;
    loading?: boolean;
    label: string;
    scopeCategory: ScopeCategory;
    errorMessage?: string;
    dataTestId?: string;
    disabled?: boolean;
};
export const ActivityPicker: React.FC<Props> = ({
    activity,
    activityOptions,
    setValue,
    loading,
    label,
    scopeCategory,
    errorMessage,
    dataTestId,
    disabled,
}) => {
    const { formatMessage } = useIntl();
    const getLabel = useGetLabelForL1AndL2Handler(scopeCategory);
    const uniqueActivities = useMemo(
        () => [...new Map(activityOptions.map((activityItem) => [activityItem.value, activityItem])).values()],
        [activityOptions]
    );
    const selectedActivity = useMemo(
        () => uniqueActivities.find((activityOption) => activityOption.value === activity?.value),
        [activity?.value, uniqueActivities]
    );
    const customOptionsExist = useMemo(
        () => uniqueActivities.some((activityOption) => activityOption.type === "custom"),
        [uniqueActivities]
    );
    return (
        <Autocomplete
            disableClearable={activity?.value === "default"}
            disabled={disabled}
            size="small"
            loading={loading}
            options={uniqueActivities}
            // MUI Autocomplete requires NULL to be set in order to clear the value from outside
            value={selectedActivity ?? activity}
            getOptionLabel={(activityOption) => {
                return getLabel(activityOption.value, activityOption.label);
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, newValue) => {
                if (newValue) {
                    setValue(newValue);
                    return;
                }
                setValue(null);
            }}
            groupBy={
                customOptionsExist
                    ? (option) =>
                          option.type === "custom"
                              ? formatMessage({
                                    defaultMessage: "Custom",
                                    description: "Used as label for custom activity categories",
                                })
                              : formatMessage({
                                    defaultMessage: "Standard",
                                    description: "Used as label for standard activity categories",
                                })
                    : undefined
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": dataTestId,
                    }}
                    label={label}
                    error={!!errorMessage}
                    helperText={errorMessage ?? ""}
                    placeholder={formatMessage({
                        defaultMessage: "Select an activity type",
                    })}
                />
            )}
        />
    );
};
