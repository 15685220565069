import { ArrowTrendDown, ArrowTrendUp } from "@ignite-analytics/icons";
import { Chip, Tooltip } from "@mui/material";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

type Props = {
    change: number;
};

const TrendArrow = ({ changePercent }: { changePercent: number }) => {
    if (changePercent === 0) {
        return null;
    }
    if (changePercent > 0) {
        return <ArrowTrendUp />;
    }
    return <ArrowTrendDown />;
};

const ChangePercentBadge: React.FC<Props> = ({ change }) => {
    let color: "success" | "error" | "neutral" = "neutral";
    if (change > 0) {
        color = "error";
    } else if (change < 0) {
        color = "success";
    }
    return (
        <Tooltip
            title={
                <FormattedMessage defaultMessage="Change since previous year" description="Tooltip for trend chip" />
            }
            placement="top"
        >
            <Chip
                color={color}
                size="large"
                variant="status"
                icon={<TrendArrow changePercent={change} />}
                // This is not an actual style object, it's just a prop called style
                // eslint-disable-next-line react/style-prop-object
                label={<FormattedNumber value={change} style="percent" signDisplay="exceptZero" />}
            />
        </Tooltip>
    );
};

export { ChangePercentBadge };
