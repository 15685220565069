import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { customGridClasses } from "../../constants";

export const DragIndicator = () => {
    return (
        <DragIndicatorIcon
            className={customGridClasses.dragIndicator}
            sx={{
                fontSize: 14,
                paddingRight: "4px",
                display: "none",
                color: "grey.500",
            }}
        />
    );
};
