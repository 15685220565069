import { DrillDownContextProvider, useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { Box, Card, CardContent, Stack } from "@mui/material";
import React from "react";
import { BackButtonForChart } from "../components/BackButtonForChart";
import { EmissionsPerScopeHeader } from "./EmissionsPerScopeHeader";
import { PieChartWrapper } from "./PieChart";
import { useGetSeriesData } from "./PieChart/hooks";

const EmissionsPerScopeCard: React.FC = () => {
    const chartData = useGetSeriesData();
    const setSelectedScope = useSetSelectedScope();
    const selectedScope = useGetSelectedScope();
    return (
        <Card
            sx={{
                minHeight: "100%",
            }}
        >
            <CardContent height="100%" component={Stack} spacing={2.5}>
                <EmissionsPerScopeHeader />
                <Stack direction="column" position="relative">
                    <Box position="absolute" top={0} right={0} zIndex={1}>
                        <BackButtonForChart
                            visible={selectedScope !== "all"}
                            goBack={() => {
                                setSelectedScope("all", "EmissionOverYearsCard");
                            }}
                        />
                    </Box>
                    <PieChartWrapper data={chartData} />
                </Stack>
            </CardContent>
        </Card>
    );
};

const WrappedEmissionsPerScopeCard: React.FC = () => {
    return (
        <DrillDownContextProvider chartType="donut">
            <EmissionsPerScopeCard />
        </DrillDownContextProvider>
    );
};
export default WrappedEmissionsPerScopeCard;
