import { DrillDownContextProvider } from "@/Providers/DrilldownContext";
import { pixelsToRem } from "@/lib/accessibility";
import { CARBON_EVENTS } from "@/lib/tracking";
import { Card, CardContent, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import EmissionsByClassificationStructureChart from "./EmissionsByClassificationStructureChart";
import EmissionsByScopeChart from "./EmissionsByScopeChart";
import { useSelectedPieChartBreakdown } from "./hooks";
import { PossibleBreakdown, breakDownOptions } from "./options";

const EmissionsBreakdownCard: React.FC = () => {
    const [selectWidth, setSelectWidth] = useState("auto");
    const measureRef = useRef<HTMLDivElement>(null);
    const [breakdown, setBreakdown] = useSelectedPieChartBreakdown();
    useEffect(() => {
        if (measureRef.current) {
            const widths = Array.from(measureRef.current.children).map((child) =>
                child instanceof HTMLElement ? child.offsetWidth : 0
            );
            setSelectWidth(`${Math.max(...widths) + 60}px`);
        }
    }, []);

    const handleChange = (bd: PossibleBreakdown) => {
        setBreakdown(bd);
        CARBON_EVENTS.PIECHART_BREAKDOWN_CHANGED({ page: "Overview" }, { breakdown: bd }).track();
    };
    return (
        <Card
            sx={{
                minHeight: "100%",
            }}
        >
            <CardContent height="100%" component={Stack} spacing={2.5}>
                <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" height={60}>
                        <Typography variant="textLg" component="h2" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Breakdown"
                                description="Title for the emissions breakdown card"
                            />
                        </Typography>

                        {/* Hidden element used to calculate necessary width of select menu */}
                        <div
                            style={{ visibility: "hidden", position: "absolute", whiteSpace: "nowrap" }}
                            ref={measureRef}
                        >
                            {breakDownOptions.map((option) => (
                                <div key={option.value}>{option.label}</div>
                            ))}
                        </div>

                        <Select
                            value={breakdown}
                            onChange={(e) => handleChange(e.target.value as PossibleBreakdown)}
                            size="small"
                            sx={{ height: pixelsToRem(40), width: selectWidth }}
                            autoWidth
                        >
                            {breakDownOptions.map((option) => (
                                <MenuItem value={option.value} sx={{ width: selectWidth }} key={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <Divider />
                </Stack>
                <Stack direction="column" position="relative">
                    {breakdown === "scope" ? (
                        <DrillDownContextProvider chartType="donut">
                            <EmissionsByScopeChart />
                        </DrillDownContextProvider>
                    ) : (
                        <EmissionsByClassificationStructureChart structure={breakdown} />
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default EmissionsBreakdownCard;
