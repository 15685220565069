import { Container, ContainerProps, styled } from "@mui/material";

const AppLayoutContainer = styled(Container)(({ theme }) => ({
    "*:where(html:not([data-new-layout])) &": {
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        padding: theme.spacing(0, 0, 12.5, 0),
    },
    "*:where(html[data-new-layout]) &": {
        flex: "1 1 calc(100vh - var(--appbar-height))",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
}));

/**
 * App Layout should never grow beyond the size of the screen, except for special cases where the screen is extremely small.
 * It should automatically fill, but never exceed, the available height. The parent element is a flex container.
 */
const AppLayout: React.FC<ContainerProps> = (props) => {
    return <AppLayoutContainer disableGutters={false} maxWidth={false} {...props} />;
};

export { AppLayout };
