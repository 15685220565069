import { trpc } from "@/lib/trpc/client";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FilterField } from "router";
import { TagColumnPicker } from "./SinglePicker";

interface Props {
    setColumnForTag: (tagId: string, columnId: FilterField | undefined) => void;
    columnsSelectedForTags: Record<string, FilterField>;
    tableId: string;
}
export const TagColumnSelect: React.FC<Props> = ({ setColumnForTag, columnsSelectedForTags, tableId }) => {
    const tags = trpc.getEmissionTags.useQuery();
    const fields = trpc.filterFieldsForTable.useQuery({ tableId });
    if (
        tags.isError ||
        tags.isLoading ||
        tags.data.tags.length === 0 ||
        fields.isError ||
        fields.isLoading ||
        fields.data.filterFields.length === 0
    ) {
        return null;
    }

    return (
        <Stack textAlign="center" gap={1}>
            <Typography variant="textMd">
                <FormattedMessage
                    defaultMessage="Select fields for tags (optional)"
                    description="Select columns for tags"
                />
            </Typography>
            <Stack direction="row" gap={1}>
                {tags.data?.tags.map((tag) => (
                    <TagColumnPicker
                        key={tag.id}
                        tagLabel={tag.name}
                        onChange={(value) => setColumnForTag(tag.id, value)}
                        value={columnsSelectedForTags[tag.id] ?? null}
                        fields={fields.data.filterFields}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
