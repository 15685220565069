import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";

type Props = {
    onDelete: () => void;
    open: boolean;
    onClose: () => void;
    title: string;
    subTitle?: string;
    cancelButtonTitle?: MessageDescriptor;
    deleteButtonTitle?: MessageDescriptor;
    titleIcon?: React.ReactNode;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const DeleteModal: React.FC<Props> = ({
    onDelete,
    open,
    onClose,
    title,
    subTitle,
    cancelButtonTitle,
    deleteButtonTitle,
    titleIcon,
    maxWidth,
}) => {
    const titles = defineMessages({
        delete: {
            defaultMessage: "Delete",
            description: "Delete",
        },
        cancel: {
            defaultMessage: "Cancel",
            description: "Cancel",
        },
    });

    const { formatMessage } = useIntl();
    return (
        <Dialog open={open} onClose={onClose} maxWidth={maxWidth ?? "md"}>
            <DialogTitle>
                <Stack direction="row" gap={1} alignItems="center">
                    {titleIcon !== undefined && titleIcon}
                    {title}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="textMd">{subTitle ?? ""}</Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between">
                    <Button onClick={() => onClose()} color="secondary" variant="text">
                        {formatMessage(cancelButtonTitle ?? titles.cancel)}
                    </Button>
                    <Button onClick={() => onDelete()} color="primary">
                        {formatMessage(deleteButtonTitle ?? titles.delete)}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
