import { trpc } from "@/lib/trpc/client";
import { addDataSourceDetailsRoute, addDataSourceTypeRoute } from "@/Router/DataPreferencesRoute";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Plus } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { CardHeader, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

type Props = {
    hasSpendOrSupplier?: boolean;
    hasSources?: boolean;
};
export const DataSourcesPageHeader: React.FC<Props> = ({ hasSpendOrSupplier, hasSources }) => {
    const navigate = useNavigate();
    const isCustomer = useFeatureToggle("is-a-customer-carbon", true);
    const addedConnections = trpc.addedConnections.useQuery();

    const customerAlreadyHasConfiguredSpendConnection =
        isCustomer &&
        !addedConnections.isLoading &&
        addedConnections?.data?.data?.some(
            (connection) => connection.type === "spend" && Boolean(connection.yearColumn)
        );

    const handleClick = () => {
        // We do not want to show customers the modal to select other data sources than spend-based when creating new ones
        if (isCustomer) {
            navigate({ to: addDataSourceDetailsRoute.fullPath, params: { dataSourceType: "spend-based" } });
            return;
        }

        navigate({
            to: addDataSourceTypeRoute.fullPath,
            search: { hideSpendAndSupplier: !!hasSpendOrSupplier },
        });
    };
    return (
        <CardHeader
            title={
                <Typography variant="textLg">
                    <FormattedMessage defaultMessage="Data sources" description="Data sources title" />
                </Typography>
            }
            subheader={
                <Grid spacing={2} alignItems="flex-start" container>
                    <Grid item sm={8.5} md={9} lg={10}>
                        <Typography variant="textSm">
                            <FormattedMessage
                                defaultMessage="Here you can extract data from your existing sources in Ignite or upload external data using Data module first, and then create a new data source here. If you need assistance, feel free to reach out to us."
                                description="Data sources title intro message"
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3.5} md={3} lg={2}>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Tooltip
                                title={
                                    customerAlreadyHasConfiguredSpendConnection && (
                                        <FormattedMessage
                                            defaultMessage="You already have a spend data source configured. You can only add one spend data source."
                                            description="Tooltip message for add data source button when already have spend data source"
                                        />
                                    )
                                }
                            >
                                <Grid item>
                                    {hasSources && (
                                        <LoadingButton
                                            loading={addedConnections.isLoading}
                                            disabled={customerAlreadyHasConfiguredSpendConnection}
                                            startIcon={<Plus />}
                                            color="primary"
                                            onClick={handleClick}
                                            size="small"
                                        >
                                            <Typography variant="inherit" noWrap>
                                                <FormattedMessage
                                                    defaultMessage="Add data source"
                                                    description="Add data source button title"
                                                />
                                            </Typography>
                                        </LoadingButton>
                                    )}
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
