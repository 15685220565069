import { useIntl } from "react-intl";

/**
 * Format a currency and modifier based on the provided locale. Uses the `Intl.NumberFormat` API to format the currency,
 * but returns a string where the actual value is omitted. This is intended to be used by emission intensities to display
 * the currency in the denominator.
 */
function currencyFormat(options: {
    locale: string;
    modifier: "million" | "thousand";
    currency: string | undefined;
}): string {
    /**
     * `Intl.NumberFormat` requires `currency` to be a currency code when using `style="currency"`. If the currency is
     * not provided, it'll throw a `TypeError`. As such, if the currency is not provided, we return an empty string.
     * Right now, this is likely to happen while company currency is loading.
     */
    if (!options.currency) {
        return "";
    }

    try {
        let value: number;
        switch (options.modifier) {
            case "million":
                value = 1e6;
                break;
            case "thousand":
                value = 1e3;
                break;
        }
        const numberFormat = Intl.NumberFormat(options.locale, {
            currency: options.currency,
            style: "currency",
            notation: "compact",
            currencyDisplay: "code",
        });

        const parts = numberFormat.formatToParts(value);

        const compactModifier = parts.find((part) => part.type === "compact")?.value;
        const currency = parts.find((part) => part.type === "currency")?.value;

        const currencyParts: string[] = [];
        if (compactModifier) {
            currencyParts.push(compactModifier);
        }
        if (currency) {
            currencyParts.push(currency);
        }

        if (currencyParts.length === 2) {
            return currencyParts.join(" ");
        }
        // Some locales (looking at you, de-DE) don't have a compact modifier for 1e3. As a fallback, we just return the currency
        // with the value.
        return numberFormat.format(value);
    } catch (err) {
        // If Intl.NumberFormat throws an error for some reason, we just default to returning the modifier in English.
        console.error(err);
        return "M";
    }
}

/**
 * @returns A function that return a string with the currency formatted based on the users' locale, currency, and modifier
 * @example
 * ```typescript
 * const formatEmissionIntensityCurrency = useFormatEmissionIntensityCurrency();
 * const formattedCurrency = formatEmissionIntensityCurrency({ currency: "USD", modifier: "thousand" });
 * // formattedCurrency => "K USD"
 * ```
 *
 */
function useFormatEmissionIntensityCurrency(): (options: {
    currency: string | undefined;
    modifier: "thousand" | "million";
}) => string {
    const { locale } = useIntl();

    return (options) => currencyFormat({ locale, ...options });
}

export { useFormatEmissionIntensityCurrency };
