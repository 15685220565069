import { getScopeByCategory } from "@/Router/interfaces";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { formatNumber } from "@/lib/primitives/numbers";
import { trpc } from "@/lib/trpc/client";
import { TableBody, TableCell, TableRow } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmissionsFromSource, ScopeCategory, TopSupplierEmissionsFromSource } from "router";
import { useGetSearchParamFilters } from "../../hooks";
import { ErrorContent, LoadingContent, SourceBreakdownDialog } from "../SourceBreakdownDialog";

type SourceData = { status: "loading" } | { status: "error" } | { status: "success"; data: EmissionsFromSource[] };

export const useGetMessagesFromEmissionsBySource = () => {
    const { formatMessage } = useIntl();
    const getMessageForSource = (emissionBySource: EmissionsFromSource | TopSupplierEmissionsFromSource) => {
        if (emissionBySource.type === "tableConnection") {
            return emissionBySource.name;
        }

        if (emissionBySource.type === "supplierSource") {
            return formatMessage({
                defaultMessage: "Supplier table",
                description: "Supplier",
            });
        }
        return formatMessage({
            defaultMessage: "Activity data",
            description: "Other",
        });
    };
    return getMessageForSource;
};
type ContentProps = {
    year: number;
    breakdownType:
        | {
              type: "category";
              value: ScopeCategory;
          }
        | {
              type: "scope";
              value: "scope1" | "scope2" | "scope3";
          }
        | {
              type: "year";
          };
    data: EmissionsFromSource[];
};

const OverviewSourceBreakdownContent: React.FC<ContentProps> = ({ year, breakdownType, data }) => {
    const getMessageForSource = useGetMessagesFromEmissionsBySource();
    const emissionsBySource = useMemo(() => {
        return data.map<{ name: string; emissions: number }>((source) => {
            const name = getMessageForSource(source);
            if (breakdownType.type === "year") {
                return {
                    name,
                    emissions: source.emissions.total.perYear?.[year] ?? 0,
                };
            }
            if (breakdownType.type === "scope") {
                return {
                    name,
                    emissions: source.emissions[breakdownType.value].categoryTotal.perYear?.[year] ?? 0,
                };
            }
            return {
                name,
                emissions: source.emissions[getScopeByCategory(breakdownType.value)][breakdownType.value]?.[year] ?? 0,
            };
        });
    }, [breakdownType, data, getMessageForSource, year]);

    return (
        <TableBody>
            {emissionsBySource.map((emissionsAndName) => {
                const { emissions, name } = emissionsAndName;
                return (
                    <TableRow key={name}>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                            <FormattedMessage
                                defaultMessage="{emissions} tCO₂e"
                                description="Emissions"
                                values={{ emissions: formatNumber(emissions ?? 0, 2) }}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

type OverviewSourceBreakdownProps = {
    year: number;
    category: ScopeCategory | "scope1" | "scope2" | "scope3" | null;
    onClose: () => void;
};

export const OverviewSourceBreakdown: React.FC<OverviewSourceBreakdownProps> = ({ year, category, onClose }) => {
    const messages = useGetAllScopeMessages();
    const { formatMessage } = useIntl();
    const { electricity, ...filters } = useGetSearchParamFilters();
    const overviewPerSourceResult = trpc.overviewPerSource.useQuery({ filters, electricity });

    const data: SourceData = React.useMemo(() => {
        if (overviewPerSourceResult.isSuccess) {
            return { data: overviewPerSourceResult.data, status: "success" };
        }
        return { status: overviewPerSourceResult.status };
    }, [overviewPerSourceResult]);

    const title = formatMessage({
        defaultMessage: "Breakdown of sources ",
        description: "Title for the breakdown dialog",
    });

    const subtitle = useMemo(() => {
        if (category === null) {
            return formatMessage(
                {
                    defaultMessage: "For data sources in {year}",
                    description: "SubTile for the breakdown dialog",
                },
                {
                    year,
                }
            );
        }
        if (category === "scope1" || category === "scope2" || category === "scope3") {
            return formatMessage(
                {
                    defaultMessage: "Scope {scope} in {year}",
                    description: "SubTile for the breakdown dialog",
                },
                {
                    scope: category[category.length - 1],
                    year,
                }
            );
        }
        return formatMessage(
            {
                defaultMessage: "{category} in {year}",
                description: "SubTile for the breakdown dialog",
            },
            {
                category: messages[category].header,
                year,
            }
        );
    }, [year, category, formatMessage, messages]);

    const breakDownType = React.useMemo(() => {
        if (category === null) {
            return {
                type: "year" as const,
            };
        }
        if (category === "scope1" || category === "scope2" || category === "scope3") {
            return {
                type: "scope" as const,
                value: category,
            };
        }
        return {
            type: "category" as const,
            value: category,
        };
    }, [category]);

    const content: React.ReactNode = React.useMemo(() => {
        switch (data.status) {
            case "success":
                return <OverviewSourceBreakdownContent data={data.data} year={year} breakdownType={breakDownType} />;
            case "loading":
                return <LoadingContent />;
            case "error":
                return <ErrorContent />;
        }
    }, [data, year, breakDownType]);

    return (
        <SourceBreakdownDialog
            content={content}
            title={title}
            subtitle={subtitle}
            onClose={onClose}
            shouldShow={!!data}
        />
    );
};
