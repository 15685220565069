import { CARBON_EVENTS } from "@/lib/tracking";
import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { TopContributorsTable } from "./components/TopContributorsTable";

export const TopContributorsPage: React.FC = () => {
    useEffect(() => {
        CARBON_EVENTS.OVERVIEW_TOP_CONTRIBUTORS_TABLE_PAGE_VIEWED({
            page: "Overview",
            component: "TopContributorsTable",
        }).track();
    }, []);

    return (
        <Stack direction="column" spacing={3} height="100%">
            <Typography variant="displayXs" component="h1" fontWeight={700}>
                <FormattedMessage defaultMessage="Top contributors" description="Top emission contributors header" />
            </Typography>
            <TopContributorsTable />
        </Stack>
    );
};
