import { CategoryActionPopover } from "@/Components/ActionPopover/CategoryActionPopover";
import { formatNumber } from "@/lib/primitives/numbers";
import { TableCell, Typography, useTheme } from "@mui/material";
import React from "react";
import { ScopeCategory } from "router";
import { getColorFromValue } from "./helpers";

interface Props {
    value: number;
    topValue: number;
    highlightRight?: boolean;
    bottom?: boolean;
    year: number;
    category: ScopeCategory;
}

export const Cell: React.FC<Props> = ({ highlightRight, value, topValue, bottom, year, category }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
    const styleBottomBorder = React.useMemo(() => {
        return bottom ? { borderBottomColor: theme.palette.primary.main } : {};
    }, [bottom, theme.palette.primary.main]);

    const styleRightBorder = React.useMemo(() => {
        return highlightRight ? { borderRightColor: theme.palette.primary.main } : {};
    }, [highlightRight, theme.palette.primary.main]);

    const styleBackgroundColor = React.useMemo(() => {
        return { backgroundColor: getColorFromValue(value, topValue) };
    }, [topValue, value]);

    const selectedBorderStyle = React.useMemo(() => {
        return anchorEl ? { outline: `1px solid red` } : {};
    }, [anchorEl]);

    const style = React.useMemo(() => {
        return {
            margin: 1,
            ...styleBottomBorder,
            ...styleRightBorder,
            ...styleBackgroundColor,
            ...selectedBorderStyle,
            cursor: "pointer",
        };
    }, [styleBackgroundColor, styleBottomBorder, styleRightBorder, selectedBorderStyle]);

    const handleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (value === 0) {
        return (
            <>
                <TableCell sx={{ ...style }} align="right" onClick={handleClick}>
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    <Typography variant="subtitle2">-</Typography>
                </TableCell>
                <CategoryActionPopover
                    config={{
                        type: "emissionsByCategory",
                        category,
                        total: value,
                        year,
                    }}
                    anchorEl={anchorEl ?? undefined}
                    handleClose={handleClose}
                    open={open}
                    trackSource={{
                        page: "Overview",
                        component: "OverviewTable",
                    }}
                />
            </>
        );
    }
    return (
        <>
            <TableCell sx={{ ...style }} align="right" onClick={handleClick}>
                <Typography variant="textSm">{formatNumber(value, 2)}</Typography>
            </TableCell>
            <CategoryActionPopover
                config={{
                    type: "emissionsByCategory",
                    category,
                    total: value,
                    year,
                }}
                anchorEl={anchorEl ?? undefined}
                handleClose={handleClose}
                open={open}
                trackSource={{
                    page: "Overview",
                    component: "OverviewTable",
                }}
            />
        </>
    );
};
