import { dataPreferenceDataSourcesOverview } from "@/Router/DataPreferencesRoute";
import { Button, Stack } from "@mui/material";
import { Link } from "@tanstack/react-router";
import React from "react";

import { FormattedMessage } from "react-intl";

interface Props {
    update?: boolean;
}
export const ButtonRow: React.FC<Props> = ({ update }) => {
    return (
        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <Link to={dataPreferenceDataSourcesOverview.fullPath}>
                <Button size="medium" color="secondary" variant="text" fullWidth>
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button label" />
                </Button>
            </Link>
            <Button size="medium" variant="contained" type="submit" color="primary">
                {update ? (
                    <FormattedMessage
                        defaultMessage="Save"
                        description="Submit button label used for changing emissions"
                    />
                ) : (
                    <FormattedMessage defaultMessage="Submit" description="Submit button label used for emissions" />
                )}
            </Button>
        </Stack>
    );
};
