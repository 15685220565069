/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n            query Carbon_searchPaginatedActivityOptions($searchTerm: String, $paginationInput: PaginationInput!) {\n                searchPaginatedActivityOptions(paginationInput: $paginationInput, searchTerm: $searchTerm) {\n                    activities {\n                        id\n                        name\n                        category\n                    }\n                }\n            }\n        ": types.Carbon_SearchPaginatedActivityOptionsDocument,
    "\n    mutation DataSourceForms_CreateEmissionsSetup($input: EmissionsSetupInput!) {\n        createEmissionsSetup(input: $input) {\n            errorMessage\n        }\n    }\n": types.DataSourceForms_CreateEmissionsSetupDocument,
    "\n    query SpendBasedEmissions_getSpendBasedEmissionsSetupOptions {\n        getSpendBasedEmissionsSetupOptions {\n            yearColumn {\n                name\n                dataColumnId\n            }\n        }\n    }\n": types.SpendBasedEmissions_GetSpendBasedEmissionsSetupOptionsDocument,
    "\n    query getTopContributors_OverviewPage(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n": types.GetTopContributors_OverviewPageDocument,
    "\n    query TotalEmissionsCard_GetTotalEmissionsByYear(\n        $year: Int!\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter!\n    ) {\n        getTotalEmissionsByYear(\n            electricityBasis: $electricityBasis\n            endYear: $year\n            startYear: $year\n            filter: $filter\n        ) {\n            results {\n                year\n                totalEmissions {\n                    value\n                    percentChange\n                }\n            }\n        }\n    }\n": types.TotalEmissionsCard_GetTotalEmissionsByYearDocument,
    "\n    query getTopContributors_TopContributorsTable(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n": types.GetTopContributors_TopContributorsTableDocument,
    "\n            query Carbon_useCurrency_getCurrency {\n                getCurrentCustomer {\n                    id\n                    currency\n                }\n            }\n        ": types.Carbon_UseCurrency_GetCurrencyDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            query Carbon_searchPaginatedActivityOptions($searchTerm: String, $paginationInput: PaginationInput!) {\n                searchPaginatedActivityOptions(paginationInput: $paginationInput, searchTerm: $searchTerm) {\n                    activities {\n                        id\n                        name\n                        category\n                    }\n                }\n            }\n        "): (typeof documents)["\n            query Carbon_searchPaginatedActivityOptions($searchTerm: String, $paginationInput: PaginationInput!) {\n                searchPaginatedActivityOptions(paginationInput: $paginationInput, searchTerm: $searchTerm) {\n                    activities {\n                        id\n                        name\n                        category\n                    }\n                }\n            }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DataSourceForms_CreateEmissionsSetup($input: EmissionsSetupInput!) {\n        createEmissionsSetup(input: $input) {\n            errorMessage\n        }\n    }\n"): (typeof documents)["\n    mutation DataSourceForms_CreateEmissionsSetup($input: EmissionsSetupInput!) {\n        createEmissionsSetup(input: $input) {\n            errorMessage\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query SpendBasedEmissions_getSpendBasedEmissionsSetupOptions {\n        getSpendBasedEmissionsSetupOptions {\n            yearColumn {\n                name\n                dataColumnId\n            }\n        }\n    }\n"): (typeof documents)["\n    query SpendBasedEmissions_getSpendBasedEmissionsSetupOptions {\n        getSpendBasedEmissionsSetupOptions {\n            yearColumn {\n                name\n                dataColumnId\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getTopContributors_OverviewPage(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query getTopContributors_OverviewPage(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query TotalEmissionsCard_GetTotalEmissionsByYear(\n        $year: Int!\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter!\n    ) {\n        getTotalEmissionsByYear(\n            electricityBasis: $electricityBasis\n            endYear: $year\n            startYear: $year\n            filter: $filter\n        ) {\n            results {\n                year\n                totalEmissions {\n                    value\n                    percentChange\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query TotalEmissionsCard_GetTotalEmissionsByYear(\n        $year: Int!\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter!\n    ) {\n        getTotalEmissionsByYear(\n            electricityBasis: $electricityBasis\n            endYear: $year\n            startYear: $year\n            filter: $filter\n        ) {\n            results {\n                year\n                totalEmissions {\n                    value\n                    percentChange\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getTopContributors_TopContributorsTable(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query getTopContributors_TopContributorsTable(\n        $contributorType: ContributorType!\n        $level: Int\n        $electricityBasis: ElectricityBasis!\n        $filter: EmissionsFilter\n    ) {\n        getTopContributorEmissions(\n            contributorType: $contributorType\n            level: $level\n            electricityBasis: $electricityBasis\n            filter: $filter\n        ) {\n            results {\n                supplier {\n                    id\n                    name\n                }\n                businessUnit {\n                    id\n                    name\n                }\n                spendCategory {\n                    id\n                    name\n                }\n                totalEmissions\n                emissionsByYear {\n                    year\n                    value\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n            query Carbon_useCurrency_getCurrency {\n                getCurrentCustomer {\n                    id\n                    currency\n                }\n            }\n        "): (typeof documents)["\n            query Carbon_useCurrency_getCurrency {\n                getCurrentCustomer {\n                    id\n                    currency\n                }\n            }\n        "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;