import { overviewRoute } from "@/Router/Overview";
import { useSearch } from "@tanstack/react-router";
import { useIntl } from "react-intl";

export const useGetRowName = (rowType: "Scope 1" | "Scope 2" | "Scope 3" | "Total") => {
    const { formatMessage } = useIntl();
    const { electricity } = useSearch({ from: overviewRoute.id });
    let descriptor: string | undefined;
    const marketBased = formatMessage({
        defaultMessage: "Market-based",
        description: "Market-based value",
    });
    const locationBased = formatMessage({
        defaultMessage: "Location-based",
        description: "Location-based value",
    });
    if (rowType === "Scope 2") {
        descriptor = electricity === "locationBased" ? locationBased : marketBased;
    }
    return { name: rowType, descriptor };
};
