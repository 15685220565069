import { OVERVIEW_YEAR_LOCAL_STORAGE_KEY, getOverviewYearLocalStorageKey } from "@/constants";
import { getScopeByCategory } from "@/lib/emissions";
import { formatNumber } from "@/lib/primitives/numbers";
import { trpc } from "@/lib/trpc/client";
import { useNavigate, useSearch } from "@tanstack/react-router";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { DeprecatedEmissionsFilter, Filters, ScopeCategoryType, SourceFilter } from "router";
import { SourceFilterType } from "./Filter/constants";

export interface OverviewFilters extends Filters {
    // TODO: Move electricity and year out of this interface
    electricity: "marketBased" | "locationBased";
    year: number;

    // Until date filter is supported, it's null
    dateFilter: null;
}

export const getYear = () => {
    const localStorageKey = getOverviewYearLocalStorageKey();
    // TODO: Remove this after august 2024, only added to make it backwards compatible with the old method
    const legacyYearFromLocalStorage = localStorage.getItem(OVERVIEW_YEAR_LOCAL_STORAGE_KEY);
    const yearFromLocalStorage = localStorage.getItem(localStorageKey) ?? legacyYearFromLocalStorage;
    if (yearFromLocalStorage) {
        const yearInNumber = parseInt(yearFromLocalStorage, 10);
        if (yearInNumber) {
            return yearInNumber;
        }
    }
    return dayjs(Date.now()).subtract(1, "year").year();
};

export const setYearPersistant = (newYear?: number | "total") => {
    if (typeof newYear === "number") {
        const localStorageKey = getOverviewYearLocalStorageKey();
        localStorage.setItem(localStorageKey, newYear.toString());
    }
};

export const useSelectedYear = () => {
    const { year } = useSearch({ from: "__root__" });

    /*  This function tries to find the best possible year and fallback to "lesser solutions"
      1. If the year is set in the URL, we use that
      2. If the year is set in the local storage, we use that
      3. If we don't have data in local storage, we use the previous year
    */
    const selectedYear = useMemo(() => {
        return year ?? getYear();
    }, [year]);

    return selectedYear;
};

export const useSelectedElectricityBasis = () => {
    const { electricity } = useSearch({ from: "__root__" });
    return electricity ?? "marketBased";
};

export const useGetSearchParamFilters = (): OverviewFilters => {
    const {
        sources,
        electricity = "marketBased",
        spendCategoryIds = null,
        businessUnitIds = null,
        supplierIds = null,
        ...searchParams
    } = useSearch({ from: "__root__" });
    const sourceFilter: SourceFilter | undefined = sources?.map((source) => {
        if (source === SourceFilterType.ACTIVITY_DATA) {
            return { filterType: SourceFilterType.ACTIVITY_DATA };
        }
        return { filterType: SourceFilterType.SOURCE, id: source };
    });
    const year = useSelectedYear();
    return {
        tagFilters: searchParams.tagFilters ?? [],
        sources: sourceFilter ?? null,
        electricity,
        spendCategoryIds,
        businessUnitIds,
        supplierIds,
        year,
        dateFilter: null,
    };
};

/** @deprecated Replaced by useGetSearchParamFilters */
export const useDeprecatedGetSearchParamFilters = (): DeprecatedEmissionsFilter => {
    const filter = useGetSearchParamFilters();
    return {
        electricity: filter.electricity,
        groups: {
            businessUnitIds: filter.businessUnitIds ?? undefined,
            spendCategoryIds: filter.spendCategoryIds ?? undefined,
        },
        source: filter.sources ?? undefined,
        suppliers: filter.supplierIds ?? undefined,
        tag: { tags: filter.tagFilters ?? [] },
    };
};

type ScopeOrCategory =
    | {
          type: "scope";
          scope: "1" | "2" | "3";
      }
    | {
          type: "category";
          category: ScopeCategoryType;
      }
    | {
          type: "total";
      };

type SourceType = "activityData" | "supplierSource" | "tableConnection";

export const LOADING = Symbol("loading state for emission totals");
export const ERROR = Symbol("error state for emission totals");

type Loading = typeof LOADING;
type Error = typeof ERROR;

export const useTotalsPerYear = (
    scopeOrCategory: ScopeOrCategory,
    sourceType?: SourceType
): Partial<Record<number, number>> | Loading | Error => {
    const { electricity, ...filters } = useGetSearchParamFilters();
    const allData = trpc.overview.useQuery({ filters: { ...filters, dateFilter: null }, electricity });
    const dataPerSource = trpc.overviewPerSource.useQuery({ filters, electricity });
    if (allData.isLoading || dataPerSource.isLoading) {
        return LOADING;
    }
    if (allData.error || dataPerSource.error) {
        return ERROR;
    }
    const data = sourceType
        ? dataPerSource.data.find((o) => o.type === sourceType)?.emissions
        : allData.data.emissionData;
    if (!data) return {};
    if (scopeOrCategory.type === "total") {
        return data?.total?.perYear ?? {};
    }
    if (scopeOrCategory.type === "scope") {
        const scope = `scope${scopeOrCategory.scope}`;
        return data[scope].categoryTotal?.perYear ?? {};
    }
    const scope = getScopeByCategory(scopeOrCategory.category);
    return data[scope]?.[scopeOrCategory.category] ?? {};
};

export const useTotal = (scopeOrCategory: ScopeOrCategory, year: number, sourceType?: SourceType) => {
    const resultPerYear = useTotalsPerYear(scopeOrCategory, sourceType);
    if (resultPerYear === LOADING || resultPerYear === ERROR) return resultPerYear;
    return resultPerYear[year] ?? 0;
};

export const useFormattedTotal = (scopeOrCategory: ScopeOrCategory, year: number, sourceType?: SourceType) => {
    const result = useTotal(scopeOrCategory, year, sourceType);
    if (result === LOADING || result === ERROR) return result;
    return formatNumber(result, 2);
};

function getEmissionIntensityIdKey() {
    return `${localStorage.getItem("tenant")}:CARBON:EMISSION_INTENSITY_ID`;
}

function getIntensitySelection(): string | null {
    const localStorageKey = getEmissionIntensityIdKey();
    const intensityId = localStorage.getItem(localStorageKey);
    return intensityId;
}

/**
 * Returns the currently selected emission intensity id with the following precedence:
 * 1. Search parameter
 * 2. Local storage
 * 3. Default value
 *
 * When updating the emission intensity id, the value is persisted to local storage and the search
 * parameter is updated.
 */
function useSelectedEmissionIntensityId(
    defaultValue?: string
): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] {
    const { emissionIntensity } = useSearch({ from: "/_pageLayout/overview" });
    const navigate = useNavigate();
    const emissionIntensityId = emissionIntensity ?? getIntensitySelection() ?? defaultValue;

    function setSelectedEmissionIntensityId(
        value: ((previousValue: string | undefined) => string | undefined) | string | undefined
    ) {
        let newValue: string | undefined;
        if (typeof value === "function") {
            newValue = value(emissionIntensityId);
        } else {
            newValue = value;
        }

        if (!newValue) {
            const localStorageKey = getEmissionIntensityIdKey();
            localStorage.removeItem(localStorageKey);
        } else {
            const localStorageKey = getEmissionIntensityIdKey();
            localStorage.setItem(localStorageKey, newValue);
        }
        navigate({ to: ".", search: (current) => ({ ...current, emissionIntensity: newValue }), replace: true });
    }

    return [emissionIntensityId, setSelectedEmissionIntensityId];
}

export { useSelectedEmissionIntensityId };
