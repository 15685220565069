import { parseHref } from "@tanstack/history";
import { createBrowserHistory, HistoryLocation, RouterHistory } from "@tanstack/react-router";
import { useMemo } from "react";
import type { History } from "history";

/**
 * This hook creates a BrowserHistory object that is synced with the react-router history object.
 * The motivation behind this is that most micro apps use react-router for navigating, which means that if a micro app
 * using react-router navigates to a Carbon app, the Carbon app will not be able to rely on the same history object to
 * navigate, as `@tanstack/router` uses its own history object.
 *
 * Thus, to ensure that navigation works as expected, we create a BrowserHistory object where the current location is
 * parsed from the react-router history object, rather than the default window object. The result is that the react-router
 * history object serves as a source of truth, if it exists.
 *
 * @returns BrowserHistory object that is synced with the react-router history object.
 */
const useSyncedTanstackBrowserHistory = ({
    reactRouterHistory,
}: {
    reactRouterHistory?: History;
}): { tanstackHistory?: RouterHistory } => {
    let parseLocation: (() => HistoryLocation) | undefined;
    /**
     * If we have a react-router history object, we can use it to parse the location, rather than using the default
     * function that relies on the window object. That way, we can ensure that the location we parse uses the
     * react-router history object as a source of truth.
     *
     * This enables navigation from react-router micro apps to Carbon to work as expected.
     *
     * Inspired by the source code of `@tanstack/router` where they use the window object to parse the location.
     * https://github.com/TanStack/router/blob/cf4f924e2c031efa2fad039f80b0bdc675b1e0ae/packages/history/src/index.ts#L208-L215
     */
    if (reactRouterHistory) {
        parseLocation = () => {
            return parseHref(
                `${reactRouterHistory.location.pathname}${reactRouterHistory.location.search}${reactRouterHistory.location.hash}`,
                /**
                 * This state is a best bet for what it should look like. It's not guaranteed to be the same as the
                 * state in react-router, but it's the best we can do.
                 */
                {
                    key: reactRouterHistory.location.key,
                    ...(typeof reactRouterHistory.location.state === "object" ? reactRouterHistory.location.state : {}),
                }
            );
        };
    }

    const tanstackHistory = useMemo(() => createBrowserHistory({ parseLocation }), [parseLocation]);
    return { tanstackHistory };
};

export { useSyncedTanstackBrowserHistory };
