import { DrillDownContextProvider } from "@/Providers/DrilldownContext";
import { CARBON_EVENTS } from "@/lib/tracking";
import { trpc } from "@/lib/trpc/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelectedEmissionIntensityId } from "../hooks";
import ActivitiesShareOfEmissionsCard from "./ActivitiesShareOfEmissionsCard";
import { GetStartedWithEmissionIntensitiesCard } from "./EmissionIntensityCard/GetStartedWithEmissionIntensitiesCard";
import { LoadingEmissionIntensityCard } from "./EmissionIntensityCard/LoadingEmissionIntensityCard";
import { NonSpendEmissionIntensityCard } from "./EmissionIntensityCard/NonSpendEmissionIntensityCard";
import { SpendEmissionIntensityCard } from "./EmissionIntensityCard/SpendEmissionIntensityCard";
import EmissionOverYearsCard from "./EmissionOverYearsCard";
import EmissionsBreakdownCard from "./EmissionsBreakdownCard";
import EmissionsPerScopeCard from "./EmissionsPerScopeCard";
import LatestActivitiesCard from "./LatestActivitiesCard";
import { TopContributorsWidget } from "./TopContributorsWidget";
import TotalEmissionsCard from "./TotalEmissionsCard";
import { ErrorEmissionIntensityCard } from "./EmissionIntensityCard/ErrorEmissionIntensityCard";

/**
 * Shows the currently selected emission intensity with sensible defaults.
 * If an ID is currently selected, either in the search parameters or in local storage, then
 * show that specific emission intensity.
 *
 * If the selected emission intensity is "spend", show spend
 *
 * If the selected emission intensity is "revenue", show
 *  - Card encouraging the user to get started if a revenue emission intensity does not exist
 *  - Emission intensity for revenue, if it exists
 *
 *  If the selected emission intensity is "employees", show
 *  - Card encouraging the user to get started if an employees emission intensity does not exist
 *  - Emission intensity for employees, if it exists
 *
 * If the user has made no prior selections, show
 * 1. Revenue if it exists
 * 2. Employees if it exists
 * 3. First of others, if any
 * 4. Get started with revenue
 */
const EmissionIntensityCard: React.FC = () => {
    const { data, isLoading, isError } = trpc.findManyEmissionIntensities.useQuery();
    const revenueId = data?.revenue?.id;
    const employeesId = data?.employees?.id;
    const [firstOtherEmissionIntensity] = data?.other ?? [];
    const [emissionIntensityId] = useSelectedEmissionIntensityId(
        revenueId ?? employeesId ?? firstOtherEmissionIntensity?.id
    );

    if (isError) {
        return <ErrorEmissionIntensityCard />;
    }

    if (isLoading) {
        return <LoadingEmissionIntensityCard />;
    }

    if (emissionIntensityId === "spend") {
        return <SpendEmissionIntensityCard />;
    }

    /**
     * If the user has selected revenue, and there isn't an emission intensity for revenue, encourage them
     * to get started.
     */
    if (emissionIntensityId === "revenue" && !revenueId) {
        return <GetStartedWithEmissionIntensitiesCard type="revenue" />;
    }

    /**
     * If they've selected revenue, and there is an emission intensity for revenue, show that.
     */
    if (emissionIntensityId === "revenue" && revenueId) {
        return <NonSpendEmissionIntensityCard emissionIntensityId={revenueId} />;
    }

    /**
     * Same logic, but for employees
     */
    if (emissionIntensityId === "employees" && !employeesId) {
        return <GetStartedWithEmissionIntensitiesCard type="employees" />;
    }

    if (emissionIntensityId === "employees" && employeesId) {
        return <NonSpendEmissionIntensityCard emissionIntensityId={employeesId} />;
    }

    if (emissionIntensityId) {
        return <NonSpendEmissionIntensityCard emissionIntensityId={emissionIntensityId} />;
    }

    /**
     * In this case, there are no emission intensities to show, so we default to encouraging them to get started with revenue.
     */
    return <GetStartedWithEmissionIntensitiesCard type="revenue" />;
};

export const LandingPageContent: React.FC = () => {
    useEffect(() => {
        CARBON_EVENTS.LANDING_PAGE_LOADED({ page: "Overview" }).track();
    }, []);

    const hasEmissionIntensities = useFeatureToggle("carbon-emission-intensities");

    return (
        <Grid
            container
            spacing={2}
            style={{
                // Could not find a better solution for aligning the grid
                marginLeft: "-1em",
            }}
        >
            {hasEmissionIntensities && (
                <>
                    <Grid item md={4} flexGrow={1}>
                        <TotalEmissionsCard />
                    </Grid>
                    <Grid item md={4} flexGrow={1}>
                        <ActivitiesShareOfEmissionsCard />
                    </Grid>
                    <Grid item md={4} flexGrow={1}>
                        <EmissionIntensityCard />
                    </Grid>
                </>
            )}
            <Grid item md={4.3} flexGrow={1}>
                {hasEmissionIntensities ? <EmissionsBreakdownCard /> : <EmissionsPerScopeCard />}
            </Grid>
            <Grid item md={7.7} flexGrow={1}>
                <TopContributorsWidget />
            </Grid>
            <Grid item md={12} flexGrow={1}>
                <LatestActivitiesCard />
            </Grid>
            <Grid item md={12} flexGrow={1}>
                <DrillDownContextProvider chartType="stacked column">
                    <EmissionOverYearsCard />
                </DrillDownContextProvider>
            </Grid>
        </Grid>
    );
};
