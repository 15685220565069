import { useIntl } from "react-intl";

export const useGetTableAndColumnMessages = () => {
    const { formatMessage } = useIntl();
    const tableSelectMessage = formatMessage({
        defaultMessage: "Select table",
        description: "Table select label used for connections",
    });
    const dateColumnSelectMessage = formatMessage({
        defaultMessage: "Select date field",
        description: "Date field select label used for connections",
    });
    const spendColumnSelectMessage = formatMessage({
        defaultMessage: "Select spend field",
        description: "Spend field select label used for connections",
    });
    const emissionFactorColumnSelectMessage = formatMessage({
        defaultMessage: "Select emission factor field",
        description: "EF field select label used for connections",
    });
    const emissionStructureColumnSelectMessage = formatMessage({
        defaultMessage: "Select emission structure field",
        description: "EF structure field select label used for connections",
    });
    const supplierColumnSelectMessage = formatMessage({
        defaultMessage: "Select supplier field (optional)",
        description: "Supplier field select label used for connections",
    });
    const scope2TypePickerMessage = formatMessage({
        defaultMessage: "Select energy type",
        description: "Energy type picker label used for connections",
    });
    const energyConsumptionColumnSelectMessage = formatMessage({
        defaultMessage: "Select energy consumption field",
        description: "Energy consumption field select label used for connections",
    });
    const regionColumnSelectMessage = formatMessage({
        defaultMessage: "Select region field (alpha-2)",
        description: "Region field select label used for connections",
    });
    const unitOfMeasurePickerMessage = formatMessage({
        defaultMessage: "Are the calculated values in kg or tonnes?",
        description: "Unit of measure picker label used for connections",
    });
    const activityValueColumnMessages = formatMessage({
        defaultMessage: "Select activity value field",
        description: "Calculated emissions select label used for connections",
    });
    const calculatedEmissionsColumnSelectMessage = formatMessage({
        defaultMessage: "Select pre-calculated emissions field",
        description: "Calculated emissions select label used for connections",
    });
    return {
        tableSelectMessage,
        dateColumnSelectMessage,
        spendColumnSelectMessage,
        emissionFactorColumnSelectMessage,
        emissionStructureColumnSelectMessage,
        supplierColumnSelectMessage,
        scope2TypePickerMessage,
        energyConsumptionColumnSelectMessage,
        regionColumnSelectMessage,
        unitOfMeasurePickerMessage,
        calculatedEmissionsColumnSelectMessage,
        activityValueColumnMessages,
    };
};
