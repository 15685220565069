import { dataPreferencesRoute } from "@/Router/DataPreferencesRoute";
import { getTRPCProxyClient } from "@/lib/trpc/client";
import { createRoute, redirect } from "@tanstack/react-router";
import {
    emissionIntensitiesEditRootRoute,
    newEmissionIntensitiesRootRoute,
    newEmissionIntensitiesRootRouteChildren,
} from "./features";

const emissionIntensitiesRootRoute = createRoute({
    getParentRoute: () => dataPreferencesRoute,
    path: "/emission-intensities",
});

/**
 * To determine whether the user should be redirected to the flow to create a new emission intensity, or
 * shown the existing emission intensities we check if there are any existing emission intensities.
 * If there are, we redirect to the first intensity, otherwise we redirect to the new intensity page.
 */
const emissionIntensitiesRoute = createRoute({
    getParentRoute: () => emissionIntensitiesRootRoute,
    path: "/",
    /**
     * If there are no emission intensities, redirect to the new intensity page.
     */
    async beforeLoad() {
        const client = getTRPCProxyClient();
        const res = await client.findManyEmissionIntensities.query();
        if (res.revenue) {
            throw redirect({
                to: "/data-preferences/emission-intensities/$emissionIntensityId",
                params: { emissionIntensityId: res.revenue.id },
            });
        }
        if (res.employees) {
            throw redirect({
                to: "/data-preferences/emission-intensities/$emissionIntensityId",
                params: { emissionIntensityId: res.employees.id },
            });
        }
        const [firstEmissionIntensity] = res.emissionIntensities;
        if (firstEmissionIntensity) {
            throw redirect({
                to: "/data-preferences/emission-intensities/$emissionIntensityId",
                params: { emissionIntensityId: firstEmissionIntensity.id },
            });
        }
        throw redirect({ to: "/data-preferences/emission-intensities/new/select-intensity" });
    },
});

const emissionIntensitiesRootRouteChildren = [
    newEmissionIntensitiesRootRoute.addChildren(newEmissionIntensitiesRootRouteChildren),
    emissionIntensitiesEditRootRoute,
    emissionIntensitiesRoute,
];

export { emissionIntensitiesRootRoute, emissionIntensitiesRootRouteChildren };
