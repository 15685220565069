import { TrackSource } from "@/lib/tracking";
import { useGetMessages } from "../../hooks";
import { SourceConfig } from "../../types";
import { ListActionItem } from "../ListActionItem";
import { useGetSeeActivitiesClick } from "./hooks";

type Props = {
    config: SourceConfig;
    trackSource: TrackSource;
};

export const SeeAllActivitiesListAction: React.FC<Props> = ({ config, trackSource: traceSource }) => {
    const messages = useGetMessages(config);
    const onClick = useGetSeeActivitiesClick(config, traceSource);

    return onClick && messages?.seeAllActivities ? (
        <ListActionItem onClick={onClick} text={messages.seeAllActivities} />
    ) : null;
};
