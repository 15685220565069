import { ERROR, LOADING, useFormattedTotal } from "@/Containers/LandingPage/hooks";
import { useGetAllScopeMessages } from "@/lib/commonMessages/useGetAllScopeMessages";
import { FormattedMessage } from "react-intl";

import { ScopeCategory } from "router";
import { ActionPopoverHeader } from "../TotalEmissionForYearHeader";

type Props = {
    category: ScopeCategory;
    year: number;
};
export const CategoryAndYearTotalHeader: React.FC<Props> = ({ category, year }) => {
    const categoryMessage = useGetAllScopeMessages();
    const categoryString = categoryMessage[category].header;

    const total = useFormattedTotal({ type: "category", category }, year);
    const getTotalForMsg = () => {
        if (total === LOADING) {
            return "...";
        }
        if (total === ERROR) {
            return "?";
        }
        return total;
    };
    return (
        <ActionPopoverHeader
            message={
                <FormattedMessage
                    defaultMessage="{category} ({year}) - {total}  tCO₂e"
                    description="Category header for overview table"
                    values={{
                        category: categoryString,
                        year,
                        total: getTotalForMsg(),
                    }}
                />
            }
        />
    );
};
