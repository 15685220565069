import carbonDemo from "@/assets/videos/carbondemo.mp4";
import carbonDemoText from "@/assets/videos/carbondemo-en-US.vtt";
import { CloseDialogTitle } from "@/Components/CloseDialog";
import { Dialog, DialogContent, Stack } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { MediaPlayer, MediaProvider, Track } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";

export const WatchDemo = () => {
    const navigate = useNavigate();

    const onClose = () => {
        navigate({
            to: ".",
            search: (prev) => ({
                ...prev,
                viewType: undefined,
            }),
        });
    };

    return (
        <Dialog fullWidth maxWidth="lg" open onClose={onClose}>
            <CloseDialogTitle onClose={onClose} />
            <DialogContent>
                <Stack justifyContent="center" alignItems="center" gap={2} p={2}>
                    <MediaPlayer title="Spend overview demo" src={carbonDemo}>
                        <MediaProvider />
                        <DefaultVideoLayout icons={defaultLayoutIcons} />
                        <Track src={carbonDemoText} kind="subtitles" label="English" lang="en-US" default />
                    </MediaPlayer>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
