import { PageLayout } from "@/Components/Layout/PageLayout";
import { createRoute, Outlet } from "@tanstack/react-router";
import { ContainerProps } from "@mui/material";
import { rootRoute } from "..";

/**
 * Use sparingly. Ideally, use either `PageLayout` or `AppLayout` instead.
 * If you need a container layout without the restrictions of `PageLayout` or `AppLayout`, use this.
 * E.g. you have a layout with tabs that should fill the entire page, and beneath that layout there's an `AppLayout`. In that case,
 * pass `<AppLayout disableGutters />` to avoid duplicate padding.
 */
const ContainerLayoutComponent: React.FC<ContainerProps> = (props) => {
    return (
        <PageLayout maxWidth={false} {...props}>
            <Outlet />
        </PageLayout>
    );
};

const containerLayoutRoute = createRoute({
    id: "_containerLayout",
    getParentRoute: () => rootRoute,
    component: ContainerLayoutComponent,
});

export { containerLayoutRoute };
