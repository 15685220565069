import { range } from "@ignite-analytics/general-tools";
import { Search } from "@ignite-analytics/icons";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Button, Divider, InputAdornment, MenuList, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
    title: React.ReactNode;
    children: React.ReactNode;
    isLoading?: boolean;
    isError?: boolean;
    onSearch?: (query: string) => void;
    onReset?: () => void;
}

export const FilterValueSelect: React.FC<Props> = ({ title, children, onReset, isLoading, isError, onSearch }) => {
    const { formatMessage } = useIntl();
    return (
        <Stack gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">{title}</Typography>
                </Stack>
                {onReset && (
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={onReset}
                        startIcon={<SettingsBackupRestoreIcon />}
                    >
                        <FormattedMessage defaultMessage="Reset" description="Reset" />
                    </Button>
                )}
            </Stack>

            <Divider />

            {onSearch && (
                <TextField
                    size="small"
                    fullWidth
                    onChange={(e) => onSearch(e.target.value)}
                    placeholder={formatMessage({
                        defaultMessage: "Search",
                        description: "Placeholder for search input in filter values component",
                    })}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="inherit" />
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <MenuList sx={{ maxHeight: "50vh", overflow: "auto" }}>
                {isLoading && (
                    <Stack alignItems="center" justifyContent="center">
                        {range(5).map((i) => (
                            <Skeleton key={i} variant="text" width="100%" height={50} />
                        ))}
                    </Stack>
                )}
                {isError && (
                    <Typography color="error">
                        <FormattedMessage
                            defaultMessage="Error loading options"
                            description="Error loading options in filter values component"
                        />
                    </Typography>
                )}
                {children}
            </MenuList>
        </Stack>
    );
};
