import { Plus } from "@ignite-analytics/icons";
import { Button, Card, CardContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type Props = {
    numberOfTags: number;
    createTag: () => void;
};

export const CreateTagCard: React.FC<Props> = ({ numberOfTags, createTag }) => {
    if (numberOfTags >= 5) {
        return null;
    }
    return (
        <Card sx={{ height: 200 }} variant="outlined">
            <CardContent sx={{ height: "100%" }}>
                <Grid container justifyContent="center" alignItems="center" height="90%">
                    {numberOfTags === 0 ? (
                        <Stack alignItems="center">
                            <Typography variant="textLg">
                                <FormattedMessage
                                    defaultMessage="No tags created yet"
                                    description="Empty state title"
                                />
                            </Typography>
                            <Typography variant="textMd" color="grey">
                                <FormattedMessage
                                    defaultMessage="Enhance your emissions overview with diverse perspectives by adding tags!"
                                    description="Empty state subtitle"
                                />
                            </Typography>
                            <Grid paddingTop={1}>
                                <Button
                                    startIcon={<Plus />}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        createTag();
                                    }}
                                >
                                    <FormattedMessage
                                        defaultMessage="Create your first tag"
                                        description="Create Tag button"
                                    />
                                </Button>
                            </Grid>
                        </Stack>
                    ) : (
                        <IconButton
                            size="large"
                            onClick={() => {
                                createTag();
                            }}
                        >
                            <Plus fontSize="large" />
                        </IconButton>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
