import { DrillDownContextProvider } from "@/Providers/DrilldownContext";
import { CARBON_EVENTS } from "@/lib/tracking";
import { Card, CardContent, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import EmissionsByClassificationStructureChart from "./EmissionsByClassificationStructureChart";
import EmissionsByScopeChart from "./EmissionsByScopeChart";
import { PossibleBreakdown, useBreakdownOptions, useSelectedPieChartBreakdown } from "./hooks";

const EmissionsBreakdownCard: React.FC = () => {
    const [breakdown, setBreakdown] = useSelectedPieChartBreakdown();

    const handleChange = (bd: PossibleBreakdown) => {
        setBreakdown(bd);
        CARBON_EVENTS.PIECHART_BREAKDOWN_CHANGED({ page: "Overview" }, { breakdown: bd }).track();
    };

    const breakdownOptions = useBreakdownOptions();
    return (
        <Card
            sx={{
                minHeight: "100%",
            }}
        >
            <CardContent height="100%" component={Stack} spacing={2.5}>
                <Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" height={60}>
                        <Typography variant="textLg" component="h2" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Breakdown"
                                description="Title for the emissions breakdown card"
                            />
                        </Typography>
                        <Select
                            value={breakdown}
                            onChange={(e) => handleChange(e.target.value as PossibleBreakdown)}
                            size="medium"
                            autoWidth
                        >
                            {breakdownOptions.map((option) => (
                                <MenuItem value={option.value} key={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <Divider />
                </Stack>
                <Stack direction="column" position="relative">
                    {breakdown === "scope" ? (
                        <DrillDownContextProvider chartType="donut">
                            <EmissionsByScopeChart />
                        </DrillDownContextProvider>
                    ) : (
                        <EmissionsByClassificationStructureChart structure={breakdown} />
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default EmissionsBreakdownCard;
