import { ContributorType as ContributorTypeGraphql } from "@/__generated__/graphql";
import { trpc } from "@/lib/trpc/client";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ContributorType, ContributorTypeOption } from ".";

export const mapContributorType = (contributorType: ContributorType): ContributorTypeGraphql => {
    switch (contributorType) {
        case "companyStructure":
            return "COMPANY_STRUCTURE";
        case "spendCategories":
            return "SPEND_CATEGORY";
        case "suppliers":
            return "SUPPLIERS";
    }
};

export const useContributorTypeOptions = () => {
    const { formatMessage } = useIntl();
    const { data: contributorTypes } = trpc.getContributorTypes.useQuery();

    const options: ContributorTypeOption[] = useMemo(
        () =>
            contributorTypes?.map((option) => {
                if (option.contributorType === "suppliers") {
                    return {
                        ...option,
                        name: formatMessage({
                            defaultMessage: "Suppliers",
                            description: "Suppliers contributor type option label",
                        }),
                    };
                }
                return option;
            }) ?? [],
        [contributorTypes, formatMessage]
    );
    return options;
};
