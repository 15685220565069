import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { ActivityLog } from "@/Containers/ActivityLog";
import { appLayoutRoute } from "@/Router/AppLayoutRoute";
import { createRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { scopeCategoryTypes } from "router";

export const activitiyLogRoute = createRoute({
    getParentRoute: () => appLayoutRoute,
    path: "/activities/edit",
    component: ActivityLog,
    validateSearch: z.object({
        activityId: z.string().optional(),
        category: z.enum(scopeCategoryTypes).optional(),
        activityL1: z.string().optional(),
        activityL2: z.string().optional(),
        year: z.number().optional(),
        activityIds: z.array(z.string()).optional(),
        supplierId: z.string().optional(),
        searchString: z
            .string()
            .optional()
            .catch((ctx) => `${ctx.input}`),
        scope: z.enum(["1", "2", "3"]).optional(),
        source: z.string().optional(),
    }),
    errorComponent: ErrorBoundary,
});

export const temporaryRedirectLegacyActivityLogRoute = createRoute({
    getParentRoute: () => appLayoutRoute,
    path: "/data-input/edit",
    beforeLoad: (ctx) => {
        throw redirect({
            to: "/activities/edit",
            search: ctx.search,
        });
    },
    component: ErrorBoundary,
    errorComponent: ErrorBoundary,
});
