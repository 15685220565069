import { trpc } from "@/lib/trpc/client";
import { ScopeCategory } from "router";
import { ActionPopover } from "../Common";
import { CreateNewActivityListAction } from "../Common/Components/CreateNewActivityListAction";
import { GoToSupplierCardListAction } from "../Common/Components/GoToSupplierCardListAction";
import { SeeAllActivitiesListAction } from "../Common/Components/SeeAllActivitiesListAction";
import { SourcesBreakDownListAction } from "../Common/Components/SourcesBreakDownListAction";
import { CommonProps, SourceConfig } from "../Common/types";

type Props = CommonProps & {
    config: SourceConfig & { type: "supplier" };
};
export const SupplierActionPopover: React.FC<Props> = ({ config, trackSource, anchorEl, open, handleClose }) => {
    const { supplierId } = config;

    const supplierCategoriesResult = trpc.supplierCategories.useQuery();
    const category: ScopeCategory | undefined = supplierCategoriesResult.data?.[supplierId] ?? undefined;
    return (
        <ActionPopover
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            items={[
                <GoToSupplierCardListAction config={config} trackSource={trackSource} key={1} />,
                <SeeAllActivitiesListAction config={config} trackSource={trackSource} key={2} />,
                config.year ? <SourcesBreakDownListAction config={config} trackSource={trackSource} key={3} /> : null,
                category ? (
                    <CreateNewActivityListAction config={{ category, ...config }} trackSource={trackSource} key={4} />
                ) : null,
            ]}
        />
    );
};
