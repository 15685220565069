import { Chip, Stack, Typography } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

type GridToolbarTotalItemsContainerProps = {
    slotProps?: { chipIcon?: JSX.Element };
};

export const GridToolbarTotalItemsContainer: React.FC<GridToolbarTotalItemsContainerProps> = ({ slotProps }) => {
    const apiRef = useGridApiContext();
    const totalItems = apiRef.current?.getRowsCount();
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Typography noWrap>
                <FormattedMessage
                    defaultMessage="Total items"
                    description="Total items grid table toolbar component text"
                />
            </Typography>
            <Chip
                size="xsmall"
                label={<FormattedNumber value={totalItems} maximumFractionDigits={0} />}
                icon={slotProps?.chipIcon}
            />
        </Stack>
    );
};
