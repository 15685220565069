import { ActionPopover } from "@/Components/ActionPopover/Common";
import { DrillDownChartListAction } from "@/Components/ActionPopover/Common/Components/DrillDownChartListAction";
import { ScopeAndYearTotalHeader } from "@/Components/ActionPopover/Common/Components/ScopeAndYearTotalHeader";
import { SeeAllActivitiesListAction } from "@/Components/ActionPopover/Common/Components/SeeAllActivitiesListAction";
import { SourcesBreakDownListAction } from "@/Components/ActionPopover/Common/Components/SourcesBreakDownListAction";
import { CommonProps, SourceConfig } from "@/Components/ActionPopover/Common/types";
import { hasValue } from "@/lib/hasValue";

type Props = CommonProps & {
    config: SourceConfig & { type: "emissionsByScope" };
};
export const EmissionsByScopeActionPopover: React.FC<Props> = ({
    anchorEl,
    open,
    config,
    trackSource,
    handleClose,
}) => {
    return (
        <ActionPopover
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            items={[
                <ScopeAndYearTotalHeader year={config.year} scope={config.scope} key="header" />,
                config.drilldown ? <DrillDownChartListAction config={config} key="drill-down-chart" /> : null,
                <SeeAllActivitiesListAction config={config} trackSource={trackSource} key="see-all-activities" />,
                <SourcesBreakDownListAction config={config} trackSource={trackSource} key="sources-breakdown" />,
            ].filter(hasValue)}
        />
    );
};
