import { UpdatedBy } from "@/Components/UpdatedBy";
import { AddedConnection } from "@/Containers/DataSources/DataSourceForms/DataTableConnectionForms/interfaces";
import { activitiyLogRoute } from "@/Router/ActivitiesRoute/ActivitiesLogRoute";
import { formatNumber } from "@/lib/primitives/numbers";
import { Bolt, Cloud } from "@ignite-analytics/icons";
import { Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";

type DataSourceProps = {
    data: AddedConnection;
    totalEmissions?: number;
    loading: boolean;
    numberOfActivities: number;
};
export const DataSourceInfo: React.FC<DataSourceProps> = ({ data, totalEmissions, loading, numberOfActivities }) => {
    const titleStyle = { fontPalette: "light", opacity: "50%" };
    const valueStyle = { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxLines: 1 };
    const gridItemStyle = { marginTop: "10px" };

    if (loading)
        return (
            <>
                <Stack direction="row" flex={1}>
                    <Skeleton variant="text" width={100} height={30} />
                    <Stack direction="column" flex={1} />
                    <Skeleton variant="text" width={150} height={30} />
                </Stack>
                <Stack direction="row" flex={1}>
                    <Skeleton variant="text" width={100} height={30} />
                    <Stack direction="column" flex={1} />
                    <Skeleton variant="text" width={150} height={30} />
                </Stack>
                <Stack direction="row" flex={1}>
                    <Skeleton variant="text" width={80} height={30} />
                    <Stack direction="column" flex={1} />
                    <Skeleton variant="circular" width={30} height={30} />
                    <Stack direction="column" paddingRight={2} />

                    <Skeleton variant="text" width={100} height={30} />
                </Stack>
                <Stack direction="row" flex={1} paddingTop={2}>
                    <Skeleton variant="rectangular" width={150} height={40} />
                </Stack>
            </>
        );
    return (
        <>
            <Grid container direction="row" sx={{ marginTop: "5px" }}>
                <Grid item xs={5} sx={gridItemStyle} alignItems="center">
                    <Typography variant="textSm" alignContent="flex-start" sx={titleStyle}>
                        <FormattedMessage defaultMessage="Data source: " description="Data source name title" />
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{ ...gridItemStyle, overflow: "hidden" }}>
                    <Typography variant="textSm" sx={{ ...valueStyle, textWrap: "wrap" }}>
                        <FormattedMessage
                            defaultMessage="{name}"
                            description="Data source name"
                            values={{ name: data.name }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={5} sx={gridItemStyle} alignItems="center">
                    <Typography variant="textSm" sx={titleStyle}>
                        <FormattedMessage defaultMessage="Creation details : " description="Creation details title" />
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={gridItemStyle}>
                    <Stack direction="row" gap={1} alignItems="start">
                        <UpdatedBy user={data.createdBy} />
                        <Typography variant="textSm" sx={valueStyle}>
                            <FormattedMessage
                                defaultMessage="{name}"
                                description="Added on date"
                                values={{ name: data.createdAt.toDateString() }}
                            />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container gap={1} sx={{ marginTop: "15px" }}>
                <Grid item>
                    <Chip
                        icon={<Cloud fontSize="small" />}
                        label={
                            <FormattedMessage
                                defaultMessage="{totalEmissions}  t CO2e"
                                description="Total emissions text"
                                values={{ totalEmissions: formatNumber(totalEmissions ?? 0, 2) }}
                            />
                        }
                    />
                </Grid>
                {["spend", "spend-based"].includes(data.formType) && (
                    <Grid item>
                        <Chip
                            color="primary"
                            component={Link}
                            sx={{ cursor: "pointer" }}
                            to={activitiyLogRoute.fullPath}
                            icon={<Bolt fontSize="small" />}
                            label={
                                <FormattedMessage
                                    defaultMessage="{num} activities excluding spend-based emissions"
                                    description="Activities excluding spend-based emissions"
                                    values={{ num: numberOfActivities }}
                                />
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
