import { PageLayout } from "@/Components/Layout/PageLayout";
import { Outlet, useMatchRoute } from "@tanstack/react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { enterInformationRoute, selectLevelRoute } from "../../pages";
import { Step, StepLabel, Stepper } from "./components/Stepper";

const StepLayout: React.FC = () => {
    const matchRoute = useMatchRoute();

    let step: number | undefined;

    if (matchRoute({ to: "/data-preferences/emission-intensities/new/step/select-year" })) {
        step = 0;
    } else if (matchRoute({ to: selectLevelRoute.fullPath })) {
        step = 1;
    } else if (matchRoute({ to: enterInformationRoute.fullPath })) {
        step = 2;
    } else {
        step = -1;
    }

    return (
        <PageLayout disableGutters>
            <Stepper
                activeStep={step}
                sx={{ padding: (t) => t.spacing(8, 0), margin: (t) => t.spacing(1, 0, 3, 0), width: "100%" }}
            >
                <Step>
                    <StepLabel>
                        <FormattedMessage
                            defaultMessage="Select year"
                            description="Step label for the first step when adding emission intensities"
                        />
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <FormattedMessage
                            defaultMessage="Select level"
                            description="Step label for the second step when adding emission intensities"
                        />
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <FormattedMessage
                            defaultMessage="Enter information"
                            description="Step label for the third step when adding emission intensities"
                        />
                    </StepLabel>
                </Step>
            </Stepper>
            <Outlet />
        </PageLayout>
    );
};

export { StepLayout };
