import { useGetSelectedScope, useSetSelectedScope } from "@/Providers/DrilldownContext";
import { getColor } from "@/lib/charts";
import { scope1Types, scope2Types, scope3Types } from "router";

export const useGetBarElements = () => {
    const setSelectedScope = useSetSelectedScope();
    const selectedScope = useGetSelectedScope();
    const scopes = ["1", "2", "3"] as const;
    if (selectedScope === "all") {
        return scopes.map((scope, i) => ({
            id: scope,
            color: getColor(i, 3),
            onClick: () => {
                setSelectedScope(scope, "EmissionsByYearChart");
            },
        }));
    }
    if (selectedScope === "1") {
        return scope1Types.map((type, index) => ({
            id: type,
            color: getColor(index, scope1Types.length),
            goBack: () => {
                setSelectedScope("all", "EmissionsByYearChart");
            },
        }));
    }
    if (selectedScope === "2") {
        return scope2Types.map((type, index) => ({
            id: type,
            color: getColor(index, scope2Types.length),
            goBack: () => {
                setSelectedScope("all", "EmissionsByYearChart");
            },
        }));
    }
    return scope3Types.map((type, index) => ({
        id: type,
        color: getColor(index, scope3Types.length),
        goBack: () => {
            setSelectedScope("all", "EmissionsByYearChart");
        },
    }));
};
