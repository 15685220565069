import { ErrorBoundary } from "@/Components/ErrorBoundary/ErrorBoundary";
import { DataPreferencesRoute } from "@/Containers/DataPreferences";
import { DataSourcesPage } from "@/Containers/DataSources/DataSourcesPage";
import { AddDataSourceDetails } from "@/Containers/DataSources/DataSourcesPage/Components/AddDataSourceDetails";
import { AddDataSourceType } from "@/Containers/DataSources/DataSourcesPage/Components/AddDataSourceType";
import {
    AddDataSourceModal,
    EditDataSourceModal,
} from "@/Containers/DataSources/DataSourcesPage/Components/DataSourceModal";
import { EditCustomCategoriesRoute } from "@/Containers/EditCustomCategories";
import { EmissionTags } from "@/Containers/Tags";
import { isEditorRoute } from "@/lib/permissions/permissions";
import { createRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";
import { rootRoute } from "..";

export const dataPreferencesRoute = createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: isEditorRoute,
    path: "/data-preferences",
    component: DataPreferencesRoute,
    errorComponent: ErrorBoundary,
});

export const dataPreferenceDataSourcesRoute = createRoute({
    getParentRoute: () => dataPreferencesRoute,
    path: "/data-sources",
    component: DataSourcesPage,
    validateSearch: z.object({}),
    errorComponent: ErrorBoundary,
});

export const dataPreferenceDataSourcesOverview = createRoute({
    getParentRoute: () => dataPreferenceDataSourcesRoute,
    path: "/overview",
    component: Outlet,
    validateSearch: z.object({}),
    errorComponent: ErrorBoundary,
});

export const editDataSourceRoute = createRoute({
    getParentRoute: () => dataPreferenceDataSourcesRoute,
    path: "/edit/$dataSourceType/$dataSourceId",
    validateSearch: z.object({
        tableId: z.string().optional().catch(undefined),
    }),
    component: EditDataSourceModal,
    errorComponent: ErrorBoundary,
});

export const addDataSourceRoute = createRoute({
    getParentRoute: () => dataPreferenceDataSourcesRoute,
    path: "/add-source",
    component: AddDataSourceModal,
    errorComponent: ErrorBoundary,
});

export const addDataSourceStart = createRoute({
    getParentRoute: () => addDataSourceRoute,
    path: "/add-source",
    component: Outlet,
    errorComponent: ErrorBoundary,
});

export const addDataSourceTypeRoute = createRoute({
    getParentRoute: () => addDataSourceRoute,
    path: "/type",
    component: AddDataSourceType,
    validateSearch: z.object({
        tableId: z.string().optional().catch(undefined), // Due to requirement in the forms we add.
        hideSpendAndSupplier: z.boolean().optional(),
    }),
    errorComponent: ErrorBoundary,
});

export const addDataSourceDetailsRoute = createRoute({
    getParentRoute: () => addDataSourceRoute,
    path: "/$dataSourceType",
    component: AddDataSourceDetails,
    parseParams: ({ dataSourceType }) => {
        const parsedDataSourceType = z
            .enum(["other", "spend", "supplier", "energy", "airTravel"])
            .safeParse(dataSourceType);
        if (!parsedDataSourceType.success) throw new Error("Invalid data source type");
        return {
            dataSourceType: parsedDataSourceType.data,
        };
    },
    validateSearch: z.object({
        tableId: z.string().optional().catch(undefined),
        connectionId: z.string().optional().catch(undefined),
    }),
    errorComponent: ErrorBoundary,
});

export const dataPreferenceTagsRoute = createRoute({
    getParentRoute: () => dataPreferencesRoute,
    path: "/tags",
    component: EmissionTags,
    validateSearch: z.object({}),
    errorComponent: ErrorBoundary,
});

export const dataPreferenceCustomCategoriesRoute = createRoute({
    getParentRoute: () => dataPreferencesRoute,
    path: "/custom-categories",
    component: EditCustomCategoriesRoute,
    validateSearch: z.object({
        searchString: z.string().optional(),
        categoryId: z.string().optional(),
        newCategory: z.boolean().optional(),
    }),
    errorComponent: ErrorBoundary,
});
