import { ArrowTrendDown, ArrowTrendUp } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useGetEmissionsCellInformation } from "./hooks";

type Props = {
    year: number;
    perYear: Partial<Record<string, number>>;
    shrink?: boolean;
};

const TrendArrow = ({ changePercent }: { changePercent: number }) => {
    if (changePercent === 0) {
        return null;
    }
    if (changePercent > 0) {
        return <ArrowTrendUp />;
    }
    return <ArrowTrendDown />;
};

export const EmissionChange: React.FC<Props> = ({ perYear, year, shrink: allowShrinking }) => {
    const { message, changePercent } = useGetEmissionsCellInformation(year, perYear);
    let color: "success" | "error" | "neutral" = "neutral";
    if (changePercent > 0) {
        color = "error";
    } else if (changePercent < 0) {
        color = "success";
    }
    return (
        <Tooltip
            title={
                <FormattedMessage defaultMessage="Change since previous year" description="Tooltip for trend chip" />
            }
            placement="top"
        >
            <Chip
                color={color}
                label={
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="100%">
                        <Typography textAlign="right" minWidth={allowShrinking ? undefined : "5rem"}>
                            {message}
                        </Typography>
                        <TrendArrow changePercent={changePercent} />
                    </Stack>
                }
            />
        </Tooltip>
    );
};
