import Grid from "@mui/material/Grid";
import React from "react";
import { Skeleton } from "@mui/material";
import { DropZone } from "./DropZone";
import { FileUploadProps } from "./file-upload-props";
import FilesList from "./files_list";
import { uploadFile } from "./helpers";
import { UploadInput, UploadWrapper } from "./style";

export const FileUpload: React.FC<FileUploadProps> = ({
    acceptedTypes,
    disabled = false,
    "data-testid": dataTestId,
    files,
    onFilesSelect,
    onFileRemove,
    pendingUpdates,
}) => {
    return (
        <Grid data-testid={dataTestId} flexDirection="column">
            {!disabled && (
                <UploadWrapper sx={{ display: "flex" }}>
                    <DropZone disabled={disabled}>
                        <UploadInput
                            type="file"
                            multiple
                            accept={acceptedTypes?.join(",")}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                uploadFile(e.target.files, acceptedTypes, onFilesSelect)
                            }
                            disabled={disabled}
                            data-testid={`${dataTestId}-input`}
                        />
                    </DropZone>
                </UploadWrapper>
            )}
            <Grid paddingTop={1}>
                {files && files.length > 0 && (
                    <Grid data-testid={`${dataTestId}-files`}>
                        <FilesList files={files} disabled={disabled} onFileRemove={onFileRemove} />
                    </Grid>
                )}
                {pendingUpdates ? (
                    <Grid paddingY={1}>
                        <Skeleton animation="wave" variant="rounded" height={12} />
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};
