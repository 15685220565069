import { countryCodeLabelRecord } from "@/Containers/ManualEmissions/interfaces";
import { countryCodeOptions } from "router";
import { CountryType } from "../../types";

export const getSortedCountries = (): CountryType[] => {
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedCountries = countryCodeOptions
        .map((countryCodeOption) => {
            return countryCodeLabelRecord[countryCodeOption];
        })
        .sort((a, b) => {
            if (a.suggested !== b.suggested) {
                return a.suggested ? -1 : 1;
            }
            return a.label.localeCompare(b.label);
        });

    return sortedCountries;
};
