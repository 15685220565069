import { CheckCircleSolid } from "@ignite-analytics/icons";
import {
    Box,
    StepLabel as MuiStepLabel,
    Stepper as MuiStepper,
    StepIconProps,
    StepLabelProps,
    StepperProps,
    stepConnectorClasses,
    stepLabelClasses,
} from "@mui/material";
import { CircleDot } from "./CircleDot";

const Stepper: React.FC<StepperProps> = (props) => {
    return (
        <Box
            sx={(t) => ({
                [`& .${stepConnectorClasses.root}`]: {
                    left: "calc(-50% - 50px)",
                    right: "calc(50% - 50px)",
                    zIndex: 1,
                    [`& .${stepConnectorClasses.line}`]: {
                        borderColor: t.palette.divider,
                        borderTopWidth: "2px",
                    },
                },
                [`& .${stepLabelClasses.label}`]: {
                    typography: t.typography.textXs,
                    fontWeight: 500,
                },
                [`& .${stepLabelClasses.iconContainer}`]: {
                    zIndex: 2,
                },
                [`& .${stepLabelClasses.active}.${stepLabelClasses.label}`]: {
                    color: t.palette.text.textTertiary,
                },
            })}
        >
            <MuiStepper alternativeLabel {...props} />
        </Box>
    );
};

const StepIcon: React.FC<StepIconProps> = ({ completed, active, className, sx }) => {
    if (completed) {
        return <CheckCircleSolid className={className} sx={{ ...sx }} />;
    }

    if (active) {
        return <CircleDot className={className} sx={{ ...sx }} />;
    }

    return <CircleDot sx={{ color: (t) => t.palette.grey[300], ...sx }} />;
};

const StepLabel: React.FC<StepLabelProps> = (props) => {
    return <MuiStepLabel StepIconProps={{ sx: { fontSize: "24px" } }} StepIconComponent={StepIcon} {...props} />;
};

export { Step } from "@mui/material";
export { StepLabel, Stepper };
