import * as React from "react";
import { Calendar } from "@ignite-analytics/icons";
import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import { IconButton } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { DefaultFormType } from "../../types";

export const DateRangePickerComponent = () => {
    const { formatMessage } = useIntl();
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();
    const to = watch("to");
    const from = watch("from");
    const fromLabel = formatMessage({
        defaultMessage: "From",
    });
    const toLabel = formatMessage({
        defaultMessage: "To",
    });

    const inputRef = React.useRef<HTMLInputElement>(null);
    const fromError = errors.from ? errors.from.message : undefined;
    const toError = errors.to ? errors.to.message : undefined;

    const years: number[] = [2023, 2022, 2021];
    const yearShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = years.map((year) => {
        return {
            label: `${year}`,
            getValue: () => {
                return [dayjs(`${year}-01-01Z`), dayjs(`${year}-12-31Z`)];
            },
        };
    });
    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
        {
            label: "Q3 2024",
            getValue: () => [dayjs("2024-07-01Z"), dayjs("2024-09-30Z")],
        },
        {
            label: "Q2 2024",
            getValue: () => [dayjs("2024-04-01Z"), dayjs("2024-06-30Z")],
        },
        {
            label: "Q1 2024",
            getValue: () => [dayjs("2024-01-01Z"), dayjs("2024-03-31Z")],
        },
        ...yearShortcuts,
    ];
    return (
        <DateRangePicker
            sx={{ width: "100%" }}
            slotProps={{
                shortcuts: {
                    items: shortcutsItems,
                },
                actionBar: { actions: [] },
                fieldSeparator: { children: "" },
                fieldRoot: { spacing: 1 },
                leftArrowIcon: Calendar,
                textField: ({ position }) => ({
                    padding: 0,
                    inputRef,
                    width: "100%",
                    fullWidth: true,
                    size: "small",

                    label: position === "start" ? fromLabel : toLabel,
                    helperText: position === "start" ? fromError : toError,
                    error: position === "start" ? !!fromError : !!toError,
                    InputProps: {
                        "data-testid": position === "start" ? "from-date" : "to-date",
                        endAdornment: (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    inputRef?.current?.focus();
                                }}
                            >
                                <Calendar fontSize="inherit" />
                            </IconButton>
                        ),
                    },
                }),
            }}
            value={[from ? dayjs.utc(from) : null, to ? dayjs.utc(to) : null]}
            onChange={([fromValue, toValue]) => {
                // We offset the date from utc to local so that it is correct on the server
                fromValue && setValue("from", fromValue?.utc(true).local().toDate());
                toValue && setValue("to", toValue.utc(true).local().toDate());
            }}
        />
    );
};
