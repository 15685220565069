import React from "react";
import { Pen } from "@ignite-analytics/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Stack, Typography, TextField, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DefaultFormType } from "../../types";

export const Description: React.FC = () => {
    const { formatMessage } = useIntl();
    const {
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<DefaultFormType>();

    const description = watch("initialDescription");
    const errorMessage = errors.initialDescription?.message;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === "") {
            setValue("initialDescription", undefined);
            return;
        }
        setValue("initialDescription", value);
    };
    return (
        <Stack direction="row" spacing={2}>
            <Stack>
                <Pen fontSize="small" />
            </Stack>
            <Stack gap={1} width="100%">
                <Typography variant="textSm" fontWeight={500}>
                    <FormattedMessage defaultMessage="Add a description" description="Add a description" />
                </Typography>
                <Grid container>
                    <TextField
                        placeholder={formatMessage({
                            defaultMessage: "Write a description",
                            description: "Write a description",
                        })}
                        inputProps={{
                            "data-testid": "description-input",
                        }}
                        onChange={handleChange}
                        multiline
                        maxRows={4}
                        minRows={3}
                        value={description ?? ""}
                        error={!!errorMessage}
                        helperText={errorMessage ?? ""}
                        fullWidth
                    />
                </Grid>
            </Stack>
        </Stack>
    );
};
