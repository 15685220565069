import { trpc } from "@/lib/trpc/client";
import { FirstSupplierActivity } from "./firstTimeConnected";
import { MultipleSuppliersActivities } from "./alreadyConnectedToActivities";

type Props = {
    supplierId: string;
    year: number;
};

export const MainContent: React.FC<Props> = ({ supplierId, year }) => {
    const { data } = trpc.emissionsToBeRemovedFromSupplier.useQuery({ supplierId, year });

    if (data?.type === "firstActivitySupplier") {
        return <FirstSupplierActivity improvedEstimates={data} />;
    }
    if (data?.type === "improvedActivitySupplier") {
        return <MultipleSuppliersActivities improvedEstimates={data} />;
    }
    return null;
};
