import { getMessageForUnit } from "@/Containers/ManualEmissions/helpers";
import { useIntl } from "react-intl";
import { UnitOfMeasurement } from "router";

export const useSpecificUnitMessages = (units: UnitOfMeasurement[]) => {
    const { formatMessage } = useIntl();
    return units.map((unit) => ({
        message: formatMessage(getMessageForUnit(unit)),
        value: unit,
    }));
};

export type UnitMessageAndValue = { message: string; value: UnitOfMeasurement };
