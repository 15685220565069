import { UnitOfMeasurement, kgCO2e, tCO2e } from "router";
import { OptionForUnitOfMeasure } from "../../types";

export const getUnitOfMeasurementsFromOption = (option: OptionForUnitOfMeasure): UnitOfMeasurement[] => {
    if (option.type === "unitOfMeasurementAvailable") {
        return [...(option.option.unitsOfMeasurement ?? []), kgCO2e, tCO2e];
    }
    if (option.type === "noActivityL1Available" && option.option) {
        return [...(option.option.unitsOfMeasurement ?? []), kgCO2e, tCO2e];
    }
    return [kgCO2e, tCO2e];
};
