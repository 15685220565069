export const MissingSvg: React.FC = () => (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y="12" width="64" height="64" rx="32" fill="#EFF8FE" />
        <rect x="1.5" y="1.5" width="85" height="85" rx="42.5" stroke="#38BCF7" strokeOpacity="0.16" strokeWidth="3" />
        <rect x="7.5" y="7.5" width="73" height="73" rx="35.5" stroke="#38BCF7" strokeOpacity="0.32" strokeWidth="3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31 33C31 31.8954 31.8954 31 33 31H40.3333C41.4379 31 42.3333 31.8954 42.3333 33V40.3333C42.3333 41.4379 41.4379 42.3333 40.3333 42.3333H33C31.8954 42.3333 31 41.4379 31 40.3333V33ZM40.3333 33H33V40.3333L40.3333 40.3333V33ZM45.6667 33C45.6667 31.8954 46.5621 31 47.6667 31H55C56.1046 31 57 31.8954 57 33V40.3333C57 41.4379 56.1046 42.3333 55 42.3333H47.6667C46.5621 42.3333 45.6667 41.4379 45.6667 40.3333V33ZM55 33L47.6667 33V40.3333L55 40.3333L55 33ZM31 47.6667C31 46.5621 31.8954 45.6667 33 45.6667H40.3333C41.4379 45.6667 42.3333 46.5621 42.3333 47.6667V55C42.3333 56.1046 41.4379 57 40.3333 57H33C31.8954 57 31 56.1046 31 55V47.6667ZM40.3333 47.6667L33 47.6667V55L40.3333 55L40.3333 47.6667ZM51.3333 45.6667C51.8856 45.6667 52.3333 46.1144 52.3333 46.6667V50.3333H56C56.5523 50.3333 57 50.781 57 51.3333C57 51.8856 56.5523 52.3333 56 52.3333H52.3333V56C52.3333 56.5523 51.8856 57 51.3333 57C50.781 57 50.3333 56.5523 50.3333 56V52.3333H46.6667C46.1144 52.3333 45.6667 51.8856 45.6667 51.3333C45.6667 50.781 46.1144 50.3333 46.6667 50.3333H50.3333V46.6667C50.3333 46.1144 50.781 45.6667 51.3333 45.6667Z"
            fill="#069CDF"
        />
    </svg>
);
