import { WarningCircle } from "@/lib/svg/warningCircle";
import { CARBON_EVENTS, Component } from "@/lib/tracking";
import { findCategory } from "@/Router/DataInputRoute/FormV2";
import { Typography, Stack, Button, Card, CardContent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "@tanstack/react-router";

export const NoActivitiesCard = () => {
    const navigate = useNavigate();
    const handleAddActivityClick = (component: Component) => {
        CARBON_EVENTS.ADD_ACTIVITY_DATA_CLICKED({
            page: "Overview",
            component,
        }).track();
        navigate({ to: findCategory.fullPath });
    };
    return (
        <Card>
            <CardContent>
                <Stack gap={3} alignItems="center" padding={3}>
                    <Stack gap={2} alignItems="center">
                        <WarningCircle />
                        <Typography variant="textMd">
                            <FormattedMessage
                                defaultMessage="We need you to add activity data to show you this"
                                description="No activities added"
                            />
                        </Typography>
                    </Stack>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={() => handleAddActivityClick("NoActivitiesButton")}
                    >
                        <FormattedMessage description="Add activity" defaultMessage="Add activity" />
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
};
