import { createSvgIcon } from "@mui/material";

export const CircleDot = createSvgIcon(
    <svg
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="24" height="24" rx="12" fill="currentColor" />
        <circle cx="12" cy="12" r="6" fill="white" />
    </svg>,
    "CircleSolid"
);
