import { Skeleton } from "@mui/material";

export const FormSkeleton = () => {
    return (
        <>
            <Skeleton animation="wave" variant="rectangular" height={50} />
            <Skeleton animation="wave" variant="rectangular" height={50} />
            <Skeleton animation="wave" variant="rectangular" height={50} />
            <Skeleton animation="wave" variant="rectangular" height={80} />
        </>
    );
};
