import { PopoverWithText } from "@/Components/PopoverWithText";
import { CategoryColumn } from "@/Containers/ActivityLog/Table/Components/CategoryColumn";
import { RegionColumn } from "@/Containers/ActivityLog/Table/Components/RegionColumn";
import { useGetCategoryOptionsAndMessages } from "@/Containers/ActivityLog/Table/RenderFunctions/helpers";
import { usePermission } from "@/lib/permissions/usePermissions";
import { track } from "@/lib/track";
import { addActivityFormRoute } from "@/Router/ActivitiesRoute/AddActivityRoute";
import { InformationCircle } from "@ignite-analytics/icons";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { Link, Stack, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useNavigate } from "@tanstack/react-router";
import { useIntl } from "react-intl";
import { EmissionFactorCell } from "./EmissionFactorCell";
import { RowData } from "./types";

const DEFAULT_COLUMN_WIDTH = 150;

type PossibleColumnActivityBased = keyof RowData | "linkToForm";
type ColumnActivityBased = GridColDef & { field: PossibleColumnActivityBased };

const baseColumnProps: Partial<ColumnActivityBased> = {
    hideSortIcons: true,
    align: "left",
    headerAlign: "left",
    width: DEFAULT_COLUMN_WIDTH,
    renderHeader: (params: GridColumnHeaderParams) => {
        return (
            <Stack direction="row" alignItems="center">
                <Typography variant="inherit">{params.colDef.headerName}</Typography>
            </Stack>
        );
    },
};

export const useGetColumns = (): ColumnActivityBased[] => {
    const { options: categoryOptions } = useGetCategoryOptionsAndMessages();
    const isEditor = usePermission({ permission: "write" });
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const activityL1Message = formatMessage({
        defaultMessage: "Activity L1",
        description: "Header for activity L1 column on uploaded table",
    });
    const activityL2Message = formatMessage({
        defaultMessage: "Activity L2",
        description: "Header for activity L2 column on uploaded table",
    });

    const navigate = useNavigate();

    const addAsActivityColumn: ColumnActivityBased[] = isEditor
        ? [
              {
                  field: "linkToForm",
                  type: "actions",
                  width: 120,
                  headerName: formatMessage({
                      defaultMessage: "Add as activity",
                      description: "Header for edit column on uploaded table",
                  }),
                  getActions: ({ row }) => {
                      const { searchParams } = row;
                      return [
                          <GridActionsCellItem
                              key="edit"
                              icon={<PostAddOutlinedIcon />}
                              onClick={() => {
                                  track(`Carbon: "Add as activity" clicked`, { searchParams });
                                  navigate({
                                      replace: true,
                                      to: addActivityFormRoute.fullPath,
                                      params: { scopeCategory: searchParams.type },
                                      search: () => {
                                          return searchParams;
                                      },
                                  });
                              }}
                              label={formatMessage({
                                  defaultMessage: "Add emissions with this factor",
                                  description: "Label for edit column on uploaded table",
                              })}
                          />,
                      ];
                  },
              },
          ]
        : [];

    // Column specific to activity based calculations
    const activityBasedColumns: ColumnActivityBased[] = [
        ...addAsActivityColumn,
        {
            ...baseColumnProps,
            field: "scope",
            headerName: formatMessage({
                defaultMessage: "Scope",
                description: "Header for scope or connection type column on uploaded table",
            }),
            width: 200,
            valueFormatter: ({ value }) => {
                return value;
            },
            type: "singleSelect",
            valueOptions: ["Scope 1", "Scope 2", "Scope 3"],
        },
        {
            ...baseColumnProps,
            field: "category",
            headerName: formatMessage({
                defaultMessage: "Category",
                description: "Header for category column on uploaded table",
            }),
            width: 200,
            type: "singleSelect",
            valueOptions: categoryOptions,
            renderCell: ({ value }) => {
                if (!value) {
                    return null;
                }
                return <CategoryColumn category={value} />;
            },
        },
        {
            ...baseColumnProps,
            field: "activityL1",
            headerName: activityL1Message,
            width: 150,
            flex: 0.3,
            valueFormatter: (v) => {
                if (v) {
                    return v;
                }
                return "-";
            },
        },
        {
            ...baseColumnProps,
            field: "activityL2",
            headerName: activityL2Message,
            width: 100,
            flex: 0.2,
            valueFormatter: (value) => {
                if (value) {
                    return value;
                }
                return "-";
            },
        },
        {
            ...baseColumnProps,
            field: "unit",
            headerName: formatMessage({
                defaultMessage: "Unit of measurement",
                description: "Header for unit column on uploaded table",
            }),
            width: 100,
        },
        {
            ...baseColumnProps,
            field: "year",
            headerName: formatMessage({
                defaultMessage: "Year",
                description: "Header for year column on uploaded table",
            }),
            width: 100,
        },
        {
            ...baseColumnProps,
            field: "source",
            headerName: formatMessage({
                defaultMessage: "Source",
                description: "Header for source column on uploaded table",
            }),
            width: 200,
            renderCell: ({ value }) => {
                if (value) {
                    return (
                        <Stack direction="row" gap={0.3} alignItems="center">
                            <Link
                                href={value.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                color={theme.palette.primary.main}
                            >
                                <Typography variant="textSm">{value.name}</Typography>
                            </Link>

                            <PopoverWithText
                                header={formatMessage({
                                    defaultMessage: "Emission factor details",
                                    description: "Database information",
                                })}
                                informationRows={[
                                    {
                                        title: formatMessage({
                                            defaultMessage: "Source",
                                            description: "Source",
                                        }),
                                        text: value.description,
                                    },
                                ]}
                            >
                                <InformationCircle fontSize="small" />
                            </PopoverWithText>
                        </Stack>
                    );
                }
                return null;
            },
        },
        {
            ...baseColumnProps,
            field: "emissionFactor",
            headerName: formatMessage({
                defaultMessage: "Emission factor [kg CO₂e/unit]",
                description: "Header for emission factor column on uploaded table",
            }),
            width: 200,
            type: "number",
            renderCell: ({ value }) => {
                return (
                    <EmissionFactorCell
                        emissionsFactors={{
                            regular: value.regular,
                            locationBased: value.locationBased,
                            marketBased: value.marketBased,
                        }}
                    />
                );
            },
        },
        {
            ...baseColumnProps,
            field: "region",
            headerName: formatMessage({
                defaultMessage: "Region",
                description: "Header for region column on uploaded table",
            }),
            type: "string",
            width: 200,
            renderCell: ({ row }) => {
                const value = row.regionCode;
                if (value && value !== "GLOBAL") {
                    return <RegionColumn countryCode={value} />;
                }
                return "-";
            },
        },
    ];
    return activityBasedColumns;
};
