import { Stack, Typography } from "@mui/material";

type SupportingTextCellProps = {
    primary?: string;
    secondary?: string;
};

/**
 * Table cell component that displays two rows of text, the first row is bold and the second row is regular.
 * - Design reference: [Figma](https://www.figma.com/design/Tb6GgTuIDhCTiA132VuYT5/Ignite-Riddle?node-id=4127-40787&t=2qlRRhx3DvUeTUkC-4)
 *
 * Props:
 * - `primary` (string): The primary text to display.
 * - `secondary` (string): The secondary text to display.
 */
const SupportingTextCell = ({ primary, secondary }: SupportingTextCellProps) => {
    return (
        <Stack direction="column" justifyContent="center" height="100%" overflow="hidden">
            {primary && (
                <Typography
                    variant="textSm"
                    fontWeight={500}
                    color={(theme) => theme.palette.text.textPrimary}
                    textOverflow="ellipsis"
                    overflow="hidden"
                >
                    {primary}
                </Typography>
            )}
            {secondary && (
                <Typography
                    variant="textSm"
                    fontWeight={400}
                    color={(theme) => theme.palette.text.textBody}
                    textOverflow="ellipsis"
                    overflow="hidden"
                >
                    {secondary}
                </Typography>
            )}
        </Stack>
    );
};

export type { SupportingTextCellProps };
export { SupportingTextCell };
