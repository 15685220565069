import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const ContentHeaderText = () => {
    return (
        <Typography variant="textLg" fontWeight={500}>
            <FormattedMessage defaultMessage="View your emission estimate" description="Emission calculation" />
        </Typography>
    );
};
