import { Box, Container, Divider, Stack } from "@mui/material";

const StepFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Box
            position="sticky"
            pb={3}
            bottom={0}
            bgcolor={(t) => t.palette.background.paper}
            zIndex={(theme) => theme.zIndex.appBar}
        >
            <Container>
                <Stack direction="column" spacing={3}>
                    <Divider />
                    {children}
                </Stack>
            </Container>
        </Box>
    );
};

export { StepFooter };
