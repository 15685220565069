export const SearchSvg: React.FC = () => (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="8" width="38" height="38" rx="19" fill="#F7F7F8" />
        <rect
            x="1.76562"
            y="1.76562"
            width="50.4688"
            height="50.4688"
            rx="25.2344"
            stroke="#94979E"
            strokeOpacity="0.16"
            strokeWidth="1.78125"
        />
        <rect
            x="5.32812"
            y="5.32812"
            width="43.3438"
            height="43.3438"
            rx="21.0781"
            stroke="#94979E"
            strokeOpacity="0.32"
            strokeWidth="1.78125"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.4167 19.775C22.3009 19.775 19.775 22.3009 19.775 25.4167C19.775 28.5324 22.3009 31.0583 25.4167 31.0583C28.5324 31.0583 31.0583 28.5324 31.0583 25.4167C31.0583 22.3009 28.5324 19.775 25.4167 19.775ZM18.3916 25.4167C18.3916 21.5368 21.5368 18.3916 25.4167 18.3916C29.2965 18.3916 32.4417 21.5368 32.4417 25.4167C32.4417 27.1075 31.8444 28.6587 30.8492 29.871L35.4058 34.4275C35.6759 34.6977 35.6759 35.1356 35.4058 35.4058C35.1356 35.6759 34.6977 35.6759 34.4275 35.4058L29.871 30.8492C28.6587 31.8444 27.1075 32.4417 25.4167 32.4417C21.5368 32.4417 18.3916 29.2965 18.3916 25.4167Z"
            fill="#61646B"
        />
    </svg>
);
