import { trpc } from "@/lib/trpc/client";
import { getLabelForUser } from "@/lib/labelling/userLabel";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { TimelineSkeleton } from "../skeletons";

interface Props {
    id: string;
}
export const ActivityAuditLog: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = trpc.getManualDataAuditLogs.useQuery({ id });
    const intl = useIntl();
    if (isLoading) {
        return <TimelineSkeleton />;
    }
    return (
        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
                pl: 0,
                pt: 0,
            }}
        >
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography variant="subtitle2" component="p">
                        <FormattedMessage defaultMessage="Created" description="Created" />
                    </Typography>
                    <Typography variant="overline" component="p" color="text.secondary">
                        {data ? dayjs(data.metaData.addedAt).format("DD MMM. YYYY") : null}
                    </Typography>
                    <Typography variant="overline" component="p">
                        {data ? getLabelForUser(data.metaData.createdBy, intl) : null}
                    </Typography>
                </TimelineContent>
            </TimelineItem>
            {data?.auditLog.map(({ id: key, addedAt, createdBy }) => (
                <TimelineItem key={key}>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="subtitle2" marginBottom="0" component="p">
                            <FormattedMessage defaultMessage="Updated" description="Updated" />
                        </Typography>
                        <Typography variant="overline" component="p" color="text.secondary">
                            {dayjs(addedAt).format("DD MMM. YYYY")}
                        </Typography>
                        <Typography variant="overline" component="p">
                            {getLabelForUser(createdBy, intl)}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            )) ?? null}
        </Timeline>
    );
};
