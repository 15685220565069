import { Activity } from "router";
import { DefaultFormType } from "../types";

export const formValuesToDefaultValues = (formValues: Activity): DefaultFormType => {
    const { removeEmissionsFromSpendSource, filter } = formValues;
    const activityL1 = formValues.activityL1
        ? {
              ...formValues.activityL1,
              label: formValues.activityL1?.value ?? "",
          }
        : ({
              value: "default",
              label: "",
              type: "predefined",
          } as const);

    const activityL2 = formValues.activityL2
        ? {
              ...formValues.activityL2,
              label: formValues.activityL2?.value ?? "",
          }
        : null;
    return {
        ...formValues,
        initialDescription: "",
        activityL1,
        activityL2,
        filter: filter ?? [],
        removeEmissionsFromSpendSource: removeEmissionsFromSpendSource ?? false,
    };
};
