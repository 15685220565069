import { FileUploadList } from "@/Components/FileList";
import { UploadInput } from "@/Components/FileUploadComponent/dumb/style";
import { UploadFileStatus } from "@/Components/FileUploadComponent/FileListItem/UploadFileStatus.enum";
import { hasValue } from "@/lib/hasValue";
import { File, InformationCircle } from "@ignite-analytics/icons";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { DefaultFormType } from "../../types";

export const FilePicker = () => {
    const ref = React.useRef<HTMLInputElement>(null);
    const { setValue, watch } = useFormContext<DefaultFormType>();
    const files = watch("files");
    const { formatMessage } = useIntl();
    return (
        <Stack direction="row" alignItems="start" gap={2} width="100%">
            <File fontSize="small" />
            <Stack width="100%" gap={2}>
                <Stack direction="row" paddingTop={0.2} alignItems="start" justifyContent="space-between" width="100%">
                    <Tooltip
                        title={formatMessage({
                            defaultMessage:
                                "Add documentation for the activity, for instance documentation from the supplier",
                            description: "Document picker subheader",
                        })}
                    >
                        <Stack direction="row" gap={0.5} alignItems="center">
                            <Typography variant="textSm" fontWeight={500}>
                                <FormattedMessage defaultMessage="Add documents" description="Document picker header" />
                            </Typography>
                            <InformationCircle fontSize="inherit" />
                        </Stack>
                    </Tooltip>
                    <Button size="2xsmall" color="secondary" variant="outlined">
                        <FormattedMessage defaultMessage="Upload" description="Upload button" />
                        <UploadInput
                            ref={ref}
                            type="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newFile = event.target.files && event.target.files[0];
                                // We store the file values in the form and not the input element itself
                                // To clear to values of the input element we avoid issues related to having states in two places
                                if (ref.current) {
                                    ref.current.value = "";
                                }
                                newFile &&
                                    setValue("files", [
                                        ...(files || []),
                                        {
                                            name: newFile.name,
                                            file: newFile,
                                            status: UploadFileStatus.Selected,
                                        },
                                    ]);
                            }}
                        />
                    </Button>
                </Stack>
                <FileUploadList
                    files={files?.map((file) => file.file).filter(hasValue) ?? []}
                    deleteFile={(fileName) =>
                        setValue(
                            "files",
                            files?.filter((file) => file.name !== fileName)
                        )
                    }
                />
            </Stack>
        </Stack>
    );
};
