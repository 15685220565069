import { graphql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { ActivityOption } from ".";

const PAGE_SIZE = 50;
export const useSearchPaginatedActivityOptions = () => {
    const [options, setOptions] = useState<ActivityOption[]>([]);
    const [searchInput, setSearchInput] = useState<{ searchTerm: string; limit: number; skip: number }>({
        searchTerm: "",
        limit: PAGE_SIZE,
        skip: 0,
    });

    const { loading } = useQuery(
        graphql(`
            query Carbon_searchPaginatedActivityOptions($searchTerm: String, $paginationInput: PaginationInput!) {
                searchPaginatedActivityOptions(paginationInput: $paginationInput, searchTerm: $searchTerm) {
                    activities {
                        id
                        name
                        category
                    }
                }
            }
        `),
        {
            variables: {
                searchTerm: searchInput.searchTerm ?? null,
                paginationInput: {
                    limit: searchInput.limit,
                    skip: searchInput.skip,
                },
            },
            onCompleted: (data) => {
                setOptions((prevOptions) => {
                    const newOptions = data.searchPaginatedActivityOptions.activities;
                    return prevOptions.concat(newOptions);
                });
            },
        }
    );

    const setSearchTerm = useCallback((value: string) => {
        setOptions([]);
        setSearchInput({
            searchTerm: value,
            limit: PAGE_SIZE,
            skip: 0,
        });
    }, []);

    const fetchMore = useCallback(() => {
        setSearchInput((prevSearchInput) => ({
            ...prevSearchInput,
            skip: prevSearchInput.skip + PAGE_SIZE,
        }));
    }, []);

    return { options, loading, setSearchTerm, searchTerm: searchInput.searchTerm, fetchMore };
};
