import { emissionFactorsRoute } from "@/Router/EmissionFactors";
import { useParams } from "@tanstack/react-router";

import { ActivityFactorTable } from "../ActivityFactorTable";
import { SpendFactorTable } from "../SpendFactorTable";

export const TableOutlet = () => {
    const { factorType } = useParams({ from: emissionFactorsRoute.fullPath });

    if (factorType === "spend") {
        return <SpendFactorTable />;
    }
    return <ActivityFactorTable />;
};
