import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { emissionIntensitiesStepLayout } from "../../routes";
import { SelectLevel } from "./SelectLevel";

const selectLevelRoute = createRoute({
    getParentRoute: () => emissionIntensitiesStepLayout,
    component: SelectLevel,
    path: "/level",
    validateSearch: z.object({
        name: z.string(),
        type: z.enum(["Revenue", "Employees", "Other"]),
        unit: z.string(),
        fromYear: z.number(),
        toYear: z.number(),
    }),
});

export { selectLevelRoute };
